/* eslint-disable */
// prettier-ignore
"use client";

import * as React from "react";
import {
    flexRender,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "layouts/cysr/components/ui/table";

import cysrConfig, { process } from "layouts/cysr/config";

import { DataTablePagination } from "layouts/cysr/timeshift/data-table-pagination";

import { DataTableToolbar } from "./data-table-toolbar";

import { DataTableExpandedContent } from "./data-table-expanded-content";

import CysrTableButtonActions from "layouts/cysr/components/cysrTableButtonActions";

export function DataTable({
    columns,
    data,
    darkMode,
    selectedDays
}) {

    const [rowSelection, setRowSelection] = React.useState({});

    const [columnVisibility, setColumnVisibility] = React.useState({
        // here set default visibility of columns!
        // status: false,
        ///domain: false,
        first_score: false,
        second_score: false,
    });

    const [columnFilters, setColumnFilters] = React.useState([]);

    const [sorting, setSorting] = React.useState([
        {
            id: "second_rating",
            desc: false,
        }
    ]);

    const table = useReactTable({
        data,
        columns,
        initialState: {
            pagination: {
                pageSize: 20,
            },
        },
        state: {
            sorting,
            columnVisibility,
            rowSelection,
            columnFilters,
        },
        // enableRowSelection: (row) => (row.original.dpo_approved && row.original.status != "acknowledged"),
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });

    // csv download s
    const escapeCsvCell = (cell) => {
        if (cell == null) {
            return '';
        }
        // const sc = cell.toString().trim();
        const sc = cell.toString()//.trim();
        if (sc === '' || sc === '""') {
            return sc;
        }
        if (sc.includes('"') || sc.includes(',') || sc.includes('\n') || sc.includes('\r')) {
            return '"' + sc.replace(/"/g, '""') + '"';
        }
        return sc;
    };

    const makeCsvData = (columns, data) => {
        return data.reduce((csvString, rowItem) => {
            return (
                csvString +
                columns.map(({ accessor }) => escapeCsvCell(accessor(rowItem))).join(',') +
                '\r\n'
            );
        }, columns.map(({ name }) => escapeCsvCell(name)).join(',') + '\r\n');
    };

    const downloadAsCsv = (columns, data, filename) => {
        const csvData = makeCsvData(columns, data);
        const csvFile = new Blob([csvData], { type: 'text/csv' });
        const downloadLink = document.createElement('a');

        downloadLink.display = 'none';
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const handleDownloadCsv = () => {
        const columns = [
            { accessor: (item) => item.status, name: 'Status' },
            { accessor: (item) => item.key, name: 'Username' },
            { accessor: (item) => item.passwordtype, name: 'Password Encoding' },
            { accessor: (item) => item.threat_type, name: 'Category' },
            { accessor: (item) => item.date, name: 'Date' },
            { accessor: (item) => item.domain, name: 'Domain' },
            { accessor: (item) => item.sourcelong, name: 'Source' },
            { accessor: (item) => item.password, name: 'Password' },
        ];

        downloadAsCsv(columns, data, 'Leaks with passwords');
    };

    const handleDownloadCsvPasswordless = () => {
        const columns = [
            { accessor: (item) => item.status, name: 'Status' },
            { accessor: (item) => item.key, name: 'Username' },
            { accessor: (item) => item.passwordtype, name: 'Password Encoding' },
            { accessor: (item) => item.threat_type, name: 'Category' },
            { accessor: (item) => item.date, name: 'Date' },
            { accessor: (item) => item.domain, name: 'Domain' },
            { accessor: (item) => item.sourcelong, name: 'Source' },
        ];

        downloadAsCsv(columns, data, 'Leaks');
    };
    // csv download e
    return (
        <div className={`${darkMode ? "dark" : "light"} space-y-4 w-full`}>
            <DataTableToolbar table={table} data={data} darkMode={darkMode} selectedDays={selectedDays} />
            <div className="rounded-md ring-1 ring-opacity-50 ring-turquoise-100 dark:ring-turquoise-900">
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header, i, { length }) => {
                                    return (
                                        // nascondere actions
                                        <TableHead className={(i + 1 === length) ? "hidden" : i === 0 ? "text-left !pl-4" : "text-left"} key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </TableHead>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => [
                                <TableRow
                                    key={row.id}
                                    data-state={row.getIsSelected() && "selected"}
                                >
                                    {row.getVisibleCells().map((cell, i) => (
                                        <TableCell
                                            key={cell.id}
                                            className={
                                                i == row.getVisibleCells().length - 1
                                                    ? // last cell
                                                    "sticky right-0 top-0 bg-inherit items-right"
                                                    : "bg-white dark:bg-[rgb(34,39,42)]"
                                            }
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>,
                                // custom expanded row:
                                row.getIsExpanded() && (
                                    <TableRow
                                        // className="from-turquoise-200 dark:from-turquoise-800 bg-gradient-to-t"
                                        className="bg-turquoise-50 dark:bg-[#121619]"
                                        key={`${row.id}-expanded`}
                                    >
                                        <TableCell colSpan={columns.length + 1}>
                                            <DataTableExpandedContent row={row} selectedDays={selectedDays} />
                                        </TableCell>
                                    </TableRow>
                                ),
                            ])
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    className="h-24 text-center"
                                >
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            {false && <div className="flex items-center space-x-2 mt-4">
                <CysrTableButtonActions
                    options={
                        [
                            {
                                label: "CSV",
                                color: "info",
                                value: "CSV",
                                onClick: "download_csv_passwordless",
                                key: 0,
                                icon: cysrConfig().report.icon.svg
                            },
                            {
                                label: "CSV (with Passwords)",
                                color: "info",
                                value: "CSV (with Passwords)",
                                onClick: "download_csv",
                                key: 1,
                                icon: cysrConfig().report.icon.svg
                            }
                        ]
                    }
                    triggerAction={
                        function (fun) {
                            switch (fun) {
                                case "download_csv":
                                    handleDownloadCsv()
                                    break;

                                case "download_csv_passwordless":
                                    handleDownloadCsvPasswordless()
                                    break;

                                default:
                                    break;
                            }
                        }
                    }
                />
            </div>
            }
            <DataTablePagination table={table} darkMode={darkMode} />
        </div>
    );
}
