/* eslint-disable */
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import cysrConfig from "layouts/cysr/config";

const filter = createFilterOptions();


const countriesCodes = [
  "AT",
  "BE",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE",
  "other"
];

export default function CysrAutocomplete({ callbackOnAdding, dialogTitle, dialogText, dialogInputs = false, optionKeyForDisplay, optionSchema, darkMode, inputLabel, defaultValue, options }) {
  const [autocompleteOptions, setAutocompleteOptions] = React.useState(options);

  const [value, setValue] = React.useState(null);
  const [open, toggleOpen] = React.useState(false);
  const [dialogValue, setDialogValue] = React.useState(optionSchema);

  const handleClose = () => {
    setDialogValue(optionSchema);
    toggleOpen(false);
  };

  const handleSubmit = () => {
    let newOption = {};
    for (const key in optionSchema) {
      if (Object.hasOwnProperty.call(optionSchema, key)) {
        // const element = optionSchema[key];
        newOption[key] = dialogValue[key]
      }
    }
    setValue(newOption);
    let currentAutocompleteOptions = autocompleteOptions;
    currentAutocompleteOptions.push(newOption);
    setAutocompleteOptions(currentAutocompleteOptions)
    callbackOnAdding(dialogValue)
    handleClose();
  };

  return (
    <React.Fragment>
      <Autocomplete
        defaultValue={defaultValue}
        value={value ? value : defaultValue}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              // add newValue
              const objectWithValue = optionSchema;
              objectWithValue[optionKeyForDisplay] = newValue;
              if (dialogInputs) {
                setDialogValue(objectWithValue);
              }
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            const objectWithValue = optionSchema;
            objectWithValue[optionKeyForDisplay] = newValue.inputValue;
            if (dialogInputs) {
              setDialogValue(objectWithValue);
            }
          } else {
            setValue(newValue);
          }
          callbackOnAdding(newValue)
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (dialogInputs) {
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`,
              });
            }
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={dialogInputs ? autocompleteOptions : options}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        sx={{ width: 300 }}
        freeSolo={dialogInputs ? true : false}
        size="small"
        renderInput={(params) => <MDInput sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#232728' : '#ffffff' }} size="small" label={inputLabel} {...params} />}
      />
      {
        dialogInputs && <Dialog open={open} onClose={handleClose} style={{
          background: darkMode ? 'rgba(0,0,0,0.25)' : 'rgba(255, 255, 255, 0.25)',
          backdropFilter: "blur(12px)"
        }}>
          <div style={{ background: darkMode ? '#232728' : '#ffffff' }}>
            <DialogTitle sx={{ marginBottom: 0 }}>{dialogTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {dialogText}
              </DialogContentText>
              {dialogInputs.map(i =>
                i.countryCodeSelector ?
                  <Autocomplete
                    fullWidth
                    sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#232728' : '#ffffff' }}
                    onChange={(e, x) => {

                      setDialogValue({
                        ...dialogValue,
                        [i.key]: x,
                      })

                    }}
                    size="small"
                    options={countriesCodes}
                    defaultValue={dialogValue[i.key]}
                    renderInput={(params) => <MDInput
                      sx={{ marginTop: "1em", color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#232728' : '#ffffff' }}
                      size="small" label={i.label} {...params} />}
                  />
                  :
                  <MDInput
                    fullWidth
                    sx={{
                      marginTop: "1em",
                      color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)',
                      background: darkMode ? '#232728' : '#ffffff'
                    }}
                    size="small"
                    defaultValue={dialogValue[i.key]}
                    label={i.label}
                    onChange={(event) =>
                      setDialogValue({
                        ...dialogValue,
                        [i.key]: event.target.value,
                      })
                    }
                    type={`${i.type ? i.type : "text"}`}
                  />
              )}
            </DialogContent>
            <DialogActions>
              <MDButton
                aria-label="expand row"
                onClick={handleClose} style={cysrConfig().button_style} size="medium">
                <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().remove.icon.svg }} />
                Cancel
              </MDButton>
              <MDButton
                aria-label="expand row"
                onClick={handleSubmit} style={cysrConfig().button_style} size="medium">
                <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().add.icon.svg }} />
                Add
              </MDButton>
            </DialogActions>
          </div>
        </Dialog>
      }
    </React.Fragment>
  );
}
/* eslint-disable */