/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { useLocation } from "react-router-dom";
import Loader from "layouts/cysr/components/Loader";

import cysrConfig from "layouts/cysr/config";

// https://www.robinwieruch.de/react-component-to-pdf/
// import html2canvas from 'html2canvas';
// import { jsPDF } from 'jspdf';

/*
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});
*/

// Material Dashboard 2 React Examples
/* eslint-disable */
function ReportCTI() {
  let assetsNumber = 50;
  let timeshiftReportDate = false;
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.get('assets_count') && queryParams.get('assets_count') > 0) {
    assetsNumber = queryParams.get('assets_count');
  }
  if (queryParams.get('timeshift')) {
    timeshiftReportDate = queryParams.get('timeshift');
  }
  const [isLoading, setLoading] = useState(true);
  const btnDownloadReportDefaultString = "Download CTI report as PDF";
  const [btnDownloadReportString, setStringBtnDownloadReport] = useState(btnDownloadReportDefaultString);
  const location = useLocation();
  // Our State.
  const [views, setViews] = useState([]);
  let accountUUID = 0;
  if (window.location.pathname.indexOf("/third-party/") > -1) {
    accountUUID = window.location.pathname.substring(
      window.location.pathname.indexOf("/third-party/") + "/third-party/".length,
      window.location.pathname.lastIndexOf("/")
    );
  } else {
    accountUUID = window.location.pathname.substring(
      window.location.pathname.indexOf("/company/") + "/company/".length,
      window.location.pathname.lastIndexOf("/")
    );
  }

  const handleDownloadPdf = async () => {
    setStringBtnDownloadReport('Generating report...')
    // fetch(`${cysrConfig().api_base_url}dashboard/account/${accountUUID}/report/${window.location.search}`, {
    fetch(timeshiftReportDate
      ? `${cysrConfig().api_base_url}timeshift/${accountUUID}/report/${timeshiftReportDate}/cti/${window.location.search}`
      : `${cysrConfig().api_base_url}cti/${accountUUID}/report/${window.location.search}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      },
    })

      .then(res => res.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        /*
        window.location.assign(file);
        */
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Cybersecurity CTI Report.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setStringBtnDownloadReport(btnDownloadReportDefaultString)
      });
    /*
      .then((response) => {
        const doc = await response.blob()
        response.blob()
        console.log(response)
      })
        .then((response) => response.json())
        .then((reportResponse) => {
          console.log("report data");
          console.log(reportResponse)
        })
        */
  };

  const SECOND = 1000;
  const MINUTE = SECOND * 60;
  // const HOUR = MINUTE * 60;
  // const DAY = HOUR * 24;

  function useTimer(deadline, interval = SECOND) {
    const [timespan, setTimespan] = useState(deadline);

    useEffect(() => {
      const intervalId = setInterval(() => {
        setTimespan((_timespan) => _timespan - interval);
      }, interval);

      return () => {
        clearInterval(intervalId);
      };
    }, [interval]);

    /* If the initial deadline value changes */
    useEffect(() => {
      setTimespan(deadline);
    }, [deadline]);

    return {
      // days: Math.floor(timespan / DAY),
      // hours: Math.floor((timespan / HOUR) % 24),
      minutes: Math.floor((timespan / MINUTE) % 60),
      seconds: Math.floor((timespan / SECOND) % 60)
    };
  }
  // const { days, hours, minutes, seconds } = useTimer("2022-12-31T23:59:59");
  const { minutes, seconds } = useTimer(30 * SECOND);
  // const dashboardCallUrl = `${cysrConfig().api_base_url}dashboard/account/${accountUUID}/`;
  const dashboardCallUrl = `${cysrConfig().api_base_url}dashboard/mainpage/${accountUUID}/`;
  useEffect(() => {
    setLoading(true);

    // very similar to from handleDownloadPdf s
    setStringBtnDownloadReport('Generating report...')

    let pdfFileName = "CTI Report";
    // fetch(`${cysrConfig().api_base_url}dashboard/account/${accountUUID}/report/${window.location.search}`, {
    fetch(timeshiftReportDate
      ? `${cysrConfig().api_base_url}timeshift/${accountUUID}/report/${timeshiftReportDate}/cti/${window.location.search}`
      : `${cysrConfig().api_base_url}cti/${accountUUID}/report/${window.location.search}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      },
    })
      .then(response => {

        for (var pair of response.headers.entries()) { // accessing the entries
          // console.log("pair")
          // console.log(pair)
          if (pair[0] === 'content-disposition') { // key I'm looking for in this instance
            // pdfFileName = pair[1] // saving that value where I can use it
            pdfFileName = pair[1].split('filename=')[1].replace('"', "").replace('"', "")
          }
        }

        // null:
        // console.log(res.headers.get('Content-Disposition'))
        // console.log(res.headers.get('Content-Disposition').split('filename=')[1])
        return response.blob()
      })
      .then(blob => {
        // does not work in chrome s
        // const fileUrl = window.URL.createObjectURL(blob); 
        // window.location.assign(fileUrl)
        // does not work in chrome e        
        var f = new FileReader();
        f.readAsDataURL(blob);
        f.onload = d => {
          var uri = d.target.result;
          var link = document.createElement('a');
          link.download = pdfFileName;
          link.href = uri;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // delete link;
        }
        /*
                console.log("blob")
                console.log(blob)
                var url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                // link.setAttribute('download', 'CYSR-report.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
        */
        setStringBtnDownloadReport(btnDownloadReportDefaultString)

        setViews({
          string: "Download completed. You can find the generated report in your download folder."
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
      });
    // very similar to handleDownloadPdf e
  }, [location]);
  return (
    <div style={{ backdropFilter: "blur(4px)", display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", position: "fixed", zIndex: "99999", left: "0", right: "0", top: "0", bottom: "0", padding: "2em" }}>
      <Card style={{ padding: "1em" }}>
        {isLoading ? (
          <Grid container spacing={3}>
            <Grid item>
              <MDTypography mb={1} variant="body2">{btnDownloadReportString}</MDTypography>
              <MDTypography mb={2} variant="button">{minutes && parseInt(minutes, 10) > 0 ? `${minutes} minutes and` : ""} {seconds && parseInt(seconds, 10) > 0 ? `${seconds} seconds` : "few seconds"} remaining</MDTypography>
              <Loader />
            </Grid>
          </Grid>
        ) : (
          <Grid item container spacing={3}>
            <Grid item container>
              <Grid item>
                {
                  views && views.string && <MDTypography mb={2} variant="body2">{views.string}</MDTypography>
                }
                <Grid item container spacing={3}>
                  {false && <Grid item>
                    <MDButton color="info" onClick={() => handleDownloadPdf()} {...(btnDownloadReportString != btnDownloadReportDefaultString && { disabled: true })}>{btnDownloadReportString != btnDownloadReportDefaultString && (
                      <div style={{ height: "20px", width: "20px", marginRight: "8px" }}><Loader /></div>
                    )}{btnDownloadReportString}</MDButton>
                  </Grid>}
                  <Grid item>
                    <MDButton color="error" onClick={() => window.close()}>Close window</MDButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Card>
    </div>
  );
}
/* eslint-disable */
export default ReportCTI;
