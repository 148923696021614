/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Authentication layout components
import BasicLayout from "layouts/cysr/login/BasicLayout";
import LoginWithAuth from "layouts/cysr/components/LoginWithAuth";
import LoginResetPassword from "layouts/cysr/components/LoginResetPassword";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import cysrConfig from "layouts/cysr/config";

function manageLogin(loginData, toggleSnackbar, show2FACode, setEphToken) {
    console.log("loginData");
    console.log(loginData);
    // check if user has 2fa activated
    if (loginData && loginData.method && loginData.method === "app") {
        if (loginData.ephemeral_token) {
            setEphToken(loginData.ephemeral_token)
            show2FACode(true)
        } else {
            toggleSnackbar("API Error", "No ephemeral token returned, please contact the support for assistance", "error", "error", "");
        }
    } else if (loginData && loginData.access) {
        localStorage.setItem("cysr_access", loginData.access);
        localStorage.setItem("cysr_refresh", loginData.refresh);
        localStorage.setItem("cysr_version", cysrConfig().version);
        /*
          // added to /companies instead to avoid calling same API p1/2
          // get accountrole of user s
          const callUrl = `${cysrConfig().api_base_url}user/mycompanies/`;
          fetch(callUrl, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
            },
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                console.error(response);
                return response.json().then(err => {
                  throw JSON.stringify(err);
                });
                // return response.json();
              }
            })
            .then((data) => {
              if (data) {
                localStorage.setItem("cysr_***useraccountroles", JSON.stringify(data));
      */
        // get mydata of user s
        const myDataCallUrl = `${cysrConfig().api_base_url}user/mydata/`;
        fetch(myDataCallUrl, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
            },
        })
            .then((response) => {
                if (response.ok) {
                    // window.location.reload();
                    return response.json();
                } else {
                    console.error(response);
                    return response.json().then(err => {
                        throw JSON.stringify(err);
                    });
                    return response.json();
                }
            })
            .then((mydataData) => {

                if (mydataData) {
                    localStorage.setItem("cysr_mydata", JSON.stringify(mydataData));
                    window.location.href = "/cysr/companies";
                } else {
                    console.error("fail with mydataData");
                    console.error(mydataData);
                    localStorage.setItem("cysr_mydata", '{"user":{"user_name":"","user_lastname":"","user_email":""},"whitelabel":{"contractor_logo_link":"https://cysr.com/static/images/logo.svg","contractor_logo_dark_link":"https://cysr.com/static/images/logo-dark.svg","contractor_badge_base_url":"https://cysr.com/static/badges"}}');
                    window.location.href = "/cysr/companies";
                }
                // toggleSnackbar(mydataData.Message, mydataData.Detail, "success", "checkmark", "");
            })
            .catch((err) => {
                console.log(err);
                console.log("err3:", err);
                if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
                    toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
                } else if (JSON.parse(err) && JSON.parse(err).error) {
                    toggleSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
                } else {
                    toggleSnackbar("Error", "Some error happened", "error", "error", "Error");
                }
            });
    } else {
        // toggleSnackbar();
        console.error(data);
    }
    /*
    // added to /companies instead to avoid calling same API p2/2
  })
  .catch((err) => {
    // toggleSnackbar();
    if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
      toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
    } else if (JSON.parse(err) && JSON.parse(err).error) {
      toggleSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
    } else {
      toggleSnackbar("Error", "Some error happened", "error", "error", "Error");
    }
    console.error(err);
  });
  }
  */
}
function Basic() {
    const params = new URLSearchParams(window.location.search);
    const [resettingPassword, setResettingPassword] = useState(params.get('reset') ? params.get('reset') : false);

    const [loginLoading, setLoginLoading] = useState(false);
    const [sendResetLoading, setSendResetLoading] = useState(false);
    // Snackbars
    const [show, setShow] = useState(false);
    const [showAuth, setShowAuth] = useState(false);
    const [showResetPassword, setShowResetPassword] = useState(false);

    const [ephToken, setEphToken] = useState("");

    const [msgDateTime, setMsgDateTime] = useState("Information");
    const [msgIcon, setMsgIcon] = useState("info");
    const [msgTheme, setMsgTheme] = useState("light");
    const [msgTitle, setMsgTitle] = useState("Recheck not executed");
    // reacthtml msgContent:
    const [msgContent, setMsgContent] = useState(
        <></>
    )

    const defaultToggleSnackbar = () => {
        setShow(!show);
    }
    const show2FACode = (show = true) => {
        setShowAuth(show);
    }
    function toggleSnackbar(title = "Error", msg = "Error", theme = "error", icon = "error", dateTime = "Error") {
        setMsgDateTime(dateTime);
        setMsgIcon(icon);
        setMsgTheme(theme);

        setMsgTitle(title);
        setMsgContent(msg);
        // setShow(!show);
        setShow(true);
    }
    // const [rememberMe, setRememberMe] = useState(false);
    // const handleSetRememberMe = () => setRememberMe(!rememberMe);
    const logIn = () => {
        setLoginLoading(true)
        // const loginCallUrl = `${cysrConfig().api_base_url}login/`;
        const loginCallUrl = `${cysrConfig().api_base_url}auth/login/`;
        const dataLogin = {
            // email: document.getElementById("cysr_email").value,
            username: document.getElementById("cysr_email").value,
            password: document.getElementById("cysr_pass").value,
        };
        fetch(loginCallUrl, {
            body: JSON.stringify(dataLogin),
            headers: {
                "content-type": "application/json",
            },
            method: "POST",
        })
            .then((response) => {
                if (response.ok) {
                    // window.location.reload();
                    return response.json();
                } else {
                    console.error(response);
                    return response.json().then(err => {
                        throw JSON.stringify(err)
                    })
                    return response.json()
                }
            })
            .then((loginData) => {
                manageLogin(loginData, toggleSnackbar, show2FACode, setEphToken)
                setLoginLoading(false)
            })
            .catch((err) => {
                setLoginLoading(false)
                console.log(err);
                console.log("err3:", err);
                if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
                    toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
                } else if (JSON.parse(err) && JSON.parse(err).error) {
                    toggleSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
                } else {
                    toggleSnackbar("Error", "Some error happened", "error", "error", "Error");
                }
            })
    };
    const sendResetPassword = () => {
        setSendResetLoading(true)
        // const passwordEmail = document.getElementById("cysr_reset-password_email").value ? document.getElementById("cysr_reset-password_email").value.replace("@", "%40") : false;
        const passwordEmail = document.getElementById("cysr_reset-password_email").value ? document.getElementById("cysr_reset-password_email").value : false;
        if (passwordEmail && passwordEmail != "") {
            // /{version}/password-reset/{email}/send-email/
            const passwordResetCallUrl = `${cysrConfig().api_base_url}password-reset/${passwordEmail}/send-mail/`;
            fetch(passwordResetCallUrl, {
                headers: {
                    "content-type": "application/json",
                },
                // method: "GET",
            })
                .then((response) => {
                    if (response.ok) {
                        // window.location.reload();
                        return response.json();
                    } else {
                        console.error(response);
                        return response.json().then(err => {
                            throw JSON.stringify(err)
                        })
                        return response.json()
                    }
                })
                .then((resetPasswordData) => {
                    console.log(resetPasswordData)
                    toggleSnackbar("Email to reset password sent", `If an account exists for ${passwordEmail}, you will get an email with instructions on resetting your password. If it doesn't arrive, be sure to check your spam folder.`, "info", "info", "");
                    setSendResetLoading(false)
                })
                .catch((err) => {
                    console.log(err);
                    console.log("err3:", err);
                    if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
                        toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
                    } else if (JSON.parse(err) && JSON.parse(err).error) {
                        toggleSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
                    } else {
                        toggleSnackbar("Error", "Some error happened", "error", "error", "Error");
                    }
                    setSendResetLoading(false)
                })
        } else {
            toggleSnackbar("Error", "No email provided", "error", "error", "Error");
        }
    };

    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox pt={2} pb={3} px={3}>
                    {resettingPassword ? <LoginResetPassword token={resettingPassword} toggleSnackbar={toggleSnackbar} setResettingPassword={setResettingPassword} />
                        : showAuth ?
                            <LoginWithAuth ephemeral_token={ephToken} toggleSnackbar={toggleSnackbar} show2FACode={show2FACode} manageLogin={manageLogin} />
                            : showResetPassword ?
                                <>
                                    <MDTypography variant="h4" fontWeight="medium" mb={1}>
                                        Reset password
                                    </MDTypography>
                                    <MDBox mb={1}>
                                        <MDTypography variant="body2" color="text">
                                            Enter the email address of the user you want to reset the password for.
                                            <span className="text-xs block my-4">
                                                Note that a maximum of 3 password reset attempts is allowed per hour.</span>
                                            <span className="text-xs block mb-4">
                                                Also, note that if 2FA (Two-Factor Authentication) is enabled for this user, it will remain active even after the password reset. If you have lost access to 2FA, use the backup codes provided during its activation. If you don't have the backup codes, please contact platform support.
                                            </span>
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox onSubmit={(e) => {
                                        e.preventDefault();
                                        sendResetPassword()
                                            ;
                                    }} component="form" role="form">
                                        <MDBox mb={2}>
                                            <MDInput autoComplete="username" type="email" label="Email" fullWidth id="cysr_reset-password_email" />
                                        </MDBox>

                                        <div style={{ display: "flex", gap: "12px" }}>
                                            <MDButton onClick={() => setShowResetPassword(false)} style={cysrConfig().button_style} size="medium">
                                                <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().exit.icon.svg }} />
                                                Back
                                            </MDButton>
                                            <MDButton type="submit" style={cysrConfig().button_style} size="medium">
                                                {sendResetLoading ?
                                                    <span className="spin" style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().loading.icon.svg }} />
                                                    :
                                                    <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().send_mail.icon.svg }} />}
                                                Send email to reset
                                            </MDButton>
                                        </div>
                                    </MDBox>
                                </>
                                :
                                <>
                                    <MDTypography variant="h4" fontWeight="medium" mb={1}>
                                        Login
                                    </MDTypography>
                                    <MDBox component="form" role="form">
                                        <MDBox mb={2}>
                                            <MDInput autoComplete="username" type="email" label="Email" fullWidth id="cysr_email" />
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput autoComplete="current-password" type="password" label="Password" id="cysr_pass" fullWidth />
                                            <div style={{ display: "flex", justifyContent: "end" }}>
                                                <MDButton style={{
                                                    fontWeight: 500,
                                                    padding: 0,
                                                    textTransform: "unset"
                                                }} variant="text" color="info" onClick={() => setShowResetPassword(true)} size="small">
                                                    Forgot password?
                                                </MDButton>
                                            </div>
                                        </MDBox>
                                        <MDBox mt={4} mb={1}>
                                            <MDButton fullWidth onClick={logIn} style={cysrConfig().button_style} size="medium">
                                                {loginLoading ?
                                                    <span className="spin" style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().loading.icon.svg }} />
                                                    :
                                                    <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().login.icon.svg }} />}
                                                Log in
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>
                                </>
                    }
                </MDBox>
            </Card>
            <MDSnackbar
                color={msgTheme}
                icon={msgIcon}
                title={msgTitle}
                content={msgContent}
                dateTime={msgDateTime}
                open={show}
                close={defaultToggleSnackbar}
            />
        </BasicLayout>
    );
}

export default Basic;
/* eslint-disable */