/* eslint-disable */
// prettier-ignore
import * as React from 'react';
// import Box from '@mui/material/Box';
// import Slider from '@mui/material/Slider';

import * as Slider from '@radix-ui/react-slider';
import "./styles.css"

export default function VerticalAccessibleSlider({ height, value, step, onChange, marks, min, max }) {
    return (
        <Slider.Root
            style={{
                height: height,
                marginBottom: 28,
                marginTop: 28,
            }}
            className="SliderRoot"
            value={[value]}
            orientation="vertical"
            variant="soft"
            step={step}
            onValueChange={onChange}
            // onValueCommit={onChange}
            min={min}
            max={max}

        >
            <Slider.Track className="SliderTrack">
                <Slider.Range className="SliderRange" />
                {marks.map((m) => {
                    return <span className='text-tremor-content dark:text-dark-tremor-content absolute left-4 text-sm whitespace-nowrap block' style={{
                        bottom: `calc(${m.value / 100} * ${height})`,
                        transform: "translateY(50%)"
                    }}>{m.label}</span>
                })}
            </Slider.Track>
            <Slider.Thumb className="SliderThumb" />
        </Slider.Root>
    )
}