/* eslint-disable */
import { Base64 } from 'js-base64';
import { useState, useEffect } from "react";
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/material-ui';
import { Collapse, Paper, Stack, TablePagination } from '@mui/material';

import { usePagination } from '@table-library/react-table-library/pagination';
import { useSort } from '@table-library/react-table-library/sort';

// custom stuffs s
import cysrConfig from "layouts/cysr/config";
import Grid from "@mui/material/Grid";
import MDBadge from "components/MDBadge";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ScoreItem from "layouts/cysr/components/ScoreItem";
import Card from "@mui/material/Card";
import CysrTags from "layouts/cysr/components/CysrTags";
import DashboardCompanyInfo from "layouts/cysr/components/DashboardCompanyInfo";
// import FormField from "layouts/pages/account/components/FormField";

import { useMaterialUIController } from "context";
// custom stuffs e

const AsyncImage = (props) => {
  const [loadedSrc, setLoadedSrc] = useState(null);
  useEffect(() => {
    setLoadedSrc(null);
    if (props.src) {
      const handleLoad = () => {
        setLoadedSrc(props.src);
      };
      const image = new Image();
      image.addEventListener('load', handleLoad);
      image.src = props.src;
      return () => {
        image.removeEventListener('load', handleLoad);
      };
    }
  }, [props.src]);
  if (loadedSrc === props.src) {
    return (
      <img {...props} />
    );
  }
  return null;
};

const toggleArchive = (accountUuid) => {
  //(?P(v1))/account/(?P<account_req_uuid>/status/(?P([archive|unarchive]))/
  const toggleArchiveUrl = `${cysrConfig().api_base_url}account/${accountUuid}/status/archive/`;
  fetch(toggleArchiveUrl, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      "content-type": "application/json",
    },
    method: "PUT",
  })
    .then((response) => {
      if (response.ok) {
        window.location.reload();
        return response.json();
      }
      throw new Error(response);
    })
    .then((toggleArchiveData) => {
      // no-console-ignore
      console.log(toggleArchiveData);
    })
    .catch((err) => {
      // no-console-ignore
      console.log(err);
    });
};


const unArchive = (accountUuid) => {

  //(?P(v1))/account/(?P<account_req_uuid>/status/(?P([archive|unarchive]))/
  const toggleArchiveUrl = `${cysrConfig().api_base_url}account/${accountUuid}/status/unarchive/`;
  fetch(toggleArchiveUrl, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      "content-type": "application/json",
    },
    method: "PUT",
  })
    .then((response) => {
      if (response.ok) {
        window.location.reload();
        return response.json();
      }
      throw new Error(response);
    })
    .then((toggleArchiveData) => {
      // no-console-ignore
      console.log(toggleArchiveData);
    })
    .catch((err) => {
      // no-console-ignore
      console.log(err);
    });
};

const Component = ({ data }) => {
  // console.log('data')
  // console.log(data)

  const actionsPixelWidth = data.isArchive ? 140 : data.isSubscription ? 272 : 332;
  const tableRowsPerPage = [25, 50, 100, 200, 400];
  const [tableTagsActive, setTableTagsActive] = useState(data.tableTagsActive);
  const [tableTagsOptions, setTableTagsOptions] = useState(data.tableTagsOptions);
  const [hideCompleted, setHideCompleted] = useState(data.hideCompleted);
  const [search, setSearch] = useState(data.search);
  let tData = { nodes: data.nodes };
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  let COLUMNS = [];

  data.isSubscription
    ?
    COLUMNS.push(
      {
        label: 'Created',
        renderCell: (item) => item.mu_created_at ? new Date(item.mu_created_at).toLocaleDateString('it-IT', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }) : "",
        sort: { sortKey: 'CREATED' },
        resize: true,
        cysrTag: "mu_created_at",
        cysrTagDateFormat: 'it-IT'
      }
    )
    :
    COLUMNS.push({
      label: 'Rating',
      renderCell: (item) =>
        <MDBox
          fullWidth
          color="text"
          style={{ textAlign: "center" }}
        >{item.account_last_rating_val ? <ScoreItem scoreValue={item.account_last_rating_val} /> : "N/A"}
        </MDBox>
      ,
      sort: { sortKey: 'RATING' },
      resize: true,
      cysrTag: "account_last_rating_val"
    },
      {
        label: 'Last Check',
        renderCell: (item) => item.account_oldest_check_time_on_manual_asset ? new Date(item.account_oldest_check_time_on_manual_asset).toLocaleDateString('it-IT', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }) : "",
        sort: { sortKey: 'LAST_CHECK' },
        resize: true,
        cysrTag: "account_oldest_check_time_on_manual_asset",
        cysrTagDateFormat: 'it-IT'
      })

  COLUMNS.push(
    {
      label: 'Name',
      renderCell: (item) => <p style={{ fontWeight: "bold", overflow: "hidden", maxWidth: "100%", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
        {
          // item.account_vat_name ? item.account_name + " (" + item.account_vat_name + ")" : item.account_name
        }
        {item.account_vat_name ? item.account_name : item.account_name}
      </p>,
      sort: { sortKey: 'NAME' },
      resize: true,
      cysrTag: ["account_vat_name", "account_name"],
    },
    {
      label: 'Id',
      renderCell: (item) => item.account_vat_country_code === "XX" ? "other" : item.account_vat_country_code + (item.account_data_national_id ? (" " + item.account_data_national_id) : ""),
      // sort: { sortKey: 'VAT' },
      sort: false,
      resize: true,
      cysrTag: ["account_vat_country_code", "account_data_national_id", "account_vat"]
    }
  )

  data.isSubscription
    ?
    null
    :
    COLUMNS.push(
      /*{
        label: 'Subscription',
        renderCell: (item) => item.contractor_subscription,
        sort: { sortKey: 'SUBSCRIPTION' },
        resize: true,
        cysrTag: "contractor_subscription"
      },*/
      {
        label: 'Linked Companies',
        renderCell: (item) => (
          Object.values(item.linked_accounts)
            ? Object.values(item.linked_accounts).length > 1
              ? Object.values(item.linked_accounts).length
              : Object.values(item.linked_accounts)[0]
            : 0
        ),
        sort: { sortKey: 'LINKED_ACCOUNTS' },
        resize: true,
        cysrTag: [
          "linked companies",
          "linked-companies",
          "linked_companies"
        ]
      }
    );

  COLUMNS.push(
    {
      label: 'Actions',
      renderCell: (item) => (
        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '8px', width: `${actionsPixelWidth}px` }}>
          {data.isArchive ?
            <MDButton onClick={() => {
              if (item.account_uuid) {
                unArchive(item.account_uuid)
              }
            }} style={cysrConfig().button_style} size="medium">
              <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().unarchive.icon.svg }} />
              Unarchive
            </MDButton>
            :
            data.isSubscription ?
              <MDButton onClick={() => {
                // if available set the current account uuuid as selected
                if (item.account_uuid) {
                  console.log("item")
                  console.log(item)
                  localStorage.setItem("cysr_useraccountrole_selected", item.account_uuid)
                  // window.location.href = `/account/${item.account_uuid}/subscription`
                  window.location.href = `/company-subscription#${item.account_uuid}`
                  // window.location.href = `/account/${item.account_uuid}/subscription#${data.isSubscription}`
                }
                // if available set the current account uuuid as selected e
              }} style={cysrConfig().button_style} size="medium">
                Go to Company Subscription
                <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
              </MDButton>
              :
              <>
                <MDButton onClick={() => {
                  // if available set the current account uuuid as selected
                  if (item.account_uuid) {
                    localStorage.setItem("cysr_useraccountrole_selected", item.account_uuid)
                    window.location.href = `/third-party/${item.account_uuid}/overview`
                  }
                  // if available set the current account uuuid as selected e
                }}
                  // aggiunto middle click new window
                  onAuxClick={() => {
                    // if available set the current account uuuid as selected
                    if (item.account_uuid) {
                      localStorage.setItem("cysr_useraccountrole_selected", item.account_uuid)
                      window.open(
                        `/third-party/${item.account_uuid}/overview`,
                        '_blank' // <- This is what makes it open in a new window.
                      );
                    }
                    // if available set the current account uuuid as selected e
                  }}
                  style={cysrConfig().button_style} size="medium">
                  Go to Third Party overview
                  <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
                </MDButton>
                <MDButton onClick={() => handleExpand(item)} style={cysrConfig().button_style} size="medium">
                  {ids && ids.includes(item.account_uuid) ? <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} /> : <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />}
                </MDButton>
              </>
          }
        </div>
      ),
      pinRight: true
      // sort: { sortKey: 'RATING' }
    },
  );

  // expandable
  const [ids, setIds] = useState([]);
  let autoString = "";
  let firstAuto = 4;
  if (data.isSubscription) {
    firstAuto = 3;
  }
  COLUMNS.forEach(c => {
    if (firstAuto === 0) {
      autoString += "auto ";
    } else {
      firstAuto--;
    }
  });
  const expandedTableStyle = `
    --data-table-library_grid-template-columns: ${data.isSubscription ? "120px" : "50px auto"} 35% ${autoString} ${actionsPixelWidth}px!important;
    .animate {
      grid-column: 1 / -1;
      display: flex;
    }
    .animate {
        visibility: visible;
        border-bottom: 1px solid #${darkMode ? "4a4545" : "f0f2f5"};
    }
    .animate > div {
      flex: 1;
      display: flex;
    }
    
    @media (min-width: 768px){
      .pin-right {
        right: 0;
      }
    }
  `;

  const handleExpand = (item) => {
    if (ids.includes(item.account_uuid)) {
      setIds(ids.filter((id) => id !== item.account_uuid));
    } else {
      setIds(ids.concat(item.account_uuid));
    }
  };

  function tableDetailsRow(sid, title, value, formatValue = false) {
    return (
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        {
          // <MDTypography variant="body2" color="text"> 
        }
        <MDTypography variant="button" color="text">
          {title}
        </MDTypography>
        <MDBox
          display="flex"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
            <MDTypography variant="button" color="text" fontWeight="regular">
              {formatValue && formatValue === "date" ? (
                new Date(value).toLocaleDateString('it-IT', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })
              ) :
                value
              }
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    )
  }

  const ROW_OPTIONS = {
    renderAfterRow: (item) => (
      <Collapse key={item.account_uuid} className="animate" in={ids.includes(item.account_uuid)}>
        <div style={{ flex: '1', display: 'flex' }}>
          <div style={{ flex: '1', backgroundColor: darkMode ? "#121619" : "#edf0f2" }}>
            <MDBox style={{ position: "sticky", left: 0, right: 0 }} maxWidth={{ xs: "calc(100vw - (48px + 64px))", xl: "calc(100vw - (282px + 48px + 58px))" }}>
              <Card style={{ backgroundColor: darkMode ? "#121619" : "#edf0f2", color: darkMode ? "#7B809A" : "#7B809A", borderRadius: 0, boxShadow: "none", padding: "1em", margin: 0 }}>
                <MDBox width="100%">
                  {/*}
                  {tableDetailsRow(item.account_uuid, "Name", item.name)}
                  <Divider />
                  {tableDetailsRow(item.account_uuid, "Deadline", item.deadline, "date")}
                  <Divider />
                  {tableDetailsRow(item.account_uuid, "Type", item.asset_type)}
                  <Divider />
                  {tableDetailsRow(item.account_uuid, "Completed", item.isComplete.toString())}
                  {*/}
                  <Grid container spacing={3} flex style={{
                    // maxWidth: "1234.5px", paddingLeft: "2em"
                    // maxWidth: "100%"
                    justifyContent: "space-between"
                  }}>
                    <Grid item>
                      <div style={{ display: "flex", gap: "2em" }}>
                        {item && item.account_last_rating_val ? (
                          <AsyncImage alt="Rating" src={`${cysrConfig().image_rating_base_url}${item.account_last_rating_val}.svg`} width="auto" height="96" />
                        ) : (
                          <p>No rating</p>
                        )}
                        <div>
                          <DashboardCompanyInfo useraccountrole={item} nobuttons />
                          <div style={{ display: "flex", flexWrap: "wrap", gap: "8px", paddingTop: "8px", marginTop: "auto" }}>
                            <MDTypography style={{ marginRight: "-8px" }} variant="caption" color="text">Tags:&nbsp;</MDTypography>
                            {item.account_tags && item.account_tags.length && item.account_tags.length > 0 ? item.account_tags.map((t) => <MDBadge style={{ cursor: "pointer" }} key={t} variant="contained" badgeContent={t} size="xs" container onClick={
                              () => {
                                let newTags = [];
                                tableTagsActive && tableTagsActive.forEach(x => {
                                  if (x !== t && x !== "tag:" + t) {
                                    newTags.push(x)
                                  }
                                });
                                newTags.push("tag:" + t)
                                handleSetTableTags(null, newTags)
                              }
                            } />) : null}
                          </div><div style={{ display: "flex", flexWrap: "wrap", gap: "8px", paddingTop: "8px", marginTop: "auto" }}>
                            <MDTypography style={{ marginRight: "-8px" }} variant="caption" color="text">Linked companies:&nbsp;</MDTypography>
                            {
                              // Object.entries(MyObjects);
                              // [ ['key1', 'value 1'], ['key2', 'value 2'] ]
                            }
                            {item.linked_accounts && Object.entries(item.linked_accounts).map((lc) => (
                              <a className="flex" key={lc[0]} href={`/company/${lc[0]}/overview`}>
                                <MDBadge style={{ cursor: "pointer" }} variant="contained" badgeContent={
                                  (lc[1] && lc[1].length > 24) ? lc[1].substr(0, 21) + "\u2026" : lc[1]

                                } size="xs" container />
                              </a>
                            )
                            )}
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1} ml={"auto"} style={{ paddingLeft: 0, width: "auto" }}>
                        <Grid item>
                          <MDButton onClick={() => {
                            // if available set the current account uuuid as selected
                            if (item.account_uuid) {
                              //(?P(v1))/account/(?P<account_req_uuid>/status/(?P([archive|unarchive]))/
                              toggleArchive(item.account_uuid)
                            }
                            // if available set the current account uuuid as selected e
                          }} style={cysrConfig().button_style} size="medium">
                            <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().archive.icon.svg }} />
                            Archive
                          </MDButton>
                        </Grid>
                        {false && [
                          <Grid item>
                            <MDButton onClick={() => {
                              // if available set the current account uuuid as selected
                              if (item.account_uuid) {
                                localStorage.setItem("cysr_useraccountrole_selected", item.account_uuid)
                                window.location.href = `/third-party/${item.account_uuid}/assets`
                              }
                              // if available set the current account uuuid as selected e
                            }} style={cysrConfig().button_style} size="medium">
                              Go to Assets
                              <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
                            </MDButton>
                          </Grid>,
                          <Grid item>
                            <MDButton onClick={() => {
                              // if available set the current account uuuid as selected
                              if (item.account_uuid) {
                                localStorage.setItem("cysr_useraccountrole_selected", item.account_uuid)
                                window.location.href = `/third-party/${item.account_uuid}/checks`
                              }
                              // if available set the current account uuuid as selected e
                            }} style={cysrConfig().button_style} size="medium">
                              Go to Checks
                              <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
                            </MDButton>
                          </Grid>]
                        }
                      </Grid>

                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </MDBox>
          </div>
        </div>
      </Collapse>
    ),
  };

  // filter list
  tData = {
    nodes: tData.nodes.filter((item) => {
      /*
      // check filter first
      if(hideCompleted && item.isComplete){
        return false
      }
      */
      // check tags
      // da fare tags con chiave valore
      if (tableTagsActive && tableTagsActive.length > 0) {
        let trueCount = tableTagsActive.length;
        tableTagsActive.forEach(ta => {
          let notFound = true;
          // X:item.cysrTag s
          if (ta && typeof ta === 'string' && ta.includes(":")) {
            const tag = ta.toLowerCase().split(":");
            const tagKey = tag[0].toLowerCase();
            // const tagValue = tag[1].toString().trim().replace(" ", "");
            const tagValue = tag[1].toString().trim();
            //tag: s
            if (tagKey === "tag" && item.account_tags && item.account_tags.indexOf(tagValue) > -1) {
              if (notFound) {
                trueCount--;
                notFound = false;
              }
            }
            //tag: e
            // linked_accounts s
            if (
              tagKey
              && (tagKey.toLowerCase() === "linked companies"
                || tagKey.toLowerCase() === "linked-companies"
                || tagKey.toLowerCase() === "linked_companies"
              )
              && item.linked_accounts
              && (
                ((Object.values(item.linked_accounts)).map((l) => l.toLowerCase()).indexOf(tagValue.toLowerCase()) > -1)
                || ((Object.keys(item.linked_accounts)).map((l) => l.toLowerCase()).indexOf(tagValue.toLowerCase()) > -1)
              )
            ) {
              if (notFound) {
                trueCount--;
                notFound = false;
              }
            }
            // linked_accounts e
            COLUMNS.forEach(col => {
              if (Array.isArray(col.cysrTag) === false) {
                if (col.cysrTag && item[col.cysrTag] && col.label.toLowerCase() === tagKey && item[col.cysrTag.toLowerCase()] && item[col.cysrTag.toLowerCase()]) {
                  // check cysrTagDateFormat
                  if (col.cysrTagDateFormat) {
                    const vd = new Date(item[col.cysrTag.toLowerCase()].toString()).toLocaleDateString(col.cysrTagDateFormat, {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })
                    if (vd.toString().includes(tagValue) && notFound) {
                      trueCount--;
                      notFound = false;
                    }
                  } else if (item[col.cysrTag.toLowerCase()].toString().toLowerCase().includes(tagValue)) {
                    if (notFound) {
                      trueCount--;
                      notFound = false;
                    }
                  }
                }
              } else {
                col.cysrTag.forEach(cT => {
                  if (cT && item[cT] && col.label.toLowerCase() === tagKey && item[cT.toLowerCase()]) {
                    // check cysrTagDateFormat
                    if (col.cysrTagDateFormat) {
                      const vd = new Date(item[cT.toLowerCase()].toString()).toLocaleDateString(col.cysrTagDateFormat, {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })
                      if (vd.toString().includes(tagValue) && notFound) {
                        trueCount--;
                        notFound = false;
                      }
                    } else if (item[cT.toLowerCase()].toString().toLowerCase().includes(tagValue)) {
                      if (notFound) {
                        trueCount--;
                        notFound = false;
                      }
                    } else if (
                      // item.account_vat_country_code === "XX" ? "other"
                      cT === "account_vat_country_code"
                      && item[cT.toLowerCase()].toString().toLowerCase() === "xx"
                      && "other".includes(tagValue)) {
                      if (notFound) {
                        trueCount--;
                        notFound = false;
                      }
                    } else if (
                      // item.account_vat_country_code === "XX" ? "other"
                      cT === "account_data_national_id"
                      && item[cT.toLowerCase()].toString().toLowerCase().includes(tagValue.toLowerCase().replace(item.account_vat_country_code.toLowerCase(), ""))) {
                      if (notFound) {
                        trueCount--;
                        notFound = false;
                      }
                    }
                  }
                })
              }
            })
          }
          // X:item.cysrTag e
          // compare (for freeSolo) s
          [
            "account_oldest_check_time_on_manual_asset",
            "account_name",
            "asset_type",
            "account_data_national_id",
            "account_vat_name",
            "account_vat_country_code",
            "contractor_subscription",
            "mu_created_at",
          ].forEach(property => {
            if (ta && ta[property]) {
              // a default option (with [property])
              if (ta && ta[property] && item[property] && item[property].toLowerCase().includes(ta[property].toLowerCase())) {
                if (notFound) {
                  trueCount--;
                  notFound = false;
                }
              }
            } else {
              // not a default option (with [property])
              if (ta && item[property] && item[property].toLowerCase().includes(ta.toLowerCase())) {
                if (notFound) {
                  trueCount--;
                  notFound = false;
                }
              } else if (
                // item.account_vat_country_code === "XX" ? "other"
                property === "account_vat_country_code" && ta && item[property] && item[property].toLowerCase() && item[property].toLowerCase() === "xx" && "other".includes(ta.toLowerCase())
              ) {
                if (notFound) {
                  trueCount--;
                  notFound = false;
                }
              }
            }
          });
          // compare (for freeSolo) e
        });
        if (trueCount !== 0) {
          return false;
        }
      }
      // check search at last
      // ..
      return true;
    }
    ),
  };

  const materialTheme = getTheme(DEFAULT_OPTIONS, {
    isVirtualized: true
  });

  // to set row border bottom style:
  // border-bottom: 1px solid #${darkMode ? "4a4545" : "f0f2f5"};

  const customTheme = {
    Table: expandedTableStyle,
    BaseCell: `
      border: 0!important;
      padding: 4px;
    `,
  };
  let theme = useTheme([materialTheme, customTheme]);
  if (darkMode) {
    // provide custom theme
    theme = useTheme([materialTheme, {
      Table: expandedTableStyle,
      BaseCell: `
        color: #ffffff;
        background-color: #192328;
        border: 0!important;
        padding: 4px;
      `,
    }
    ]);
  }

  const pagination = usePagination(tData, {
    state: {
      page: 0,
      // size: 2,
      // size: tData.nodes.length,
      size: tableRowsPerPage[0],
    },
    onChange: onPaginationChange,
  });

  function onPaginationChange(action, state) {
    console.log(action, state);
  }

  const sort = useSort(
    tData,
    {
      state: {
        sortKey: 'NAME',
        reverse: false
      },
      onChange: onSortChange,
    },
    {
      sortIcon: {
        iconDefault: null,
        iconUp: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
        </svg>
        ,
        iconDown: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
        ,
      },
      sortFns: {
        /*
        RATING: (array) => array.sort((a, b) =>
          (a.account_last_rating_val ? a.account_last_rating_val : 0) - (b.account_last_rating_val ? b.account_last_rating_val : 0)
        ),
        */
        RATING: (array) => array.sort(function (a, b) {
          let aR = a.account_last_rating_val ? a.account_last_rating_val : "N/A";
          let bR = b.account_last_rating_val ? b.account_last_rating_val : "N/A";
          if (!aR || aR === null || aR === undefined || aR === "" || aR === "N/A") {
            aR = 101;
          }
          if (!bR || bR === null || bR === undefined || bR === "" || bR === "N/A") {
            bR = 101;
          }
          return aR - bR
        }),
        CREATED: (array) => array.sort((a, b) =>
          new Date(b.mu_created_at).getTime() - new Date(a.mu_created_at).getTime()
        ),
        LAST_CHECK: (array) => array.sort((a, b) =>
          new Date(b.account_oldest_check_time_on_manual_asset).getTime() - new Date(a.account_oldest_check_time_on_manual_asset).getTime()
        ),
        NAME: (array) => array.sort((a, b) => a.account_name.localeCompare(b.account_name)),
        TYPE: (array) => array.sort((a, b) => a.asset_type.localeCompare(b.asset_type)),
        VAT: (array) => array.sort((a, b) => a.account_data_national_id.localeCompare(b.account_data_national_id)),
        SUBSCRIPTION: (array) => array.sort((a, b) => a.contractor_subscription.localeCompare(b.contractor_subscription)),
        LINKED_ACCOUNTS: (array) => array.sort((a, b) => {
          const aVal = (Object.values(a.linked_accounts)
            ? Object.values(a.linked_accounts).length > 1
              ? Object.values(a.linked_accounts).length
              : Object.values(a.linked_accounts)[0]
            : 0);
          const bVal = (Object.values(b.linked_accounts) ?
            Object.values(b.linked_accounts).length > 1
              ? Object.values(b.linked_accounts).length
              : Object.values(b.linked_accounts)[0]
            : 0);
          if (Number.isInteger(aVal) && Number.isInteger(bVal)) {
            return aVal - bVal;
          } else if (Number.isInteger(aVal)) {
            return -1;
          } else if (Number.isInteger(bVal)) {
            return +1;
          } else {
            return aVal.localeCompare(bVal)
          }
        })
      },
    },
  );

  function onSortChange(action, state) {
    console.log(action, state);
  }

  const handleSetTableTags = (event, t) => {
    // console.log(t)
    setTableTagsActive(t);
    // replace url s
    let params = new URLSearchParams(window.location.search)
    params.delete('t')
    if (t != "" && t.length > 0) {
      params.append("t", Base64.encode(JSON.stringify(t)))
    }
    window.history.replaceState(null, '', '?' + params + window.location.hash)
    // replace url e
    // go to first page
    pagination.fns.onSetPage(0)
  };
  const handleSearch = (event) => {
    const s = event.target.value;
    setSearch(s);
    // replace url s
    let params = new URLSearchParams(window.location.search)
    params.delete('s')
    if (s != "") {
      params.append("s", s)
    }
    window.history.replaceState(null, '', '?' + params + window.location.hash)
    // replace url e
  };
  const handleSetHideCompleted = (event) => {
    const c = event.target.checked;
    setHideCompleted(c);
    // replace url s
    let params = new URLSearchParams(window.location.search)
    params.delete('hc')
    if (c) {
      params.append("hc", "true")
    }
    window.history.replaceState(null, '', '?' + params + window.location.hash)
    // replace url e
  };

  const VIRTUALIZED_OPTIONS = {
    rowHeight: (_item, _index) => {
      return 54
    }
  };

  return (
    <>
      <Stack spacing={10} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CysrTags tableTagsOptions={tableTagsOptions} tableTagsActive={tableTagsActive} handleSetTableTags={handleSetTableTags} darkMode={darkMode} />
          </Grid>
        </Grid>
      </Stack>
      <Paper style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none', height: 'auto' }}>
        {
          // <Paper style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none', height: '360px' }}>
        }
        <CompactTable
          columns={COLUMNS}
          // passando le virtualizedOptions non funziona l'expand abilitato, penso perchè imposta l'height da solo
          // virtualizedOptions={VIRTUALIZED_OPTIONS}
          data={tData}
          sort={sort}
          theme={theme}
          rowOptions={ROW_OPTIONS}
          layout={{
            isDiv: true,
            fixedHeader: true
          }}
          pagination={pagination}
          style={{ borderColor: 'transparent' }}
        // horizontalScroll={true}
        />
      </Paper>
      <Stack spacing={10}>
        <TablePagination
          component="div"
          style={{ color: darkMode ? "#ffffff" : "#000000", border: 0 }}
          count={tData.nodes.length}
          page={pagination.state.page}
          rowsPerPage={pagination.state.size}
          rowsPerPageOptions={tableRowsPerPage}
          onRowsPerPageChange={(event) =>
            pagination.fns.onSetSize(parseInt(event.target.value, 10))
          }
          onPageChange={(event, page) => pagination.fns.onSetPage(page)}
        />
      </Stack>
    </>
  );
};
export default Component;
/* eslint-disable */