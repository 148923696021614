/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
// prettier-ignore
import { Base64 } from 'js-base64';
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
import GroupedSelect from "layouts/cysr/components/CysrGroupedSelect";
// import Footer from "examples/Footer";
import Card from "@mui/material/Card";

// +

import { Separator } from "layouts/cysr/components/ui/separator";
import {
  Card as CardIn,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "layouts/cysr/components/ui/card";

import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "layouts/cysr/components/ui/accordion";

import ScoreItem from "layouts/cysr/components/ScoreItem";
// -

import InfoButton from "layouts/cysr/components/infoButton";

import cysrConfig, { process } from "layouts/cysr/config";

import CysrTableKi from "layouts/cysr/components/CysrTable/botnets";
import Loader from "layouts/cysr/components/Loader";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import MDSnackbar from "components/MDSnackbar";
// cti s
import { DataTable } from "layouts/cysr/components/cti/components/data-table";
// cti e
// dpo s
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { useMaterialUIController } from "context";
// dpo e
import { Icons } from "layouts/cysr/components/Icons";

import { Checkbox } from "layouts/cysr/components/ui/checkbox";
import { Badge } from "layouts/cysr/components/ui/badge";
import { DataTableColumnHeader } from "layouts/cysr/components/cti/components/data-table-column-header";
import { DataTableRowExpand } from "layouts/cysr/components/cti/components/data-table-row-expand";

import CYSRHeatmap from "layouts/cysr/components/heatmap";
import CYSRBubbles from "layouts/cysr/components/bubbles";
import { differenceInYears, format } from "date-fns";

import { useSnackbar } from 'notistack';
import MDButton from "components/MDButton";

export const columns = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      (row.original.dpo_approved && row.original.status != "acknowledged") ?
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
          className="translate-y-[2px] bg-white dark:bg-black"
        /> :
        <svg className="opacity-50" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 2H13.5C13.7761 2 14 2.22386 14 2.5V7H1V2.5C1 2.22386 1.22386 2 1.5 2ZM1 8V12.5C1 12.7761 1.22386 13 1.5 13H13.5C13.7761 13 14 12.7761 14 12.5V8H1ZM0 2.5C0 1.67157 0.671573 1 1.5 1H13.5C14.3284 1 15 1.67157 15 2.5V12.5C15 13.3284 14.3284 14 13.5 14H1.5C0.671573 14 0 13.3284 0 12.5V2.5Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => {
      const status = [
        {
          value: "acknowledged",
          label: "Acknowledged",
          icon: Icons.acknowledged,
        },
        {
          value: "new",
          label: "To check",
          icon: Icons.new,
        },
      ].find(
        (status) => status.value === row.getValue("status")
      );

      if (!status) {
        return null;
      }

      return (
        <div className="w-4 relative">
          {status.icon && (
            <status.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span className="whitespace-nowrap absolute ml-2 left-4 bottom-0 top-0 flex gap-2 items-center">
            {
              status.value === "acknowledged" ? "ACK" : "To check"
            }
          </span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      const statusValue = row.original.status;
      return value.includes(statusValue);
    },
    enableSorting: true,
    enableHiding: false,
    // hidden in the state default, at row 44 di data-table.tsx:
    /*
    React.useState<VisibilityState>({
      // here set default visibility of columns!
      status: false,
    });
    */
  },
  {
    accessorKey: "user",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Username" />
    ),
    cell: ({ row }) => (
      <div
        className={`whitespace-nowrap ${process.env.NEXT_PUBLIC_CLASSES_ASSET_SOURCE}`}
      >
        {row.getValue("user")
          ? row.getValue("user")
          : process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE}
      </div>
    ),
    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: "passwordtype",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="whitespace-nowrap"
        column={column}
        title="Password Encoding"
      />
    ),
    cell: ({ row }) =>
      row.getValue("passwordtype") ? (
        <Badge className="whitespace-nowrap" variant="outline">
          {row.getValue("passwordtype")}
        </Badge>
      ) : (
        process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE
      ),
    filterFn: (row, id, value) => {
      const labelType = row.original.passwordtype; /*
        ? process.env.NEXT_PUBLIC_STRING_TERM_300_SINGLE
        : process.env.NEXT_PUBLIC_STRING_TERM_100_SINGLE
        */
      return value.includes(labelType);
    },
    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: "threat_type",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Category" />
    ),
    cell: ({ row }) =>
      row.getValue("threat_type") ? (
        row.getValue("threat_type").map((v) =>
          <Badge className="whitespace-nowrap" variant="outline">
            {v}
          </Badge>
        )
      ) : (
        process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE
      ),
    filterFn: (row, id, value) => {
      const labelType = row.original.threat_type;
      /*
        ? process.env.NEXT_PUBLIC_STRING_TERM_300_SINGLE
        : process.env.NEXT_PUBLIC_STRING_TERM_100_SINGLE
        */
      return labelType.indexOf(value[0]) > - 1;
      ///return value.includes(labelType);
    },
    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: "date",
    header: ({ column }) => {
      return (
        <DataTableColumnHeader
          className="whitespace-nowrap"
          column={column}
          title="Earliest found date"
        />
      );
    },
    cell: ({ row }) => {
      return (
        <div className="whitespace-nowrap">
          {row.getValue("date")
            ? (format(
              new Date(row.getValue("date")),
              process.env.NEXT_PUBLIC_DATE_FORMAT
                ? process.env.NEXT_PUBLIC_DATE_FORMAT
                : "dd/MM/yyyy"
            ) && format(
              new Date(row.getValue("date")),
              process.env.NEXT_PUBLIC_DATE_FORMAT
                ? process.env.NEXT_PUBLIC_DATE_FORMAT
                : "dd/MM/yyyy"
            ) !== "01/01/1970" ? format(
              new Date(row.getValue("date")),
              process.env.NEXT_PUBLIC_DATE_FORMAT
                ? process.env.NEXT_PUBLIC_DATE_FORMAT
                : "dd/MM/yyyy"
            ) : process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE)
            : process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE}
        </div>
      );
    },
  },
  {
    accessorKey: "domain",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Domain" />
    ),
    cell: ({ row }) => {
      return row.original.ki_q;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.ki_q);
    },
    enableSorting: true,
    enableHiding: false,
    // hidden in the state default, at row 44 di data-table.tsx:
    /*
    React.useState<VisibilityState>({
      // here set default visibility of columns!
      domain: false,
    });
    */
  },
  {
    id: "action",
    cell: ({ row }) => (
      <div className="flex gap-2 justify-end">
        <DataTableRowExpand row={row} />
      </div>
    ),
  },
];

function Botnets() {
  //snackBar s
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // add action to an individual snackbar
  const action = snackbarId => (
    <>
      <MDButton onClick={() => {
        window.location.reload()
        closeSnackbar(snackbarId)
      }} style={cysrConfig().button_style} size="medium">
        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().close.icon.svg }} />
        Dismiss and reload page
      </MDButton>
    </>
  );
  //snackBar e

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const timeshift = null;
  const accountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/company/") + "/company/".length,
    window.location.pathname.lastIndexOf("/")
  );
  // mdsnack s
  const [show, setShow] = useState(false);
  const [openDPO, setOpenDPO] = useState(false);
  const [msgDateTime, setMsgDateTime] = useState("Information");
  const [msgIcon, setMsgIcon] = useState("info");
  const [msgTheme, setMsgTheme] = useState("light");
  const [msgTitle, setMsgTitle] = useState("Recheck not executed");
  const [msgContent, setMsgContent] = useState(
    <></>
  )
  const defaultToggleSnackbar = () => {
    setShow(!show);
  }
  function toggleSnackbar(title = "Error", msg = "Error", theme = "error", icon = "error", dateTime = "Error") {
    setMsgDateTime(dateTime);
    setMsgIcon(icon);
    setMsgTheme(theme);
    setMsgTitle(title);
    setMsgContent(msg);
    setShow(true);
  }
  // mdsnack e
  // filter test s
  const params = new URLSearchParams(window.location.search);
  let activeTags = [];
  try {
    activeTags = params.get('t') && (decodeURI(params.get('t'))) && Base64.decode(decodeURI(params.get('t'))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('t')))) : [];
  } catch (error) {
    console.error("Error in setting active tags:")
    console.error(error)
  }
  // filter test e

  // form D.P.O. s

  // form D.P.O. s
  const profileFormSchema = z.object({
    email: z
      .string({
        required_error: "Please enter a valid email to display.",
      })
      .email(),
    nominative: z
      .string({
        required_error: "Nominative is required.",
      })
      .min(2, {
        message: "Nominative must be at least 2 characters.",
      }),
  });
  // This can come from your database or API.
  const defaultValues = {
    email: "",
    nominative: "",
  };
  // form D.P.O. e

  const [DPOData, setDPOData] = useState(null);

  const form = useForm({
    resolver: zodResolver(profileFormSchema),
    defaultValues,
    mode: "onChange",
  });
  function onSubmit(data) {
    fetch(`${cysrConfig().api_base_url}account/${accountUUID}/dpo/ask`, {
      body: JSON.stringify({
        validator_email: data.email,
        validator_name: data.nominative,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      method: "POST"
    })
      .then((r) => {
        if (r.ok) {
          // window.location.reload();
          return r.json();
        } else {
          console.error(r);
          return r.json().then(err => {
            throw JSON.stringify(err)
          })
          return r.json()
        }
      })
      .then((response) => {
        enqueueSnackbar(
          <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <MDTypography variant="caption" style={{ fontWeight: "600" }}>Request Sent</MDTypography>
            <MDTypography variant="caption">Your authorization request has been submitted. The cybersecurity threat data will become visible once the Data Protection Officer (DPO) has confirmed the authorization.
              Thank you for your request.</MDTypography>
          </div>, {
          action,
          variant: 'success',
          persist: true
        }
        )
        // window.location.reload()
      }
      ).catch((err) => {
        console.log(err);
        console.log("err3:", err);
        if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
          toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
        } else if (JSON.parse(err) && JSON.parse(err).error) {
          toggleSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
        } else {
          toggleSnackbar("Error", "Some error happened", "error", "error", "Error");
        }
        setOpenDPO(false)
        setDPOData(null)
      })
  }
  // form D.P.O. e
  const [ctiStatus, setCtiStatus] = useState("new");
  const [tabValue, setTabValue] = useState(0);
  const [selectedSub, setSelectedSub] = useState(0);
  const [selectedKey, setSelectedKey] = useState(0);
  const [tabsSwitchingLoading, setTabsSwitchingLoading] = useState(false);
  const [lastTimeuuid, setSelectedLastTimeuuid] = useState(0);
  const [kiData, setKiData] = useState(Array());
  const tabsSwapper = [
    {
      id: "new",
      label: "New"
    },
    {
      id: "ack",
      label: "Acknowledged"
    },
    {
      id: "all",
      label: "All"
    }
  ];
  function selectCallback(key) {
    setTabsSwitchingLoading(true)
    // console.log("selectedSub")
    // console.log(selectedSub)
    // console.log("selectedKey")
    // console.log(selectedKey)
    // console.log("key")
    // console.log(key)
    // do new call with the ctiStatus s

    // it will be like:
    // fetch(`${cysrConfig().api_base_url}cti/${accountUUID}/list/${ctiStatus}/`, {

    fetch(`${cysrConfig().api_base_url}cti/${selectedSub}/botnet/list/${ctiStatus}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      },
    })
      .then((response) => response.json())
      .then((rSD) => {
        if (rSD && rSD[key]) {
          // console.log("rSD)")
          // console.log(rSD)
          setKiData(rSD[key].data)
          setSelectedLastTimeuuid(rSD[key].last_timeuuid)
        } else {
          // reset s
          // se servisse un reset decommentare:
          // setKiData([])
          // setSelectedLastTimeuuid(0)
          // reset e
          // console.warn(`${cysrConfig().api_base_url}cti/${selectedSub}/botnet/list/${ctiStatus}/`)
          // console.log("rSD)")
          // console.log(rSD)
        }
        setTabsSwitchingLoading(false)
        // console.log("kiData")
        // console.log(kiData)
      })
      .catch((err) => console.error(err));
    // do new call with the ctiStatus e
  }
  const tabHandler = (event, newValue) => {
    setTabsSwitchingLoading(true)
    setTabValue(newValue);
    setCtiStatus(tabsSwapper[newValue].id)
    // console.warn("tabHandler")
    // console.warn(selectedSub)
    // do new call with the ctiStatus s
    fetch(`${cysrConfig().api_base_url}cti/${selectedSub}/botnet/list/${tabsSwapper[newValue].id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      },
    })
      .then((response) => response.json())
      .then((rSD) => {
        // console.log("rSD)")
        // console.log(rSD)
        if (rSD && rSD[selectedKey]) {
          setKiData(rSD[selectedKey].data)
          setSelectedLastTimeuuid(rSD[selectedKey].last_timeuuid)
        }
        setTabsSwitchingLoading(false)
        // console.log("kiData")
        // console.log(kiData)
      })
      .catch((err) => console.error(err));
    // do new call with the ctiStatus e
  };

  // Our State.
  const [views, setViews] = useState([]);
  // Tutti gli asset di un account (/asset/) senza UUID?
  if (localStorage.getItem("cysr_useraccountroles") === null) {
    window.location.href = "/cysr/login";
  }

  const getCallUrl = `${cysrConfig().api_base_url}cti/${accountUUID}/`;
  useEffect(() => {
    const pageTitle = "Botnets";
    document.title = `${pageTitle} - ${document.title.split(" - ")[1] ? document.title.split(" - ")[1] : document.title}`;
    if (localStorage.getItem("cysr_useraccountroles")) {
      //let groupSelectData = [];
      // call table of asset + call table of checks
      fetch(getCallUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
      })
        .then((response) => response.json())
        .then((ctiResponse) => {
          console.log('ctiResponse');
          console.log(ctiResponse);
          const domains = ctiResponse.details;
          const summary = ctiResponse.summary;
          let tempLeaks = [];
          for (const key in domains) {
            if (Object.hasOwnProperty.call(domains, key)) {
              const d = domains[key];
              d.forEach(element => {
                if (element.domain) {
                  /*
                  NEW
                  {
                      "username": "sonia.dilillo@eng.it",
                      "password": "ada-+-+-+",
                      "password_status": "plain",
                      "leak_date": "2012-05-01",
                      "leak_source": [
                          "LinkedIn.com"
                      ],
                      "threat_type": [
                          "leak"
                      ],
                      "domain": "eng.it",
                      "period": "4Y_OLD",
                      "ack_status": "NO_ACK"
                  }
                  OLD
                    {
                      status: "new",
                      ki_q: "klecha-co.com",
                      ki_timeuuid: "8eb0fd92-b6f3-11ed-ab12-855235362899",
                      added: "2023-03-01T14:00:31.136",
                      bucket: "leaks.logs",
                      threat_type: "botnet",
                      date: "2023-02-27T23:07:50.562",
                      key: "fabiola.pellegrini@klecha-co.com",
                      password: "ada++Juliette089xz1104w2",
                      passwordtype: "Plaintext",
                      sourcelong:
                        "PLBB4BA64C1E0A18C27604CE9EB57210F8_2023_01_10T11_14_01_300249.rar/FileGrabber/Users/Admin/Desktop/gry/inne/X/X/results/[11-02-2022 11-03-08]/Badline.txt",
                      sourceshort:
                        "PLBB4BA64C1E0A18C27604CE9EB57210F8_2023_01_10T11_14_01_300249.rar",
                      systemid: "9be61980-c2bd-4c46-b9ec-812ab48cb0b7",
                      user: "fabiola.pellegrini@klecha-co.com",
                    },
                  */
                  element.status = element.ack_status && element.ack_status == "ACK" ? "acknowledged" : "new";
                  element.ki_q = element.domain;
                  element.key = element.username;

                  element.sourcelong = (element.leak_source && element.leak_source.length > 0) ? element.leak_source : false;

                  element.date = element.leak_date;

                  element.threat_type = element.threat_type;
                  element.passwordtype = element.password_status;
                  element.user = element.username
                  tempLeaks.push(element)
                }
              });
            }
          }
          let bubblesDatasets = [
            {
              label: 'New Botnets',
              data: [],
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
              label: 'Acknowledged Botnets',
              data: [],
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
              label: 'New Leaks',
              data: [],
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
              label: 'Acknowledged Leaks',
              data: [],
              backgroundColor: 'rgba(53, 162, 235, 0.5)',

            }
          ]
          let bubbleDates = [];
          const skipDatesOlderThanYearsAmount = 4;
          for (const key in summary.bouble_graph) {
            if (Object.hasOwnProperty.call(summary.bouble_graph, key)) {
              const element = summary.bouble_graph[key];
              element.data.forEach(n => {
                if (differenceInYears(new Date(), new Date(n.date)) < skipDatesOlderThanYearsAmount) {
                  let newItem = {}
                  let newDate = format(
                    new Date(n.date),
                    process.env.NEXT_PUBLIC_DATE_FORMAT
                      ? process.env.NEXT_PUBLIC_DATE_FORMAT
                      : "dd/MM/yyyy"
                  )
                  newItem.x = newDate;
                  // newItem.y = n.label;
                  newItem.label = n.label;
                  // newItem.y = n.gravity;
                  newItem.r = n.value / 16 + 4;
                  if (bubbleDates.indexOf(newDate) > -1) {
                    // already in
                  } else {
                    bubbleDates.push(newDate)
                  }
                  // gravity 5 -> ACK / 10 -> NO_ACK
                  if (n.gravity === 10 && n.label === "botnet") {
                    // New Botnets
                    bubblesDatasets[0].data.push(newItem)
                    newItem.y = 40
                  } else if (n.gravity === 5 && n.label === "botnet") {
                    // Acknowledged Botnets
                    bubblesDatasets[1].data.push(newItem)
                    newItem.y = 30
                  } else if (n.gravity === 5 && n.label === "leak") {
                    // Acknowledged Leaks
                    bubblesDatasets[3].data.push(newItem)
                    newItem.y = 10
                  } else {
                    // New Leaks
                    bubblesDatasets[2].data.push(newItem)
                    newItem.y = 20
                  }
                }
              });
            }
          }
          setViews({
            // surveillance: responseSurveillanceData,
            //groupSelectData: removedEmptyGroupSelectData,
            ctiList: tempLeaks,
            ctiDomains: summary.domain_status ? Object.entries(summary.domain_status) : [],
            ctiSummary: summary,
            ctiRating: ctiResponse.rating,
            ctiDPOInfo: ctiResponse.dpo,
            // ctiBubblesData: summary.bouble_graph,
            ctiBubblesData: {
              labels: bubbleDates.sort(),
              datasets: bubblesDatasets
            },
            cysr_tags_active: activeTags
          });
        })

        .catch((err) => console.error(err));
    } else {
      setViews({});
    }
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar cysr_company cti={
        {
          domains: views.ctiDomains,
          rating: views.ctiRating,
          dpo: views.ctiDPOInfo,
          summary: views.ctiSummary,
        }
      } dpo={{ DPOData: DPOData, setDPOData: setDPOData, form: form, onSubmit: onSubmit }} />
      <>
        <div className={`${darkMode ? "dark " : ""}w-full`}>
          <div className="flex flex-col w-full gap-4 mb-4">
            <CardIn className="w-full rounded-md">
              <CardHeader>
                <CardTitle>Botnets and leaks</CardTitle>
                <CardDescription>
                  This bubble chart illustrates the leaks and botnets discovered over time, with the size of each bubble representing the quantity of information found. It also shows the information that has been acknowledged by the platform's user.
                </CardDescription>
              </CardHeader>
              <CardContent>
                {(views && views.ctiList && tabsSwitchingLoading === false) && (
                  <MDBox pb={2} px={1} fullWidth>
                    <CYSRBubbles data={views.ctiBubblesData} />
                  </MDBox>
                )}</CardContent>
            </CardIn>
          </div>

          <div className="flex flex-col md:flex-row w-full gap-4 mb-4">
            <CardIn className="w-full md:w-2/3 rounded-md">
              <CardHeader>
                <CardTitle>Number of leaks and botnets per period</CardTitle>
                <CardDescription>
                  Leaks counted by period and percentage of those acknowledged
                </CardDescription>
              </CardHeader>
              <CardContent>
                {(views && views.ctiSummary && views.ctiSummary.timeframe_boxes && tabsSwitchingLoading === false) && (
                  <MDBox pb={2} px={1} fullWidth>
                    <table class="risk-table">
                      <thead>
                        <tr>
                          <th>Period</th>
                          <th>Leaks</th>
                          <th>Botnets</th>
                          <th>Total</th>
                          <th>Acknowledged</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(views.ctiSummary.timeframe_boxes).sort((a, b) => {
                          /*
                          let pA = 0;
                          let pB = 0;

                          switch (a[0]) {
                            case "3y+":
                              pA = 4
                              break;
                            case "1y-3y":
                              pA = 3
                              break;
                            case "6m-1y":
                              pA = 2
                              break;
                            case "0d-6m":
                              pA = 1
                              break;

                            default:
                              break;
                          }
                          switch (b[0]) {
                            case "3y+":
                              pB = 4
                              break;
                            case "1y-3y":
                              pB = 3
                              break;
                            case "6m-1y":
                              pB = 2
                              break;
                            case "0d-6m":
                              pB = 1
                              break;

                            default:
                              break;
                          }
                          */
                          return a.order - b.order
                        }).map(
                          (x) =>
                          (
                            <tr>
                              <td>{x.descr === "All" ? "All time" : x.descr}</td>
                              <td>{x.leak ? x.leak : "N/A"}</td>
                              <td>{x.botnet ? x.botnet : "N/A"}</td>
                              <td>{x.tot}</td>
                              <td>{x.ACK ? x.ACK : "0"} {x.ACK_percent ? `(${x.ACK_percent.toFixed(2)}%)` : "(0.00%)"}</td>
                            </tr>
                          ))}</tbody></table>
                  </MDBox>
                )}</CardContent>
            </CardIn>

            <CardIn className="w-full md:w-1/3 rounded-md">
              <CardHeader>
                <CardTitle>Top 5 users leaked</CardTitle>
                <CardDescription>List of top 5 leaked users with different passwords
                </CardDescription>
              </CardHeader>
              <CardContent>
                {(views && views.ctiSummary && views.ctiSummary.top_leaked_users && tabsSwitchingLoading === false) && (
                  <MDBox pb={2} px={1} fullWidth>
                    <table class="risk-table">
                      <thead>
                        <tr>
                          <th>Username</th>
                          <th>Leaks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {views.ctiSummary.top_leaked_users && views.ctiSummary.top_leaked_users[0] && (views.ctiSummary.top_leaked_users).map(
                          (x) =>
                          (
                            <tr>
                              <td>{x[1]}</td>
                              <td>{x[0]}</td>
                            </tr>
                          ))}</tbody></table>

                  </MDBox>
                )}</CardContent>
            </CardIn>
          </div>

          {false && <div className="flex flex-col w-full gap-4 mb-4">
            <CardIn className="w-full rounded-md">
              <CardHeader>
                <CardTitle>HEATMAP</CardTitle>
                <CardDescription>
                  DESC??.
                </CardDescription>
              </CardHeader>
              <CardContent>
                {(views && views.ctiSummary && views.ctiSummary.heatmap && tabsSwitchingLoading === false) && (
                  <MDBox pb={2} px={1} fullWidth>
                    <CYSRHeatmap data={views.ctiSummary.heatmap} />
                  </MDBox>
                )}</CardContent>
            </CardIn>
          </div>
          }

          {(views && views.ctiList) ? (

            <CardIn className="w-full rounded-md">
              <CardHeader>
                <CardTitle>Leaks and botnets details</CardTitle>

              </CardHeader>
              <CardContent>
                <div className={`${darkMode ? "dark" : "light"} cti-table text-turquoise-950 dark:text-turquoise-50 h-full flex-1 flex-col space-y-8 flex`}>

                  <DataTable data={
                    views.ctiList
                  } columns={columns} darkMode={darkMode} />

                </div>
              </CardContent>
            </CardIn>

          ) : <Loader />}
        </div>

        <MDSnackbar
          color={msgTheme}
          icon={msgIcon}
          title={msgTitle}
          content={msgContent}
          dateTime={msgDateTime}
          open={show}
          close={defaultToggleSnackbar}
        />
      </>
    </DashboardLayout >
  )
}

export default Botnets;

/* eslint-disable */
// prettier-ignore
