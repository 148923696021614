/* eslint-disable */
// prettier-ignore
import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { process } from "layouts/cysr/config";
import { format } from "date-fns";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


export function HorizontalBars({ datasets, labels, selectedDays, darkMode }) {
    const options = {
        barThickness: 10,
        // this keep both bars on same row s
        grouped: false,
        // this keep both bars on same row e
        // this removes x axis -100 ... 100 s
        scales: {
            x: {
                position: "top",
                ticks: {
                    // display: false,
                    align: "center",
                    font: {
                        size: 16,
                        weight: 500,
                        family: 'Ubuntu'
                    },
                    color: darkMode ? '#fff' : "#000",
                    maxRotation: 0,
                    padding: 0,
                    callback: function (value, index, ticks) {
                        return value === 40
                            ? selectedDays && selectedDays[1] ? format(new Date(selectedDays[1].toString()), process.env.NEXT_PUBLIC_DATE_FORMAT
                                ? process.env.NEXT_PUBLIC_DATE_FORMAT
                                : "dd/MM/yyyy") : "unselected"
                            : value === -40
                                ? selectedDays && selectedDays[0] ? format(new Date(selectedDays[0].toString()), process.env.NEXT_PUBLIC_DATE_FORMAT
                                    ? process.env.NEXT_PUBLIC_DATE_FORMAT
                                    : "dd/MM/yyyy") : "unselected"
                                : "";
                    },
                },
            },
        },
        // this removes x axis -100 ... 100 e
        indexAxis: "y",
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
                // position: "right" as const,
            },
            title: {
                display: false,
                // text: "Chart.js Horizontal Bar Chart",
            },
            tooltip: {
                callbacks: {
                    label: () => ""
                }
            },
        },
    };
    return (
        <Bar
            options={options}
            data={{
                labels,
                datasets: datasets,
            }}
        />
    );
}
