/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Base64 } from 'js-base64';
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import DataTable from "layouts/cysr/components/DataTable";
import Loader from "layouts/cysr/components/Loader";
// Data
// import checksData from "layouts/cysr/components/checksData";

import CysrTableIssues from "layouts/cysr/components/CysrTable/issues";
import { useLocation } from "react-router-dom";

import cysrConfig from "layouts/cysr/config";
// ms
const App = function () {
    // filter test s
    const params = new URLSearchParams(window.location.search);
    let activeTags = [];
    try {
        activeTags = params.get('ti') && (decodeURI(params.get('ti'))) && Base64.decode(decodeURI(params.get('ti'))) && JSON.parse(Base64.decode(decodeURI(params.get('ti')))) && JSON.parse(Base64.decode(decodeURI(params.get('ti')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('ti')))) : [];
    } catch (error) {
        console.error("Error in setting active tags:")
        console.error(error)
    }
    // filter test e
    const [isLoading, setLoading] = useState(true);
    const location = useLocation();
    // Our State.
    const [views, setViews] = useState([]);
    // const accountUUID = "8c75a44b-0faa-4e83-9bef-4ee47d21883a";
    // const accountUUID = window.location.pathname.substring(window.location.pathname.indexOf(":") + 1);
    const accountUUID = window.location.pathname.substring(
        window.location.pathname.indexOf("/company/") + "/company/".length,
        window.location.pathname.lastIndexOf("/")
    );
    // all checks:
    // const callUrl = `${cysrConfig().api_base_url}account/${accountUUID}/lastcheck/`;
    // fare loop su questo
    // const callUrl = `${cysrConfig().api_base_url}dashboard/account/${accountUUID}/`;
    // /{version}/dashboard/account/{account_req_uuid}/checks/
    const callUrl = `${cysrConfig().api_base_url}dashboard/account/${accountUUID}/cves/`;
    useEffect(() => {
        setLoading(true);
        fetch(callUrl, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('vulnerabilities')
                console.log(data)
                setViews({
                    // checks: data,
                    issues: data?.issues ? Object.values(data.issues) : [],
                    useraccountrole_account: {
                        account_uuid: accountUUID,
                    },
                    cysr_tags_active: activeTags
                });
                setLoading(false);
            })
            .catch((err) => {
                console.error(err)
                setLoading(false);
            });
    }, [location]);
    return (
        <DashboardLayout>
            <DashboardNavbar cysr_company />
            <MDBox pt={6} pb={3}>
                <Card>
                    <MDBox pl={3} pt={2} lineHeight={1}>
                        <MDTypography variant="h5" fontWeight="medium">
                            Vulnerabilities
                        </MDTypography>
                    </MDBox>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            {views && views.issues &&
                                <MDBox py={5} px={2} fullWidth>
                                    <CysrTableIssues data={
                                        {
                                            isVulnerabilities: true,
                                            search: params.get('si') ? params.get('si') : "",
                                            hideCompleted: params.get('hci') ? true : false,
                                            tableTagsOptions: [],
                                            nodes: views.issues,
                                            tableTagsActive: views.cysr_tags_active,
                                        }
                                    }
                                    />
                                </MDBox>
                            }
                            {
                                // <DataTable  entriesPerPage={{ defaultValue: 50, entries: [25, 50, 100, 200, 400] }} table={checksData(views)} canSearch />
                            }
                        </>
                    )}
                </Card>
            </MDBox>
        </DashboardLayout>
    );
};
export default App;
/* eslint-disable */