/* eslint-disable */
// prettier-ignore
"use client"
import { Field, Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverPanel, Radio, RadioGroup } from '@headlessui/react'
import { CircleSharp, MoreVertSharp } from '@mui/icons-material'
import cysrConfig from "layouts/cysr/config";
import { toast } from "sonner"

export function QuestionnaireActions({
    accountUUID,
    questionnaireUUID,
    questionnaire,
    setQuestionnaire,
    statuses,
    className,
    ghost,
    label,
}) {
    return (
        <div className={className}>
            <Menu>
                {
                    ghost
                        ?
                        <MenuButton className="inline-flex items-center gap-2 bg-transparent text-sm/6 font-medium">

                            <MoreVertSharp style={{
                                height: "1.5rem",
                                width: "1.5rem"
                            }} className="size-6 shrink-0 text-black dark:text-white" aria-hidden="true" />

                            {label ? label : null}
                        </MenuButton>
                        :
                        <MenuButton className="inline-flex items-center gap-2 rounded-md bg-turquoise-600 py-1 px-3 text-sm/6 font-medium text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-700 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white">

                            <MoreVertSharp className="size-4 shrink-0 text-white" aria-hidden="true" />

                            {label ? label : null}
                        </MenuButton>
                }

                <MenuItems
                    transition
                    portal={false}
                    // anchor="bottom end"
                    className="mt-1 min-w-[200px] absolute w-52 origin-top-right rounded-xl border border-white/5 bg-tremor-background dark:bg-turquoise-900 p-1 text-sm/6 dark:text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                >
                    {statuses ? (
                        <MenuItem onClick={e => e.preventDefault()}>
                            <div>
                                <RadioGroup
                                    value={questionnaire.status}
                                    onChange={(x) => {
                                        let newQ = { ...questionnaire };
                                        newQ.status = x;
                                        setQuestionnaire(newQ)
                                        fetch(`${cysrConfig().ctrl_api_base_url}company/${accountUUID}/questionnaire/${questionnaireUUID}`, {
                                            body: JSON.stringify({
                                                status: x
                                            }),
                                            headers: {
                                                Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                                "content-type": "application/json",
                                            },
                                            method: "PATCH"
                                        })
                                            .then((response) => response.json())
                                            .then(() => {
                                                toast.success("Status updated")
                                            })
                                            .catch((err) => {
                                                console.error(1, err)
                                            })
                                    }}
                                >
                                    <div className="px-5 mt-4 mb-1 text-xs text-zinc-800 dark:text-zinc-300">Set questionnaire status</div>
                                    {statuses.map((item) => (

                                        <Field
                                            aria-label="Switch status"

                                            key={item.value}
                                            value={item.value}
                                            disabled={item.disabled}
                                        >
                                            {
                                                // <LightModeSharp className="size-4 shrink-0" aria-hidden="true" />
                                            }
                                            <Radio
                                                value={item.value}
                                                className="mb-1 group relative flex cursor-pointer rounded-lg py-1 px-5 dark:text-white transition focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-white/20 data-[hover]:bg-white/10"
                                            >
                                                <div className="flex w-full items-center gap-2">
                                                    <CircleSharp className="size-6 opacity-25 transition group-checked:opacity-100" />
                                                    <div className="text-sm/6">
                                                        <p className="font-medium dark:text-white">{item.label}</p>
                                                    </div>
                                                </div> </Radio>
                                        </Field>
                                    ))
                                    }
                                </RadioGroup>
                                <MenuButton className="px-5 mt-4 mb-1 text-md text-red-500 font-medium" onClick={(e) => {
                                    e => e.preventDefault()
                                    toast.warning('Delete the questionnaire?', {
                                        action: {
                                            label: 'Confirm',
                                            // /{version}/questionnaire-improved/{questionnaire_id}
                                            onClick: () => fetch(`${cysrConfig().ctrl_api_base_url}questionnaire-improved/${questionnaireUUID}`, {
                                                headers: {
                                                    Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                                    "content-type": "application/json",
                                                },
                                                method: "DELETE"
                                            })
                                                .then((response) => response.json())
                                                .then(() => {
                                                    toast.success("Questionnaire removed")
                                                    window.location.href = window.location.href.split('questionnaire#')[0] + "comply"
                                                })
                                                .catch((err) => {
                                                    console.error(1, err)
                                                })
                                        }
                                    })
                                }}>
                                    Delete Questionnaire
                                </MenuButton>
                            </div>
                        </MenuItem>
                    ) : null}
                </MenuItems>
            </Menu>
        </div>
    )
}
