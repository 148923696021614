/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Pmu_created_at: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import DataTable from "examples/Tables/DataTable";

// Material Dashboard 2 PRO React components
import { useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Collapse from "@mui/material/Collapse";
import cysrConfig from "layouts/cysr/config";

import { useMaterialUIController } from "context";

import RelatedItem from "layouts/cysr/components/RelatedItem";
import { Link } from "react-router-dom";

export default function data(views) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [relatedOpen, setOpen] = useState(false);
  if (views && views.related) {
    /*
    Chi ha generato l’asset
    Quali asset sono stati generati da
    show type e val (usare id per fare link all’altro asset)
    */
    const rowsFrom = [];
    const rowsTo = [];
    if (views.related.from) {
      views.related.from.forEach((element) => {
        rowsFrom.unshift({
          asset_uuid: element.asset_uuid,
          generalasset_type: element.generalasset.generalasset_type,
          generalasset_val: element.generalasset.generalasset_val,
        });
      });
    }
    if (views.related.to) {
      views.related.to.forEach((element) => {
        rowsTo.unshift({
          asset_uuid: element.asset_uuid,
          generalasset_type: element.generalasset.generalasset_type,
          generalasset_val: element.generalasset.generalasset_val,
        });
      });
    }
    const relatedFromTable = {
      rows: rowsFrom,
    };
    const relatedToTable = {
      rows: rowsTo,
    };
    const accountUUID = window.location.pathname.substring(
      window.location.pathname.indexOf("/company/") + "/company/".length,
      window.location.pathname.lastIndexOf("/")
    );
    /*
    let relatedMuCreatedDate = "";
    if (
      views &&
      views.asset &&
      views.asset.generalasset &&
      views.asset.generalasset.mu_updated_at
    ) {
      relatedMuCreatedDate = new Date(views.asset.generalasset.mu_updated_at).toLocaleDateString(
        "en-US"
      );
    }
    */
    return (
      <>
        <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <div>
            {cysrConfig().i("asset", "related", "title") && (
              <MDTypography variant="h5">{cysrConfig().i("asset", "related", "title")}</MDTypography>
            )}
            {
            /*
            cysrConfig().i("asset", "related", "sub_title") && (
              <MDTypography variant="button" color="text" fontWeight="regular">
                {cysrConfig().i("asset", "related", "sub_title")}
              </MDTypography>
            )
            */}
            <MDTypography variant="button" color="text" fontWeight="regular">
              {
                relatedFromTable.rows && relatedFromTable.rows.length > 0 &&
                `${relatedFromTable.rows.length} source assets`
              }
              {
                relatedToTable.rows && relatedToTable.rows.length > 0 &&
                `${relatedFromTable.rows && relatedFromTable.rows.length > 0 && ", "
                }${relatedToTable.rows.length
                } derived assets`}
            </MDTypography>
          </div>
          <MDButton
            style={cysrConfig().button_style}
            size="medium"
            onClick={() => setOpen(!relatedOpen)}
          >
            {relatedOpen ? <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} /> : <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />}
          </MDButton>
        </MDBox>
        {
          // <Collapse in={relatedOpen} style={cysrConfig().collapse_style} timeout="auto" unmountOnExit>  
        }
        <Collapse in={relatedOpen} style={{ marginTop: ".5em" }} timeout="auto" unmountOnExit>
          <div style={{
            borderTop: `1px solid ${darkMode ? "#4a4545" : "#f0f2f5"}`,
            marginTop: "8px"
          }}>
            <Grid container spacing={12}>
              <Grid item md={12} lg={4}>
                <MDBox mb={1}>
                  <MDTypography
                    variant="button"
                    color="text"
                  >
                    Source assets
                  </MDTypography>
                </MDBox>
                <MDBox
                  component="ul"
                  display="flex"
                  flexDirection="column" style={{ height: "100%", justifyContent: "center" }}
                  p={0}
                  m={0}
                  sx={{ listStyle: "none" }}
                >
                  {relatedFromTable.rows.length === 0 ? (
                    <MDTypography variant="caption" color="text">
                      This asset does not come from any asset
                    </MDTypography>
                  ) : (
                    relatedFromTable.rows.map((t) => (
                      <Link key={t.asset_uuid} to={`/company/${accountUUID}/${"asset"}#${t.asset_uuid}`}>
                        <RelatedItem
                          color="info"
                          icon="keyboard_arrow_up"
                          name={t.generalasset_val}
                          description={t.asset_uuid}
                          value={t.generalasset_type}
                        />
                      </Link>
                    ))
                  )}
                </MDBox>
              </Grid>
              <Grid item md={12} lg={4}>
                <MDBox mb={1}>
                  <MDTypography
                    variant="button"
                    color="text"
                  >
                    Current asset
                  </MDTypography>
                </MDBox>
                <MDBox
                  component="ul"
                  display="flex"
                  flexDirection="column" style={{ height: "100%", justifyContent: "center" }}
                  p={0}
                  m={0}
                  sx={{ listStyle: "none" }}
                >
                  {views && views.asset && views.asset.asset_uuid ? (
                    <div style={{ position: "relative" }}>
                      <span className="cysr-related-left-arrow" dangerouslySetInnerHTML={{ __html: cysrConfig().double_right_arrow.icon.svg }} />
                      {
                        // <Link to={`/company/${accountUUID}/${"asset"}#${views.asset.asset_uuid}`}>
                      }
                      <RelatedItem
                        color="info"
                        icon="current"
                        name={views.asset.generalasset.generalasset_val}
                        description={views.asset.asset_uuid}
                        value={views.asset.generalasset.generalasset_type}
                      />
                      {
                        // </Link>
                      }
                      <span className="cysr-related-right-arrow" dangerouslySetInnerHTML={{ __html: cysrConfig().double_right_arrow.icon.svg }} />
                    </div>
                  ) : (
                    <MDTypography variant="caption" color="text">
                      This asset does not exist
                    </MDTypography>
                  )}
                </MDBox>
              </Grid>
              <Grid item md={12} lg={4}>
                <MDBox mb={1}>
                  <MDTypography
                    variant="button"
                    color="text"
                  >
                    Derived assets
                  </MDTypography>
                </MDBox>
                <MDBox
                  component="ul"
                  display="flex"
                  flexDirection="column" style={{ height: "100%", justifyContent: "center" }}
                  p={0}
                  m={0}
                  sx={{ listStyle: "none" }}
                >
                  {relatedToTable.rows.length === 0 ? (
                    <MDTypography variant="caption" color="text">
                      No assets coming from this asset
                    </MDTypography>
                  ) : (
                    relatedToTable.rows.map((t) => (
                      <Link key={t.asset_uuid} to={`/company/${accountUUID}/${"asset"}#${t.asset_uuid}`}>
                        <RelatedItem
                          color="info"
                          icon="keyboard_arrow_down"
                          name={t.generalasset_val}
                          description={t.asset_uuid}
                          value={t.generalasset_type}
                        />
                      </Link>
                    ))
                  )}
                </MDBox>
              </Grid>
            </Grid>
          </div>
        </Collapse>
      </>
    );
  }
  return "";
}
