/* eslint-disable */
// prettier-ignore

import {
  Button
} from '@tremor/react';

export function LawNavigatorListItem({ scrolledToID, article, setScrollToID }) {
  return <li style={{ paddingBottom: 0, paddingTop: 0 }} className="tremor-Tab-root flex whitespace-nowrap truncate max-w-xs outline-none focus:ring-0 text-tremor-default ui-selected:text-tremor-brand dark:ui-selected:text-dark-tremor-brand ui-selected:border-b-2 hover:border-b-2 border-transparent transition duration-100 -mb-px px-2 py-2 hover:border-tremor-content hover:text-tremor-content-emphasis text-tremor-content dark:hover:border-dark-tremor-content-emphasis dark:hover:text-dark-tremor-content-emphasis dark:text-dark-tremor-content ui-selected:border-tremor-brand dark:ui-selected:border-dark-tremor-brand">
    <Button className='text-xs py-0 my-0' style={{
      color: scrolledToID === article.id ? 'rgb(20, 184, 166)' : null
    }} size='xs' variant='light' onClick={() => {
      setScrollToID(article.id);
    }}
      type='button'>
      {article.title // ?.split('.')[0]
      }
    </Button>
  </li>;
}