/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
// import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components

import { useLocation } from "react-router-dom";
import HierarchicalGraph from "layouts/cysr/components/Circle2GraphStatic";

import cysrConfig from "layouts/cysr/config";

// https://www.robinwieruch.de/react-component-to-pdf/
// import html2canvas from 'html2canvas';
// import { jsPDF } from 'jspdf';

/*
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});
*/

// Material Dashboard 2 React Examples
/* eslint-disable */
function CircleChart() {
  const location = useLocation();
  // Our State.
  const [views, setViews] = useState([]);
  const accountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/company/") + "/company/".length,
    window.location.pathname.lastIndexOf("/")
  );
  const dashboardCallUrl = `${cysrConfig().api_base_url}dashboard/mainpage/${accountUUID}/`;
  useEffect(() => {
    //setLoading(true);
    fetch(dashboardCallUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      },
    })
      .then((response) => response.json())
      .then((responseDashboardAccountData) => {        
        setViews({
            circle2: responseDashboardAccountData.assets.graphs.circle2,
        });
        //setLoading(false);
      })
      .catch((err) => {
        console.error(err)
        //setLoading(false);
      });
    // very similar to handleDownloadPdf e
  }, [location]);
  return (
    <div style={{background: "#ffffff", display: "block", textAlign: "center", position: "fixed", zIndex: "99999", left: "0", right: "0", top:"0", bottom:"0", padding:"2em", overflow:"auto", height: "100%", width: "100%"}}>
      {views && views.circle2 && views.circle2.tree && views.circle2.tree.length > 0 && (
        <HierarchicalGraph data={views.circle2} />
      )}
    </div>
  );
}
/* eslint-disable */
export default CircleChart;
