/* eslint-disable */
// prettier-ignore
import { Button, Textarea } from '@tremor/react';

export function CtrlTextareaObjectiveNote({ noteText, setNoteText, label, update }) {
    return (
        <>
            <div className="flex flex-col gap-2 max-w-lg">
                <label htmlFor="note" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                    {label}
                </label>
                <Textarea
                    onChange={(e) => setNoteText(e.target.value)}
                    id="note"
                    placeholder="Start typing here..."
                    rows={6}
                    value={noteText}
                />
                <div className="mt-6 flex justify-end">
                    <Button className='text-white bg-turquoise-600' type="button" onClick={() => update()}>
                        Save
                    </Button>
                </div>
            </div>
        </>
    );
}