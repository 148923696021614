/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
// prettier-ignore
import { Base64 } from 'js-base64';
import { useState, useEffect } from "react";


import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import Radio from "@mui/material/Radio";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import Slider from "@mui/material/Slider";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
import VerticalAccessibleSlider from "layouts/cysr/risk/questionnaire/SliderVertical";
import RenderMarkdown from "layouts/cysr/components/RenderMarkdown"

import MDButton from "components/MDButton";
import {
    Card as CardIn,
    CardHeader,
    CardTitle,
    CardDescription,
    CardContent,
} from "layouts/cysr/components/ui/card";


import {
    badgeNumberMaxLengthForDecimal,
    valueFormatter
} from "layouts/cysr/risk"


import cysrConfig, { process } from "layouts/cysr/config";

import Loader from "layouts/cysr/components/Loader";


import { useMaterialUIController } from "context";

//+

import {
    AreaChart,
    Card,
    Col,
    Flex,
    Grid,
    Icon,
    Metric,
    Text,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    Title,
    MarkerBar,
    Select,
    SelectItem
} from "@tremor/react";
import {
    GppMaybeSharp,
    LeakRemoveSharp,
    SpeedSharp,
    InfoSharp,
    RestartAltSharp
} from "@mui/icons-material";

import { toast } from "sonner"

const savingTimeout = 600;
const stringForMode = "mode"

let timeoutId

const propNames = {
    min: "low",
    avg: "mode",
    max: "high",
}

const propNamesUser = {
    min: "min",
    avg: "mode",
    max: "max",
}

function getRatingColorRiskActual(x) {
    return `#${x.companyrisk_lef_avg > .6
        ? "E7212E"
        : x.companyrisk_lef_avg > .5
            ? "F05E25"
            : x.companyrisk_lef_avg > .4
                ? "F6921F"
                : x.companyrisk_lef_avg > .3
                    ? "57A6D9"
                    : x.companyrisk_lef_avg > .2
                        ? "2466B0" : "CCCCCC"}`;
}

function roundValue(x) {
    if (Array.isArray(x)) {
        return x.map(y => roundValue(y))
    } else {
        return parseFloat(x) ? +parseFloat(x).toFixed(2) : x
    }
}

export function Question({ data, darkMode, onChange, changedParametersNotOK, setChangedParametersNotOK }) {

    {
        /*
        ID	Tipo	Scenari	Descrizione
BACKUP	%	*	Quanto é ben configurato il backup: 0 non fa i backup, 1 ha i backup non modificabili su infrastruttura separata
MFA	%	*	Per quale percentuale di servizi usati é necessaria una mfa per autenticarsi
RANSOMWARE_AWARENESS	%	ransomware	Quanto fanno fare ai dipendenti corsi di awarness: 0 non ne fanno, 1 tutti i dipendenti seguono periodicamente corsi.
RANSOMWARE_BUSINESS_INTERRUPTION	(N, N, N)	ransomware	Mancato guadagno a causa del ransomware
RANSOMWARE_RANSOM_PAYMENT	(N, N, N)	ransomware	Costo del riscatto
RANSOMWARE_RECOVERY_COST	(N, N, N)	ransomware	Costi per il ripristino
RANSOMWARE_VOLUNTARY_IMPROVEMENT	(N, N, N)	ransomware	Costi di miglioramenti volontari
        */
    }

    // types
    // TRIPLE_NUMBER
    const type = data.parametertype;

    const date_updated = data.last_update;
    const date_edited = data.user_value_lastupdate;

    const description = data.description;

    const order = data.order;
    const key = data.key;
    const scenario = data.scenario;
    const settings = data.parametertype_settings;
    const title = data.question;

    const valueCalculated = data.last_calculated_value || data.last_calculated_value === 0 ? roundValue(data.last_calculated_value) : null;
    const valueCustom = data.last_custom_value || data.last_custom_value === 0 ? roundValue(data.last_custom_value) : null;
    const valueEdited = data.last_user_value || data.last_user_value === 0 ? roundValue(data.last_user_value) : null;

    const defaultValue = roundValue(type === "TRIPLE_NUMBER"
        ? [
            valueCustom && valueCustom[propNames.min] ? valueCustom[propNames.min] : null,
            valueCustom && valueCustom[propNames.avg] ? valueCustom[propNames.avg] : null,
            valueCustom && valueCustom[propNames.max] ? valueCustom[propNames.max] : null,
        ]
        : (type === "PERCENT" || type === "PERCENT_LEVELS" || type === "RADIO")
            ? (valueCustom ? valueCustom * 100 : null)
            : valueCustom
    )

    const resetValue = roundValue(type === "TRIPLE_NUMBER"
        ? [
            valueCalculated && valueCalculated[propNames.min] ? valueCalculated[propNames.min] : null,
            valueCalculated && valueCalculated[propNames.avg] ? valueCalculated[propNames.avg] : null,
            valueCalculated && valueCalculated[propNames.max] ? valueCalculated[propNames.max] : null,
        ]
        : (type === "PERCENT" || type === "PERCENT_LEVELS" || type === "RADIO")
            ? (valueCalculated ? valueCalculated * 100 : null)
            : valueCalculated
    )

    const currentRiskValue = roundValue(valueEdited
        ? type === "TRIPLE_NUMBER"
            ? [
                // valueEdited[propNamesUser.min] ?? null,
                valueEdited && valueEdited[propNames.min] ? valueEdited[propNames.min] : null,
                // valueEdited[propNamesUser.avg] ?? null,
                valueEdited && valueEdited[propNames.avg] ? valueEdited[propNames.avg] : null,
                // valueEdited[propNamesUser.max] ?? null,
                valueEdited && valueEdited[propNames.max] ? valueEdited[propNames.max] : null,
            ]
            : (type === "PERCENT" || type === "PERCENT_LEVELS" || type === "RADIO")
                ? (valueEdited ? valueEdited * 100 : null)
                : valueEdited
        : null
    )

    const [value, setValue] = useState(currentRiskValue || currentRiskValue === 0 ? currentRiskValue : defaultValue)
    const [value1, setValue1] = useState(currentRiskValue || currentRiskValue === 0 ? currentRiskValue[0] : defaultValue && defaultValue[0] ? defaultValue[0] : null)
    const [value2, setValue2] = useState(currentRiskValue || currentRiskValue === 0 ? currentRiskValue[1] : defaultValue && defaultValue[1] ? defaultValue[1] : null)
    const [value3, setValue3] = useState(currentRiskValue || currentRiskValue === 0 ? currentRiskValue[2] : defaultValue && defaultValue[2] ? defaultValue[2] : null)

    const [savedValue1, setSavedValue1] = useState(null)
    const [savedValue2, setSavedValue2] = useState(null)
    const [savedValue3, setSavedValue3] = useState(null)
    function getClosestValueFromSettings(v) {
        let closest = 0;
        Object.keys(settings).forEach(
            (s) => {
                if (parseFloat(v) === parseFloat(s)) {
                    closest = s
                } else if (
                    parseFloat(v) > parseFloat(s)
                    && (parseFloat(closest) < parseFloat(v)) > 0
                ) {
                    closest = s;
                }
            })
        return closest
    }
    return <div>
        <div className="max-w-3xl">
            <Title className="mb-2"><span className="inline-block rounded-full bg-teal-500 h-3 w-3 mr-2" style={savedValue1 === false || savedValue2 === false || savedValue3 === false ? {
                background: process.env.NEXT_PUBLIC_RATING_BAD_COLOR
            } : {}}></span>{title}</Title>
            {description ? <Text className="mb-4">
                <RenderMarkdown components={{

                    // Map `h1` (`# heading`) to use `h2`s.
                    // h1: 'h2',
                    ul(props) {
                        const { node, ...rest } = props
                        return <ul className="mt-2 flex flex-col gap-1 list-disc list-inside marker:text-teal-500" {...rest} />
                    }

                }}>{description}</RenderMarkdown>
            </Text> : null}
            {type === "TRIPLE_NUMBER"
                ? <Grid numItems={12} className="gap-4">
                    <Col numColSpan={4} numColSpanSm={4} numColSpanMd={4}>
                        <MDInput
                            fullWidth
                            value={value1}
                            // defaultValue={defaultValue[0]}
                            sx={{ border: savedValue1 === false ? `1px solid ${process.env.NEXT_PUBLIC_RATING_BAD_COLOR}` : "", borderRadius: "5px", color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#232728' : '#ffffff' }}
                            onChange={(e) => {
                                const v1 = parseFloat(e.target.value);
                                setValue1(v1)

                                if (v1 != value1) {
                                    if (((v1 < value2) && (v1 < value3)) || ((v1 == value2) && (v1 < value3))) {
                                        clearTimeout(timeoutId); // Clear any previously set timeout
                                        timeoutId = setTimeout(() => {
                                            if (v1 != savedValue1) {
                                                setSavedValue1(v1)
                                                const v = [
                                                    ...value
                                                ]
                                                v[0] = v1;
                                                setValue(v)
                                                // check if triple values are clean
                                                if (savedValue2 === false || savedValue3 === false) {
                                                    // try see if now they are correct
                                                    saveIfAllCorrect(v1, value2, value3, key);
                                                } else {
                                                    onChange({
                                                        "parameter_key": key,
                                                        "parameter_value": {
                                                            "low": v[0],
                                                            // "min": v[0],
                                                            "mode": v[1],
                                                            // "max": v[2]
                                                            "high": v[2]
                                                        }
                                                    });
                                                    removeKeyFromNotOK(changedParametersNotOK, key, setChangedParametersNotOK);
                                                }
                                            }
                                        }, savingTimeout);
                                    } else {
                                        setSavedValue1(false)
                                        addKeyToNotOK(changedParametersNotOK, key, setChangedParametersNotOK);
                                        toast.warning(`Min value must be smaller than the ${stringForMode} and max`)
                                    }
                                }
                            }}
                            size="small"
                            label={data.parametertype_settings && data.parametertype_settings[0] ? data.parametertype_settings[0] : "Min"}
                            type="number"
                        />
                    </Col>
                    <Col numColSpan={4} numColSpanSm={4} numColSpanMd={4}>
                        <MDInput
                            fullWidth
                            value={value2}
                            sx={{ border: savedValue2 === false ? `1px solid ${process.env.NEXT_PUBLIC_RATING_BAD_COLOR}` : "", borderRadius: "5px", color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#232728' : '#ffffff' }}
                            onChange={(e) => {
                                const v2 = parseFloat(e.target.value);
                                if (v2 != value2) {
                                    setValue2(v2)
                                    if (((value1 < v2) && (v2 < value3)) || ((value1 == v2) && (v2 < value3)) || ((value1 == v2) && (v2 == value3)) || ((value1 == v2) && (v2 < value3))) {
                                        clearTimeout(timeoutId); // Clear any previously set timeout
                                        timeoutId = setTimeout(() => {
                                            if (v2 != savedValue2) {
                                                setSavedValue2(v2)
                                                const v = [
                                                    ...value
                                                ]
                                                v[1] = v2;
                                                setValue(v)
                                                // check if triple values are clean
                                                if (savedValue1 === false || savedValue3 === false) {
                                                    // try see if now they are correct
                                                    saveIfAllCorrect(value1, v2, value3, key);
                                                } else {
                                                    onChange({
                                                        "parameter_key": key,
                                                        "parameter_value": {
                                                            // "min": v[0],
                                                            "low": v[0],
                                                            "mode": v[1],
                                                            //"max": v[2]
                                                            "high": v[2]
                                                        }
                                                    });
                                                    removeKeyFromNotOK(changedParametersNotOK, key, setChangedParametersNotOK);
                                                }
                                            }
                                        }, savingTimeout);
                                    } else {
                                        setSavedValue2(false)
                                        addKeyToNotOK(changedParametersNotOK, key, setChangedParametersNotOK);
                                        toast.warning(`${stringForMode} value must be higher than the min and smaller than max`)
                                    }
                                }
                            }}
                            size="small"
                            label={data.parametertype_settings && data.parametertype_settings[1] ? data.parametertype_settings[1] : stringForMode}
                            type="number"
                        />
                    </Col>
                    <Col numColSpan={4} numColSpanSm={4} numColSpanMd={4}>
                        <MDInput
                            fullWidth
                            value={value3}
                            sx={{ border: savedValue3 === false ? `1px solid ${process.env.NEXT_PUBLIC_RATING_BAD_COLOR}` : "", borderRadius: "5px", color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#232728' : '#ffffff' }}
                            onChange={(e) => {
                                const v3 = parseFloat(e.target.value);
                                if (v3 != value3) {
                                    setValue3(v3)
                                    if (((v3 > value1) && (v3 > value2)) || ((v3 > value1) && (v3 == value2))) {
                                        clearTimeout(timeoutId); // Clear any previously set timeout
                                        timeoutId = setTimeout(() => {
                                            if (v3 != savedValue3) {
                                                setSavedValue3(v3)
                                                const v = [
                                                    ...value
                                                ]
                                                v[2] = v3;
                                                setValue(v)
                                                // check if triple values are clean
                                                if (savedValue1 === false || savedValue2 === false) {
                                                    // try see if now they are correct
                                                    saveIfAllCorrect(value1, value2, v3, key);
                                                } else {
                                                    onChange({
                                                        "parameter_key": key,
                                                        "parameter_value": {
                                                            // "min": v[0],
                                                            "low": v[0],
                                                            "mode": v[1],
                                                            // "max": v[2]
                                                            "high": v[2]
                                                        }
                                                    });
                                                    removeKeyFromNotOK(changedParametersNotOK, key, setChangedParametersNotOK);
                                                }
                                            }
                                        }, savingTimeout);
                                    } else {
                                        setSavedValue3(false)
                                        addKeyToNotOK(changedParametersNotOK, key, setChangedParametersNotOK);
                                        toast.warning(`Max value must be higher than the min and ${stringForMode}`)
                                    }
                                }
                            }}
                            size="small"
                            label={data.parametertype_settings && data.parametertype_settings[2] ? data.parametertype_settings[2] : "Max"}
                            type="number"
                        />
                    </Col>
                </Grid>
                : (
                    type === "PERCENT" ? <Slider
                        // aria-label="Temperature"
                        // defaultValue={defaultValue}
                        value={value}
                        // getAriaValueText={valuetext}
                        valueLabelDisplay="auto"
                        shiftStep={10}
                        step={1}
                        marks={[
                            {
                                value: 0,
                                label: '0%',
                            },
                            /*
                            {
                                value: 25,
                                label: '25%',
                            },
                            {
                                value: 50,
                                label: '50%',
                            },
                            {
                                value: 75,
                                label: '75%',
                            },
                            */
                            {
                                value: 100,
                                label: '100%',
                            },
                        ]}
                        onChange={(e) => {
                            const v = parseFloat(e.target.value);
                            setValue(v)

                            clearTimeout(timeoutId); // Clear any previously set timeout
                            timeoutId = setTimeout(() => {
                                // console.warn(data)
                                onChange({
                                    "parameter_key": key,
                                    "parameter_value": v / 100
                                });
                            }, savingTimeout);
                        }}
                        min={0}
                        max={100}
                    />
                        : type === "PERCENT_LEVELS"
                            ? <>
                                <Slider
                                    //aria-label="Temperature"
                                    // defaultValue={defaultValue}
                                    value={value}
                                    // getAriaValueText={valuetext}
                                    valueLabelDisplay="auto"
                                    shiftStep={10}
                                    step={1}
                                    onChange={(e) => {
                                        const v = parseFloat(e.target.value);
                                        setValue(v)

                                        clearTimeout(timeoutId); // Clear any previously set timeout
                                        timeoutId = setTimeout(() => {
                                            // if (v != value) {
                                            // console.warn(data)
                                            onChange({
                                                "parameter_key": key,
                                                "parameter_value": v / 100
                                            });
                                            // }
                                        }, savingTimeout);
                                    }}
                                    // You can restrict the selectable values to those provided with the marks prop with step={null}.
                                    // step={null}
                                    marks={
                                        Object.entries(settings).map(
                                            (s) => ({
                                                value: s[0] * 100,
                                                label: s[1]
                                            })
                                        )
                                    }
                                    min={0}
                                    max={100}
                                />
                            </>
                            : type === "RADIO"
                                ?

                                <>
                                    <VerticalAccessibleSlider
                                        // height="300px"
                                        // ridotto di 1/4
                                        // height="225px"
                                        height="200px"
                                        //aria-label="Temperature"
                                        // defaultValue={defaultValue}
                                        value={value}
                                        // getAriaValueText={valuetext}
                                        valueLabelDisplay="auto"
                                        shiftStep={10}
                                        step={1}
                                        onChange={(x) => {
                                            const v = x[0];
                                            setValue(v);

                                            clearTimeout(timeoutId); // Clear any previously set timeout
                                            timeoutId = setTimeout(() => {
                                                // if (v !== value) {
                                                // console.warn(data);
                                                onChange({
                                                    parameter_key: key,
                                                    parameter_value: v / 100,
                                                });
                                                // }
                                            }, savingTimeout);
                                        }}
                                        // You can restrict the selectable values to those provided with the marks prop with step={null}.
                                        // step={null}
                                        marks={
                                            Object.entries(settings).map(
                                                (s) => ({
                                                    value: s[0] * 100,
                                                    label: s[1]
                                                })
                                            )
                                        }
                                        min={0}
                                        max={100}
                                    /*
                                    orientation="vertical"
                                    sx={{
                                        '& input[type="range"]': {
                                            WebkitAppearance: 'slider-vertical',
                                        },
                                    }}
                                    */
                                    />
                                </>
                                /*
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            // defaultValue="female"
                                            // value={value}
                                            value={
                                                getClosestValueFromSettings(value)
                                            }
                                            name="radio-buttons-group"
                                            onChange={(e) => {
                                                if (value != e.target.value) {
                                                    const v = e.target.value;
                                                    setValue(v)
                                                    console.warn(data)
                                                    onChange(v);
                                                }
                                            }}
                                        >
        
                                            {
                                                Object.entries(settings).sort((a, b) => a[0] - b[0]).map(
                                                    (s) => <FormControlLabel value={s[0]} control={<Radio />} label={s[1]} />
                                                )
                                            }
                                        </RadioGroup>
                                    </FormControl>
                                */
                                : type === "NUMBER"
                                ? <Grid numItems={12} className="gap-4">
                                    <Col numColSpan={4} numColSpanSm={4} numColSpanMd={4}>
                                        <MDInput
                                            fullWidth
                                            value={value}
                                            // defaultValue={defaultValue[0]}
                                            sx={{ border: value === false ? `1px solid ${process.env.NEXT_PUBLIC_RATING_BAD_COLOR}` : "", borderRadius: "5px", color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#232728' : '#ffffff' }}
                                            onChange={(e) => {
                                                const v = parseFloat(e.target.value);
                                                setValue(v)

                                                clearTimeout(timeoutId); // Clear any previously set timeout
                                                timeoutId = setTimeout(() => {
                                                    // if (v != value) {
                                                    // console.warn(data)
                                                    onChange({
                                                        "parameter_key": key,
                                                        "parameter_value": v
                                                    });
                                                    // }
                                                }, savingTimeout);

                                            }}
                                            size="small"
                                            label={data.parametertype_settings && data.parametertype_settings[0] ? data.parametertype_settings[0] : "Number"}
                                            type="number"
                                        />
                                    </Col>
                                </Grid> : JSON.stringify(data)
                )
            }
        </div>
        <div className="mt-2 flex gap-2 items-center">
            {valueCalculated || valueCalculated === 0 ?
                <button type="button" className="inline-flex gap-1 items-center py-1 px-2 text-xs rounded-md" style={{
                    // hsla(240,100%,50%, 0.4) /* 40% opaque blue */ 
                    background: darkMode ? "hsla(184, 74%, 23%, .2)" : "#AED2D6",
                    color: darkMode ? "#AED2D6" : "#000000"
                }}
                    onClick={() => {
                        const dV = resetValue
                        setValue(dV)
                        if (type === "TRIPLE_NUMBER") {
                            setValue1(dV[0])
                            setSavedValue1(null)
                            setValue2(dV[1])
                            setSavedValue2(null)
                            setValue3(dV[2])
                            setSavedValue3(null)
                            onChange({
                                "parameter_key": key,
                                "parameter_value": {
                                    // "min": dV[0],
                                    "low": dV[0],
                                    "mode": dV[1],
                                    // "max": dV[2]
                                    "high": dV[2]
                                }
                            });
                        } else {
                            onChange({
                                "parameter_key": key,
                                "parameter_value": dV / 100
                            });
                        }
                        removeKeyFromNotOK(changedParametersNotOK, key, setChangedParametersNotOK);
                    }}
                >
                    <RestartAltSharp className="h-3 w-3" />
                    Reset to statistical default: {
                        type === "TRIPLE_NUMBER"
                            ? `${valueCalculated[propNames.min].toFixed(0)} - ${valueCalculated[propNames.avg].toFixed(0)} - ${valueCalculated[propNames.max].toFixed(0)}`
                            :
                            type === "PERCENT" || type === "PERCENT_LEVELS" || type === "RADIO"
                                ? (valueCalculated * 100).toFixed(0)
                                : valueCalculated.toFixed(0)
                    }
                </button>
                : null}
            {valueCustom || valueCalculated === 0 ?
                <button type="button" className="inline-flex gap-1 items-center py-1 px-2 text-xs rounded-md" style={{
                    // hsla(240,100%,50%, 0.4) /* 40% opaque blue */ 
                    background: darkMode ? "hsla(184, 74%, 23%, .2)" : "#AED2D6",
                    color: darkMode ? "#AED2D6" : "#000000"
                }}
                    onClick={() => {
                        const dV = defaultValue
                        setValue(dV)
                        if (type === "TRIPLE_NUMBER") {
                            setValue1(dV[0])
                            setSavedValue1(null)
                            setValue2(dV[1])
                            setSavedValue2(null)
                            setValue3(dV[2])
                            setSavedValue3(null)
                            onChange({
                                "parameter_key": key,
                                "parameter_value": {
                                    // "min": dV[0],
                                    "low": dV[0],
                                    "mode": dV[1],
                                    // "max": dV[2]
                                    "high": dV[2]
                                }
                            });
                        } else if (type === "PERCENT" || type === "PERCENT_LEVELS" || type === "RADIO"){
                            onChange({
                                "parameter_key": key,
                                "parameter_value": dV / 100
                            });
                        } else {
                            onChange({
                                "parameter_key": key,
                                "parameter_value": dV
                            });
                        }
                        removeKeyFromNotOK(changedParametersNotOK, key, setChangedParametersNotOK);
                    }}
                >
                    <RestartAltSharp className="h-3 w-3" />
                    Reset to last values used for calculation: {
                        type === "TRIPLE_NUMBER"
                            ? `${valueCustom[propNames.min].toFixed(0)} - ${valueCustom[propNames.avg].toFixed(0)} - ${valueCustom[propNames.max].toFixed(0)}`
                            :
                            type === "PERCENT" || type === "PERCENT_LEVELS" || type === "RADIO"
                                ? (valueCustom * 100).toFixed(0)
                                : valueCustom.toFixed(0)
                    }
                </button>
                : <Text>
                    not having an edited saved value
                </Text>
            }
            {scenario ? <div className="inline-flex gap-2 items-center">
                {scenario.split(",").map((s) => <span style={
                    {
                        border: `1px solid ${darkMode ? "#AED2D6" : "#50666B"}`,
                        borderRadius: 20,
                        padding: "1px 8px",
                    }
                }><MDTypography fontSize={10} color="text">
                        {s}
                    </MDTypography>
                </span>
                )}
            </div> : null}
        </div>
    </div>

    function saveIfAllCorrect(val1, val2, val3, key) {
        const v = [
            val1, val2, val3
        ]
        if (((v[0] < v[1]) || v[0] == v[1])
            &&
            ((v[1] > v[0]) || v[0] == v[1])
            &&
            ((v[1] < v[2]) || v[1] == v[2])
            &&
            ((v[2] > v[1]) || v[1] == v[2])) {
            setSavedValue1(v[0])
            setSavedValue2(v[1])
            setSavedValue3(v[2])
            setValue(v)
            onChange({
                "parameter_key": key,
                "parameter_value": {
                    "low": v[0],
                    // "min": v[0],
                    "mode": v[1],
                    // "max": v[2]
                    "high": v[2]
                }
            });
            // remove key from array of not OK
            removeKeyFromNotOK(changedParametersNotOK, key, setChangedParametersNotOK);
        } else {
            toast.warning("Correct all the values first");
        }
    }
}

const plans = [
    {
        name: 'Startup',
        ram: '12GB',
        cpus: '6 CPUs',
        disk: '160 GB SSD disk',
    },
    {
        name: 'Business',
        ram: '16GB',
        cpus: '8 CPUs',
        disk: '512 GB SSD disk',
    },
    {
        name: 'Enterprise',
        ram: '32GB',
        cpus: '12 CPUs',
        disk: '1024 GB SSD disk',
    },
]
function addKeyToNotOK(changedParametersNotOK, key, setChangedParametersNotOK) {
    let newNotOK = [];
    changedParametersNotOK.forEach(k => {
        if (k != key) {
            newNotOK.push(k);
        }
    });
    newNotOK.push(key);
    setChangedParametersNotOK(newNotOK);
}
function removeKeyFromNotOK(changedParametersNotOK, key, setChangedParametersNotOK) {
    let newNotOK = [];
    changedParametersNotOK.forEach(k => {
        if (k != key) {
            newNotOK.push(k);
        }
    });
    console.warn('newNotOK')
    console.warn(newNotOK)
    setChangedParametersNotOK(newNotOK);
}

function Questionnaire() {

    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const accountUUID = window.location.pathname.substring(
        window.location.pathname.indexOf("/company/") + "/company/".length,
        window.location.pathname.lastIndexOf("/")
    );


    const [changedParametersNotOK, setChangedParametersNotOK] = useState([])
    const [changedParametersToSave, setChangedParametersToSave] = useState([])
    const [selected, setSelected] = useState(plans[0])
    const [selectedIndex, setSelectedIndex] = useState(0)
    // filter test s
    const params = new URLSearchParams(window.location.search);
    let activeTags = [];
    try {
        activeTags = params.get('t') && (decodeURI(params.get('t'))) && Base64.decode(decodeURI(params.get('t'))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('t')))) : [];
    } catch (error) {
        console.error("Error in setting active tags:")
        console.error(error)
    }
    // filter test e

    // Our State.
    const [views, setViews] = useState([]);
    // Tutti gli asset di un account (/asset/) senza UUID?
    if (localStorage.getItem("cysr_useraccountroles") === null) {
        window.location.href = "/cysr/login";
    }

    // const getCallUrl = `${cysrConfig().api_base_url}risk/questions/${accountUUID}/`;
    const getCallUrl = `${cysrConfig().api_base_url}risk/${accountUUID}/question/`;
    useEffect(() => {
        const pageTitle = "Risk";
        document.title = `${pageTitle} - ${document.title.split(" - ")[1] ? document.title.split(" - ")[1] : document.title}`;
        if (localStorage.getItem("cysr_useraccountroles")) {
            //let groupSelectData = [];
            // call table of asset + call table of checks
            fetch(getCallUrl, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                },
            })
                .then((response) => response.json())
                .then((riskQuestionsResponse) => {
                    setViews({
                        risk: riskQuestionsResponse,
                        riskBadgePreview: {
                            "RANSOMWARE": {
                                "CALCULATED": {
                                    "companyrisk_risk_avg": 104341,
                                    "companyrisk_risk_min": 3026,
                                    "companyrisk_risk_max": 513077,
                                    "companyrisk_lef_avg": "0.600000",
                                    "companyrisk_scenario": "RANSOMWARE"
                                }
                            }
                        }
                    });
                })

                .catch((err) => console.error(err));
        } else {
            setViews({});
        }
    }, []);
    return (
        <DashboardLayout>
            <DashboardNavbar cysr_company />
            <>
                <div className={`${darkMode ? "dark " : ""}w-full`}>

                    {(views && views.risk) ? (
                        // <div className="px-4 pt-4">
                        <div className="flex flex-col gap-4">
                            {
                                /*
                            }
                            <Card>
                                <Flex className="space-x-6 justify-between">
                                    <div>
                                        <Title>Risk questions</Title>
                                        <Text>Answer these questions and toggle the Risk Discovery to Manual for a more precise calculation of your risk.</Text>
                                    </div>
                                    {
                                        // <MDBadge color="info" size="sm" badgeContent={"Automatic"} container />
                                    }
                                </Flex>
                            </Card>
                            {
                                */
                            }
                            <Card>
                                {
                                    // <Text className='text-turquoise-500 dark:text-turquoise-400' color="teal">Risk type</Text>
                                    // <Metric>Your company cyber security risk</Metric>
                                }
                                <Title>Risk customization</Title>
                                <Text className="my-2 max-w-3xl">
                                    This page contains a series of questions regarding parameters used in risk calculation.
                                    If the user does not fill out the questions, statistical values based on the company's size and industry sector will be used. <br />
                                    However, if more relevant and specific values have been provided, these will be used for a more accurate risk estimation.
                                </Text>
                                <div className="flex flex-col gap-12 mt-8 risk-questionnaire-questions">
                                    {
                                        Object.values(views.risk).sort((a, b) => a.order - b.order).map((q) => {
                                            return <Question data={q} darkMode={darkMode}
                                                changedParametersNotOK={changedParametersNotOK}
                                                setChangedParametersNotOK={
                                                    setChangedParametersNotOK} onChange={
                                                        (x) => {
                                                            let newParametersToSave = [x]
                                                            changedParametersToSave.forEach(element => {
                                                                if (element.parameter_key === x.parameter_key) {
                                                                    // ignore
                                                                } else {
                                                                    newParametersToSave.push(element)
                                                                }
                                                            });
                                                            setChangedParametersToSave(newParametersToSave)
                                                            console.log(newParametersToSave)
                                                            /*
                                                            fetch(`${cysrConfig().api_base_url}risk/${accountUUID}/question/`, {
                                                                body: JSON.stringify({
                                                                    parameters: [
                                                                        x
                                                                    ]
                                                                }),
                                                                headers: {
                                                                    Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                                                    "content-type": "application/json",
                                                                },
                                                                method: "PATCH",
                                                            })
                                                                .then((response) => {
                                                                    if (response.ok) {
                                                                        console.info(x)
                                                                        toast.success(`Saved: value is now ${(x.parameter_value && x.parameter_value[propNames.min])
                                                                            ? `${x.parameter_value[propNames.min]} - ${x.parameter_value[propNames.avg]} - ${x.parameter_value[propNames.max]}`
                                                                            : isNaN(x.parameter_value) ? x.parameter_value : x.parameter_value * 100
                                                                            } and has been queued to recalculation (wait from 10 to 30 minutes).`)
                                                                    } else {
                                                                        throw new Error(response);
                                                                    }
                                                                })
                                                                .then(() => {
                                                                })
                                                                .catch((err) => {
                                                                    console.error(err);
                                                                    console.error(x)
                                                                    toast.error(`Error: ${JSON.stringify(err)}`)
                                                                });
                                                            */
                                                        }
                                                    } />
                                        })
                                    }
                                </div>
                            </Card>
                            <Card>
                                <Title>Save Questionnaire Responses and Request Risk Recalculation</Title>
                                <Text className="mb-4 max-w-3xl">
                                    Press the save button to record the questionnaire responses. <br />Risk recalculation will be automatically initiated and completed within 5 to 30 minutes.
                                </Text>
                                <Flex justifyContent="start" className="gap-4 items-center w-full">
                                    <a href="risk">
                                        <MDButton style={cysrConfig().button_style} size="medium">
                                            <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().back.icon.svg }} />
                                            Back to Risk page
                                        </MDButton>
                                    </a>
                                    <MDButton style={cysrConfig().button_style} size="medium" onClick={() => changedParametersNotOK.length === 0 ? fetch(`${cysrConfig().api_base_url}risk/${accountUUID}/question/`, {
                                        body: JSON.stringify({
                                            parameters: changedParametersToSave
                                        }),
                                        headers: {
                                            Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                            "content-type": "application/json",
                                        },
                                        method: "PATCH",
                                    })
                                        .then((response) => {
                                            if (response.ok) {
                                                console.info(changedParametersToSave)
                                                toast.success(`Your questionnaire responses have been successfully saved. The risk recalculation has been requested and will be completed within the next 5 to 30 minutes. Thank you for your submission`)
                                            } else {
                                                throw new Error(response);
                                            }
                                        })
                                        .then(() => {
                                        })
                                        .catch((err) => {
                                            console.error(err);
                                            console.error(changedParametersToSave)
                                            toast.error(`Error: ${JSON.stringify(err)}`)
                                        }) : toast.error(`Some fields (${changedParametersNotOK.length}) are not valid, correct them and press again to save`)}>
                                        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().update.icon.svg }} />
                                        Save and Request Risk Recalculation
                                    </MDButton>
                                </Flex>
                            </Card>
                            {
                                /*
                            }
                            <Card>
                                <Title>Preview (or history) of the impact these answers will produce to the risk</Title>
                                <Text className="mb-4">
                                    Save the answers to update the risk, this will change the risk badge accordingly to the provided answers.
                                </Text>
                                <Grid numItems={12} className="mt-16 py-5 gap-6 items-center" style={{
                                    minHeight: "200px"
                                }}>
                                    <Col numColSpan={6} numColSpanSm={2}>
                                        <Flex className="justify-start items-center">
                                            {views.riskBadgePreview && Object.values(views.riskBadgePreview).map(
                                                (r_type) => {
                                                    const method = r_type.CALCULATED;
                                                    return riskBadgePreview(method)
                                                }
                                            )}
                                        </Flex>
                                    </Col>
                                    <Col numColSpan={6} numColSpanSm={3}>
                                        <Flex className="justify-start items-center h-full">
                                            <div className="inline-flex flex-col">
                                                <Title>
                                                    Actual badge
                                                </Title>
                                                <Text>
                                                    This is your company current badge
                                                </Text>
                                            </div>
                                        </Flex>
                                    </Col>
                                    <Col numColSpan={12} numColSpanSm={2}>
                                        <span className="flex justify-center" dangerouslySetInnerHTML={{ __html: cysrConfig().double_right_arrow.icon.svg }} />
                                    </Col>
                                    <Col numColSpan={6} numColSpanSm={2}>
                                        <Flex className="justify-start items-center">
                                            {views.riskBadgePreview && Object.values(views.riskBadgePreview).map(
                                                (r_type) => {
                                                    const method = r_type.CALCULATED;
                                                    return riskBadgePreview(method)
                                                }
                                            )}
                                        </Flex>
                                    </Col>
                                    <Col numColSpan={6} numColSpanSm={3}>
                                        <Flex className="justify-start items-center h-full">
                                            <div className="inline-flex flex-col">
                                                <Title>
                                                    Queued badge
                                                </Title>
                                                <Text>
                                                    This is your company updated badge
                                                </Text>
                                            </div>
                                        </Flex>
                                    </Col>
                                </Grid>
        
                            </Card>
                            {
                                */
                            }
                        </div>

                    ) : <Loader />}
                </div>

            </>
        </DashboardLayout >
    )
}

export default Questionnaire;


function riskBadgePreview(method) {
    return (
        <MDBox style={{
            position: "relative"
        }} marginTop={{ xs: "16px", md: "-27.5px" }} height={{ xs: "112px", md: "160px" }}>
            <Flex className="ml-auto relative max-w-[124px] justify-end" style={{
                // transform: "scale(0.8163)",
                // transformOrigin: "100% 0",
                color: getRatingColorRiskActual(method),
            }}>
                <svg
                    className="max-w-[124px] w-full h-auto inline-flex" width="124" height="196" viewBox="0 0 124 196" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0V195.96H123.34V0H0ZM117.52 190.14H5.82V5.82H39.57V26.17H46.16V5.82H52.35V26.17H58.94V5.82H65.15L65.12 33.69H71.71L71.68 5.82H77.89V26.17H84.48V5.82H117.52V190.14Z" fill="currentColor" />
                </svg>
                <span style={{
                    left: 0,
                    bottom: 132,
                    color: "inherit",
                    right: 0,
                    fontSize: 14,
                    fontWeight: 500,
                    textAlign: "center"
                }} className='absolute'>
                    {method.companyrisk_type ? method.companyrisk_type : method.companyrisk_scenario}
                </span>
                <span style={{
                    left: 0,
                    bottom: 84,
                    color: "inherit",
                    right: 0,
                    fontSize: 32,
                    fontWeight: 500,
                    textAlign: "center"
                }} className='absolute'>
                    {valueFormatter(method.companyrisk_risk_avg, 1).length > badgeNumberMaxLengthForDecimal ?
                        valueFormatter(method.companyrisk_risk_avg, 0)
                        : valueFormatter(method.companyrisk_risk_avg, 1)}
                </span>
                <span style={{
                    bottom: 74,
                    color: "inherit",
                    left: 12,
                    right: 68,
                    fontSize: 12,
                    fontWeight: 500,
                    textAlign: "center",
                }} className='absolute'>
                    min
                </span>
                <span style={{
                    bottom: 56,
                    color: "inherit",
                    left: 12,
                    right: 68,
                    fontSize: 14,
                    fontWeight: 500,
                    textAlign: "center",
                }} className='absolute'>
                    {valueFormatter(method.companyrisk_risk_max < method.companyrisk_risk_min ?
                        method.companyrisk_risk_max
                        : method.companyrisk_risk_min, 2).length > badgeNumberMaxLengthForDecimal ?
                        valueFormatter(method.companyrisk_risk_max < method.companyrisk_risk_min ?
                            method.companyrisk_risk_max
                            : method.companyrisk_risk_min, 0)
                        :
                        valueFormatter(method.companyrisk_risk_max < method.companyrisk_risk_min ?
                            method.companyrisk_risk_max
                            : method.companyrisk_risk_min, 1)}
                </span>
                <span style={{
                    bottom: 74,
                    color: "inherit",
                    left: 52,
                    right: 52,
                    fontSize: 8,
                    fontWeight: 400,
                    textAlign: "center",
                }} className='absolute'>
                    {
                        // to
                    }
                </span>
                <span style={{
                    bottom: 74,
                    color: "inherit",
                    left: 68,
                    right: 12,
                    fontSize: 12,
                    fontWeight: 500,
                    textAlign: "center",
                }} className='absolute'>
                    max
                </span>
                <span style={{
                    bottom: 56,
                    color: "inherit",
                    left: 68,
                    right: 12,
                    fontSize: 14,
                    fontWeight: 500,
                    textAlign: "center",
                }} className='absolute'>
                    {valueFormatter(method.companyrisk_risk_min > method.companyrisk_risk_max ? method.companyrisk_risk_min : method.companyrisk_risk_max, 2).length > badgeNumberMaxLengthForDecimal ?
                        valueFormatter(method.companyrisk_risk_min > method.companyrisk_risk_max ? method.companyrisk_risk_min : method.companyrisk_risk_max, 0) :
                        valueFormatter(method.companyrisk_risk_min > method.companyrisk_risk_max ? method.companyrisk_risk_min : method.companyrisk_risk_max, 1)}
                </span>
                <span style={{
                    left: 0,
                    bottom: 32,
                    color: "inherit",
                    right: 0,
                    fontSize: 15,
                    fontWeight: 500,
                    textAlign: "center"
                }} className='absolute'>
                    {method.companyrisk_lef_avg < 0.1 ? "< 1 event" : `${(Math.ceil(1 / method.companyrisk_lef_avg) * method.companyrisk_lef_avg).toFixed(2)} events`}
                </span>
                <span style={{
                    left: 0,
                    bottom: 14,
                    color: "inherit",
                    right: 0,
                    fontSize: 16,
                    fontWeight: 500,
                    textAlign: "center"
                }} className='absolute'>
                    {
                        // method.companyrisk_lef_avg < 0.1 ? "Less than 1 event" : `${(Math.ceil(1 / method.companyrisk_lef_avg) * method.companyrisk_lef_avg).toFixed(2)} events in ${Math.ceil(1 / method.companyrisk_lef_avg)} years`}
                        method.companyrisk_lef_avg < 0.1 ? "in 10 year" : `in ${Math.ceil(1 / method.companyrisk_lef_avg)} years`}
                </span>
            </Flex>
        </MDBox>
    )
}
/* eslint-disable */
// prettier-ignore
