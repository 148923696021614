/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

import cysrConfig from "layouts/cysr/config";

// Material Dashboard 2 PRO React examples
import Breadcrumbs from "layouts/cysr/components/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

import { useSnackbar } from 'notistack';
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import SelectedCompany from "./SelectedCompany";

import RenderMarkdown from "layouts/cysr/components/RenderMarkdown"
import { ImportantMessage } from "layouts/cysr/components/ImportantMessage";
import RiskDialog from "layouts/cysr/components/RiskDialog";

function DashboardNavbar({ cysr_company, absolute, light, isMini, features = null, setFeatures = null, cti = null, dpo = null, risk_actual = null }) {
  const [myData, setMyData] = useState();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);

  const [notificationsBadgeContent, setNotificationsBadgeContent] = useState(0);
  const [riskBannerNotice, setRiskBannerNotice] = useState(false);
  const [riskBannerNoticeOpen, setRiskBannerNoticeOpen] = useState(false);

  const route = useLocation().pathname.split("/").slice(1);

  //snackBar s
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // add action to an individual snackbar
  const action = snackbarId => (
    <>
      <MDButton onClick={() => { closeSnackbar(snackbarId) }} style={cysrConfig().button_style} size="medium">
        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().close.icon.svg }} />
        Dismiss
      </MDButton>
    </>
  );
  const goToLogin = snackbarId => (
    <>
      <MDButton onClick={() => { window.location.href = "/cysr/login" }} style={cysrConfig().button_style} size="medium">
        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().login.icon.svg }} />
        Go to Login
      </MDButton>
    </>
  );
  //snackBar e

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const callUrl = `${cysrConfig().api_base_url}user/mydata/`;
  useEffect(() => {
    fetch(callUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === "token_not_valid") {
          setMyData(null)
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>Unhauthorized</MDTypography>
              <MDTypography variant="caption">{data.detail}</MDTypography>
            </div>, {
            action: goToLogin,
            variant: 'error',
            persist: true
          })
        } else {
          // refresh mydata in storage s
          if (data) {
            localStorage.setItem("cysr_mydata", JSON.stringify(data));
          }
          // refresh mydata in storage e
          setMyData(data)
          // setNotificationsBadgeContent(data.groups ? data.groups.length : 0)
          fetch(`${cysrConfig().api_base_url}notification/myfeed/?archived=false`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
            },
          })
            .then((response) => response.json())
            .then((responseAccountsRoleData) => {
              const notificationsCount = responseAccountsRoleData.events ? responseAccountsRoleData.events.filter((notification) => notification.read === false) : [];
              setNotificationsBadgeContent(notificationsCount.length)
              // check there is banner
              if (responseAccountsRoleData.banner) {
                setRiskBannerNotice(responseAccountsRoleData.banner)
              }
            })
            .catch((err) => {
              console.log(err)
            });
        }
      })
      .catch((aerr) => {
        console.log(aerr)
        setMyData(null)
        enqueueSnackbar(
          <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <MDTypography variant="caption" style={{ fontWeight: "600" }}>Error</MDTypography>
            <MDTypography variant="caption">{aerr.detail ? aerr.detail : aerr}</MDTypography>
          </div>, {
          action,
          variant: 'error',
          persist: true
        })
      });
  }, []);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {myData ? [
        <Link key="alert" to="/threats-intelligence/leaks">
          <NotificationItem icon={<Icon>warning</Icon>} title="New Leak Discovered!" />
        </Link>
      ] :
        <Link to="#">
          <NotificationItem icon={<Icon>email</Icon>} title="No new messages" />
        </Link>
      }
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <>
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
            <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          </MDBox>
          <MDBox ml="auto" style={{ display: "flex" }} alignItems="center">
            <MDTypography variant="caption">
              hi <strong>{localStorage.getItem("cysr_mydata") &&
                JSON.parse(localStorage.getItem("cysr_mydata")) &&
                JSON.parse(localStorage.getItem("cysr_mydata")).user &&
                JSON.parse(localStorage.getItem("cysr_mydata")).user.user_name ?
                JSON.parse(localStorage.getItem("cysr_mydata")).user.user_name :

                localStorage.getItem("cysr_mydata") &&
                  JSON.parse(localStorage.getItem("cysr_mydata")) &&
                  JSON.parse(localStorage.getItem("cysr_mydata")).user &&
                  JSON.parse(localStorage.getItem("cysr_mydata")).user &&
                  JSON.parse(localStorage.getItem("cysr_mydata")).user.user_email ?
                  JSON.parse(localStorage.getItem("cysr_mydata")).user.user_email : ""

              }</strong>
            </MDTypography>
          </MDBox>
          {isMini ? (
            <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
              <Icon fontSize="medium" sx={iconsStyle}>
                {miniSidenav ? "menu_open" : "menu"}
              </Icon>
            </IconButton>
          ) : (
            <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              <MDBox color={light ? "white" : "inherit"}>
                {
                  // <Link to="/cysr/login">
                }
                <MDButton style={{
                  background: "transparent", paddingRight: "8px", minWidth: "unset", paddingLeft: "8px"
                }} onClick={() => {
                  // remove localstorage and go to login
                  const dMode = localStorage.getItem('cysr_dark_mode') ? localStorage.getItem('cysr_dark_mode') : "true";
                  localStorage.clear();
                  localStorage.setItem('cysr_dark_mode', dMode);
                  window.location.href = `/cysr/login`;
                  // if available set the current account uuuid as selected e
                }}>
                  {
                    // <IconButton sx={navbarIconButton} size="small" disableRipple>
                  }
                  <Icon sx={iconsStyle}>logout</Icon>
                  {
                    // </IconButton>
                  }
                </MDButton>
                {
                  // </Link>
                }
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={handleConfiguratorOpen}
                >
                  <Icon sx={iconsStyle}>settings</Icon>
                </IconButton>
                <Link to="/cysr/profile">
                  <IconButton
                    size="small"
                    disableRipple
                    color="inherit"
                    sx={navbarIconButton}
                  >
                    <Icon sx={iconsStyle}>account_circle</Icon>
                  </IconButton>
                </Link>
                <Link to="/cysr/notifications">
                  <IconButton
                    size="small"
                    disableRipple
                    color="inherit"
                    sx={navbarIconButton}
                    aria-controls="notification-menu"
                    aria-haspopup="true"
                    variant="contained"
                  // onClick={handleOpenMenu}
                  >
                    <MDBadge badgeContent={notificationsBadgeContent} color="error" size="xs" circular>
                      <Icon sx={iconsStyle}>notifications</Icon>
                    </MDBadge>
                  </IconButton>
                </Link>
                {
                  // renderMenu()
                }
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon sx={iconsStyle} fontSize="medium">
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
              </MDBox>
            </MDBox>
          )}
        </Toolbar>
        {cysr_company && <SelectedCompany features={features} setFeatures={setFeatures} cysr_company={cysr_company === true ? false : cysr_company} cti={cti} dpo={dpo} risk_actual={risk_actual} />
        }
      </AppBar>
      {riskBannerNotice ? <div className={`risk-banner-notice ${darkMode ? "risk-banner-notice-dark" : "risk-banner-notice-light"}`}>
        <RiskDialog darkMode={darkMode} title={riskBannerNotice.title}
          trigger={

            (
              <ImportantMessage
                className={`risk-banner-notice-inside text-left w-full pl-11 pr-4 py-3 border-b-red-500/50 border-x-0 border-t-0 border-b bg-red-500/25 ${darkMode ? "text-white" : "text-black"}`}
                title={<RenderMarkdown>{riskBannerNotice.title}</RenderMarkdown>}
                button={"Learn more"}
              />
            )
          } content={<div className={`text-left w-full py-2 ${darkMode ? "text-white" : "text-black"}`}>
            <RenderMarkdown>{riskBannerNotice.content}</RenderMarkdown>
          </div>}
        />
      </div> : null}
    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  cysr_company: false,
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  cysr_company: PropTypes.any,
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
/* eslint-disable */