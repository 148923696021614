/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
/*
// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
// import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";
*/
/* eslint-disable */
// prettier-ignore
// import DataTables from "layouts/applications/data-tables";
// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
// import profilePicture from "assets/images/team-3.jpg";
import profilePictureIt from "layouts/cysr/images/italy-flag.svg";
import profilePictureEn from "layouts/cysr/images/europe-flag.svg";

// comply
import Comply from "layouts/cysr/comply";
import ComplyQuestionnaire from "layouts/cysr/comply/questionnaire";
// cysr
import Companies from "layouts/cysr/companies";
import Insight from "layouts/cysr/insight";
import Notifications from "layouts/cysr/notifications";
import Archive from "layouts/cysr/archive";
// threats-intelligence
import ThreatsIntelligence from "layouts/cysr/threats-intelligence";
import Surveillance from "layouts/cysr/surveillance";
// import Botnets from "layouts/cysr/botnets";
import Risk from "layouts/cysr/risk";
import Questionnaire from "layouts/cysr/risk/questionnaire";
import Reports from "layouts/cysr/reports";
import Botnets from "layouts/cysr/cti";
import BotnetsArchive from "layouts/cysr/cti-archive";
import Leaks from "layouts/cysr/leaks";
import Events from "layouts/cysr/events";
import Actors from "layouts/cysr/actors";
import Actor from "layouts/cysr/actor";
import Countries from "layouts/cysr/countries";
// import Dashboards from "layouts/cysr/dashboards";
// import Dashboard from "layouts/cysr/dashboard";
import Overview from "layouts/cysr/overview";
import OverviewThirdParty from "layouts/cysr/overview-third-party";
import Report from "layouts/cysr/report";
import ReportExcel from "layouts/cysr/report-excel";
import ReportCTI from "layouts/cysr/report-cti";
import ReportRisk from "layouts/cysr/report-risk";
import Chart from "layouts/cysr/chart";
import ChartGeneral from "layouts/cysr/chart-general";
import ChartSolar from "layouts/cysr/chart-clustered";
import Radar from "layouts/cysr/radar";
import ReportChartRadar from "layouts/cysr/report-chart-radar";
import ReportChartCircle from "layouts/cysr/report-chart-circle";
import ReportChart1 from "layouts/cysr/report-chart-1";
import ReportChart2 from "layouts/cysr/report-chart-2";
import ReportChart3 from "layouts/cysr/report-chart-3";
import ReportChart4 from "layouts/cysr/report-chart-4";
import ReportChart5 from "layouts/cysr/report-chart-5";
import Timeline from "layouts/cysr/timeline";
import Timeshift from "layouts/cysr/timeshift";
import Circle from "layouts/cysr/circle";
import Login from "layouts/cysr/login";
import Invitation from "layouts/cysr/invitation";
import InvitationDPO from "layouts/cysr/invitation-dpo";
import RevocationDPO from "layouts/cysr/revocation-dpo";
import InvitationTerms from "layouts/cysr/invitation/terms";
import Assets from "layouts/cysr/assets";
import Asset from "layouts/cysr/asset";
import Profile from "layouts/cysr/profile";
import Help from "layouts/cysr/help";

import ManageBillingInfo from "layouts/cysr/subscribers";

import Subscription from "layouts/cysr/subscription";
import Subscriptions from "layouts/cysr/subscriptions";
import CompanyEditInfo from "layouts/cysr/company-edit-info";
import AdminArea from "layouts/cysr/admin-area";

// import ChecksOG from "layouts/cysr/checks_og";
import Checks from "layouts/cysr/checks";
import Issue from "layouts/cysr/issue";
import Issues from "layouts/cysr/issues";
import Vulnerabilities from "layouts/cysr/vulnerabilities";
import ThirdParties from "layouts/cysr/third-parties";
import cysrConfig from "layouts/cysr/config";
// import Check from "layouts/authentication/reset-password/cover";
import { getCompany, getCompanies, getCompaniesCount, removeCompaniesDB, updateCompanies } from "db";

const renderRoutes = [];
let userAccounts = [];
let userAccountsCount = 0;
// check version s
// if (window.location.pathname.indexOf("/login") > -1 || window.location.pathname.indexOf("/terms-and-conditions") > -1 || window.location.pathname.indexOf("/invitation") > -1 || window.location.pathname.indexOf("/report-chart") > -1) {
//   // already in login
// } else if (cysrConfig().version != localStorage.getItem("cysr_version")) {
//   const dMode = localStorage.getItem('cysr_dark_mode') ? localStorage.getItem('cysr_dark_mode') : "true";
//   localStorage.clear();
//   localStorage.setItem('cysr_dark_mode', dMode);
//   window.location.href = `/cysr/login?new-version=${cysrConfig().version}`;
// }

// check version e
// fix multi tabs s
let fixAccountUUID = "";
if (window.location.pathname.indexOf("/company/") > -1) {
  fixAccountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/company/") + "/company/".length,
    window.location.pathname.lastIndexOf("/")
  );
  if (fixAccountUUID) {
    if (localStorage.getItem("cysr_useraccountrole_selected") !== fixAccountUUID) {
      localStorage.setItem("cysr_useraccountrole_selected", fixAccountUUID)
    }
  }
}
if (window.location.pathname.indexOf("/third-party/") > -1) {
  fixAccountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/third-party/") + "/third-party/".length,
    window.location.pathname.lastIndexOf("/")
  );
  if (fixAccountUUID) {
    if (localStorage.getItem("cysr_useraccountrole_selected") !== fixAccountUUID) {
      localStorage.setItem("cysr_useraccountrole_selected", fixAccountUUID)
    }
  }
}
// fix multi tabs e
if (window.location.pathname.indexOf("/invitation") > -1 || window.location.pathname.indexOf("/report-chart") > -1) {
  // do not do various things by removing this:
  localStorage.removeItem("cysr_useraccountrole_selected");
}
if (localStorage.getItem("cysr_useraccountrole_selected")) {

  // check if is not in cysr_useraccountroles (localStorage)
  // The find() method returns the first element in the provided array that satisfies the provided testing function. If no values satisfy the testing function, undefined is returned.
  //DB GET
  if (
    // localStorage.getItem("cysr_useraccountroles") === undefined
    //  || (localStorage.getItem("cysr_useraccountroles") ? (JSON.parse(localStorage.getItem("cysr_useraccountroles")).find((c) => c.account_uuid === localStorage.getItem("cysr_useraccountrole_selected"))
    //  ) : undefined) === undefined
    await getCompany(localStorage.getItem("cysr_useraccountrole_selected")) === undefined
  ) {
    // call if need to refresh s
    // s
    // get accountrole of user s
    const callUrl = `${cysrConfig().api_base_url}user/mycompanies/`;
    fetch(callUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      },
    })
      .then(async (response) => {
        if (response.code && response.code === "token_not_valid") {
          // remove localstorage and go to login
          await removeCompaniesDB()
          localStorage.removeItem("cysr_useraccountrole_selected")
          localStorage.removeItem("cysr_useraccountroles")
          localStorage.removeItem("cysr_access")
          localStorage.removeItem("cysr_refresh")
          window.location.href = `/cysr/login`;
          return
        }
        if (response && response.ok) {
          return response.json();
        }
        if (response.status && response.status === 401) {
          // Unauthorized 401
          // remove localstorage and go to login
          const dMode = localStorage.getItem('cysr_dark_mode') ? localStorage.getItem('cysr_dark_mode') : "true";
          localStorage.clear();
          localStorage.setItem('cysr_dark_mode', dMode);
          window.location.href = `/cysr/login`;

          return
        };
        throw new Error(response);
      })
      .then(async data => {
        if (data) {
          /*
          [
            {
              id: 6
              useraccountrole_account: "00000000-0faa-4e83-9bef-4ee47d21883a"
              useraccountrole_role: 1
              useraccountrole_user: 10
            }
          ]
          */
          // localStorage.setItem("cysr_useraccountroles", JSON.stringify(data));
          // up DB
          await updateCompanies({ data: data })
          // set as true to verify it has data
          localStorage.setItem("cysr_useraccountroles", true);
          // console.log("cysr_useraccountroles refreshed");
        } else {
          console.error(data);
        }
      })
      .catch(async (err) => {
        console.error(err);

        if (await getCompanies()) {
          // non ha fatto in tempo a caricare
        } else {
          // se non auth rimuovere cysr_useraccountroles e refresh          
          await removeCompaniesDB()
          localStorage.removeItem("cysr_useraccountroles")
          window.location.reload();
        }
      })
  }
  // call if need to refresh e
  // e
  // userAccounts = JSON.parse(localStorage.getItem("cysr_useraccountroles"));
  userAccounts = await getCompanies();
  userAccounts.sort((a, b) =>
    b.account_name
      .toLowerCase()
      .localeCompare(a.account_name.toLowerCase())
  );
  userAccountsCount = userAccounts.length;
}
renderRoutes.push(
  /*
  {
    type: "collapse",
    name: "Dashboards",
    key: "cysr-dashboards",
    icon: <Icon fontSize="small">dashboard</Icon>,
    // actual path:
    // path: "/cysr/dashboards",
    // how appears:
    // route: "/dashboard",
    route: "/cysr/dashboards",
    component: <Dashboards />,
    noCollapse: true,
  },
  */
  { type: "title", title: "Dashboard", key: "cysr-title-dashboard" },
  {
    type: "collapse",
    name: "Companies",
    key: "cysr-companies",
    icon: <Icon fontSize="small">list</Icon>,
    // actual path:
    // path: "/cysr/dashboards",
    // how appears:
    // route: "/dashboard",
    route: "/cysr/companies",
    component: <Companies />,
    noCollapse: true,
  }
)
if (localStorage.getItem("cysr_mydata") && JSON.parse(localStorage.getItem("cysr_mydata")) && JSON.parse(localStorage.getItem("cysr_mydata")).modules && JSON.parse(localStorage.getItem("cysr_mydata")).modules.indexOf("CYSR/THIRDPARTY") > -1) {
  renderRoutes.push(
    {
      type: "collapse",
      name: "Third parties",
      key: "cysr-third-parties-list",
      icon: <Icon fontSize="medium">device_hub</Icon>,
      //path: "/cysr/third-parties",
      route: `/cysr/third-parties`,
      component: <ThirdParties />,
      noCollapse: true,
    }
  )
}
if (localStorage.getItem("cysr_mydata") && JSON.parse(localStorage.getItem("cysr_mydata")) && JSON.parse(localStorage.getItem("cysr_mydata")).modules && JSON.parse(localStorage.getItem("cysr_mydata")).modules.indexOf("GROUPINSIGHT") > -1) {
  renderRoutes.push(
    // insight s
    {
      type: "collapse",
      name: "Group insight",
      key: "cysr-insight",
      icon: <Icon fontSize="small">insights</Icon>,
      // actual path:
      // path: "/cysr/dashboards",
      // how appears:
      // route: "/dashboard",
      route: "/cysr/insight",
      component: <Insight />,
      noCollapse: true,
    }
  )
}
// insight e

renderRoutes.push(
  {
    type: "collapse",
    name: "Archive",
    key: "cysr-archive",
    icon: <Icon fontSize="small">archive</Icon>,
    // actual path:
    // path: "/cysr/dashboards",
    // how appears:
    // route: "/dashboard",
    route: "/cysr/archive",
    component: <Archive />,
    noCollapse: true,
  }
  //{ type: "title", title: `Companies (${userAccountsCount})`, key: "cysr-title-accounts" }
);
// SALESADMIN
if (localStorage.getItem("cysr_mydata") && JSON.parse(localStorage.getItem("cysr_mydata")) && JSON.parse(localStorage.getItem("cysr_mydata")).modules && JSON.parse(localStorage.getItem("cysr_mydata")).modules.indexOf("SALESADMIN") > -1) {
  renderRoutes.push(
    { type: "divider", key: "cysr-divider-r-t" },
    { type: "title", title: "Admin", key: "cysr-admin-title" },
    {
      type: "collapse",
      noCollapse: true,
      icon: <Icon fontSize="medium">difference</Icon>,
      name: "Sales Admin",
      key: `cysr-sales-admin`,
      path: "/cysr/sales-admin",
      //route: `/company/${a.account_uuid}/cti`,
      route: "/cysr/sales-admin",
      component: <AdminArea />,
    },
    {
      type: "collapse",
      noCollapse: true,
      icon: <Icon fontSize="medium">money</Icon>,
      name: "Manage Subscribers",
      key: `cysr-sales-admin-ms`,
      path: "/cysr/profile/subscribers",
      //route: `/company/${a.account_uuid}/cti`,
      route: "/cysr/profile/subscribers",
      component: <ManageBillingInfo />,
    },
    {
      type: "collapse",
      noCollapse: true,
      icon: <Icon fontSize="medium">difference</Icon>,
      name: "Manage Subscriptions",
      key: `cysr-sales-admin-mss`,
      path: "/cysr/profile/manage-subscriptions",
      //route: `/company/${a.account_uuid}/cti`,
      route: "/cysr/profile/manage-subscriptions",
      component: <Subscriptions />,
    }
  )
}

/*
renderRoutes.push(
  {
    type: "collapse",
    name: "Profile",
    key: "cysr-profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/cysr/profile",
    component: <Profile />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Help",
    key: "cysr-help",
    icon: <Icon fontSize="small">support</Icon>,
    route: "/cysr/help",
    component: <Help />,
    noCollapse: true,
  }
  //{ type: "title", title: `Companies (${userAccountsCount})`, key: "cysr-title-accounts" }
);
*/
// '[{"id":6,"useraccountrole_user":10,"useraccountrole_account":"8c75a44b-0faa-4e83-9bef-4ee47d21883a","useraccountrole_role":1}]'
// if (localStorage.getItem("cysr_useraccountroles")) {
if (await getCompaniesCount()) {
  let missingID = 0;
  let missingObject = false;
  let missingName = false;
  if (window.location.pathname.indexOf("/company/") > -1) {
    missingID = window.location.pathname.substring(
      window.location.pathname.indexOf("/company/") + "/company/".length,
      window.location.pathname.lastIndexOf("/")
    );
  }
  if (window.location.pathname.indexOf("/third-party/") > -1) {
    missingID = window.location.pathname.substring(
      window.location.pathname.indexOf("/third-party/") + "/third-party/".length,
      window.location.pathname.lastIndexOf("/")
    );
  }
  while (userAccountsCount > 0) {
    userAccountsCount -= 1;
    let a = userAccounts[userAccountsCount];
    // here adding a check on cysr_useraccountrole_selected s
    if (localStorage.getItem("cysr_useraccountrole_selected")) {
      if (a.account_uuid !== localStorage.getItem("cysr_useraccountrole_selected")) {
        // if in /company/ check if equal to pathname
        if (missingID == localStorage.getItem("cysr_useraccountrole_selected")) {
          // check is 3rd party s
          const missingNameDefaultString = "Company";
          if (window.location.pathname.indexOf("/third-party/") > -1 && (missingName != missingNameDefaultString)) {
            // call directly the api to get the name s
            const missingNameResponse = await fetch(`${cysrConfig().api_base_url}dashboard/pageheader/${missingID}/`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
              },
            })
            const missingNameJson = await missingNameResponse.json();
            let missingNameString = (missingNameJson && missingNameJson.company_profile && missingNameJson.company_profile.account_name) ? missingNameJson.company_profile.account_name : missingNameDefaultString;
            let missingNameLength = 28;
            missingName = missingNameString.length > missingNameLength ? missingNameString.substring(0, missingNameLength - 3) + "..." : missingNameString.substring(0, missingNameLength);
            userAccountsCount = -1;
            // call directly the api to get the name e
          }
          // check is 3rd party e
          // found it, use this
          missingObject = {
            /*
            1/1 diventa lento per molte commpanies s:
            account_name: localStorage.getItem("cysr_useraccountroles") && JSON.parse(localStorage.getItem("cysr_useraccountroles")).find((stCU) => stCU.account_uuid === missingID) ? JSON.parse(localStorage.getItem("cysr_useraccountroles")).find((stCU) => stCU.account_uuid === missingID).account_name : (
              localStorage.getItem("cysr_useraccountroles") && JSON.parse(localStorage.getItem("cysr_useraccountroles")).find((stCU) => (
                Object.entries(stCU.linked_thirdparties).find((x) => x[0] === missingID)
              ))
                && Object.entries(JSON.parse(localStorage.getItem("cysr_useraccountroles")).find((stCU) => (
                  Object.entries(stCU.linked_thirdparties).find((x) => x[0] === missingID)
                )).linked_thirdparties).find((z) => z[0] === missingID)
                ? Object.entries(JSON.parse(localStorage.getItem("cysr_useraccountroles")).find((stCU) => (
                  Object.entries(stCU.linked_thirdparties).find((x) => x[0] === missingID)
                )).linked_thirdparties).find((z) => z[0] === missingID)[1] :
                missingID
            ),
            1/1 diventa lento per molte companies e
            */
            account_name: (missingName ? missingName : ""),
            account_uuid: missingID
          }
        }
        if (userAccountsCount > 0) {
          continue;
        }
      }
    } else {
      // no company will be shown
      break;
    }
    if (a.account_uuid !== localStorage.getItem("cysr_useraccountrole_selected") && missingObject) {
      a = missingObject;
    } else if(a.account_uuid == localStorage.getItem("cysr_useraccountrole_selected")){
      // call directly the api to get the active_modules s
      const selectedPageheader = await fetch(`${cysrConfig().api_base_url}dashboard/pageheader/${a.account_uuid}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
      })
      let rSP = await selectedPageheader.json();
      a.active_modules = rSP.active_modules
        // call directly the api to get the active_modules e
    }
    // here adding a check on cysr_useraccountrole_selected e
    const navigatorTab = {
      type: "collapse",
      noCollapse: true,
      name: a.account_name,
      key: `cysr-menu-${a.account_uuid}`,
      icon: (
        <MDAvatar src={a.account_vat_country_code && a.account_vat_country_code === "IT" ? profilePictureIt : profilePictureEn} alt={a.account_name} size="xs" />
      ),
      path: `/cysr/overview`,
      // this is how it appears in the browser:
      route: `/company/${a.account_uuid}/overview`,
      component: <Overview />,
      /*
      collapse: [
        /*
        {
          name: "Dashboard",
          key: `cysr-dashboard-list-${a.account_uuid}`,
          icon: <Icon fontSize="small">dashboard</Icon>,
          // this is the actual path:
          path: `/cysr/dashboard`,
          // this is how it appears in the browser:
          route: `/company/${a.account_uuid}/dashboard`,
          component: <Dashboard />,
        },
        */
      /*
       {
         name: "Overview",
         key: `cysr-overview-list-${a.account_uuid}`,
         icon: <Icon fontSize="small">dashboard</Icon>,
         // this is the actual path:
         path: `/cysr/overview`,
         // this is how it appears in the browser:
         route: `/company/${a.account_uuid}/overview`,
         component: <Overview />,
       },
       {
         name: "Assets",
         key: `cysr-assets-list-${a.account_uuid}`,
         icon: <Icon fontSize="medium">apps</Icon>,
         // http://www.hackingwithreact.com/read/1/24/making-custom-urls-with-react-router-params
         // route: `/cysr/assets:${a.account_uuid}`,
         // this is the actual path:
         path: `/cysr/assets`,
         // this is how it appears in the browser:
         route: `/company/${a.account_uuid}/assets`,
         component: <Assets />,
       },
       {
         name: "Asset",
         key: `cysr-asset-detail-${a.account_uuid}`,
         // route: `/cysr/asset`,
         path: `/cysr/asset`,
         route: `/company/${a.account_uuid}/asset`,
         hide: true,
         component: <Asset />,
       },
       {
         name: "Checks",
         key: `cysr-checks-list-${a.account_uuid}`,
         path: "/cysr/checks",
         route: `/company/${a.account_uuid}/checks`,
         component: <Checks />,
       },
  
       /*
       {
         name: "Check",
         key: `cysr-check-detail-${a.id}`,
         path: "/applications/data-tables",
         route: `/company/${a.account_uuid}/check`,
         hide: true,
         component: <DataTables />,
       },
       */
      /*
     ],
     */
    };
    renderRoutes.push(
      { type: "divider", key: "cysr-divider-accounts" }
    );

    if (window.location.pathname.indexOf("/third-party/") > -1) {
      renderRoutes.push(
        { type: "title", title: a.account_name, key: `cysr-title-tp-${a.account_uuid}` }
      )
      // third-party overview s
      renderRoutes.push({
        type: "collapse",
        noCollapse: true,
        name: "Overview",
        key: `cysr-overview-third-party-list-${a.account_uuid}`,
        icon: <Icon fontSize="small">group</Icon>,
        // this is the actual path:
        path: `/cysr/overview-third-party`,
        // this is how it appears in the browser:
        route: `/third-party/${a.account_uuid}/overview`,
        component: <OverviewThirdParty />,
      });
      // third-party overview e
      renderRoutes.push({
        // type: "collapse",
        hide: true,
        name: "Report",
        key: `cysr-tp-report-${a.account_uuid}`,
        icon: <Icon fontSize="small">login</Icon>,
        // this is the actual path:
        path: `/cysr/report`,
        route: `/third-party/${a.account_uuid}/report`,
        component: <Report />,
        noCollapse: true,
      });
      renderRoutes.push({
        // type: "collapse",
        hide: true,
        name: "CTI Report",
        key: `cysr-tp-report-cti-${a.account_uuid}`,
        icon: <Icon fontSize="small">login</Icon>,
        // this is the actual path:
        path: `/cysr/report-cti`,
        route: `/third-party/${a.account_uuid}/report-cti`,
        component: <ReportCTI />,
        noCollapse: true,
      });
      /*
      renderRoutes.push({
        // type: "collapse",
        hide: true,
        name: "Risk Report",
        key: `cysr-tp-report-risk-${a.account_uuid}`,
        icon: <Icon fontSize="small">login</Icon>,
        // this is the actual path:
        path: `/cysr/report-risk`,
        route: `/third-party/${a.account_uuid}/report-risk`,
        component: <ReportRisk />,
        noCollapse: true,
      });
      */
    } else {
      // ! use title instead of flagged company name
      // renderRoutes.push(navigatorTab);
      var string = a.account_name;
      var length = 28;
      var trimmedString = string.length > length ? string.substring(0, length - 3) + "..." : string.substring(0, length);
      renderRoutes.push(
        { type: "title", title: `${trimmedString}`, key: `cysr-title-${a.account_uuid}` }
      )
      // overview
      renderRoutes.push({
        type: "collapse",
        noCollapse: true,
        name: "Overview",
        key: `cysr-overview-list-${a.account_uuid}`,
        icon: <Icon fontSize="small">dashboard</Icon>,
        // this is the actual path:
        path: `/cysr/overview`,
        // this is how it appears in the browser:
        route: `/company/${a.account_uuid}/overview`,
        component: <Overview />,
      });
      renderRoutes.push({
        // type: "collapse",
        hide: true,
        name: "Report",
        key: `cysr-report-${a.account_uuid}`,
        icon: <Icon fontSize="small">login</Icon>,
        // this is the actual path:
        path: `/cysr/report`,
        route: `/company/${a.account_uuid}/report`,
        component: <Report />,
        noCollapse: true,
      });
      renderRoutes.push({
        // type: "collapse",
        hide: true,
        name: "Report xlsx",
        key: `cysr-report-excel-${a.account_uuid}`,
        icon: <Icon fontSize="small">login</Icon>,
        // this is the actual path:
        path: `/cysr/report-excel`,
        route: `/company/${a.account_uuid}/report-excel`,
        component: <ReportExcel />,
        noCollapse: true,
      });
      renderRoutes.push({
        // type: "collapse",
        hide: true,
        name: "CTI Report",
        key: `cysr-report-cti-${a.account_uuid}`,
        icon: <Icon fontSize="small">login</Icon>,
        // this is the actual path:
        path: `/cysr/report-cti`,
        route: `/company/${a.account_uuid}/report-cti`,
        component: <ReportCTI />,
        noCollapse: true,
      });
      renderRoutes.push({
        // type: "collapse",
        hide: true,
        name: "Risk Report",
        key: `cysr-report-risk-${a.account_uuid}`,
        icon: <Icon fontSize="small">login</Icon>,
        // this is the actual path:
        path: `/cysr/report-risk`,
        route: `/company/${a.account_uuid}/report-risk`,
        component: <ReportRisk />,
        noCollapse: true,
      });
    }
    renderRoutes.push({
      hide: true,
      name: "Chart",
      key: `cysr-chart-${a.account_uuid}`,
      icon: <Icon fontSize="small">login</Icon>,
      // this is the actual path:
      path: `/cysr/chart`,
      route: `/company/${a.account_uuid}/chart`,
      component: <Chart />,
      noCollapse: true,
    });
    renderRoutes.push({
      hide: true,
      name: "Chart General",
      key: `cysr-chart-general-${a.account_uuid}`,
      icon: <Icon fontSize="small">login</Icon>,
      // this is the actual path:
      path: `/cysr/chart-general`,
      route: `/company/${a.account_uuid}/chart-general`,
      component: <ChartGeneral />,
      noCollapse: true,
    });
    renderRoutes.push({
      hide: true,
      name: "Chart Clustered",
      key: `cysr-chart-clustered-${a.account_uuid}`,
      icon: <Icon fontSize="small">login</Icon>,
      // this is the actual path:
      path: `/cysr/chart-clustered`,
      route: `/company/${a.account_uuid}/chart-clustered`,
      component: <ChartSolar />,
      noCollapse: true,
    });
    renderRoutes.push({
      hide: true,
      name: "Radar",
      key: `cysr-radar-${a.account_uuid}`,
      icon: <Icon fontSize="small">login</Icon>,
      // this is the actual path:
      path: `/cysr/radar`,
      route: `/company/${a.account_uuid}/radar`,
      component: <Radar />,
      noCollapse: true,
    });
    renderRoutes.push({
      hide: true,
      name: "Timeline",
      key: `cysr-timeline-${a.account_uuid}`,
      icon: <Icon fontSize="small">login</Icon>,
      // this is the actual path:
      path: `/cysr/timeline`,
      route: `/company/${a.account_uuid}/timeline`,
      component: <Timeline />,
      noCollapse: true,
    });
    renderRoutes.push({
      hide: true,
      name: "Circle",
      key: `cysr-circle-${a.account_uuid}`,
      icon: <Icon fontSize="small">login</Icon>,
      // this is the actual path:
      path: `/cysr/circle`,
      route: `/company/${a.account_uuid}/circle`,
      component: <Circle />,
      noCollapse: true,
    });
    if (
      window.location.pathname.indexOf("/third-party/") > -1
      || a.account_has_starter_subscription
    ) {
      // do not add assets, checks and CTI in third-party or account_has_starter_subscription
    } else {
      // assets
      renderRoutes.push({
        type: "collapse",
        noCollapse: true,
        name: "Assets",
        key: `cysr-assets-list-${a.account_uuid}`,
        icon: <Icon fontSize="medium">apps</Icon>,
        // http://www.hackingwithreact.com/read/1/24/making-custom-urls-with-react-router-params
        // route: `/cysr/assets:${a.account_uuid}`,
        // this is the actual path:
        path: `/cysr/assets`,
        // this is how it appears in the browser:
        route: `/company/${a.account_uuid}/assets`,
        component: <Assets />,
      })
      renderRoutes.push({
        name: "Asset",
        key: `cysr-asset-detail-${a.account_uuid}`,
        // route: `/cysr/asset`,
        path: `/cysr/asset`,
        route: `/company/${a.account_uuid}/asset`,
        hide: true,
        component: <Asset />,
      })
      renderRoutes.push({
        name: "Issue",
        key: `cysr-issue-detail-${a.account_uuid}`,
        // route: `/cysr/asset`,
        path: `/cysr/issue`,
        route: `/company/${a.account_uuid}/issue`,
        hide: true,
        component: <Issue />,
      })
      renderRoutes.push({
        type: "collapse",
        noCollapse: true,
        icon: <Icon fontSize="medium">nearby_error</Icon>,
        name: "Issues",
        key: `cysr-issues-list-${a.account_uuid}`,
        path: "/cysr/issues",
        route: `/company/${a.account_uuid}/issues`,
        component: <Issues />,
      })
      renderRoutes.push({
        type: "collapse",
        noCollapse: true,
        icon: <Icon fontSize="medium">pest_control_sharp</Icon>,
        name: "Vulnerabilities",
        key: `cysr-vulnerabilities-list-${a.account_uuid}`,
        path: "/cysr/vulnerabilities",
        route: `/company/${a.account_uuid}/vulnerabilities`,
        component: <Vulnerabilities />,
      })
      renderRoutes.push({
        type: "collapse",
        noCollapse: true,
        icon: <Icon fontSize="medium">check</Icon>,
        name: "Checks",
        key: `cysr-checks-list-${a.account_uuid}`,
        path: "/cysr/checks",
        route: `/company/${a.account_uuid}/checks`,
        component: <Checks />,
      })
      renderRoutes.push({
        type: "collapse",
        noCollapse: true,
        icon: <Icon fontSize="medium">restore</Icon>,
        name: "Timeshift",
        key: `cysr-timeshift-${a.account_uuid}`,
        path: "/cysr/timeshift",
        route: `/company/${a.account_uuid}/timeshift`,
        component: <Timeshift />,
      })
      // if (localStorage.getItem("cysr_mydata") && JSON.parse(localStorage.getItem("cysr_mydata")) && JSON.parse(localStorage.getItem("cysr_mydata")).modules && JSON.parse(localStorage.getItem("cysr_mydata")).modules.indexOf("CTI") > -1) {
      // controllare se c'è il modulo CTI dentro il cysr_useraccountroles selezionato (a)
      // if a.CTIENABLEDATTRIBUTE ...
      // if (a && a.modules && a.modules.indexOf("CTI") > -1) {
      if (a && a.active_modules && a.active_modules.indexOf("CTI") > -1) {
        renderRoutes.push(
          // { type: "divider", key: "cysr-divider-r-t" },
          // { type: "title", title: "Threats Intelligence", key: "cysr-title-threats-intelligence" },
          {
            type: "collapse",
            noCollapse: true,
            icon: <Icon fontSize="medium">hub</Icon>,
            name: "CTI",
            key: `cysr-cti-${a.account_uuid}`,
            path: "/cysr/cti",
            route: `/company/${a.account_uuid}/cti`,
            component: <Botnets />,
          },
          {
            hide: true,
            name: "CTI Archive",
            key: `cysr-cti-${a.account_uuid}-archive`,
            path: "/cysr/cti-archive",
            route: `/company/${a.account_uuid}/cti-archive`,
            component: <BotnetsArchive />,
          })
      }

      // risk s
      // if (true || a && a.modules && a.modules.indexOf("RISK") > -1) {
      // if (a && a.modules && a.modules.indexOf("RISK") > -1) {
      if (a && a.active_modules && a.active_modules.indexOf("RISK") > -1) {
        renderRoutes.push({
          type: "collapse",
          noCollapse: true,
          // icon: <Icon fontSize="medium">error_outline_sharp</Icon>,
          // icon: <Icon fontSize="medium">trending_down_sharp</Icon>,
          icon: <Icon fontSize="medium"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_946_3027)">
              <path d="M16.8 14L19.548 11.3283L13.692 5.635L8.892 10.3017L0 1.645L1.692 0L8.892 7L13.692 2.33333L21.252 9.67167L24 7V14H16.8Z" fill="currentColor" />
              <path d="M7.995 22.335C6.315 22.335 4.875 21.39 4.155 19.995H7.995V18.66H3.72C3.69 18.435 3.66 18.225 3.66 18C3.66 17.775 3.675 17.55 3.72 17.34H7.995V16.005H4.155C4.875 14.625 6.33 13.665 7.995 13.665C9.075 13.665 10.05 14.055 10.815 14.715L12 13.53C10.935 12.57 9.54 12 7.995 12C5.385 12 3.165 13.68 2.34 16.005H0V17.34H2.04C2.01 17.565 1.995 17.775 1.995 18C1.995 18.225 1.995 18.45 2.04 18.66H0V19.995H2.34C3.165 22.32 5.385 24 7.995 24C9.54 24 10.935 23.415 12 22.47L10.815 21.285C10.065 21.945 9.075 22.335 7.995 22.335Z" fill="currentColor" />
            </g>
            <defs>
              <clipPath id="clip0_946_3027">
                <rect width="24" height="24" fill="transparent" />
              </clipPath>
            </defs>
          </svg>
          </Icon>,
          name: "Risk",
          key: `cysr-risk-${a.account_uuid}`,
          path: "/cysr/risk",
          route: `/company/${a.account_uuid}/risk`,
          component: <Risk />
        })
        renderRoutes.push({
          name: "Questionnaire",
          key: `cysr-risk-questionnaire-${a.account_uuid}`,
          path: "/cysr/risk-questionnaire",
          route: `/company/${a.account_uuid}/risk-questionnaire`,
          hide: true,
          component: <Questionnaire />
        })
      }
      // risk e
      // reports s
      renderRoutes.push({
        type: "collapse",
        noCollapse: true,
        icon: <Icon fontSize="medium">receipt_long</Icon>,
        name: "Reports",
        key: `cysr-reports-list-${a.account_uuid}`,
        path: "/cysr/reports",
        route: `/company/${a.account_uuid}/reports`,
        component: <Reports />,
      })
      // reports e
      // comply s
      // if (a && a.modules && a.modules.indexOf("COMPLY") > -1) {
      if (a && a.active_modules && a.active_modules.indexOf("COMPLY") > -1) {
        renderRoutes.push(
          { type: "divider", key: "cysr-divider-comply" },
          { type: "title", title: "Compliance", key: "title-comply" },
          {
            type: "collapse",
            noCollapse: true,
            icon: <Icon fontSize="medium">
              {
                //<!-- edit of file-certificate icon by Free Icons (https://free-icons.github.io/free-icons/) -->
              }
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1092_3347)">
                  <path d="M10.6306 21.6557H21.7718V7.54356H16.5725V2.34433H8.40232V5.59385L8.30948 5.501L7.2882 5.9188L6.17408 6.38301V2.34433V0.116089H8.40232H18.058L24 6.05806V21.6557V23.884H21.7718H10.6306V21.6557ZM4.5029 7.31145L4.78143 7.40429L6.17408 7.96135L7.56673 7.40429L7.84526 7.31145L7.98453 7.54356L8.91296 8.7041L10.4449 8.9362H10.7234V9.21474L10.9555 10.7467L12.1161 11.6287L12.3482 11.8143L12.2553 12.0929L11.6983 13.4855L12.2553 14.8782L12.3482 15.1567L12.1625 15.296L10.9555 16.2244L10.7234 17.7563V18.0349H10.4449L9.14507 18.2205V22.884L6.17408 21.0271L3.20309 22.884V18.2205L1.90329 18.0349H1.62476V17.7563L1.39265 16.2244L0.185687 15.296L0 15.1567L0.0928433 14.8782L0.649903 13.4855L0.0928433 12.0929L0 11.8143L0.185687 11.6751L1.39265 10.7467L1.62476 9.26116V8.98263L1.90329 8.9362L3.4352 8.7041L4.36364 7.54356L4.5029 7.31145ZM9.14507 13.4855C9.11412 12.3714 8.61896 11.5203 7.65957 10.9323C6.66925 10.3753 5.67892 10.3753 4.68859 10.9323C3.72921 11.5203 3.23404 12.3714 3.20309 13.4855C3.23404 14.5996 3.72921 15.4507 4.68859 16.0387C5.67892 16.5958 6.66925 16.5958 7.65957 16.0387C8.61896 15.4507 9.11412 14.5996 9.14507 13.4855Z" fill="currentColor" />
                  <path d="M14.15 13.9967V11.7467H19.875H20V13.9967H14.625H13.5Z" fill="currentColor">
                  </path><path d="M13.5 16.7467V18.9967H14.625H20V16.7467H19.875H13.5Z" fill="currentColor"></path>
                </g>
              </svg>

            </Icon> //<Icon fontSize="small">contact_support</Icon>
            ,
            name: <div className="flex items-center justify-between gap-2"><span>Comply</span><strong>BETA</strong></div>,
            key: `cysr-comply-${a.account_uuid}`,
            path: "/cysr/comply",
            route: `/company/${a.account_uuid}/comply`,
            component: <Comply />,
          },
          renderRoutes.push({
            name: "Questionnaire",
            key: `cysr-questionnaire-comply-${a.account_uuid}`,
            // route: `/cysr/asset`,
            path: `/cysr/questionnaire`,
            route: `/company/${a.account_uuid}/questionnaire`,
            hide: true,
            component: <ComplyQuestionnaire />,
          })
        )
        // comply e
      }
    }
    /*
    renderRoutes.push({
      // type: "collapse",
      // noCollapse: true,
      // icon: <Icon fontSize="medium">article</Icon>,
      name: "Subscription",
      key: `cysr-company-subscription-detail-${a.account_uuid}`,
      // route: `/cysr/asset`,
      path: `/cysr/subscription`,
      route: `/company/${a.account_uuid}/subscription`,
      hide: true,
      // hide: true,
      component: <Subscription />,
    })
    renderRoutes.push({
      type: "collapse",
      noCollapse: true,
      icon: <Icon fontSize="medium">group</Icon>,
      name: "Third parties",
      key: `cysr-third-parties-list-${a.account_uuid}`,
      path: "/cysr/third-parties",
      route: `/company/${a.account_uuid}/third-parties`,
      component: <ThirdParties />,
    })
    */
    // only one company can be selected
    break;
  }
}

renderRoutes.push({
  // type: "collapse",
  // noCollapse: true,
  // icon: <Icon fontSize="medium">article</Icon>,
  name: "Subscription",
  key: `cysr-company-subscription-detail`,
  // route: `/cysr/asset`,
  path: `/cysr/company-subscription`,
  route: `/company-subscription`,
  hide: true,
  // hide: true,
  component: <Subscription />,
})

renderRoutes.push({
  // type: "collapse",
  // noCollapse: true,
  // icon: <Icon fontSize="medium">article</Icon>,
  name: "Company edit info",
  key: `cysr-company-edit-info`,
  // route: `/cysr/asset`,
  path: `/cysr/company-edit-info`,
  route: `/company-edit-info`,
  hide: true,
  // hide: true,
  component: <CompanyEditInfo />,
})

// Threats Intelligence
// modules: ["CYSR", "CTI"]
if (false && localStorage.getItem("cysr_mydata") && JSON.parse(localStorage.getItem("cysr_mydata")) && JSON.parse(localStorage.getItem("cysr_mydata")).modules && JSON.parse(localStorage.getItem("cysr_mydata")).modules.indexOf("CTI") > -1) {
  renderRoutes.push(
    { type: "divider", key: "cysr-divider-r-t" },
    { type: "title", title: "Threats Intelligence", key: "cysr-title-threats-intelligence" },
    /*
    {
      type: "collapse",
      name: "Threats Intelligence",
      key: "cysr-threats-intelligence",
      icon: <Icon fontSize="small">warning</Icon>,
      // actual path:
      // path: "/cysr/dashboards",
      // how appears:
      // route: "/dashboard",
      route: "/cysr/threats-intelligence",
      component: <ThreatsIntelligence />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Threats Intelligence",
      key: "cysr-threats-intelligence",
      icon: <Icon fontSize="medium">warning</Icon>,
      collapse: [
        */
    {
      type: "collapse",
      noCollapse: true,
      name: "Botnets",
      key: `cysr-botnets-list`,
      icon: <Icon fontSize="small">hub</Icon>,
      path: `/cysr/botnets`,
      // this is how it appears in the browser:
      route: `/threats-intelligence/botnets`,
      component: <Botnets />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Leaks",
      key: `cysr-leaks-list`,
      icon: <Icon fontSize="small">content_paste</Icon>,
      path: `/cysr/leaks`,
      // this is how it appears in the browser:
      route: `/threats-intelligence/leaks`,
      component: <Leaks />,
    }
    /*
  ]
}
*/
  )
}
// threats-intelligence
if (false && window.location.pathname.indexOf("/threats-intelligence") > -1) {
  renderRoutes.push(
    { type: "divider", key: "cysr-divider-accounts" }
  );
  renderRoutes.push({
    type: "collapse",
    noCollapse: true,
    name: "Botnets",
    key: `cysr-botnets-list`,
    icon: <Icon fontSize="medium">apps</Icon>,
    path: `/cysr/surveillance`,
    // this is how it appears in the browser:
    route: `/threats-intelligence/botnets`,
    component: <Botnets />,
  })
  renderRoutes.push({
    type: "collapse",
    noCollapse: true,
    name: "Leaks",
    key: `cysr-leaks-list`,
    icon: <Icon fontSize="medium">apps</Icon>,
    path: `/cysr/leaks`,
    // this is how it appears in the browser:
    route: `/threats-intelligence/leaks`,
    component: <Leaks />,
  })
  renderRoutes.push({
    type: "collapse",
    noCollapse: true,
    name: "Surveillance",
    key: `cysr-surveillance-list`,
    icon: <Icon fontSize="medium">apps</Icon>,
    path: `/cysr/surveillance`,
    // this is how it appears in the browser:
    route: `/threats-intelligence/surveillance`,
    component: <Surveillance />,
  })
  renderRoutes.push({
    type: "collapse",
    noCollapse: true,
    name: "Events",
    key: `cysr-events-list`,
    icon: <Icon fontSize="medium">apps</Icon>,
    path: `/cysr/events`,
    // this is how it appears in the browser:
    route: `/threats-intelligence/events`,
    component: <Events />,
  })
  renderRoutes.push({
    type: "collapse",
    noCollapse: true,
    name: "Actors",
    key: `cysr-actors-list`,
    icon: <Icon fontSize="medium">apps</Icon>,
    path: `/cysr/actors`,
    // this is how it appears in the browser:
    route: `/threats-intelligence/actors`,
    component: <Actors />,
  })
  renderRoutes.push({
    name: "Actor",
    key: `cysr-actor-detail-actor`,
    path: `/cysr/actor`,
    route: `/threats-intelligence/actor`,
    hide: true,
    component: <Actor />,
  })
  renderRoutes.push({
    type: "collapse",
    noCollapse: true,
    name: "Countries",
    key: `cysr-countries-list`,
    icon: <Icon fontSize="medium">apps</Icon>,
    path: `/cysr/countries`,
    // this is how it appears in the browser:
    route: `/threats-intelligence/countries`,
    component: <Countries />,
  })
}
// login
renderRoutes.push(
  // { type: "divider", key: "cysr-divider-login" },
  {
    // type: "collapse",
    hide: true,
    name: "Login",
    key: "cysr-login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/cysr/login",
    component: <Login />,
    noCollapse: true,
  },
  {
    // type: "collapse",
    hide: true,
    name: "Notifications",
    key: "cysr-notifications",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/cysr/notifications",
    component: <Notifications />,
    noCollapse: true,
  },
  {
    // type: "collapse",
    hide: true,
    name: "Invitation",
    key: "cysr-invitation-contractor",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/cysr/subscriber/invitation",
    component: <Invitation />,
    noCollapse: true,
  },
  {
    // type: "collapse",
    hide: true,
    name: "Invitation",
    key: "cysr-invitation-subscription",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/cysr/subscription/invitation",
    component: <Invitation />,
    noCollapse: true,
  },
  {
    hide: true,
    name: "Invitation DPO",
    key: "cysr-invitation-dpo",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/cysr/dpo/invitation",
    component: <InvitationDPO />,
    noCollapse: true,
  },
  {
    hide: true,
    name: "Revocation DPO",
    key: "cysr-revocation-dpo",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/cysr/dpo/revocation",
    component: <RevocationDPO />,
    noCollapse: true,
  },
  {
    // type: "collapse",
    hide: true,
    name: "Invitation Terms",
    key: "cysr-invitation-terms",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/terms-and-conditions",
    component: <InvitationTerms />,
    noCollapse: true,
  },
  // billing info
  {
    // type: "collapse",
    hide: true,
    name: "Billing Info",
    key: "cysr-subscribers",
    icon: <Icon fontSize="small">money</Icon>,
    route: "/cysr/profile/subscribers",
    component: <ManageBillingInfo />,
    noCollapse: true,
  },
  // subscriptions
  {
    // type: "collapse",
    hide: true,
    name: "Subscriptions",
    key: "cysr-manage-subscriptions",
    icon: <Icon fontSize="small">difference</Icon>,
    route: "/cysr/profile/manage-subscriptions",
    component: <Subscriptions />,
    noCollapse: true,
  },
  {
    hide: true,
    name: "Subscription",
    key: `cysr-subscription-detail`,
    // route: `/cysr/contract`,
    path: `/cysr/profile/manage-subscriptions/subscription`,
    route: "/cysr/profile/manage-subscriptions/subscription",
    component: <Subscription />,
  },
  /*
  // contract
  (
    {
      name: "Contract",
      key: `cysr-contract-detail`,
      // route: `/cysr/asset`,
      path: `/cysr/contract`,
      route: `/cysr/contract`,
      hide: true,
      component: <Contract />,
      noCollapse: true,
    }
  ),
  // contracts
  (
    {
      name: "Contracts",
      key: `cysr-contracts`,
      // route: `/cysr/asset`,
      path: `/cysr/contracts`,
      route: `/cysr/contracts`,
      hide: canViewContracts,
      component: <Contracts />,
      noCollapse: true,
    }
  ),
  */
  {
    hide: true,
    name: "Profile",
    key: "cysr-profile",
    icon: <Icon fontSize="small">account_circle</Icon>,
    route: "/cysr/profile",
    component: <Profile />,
    noCollapse: true,
  },
  {
    hide: true,
    name: "Report Chart Radar",
    key: `cysr-report-chart-radar`,
    icon: <Icon fontSize="small">login</Icon>,
    // this is the actual path:
    // path: `/cysr/report-chart-radar`,
    route: `/company/${fixAccountUUID}/report-chart-radar`,
    component: <ReportChartRadar />,
    noCollapse: true,
  },
  {
    hide: true,
    name: "Report Chart Circle",
    key: `cysr-report-chart-circle`,
    icon: <Icon fontSize="small">login</Icon>,
    // this is the actual path:
    // path: `/cysr/report-chart-circle`,
    route: `/company/${fixAccountUUID}/report-chart-circle`,
    component: <ReportChartCircle />,
    noCollapse: true,
  },
  // report-infographic
  {
    hide: true,
    name: "Report Chart 1",
    key: `cysr-report-chart-1`,
    icon: <Icon fontSize="small">login</Icon>,
    route: `/report-chart-1`,
    component: <ReportChart1 />,
    noCollapse: true,
  },
  {
    hide: true,
    name: "Report Chart 2",
    key: `cysr-report-chart-2`,
    icon: <Icon fontSize="small">login</Icon>,
    route: `/report-chart-2`,
    component: <ReportChart2 />,
    noCollapse: true,
  },
  {
    hide: true,
    name: "Report Chart 3",
    key: `cysr-report-chart-3`,
    icon: <Icon fontSize="small">login</Icon>,
    route: `/report-chart-3`,
    component: <ReportChart3 />,
    noCollapse: true,
  },
  {
    hide: true,
    name: "Report Chart 4",
    key: `cysr-report-chart-4`,
    icon: <Icon fontSize="small">login</Icon>,
    route: `/report-chart-4`,
    component: <ReportChart4 />,
    noCollapse: true,
  },
  {
    hide: true,
    name: "Report Chart 5",
    key: `cysr-report-chart-5`,
    icon: <Icon fontSize="small">login</Icon>,
    route: `/report-chart-5`,
    component: <ReportChart5 />,
    noCollapse: true,
  }
);
// push defaults of tim creative
/*
renderRoutes.push(
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Brooklyn Alice",
    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },
      {
        name: "Settings",
        key: "profile-settings",
        route: "/pages/account/settings",
        component: <Settings />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
      {
        name: "Dashboard",
        key: "sales",
        route: "/dashboards/sales",
        component: <Dashboard />,
      },
    ],
  },
  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "Pages",
    key: "pages",
    icon: <Icon fontSize="medium">image</Icon>,
    collapse: [
      {
        name: "Profile",
        key: "profile",
        collapse: [
          {
            name: "Profile Overview",
            key: "profile-overview",
            route: "/pages/profile/profile-overview",
            component: <ProfileOverview />,
          },
          {
            name: "All Projects",
            key: "all-projects",
            route: "/pages/profile/all-projects",
            component: <AllProjects />,
          },
        ],
      },
      {
        name: "Users",
        key: "users",
        collapse: [
          {
            name: "New User",
            key: "new-user",
            route: "/pages/users/new-user",
            component: <NewUser />,
          },
        ],
      },
      {
        name: "Account",
        key: "account",
        collapse: [
          {
            name: "Settings",
            key: "settings",
            route: "/pages/account/settings",
            component: <Settings />,
          },
          {
            name: "Billing",
            key: "billing",
            route: "/pages/account/billing",
            component: <Billing />,
          },
          {
            name: "Invoice",
            key: "invoice",
            route: "/pages/account/invoice",
            component: <Invoice />,
          },
        ],
      },
      {
        name: "Projects",
        key: "projects",
        collapse: [
          {
            name: "Timeline",
            key: "timeline",
            route: "/pages/projects/timeline",
            component: <Timeline />,
          },
        ],
      },
      {
        name: "Pricing Page",
        key: "pricing-page",
        route: "/pages/pricing-page",
        component: <PricingPage />,
      },
      { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
      { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
      { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
      {
        name: "Notfications",
        key: "notifications",
        route: "/pages/notifications",
        component: <Notifications />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Kanban",
        key: "kanban",
        route: "/applications/kanban",
        component: <Kanban />,
      },
      {
        name: "Wizard",
        key: "wizard",
        route: "/applications/wizard",
        component: <Wizard />,
      },
      {
        name: "Data Tables",
        key: "data-tables",
        route: "/applications/data-tables",
        component: <DataTables />,
      },
      {
        name: "Calendar",
        key: "calendar",
        route: "/applications/calendar",
        component: <Calendar />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Ecommerce",
    key: "ecommerce",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {
        name: "Products",
        key: "products",
        collapse: [
          {
            name: "New Product",
            key: "new-product",
            route: "/ecommerce/products/new-product",
            component: <NewProduct />,
          },
          {
            name: "Edit Product",
            key: "edit-product",
            route: "/ecommerce/products/edit-product",
            component: <EditProduct />,
          },
          {
            name: "Product Page",
            key: "product-page",
            route: "/ecommerce/products/product-page",
            component: <ProductPage />,
          },
        ],
      },
      {
        name: "Orders",
        key: "orders",
        collapse: [
          {
            name: "Order List",
            key: "order-list",
            route: "/ecommerce/orders/order-list",
            component: <OrderList />,
          },
          {
            name: "Order Details",
            key: "order-details",
            route: "/ecommerce/orders/order-details",
            component: <OrderDetails />,
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-in/basic",
            component: <SignInBasic />,
          },
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-in/cover",
            component: <SignInCover />,
          },
          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/sign-in/illustration",
            component: <SignInIllustration />,
          },
        ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-up/cover",
            component: <SignUpCover />,
          },
        ],
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/reset-password/cover",
            component: <ResetCover />,
          },
        ],
      },
    ],
  },
  { type: "divider", key: "divider-2" },
  { type: "title", title: "Docs", key: "title-docs" },
  {
    type: "collapse",
    name: "Basic",
    key: "basic",
    icon: <Icon fontSize="medium">upcoming</Icon>,
    collapse: [
      {
        name: "Getting Started",
        key: "getting-started",
        collapse: [
          {
            name: "Overview",
            key: "overview",
            href: "https://www.creative-tim.com/learning-lab/react/overview/material-dashboard/",
          },
          {
            name: "License",
            key: "license",
            href: "https://www.creative-tim.com/learning-lab/react/license/material-dashboard/",
          },
          {
            name: "Quick Start",
            key: "quick-start",
            href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/",
          },
          {
            name: "Build Tools",
            key: "build-tools",
            href: "https://www.creative-tim.com/learning-lab/react/build-tools/material-dashboard/",
          },
        ],
      },
      {
        name: "Foundation",
        key: "foundation",
        collapse: [
          {
            name: "Colors",
            key: "colors",
            href: "https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/",
          },
          {
            name: "Grid",
            key: "grid",
            href: "https://www.creative-tim.com/learning-lab/react/grid/material-dashboard/",
          },
          {
            name: "Typography",
            key: "base-typography",
            href: "https://www.creative-tim.com/learning-lab/react/base-typography/material-dashboard/",
          },
          {
            name: "Borders",
            key: "borders",
            href: "https://www.creative-tim.com/learning-lab/react/borders/material-dashboard/",
          },
          {
            name: "Box Shadows",
            key: "box-shadows",
            href: "https://www.creative-tim.com/learning-lab/react/box-shadows/material-dashboard/",
          },
          {
            name: "Functions",
            key: "functions",
            href: "https://www.creative-tim.com/learning-lab/react/functions/material-dashboard/",
          },
          {
            name: "Routing System",
            key: "routing-system",
            href: "https://www.creative-tim.com/learning-lab/react/routing-system/material-dashboard/",
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    name: "Components",
    key: "components",
    icon: <Icon fontSize="medium">view_in_ar</Icon>,
    collapse: [
      {
        name: "Alerts",
        key: "alerts",
        href: "https://www.creative-tim.com/learning-lab/react/alerts/material-dashboard/",
      },
      {
        name: "Avatar",
        key: "avatar",
        href: "https://www.creative-tim.com/learning-lab/react/avatar/material-dashboard/",
      },
      {
        name: "Badge",
        key: "badge",
        href: "https://www.creative-tim.com/learning-lab/react/badge/material-dashboard/",
      },
      {
        name: "Badge Dot",
        key: "badge-dot",
        href: "https://www.creative-tim.com/learning-lab/react/badge-dot/material-dashboard/",
      },
      {
        name: "Box",
        key: "box",
        href: "https://www.creative-tim.com/learning-lab/react/box/material-dashboard/",
      },
      {
        name: "Buttons",
        key: "buttons",
        href: "https://www.creative-tim.com/learning-lab/react/buttons/material-dashboard/",
      },
      {
        name: "Date Picker",
        key: "date-picker",
        href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-dashboard/",
      },
      {
        name: "Dropzone",
        key: "dropzone",
        href: "https://www.creative-tim.com/learning-lab/react/dropzone/material-dashboard/",
      },
      {
        name: "Editor",
        key: "editor",
        href: "https://www.creative-tim.com/learning-lab/react/quill/material-dashboard/",
      },
      {
        name: "Input",
        key: "input",
        href: "https://www.creative-tim.com/learning-lab/react/input/material-dashboard/",
      },
      {
        name: "Pagination",
        key: "pagination",
        href: "https://www.creative-tim.com/learning-lab/react/pagination/material-dashboard/",
      },
      {
        name: "Progress",
        key: "progress",
        href: "https://www.creative-tim.com/learning-lab/react/progress/material-dashboard/",
      },
      {
        name: "Snackbar",
        key: "snackbar",
        href: "https://www.creative-tim.com/learning-lab/react/snackbar/material-dashboard/",
      },
      {
        name: "Social Button",
        key: "social-button",
        href: "https://www.creative-tim.com/learning-lab/react/social-buttons/material-dashboard/",
      },
      {
        name: "Typography",
        key: "typography",
        href: "https://www.creative-tim.com/learning-lab/react/typography/material-dashboard/",
      },
    ],
  },
  {
    type: "collapse",
    name: "Change Log",
    key: "changelog",
    href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
    icon: <Icon fontSize="medium">receipt_long</Icon>,
    noCollapse: true,
  }
);
*/
const routes = renderRoutes;

export default routes;
/* eslint-disable */
// prettier-ignore
