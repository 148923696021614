/* eslint-disable */
// prettier-ignore
// 'use client';
import { Tracker } from '@tremor/react';
import { useEffect, useState } from 'react';

export default function CtrlTrackerControl({ answers, c, label, percentageLabel, language = "en", legend = false }) {
  const colorUnanswered = "gray"
  const colorsAnswers =
    language === "es"
      ? {
        "Cumplido": "emerald",
        "No Cumplido": "rose",
        "Not Applicable": "violet",
        "Control Compensario": "amber",
      }
      : language === "de"
      ? {
        "Erreicht": "emerald",
        "Nicht Erreicht": "rose",
        "Nicht Anwendbar": "violet",
        "Kompensierende Kontrolle": "amber",
      }
      : language === "fr"
      ? {
        "Atteint": "emerald",
        "Non Atteint": "rose",
        "Non Applicable": "violet",
        "Contrôle Compensatoire": "amber",
      }
      : language === "it"
      ? {
        "Raggiunto": "emerald",
        "Non Raggiunto": "rose",
        "Non Applicabile": "violet",
        "Controllo Compensativo": "amber",
      }
      : {
        "Met": "emerald",
        "Not Met": "rose",
        "Not Applicable": "violet",
        "Compensating Control": "amber",
      }

  function getColor(y) {
    if (y && y.objective_answer) {
      return colorsAnswers[y.objective_answer] ?? "teal"
    } else {
      console.warn(y)
    }
    return "teal"
  }

  const keyVal = "tooltip"

  const [data, setData] = useState(null)
  useEffect(() => {
    if (answers) {
      const a = JSON.parse(answers);
      let newData = []
      Object.keys(c).forEach(o => {
        let ob = {
          color: 'gray', [keyVal]: o
        };
        if (a && a[o] && a[o].objective_answer_id) {
          ob.color = getColor(a[o])
        } else {
          ob.color = colorUnanswered
        }
        newData.push(ob)
      }
      )
      setData(newData)
      console.log(a)
      console.log(newData)
    }
  }, [answers, c]
  )

  // const data = [
  // { color: 'teal', tooltip: 'Answered' },
  // { color: 'gray', tooltip: 'Unanswered' },
  // { color: 'gray', tooltip: 'Maintenance' },
  // { color: 'emerald', tooltip: 'Operational' },
  // { color: 'rose', tooltip: 'Downtime' },
  // { color: 'yellow', tooltip: 'Degraded' },
  // ];

  /*
  let counts = []
  if (legend) {
    data.forEach(d => {
      let y = counts.find(x => x.key === d[keyVal])
      if (y) {
        y.count++
      } else {
        counts.push({
          key: d[keyVal],
          color: d.color,
          count: 1
        })
      }
    });
  }
  */

  return data ? (
    <div className='w-full items-end flex gap-4'>
      {
        /* 
          legend ?
          <div className='w-full -mb-2 max-w-xs'>
            <List>
              {counts.map(item => (
                <ListItem key={item.key} className="space-x-6">
                  <div className="flex items-center space-x-2.5 truncate">
                    <span
                      className={"bg-" + item.color + "-500 h-2.5 w-2.5 shrink-0 rounded-sm"}
                      aria-hidden={true}
                    />
                    <span className="truncate dark:text-dark-tremor-content-emphasis">
                      {item.key}
                    </span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <span className="font-medium tabular-nums text-tremor-content-strong dark:text-dark-tremor-content-strong">
                      {item.count}
                    </span>
                    <span className="w-16 rounded-tremor-small bg-tremor-background-subtle px-1.5 py-0.5 text-tremor-label font-medium tabular-nums text-tremor-content-emphasis dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis">
                      {(item.count / data.length * 100).toFixed(2)}%
                    </span>
                  </div>
                </ListItem>
              ))}
            </List>
          </div> : null
          */
      }
      <div className='flex gap-2 flex-col justify-end w-full max-w-xl'>
        <div className="text-tremor-default flex gap-4 items-center justify-start">
          <span className="text-tremor-content dark:text-dark-tremor-content">{percentageLabel} <strong>{(data.filter(x => x.color !== colorUnanswered).length / data.length * 100).toFixed(2)}%</strong>
          </span>
          {
            Object.entries(colorsAnswers).map(([name, color]) => (
              <div className="text-tremor-content dark:text-dark-tremor-content flex items-center space-x-2.5 truncate">
                <span
                  className={'bg-' + color + '-500 h-2.5 w-2.5 shrink-0 rounded-sm'}
                  aria-hidden={true}
                />
                <span className="truncate dark:text-dark-tremor-content-emphasis">
                  {name}
                </span>
              </div>
            )
            )
          }
        </div>
        <Tracker data={data} className="mt-0 w-full h-2" />
      </div>
    </div>
  ) : null;
}