/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/cysr/login/BasicLayout";
import { useSnackbar } from 'notistack';

import { Checkbox, FormControlLabel } from '@mui/material';

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import cysrConfig from "layouts/cysr/config";

function Basic() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // add action to an individual snackbar
  const action = snackbarId => (
    <>
      <MDButton onClick={() => { closeSnackbar(snackbarId) }} style={cysrConfig().button_style} size="medium">
        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().close.icon.svg }} />
        Dismiss
      </MDButton>
    </>
  );
  const [invitationEmail, setInvitationEmail] = useState("");
  const [areConfirmedTerms, setAreConfirmedTerms] = useState(false);
  const [invitationExistingUser, setInvitationExistingUser] = useState(false);
  const [invitationMessage, setInvitationMessage] = useState(<></>);
  const isDPO = window.location.href.indexOf("dpo") > - 1;
  const invitationId = window.location.href.split('/invitation#')[1];
  console.warn("invitationId")
  console.warn(invitationId)
  const [readyToRegister, setReadyToRegister] = useState(false);

  const invitationRegisterUser = () => {
    // const loginCallUrl = `${cysrConfig().api_base_url}login/`;
    const invitationRegisterUrl = `${cysrConfig().api_base_url}invitation/register/user/${invitationId}/`;
    // check confirm password
    if (document.getElementById("cysr_pass").value == "") {
      // toggleSnackbar("Error", "Password can not be empty", "error", "error", "Error");
      enqueueSnackbar(
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <MDTypography variant="caption" fontWeight="bold">Error</MDTypography>
          <MDTypography variant="caption">Password can not be empty</MDTypography>
        </div>, {
        action,
        variant: 'error',
        persist: true
      })
    } else if (document.getElementById("cysr_pass").value != document.getElementById("cysr_pass_confirm").value) {
      // toggleSnackbar("Error", "Password does not match the confirmation one", "error", "error", "Error");
      enqueueSnackbar(
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <MDTypography variant="caption" fontWeight="bold">Error</MDTypography>
          <MDTypography variant="caption">Password does not match the confirmation one</MDTypography>
        </div>, {
        action,
        variant: 'error',
        persist: true
      })
    } else {
      const dataLogin = {
        email: invitationEmail,
        password: document.getElementById("cysr_pass").value,
      };
      console.log(dataLogin)
      fetch(invitationRegisterUrl, {
        body: JSON.stringify(dataLogin),
        headers: {
          "content-type": "application/json",
        },
        method: "POST",
      })
        .then((response) => {
          if (response.ok) {
            // window.location.reload();
            return response.json();
          } else {
            console.error(response);
            return response.json().then(err => {
              throw JSON.stringify(err)
            })
          }
        })
        .then((loginData) => {
          console.warn(loginData)
          setInvitationMessage(
            <>
              <MDTypography variant="body2" mb={2}>You succesfully signed up</MDTypography>
              <MDTypography variant="body2" mb={2}>Access the platform using your credentials</MDTypography>
            </>
          )
          setInvitationExistingUser(true)
          /*
          enqueueSnackbar(
            <div style={{display: "flex", flexDirection: "column"}}>
              <MDTypography variant="caption" fontWeight="bold">Going to login..</MDTypography>
              <MDTypography variant="caption">Redirecting to the login page</MDTypography>
            </div>, { 
              // action,
              variant: 'info'
            }
          )
          window.location.href = "/cysr/login";
          */
        })
        .catch((err) => {
          console.log(err);
          console.log("err3:", err);
          if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
            // toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
            enqueueSnackbar(
              <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                <MDTypography variant="caption" fontWeight="bold">{JSON.parse(err).ErrorMessage}</MDTypography>
                <MDTypography variant="caption">
                  <div
                    dangerouslySetInnerHTML={{ __html: JSON.parse(err).ErrorDetail }}
                  ></div>
                </MDTypography>
              </div>, {
              action,
              variant: 'error',
              persist: true
            })
          } else if (JSON.parse(err) && JSON.parse(err).error) {
            // toggleSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
            enqueueSnackbar(
              <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                <MDTypography variant="caption" fontWeight="bold">Error</MDTypography>
                <MDTypography variant="caption">{JSON.parse(err).error}</MDTypography>
              </div>, {
              action,
              variant: 'error',
              persist: true
            })
          } else {
            // toggleSnackbar("Error", "Some error happened", "error", "error", "Error");
            enqueueSnackbar(
              <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                <MDTypography variant="caption" fontWeight="bold">Error</MDTypography>
                <MDTypography variant="caption">Some error happened</MDTypography>
              </div>, {
              action,
              variant: 'error',
              persist: true
            })
          }
        })
    }
  };

  // fetch on invitation/subscription/join
  const joinUrl = `${cysrConfig().api_base_url}${isDPO ? "dpo" : "cti"}/approve/${invitationId}/`;
  // const joinUrl = `${cysrConfig().api_base_url}invitation/contractor/join/${invitationId}/`;
  useEffect(() => {
    if (invitationId) {
      fetch(joinUrl, {
        /*
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
        */
        method: "POST"
      })
        .then((response) => {
          console.warn("rresp")
          console.warn(response)
          return response.json()
        })
        .then((responseData) => {
          console.log("responseData")
          console.log(responseData)
          if (responseData.invited) {
            setInvitationEmail(responseData.invited)
          }
          setInvitationExistingUser(false)
          if (responseData.message && responseData.message === "Unable to add. Non existent user") {
            setInvitationMessage(
              <>
                <MDTypography variant="body2" mb={2}>
                  To accept your invitation, you must first register on the {platformName} platform.
                  Please read the <a href="/terms-and-conditions/" target="_blank">terms of service</a> and fill in the following form:
                </MDTypography>
              </>
            )
            setReadyToRegister(true)
          } else if (responseData.ErrorDetail || responseData.ErrorMessage) {
            const warningTitle = "Authorization Link Expired or Invalid"
            const warningDetail = "The authorization link you are trying to use has either expired, is invalid, or has already been utilized. To regain authorization, please request a new link through the platform or contact our support team for further assistance."

            setInvitationMessage(
              <>
                <MDTypography variant="h4" fontWeight="medium" mb={2} align="center">{warningTitle}</MDTypography>
                <MDTypography mb={2} variant="body2">{warningDetail}{
                  // responseData.ErrorMessage
                }</MDTypography>
                {
                  // <MDTypography variant="body2" color="warning" mb={2}>{responseData.ErrorDetail}</MDTypography>
                }
              </>
            )
            enqueueSnackbar(
              <div style={{ display: "flex", flexDirection: "column" }}>
                <MDTypography variant="caption" fontWeight="bold">{warningTitle}{
                  // responseData.ErrorMessage
                }</MDTypography>
                <MDTypography variant="caption">{warningDetail}{
                  // responseData.ErrorDetail
                }</MDTypography>
              </div>, {
              action,
              variant: 'warning',
              persist: true
            })
          } else {
            setInvitationMessage(
              <>
                <MDTypography variant="h4" fontWeight="medium" mb={2} align="center">
                  Authorization Confirmed
                </MDTypography>
                {false && responseData && responseData.company && responseData.company.name && <MDTypography variant="body2" mb={2}>Company: {responseData.company.name}</MDTypography>}
                {responseData && responseData.confirmed_domains && <MDTypography variant="body2" mb={2}>You have authorized the processing of personal data for the following domains:
                  <ul className="mt-2 flex flex-col gap-1 list-disc list-inside marker:text-teal-500">
                    {
                      responseData.confirmed_domains.map(
                        (x) => <li key={x}>{x}</li>
                      )
                    }
                  </ul>
                </MDTypography>
                }
                {responseData && responseData.confirmation_ip && responseData.confirmation_date && <MDTypography variant="body2" mb={2}>
                  Your authorization has been recorded, including your IP address and timestamp.</MDTypography>}
                {responseData && responseData.revocation_link &&
                  <>
                    <MDTypography variant="body2" mb={2}>If you ever wish to revoke this authorization, you can do so by using the following link: <a style={{
                      wordBreak: "break-all",
                    }} href={`/cysr/dpo/revocation#${responseData.revocation_link}`}>{
                        window.location.href.split('/').slice(0, -1).join('/') + `/revocation#${responseData.revocation_link}`
                      }</a></MDTypography>
                    {false && (
                      <MDButton fullWidth onClick={() => { window.location.href = `/cysr/dpo/revocation#${responseData.revocation_link}` }} style={cysrConfig().button_style} size="medium">
                        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().remove.icon.svg }} />
                        Revoke authorization
                      </MDButton>)
                    }
                  </>
                }
              </>
            )
            setInvitationExistingUser(true)
          }
        })
        .catch((err) => {
          let snackErrorTitle = "Invitation error";
          let snackErrorDetail = "Invite could not be accepted at this time";
          if (err && err.ErrorMessage) {
            snackErrorTitle = err.ErrorMessage;
            snackErrorDetail = err.ErrorDetail;
          } else if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
            snackErrorTitle = JSON.parse(err).ErrorMessage;
            snackErrorDetail = JSON.parse(err).ErrorDetail;
          } else if (JSON.parse(err) && JSON.parse(err).error) {
            // snackErrorTitle = "Error";
            snackErrorDetail = JSON.parse(err).error;
          }
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>{snackErrorTitle}</MDTypography>
              <MDTypography variant="caption">{snackErrorDetail}</MDTypography>
            </div>, {
            action,
            variant: 'error',
            persist: true
          })
          console.log("err...");
          console.log(err);
        });
    } else {
      let snackErrorTitle = "CTI approval error";
      let snackErrorDetail = "The link provided is not valid, use the one received in the email";
      enqueueSnackbar(
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <MDTypography variant="caption" style={{ fontWeight: "600" }}>{snackErrorTitle}</MDTypography>
          <MDTypography variant="caption">{snackErrorDetail}</MDTypography>
        </div>, {
        action,
        variant: 'error',
        persist: true
      })
    }
  }, [])

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox pt={2} pb={3} px={3}>
          <>
            <MDTypography variant="h4" fontWeight="medium" align="center">
              {platformName}
            </MDTypography>
            {invitationMessage}
            {
              false && (
                <MDBox mt={2} component="form" role="form" autoComplete="off">
                  <MDBox mt={2}>
                    {invitationExistingUser ?
                      <MDButton fullWidth onClick={() => { window.location.href = "/cysr/login" }} style={cysrConfig().button_style} size="medium">
                        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().login.icon.svg }} />
                        Go to Login
                      </MDButton>
                      :
                      <>
                        {readyToRegister ?
                          <>
                            <MDBox mb={1}>
                              <MDInput type="text" label="Email" id="cysr_email" value={invitationEmail} fullWidth autoComplete="off" readonly />
                            </MDBox>
                            <MDBox mb={1}>
                              <MDInput type="password" label="Password" id="cysr_pass" fullWidth autoComplete="off" />
                            </MDBox>
                            <MDBox mb={1}>
                              <MDInput type="password" label="Confirm Password" id="cysr_pass_confirm" fullWidth autoComplete="off" />
                            </MDBox>
                            <MDBox mb={1}>
                              <FormControlLabel
                                control={
                                  <Checkbox defaultChecked={areConfirmedTerms} onChange={() => setAreConfirmedTerms(!areConfirmedTerms)} />
                                }
                                // label="I have read and accept the terms of service"
                                label={<span>I have read and accept <a href="/terms-and-conditions/" target="_blank">the terms</a></span>}
                              />
                            </MDBox>
                            {areConfirmedTerms ? <MDButton fullWidth onClick={invitationRegisterUser} style={cysrConfig().button_style} size="medium">
                              <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().login.icon.svg }} />
                              Sign up
                            </MDButton> :
                              <MDButton fullWidth style={cysrConfig().button_style} size="medium" disabled>
                                <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().login.icon.svg }} />
                                Sign up
                              </MDButton>
                            }
                          </>
                          :
                          <MDButton fullWidth onClick={() => { window.location.href = "/cysr/login" }} style={cysrConfig().button_style} size="medium">
                            <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().login.icon.svg }} />
                            Go to Login
                          </MDButton>
                        }
                      </>
                    }
                  </MDBox>
                </MDBox>
              )
            }
          </>

        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
/* eslint-disable */