/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
// prettier-ignore
import { Base64 } from 'js-base64';
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
import GroupedSelect from "layouts/cysr/components/CysrGroupedSelect";
// import Footer from "examples/Footer";
import Card from "@mui/material/Card";

import InfoButton from "layouts/cysr/components/infoButton";

import cysrConfig, { process } from "layouts/cysr/config";

import CysrTableKi from "layouts/cysr/components/CysrTable/botnets";
import Loader from "layouts/cysr/components/Loader";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import MDSnackbar from "components/MDSnackbar";
// cti s
import { DataTable } from "layouts/cysr/components/cti-archive/components/data-table";
// cti e
// dpo s
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { useMaterialUIController } from "context";
// dpo e
import { DataTableRowButton } from "layouts/cysr/components/cti-archive/components/data-table-row-button-unacknowledge";
import { CompanyDPO } from "layouts/cysr/components/CompanyDPO";

import { Checkbox } from "layouts/cysr/components/ui/checkbox";
import { Badge } from "layouts/cysr/components/ui/badge";
import { DataTableColumnHeader } from "layouts/cysr/components/cti-archive/components/data-table-column-header";
import { DataTableRowExpand } from "layouts/cysr/components/cti-archive/components/data-table-row-expand";

import { format } from "date-fns";

export const columns = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      //row.original.dpo_approved ?
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px] bg-white dark:bg-black"
      />
      // : <svg className="opacity-50" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 2H13.5C13.7761 2 14 2.22386 14 2.5V7H1V2.5C1 2.22386 1.22386 2 1.5 2ZM1 8V12.5C1 12.7761 1.22386 13 1.5 13H13.5C13.7761 13 14 12.7761 14 12.5V8H1ZM0 2.5C0 1.67157 0.671573 1 1.5 1H13.5C14.3284 1 15 1.67157 15 2.5V12.5C15 13.3284 14.3284 14 13.5 14H1.5C0.671573 14 0 13.3284 0 12.5V2.5Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "accountack_key",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Username" />
    ),
    cell: ({ row }) => (
      <div
        className={`whitespace-nowrap ${process.env.NEXT_PUBLIC_CLASSES_ASSET_SOURCE}`}
      >
        {row.getValue("accountack_key")
          ? row.getValue("accountack_key")
          : process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE}
      </div>
    ),
    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: "accountack_type",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="whitespace-nowrap"
        column={column}
        title="Type"
      />
    ),
    cell: ({ row }) =>
      row.getValue("accountack_type") ? (
        <Badge className="whitespace-nowrap" variant="outline">
          {row.getValue("accountack_type")}
        </Badge>
      ) : (
        process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE
      ),
    filterFn: (row, id, value) => {
      const labelType = row.original.accountack_type; /*
        ? process.env.NEXT_PUBLIC_STRING_TERM_300_SINGLE
        : process.env.NEXT_PUBLIC_STRING_TERM_100_SINGLE
        */
      return value.includes(labelType);
    },
    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: "accountack_notes",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Note" />
    ),
    cell: ({ row }) =>
      row.getValue("accountack_notes") ?
        row.getValue("accountack_notes") : (
          process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE
        ),

    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: "accountack_creation_datetime",
    header: ({ column }) => {
      return (
        <DataTableColumnHeader
          className="whitespace-nowrap"
          column={column}
          title="Date"
        />
      );
    },
    cell: ({ row }) => {
      return (
        <div className="whitespace-nowrap">
          {row.getValue("accountack_creation_datetime")
            ? format(
              new Date(row.getValue("accountack_creation_datetime")),
              process.env.NEXT_PUBLIC_DATE_FORMAT
                ? process.env.NEXT_PUBLIC_DATE_FORMAT
                : "dd/MM/yyyy"
            )
            : process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE}
        </div>
      );
    },
  },
  {
    accessorKey: "accountack_uuid",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="accountack_uuid" />
    ),
    cell: ({ row }) => {
      return row.original.accountack_uuid;
    },
    enableHiding: false,
  },
  {
    accessorKey: "accountack_acknowledger",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Acknowledged by" />
    ),
    cell: ({ row }) => {
      return row.original.accountack_acknowledger;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.accountack_acknowledger);
    },
    enableSorting: true,
    enableHiding: false,
    // hidden in the state default, at row 44 di data-table.tsx:
    /*
    React.useState<VisibilityState>({
      // here set default visibility of columns!
      domain: false,
    });
    */
  },
  {
    id: "action",
    cell: ({ row }) => (
      <div className="flex gap-2">
        {false && <DataTableRowExpand row={row} />}
        <DataTableRowButton rows={null} row={row} status={"acknowledged"} />
      </div>
    ),
  },
];

function BotnetsArchive() {
  const accountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/company/") + "/company/".length,
    window.location.pathname.lastIndexOf("/")
  );
  // mdsnack s
  const [show, setShow] = useState(false);
  const [openDPO, setOpenDPO] = useState(false);
  const [msgDateTime, setMsgDateTime] = useState("Information");
  const [msgIcon, setMsgIcon] = useState("info");
  const [msgTheme, setMsgTheme] = useState("light");
  const [msgTitle, setMsgTitle] = useState("Recheck not executed");
  const [msgContent, setMsgContent] = useState(
    <></>
  )
  const defaultToggleSnackbar = () => {
    setShow(!show);
  }
  function toggleSnackbar(title = "Error", msg = "Error", theme = "error", icon = "error", dateTime = "Error") {
    setMsgDateTime(dateTime);
    setMsgIcon(icon);
    setMsgTheme(theme);
    setMsgTitle(title);
    setMsgContent(msg);
    setShow(true);
  }
  // mdsnack e
  // filter test s
  const params = new URLSearchParams(window.location.search);
  let activeTags = [];
  try {
    activeTags = params.get('t') && (decodeURI(params.get('t'))) && Base64.decode(decodeURI(params.get('t'))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('t')))) : [];
  } catch (error) {
    console.error("Error in setting active tags:")
    console.error(error)
  }
  // filter test e

  // form D.P.O. s

  // form D.P.O. s
  const profileFormSchema = z.object({
    email: z
      .string({
        required_error: "Please enter a valid email to display.",
      })
      .email(),
    nominative: z
      .string({
        required_error: "Nominative is required.",
      })
      .min(2, {
        message: "Nominative must be at least 2 characters.",
      }),
  });
  // This can come from your database or API.
  const defaultValues = {
    email: "andrea.vitali@overweb.it",
    nominative: "Mario Bros",
  };
  // form D.P.O. e
  const [DPOData, setDPOData] = useState(null);

  const form = useForm({
    resolver: zodResolver(profileFormSchema),
    defaultValues,
    mode: "onChange",
  });
  function onSubmit(data) {
    fetch(`${cysrConfig().api_base_url}account/${accountUUID}/dpo/ask`, {
      body: JSON.stringify({
        validator_email: data.email,
        validator_name: data.nominative,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      method: "POST"
    })
      .then((r) => {
        if (r.ok) {
          // window.location.reload();
          return r.json();
        } else {
          console.error(r);
          return r.json().then(err => {
            throw JSON.stringify(err)
          })
          return r.json()
        }
      })
      .then((response) => {
        console.log(response)
        setOpenDPO(false)
        setDPOData({
          email: data.email,
          validator_name: data.nominative,
          // inviter_name: "Z <abc@dfg.it>",
        });
      }
      ).catch((err) => {
        console.log(err);
        console.log("err3:", err);
        if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
          toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
        } else if (JSON.parse(err) && JSON.parse(err).error) {
          toggleSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
        } else {
          toggleSnackbar("Error", "Some error happened", "error", "error", "Error");
        }
        setOpenDPO(false)
        setDPOData(null)
      })
  }
  // form D.P.O. e
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [ctiStatus, setCtiStatus] = useState("acknowledged");
  const [tabValue, setTabValue] = useState(0);
  const [selectedSub, setSelectedSub] = useState(0);
  const [selectedKey, setSelectedKey] = useState(0);
  const [tabsSwitchingLoading, setTabsSwitchingLoading] = useState(false);
  const [lastTimeuuid, setSelectedLastTimeuuid] = useState(0);
  const [kiData, setKiData] = useState(Array());
  const tabsSwapper = [
    {
      id: "new",
      label: "New"
    },
    {
      id: "ack",
      label: "Acknowledged"
    },
    {
      id: "all",
      label: "All"
    }
  ];
  function selectCallback(key) {
    setTabsSwitchingLoading(true)
    // console.log("selectedSub")
    // console.log(selectedSub)
    // console.log("selectedKey")
    // console.log(selectedKey)
    // console.log("key")
    // console.log(key)
    // do new call with the ctiStatus s

    // it will be like:
    // fetch(`${cysrConfig().api_base_url}cti/${accountUUID}/list/${ctiStatus}/`, {

    fetch(`${cysrConfig().api_base_url}cti/${selectedSub}/botnet/list/${ctiStatus}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      },
    })
      .then((response) => response.json())
      .then((rSD) => {
        if (rSD && rSD[key]) {
          // console.log("rSD)")
          // console.log(rSD)
          setKiData(rSD[key].data)
          setSelectedLastTimeuuid(rSD[key].last_timeuuid)
        } else {
          // reset s
          // se servisse un reset decommentare:
          // setKiData([])
          // setSelectedLastTimeuuid(0)
          // reset e
          // console.warn(`${cysrConfig().api_base_url}cti/${selectedSub}/botnet/list/${ctiStatus}/`)
          // console.log("rSD)")
          // console.log(rSD)
        }
        setTabsSwitchingLoading(false)
        // console.log("kiData")
        // console.log(kiData)
      })
      .catch((err) => console.error(err));
    // do new call with the ctiStatus e
  }
  const tabHandler = (event, newValue) => {
    setTabsSwitchingLoading(true)
    setTabValue(newValue);
    setCtiStatus(tabsSwapper[newValue].id)
    // console.warn("tabHandler")
    // console.warn(selectedSub)
    // do new call with the ctiStatus s
    fetch(`${cysrConfig().api_base_url}cti/${selectedSub}/botnet/list/${tabsSwapper[newValue].id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      },
    })
      .then((response) => response.json())
      .then((rSD) => {
        // console.log("rSD)")
        // console.log(rSD)
        if (rSD && rSD[selectedKey]) {
          setKiData(rSD[selectedKey].data)
          setSelectedLastTimeuuid(rSD[selectedKey].last_timeuuid)
        }
        setTabsSwitchingLoading(false)
        // console.log("kiData")
        // console.log(kiData)
      })
      .catch((err) => console.error(err));
    // do new call with the ctiStatus e
  };

  // Our State.
  const [views, setViews] = useState([]);
  // Tutti gli asset di un account (/asset/) senza UUID?
  if (localStorage.getItem("cysr_useraccountroles") === null) {
    window.location.href = "/cysr/login";
  }

  const getCallUrl = `${cysrConfig().api_base_url}cti/${accountUUID}/ack/list/`;
  useEffect(() => {
    const pageTitle = "Botnets Archive";
    document.title = `${pageTitle} - ${document.title.split(" - ")[1] ? document.title.split(" - ")[1] : document.title}`;
    if (localStorage.getItem("cysr_useraccountroles")) {
      //let groupSelectData = [];
      // call table of asset + call table of checks
      fetch(getCallUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
      })
        .then((response) => response.json())
        .then((ctiResponse) => {
          console.log('ctiResponse');
          console.log(ctiResponse);
          setViews({
            // surveillance: responseSurveillanceData,
            //groupSelectData: removedEmptyGroupSelectData,
            ctiList: ctiResponse,
            cysr_tags_active: activeTags
          });
        })

        .catch((err) => console.error(err));
    } else {
      setViews({});
    }
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar cysr_company />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card
            style={{
              background: darkMode ? "#192328" : "white",
            }}>
            <InfoButton cysr_info={cysrConfig().i("surveillance", "botnets", "help")} cysr_info_link={cysrConfig().i("surveillance", "botnets", "help_link")} />
            {false && <div style={{
              padding: 24
            }}>
              {CompanyDPO([], DPOData, setDPOData, form, onSubmit, darkMode, openDPO, setOpenDPO)}
            </div>}
            <MDBox py={5} px={3} fullWidth>
              <div className="flex flex-col gap-4 justify-center">
                {cysrConfig().i("surveillance", "botnets", "title") && (
                  <MDTypography variant="h5">Credential Breach Acknowledgment</MDTypography>
                )}
                <div className="flex gap-2 items-center">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.85355 3.14645C7.04882 3.34171 7.04882 3.65829 6.85355 3.85355L3.70711 7H12.5C12.7761 7 13 7.22386 13 7.5C13 7.77614 12.7761 8 12.5 8H3.70711L6.85355 11.1464C7.04882 11.3417 7.04882 11.6583 6.85355 11.8536C6.65829 12.0488 6.34171 12.0488 6.14645 11.8536L2.14645 7.85355C1.95118 7.65829 1.95118 7.34171 2.14645 7.14645L6.14645 3.14645C6.34171 2.95118 6.65829 2.95118 6.85355 3.14645Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
                  <a href="cti" className="text-base">Back to CTI list</a>
                </div>
              </div>
              {cysrConfig().i("surveillance", "botnets", "sub_title") && (
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {cysrConfig().i("surveillance", "botnets", "sub_title")}
                </MDTypography>
              )}
              <MDBox pt={3}>
                {views && views.ctiList && tabsSwitchingLoading === false ?
                  <>
                    {views.ctiList ?
                      <>
                        {false &&
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              {views && views.groupSelectData &&
                                <GroupedSelect setSelectedKey={setSelectedKey} selectCallback={selectCallback} defaultValue={selectedKey} data={views.groupSelectData} label="Search key" title="Search key" />
                              }
                            </Grid>
                            <Grid item xs={12} sm={6} ml="auto">
                              <MDBox ml="auto" mb={2} p={.25} style={{
                                backgroundColor: darkMode ? "#040506" : "#f8f9fa",
                                borderRadius: "0.75rem",
                                maxWidth: "300px"
                              }}>
                                <AppBar position="static">
                                  <Tabs defaultValue={0} value={tabValue} onChange={tabHandler}>
                                    {tabsSwapper.map((item) => (
                                      <Tab
                                        id={item.id}
                                        label={
                                          <MDBox py={0.25} px={1} color="inherit">
                                            {item.label}
                                          </MDBox>
                                        }
                                      />
                                    ))}
                                  </Tabs>
                                </AppBar>
                              </MDBox>
                            </Grid>
                          </Grid>
                        }
                        <MDBox pb={1} px={1} fullWidth>
                          <div className={`${darkMode ? "dark" : "light"} cti-table text-turquoise-950 dark:text-turquoise-50 h-full flex-1 flex-col space-y-8 flex`}>

                            <DataTable data={
                              views.ctiList
                            } columns={columns} darkMode={darkMode} />

                          </div>
                        </MDBox>
                        {
                          false && <MDBox pb={1} px={1} fullWidth>
                            {kiData && kiData.length > 0 ?

                              <CysrTableKi data={
                                {
                                  search: params.get('s') ? params.get('s') : "",
                                  hideCompleted: params.get('hc') ? true : false,
                                  // options available
                                  tableTagsOptions: [],
                                  nodes: kiData,
                                  subscription: selectedSub,
                                  key: selectedKey,
                                  last_timeuuid: lastTimeuuid,
                                  ctiStatus: ctiStatus,
                                  tableTagsActive: views.cysr_tags_active,
                                }
                              }
                                selectCallback={selectCallback}
                                toggleSnackbar={toggleSnackbar}
                              />
                              : <MDTypography>There are no {ctiStatus} botnets for {selectedKey}.</MDTypography>
                            }
                          </MDBox>
                        }
                      </>
                      :
                      <MDTypography>No botnet search keys are configured for your subscription.</MDTypography>
                    }
                  </>
                  : <Loader />
                }
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>

      <MDSnackbar
        color={msgTheme}
        icon={msgIcon}
        title={msgTitle}
        content={msgContent}
        dateTime={msgDateTime}
        open={show}
        close={defaultToggleSnackbar}
      />
    </DashboardLayout >
  );
}

export default BotnetsArchive;

/* eslint-disable */
// prettier-ignore
