/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import HierarchicalGraph from "layouts/cysr/components/Circle2GraphStatic";

import cysrConfig from "layouts/cysr/config";
import './App.css';

function getSortRank(t) {
  if (t == 'domain') {
    return 10
  } else if (t == 'email') {
    return 20
  } else if (t == 'dns') {
    return 30
  } else if (t == 'host') {
    return 40
  } else if (t == 'website') {
    return 50
  } else if (t == 'ip') {
    return 60
  } else if (t == 'network') {
    return 70
  } else if (t == 'as') {
    return 80
  } else {
    return 0
  }
}

/* eslint-disable */
function Radar() {
  const params = new URLSearchParams(window.location.search);
  // Our State.
  const accountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/company/") + "/company/".length,
    window.location.pathname.lastIndexOf("/")
  );
  const dashboardCallUrl = `${cysrConfig().api_base_url}dashboard/mainpage/${accountUUID}/`;
  // useEffect(() => {
  //setLoading(true);
  let chartData = false;
  const request = new XMLHttpRequest();
  request.open('GET', dashboardCallUrl, false);  // `false` makes the request synchronous
  request.setRequestHeader("Authorization", `Bearer ${params.get('t')}`);
  request.send(null);

  if (request.status === 200) {
    chartData = JSON.parse(request.responseText).assets.graphs.circle2;
    let orderedCircle2 = {};
    orderedCircle2.tree = (chartData.tree).sort((a, b) => {
      return getSortRank(a.group) - getSortRank(b.group)
    });
    orderedCircle2.flowEdges = chartData.flowEdges;
    // add status to circle graph s
    JSON.parse(request.responseText).assets.graphs.general.nodes.forEach(node => {
      orderedCircle2.tree.find((x) => x.id === node.id).status = node.status
    });
    // add status to circle graph e
    chartData = orderedCircle2;
  }
  // very similar to handleDownloadPdf e
  // }, []);
  return (
    <div style={{ background: "#ffffff", display: "block", textAlign: "center", position: "fixed", zIndex: "99999", left: "0", right: "0", top: "0", bottom: "0", padding: "0", overflow: "auto", height: "100%", width: "100%", minHeight: 760, minWidth: 760 }}>
      <HierarchicalGraph data={chartData} />
    </div>
  );
}
/* eslint-disable */
export default Radar;
