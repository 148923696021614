/* eslint-disable */
// prettier-ignore
import { BarList } from '@tremor/react';

export function InsightBarList({ data, labels = ["Value", "Count"], valueFormatter = undefined }) {
    return (<>
        <p className="mt-4 text-tremor-default flex items-center justify-between text-tremor-content dark:text-dark-tremor-content">
            <span>{labels[0]}</span>
            <span>{labels[1]}</span>
        </p>
        <BarList data={data.sort((a, b) => b.value - a.value)} valueFormatter={valueFormatter} className="mt-2" // sortOrder="descending" (default)
        />
    </>
    );
}