/* eslint-disable */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import cysrConfig from "layouts/cysr/config";
import { useSnackbar } from 'notistack';

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function Sidenav({ profileData }) {
  const [controller] = useMaterialUIController();
  //snackBar s
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // add action to an individual snackbar
  const action = snackbarId => (
    <>
      <MDButton onClick={() => { closeSnackbar(snackbarId) }} style={cysrConfig().button_style} size="medium">
        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().close.icon.svg }} />
        Dismiss
      </MDButton>
    </>
  );
  //snackBar e
  const { darkMode } = controller;

  const [canManageBillingInfo, setCanManageBillingInfo] = useState(false);
  const [canManageSubscriptions, setCanManageSubscriptions] = useState(false);

  const sidenavItems = [
    { type: "title", title: "Information", key: "side-title-profile" },
    { icon: "person", label: "profile", href: "profile" },
    { icon: "receipt_long", label: "basic info", href: "basic-info" },
    { icon: "lock", label: "change password", href: "change-password" },
    { icon: "security", label: "2FA", href: "2fa" },
    { icon: "badge", label: "Default subscription", href: "subscriptions" },
    // { icon: "campaign", label: "notifications", href: "notifications" },
    // { icon: "settings_applications", label: "sessions", href: "sessions" },
    // { icon: "delete", label: "delete account", href: "delete-account" },
  ];
  useEffect(() => {
    if (profileData) {
      let manageBillingInfoValue = false;
      let manageSubscriptionsInfoValue = false;
      if (profileData.contractors && (Object.keys(profileData.contractors).length > 0 || (profileData.contractors.length && profileData.contractors.length > 0))) {
        manageBillingInfoValue = true;
        manageSubscriptionsInfoValue = true;
      }
      setCanManageBillingInfo(manageBillingInfoValue)
      setCanManageSubscriptions(manageSubscriptionsInfoValue)
      let popVariant = 'info';
      if (!manageBillingInfoValue || !manageSubscriptionsInfoValue) {
        popVariant = 'warning'
      }
      /*
      enqueueSnackbar(
        <div style={{display: "flex", flexDirection: "column", gap: "12px"}}>
          <MDTypography variant="caption" style={{fontWeight: "600"}}>Cheched permissions</MDTypography>
          <MDTypography variant="caption">{(manageBillingInfoValue && manageSubscriptionsInfoValue) ? `You can manage billing info and subscriptions` : (manageBillingInfoValue ? `You can manage only billing info` : (manageSubscriptionsInfoValue ? `You can manage only subscriptions` : `You can not manage billing info nor subscriptions`))}</MDTypography>
        </div>, { 
          action,
          variant: popVariant,
          persist: true
        }
      )
      */
    }
  }, [profileData]);
  const renderSidenavItems = sidenavItems.map(({ icon, label, href, type, title }, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        {type && type === "title" ?
          <MDTypography style={{
            lineHeight: 1.25, display: "block", marginTop: "8px", fontSize: "12px", fontWeight: 700, textTransform: "uppercase"
          }}>
            {title}
          </MDTypography>
          :
          <MDTypography
            component="a"
            href={`#${href}`}
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            sx={({
              borders: { borderRadius },
              functions: { pxToRem },
              palette: { light },
              transitions,
            }) => ({
              color: darkMode ? "#aed2d6!important" : "#5c959f!important",
              display: "flex",
              alignItems: "center",
              borderRadius: borderRadius.md,
              padding: `${pxToRem(10)} ${pxToRem(16)}`,
              transition: transitions.create("background-color", {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.shorter,
              }),

              "&:hover": {
                backgroundColor: light.main,
              },
            })}
          >
            <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
              <Icon fontSize="small">{icon}</Icon>
            </MDBox>
            {label}
          </MDTypography>
        }
      </MDBox>
    );
  });

  return (
    <div style={{
      position: "sticky",
      top: "1%"
    }}>
      <Card
        sx={{
          borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
          // position: "sticky",
          // top: "1%",
        }}
      >
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={2}
          m={0}
          sx={{ listStyle: "none" }}
        >
          {renderSidenavItems}
        </MDBox>
      </Card>
      {
        // notification s
      }
      <Card
        sx={{
          borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
          marginTop: "1em"
        }}
      >
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={2}
          m={0}
          sx={{ listStyle: "none" }}
        >
          <MDTypography style={{
            lineHeight: 1.25, display: "block", marginBottom: "8px", marginTop: "8px", fontSize: "12px", fontWeight: 700, textTransform: "uppercase"
          }}>Notifications</MDTypography>

          <MDBox component="li" pt={0}>
            <MDTypography
              component="a"
              href="#channels"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              sx={({
                borders: { borderRadius },
                functions: { pxToRem },
                palette: { light },
                transitions,
              }) => ({
                color: darkMode ? "#aed2d6!important" : "#5c959f!important",
                display: "flex",
                alignItems: "center",
                borderRadius: borderRadius.md,
                padding: `${pxToRem(10)} ${pxToRem(16)}`,
                transition: transitions.create("background-color", {
                  easing: transitions.easing.easeInOut,
                  duration: transitions.duration.shorter,
                }),

                "&:hover": {
                  backgroundColor: light.main,
                },
              })}
            >
              <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
                <Icon fontSize="small">campaign</Icon>
              </MDBox>
              Channels
            </MDTypography>
          </MDBox>

          <MDBox component="li">
            <MDTypography
              component="a"
              href="#topics"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              sx={({
                borders: { borderRadius },
                functions: { pxToRem },
                palette: { light },
                transitions,
              }) => ({
                color: darkMode ? "#aed2d6!important" : "#5c959f!important",
                display: "flex",
                alignItems: "center",
                borderRadius: borderRadius.md,
                padding: `${pxToRem(10)} ${pxToRem(16)}`,
                transition: transitions.create("background-color", {
                  easing: transitions.easing.easeInOut,
                  duration: transitions.duration.shorter,
                }),

                "&:hover": {
                  backgroundColor: light.main,
                },
              })}
            >
              <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
                <Icon fontSize="small">settings_applications</Icon>
              </MDBox>
              Topics
            </MDTypography>
          </MDBox>

        </MDBox>
      </Card>
      {
        // notification e
      }
      {(canManageBillingInfo || canManageSubscriptions) && <Card
        sx={{
          borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
          marginTop: "1em"
        }}
      >
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={2}
          m={0}
          sx={{ listStyle: "none" }}
        >
          <MDTypography style={{
            lineHeight: 1.25, display: "block", marginBottom: "8px", marginTop: "8px", fontSize: "12px", fontWeight: 700, textTransform: "uppercase"
          }}>Admin</MDTypography>
          {canManageBillingInfo && <MDBox component="li" pt={0}>
            <MDTypography
              component="a"
              href="/cysr/profile/subscribers"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              sx={({
                borders: { borderRadius },
                functions: { pxToRem },
                palette: { light },
                transitions,
              }) => ({
                color: darkMode ? "#aed2d6!important" : "#5c959f!important",
                display: "flex",
                alignItems: "center",
                borderRadius: borderRadius.md,
                padding: `${pxToRem(10)} ${pxToRem(16)}`,
                transition: transitions.create("background-color", {
                  easing: transitions.easing.easeInOut,
                  duration: transitions.duration.shorter,
                }),

                "&:hover": {
                  backgroundColor: light.main,
                },
              })}
            >
              <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
                <Icon fontSize="small">money</Icon>
              </MDBox>
              Manage Subscribers
            </MDTypography>
          </MDBox>
          }
          {canManageSubscriptions &&
            <MDBox component="li">
              <MDTypography
                component="a"
                href="/cysr/profile/manage-subscriptions"
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                sx={({
                  borders: { borderRadius },
                  functions: { pxToRem },
                  palette: { light },
                  transitions,
                }) => ({
                  color: darkMode ? "#aed2d6!important" : "#5c959f!important",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: borderRadius.md,
                  padding: `${pxToRem(10)} ${pxToRem(16)}`,
                  transition: transitions.create("background-color", {
                    easing: transitions.easing.easeInOut,
                    duration: transitions.duration.shorter,
                  }),

                  "&:hover": {
                    backgroundColor: light.main,
                  },
                })}
              >
                <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
                  <Icon fontSize="small">difference</Icon>
                </MDBox>
                Manage Subscriptions
              </MDTypography>
            </MDBox>
          }
        </MDBox>
      </Card>
      }
    </div>
  );
}

export default Sidenav;
