import Plot from 'react-plotly.js';
// import * as d3 from 'layouts/cysr/components/HierarchicalGraph/d3v4';
import { useState, useEffect } from "react";

/* eslint-disable */
export default function AssetChart(ac_data) {
    const fig = ac_data.fig;

  const [views, setViews] = useState([]);
  useEffect(() => {
        let data = {
          type: "sankey",
          domain: {
            x: [0,1],
            y: [0,1]
          },
          orientation: "h",
          valueformat: ".0f",
          valuesuffix: " route",
          allignments: "right",
          node: {
            pad: 5,
            thickness: 12,
            line: {
                color: "white",
                  width: 0 
            },
           label: fig.data[0].node.label,
           color: fig.data[0].node.color
              },
        
          link: {
            source: fig.data[0].link.source,
            target: fig.data[0].link.target,
            value: fig.data[0].link.value,
            label: fig.data[0].link.label,
            color: fig.data[0].link.color
        
          }
        }
        data = [data]
        
          //title: "CYSR Graph <a href='https://bost.ocks.org/mike/sankey/'>Mike Bostock</a>",
          //title: "CYSR Graph",
        const layout = {
          // title: fig.layout.title.text,
          // width: 1600,
          // height: 800,
          paper_bgcolor:'rgba(0,0,0,0)',
          plot_bgcolor:'rgba(0,0,0,0)',
          margin: {
            // t:0,
            r:0,
            b:0,
            l:0
          },
          font: {
            size: 10,
            color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white"
          }
        }

      setViews({
        data: data,
        layout: layout
      })

    
    }, [])
    return (
        <>
      {views && views.data ? <Plot
        width="100%"
        data={views.data}
        layout={views.layout}
        style={{width: "100%", height: "100%", minHeight:"800px"}}
        config={{
          staticPlot: true,
          displayModeBar: false,
          responsive: true,
          setBackground: "transparent",
          autosize: true,
          autosizable: true
        }}
      /> : <p>No data available</p>}
      </>
    );
}
/* eslint-disable */