/* eslint-disable */
// prettier-ignore
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";


// Material Dashboard 2 PRO React components
import { useMaterialUIController } from "context";
import Select, { components } from 'react-select';
import cysrConfig from "layouts/cysr/config";
import { useSnackbar } from 'notistack';

function Channels() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  /*
  FEED = "feed", _("feed")
  EMAIL = "email", _("email")
  TELEGRAM = "telegram", _("telegram")
  SLACK = "slack", _("slack")
  SMS = "sms", _("sms")
  GOOGLE_CHAT = "google_chat", _("google_chat")
  MICROSOFT_TEAMS = "microsoft_teams", _("microsoft_teams")
  WHATSAPP = "whatsapp", _("whatsapp")
  SIGNAL = "signal", _("signal")
  WEB_HOOK = "web_hook", _("web_hook")
  */
  const allChannels = ["feed", "email"]
  const [channels, setChannels] = useState([])
  const [enabledChannels, setEnabledChannels] = useState([]);
  const [selectStatusLoading, setSelectStatusLoading] = useState(false);
  const [selectSeverityValue, setSelectSeverityValue] = useState({});
  function handleEnabled(ch) {
    let newArray = [];
    let found = false;
    enabledChannels.forEach(uuid => {
      if (uuid == ch.usernotificationchannel_uuid) {
        found = true;
      } else {
        newArray.push(uuid)
      }
    });
    if (found === false) {
      newArray.push(ch.usernotificationchannel_uuid)
    }
    setEnabledChannels(newArray)
  }

  function isThereChannelNameInChannels(x) {
    let found = false;
    channels.forEach(element => {
      if (element.usernotificationchannel_channel === x) {
        found = true;
      }
    });
    return found;
  }

  // custom select
  const colourStyles = {
    placeholder: (styles) => ({
      ...styles,
      fontSize: '16px',
      color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white"
    }),
    multiValueRemove: (base) => ({
      ...base,
      backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#f8f9fa" : "#23272E",
      borderRadius: 0
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
      backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#f8f9fa" : "#23272E",
      height: "18px",
      minHeight: "18px",
      lineHeight: "1",
      fontSize: '12px',
      borderRadius: 0
    }),
    valueContainer: (base) => ({
      ...base,
      width: '100%',
      minWidth: '160px',
      display: "flex",
    }),
    indicatorsContainer: (styles) => ({ ...styles, height: "100%" }),
    indicatorSeparator: (styles) => ({ ...styles, border: "0", backgroundColor: "transparent" }),
    control: (styles, { isDisabled }) => ({
      ...styles, border: "0", color: isDisabled
        ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#bdc7d0" : "#666666") : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: isDisabled
          ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#EEF0F1" : "#17191c") : (localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b"), height: "32px", minHeight: "32px", minWidth: "160px"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? undefined
              : undefined,
        color: isDisabled
          ? '#ccc'
          : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
            && data.color
            : undefined,
        },
      };
    },
    menuList: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b" }),
    input: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };

  const loadingStyles = {
    valueContainer: (base) => ({
      ...base,
      width: '100%',
      display: "flex",
    }),
    indicatorsContainer: (styles) => ({ ...styles, height: "100%" }),
    indicatorSeparator: (styles) => ({ ...styles, border: "0", backgroundColor: "transparent" }),
    control: (styles, { isDisabled }) => ({
      ...styles, border: "0", color: isDisabled
        ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#bdc7d0" : "#666666") : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: isDisabled
          ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#EEF0F1" : "#17191c") : (localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b"), height: "32px", minHeight: "32px"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? undefined
              : undefined,
        color: isDisabled
          ? '#ccc'
          : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
            && data.color
            : undefined,
        },
      };
    },
    menuList: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b" }),
    input: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
    placeholder: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };
  const { Option } = components
  const CustomSelectOption = props => (
    <Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ display: "inline-flex", alignItems: "center", transform: "scale(0.75)", marginRight: "12px" }} dangerouslySetInnerHTML={{ __html: props.data.icon }} />
        <span style={{ fontSize: "14px", lineHeight: "1" }}>{props.data.label}</span>
      </div>
    </Option>
  )

  const CustomSelectValue = props => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: props.data.icon }} />
      <span style={{ fontSize: "16px", lineHeight: "1" }}>{props.data.label}</span>
    </div>
  )
  const CustomSelectValueLoading = props => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={cysrConfig().button_icon_left_style} className="spin" dangerouslySetInnerHTML={{ __html: cysrConfig().loading.icon.svg }} />
      <span style={{ fontSize: "16px", lineHeight: "1" }}>{props.data.label}</span>
    </div>
  )

  const Svg = (p) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      focusable="false"
      role="presentation"
      {...p}
    />
  );
  const DropdownIndicator = () => (
    <div style={{ color: "#0D918F", height: 20, width: 20, display: "flex", alignItems: "center" }}>
      <Svg>
        <path d="M10 13.75L3.75 7.5L4.625 6.625L10 12L15.375 6.625L16.25 7.5L10 13.75Z" fill="currentColor" />
      </Svg>
    </div>
  );

  useEffect(() => {
    const channelsUrl = `${cysrConfig().api_base_url}notification/channel/`;
    fetch(channelsUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      method: "GET",
    })
      .then((response) => {
        if (response.status === 200) {
          response.json()
            .then((responseData) => {
              let chs = [];
              let sv = [];
              let enableds = [];
              responseData.forEach(ch => {
                sv[ch.usernotificationchannel_uuid] = ch.usernotificationchannel_severity;
                if (ch.usernotificationchannel_enabled) {
                  enableds.push(ch.usernotificationchannel_uuid)
                }
                if (allChannels.includes(ch.usernotificationchannel_channel)) {
                  chs.push(ch)
                }
              });
              setEnabledChannels(enableds)
              setSelectSeverityValue(sv)
              setChannels(chs)
            })
        } else {
          return response.json().then(err => {
            throw JSON.stringify(err)
          })
        }
      })
      .catch((err) => {
        console.error("err1:", err);
        console.error(err);
      });
  }, [])
  return (
    <Card id="channels">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Channels</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          Setup and manage your notifications channels settings.
        </MDTypography>
      </MDBox>
      <MDBox pt={2} pb={3} px={3}>
        {allChannels
          .filter((x) => !isThereChannelNameInChannels(x)
          ).map((ach) => <div key={ach}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <MDTypography variant="h5" fontWeight="medium">
                {ach}
              </MDTypography>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                width={{ xs: "100%", sm: "auto" }}
                mt={{ xs: 1, sm: 0 }}
              >
                <MDBox lineHeight={0} mx={2}>
                  <MDTypography variant="button" color="text">
                    Disabled
                  </MDTypography>
                </MDBox>
                <MDBox mr={1}>
                  <Switch checked={false} onChange={
                    function () {
                      // notification/channel/{ch.usernotificationchannel_channel}
                      const channelPostUrl = `${cysrConfig().api_base_url}notification/channel/`;
                      fetch(channelPostUrl, {
                        body: JSON.stringify({
                          usernotificationchannel_channel: ach,
                          usernotificationchannel_conf: {},
                          usernotificationchannel_enabled: true,
                          usernotificationchannel_severity: cysrConfig().severityArray[0].value
                        }),
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                          "content-type": "application/json",
                        },
                        method: "POST",
                      })
                        .then((response) => {
                          if (response.ok) {
                            response.json()
                              .then((responseData) => {
                                console.warn(responseData);
                                // window.location.reload()
                                // handleEnabled(responseData)
                              })
                          } else {
                            return response.json().then(err => {
                              throw JSON.stringify(err)
                            })
                          }
                        })
                        .catch((err) => {
                          console.error("enabled err:", err);
                          console.error(err);
                        });
                    }
                  } />
                </MDBox>
              </MDBox>
            </MDBox>
          </div>
          )}
        {channels && channels.map((ch, i) => <div key={ch.usernotificationchannel_uuid}>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <MDTypography variant="h5" fontWeight="medium">
              {ch.usernotificationchannel_channel}
            </MDTypography>
            <MDBox
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              width={{ xs: "100%", sm: "auto" }}
              mt={{ xs: 1, sm: 0 }}
            >
              <MDBox lineHeight={0} mx={2}>
                <MDTypography variant="button" color="text">
                  {enabledChannels.includes(ch.usernotificationchannel_uuid) ? "Enabled" : "Disabled"}
                </MDTypography>
              </MDBox>
              <MDBox mr={1}>
                <Switch checked={enabledChannels.includes(ch.usernotificationchannel_uuid)} onChange={
                  function () {
                    // notification/channel/{ch.usernotificationchannel_channel}
                    const channelsPatchUrl = `${cysrConfig().api_base_url}notification/channel/${ch.usernotificationchannel_channel}/`;
                    fetch(channelsPatchUrl, {
                      body: JSON.stringify({
                        usernotificationchannel_enabled: !(enabledChannels.includes(ch.usernotificationchannel_uuid)),
                      }),
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                        "content-type": "application/json",
                      },
                      method: "PATCH",
                    })
                      .then((response) => {
                        if (response.ok) {
                          response.json()
                            .then((responseData) => {
                              handleEnabled(ch)
                            })
                        } else {
                          return response.json().then(err => {
                            throw JSON.stringify(err)
                          })
                        }
                      })
                      .catch((err) => {
                        console.error("enabled err:", err);
                        console.error(err);
                      });
                  }
                } />
              </MDBox>
            </MDBox>
          </MDBox>
          {enabledChannels.includes(ch.usernotificationchannel_uuid) &&
            <MDBox style={{ display: "inline-flex", flexDirection: "column", gap: 8 }}>
              <MDTypography variant="button" color="text">
                choose which notifications to receive via {ch.usernotificationchannel_channel}:
              </MDTypography>
              <div>
                <MDTypography variant="caption" color="text">
                  select a level, saved on change
                </MDTypography>
                <Select isSearchable={false} options={cysrConfig().severityArray} defaultValue={cysrConfig().severityArray.filter((s) => s.value == selectSeverityValue[ch.usernotificationchannel_uuid])} styles={selectStatusLoading ? loadingStyles : colourStyles} onChange={(newValue) => {
                  let currentSeverityValue = selectSeverityValue;
                  currentSeverityValue[ch.usernotificationchannel_uuid] = newValue.value;
                  const channelsPatchUrl = `${cysrConfig().api_base_url}notification/channel/${ch.usernotificationchannel_channel}/`;
                  fetch(channelsPatchUrl, {
                    body: JSON.stringify({
                      usernotificationchannel_severity: newValue.value,
                    }),
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                      "content-type": "application/json",
                    },
                    method: "PATCH",
                  })
                    .then((response) => {
                      if (response.ok) {
                        response.json()
                          .then((responseData) => {
                            const newSeverityValue = currentSeverityValue;
                            setSelectSeverityValue(newSeverityValue)
                            enqueueSnackbar(
                              <div style={{ display: "flex", flexDirection: "column" }}>
                                <MDTypography variant="caption" style={{ fontWeight: "600" }}>Updated successfully</MDTypography>
                                <MDTypography variant="caption">Notifications for this channel have been successfully updated</MDTypography>
                              </div>, {
                              variant: 'success',
                              persist: false
                            })
                          })
                      } else {
                        return response.json().then(err => {
                          throw JSON.stringify(err)
                        })
                      }
                    })
                    .catch((err) => {
                      console.error("enabled err:", err);
                      console.error(err);
                      enqueueSnackbar(
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <MDTypography variant="caption" style={{ fontWeight: "600" }}>Updated failed</MDTypography>
                          <MDTypography variant="caption">Notifications for this channel couldn't be updated</MDTypography>
                        </div>, {
                        variant: 'error',
                        persist: false
                      })
                    });

                }}
                  components={{ DropdownIndicator, Option: CustomSelectOption, SingleValue: selectStatusLoading ? CustomSelectValueLoading : CustomSelectValue }}
                />
              </div>
            </MDBox>
          }
          {false &&
            <MDBox pl={6} pt={2} lineHeight={1}>
              <MDTypography variant="button" color="text">
                You haven&apos;t added your Slack yet or you aren&apos;t authorized. Please add our
                Slack Bot to your account by clicking on here. When you&apos;ve added the bot, send your
                verification code that you have received.
              </MDTypography>
              <MDBox
                bgColor={darkMode ? "grey-900" : "grey-100"}
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
                my={3}
                py={1}
                pl={{ xs: 1, sm: 2 }}
                pr={1}
              >
                <MDTypography variant="button" fontWeight="medium" color="text">
                  Verification Code
                </MDTypography>
                <MDBox width={{ xs: "100%", sm: "25%", md: "15%" }} mt={{ xs: 1, sm: 0 }}>
                  <Tooltip title="Copy" placement="top">
                    <MDInput size="small" value="1172913" />
                  </Tooltip>
                </MDBox>
              </MDBox>
              <MDBox
                bgColor={darkMode ? "grey-900" : "grey-100"}
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
                my={3}
                py={1}
                pl={{ xs: 1, sm: 2 }}
                pr={1}
              >
                <MDTypography variant="button" fontWeight="medium" color="text">
                  Connected account
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDBox mr={2} mb={{ xs: 1, sm: 0 }} lineHeight={0}>
                    <MDTypography variant="button" fontWeight="medium">
                      hello@creative-tim.com
                    </MDTypography>
                  </MDBox>
                  <MDButton variant="gradient" color="dark" size="small">
                    delete
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          }
          {(i < channels.length - 1) && <Divider />}
        </div>
        )}
      </MDBox>
    </Card>
  );
}

export default Channels;

/* eslint-disable */
// prettier-ignore