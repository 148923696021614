/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import { useState, useEffect } from "react";
import QRCodeImage from "layouts/cysr/components/QRCodeImage";
import Loader from "layouts/cysr/components/Loader";
import MDAlert from "components/MDAlert";
import AuthCode from "react-auth-code-input";
import cysrConfig from "layouts/cysr/config";

import Icon from "@mui/material/Icon";

function getMultilineStringFromArray(array) {
  let str = "";
  array.forEach(element => {
    str += element;
    if (array[array.length - 1] !== element) {
      // add new line except for last element
      str += "\n";
    }
  });
  return str;
}


function Authentication({ toggleSnackbar }) {
  const [isLoading, setLoading] = useState(true);
  const [isEnabled, setEnabled] = useState(false);
  const [showSetUp, setShowSetUp] = useState(false);
  const [qrValue, setQRValue] = useState("");
  const [backupCodes, setBackupCodes] = useState(Array());
  const [authConfirmationCode, setAuthConfirmationCode] = useState("123");
  // handleOnChange (diverso da quello in LoginWithAuth)
  const handleOnChange = (res) => {
    console.log(res)
    console.log(res.length)
    // automatically do confirmation after input all the 6 inputs
    if (res.length === 6) {
      setAuthConfirmationCode(res)
      authAppConfirmation(res)
    }
  };

  // auth/app/activate/confirm
  function authAppConfirmation(confirmationCode = false) {
    if (confirmationCode === false) {
      confirmationCode = authConfirmationCode;
    }
    if (confirmationCode && confirmationCode !== "") {
      const authAppConfirmUrl = `${cysrConfig().api_base_url}auth/app/activate/confirm/`;
      fetch(authAppConfirmUrl, {
        body: JSON.stringify({
          code: confirmationCode
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
          "content-type": "application/json",
        },
        method: "POST",
      })
        .then((response) => {
          if (response.ok) {
            // window.location.reload();
            return response.json();
          } else {
            console.error(response);
            return response.json().then(err => {
              throw JSON.stringify(err);
            });
            // setSelectAssetSrcLoading(false)
            // return response.json()
          }
        })
        .then((responseData) => {
          console.log('responseData')
          console.log(responseData)
          // toggleSnackbar(responseData.Message, responseData.Detail, "success", "checkmark", "Saved");
          toggleSnackbar("Save the backup codes", "Auth enabled, now save the backup codes somewhere safe", "info", "info", "Start");
          setBackupCodes(responseData.backup_codes)
          // setQRValue(responseData.details)
          // setShowSetUp(true)
          setEnabled(true);
        })
        .catch((err) => {
          console.log("err1:", err);
          if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
            toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
          } else if (JSON.parse(err) && JSON.parse(err).error) {
            toggleSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
          } else {
            toggleSnackbar("Error", "Some error happened", "error", "error", "Error");
          }
          console.log(err);
        });
    } else {
      toggleSnackbar("Insert the code", "Write down the code received in the app to activate", "info", "info", "Info");
    }
  }
  // auth/app/activate/
  function authAppActivate() {
    if (isEnabled === false) {
      const authAppActivateUrl = `${cysrConfig().api_base_url}auth/app/activate/`;
      fetch(authAppActivateUrl, {
        /*
        body: JSON.stringify({
          user_subscription_default: sid
        }),
        */
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
          "content-type": "application/json",
        },
        method: "POST",
      })
        .then((response) => {
          if (response.ok) {
            // window.location.reload();
            return response.json();
          } else {
            console.error(response);
            return response.json().then(err => {
              throw JSON.stringify(err);
            });
            // setSelectAssetSrcLoading(false)
            // return response.json()
          }
        })
        .then((responseData) => {
          console.log('responseData')
          console.log(responseData)
          // toggleSnackbar(responseData.Message, responseData.Detail, "success", "checkmark", "Saved");
          toggleSnackbar("Auth set up started", "Follow the instructions", "info", "info", "Start");
          setQRValue(responseData.details)
          setShowSetUp(true)
          // setEnabled(true);
        })
        .catch((err) => {
          console.log("err1:", err);
          if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
            toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
          } else if (JSON.parse(err) && JSON.parse(err).error) {
            toggleSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
          } else {
            toggleSnackbar("Error", "Some error happened", "error", "error", "Error");
          }
          console.log(err);
        });
    } else {
      toggleSnackbar("Already activated", "The 2FA is already activated", "info", "info", "Info");
    }
  }

  // auth/app/deactivate/
  function authAppDeactivate() {
    if (isEnabled === true) {
      const authAppDeactivateUrl = `${cysrConfig().api_base_url}auth/app/deactivate/`;
      fetch(authAppDeactivateUrl, {
        /*
        body: JSON.stringify({
          user_subscription_default: sid
        }),
        */
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
          "content-type": "application/json",
        },
        method: "POST",
      })
        .then((response) => {
          console.log("deactivate response")
          console.log(response)
          if (response.status === 204) {
            // window.location.reload();
            // return response.json();
            // questa chiamata non ritorna json
            return response;
          } else {
            console.error(response);
            return response.json().then(err => {
              throw JSON.stringify(err);
            });
            // setSelectAssetSrcLoading(false)
            // return response.json()
          }
        })
        .then((responseData) => {
          toggleSnackbar("Deactivated with success", "The auth app has been succesfully deactivated", "success", "checkmark", "Deactivated");
          setBackupCodes(Array())
          setEnabled(false);
        })
        .catch((err) => {
          console.log("err1:", err);
          if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
            toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
          } else if (JSON.parse(err) && JSON.parse(err).error) {
            toggleSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
          } else {
            toggleSnackbar("Error", "Some error happened", "error", "error", "Error");
          }
          console.log(err);
        });
    } else {
      toggleSnackbar("Already deactivated", "The 2FA is already deactivated", "info", "info", "Info");
    }
  }

  useEffect(() => {
    if (localStorage.getItem("cysr_useraccountroles")) {
      // auth/mfa/user-active-methods/
      fetch(`${cysrConfig().api_base_url}auth/mfa/user-active-methods/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
      })
        .then((response) => response.json())
        .then((responseActiveMethods) => {
          console.log("activeMethods");
          console.log(responseActiveMethods);
          let enabledAppMethod = false;
          responseActiveMethods.forEach(e => {
            /*
            {
              "name": "app",
              "is_primary": true
            }
            */
            if (e.name === "app") {
              enabledAppMethod = true;
            }
          });
          setEnabled(enabledAppMethod)
          setLoading(false);
        })
        .catch((err) => {
          console.log(err)
          if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
            toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
          } else if (JSON.parse(err) && JSON.parse(err).error) {
            toggleSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
          } else {
            toggleSnackbar("Error", "Some error happened", "error", "error", "Error");
          }
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);
  return (
    <Card id="2fa" sx={{ overflow: "visible" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDTypography variant="h5">Two-factor authentication</MDTypography>
        {isLoading ?
          <Loader />
          :
          <MDBadge variant="contained" color={isEnabled ? "success" : "error"} badgeContent={isEnabled ? "enabled" : "disabled"} container />
        }
      </MDBox>
      {isLoading ?
        <Loader />
        :
        <MDBox p={3}>
          {false &&
            <>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDTypography variant="body2" color="text">
                  Security keys
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      No Security keys
                    </MDTypography>
                  </MDBox>
                  <MDButton variant="outlined" color="dark" size="small">
                    add
                  </MDButton>
                </MDBox>
              </MDBox>
              <Divider />
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDTypography variant="body2" color="text">
                  SMS number
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      +3012374423
                    </MDTypography>
                  </MDBox>
                  <MDButton variant="outlined" color="dark" size="small">
                    edit
                  </MDButton>
                </MDBox>
              </MDBox>
              <Divider />
            </>
          }
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <MDTypography variant="body2" color="text">
              Authenticator app
            </MDTypography>
            {showSetUp &&
              <>
                <div className="cysr-qr-auth-setup">
                  <Card>
                    <MDBox p={3}>
                      <MDTypography
                        style={{ width: "100%", textAlign: "right", cursor: "pointer", margin: "4px auto" }}
                        variant="h5"
                        color="text"
                        lineHeight={1}
                        onClick={() => {
                          setShowSetUp(false)
                        }}
                      >
                        <Icon color="text">close</Icon>
                      </MDTypography>
                      {backupCodes && backupCodes.length > 0 ?
                        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                          <MDTypography variant="h5">Backup Codes</MDTypography>
                          <MDTypography variant="button" mb={1}>Use these backup codes to be able to login in case you can't access the auth app on your device</MDTypography>
                          <MDAlert color="warning">
                            <Icon fontSize="small">warning</Icon>&nbsp;
                            <MDTypography style={{ fontSize: "16px" }}>Be sure to copy it now or never</MDTypography>
                          </MDAlert>
                          <MDInput multiline row={5} readOnly type="text" label="Backup Codes" id="cysr_backup_codes-input" value={getMultilineStringFromArray(backupCodes)} fullWidth />
                          <MDTypography style={{ width: "100%", textAlign: "center", cursor: "pointer", margin: "4px auto" }}
                            onClick={() => {
                              document.getElementById("cysr_backup_codes-input").select();
                              document.execCommand("copy");
                              toggleSnackbar("Backup Codes copied", "The backup codes are copied to your clipboard", "info", "info", "Info");
                            }} variant="body1" color="info" lineHeight={1}>
                            <Icon color="inherit">copy</Icon>
                          </MDTypography>
                        </div>
                        :
                        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                          {qrValue &&
                            <>
                              <QRCodeImage value={qrValue} />
                              {false &&
                                <MDTypography
                                  style={{ width: "100%", textAlign: "center", cursor: "pointer", margin: "4px auto" }}
                                  variant="body1"
                                  color="info"
                                  lineHeight={1}
                                  onClick={() => {
                                    authAppActivate()
                                  }}
                                >
                                  <Icon color="inherit">refresh</Icon>
                                </MDTypography>
                              }
                              <MDTypography variant="button" mb={1}>Scan this QR Code using your auth app of choice to create an account on your device, then type here the code you see on your device to confirm the connection.</MDTypography>
                            </>
                          }
                          {false && <MDInput type="text" label="Confirm Code" id="cysr_code_confirm" fullWidth />}
                          <div className='cysr-auth-container'>
                            <div className='cysr-auth-code'>
                              <MDTypography variant="caption" mb={1}>
                                Enter the code to continue
                              </MDTypography>
                              <AuthCode onChange={handleOnChange} style={{ display: "flex" }} />
                            </div>
                          </div>
                          <MDButton
                            onClick={() => {
                              authAppConfirmation()
                            }}
                            style={cysrConfig().button_style} size="medium">
                            <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().app_auth.icon.svg }} />
                            Connect auth app
                          </MDButton>
                        </div>
                      }
                    </MDBox>
                  </Card>
                </div>
                <div className="cysr-qr-auth-setup-back" onClick={() => {
                  setShowSetUp(false)
                }}></div>
              </>
            }
            <MDBox
              display="flex"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              {isEnabled ?
                <MDButton
                  aria-label="Deactivate"
                  onClick={() => {
                    authAppDeactivate()
                  }} style={cysrConfig().button_style} size="medium">
                  <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().remove.icon.svg }} />
                  Deactivate
                </MDButton>
                : <>
                  <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      Not Configured
                    </MDTypography>
                  </MDBox>
                  <MDButton
                    aria-label="Deactivate"
                    onClick={() => {
                      authAppActivate()
                    }} style={cysrConfig().button_style} size="medium">
                    <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().add.icon.svg }} />
                    Set up
                  </MDButton>
                </>
              }
            </MDBox>
          </MDBox>
        </MDBox>
      }
    </Card>
  );
}

export default Authentication;
/* eslint-disable */