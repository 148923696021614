// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/waffle

/* eslint-disable */
import React from "react";
import { ResponsiveWaffle } from '@nivo/waffle'

import { colorArray } from "layouts/cysr/insight"
/*
const data = [
    {
      "id": "men",
      "label": "men",
      "value": 22.661150503546118,
      "color": "#468df3"
    }
  ];
  */
const MyResponsiveWaffle = function ({ data, className, avgValProperty = null }) {
    const h = 200;
    const w = 200;
    let colors = []
    let legendsData = [];
    let total = 0;
    if (data && data.length > 0) {
        data.forEach((element, index) => {
            // decrease the value down to the next integer
            if (element.value) {
                element.value = Math.floor(element.value);
            } else if (element.values && avgValProperty) {
                // caso risk
                element.value = element.values[avgValProperty]["avg"];
            }
            if (element.label === null || element.label === "N/A") {
                element.label = "Unknown"
            } else if (element.label.toUpperCase() === "XX") {
                element.label = "other"
            }
            const color = (element.color ? element.color : colorArray[index].code);
            colors.push(color);
            legendsData.push({
                "color": color,
                "label": element.label,
                "value": element.value// ? Math.floor(element.value) : 0
            })
            total += element.value
        })
    }
    return (

        <div className={className}>
            <div style={{ height: h, width: w, display: "inline-flex", justifyContent: "flex-start" }}>

                <ResponsiveWaffle
                    data={data}
                    total={total}
                    padding={1}
                    rows={10}
                    columns={10}
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    // margin={{ top: 10, right: 10, bottom: 10, left: 120 }}
                    // colors={[data.color]}
                    colors={colors}
                /*
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 40,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemsSpacing: 4,
                        symbolSize: 20,
                        itemDirection: 'left-to-right',
                        itemTextColor: '#cccccc',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#cccccc',
                                    itemBackground: '#f7fafb'
                                }
                            }
                        ]
                    }
                ]}
            */

                />
            </div>
            <div className="flex flex-wrap gap-2 w-full justify-end" style={{ maxWidth: w }}>
                {legendsData.map((z, ind) =>
                    <div className='w-auto flex gap-2 items-center'>
                        <div className='ml-2 h-3 w-3 border' style={
                            {
                                background: z.color ? z.color : colorArray[ind].code
                            }
                        }></div>
                        <div className="flex items-center gap-2">
                            <p className="text-sm text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis font-medium">{z.label}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export function Waffle({ data, className, avgValProperty = null }) {
    return (<MyResponsiveWaffle className={className} data={data} avgValProperty={avgValProperty} />
    );
}

/* eslint-disable */