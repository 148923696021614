/* eslint-disable */
// prettier-ignore
"use client";

import * as React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";

import { cn } from "lib/utils"

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef(({ className, ...props }, ref) => (
    <AccordionPrimitive.Item ref={ref} className={cn(className)} {...props} />
));
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = React.forwardRef(({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Header className="flex rounded-md px-4 py-2 data-[state=open]:!bg-transparent" style={{ background: "rgba(195, 218, 222, 20%" }}>
        <AccordionPrimitive.Trigger
            ref={ref}
            className={cn(
                "flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all [&[data-state=open]>.flip]:rotate-180",
                className
            )}
            {...props}
        >
            {children}
            <button size="sm" className="flip">
                <svg
                    className="h-4 w-4"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M4 6H11L7.5 10.5L4 6Z" fill="currentColor"></path>
                </svg>
            </button>
        </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef(({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Content
        ref={ref}
        className={cn(
            "overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
            "rounded-md border border-turquoise-200 bg-turquoise-100 text-turquoise-950 shadow-sm dark:border-turquoise-800 dark:bg-turquoise-900 dark:text-turquoise-50 p-4",
            className
        )}
        {...props}
    >
        {children}
    </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
