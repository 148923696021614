/* eslint-disable */
import Icon from "layouts/cysr/components/Icon";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Action({
  className,
  disabled,
  width = "auto",
  leftIcon,
  rightIcon,
  loading,
  text,
  type,
  onChange,
  onClick,
  defaultValue,
  value,
  title,
  placeholder,
  ...params
}) {
  switch (type) {
    // handle all text like cases
    case "text":
    case "tel":
    case "search":
    case "number":
    case "email":
      return (
        <label className={classNames(
          "text-xs flex flex-col gap-1 indent-2 text-zinc-600 dark:text-zinc-400",
          className
        )}
        >
          {text && <span>{text}</span>}
          {value || defaultValue === undefined ? <input
            type={type}
            disabled={disabled}
            onChange={onChange}
            value={value}
            className={classNames(
              `disabled:cursor-not-allowed disabled:bg-zinc-300 disabled:dark:bg-zinc-800 disabled:text-zinc-50 disabled:dark:text-zinc-500 rounded-md px-3 py-1.5 text-base w-${width} bg-zinc-50 border border-zinc-300 dark:border-zinc-700 dark:bg-zinc-950 text-zinc-900 dark:text-zinc-100`
            )}
            placeholder={placeholder ? placeholder : text}
            {...params}
          />
            :
            <input
              type={type}
              disabled={disabled}
              onChange={onChange}
              defaultValue={defaultValue}
              className={classNames(
                `disabled:cursor-not-allowed disabled:bg-zinc-300 disabled:dark:bg-zinc-800 disabled:text-zinc-50 disabled:dark:text-zinc-500 rounded-md px-3 py-1.5 text-base w-${width} bg-zinc-50 border border-zinc-300 dark:border-zinc-700 dark:bg-zinc-950 text-zinc-900 dark:text-zinc-100`
              )}
              placeholder={placeholder ? placeholder : text}
              {...params}
            />
          }
        </label>
      );
      break;
    case "submit":
      return (
        <button
          type="submit"
          disabled={disabled}
          onClick={onClick}
          title={title}
          className={classNames(
            disabled || loading
              ? "bg-zinc-400 dark:bg-zinc-800 text-zinc-200 dark:text-zinc-500"
              : "bg-cyan-700 dark:bg-cyan-500 text-zinc-100 dark:text-zinc-50",
            `w-${width} rounded-md h-8 px-3 py-1 text-base inline-flex items-center gap-2`,
            className
          )}
        >
          {leftIcon && <Icon icon={leftIcon} loading={loading} size={5} />}
          {text && <span className="whitespace-nowrap">{text}</span>}
          {rightIcon && <Icon icon={rightIcon} loading={loading} size={5} />}
        </button>
      );
      break;

    default:
      return (
        <button
          type="button"
          disabled={disabled}
          onClick={onClick}
          title={title}
          className={classNames(
            disabled || loading
              ? "bg-zinc-400 dark:bg-zinc-800 text-zinc-200 dark:text-zinc-500"
              : "bg-cyan-700 dark:bg-cyan-500 text-zinc-100 dark:text-zinc-50",
            `w-${width} rounded-md h-8 px-3 py-1 text-base inline-flex items-center gap-2`,
            className
          )}
        >
          {leftIcon && <Icon icon={leftIcon} loading={loading} size={5} />}
          {text && <span className="whitespace-nowrap">{text}</span>}
          {rightIcon && <Icon icon={rightIcon} loading={loading} size={5} />}
        </button>
      );
      break;
  }
}
/* eslint-disable */
