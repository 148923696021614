/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import cysrConfig from "layouts/cysr/config";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// Material Dashboard 2 PRO React components
import { useMaterialUIController } from "context";
/* eslint-disable */
function Subscriptions({ defaultSubscriptionID, setDefaultSubscriptionID, subscriptions, toggleSnackbar, radioChangeCallback = false, textDescription = "Choose your default Subscription for the new Companies" }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [subscriptionDetailsOpen, setSubscriptionDetailsOpen] = useState(false);

  function subscriptionRadioChange(sid) {
    if (defaultSubscriptionID !== sid) {
      const editProfileUrl = `${cysrConfig().api_base_url}user/myprofile/`;
      fetch(editProfileUrl, {
        body: JSON.stringify({
          user_subscription_default: sid
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
          "content-type": "application/json",
        },
        method: "PATCH",
      })
        .then((response) => {
          if (response.ok) {
            // window.location.reload();
            return response.json();
          } else {
            console.error(response);
            return response.json().then(err => {
              throw JSON.stringify(err);
            });
            // setSelectAssetSrcLoading(false)
            // return response.json()
          }
        })
        .then((responseData) => {
          // refresh mydata in storage because default subscription changed s
          const myDataCallUrl = `${cysrConfig().api_base_url}user/mydata/`;
          fetch(myDataCallUrl, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
            },
          })
            .then((response) => {
              if (response.ok) {
                // window.location.reload();
                return response.json();
              } else {
                return response.json();
              }
            })
            .then((mydataData) => {

              if (mydataData) {
                localStorage.setItem("cysr_mydata", JSON.stringify(mydataData));
              }
              toggleSnackbar(responseData.Message, responseData.Detail, "success", "checkmark", "Saved");
              setDefaultSubscriptionID(sid);
            })
          // refresh mydata in storage because default subscription changed e
        })
        .catch((err) => {
          console.log("err1:", err);
          toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
          console.log(err);
        });
    } else {
      toggleSnackbar("Already active", "This subscription is already the default", "info", "info", "Info");
    }
  }

  function subscriptionDetailsRow(sid, title, value, formatValue = false) {
    return (
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        {
          // <MDTypography variant="body2" color="text"> 
        }
        <MDTypography variant="button" color="text">
          {title}
        </MDTypography>
        <MDBox
          display="flex"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
            <MDTypography variant="button" color="text" fontWeight="regular">
              {formatValue && formatValue === "date" ? (
                new Date(subscriptions[sid][value]).toLocaleDateString('it-IT', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })
              ) :
                (
                  subscriptions[sid][value]
                  && subscriptions[sid][value].indexOf("&infin;") > -1
                )
                  ? subscriptions[sid][value].replace("&infin;", "∞")
                  : subscriptions[sid][value]
              }
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    )
  }
  return (
    <Card id="subscriptions">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Subscriptions</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          {textDescription}
        </MDTypography>
      </MDBox>

      <MDBox pt={2} pb={3} px={3}>
        {Object.keys(subscriptions).map((sid, i) => (
          <div key={sid}>
            {i > 0 && <Divider />}
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <div>
                <Radio
                  checked={defaultSubscriptionID === sid}
                  onChange={() => {
                    if (radioChangeCallback) {
                      radioChangeCallback(sid)
                    } else {
                      subscriptionRadioChange(sid)
                    }
                  }
                  }
                  value={sid}
                  name="radio-buttons"
                  inputProps={{ 'aria-label': sid }}
                />
                {false &&
                  <MDTypography variant="button" color="text">
                    {defaultSubscriptionID === sid ? "My default" : "Set as my Default"}
                  </MDTypography>
                }
                <div style={{ display: "inline-flex", gap: "8px", alignItems: "center" }}>
                  <MDTypography variant="text" fontSize="small">
                    {subscriptions[sid]["subscription_holder"]}
                  </MDTypography>
                  <MDTypography variant="text" fontSize="small" color="text"> - </MDTypography>
                  <MDTypography variant="text" fontSize="small">
                    {subscriptions[sid]["subscription_contracttype"]}
                  </MDTypography>
                  <MDTypography variant="text" fontSize="small" color="text"> - </MDTypography>
                  <MDTypography variant="tex" fontSize="small">
                    {subscriptions[sid]["subscription_status"]}
                  </MDTypography>
                </div>
              </div>
              <MDButton
                aria-label="expand row"
                onClick={() => {
                  subscriptionDetailsOpen === sid ? setSubscriptionDetailsOpen(false) : setSubscriptionDetailsOpen(sid)
                }} style={cysrConfig().button_style} size="medium">
                {subscriptionDetailsOpen === sid ? <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} /> : <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />
                }
                Show Details
              </MDButton>
            </MDBox>
            <Collapse in={subscriptionDetailsOpen === sid} style={cysrConfig().collapse_style} timeout="auto" unmountOnExit>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                width={{ xs: "100%", sm: "auto" }}
                mt={{ xs: 1, sm: 0 }}
              >
                {false &&
                  <MDBox mr={1}>
                    <Switch disabled checked={defaultSubscriptionID === sid} onChange={() => {
                      if (radioChangeCallback) {
                        radioChangeCallback(sid)
                      } else {
                        subscriptionRadioChange(sid)
                      }
                    }} />
                  </MDBox>
                }
                <MDBox width="100%">
                  {subscriptionDetailsRow(sid, "Holder", "subscription_holder")}
                  <Divider />
                  {subscriptionDetailsRow(sid, "Billing Contact", "subscription_billingcontact")}
                  <Divider />
                  {subscriptionDetailsRow(sid, "Status", "subscription_status")}
                  <Divider />
                  {subscriptionDetailsRow(sid, "Type", "subscription_contracttype")}
                  <Divider />
                  {subscriptionDetailsRow(sid, "Start", "subscription_start_datetime", "date")}
                  <Divider />
                  {subscriptionDetailsRow(sid, "End", "subscription_end_datetime", "date")}
                  <Divider />
                  {subscriptionDetailsRow(sid, "Companies", "companies_active_count")}
                  <Divider />
                  {subscriptionDetailsRow(sid, "Thirdparties", "thirdparty_active_count")}
                  <Divider />
                  {subscriptionDetailsRow(sid, "Checks", "subscription_check_count")}
                </MDBox>
              </MDBox>
            </Collapse>
          </div>
        ))}


      </MDBox>
    </Card>
  );
}

// Setting default values for the props
Subscriptions.defaultProps = {
  defaultSubscriptionID: "0",
  subscriptions: {},
};

// Typechecking props
Subscriptions.propTypes = {
  defaultSubscriptionID: PropTypes.string,
  subscriptions: PropTypes.objectOf(PropTypes.any),
};

export default Subscriptions;
/* eslint-disable */