/* eslint-disable */
import { Base64 } from 'js-base64';
import { useState } from "react";
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/material-ui';
import { Collapse, Paper, Stack, TablePagination, Checkbox, FormControlLabel, Icon } from '@mui/material';

import { usePagination } from '@table-library/react-table-library/pagination';
import { useSort } from '@table-library/react-table-library/sort';

// custom stuffs s
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import cysrConfig from "layouts/cysr/config";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import ScoreItem from "layouts/cysr/components/ScoreItem";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import StatusItem from "layouts/cysr/components/StatusItem";
import CysrTags from "layouts/cysr/components/CysrTags";
// import FormField from "layouts/pages/account/components/FormField";

import { useMaterialUIController } from "context";
import { useSnackbar } from 'notistack';
// custom stuffs e

const Component = ({ data }) => {
  const subscription = data.subscription;
  const contractor = data.contractor;
  const withStatus = data.withStatus;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedItemForDialog, setSelectedItemForDialog] = useState(null);
  let tData = { nodes: data.nodes };
  //snackBar s
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // add action to an individual snackbar
  const action = snackbarId => (
    <>
      <MDButton onClick={() => { closeSnackbar(snackbarId) }} style={cysrConfig().button_style} size="medium">
        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().close.icon.svg }} />
        Dismiss
      </MDButton>
    </>
  );
  //snackBar e

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const actionsPixelWidth = 164;
  const tableRowsPerPage = [25, 50, 100, 200, 400];
  const [tableTagsActive, setTableTagsActive] = useState(data.tableTagsActive);
  const [tableTagsOptions, setTableTagsOptions] = useState(data.tableTagsOptions);
  const [hideCompleted, setHideCompleted] = useState(data.hideCompleted);
  const [search, setSearch] = useState(data.search);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  let accountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/company/") + "/company/".length,
    window.location.pathname.lastIndexOf("/")
  );
  if (localStorage.getItem("cysr_useraccountrole_selected")) {
    accountUUID = localStorage.getItem("cysr_useraccountrole_selected");
  }

  let COLUMNS = [];
  if (contractor) {
    COLUMNS = [
      /*
      {
        label: 'Status',
        renderCell: (item) => item.status ? item.status : "Unsure",
        sort: { sortKey: 'STATUS' },
        resize: true,
        cysrTag: "status"
      },*/
      {
        label: 'Email',
        renderCell: (item) => item.email,
        sort: { sortKey: 'EMAIL' },
        resize: true,
        cysrTag: "email"
      },
      {
        label: 'Actions',
        renderCell: (item) => (
          <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '8px', width: `${actionsPixelWidth}px` }}>
            <MDButton onClick={() => handleRemoveUser(item)} style={cysrConfig().button_style} size="medium">
              <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().remove.icon.svg }} />
              Remove user
            </MDButton>
            {false &&
              <MDButton onClick={() => handleExpand(item)} style={cysrConfig().button_style} size="medium">
                {ids.includes(item.email) ?
                  <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} />
                  :
                  <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().edit.icon.svg }} />
                }
                Edit user
              </MDButton>
            }
          </div>
        ),
        pinRight: true
        // sort: { sortKey: 'RATING' }
      },
    ]
  } else {
    COLUMNS = [
      {
        label: 'Nominative',
        renderCell: (item) => (item.first_name || item.last_name) ? `${item.first_name} ${item.last_name}` : item.username,
        sort: { sortKey: 'USERNAME' },
        resize: true,
        cysrTag: "nominative"
      },
      {
        label: 'Email',
        renderCell: (item) => item.email,
        sort: { sortKey: 'EMAIL' },
        resize: true,
        cysrTag: "email"
      },
      /*
      //group no more needed
      {
        label: 'Group',
        renderCell: (item) => item.group_name,
        sort: { sortKey: 'GROUP' },
        resize: true,
        cysrTag: "group_name"
      },
      */
      /*
      {
        label: 'Created',
        renderCell: (item) =>
        item.mu_created_at ? new Date(item.mu_created_at).toLocaleDateString('it-IT', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }) : "",
        sort: { sortKey: 'CREATED' },
        resize: true,
        cysrTag: "mu_created_at",
        cysrTagDateFormat:'it-IT'
      },
      */
      {
        label: 'Actions',
        renderCell: (item) => (
          <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '8px', width: `${actionsPixelWidth}px` }}>
            <MDButton onClick={() => handleRemoveUser(item)} style={cysrConfig().button_style} size="medium">
              <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().remove.icon.svg }} />
              Remove user
            </MDButton>
            {false &&
              <MDButton onClick={() => handleExpand(item)} style={cysrConfig().button_style} size="medium">
                {ids.includes(item.email) ?
                  <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} />
                  :
                  <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().edit.icon.svg }} />
                }
                Edit user
              </MDButton>
            }
          </div>
        ),
        pinRight: true
        // sort: { sortKey: 'RATING' }
      },
    ];
  }
  if (withStatus || contractor) {
    // pending
    COLUMNS.unshift(
      {
        label: 'Status',
        renderCell: (item) => item.invitation_date ?
          <MDBadge badgeContent="Pending" size="xs" container color="warning" />
          : item.is_active ?
            <MDBadge badgeContent="Active" size="xs" container color="success" /> :
            <MDBadge badgeContent="Inactive" size="xs" container color="error" />,
        sort: { sortKey: 'STATUS' },
        resize: false,
        cysrTag: "status"
      }
    )
  }

  // expandable
  const [ids, setIds] = useState([]);
  let autoString = "";
  let firstAuto = 1;
  COLUMNS.forEach(c => {
    if (firstAuto === 0) {
      autoString += "auto ";
    } else {
      firstAuto--;
    }
  });
  const expandedTableStyle = `
    --data-table-library_grid-template-columns: ${autoString} ${actionsPixelWidth}px!important;
    .animate {
      grid-column: 1 / -1;
      display: flex;
    }
    .animate {
        visibility: visible;
        border-bottom: 1px solid #${darkMode ? "4a4545" : "f0f2f5"};
    }
    .animate > div {
      flex: 1;
      display: flex;
    }
    
    @media (min-width: 768px){
      .pin-right {
        right: 0;
      }
    }
  `;

  const handleExpand = (item) => {
    if (ids.includes(item.email)) {
      setIds(ids.filter((email) => id !== item.email));
    } else {
      setIds(ids.concat(item.email));
    }
  };
  const removeUserConfirmed = (item) => {
    console.log(selectedItemForDialog)
    const userID = selectedItemForDialog.email;
    // /subscription/<uuid>
    // subscription/id_sub
    // console.warn(subscription)
    const subscriptionID = (subscription.item && subscription.item.id) ? subscription.item.id : subscription.subscription_uuid;
    const callUrl = `${cysrConfig().api_base_url}subscription/${subscriptionID}/user/remove/`;
    const userToDelete = {
      user_to_remove: userID
    }
    fetch(callUrl, {
      body: JSON.stringify(userToDelete),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      method: "PATCH"
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("user");
        console.log(data);
        if (data.ErrorMessage) {
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>{data.ErrorMessage}</MDTypography>
              <MDTypography variant="caption">{data.ErrorDetail}</MDTypography>
            </div>, {
            action,
            variant: 'error',
            persist: true
          })
        } else {
          handleClose()
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>User Removed</MDTypography>
              <MDTypography variant="caption">The User was successfully removed</MDTypography>
            </div>, {
            // action,
            variant: 'success'
          }
          )
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>Reloading..</MDTypography>
              <MDTypography variant="caption">The page will reload shortly</MDTypography>
            </div>, {
            // action,
            variant: 'info'
          }
          )
          window.location.reload()
        }
      })
      .catch((aerr) => {
        console.error(aerr)
        const err = aerr;
        let snackErrorTitle = "Error";
        let snackErrorDetail = "Some error happened";
        if (err && err.status) {
          snackErrorTitle = `Error ${err.status}`;
          snackErrorDetail = err.message;
        } else if (err && err.ErrorMessage) {
          snackErrorTitle = err.ErrorMessage;
          snackErrorDetail = err.ErrorDetail;
        } else if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
          snackErrorTitle = JSON.parse(err).ErrorMessage;
          snackErrorDetail = JSON.parse(err).ErrorDetail;
        } else if (JSON.parse(err) && JSON.parse(err).error) {
          // snackErrorTitle = "Error";
          snackErrorDetail = JSON.parse(err).error;
        }
        handleClose()
        enqueueSnackbar(
          <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <MDTypography variant="caption" style={{ fontWeight: "600" }}>{snackErrorTitle}</MDTypography>
            <MDTypography variant="caption">{snackErrorDetail}</MDTypography>
          </div>, {
          action,
          variant: 'error',
          persist: true
        })
      });
  }
  const removeUserContractorConfirmed = (item) => {
    console.log("user:")
    console.log(selectedItemForDialog)
    const userID = selectedItemForDialog.email;
    // /subscription/<uuid>
    // subscription/id_sub
    console.warn("contractor:")
    console.warn(contractor)
    const contractorID = (contractor.item && contractor.item.id) ? contractor.item.id : contractor.id;
    // /{version}/contractor/{contractor_req_uuid}/user/remove/
    // const callUrl = `${cysrConfig().api_base_url}contractor/${contractorID}/user/${userID}/`;
    const callUrl = `${cysrConfig().api_base_url}contractor/${contractorID}/user/remove/`;
    const userToDelete = {
      user_to_remove: userID
    }
    fetch(callUrl, {
      body: JSON.stringify(userToDelete),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      method: "PATCH"
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("user");
        console.log(data);
        if (data.ErrorMessage) {
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>{data.ErrorMessage}</MDTypography>
              <MDTypography variant="caption">{data.ErrorDetail}</MDTypography>
            </div>, {
            action,
            variant: 'error',
            persist: true
          })
        } else {
          handleClose()
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>User Removed</MDTypography>
              <MDTypography variant="caption">The User was successfully removed</MDTypography>
            </div>, {
            // action,
            variant: 'success'
          }
          )
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>Reloading..</MDTypography>
              <MDTypography variant="caption">The page will reload shortly</MDTypography>
            </div>, {
            // action,
            variant: 'info'
          }
          )
          window.location.reload()
        }
      })
      .catch((aerr) => {
        console.error(aerr)
        const err = aerr;
        let snackErrorTitle = "Error";
        let snackErrorDetail = "Some error happened";
        if (err && err.status) {
          snackErrorTitle = `Error ${err.status}`;
          snackErrorDetail = err.message;
        } else if (err && err.ErrorMessage) {
          snackErrorTitle = err.ErrorMessage;
          snackErrorDetail = err.ErrorDetail;
        } else if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
          snackErrorTitle = JSON.parse(err).ErrorMessage;
          snackErrorDetail = JSON.parse(err).ErrorDetail;
        } else if (JSON.parse(err) && JSON.parse(err).error) {
          // snackErrorTitle = "Error";
          snackErrorDetail = JSON.parse(err).error;
        }
        handleClose()
        enqueueSnackbar(
          <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <MDTypography variant="caption" style={{ fontWeight: "600" }}>{snackErrorTitle}</MDTypography>
            <MDTypography variant="caption">{snackErrorDetail}</MDTypography>
          </div>, {
          action,
          variant: 'error',
          persist: true
        })
      });
  }
  const handleRemoveUser = (item) => {
    setSelectedItemForDialog(item)
    handleClickOpen();
  };

  const ROW_OPTIONS = {
    renderAfterRow: (item) => (
      <Collapse className="animate" in={ids.includes(item.email)}>
        <div style={{ flex: '1', display: 'flex' }}>
          <div style={{ flex: '1', backgroundColor: darkMode ? "#121619" : "#edf0f2" }}>
            {
              // <MDBox style={{position: "sticky", left: 0, right: 0}} maxWidth={{xs:"calc(100vw - (48px + 64px))", xl: "calc(100vw - (282px + 48px + 58px))"}}>
            }
            <MDBox>
              <Card style={{ backgroundColor: darkMode ? "#121619" : "#edf0f2", color: darkMode ? "#7B809A" : "#7B809A", borderRadius: 0, boxShadow: "none", padding: "1em", margin: 0 }}>
                <MDBox width="100%">
                  <MDTypography variant="h6">Tech</MDTypography>
                  <MDTypography variant="caption">An user with the tech role can do many fabulous things...</MDTypography>
                  <FormControlLabel
                    control={
                      <Checkbox defaultChecked={item.user_tech} />
                    }
                    label="Tech"
                  />
                  <Divider />
                  <MDTypography variant="h6">Admin</MDTypography>
                  <MDTypography variant="caption">An user with the admin role can do many fabulous things...</MDTypography>
                  <FormControlLabel
                    control={
                      <Checkbox defaultChecked={item.user_admin} />
                    }
                    label="Admin"
                  />
                  <Divider />
                  <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '8px', width: "100%" }}>
                    <MDButton onClick={() => handleExpand(item)} style={cysrConfig().button_style} size="medium">
                      <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().edit.icon.svg }} />
                      Save edits
                    </MDButton>
                    <MDButton onClick={() => handleExpand(item)} style={cysrConfig().button_style} size="medium">
                      <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().remove.icon.svg }} />
                      Remove user
                    </MDButton>
                  </div>
                  {/*}
                  {tableDetailsRow(item.id, "Name", item.name)}
                  <Divider />
                  {tableDetailsRow(item.id, "Deadline", item.deadline, "date")}
                  <Divider />
                  {tableDetailsRow(item.id, "Type", item.generalasset_type)}
                  <Divider />
                  {tableDetailsRow(item.id, "Completed", item.isComplete.toString())}
                  {*/}
                </MDBox>
              </Card>
            </MDBox>
          </div>
        </div>

        <Dialog
          fullScreen={false}
          open={dialogOpen}
          onClose={handleClose}
          aria-labelledby="remove-user-dialog-title"
          style={{
            background: darkMode ? 'rgba(0,0,0,0.25)' : 'rgba(255, 255, 255, 0.25)',
            backdropFilter: "blur(12px)"
          }}>
          <div style={{ background: darkMode ? '#192328' : '#ffffff' }}>
            <DialogTitle id="remove-user-dialog-title">
              {"Confirm user removal?"}
            </DialogTitle>
            {contractor ?
              <DialogContent>
                <DialogContentText>
                  <MDTypography variant="body2">
                    You are about to remove {selectedItemForDialog && selectedItemForDialog.username}{selectedItemForDialog && selectedItemForDialog.email ? ` (${selectedItemForDialog.email})` : ""} from this subscriber, do you want to proceed?
                  </MDTypography>
                </DialogContentText>
              </DialogContent>
              :
              <DialogContent>
                <DialogContentText>
                  <MDTypography variant="body2">
                    You are about to remove {selectedItemForDialog && selectedItemForDialog.username}{selectedItemForDialog && selectedItemForDialog.email ? ` (${selectedItemForDialog.email})` : ""} from this subscription, sounds good?
                  </MDTypography>
                </DialogContentText>
              </DialogContent>
            }
            {contractor ?
              <DialogActions>
                <MDButton style={cysrConfig().button_style} size="medium" autoFocus onClick={handleClose}>
                  Disagree
                </MDButton>
                <MDButton style={cysrConfig().button_style} size="medium" onClick={() => removeUserContractorConfirmed(item)} autoFocus>
                  Agree
                </MDButton>
              </DialogActions>
              :
              <DialogActions>
                <MDButton style={cysrConfig().button_style} size="medium" autoFocus onClick={handleClose}>
                  Disagree
                </MDButton>
                <MDButton style={cysrConfig().button_style} size="medium" onClick={() => removeUserConfirmed(item)} autoFocus>
                  Agree
                </MDButton>
              </DialogActions>
            }
          </div>
        </Dialog>
      </Collapse>
    ),
  };

  // filter list
  tData = {
    nodes: tData.nodes.filter((item) => {
      /*
      // check filter first
      if(hideCompleted && item.isComplete){
        return false
      }
      */
      // check tags
      // da fare tags con chiave valore
      if (tableTagsActive && tableTagsActive.length > 0) {
        let trueCount = tableTagsActive.length;
        const scoreValue = item.asset_last_rating && item.asset_last_rating.rating_val ? item.asset_last_rating.rating_val : "N/A";
        let textValue = "Bad";
        if (scoreValue === "N/A") {
          textValue = "N/A";
        } else if (scoreValue < 30) {
          textValue = "Bad";
        } else if (scoreValue < 50) {
          textValue = "Poor";
        } else if (scoreValue < 70) {
          textValue = "Fair";
        } else if (scoreValue < 90) {
          textValue = "Good";
        } else if (scoreValue < 100 || scoreValue == 100) {
          textValue = "Excellent";
        }
        tableTagsActive.forEach(ta => {
          let notFound = true;
          // X:item.cysrTag s
          if (ta && typeof ta === 'string' && ta.includes(":")) {
            const tag = ta.toLowerCase().split(":");
            const tagKey = tag[0].toLowerCase();
            const tagValue = tag[1].toString().trim();
            if (tagKey === "rating" && tagValue.toLowerCase() === textValue.toLowerCase()) {
              if (notFound) {
                trueCount--;
                notFound = false;
              }
            }
            COLUMNS.forEach(col => {
              if (col.cysrTag && item[col.cysrTag] && col.label.toLowerCase() === tagKey && item[col.cysrTag.toLowerCase()] && item[col.cysrTag.toLowerCase()]) {
                // check cysrTagDateFormat
                if (col.cysrTagDateFormat) {
                  const vd = new Date(item[col.cysrTag.toLowerCase()].toString()).toLocaleDateString(col.cysrTagDateFormat, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })
                  if (vd.toString().includes(tagValue) && notFound) {
                    trueCount--;
                    notFound = false;
                  }
                } else if (item[col.cysrTag.toLowerCase()].toString().toLowerCase().includes(tagValue)) {
                  if (notFound) {
                    trueCount--;
                    notFound = false;
                  }
                }
              }
            })
          }
          // X:item.cysrTag e
          // compare (for freeSolo) s
          if (ta && ta.rating && ta.rating === textValue) {
            if (notFound) {
              trueCount--;
              notFound = false;
            }
          }
          [
            "asset_status",
            "nominative",
            "email",
            "generalasset_type",
            "generalasset_val"
          ].forEach(property => {
            if (ta && ta[property]) {
              // a default option (with [property])
              if (ta && ta[property] && item[property] && item[property].toLowerCase().includes(ta[property].toLowerCase())) {
                if (notFound) {
                  trueCount--;
                  notFound = false;
                }
              }
            } else {
              if (ta && ta.rating) {
                // avoid checking again ta with title (rating Excellent, Good...)
              } else if (ta === textValue) {
                // checking ta without title written by user ("Excellent", "Good"...)
                if (notFound) {
                  trueCount--;
                  notFound = false;
                }
              } else {
                // not a default option (with [property])
                if (ta && item[property] && item[property].toLowerCase().includes(ta.toLowerCase())) {
                  if (notFound) {
                    trueCount--;
                    notFound = false;
                  }
                }
              }
            }
          });
          // compare (for freeSolo) e
        });
        if (trueCount !== 0) {
          return false;
        }
      }
      // check search at last
      // ..
      return true;
    }
    ),
  };

  const materialTheme = getTheme(DEFAULT_OPTIONS, {
    isVirtualized: true
  });

  // to set row border bottom style:
  // border-bottom: 1px solid #${darkMode ? "4a4545" : "f0f2f5"};

  const customTheme = {
    Table: expandedTableStyle,
    BaseCell: `
      border: 0!important;
      padding: 4px;
    `,
  };
  let theme = useTheme([materialTheme, customTheme]);
  if (darkMode) {
    // provide custom theme
    theme = useTheme([materialTheme, {
      Table: expandedTableStyle,
      BaseCell: `
        color: #ffffff;
        background-color: #192328;
        border: 0!important;
        padding: 4px;
      `,
    }
    ]);
  }

  const pagination = usePagination(tData, {
    state: {
      page: 0,
      // size: 2,
      // size: tData.nodes.length,
      size: tableRowsPerPage[0],
    },
    onChange: onPaginationChange,
  });

  function onPaginationChange(action, state) {
    console.log(action, state);
  }

  const sort = useSort(
    tData,
    {
      state: {
        sortKey: 'RATING',
        reverse: false
      },
      onChange: onSortChange,
    },
    {
      sortIcon: {
        iconDefault: null,
        iconUp: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
        </svg>
        ,
        iconDown: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
        ,
      },
      sortFns: {
        USERNAME: (array) => array.sort((a, b) => ((a.first_name || a.last_name) ? `${a.first_name} ${a.last_name}` : a.username ? a.username : "").localeCompare(((b.first_name || b.last_name) ? `${b.first_name} ${b.last_name}` : b.username ? b.username : ""))),
        EMAIL: (array) => array.sort((a, b) => a.email.localeCompare(b.email)),
        STATUS: (array) => array.sort((a, b) => a.status ? a.status.localeCompare(b.status ? b.status : "") : ""),
        GROUP: (array) => array.sort((a, b) => a.group_name.localeCompare(b.group_name)),
        // STATUS: (array) => array.sort((a, b) => a.asset_status.localeCompare(b.asset_status)),
        TYPE: (array) => array.sort((a, b) => a.generalasset_type.localeCompare(b.generalasset_type)),
        RATING: (array) => array.sort(function (a, b) {
          let aR = a.asset_last_rating && a.asset_last_rating.rating_val ? a.asset_last_rating.rating_val : "N/A";
          let bR = b.asset_last_rating && b.asset_last_rating.rating_val ? b.asset_last_rating.rating_val : "N/A";
          if (!aR || aR === null || aR === undefined || aR === "" || aR === "N/A") {
            aR = 101;
          }
          if (!bR || bR === null || bR === undefined || bR === "" || bR === "N/A") {
            bR = 101;
          }
          return aR - bR
        }),
        VALUE: (array) => array.sort((a, b) => a.generalasset_val.localeCompare(b.generalasset_val)),
        CREATED: (array) => array.sort((a, b) =>
          new Date(b.mu_created_at).getTime() - new Date(a.mu_created_at).getTime()
        ),
        UPDATED: (array) => array.sort((a, b) => a.generalasset_lastcheck_datetime - b.generalasset_lastcheck_datetime),
        SOURCE: (array) => array.sort((a, b) => a.assetsrc_name.localeCompare(b.assetsrc_name)),
        // TYPE: (array) => array.sort((a, b) => a.type.localeCompare(b.type)),
        // COMPLETE: (array) => array.sort((a, b) => a.isComplete - b.isComplete),
        // TASKS: (array) => array.sort((a, b) => a.tasks || 0 - b.tasks || 0),
        // TASKS: (array) => array.sort((a, b) => (a.nodes || []).length - (b.nodes || []).length),
      },
    },
  );

  function onSortChange(action, state) {
    console.log(action, state);
  }

  const handleSetTableTags = (event, t) => {
    // console.log(t)
    setTableTagsActive(t);
    // replace url s
    let params = new URLSearchParams(window.location.search)
    params.delete('u')
    if (t != "" && t.length > 0) {
      params.append("u", Base64.encode(JSON.stringify(t)))
    }
    window.history.replaceState(null, '', '?' + params + window.location.hash)
    // replace url e
    // go to first page
    pagination.fns.onSetPage(0)
  };

  const VIRTUALIZED_OPTIONS = {
    rowHeight: (_item, _index) => {
      return 54
    }
  };

  return (
    <>
      <Stack spacing={10} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CysrTags tableTagsOptions={tableTagsOptions} tableTagsActive={tableTagsActive} handleSetTableTags={handleSetTableTags} darkMode={darkMode} />
          </Grid>
        </Grid>
      </Stack>
      <Paper style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none', height: 'auto' }}>
        {
          // <Paper style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none', height: '360px' }}>
        }
        <CompactTable
          columns={COLUMNS}
          // passando le virtualizedOptions non funziona l'expand abilitato, penso perchè imposta l'height da solo
          // virtualizedOptions={VIRTUALIZED_OPTIONS}
          data={tData}
          sort={sort}
          theme={theme}
          rowOptions={ROW_OPTIONS}
          layout={{
            isDiv: true,
            fixedHeader: true
          }}
          pagination={pagination}
          style={{ borderColor: 'transparent' }}
        // horizontalScroll={true}
        />
      </Paper>
      <Stack spacing={10}>
        <TablePagination
          component="div"
          style={{ color: darkMode ? "#ffffff" : "#000000", border: 0 }}
          count={tData.nodes.length}
          page={pagination.state.page}
          rowsPerPage={pagination.state.size}
          rowsPerPageOptions={tableRowsPerPage}
          onRowsPerPageChange={(event) =>
            pagination.fns.onSetSize(parseInt(event.target.value, 10))
          }
          onPageChange={(event, page) => pagination.fns.onSetPage(page)}
        />
      </Stack>
    </>
  );
};
export default Component;
/* eslint-disable */