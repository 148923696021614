/* eslint-disable */
// prettier-ignore
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";


// Material Dashboard 2 PRO React components
import { useMaterialUIController } from "context";
import Select, { components } from 'react-select';
import cysrConfig from "layouts/cysr/config";
import { useSnackbar } from 'notistack';

function Topics() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [topics, setTopics] = useState([])
  const [enabledTopics, setEnabledTopics] = useState([]);
  const [selectStatusLoading, setSelectStatusLoading] = useState(false);
  const [selectSeverityValue, setSelectSeverityValue] = useState({});
  function handleEnabled(ch) {
    let newArray = [];
    let found = false;
    enabledTopics.forEach(uuid => {
      if (uuid == ch.usernotificationtopic_uuid) {
        found = true;
      } else {
        newArray.push(uuid)
      }
    });
    if (found === false) {
      newArray.push(ch.usernotificationtopic_uuid)
    }
    setEnabledTopics(newArray)
  }

  // custom select
  const colourStyles = {
    placeholder: (styles) => ({
      ...styles,
      fontSize: '16px',
      color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white"
    }),
    multiValueRemove: (base) => ({
      ...base,
      backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#f8f9fa" : "#23272E",
      borderRadius: 0
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
      backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#f8f9fa" : "#23272E",
      height: "18px",
      minHeight: "18px",
      lineHeight: "1",
      fontSize: '12px',
      borderRadius: 0
    }),
    valueContainer: (base) => ({
      ...base,
      width: '100%',
      minWidth: '160px',
      display: "flex",
    }),
    indicatorsContainer: (styles) => ({ ...styles, height: "100%" }),
    indicatorSeparator: (styles) => ({ ...styles, border: "0", backgroundColor: "transparent" }),
    control: (styles, { isDisabled }) => ({
      ...styles, maxWidth: '435px', border: "0", color: isDisabled
        ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#bdc7d0" : "#666666") : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: isDisabled
          ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#EEF0F1" : "#17191c") : (localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b"), height: "32px", minHeight: "32px", minWidth: "160px"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? undefined
              : undefined,
        color: isDisabled
          ? '#ccc'
          : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
            && data.color
            : undefined,
        },
      };
    },
    menuList: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b" }),
    input: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };

  const loadingStyles = {
    valueContainer: (base) => ({
      ...base,
      width: '100%',
      display: "flex",
    }),
    indicatorsContainer: (styles) => ({ ...styles, height: "100%" }),
    indicatorSeparator: (styles) => ({ ...styles, border: "0", backgroundColor: "transparent" }),
    control: (styles, { isDisabled }) => ({
      ...styles, border: "0", color: isDisabled
        ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#bdc7d0" : "#666666") : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: isDisabled
          ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#EEF0F1" : "#17191c") : (localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b"), height: "32px", minHeight: "32px"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? undefined
              : undefined,
        color: isDisabled
          ? '#ccc'
          : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
            && data.color
            : undefined,
        },
      };
    },
    menuList: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b" }),
    input: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
    placeholder: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };
  const { Option } = components
  const CustomSelectOption = props => (
    <Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ display: "inline-flex", alignItems: "center", transform: "scale(0.75)", marginRight: "12px" }} dangerouslySetInnerHTML={{ __html: props.data.icon }} />
        <span style={{ fontSize: "14px", lineHeight: "1" }}>{props.data.label}</span>
      </div>
    </Option>
  )

  const CustomSelectValue = props => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: props.data.icon }} />
      <span style={{ fontSize: "16px", lineHeight: "1" }}>{props.data.label}</span>
    </div>
  )
  const CustomSelectValueLoading = props => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={cysrConfig().button_icon_left_style} className="spin" dangerouslySetInnerHTML={{ __html: cysrConfig().loading.icon.svg }} />
      <span style={{ fontSize: "16px", lineHeight: "1" }}>{props.data.label}</span>
    </div>
  )

  const Svg = (p) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      focusable="false"
      role="presentation"
      {...p}
    />
  );
  const DropdownIndicator = () => (
    <div style={{ color: "#0D918F", height: 20, width: 20, display: "flex", alignItems: "center" }}>
      <Svg>
        <path d="M10 13.75L3.75 7.5L4.625 6.625L10 12L15.375 6.625L16.25 7.5L10 13.75Z" fill="currentColor" />
      </Svg>
    </div>
  );

  useEffect(() => {
    const topicsUrl = `${cysrConfig().api_base_url}notification/topic/`;
    fetch(topicsUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          response.json()
            .then((responseData) => {
              console.warn("topics:");
              console.warn(responseData);
              let sv = [];
              let enableds = [];
              responseData.forEach(ch => {
                sv[ch.usernotificationtopic_uuid] = ch.usernotificationtopic_severity;
                if (ch.subscription_tech_accounts && ch.subscription_tech_accounts.length > 0) {
                  ch.subscription_tech_accounts.forEach(sch => {
                    sv[sch.usernotificationtopic_uuid] = sch.usernotificationtopic_severity;
                    if (sch.usernotificationtopic_enabled) {
                      enableds.push(sch.usernotificationtopic_uuid)
                    }
                    if (sch.subscription_tech_accounts && sch.subscription_tech_accounts.length > 0) {
                      sch.subscription_tech_accounts.forEach(xsch => {
                        sv[xsch.usernotificationtopic_uuid] = xsch.usernotificationtopic_severity;
                        if (xsch.usernotificationtopic_enabled) {
                          enableds.push(xsch.usernotificationtopic_uuid)
                        }
                      });
                    }
                  });
                }
                if (ch.usernotificationtopic_enabled) {
                  enableds.push(ch.usernotificationtopic_uuid)
                }
              });
              setEnabledTopics(enableds)
              setSelectSeverityValue(sv)
              setTopics(responseData)
            })
        } else {
          return response.json().then(err => {
            throw JSON.stringify(err)
          })
        }
      })
      .catch((err) => {
        console.error("err1:", err);
        console.error(err);
      });
  }, [])
  return (
    <Card id="topics">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Topics</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          Choose which notifications you would like to receive.
        </MDTypography>
      </MDBox>
      <MDBox pt={2} pb={3} px={3}>
        {topics && topics.sort(function (a, b) {
          // sort liek this:
          // 1) platform news
          // 2) world news
          // 3) subscriptions
          let aVal = 5;
          let bVal = 5;
          if (a.usernotificationtopic_topic === "cysr-news") {
            aVal = 1;
          } else if (a.usernotificationtopic_topic === "world-news") {
            aVal = 2;
          } else if (a.usernotificationtopic_topic.indexOf("subscription") > -1) {
            aVal = 6;
          }
          if (b.usernotificationtopic_topic === "cysr-news") {
            bVal = 1;
          } else if (b.usernotificationtopic_topic === "world-news") {
            bVal = 2;
          } else if (b.usernotificationtopic_topic.indexOf("subscription") > -1) {
            bVal = 6;
          }
          return aVal - bVal;
        }).map((ch, i) =>
          <div key={ch.usernotificationtopic_uuid}>
            {
              isASubscriptionAndThereIsAlreadyASubscription(ch, i)
                ? null
                :
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    {ch.usernotificationtopic_topic && ch.usernotificationtopic_topic.indexOf("subscription") > -1 ? "Subscriptions" : ch.usernotificationtopic_descr}
                  </MDTypography>
                  {
                    // subscriptions senza enable / disable
                    ch.usernotificationtopic_topic && ch.usernotificationtopic_topic.indexOf("subscription") > -1 ? null :
                      <MDBox
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        width={{ xs: "100%", sm: "auto" }}
                        mt={{ xs: 1, sm: 0 }}
                      >
                        <MDBox lineHeight={0} mx={2}>
                          <MDTypography variant="button" color="text">
                            {enabledTopics.includes(ch.usernotificationtopic_uuid) ? "Enabled" : "Disabled"}
                          </MDTypography>
                        </MDBox>
                        <MDBox mr={1}>
                          <Switch checked={enabledTopics.includes(ch.usernotificationtopic_uuid)} onChange={
                            function () {
                              // notification/topic/{ch.usernotificationtopic_topic}
                              const topicsPatchUrl = `${cysrConfig().api_base_url}notification/topic/${ch.usernotificationtopic_topic}/`;
                              fetch(topicsPatchUrl, {
                                body: JSON.stringify({
                                  usernotificationtopic_enabled: !(enabledTopics.includes(ch.usernotificationtopic_uuid)),
                                }),
                                headers: {
                                  Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                  "content-type": "application/json",
                                },
                                method: "PATCH",
                              })
                                .then((response) => {
                                  if (response.ok) {
                                    response.json()
                                      .then((responseData) => {
                                        handleEnabled(ch)
                                      })
                                  } else {
                                    return response.json().then(err => {
                                      throw JSON.stringify(err)
                                    })
                                  }
                                })
                                .catch((err) => {
                                  console.error("enabled err:", err);
                                  console.error(err);
                                });
                            }
                          } />
                        </MDBox>
                      </MDBox>
                  }
                </MDBox>
            }
            {
              // fare sempre vedere
              //enabledTopics.includes(ch.usernotificationtopic_uuid) &&
              true &&
              <MDBox style={{ display: "inline-flex", flexDirection: "column", gap: 8, width: "100%" }}>
                {isASubscriptionAndThereIsAlreadyASubscription(ch, i)
                  ? null
                  : <MDTypography variant="button" color="text">
                    choose which notifications to receive about {ch.usernotificationtopic_topic && ch.usernotificationtopic_topic.indexOf("subscription") > -1 ? "each subscription" : ch.usernotificationtopic_descr}:
                  </MDTypography>
                }
                {
                  /*
                  subscription_tech_accounts:
                  [
                      {
                          "usernotificationtopic_uuid": "00fce22c-4dec-4385-9567-be291ceff73c",
                          "usernotificationtopic_topic": "account=767b4f4c-3c33-4e82-92ea-4a9b40a76796",
                          "usernotificationtopic_severity": 10,
                          "usernotificationtopic_enabled": true,
                          "usernotificationtopic_user": 3,
                          "usernotificationtopic_descr": "Blue Skye",
                          "subscription_tech_accounts": null
                      }
                  ]
                  */
                }
                {(ch.usernotificationtopic_topic.indexOf("subscription") > -1 || ch.subscription_tech_accounts && ch.subscription_tech_accounts.length > 0)
                  ? <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                    { // ch.subscription_tech_accounts.map((sub) => (
                      <div key={ch.usernotificationtopic_uuid} style={{
                        background: `${darkMode ? "rgb(53 82 85)" : "#e6ebeb"}`,
                        borderRadius: "8px",
                        display: "flex",
                        flexDirection: "column",
                        gap: 12,
                        padding: "16px"
                      }}
                      >
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems={{ xs: "flex-start", sm: "center" }}
                          flexDirection={{ xs: "column", sm: "row" }}
                        >
                          <MDTypography variant="h5" fontWeight="medium">
                            {ch.usernotificationtopic_descr}
                          </MDTypography>
                          <MDBox
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                            width={{ xs: "100%", sm: "auto" }}
                            mt={{ xs: 1, sm: 0 }}
                          >
                            <MDBox lineHeight={0} mx={2}>
                              <MDTypography variant="button" color="text">
                                {enabledTopics.includes(ch.usernotificationtopic_uuid) ? "Enabled" : "Disabled"}
                              </MDTypography>
                            </MDBox>
                            <MDBox mr={1}>
                              <Switch checked={enabledTopics.includes(ch.usernotificationtopic_uuid)} onChange={
                                function () {
                                  // notification/topic/{ch.usernotificationtopic_topic}
                                  const topicsPatchUrl = `${cysrConfig().api_base_url}notification/topic/${ch.usernotificationtopic_topic}/`;
                                  fetch(topicsPatchUrl, {
                                    body: JSON.stringify({
                                      usernotificationtopic_enabled: !(enabledTopics.includes(ch.usernotificationtopic_uuid)),
                                    }),
                                    headers: {
                                      Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                      "content-type": "application/json",
                                    },
                                    method: "PATCH",
                                  })
                                    .then((response) => {
                                      if (response.ok) {
                                        response.json()
                                          .then((responseData) => {
                                            handleEnabled(ch)
                                          })
                                      } else {
                                        return response.json().then(err => {
                                          throw JSON.stringify(err)
                                        })
                                      }
                                    })
                                    .catch((err) => {
                                      console.error("enabled err:", err);
                                      console.error(err);
                                    });
                                }
                              } />
                            </MDBox>
                          </MDBox>
                        </MDBox>
                        <div>
                          <MDTypography variant="caption" color="text">
                            select a level, saved on change
                          </MDTypography>
                          <Select isDisabled={enabledTopics.includes(ch.usernotificationtopic_uuid) === false} isSearchable={false} options={cysrConfig().severityArray} defaultValue={cysrConfig().severityArray.filter((s) => s.value == selectSeverityValue[ch.usernotificationtopic_uuid])} styles={selectStatusLoading ? loadingStyles : colourStyles} onChange={(newValue) => {
                            let currentSeverityValue = selectSeverityValue;
                            currentSeverityValue[ch.usernotificationtopic_uuid] = newValue.value;
                            const topicsPatchUrl = `${cysrConfig().api_base_url}notification/topic/${ch.usernotificationtopic_topic}/`;
                            fetch(topicsPatchUrl, {
                              body: JSON.stringify({
                                usernotificationtopic_severity: newValue.value,
                              }),
                              headers: {
                                Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                "content-type": "application/json",
                              },
                              method: "PATCH",
                            })
                              .then((response) => {
                                if (response.ok) {
                                  response.json()
                                    .then((responseData) => {
                                      const newSeverityValue = currentSeverityValue;
                                      setSelectSeverityValue(newSeverityValue)
                                      enqueueSnackbar(
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                          <MDTypography variant="caption" style={{ fontWeight: "600" }}>Updated successfully</MDTypography>
                                          <MDTypography variant="caption">Notifications for this subscription have been successfully updated</MDTypography>
                                        </div>, {
                                        variant: 'success',
                                        persist: false
                                      })
                                    })
                                } else {
                                  return response.json().then(err => {
                                    throw JSON.stringify(err)
                                  })
                                }
                              })
                              .catch((err) => {
                                console.error("enabled err:", err);
                                console.error(err);

                                enqueueSnackbar(
                                  <div style={{ display: "flex", flexDirection: "column" }}>
                                    <MDTypography variant="caption" style={{ fontWeight: "600" }}>Updated failed</MDTypography>
                                    <MDTypography variant="caption">Notifications for this subscription couldn't be updated</MDTypography>
                                  </div>, {
                                  variant: 'error',
                                  persist: false
                                })
                              });
                          }}
                            components={{ DropdownIndicator, Option: CustomSelectOption, SingleValue: selectStatusLoading ? CustomSelectValueLoading : CustomSelectValue }}
                          />
                        </div>
                        {ch.subscription_tech_accounts && ch.subscription_tech_accounts.length > 0 ?
                          <MDTypography variant="button" color="text">
                            Companies with special configuration in this subscription:
                          </MDTypography>
                          :
                          <MDTypography variant="button" color="text">
                            Configure special configuration per company in this subscription in their actions menu
                          </MDTypography>
                        }
                        {ch.subscription_tech_accounts && ch.subscription_tech_accounts.length > 0 &&
                          <div style={{ background: `${darkMode ? "#26262B" : "rgba(93, 97, 107, 0.08)"}`, borderRadius: "5px", padding: 16, display: "flex", flexDirection: "column", gap: 12 }}>
                            {ch.subscription_tech_accounts && ch.subscription_tech_accounts.length > 0 && ch.subscription_tech_accounts.map((c, z) => (
                              <div key={c.usernotificationtopic_uuid} style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                                <MDBox
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems={{ xs: "flex-start", sm: "center" }}
                                  flexDirection={{ xs: "column", sm: "row" }}
                                >
                                  <MDTypography variant="h5" fontWeight="medium" style={{ fontSize: "15px" }}>
                                    {c.usernotificationtopic_descr}
                                  </MDTypography>
                                  <MDBox
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    width={{ xs: "100%", sm: "auto" }}
                                    mt={{ xs: 1, sm: 0 }}
                                  >
                                    <MDBox lineHeight={0} mx={2}>
                                      <MDTypography variant="button" color="text">
                                        {enabledTopics.includes(c.usernotificationtopic_uuid) ? "Enabled" : "Disabled"}
                                      </MDTypography>
                                    </MDBox>
                                    <MDBox mr={1}>
                                      <Switch checked={enabledTopics.includes(c.usernotificationtopic_uuid)} onChange={
                                        function () {
                                          // notification/topic/{c.usernotificationtopic_topic}
                                          const topicsPatchUrl = `${cysrConfig().api_base_url}notification/topic/${c.usernotificationtopic_topic}/`;
                                          fetch(topicsPatchUrl, {
                                            body: JSON.stringify({
                                              usernotificationtopic_enabled: !(enabledTopics.includes(c.usernotificationtopic_uuid)),
                                            }),
                                            headers: {
                                              Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                              "content-type": "application/json",
                                            },
                                            method: "PATCH",
                                          })
                                            .then((response) => {
                                              if (response.ok) {
                                                response.json()
                                                  .then((responseData) => {
                                                    handleEnabled(c)
                                                  })
                                              } else {
                                                return response.json().then(err => {
                                                  throw JSON.stringify(err)
                                                })
                                              }
                                            })
                                            .catch((err) => {
                                              console.error("enabled err:", err);
                                              console.error(err);
                                            });
                                        }
                                      } />
                                    </MDBox>
                                  </MDBox>
                                </MDBox>
                                <div style={{ display: "flex", flexWrap: "wrap", gap: 8, justifyContent: "space-between" }}>
                                  <div style={{ flex: 1 }}>
                                    <MDTypography variant="caption" color="text">
                                      select a level, saved on change
                                    </MDTypography>
                                    <Select isDisabled={enabledTopics.includes(c.usernotificationtopic_uuid) === false} isSearchable={false} options={cysrConfig().severityArray} defaultValue={cysrConfig().severityArray.filter((s) => s.value == selectSeverityValue[c.usernotificationtopic_uuid])} styles={selectStatusLoading ? loadingStyles : colourStyles} onChange={(newValue) => {
                                      let currentSeverityValue = selectSeverityValue;
                                      currentSeverityValue[c.usernotificationtopic_uuid] = newValue.value;
                                      const topicsPatchUrl = `${cysrConfig().api_base_url}notification/topic/${c.usernotificationtopic_topic}/`;
                                      fetch(topicsPatchUrl, {
                                        body: JSON.stringify({
                                          usernotificationtopic_severity: newValue.value,
                                        }),
                                        headers: {
                                          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                          "content-type": "application/json",
                                        },
                                        method: "PATCH",
                                      })
                                        .then((response) => {
                                          if (response.ok) {
                                            response.json()
                                              .then((responseData) => {
                                                const newSeverityValue = currentSeverityValue;
                                                setSelectSeverityValue(newSeverityValue)
                                                enqueueSnackbar(
                                                  <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <MDTypography variant="caption" style={{ fontWeight: "600" }}>Updated successfully</MDTypography>
                                                    <MDTypography variant="caption">Notifications for this company have been successfully updated</MDTypography>
                                                  </div>, {
                                                  variant: 'success',
                                                  persist: false
                                                })
                                              })
                                          } else {
                                            return response.json().then(err => {
                                              throw JSON.stringify(err)
                                            })
                                          }
                                        })
                                        .catch((err) => {
                                          console.error("enabled err:", err);
                                          console.error(err);
                                          enqueueSnackbar(
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                              <MDTypography variant="caption" style={{ fontWeight: "600" }}>Updated failed</MDTypography>
                                              <MDTypography variant="caption">Notifications for this company couldn't be updated</MDTypography>
                                            </div>, {
                                            variant: 'error',
                                            persist: false
                                          })
                                        });
                                    }}
                                      components={{ DropdownIndicator, Option: CustomSelectOption, SingleValue: selectStatusLoading ? CustomSelectValueLoading : CustomSelectValue }}
                                    />
                                  </div>
                                  <MDButton style={cysrConfig().button_style} size="medium" onClick={() => {
                                    const topicsPatchUrl = `${cysrConfig().api_base_url}notification/topic/${c.usernotificationtopic_topic}/`;
                                    fetch(topicsPatchUrl, {
                                      /*
                                      body: JSON.stringify({
                                        usernotificationtopic_enabled: ch.usernotificationtopic_enabled,
                                        usernotificationtopic_severity: ch.usernotificationtopic_severity
                                      }),
                                      */
                                      headers: {
                                        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                        "content-type": "application/json",
                                      },
                                      method: "DELETE",
                                    })
                                      .then((response) => {
                                        if (response.ok) {
                                          response.json()
                                            .then((responseData) => {
                                              window.location.reload()
                                            })
                                        } else {
                                          return response.json().then(err => {
                                            throw JSON.stringify(err)
                                          })
                                        }
                                      })
                                      .catch((err) => {
                                        console.error("enabled err:", err);
                                        console.error(err);
                                      });
                                  }}>
                                    <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().exit.icon.svg }} />
                                    Reset to default
                                  </MDButton>
                                </div>
                                {(z < ch.subscription_tech_accounts.length - 1) && <Divider />}
                              </div>
                            ))}
                          </div>
                        }
                      </div>
                      // ))
                    }
                  </div>
                  : <>
                    <MDTypography variant="caption" color="text">
                      select a level, saved on change
                    </MDTypography>
                    <Select isDisabled={enabledTopics.includes(ch.usernotificationtopic_uuid) === false} isSearchable={false} options={cysrConfig().severityArray} defaultValue={cysrConfig().severityArray.filter((s) => s.value == selectSeverityValue[ch.usernotificationtopic_uuid])} styles={selectStatusLoading ? loadingStyles : colourStyles} onChange={(newValue) => {
                      let currentSeverityValue = selectSeverityValue;
                      currentSeverityValue[ch.usernotificationtopic_uuid] = newValue.value;
                      const topicsPatchUrl = `${cysrConfig().api_base_url}notification/topic/${ch.usernotificationtopic_topic}/`;
                      fetch(topicsPatchUrl, {
                        body: JSON.stringify({
                          usernotificationtopic_severity: newValue.value,
                        }),
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                          "content-type": "application/json",
                        },
                        method: "PATCH",
                      })
                        .then((response) => {
                          if (response.ok) {
                            response.json()
                              .then((responseData) => {
                                const newSeverityValue = currentSeverityValue;
                                setSelectSeverityValue(newSeverityValue)
                                enqueueSnackbar(
                                  <div style={{ display: "flex", flexDirection: "column" }}>
                                    <MDTypography variant="caption" style={{ fontWeight: "600" }}>Updated successfully</MDTypography>
                                    <MDTypography variant="caption">Notifications for this topic have been successfully updated</MDTypography>
                                  </div>, {
                                  variant: 'success',
                                  persist: false
                                })
                              })
                          } else {
                            return response.json().then(err => {
                              throw JSON.stringify(err)
                            })
                          }
                        })
                        .catch((err) => {
                          console.error("enabled err:", err);
                          console.error(err);
                          enqueueSnackbar(
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <MDTypography variant="caption" style={{ fontWeight: "600" }}>Updated failed</MDTypography>
                              <MDTypography variant="caption">Notifications for this topic couldn't be updated</MDTypography>
                            </div>, {
                            variant: 'error',
                            persist: false
                          })
                        });
                    }}
                      components={{ DropdownIndicator, Option: CustomSelectOption, SingleValue: selectStatusLoading ? CustomSelectValueLoading : CustomSelectValue }}
                    />
                  </>
                }
              </MDBox>
            }
            {false &&
              <MDBox pl={6} pt={2} lineHeight={1}>
                <MDTypography variant="button" color="text">
                  You haven&apos;t added your Slack yet or you aren&apos;t authorized. Please add our
                  Slack Bot to your account by clicking on here. When you&apos;ve added the bot, send your
                  verification code that you have received.
                </MDTypography>
                <MDBox
                  bgColor={darkMode ? "grey-900" : "grey-100"}
                  borderRadius="lg"
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  my={3}
                  py={1}
                  pl={{ xs: 1, sm: 2 }}
                  pr={1}
                >
                  <MDTypography variant="button" fontWeight="medium" color="text">
                    Verification Code
                  </MDTypography>
                  <MDBox width={{ xs: "100%", sm: "25%", md: "15%" }} mt={{ xs: 1, sm: 0 }}>
                    <Tooltip title="Copy" placement="top">
                      <MDInput size="small" value="1172913" />
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <MDBox
                  bgColor={darkMode ? "grey-900" : "grey-100"}
                  borderRadius="lg"
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  my={3}
                  py={1}
                  pl={{ xs: 1, sm: 2 }}
                  pr={1}
                >
                  <MDTypography variant="button" fontWeight="medium" color="text">
                    Connected account
                  </MDTypography>
                  <MDBox
                    display="flex"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    <MDBox mr={2} mb={{ xs: 1, sm: 0 }} lineHeight={0}>
                      <MDTypography variant="button" fontWeight="medium">
                        hello@creative-tim.com
                      </MDTypography>
                    </MDBox>
                    <MDButton variant="gradient" color="dark" size="small">
                      delete
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            }
            {(i < topics.length - 1) && <Divider />}
          </div>
        )}
      </MDBox>
    </Card>
  );

  function isASubscriptionAndThereIsAlreadyASubscription(ch, i) {
    return ch.usernotificationtopic_topic && ch.usernotificationtopic_topic.indexOf("subscription") > -1 && topics.sort(function (a, b) {
      // sort liek this:
      // 1) platform news
      // 2) world news
      // 3) subscriptions
      let aVal = 5;
      let bVal = 5;
      if (a.usernotificationtopic_topic === "cysr-news") {
        aVal = 1;
      } else if (a.usernotificationtopic_topic === "world-news") {
        aVal = 2;
      } else if (a.usernotificationtopic_topic.indexOf("subscription") > -1) {
        aVal = 6;
      }
      if (b.usernotificationtopic_topic === "cysr-news") {
        bVal = 1;
      } else if (b.usernotificationtopic_topic === "world-news") {
        bVal = 2;
      } else if (b.usernotificationtopic_topic.indexOf("subscription") > -1) {
        bVal = 6;
      }
      return aVal - bVal;
    })[i - 1].usernotificationtopic_topic.indexOf("subscription") > -1;
  }
}

export default Topics;

/* eslint-disable */
// prettier-ignore