/* eslint-disable */
// prettier-ignore
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Base64 } from 'js-base64';
import React, { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import DataTable from "layouts/cysr/components/DataTable";
import CysrTableChecks from "layouts/cysr/components/CysrTable/checks";

import { useLocation } from "react-router-dom";
// Data
import ScoreItem from "layouts/cysr/components/ScoreItem";
import RatingData from "layouts/cysr/components/ratingData";
import relatedData from "layouts/cysr/components/relatedData";
import AssetTypeTypologyDetail from "layouts/cysr/asset/AssetTypeTypologyDetail";

import InfoButton from "layouts/cysr/components/infoButton";
// import checksData from "layouts/cysr/components/assetChecksDataTableData";
// import checksData from "layouts/cysr/components/lastchecksDataTableData";
// new
// import checksData from "layouts/cysr/components/checksData";
// import BubbleChartLabel from "layouts/cysr/asset/BubbleChartLabel";
// import BubbleChartValue from "layouts/cysr/asset/BubbleChartValue";

import cysrConfig from "layouts/cysr/config";

import { ImportantMessage } from "layouts/cysr/components/ImportantMessage";
/* eslint-disable */
// import { BubbleChart } from "@carbon/charts-react";
import { LineChart } from "@carbon/charts-react";
import(`@carbon/charts/styles-${localStorage.getItem("cysr_dark_mode") && localStorage.getItem("cysr_dark_mode") === "false" ? "g10" : "g90"}.css`);
// Or
// import "@carbon/charts/styles/styles.scss";

// IBM Plex should either be imported in your project by using Carbon
// or consumed manually through an import
// import "./plex-and-carbon-components.css";
import { Timeline } from "layouts/cysr/components/timeline";
import { format } from "date-fns";
import CysrTableIssues from "layouts/cysr/components/CysrTable/issues";

import { getCompany, getCompanies, updateCompanies } from "db";

// ms
const App = function app() {
  // filter test s
  const params = new URLSearchParams(window.location.search);
  let activeTags = [];
  let activeTagsIssues = [];
  let activeTagsCves = [];
  try {
    activeTags = params.get('t') && (decodeURI(params.get('t'))) && Base64.decode(decodeURI(params.get('t'))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('t')))) : [];
    activeTagsIssues = params.get('ti') && (decodeURI(params.get('ti'))) && Base64.decode(decodeURI(params.get('ti'))) && JSON.parse(Base64.decode(decodeURI(params.get('ti')))) && JSON.parse(Base64.decode(decodeURI(params.get('ti')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('ti')))) : [];
    activeTagsCves = params.get('tv') && (decodeURI(params.get('tv'))) && Base64.decode(decodeURI(params.get('tv'))) && JSON.parse(Base64.decode(decodeURI(params.get('tv')))) && JSON.parse(Base64.decode(decodeURI(params.get('tv')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('tv')))) : [];
  } catch (error) {
    console.error("Error in setting active tags:")
    console.error(error)
  }
  // filter test e
  const location = useLocation();
  const [expandedTable, setExpandedTableOpen] = useState(false);
  const [features, setFeatures] = useState(null);
  const [views, setViews] = useState([]);
  let historyState = {};
  // get hashtag
  let assetUUID = 0;
  // extract id
  if (window.location.hash !== undefined) {
    assetUUID = window.location.hash.replace("#", "");
  }

  async function isThisCompanyWithCTIEnabled() {
    const companyID = localStorage.getItem("cysr_useraccountrole_selected");
    // const companiesList = localStorage.getItem("cysr_useraccountroles")
    const companiesList = await getCompanies()
    try {
      const cFound = JSON.parse(companiesList).find((x) => (
        x.account_uuid === companyID
      ))
      if (cFound) {
        return cFound.modules.indexOf("CTI") > -1
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  }

  const assetCallUrl = `${cysrConfig().api_base_url}dashboard/asset/${assetUUID}/`;
  if (assetUUID && assetUUID !== "") {
    useEffect(() => {
      fetch(`${cysrConfig().api_base_url}dashboard/asset/${assetUUID}/issues/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
      })
        .then((isResponse) => isResponse.json())
        .then((issuesData) => {

      fetch(`${cysrConfig().api_base_url}dashboard/asset/${assetUUID}/cves/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
      })
        .then((cvesResponse) => cvesResponse.json())
        .then((cvesData) => {

          const timelineUrl = `${cysrConfig().api_base_url}asset/${assetUUID}/rating/history/`;
          fetch(timelineUrl, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
            },
          })
            .then((response) => response.json())
            .then((timeLineData) => {
              const tData = [];
              if (Array.isArray(timeLineData) === false) {
                timeLineData = [];
              }
              timeLineData.forEach(t => {
                // "date": "2020-12-10T21:00:00.000Z",
                // "value": 10
                tData.push({
                  date: t.mu_created_at,
                  value: parseInt(t.rating_val, 10)
                });
              });
              historyState = {
                data: {
                  "labels": [
                    "Rating score"
                  ],
                  "datasets": [
                    {
                      "label": "Rating",
                      "data": tData
                    }
                  ]
                },
                options: {
                  // "title": "Line (time series) - Time interval hourly with custom ticks formats ('MMM d, HH:mm' and 'HH:mm')",
                  "title": false,
                  "axes": {
                    "left": {},
                    "bottom": {
                      "scaleType": "time"
                    }
                  },
                  "timeScale": {
                    "timeIntervalFormats": {
                      "hourly": {
                        "primary": "MMM d, HH:mm",
                        "secondary": "HH:mm"
                      }
                    }
                  },
                  "legend": {
                    "enabled": false
                  },
                  "height": "100%",
                  "toolbar": { "enabled": false },
                }
              };
              fetch(assetCallUrl, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                },
              })
                .then((response) => response.json())
                .then((responseAssetData) => {
                  // here check if it is a manual domain to call cti/aID/asset api instead s
                  if (responseAssetData &&
                    responseAssetData.generalasset &&
                    responseAssetData.generalasset.generalasset_type &&
                    responseAssetData.generalasset.generalasset_type === "domain" &&
                    responseAssetData.assetsrc &&
                    responseAssetData.assetsrc.assetsrc_name === "manual"
                    && isThisCompanyWithCTIEnabled()
                  ) {
                    // call cti asset s
                    const assetCTICallUrl = `${cysrConfig().api_base_url}cti/asset/${assetUUID}/`;
                    fetch(assetCTICallUrl, {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                      },
                    })
                      .then((ctiAssetResponse) => ctiAssetResponse.json())
                      .then((ctiAssetResponseData) => {
                        // cti timeline s
                        let ctiHistoryState = null;
                        if (ctiAssetResponseData && ctiAssetResponseData.rating_history) {
                          const ctiData = [];
                          Object.entries(ctiAssetResponseData.rating_history).forEach(t => {
                            ctiData.push({
                              date: format(new Date(t[0]), "yyyy-MM-dd"),
                              value: parseInt(t[1], 10)
                            });
                          });
                          ctiHistoryState = {
                            data: {
                              "labels": [
                                "CTI Rating score"
                              ],
                              "datasets": [
                                {
                                  "label": "CTI Rating",
                                  "data": ctiData
                                }
                              ]
                            },
                            options: {
                              // "title": "Line (time series) - Time interval hourly with custom ticks formats ('MMM d, HH:mm' and 'HH:mm')",
                              "title": false,
                              "axes": {
                                "left": {},
                                "bottom": {
                                  "scaleType": "time"
                                }
                              },
                              "timeScale": {
                                "timeIntervalFormats": {
                                  "hourly": {
                                    "primary": "MMM d, HH:mm",
                                    "secondary": "HH:mm"
                                  }
                                }
                              },
                              "legend": {
                                "enabled": false
                              },
                              "height": "100%",
                              "toolbar": { "enabled": false },
                            }
                          }
                        }
                        // cti timeline e
                        // call cti asset e
                        setViews({
                          issues: issuesData?.issues ? Object.values(issuesData.issues) : [],
                          cves: cvesData?.issues ? Object.values(cvesData.issues) : [],
                          // graph: graphResponse,
                          asset: responseAssetData,
                          ctiAsset: ctiAssetResponseData,
                          ctiAssetTimeline: ctiHistoryState,
                          timeline: historyState,
                          // ratingCurrent: responseRatingCurrentData,
                          // from_to
                          // to_from
                          ratingCurrent: responseAssetData.rating,
                          // related: responseRelatedCurrentData,
                          related: {
                            // from: responseAssetData.to_from,
                            from: responseAssetData.asset_from,
                            // to: responseAssetData.from_to,
                            to: responseAssetData.asset_to,
                          },
                          // ratingdetails: checksResponse.ratingdetails,
                          hideGoToAssetChecks: true,
                          highlights: responseAssetData.highlights,
                          cysr_tags_active: activeTags,
                          cysr_tags_active_issues: activeTagsIssues,
                          cysr_tags_active_cves: activeTagsCves,
                        });

                      })
                      .catch((err) => console.error(err));
                  } else {
                    // here check if it is a manual domain to call cti/aID/asset api instead e
                    fetch(`${cysrConfig().api_base_url}assettype/`, {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                      },
                    })
                      .then((graphRes) => {
                        graphRes.json();
                      })
                      .then((graphResponse) => {
                        // checks
                        // /dashboard/asset/{asset_req_uuid}/checks/
                        fetch(`${cysrConfig().api_base_url}dashboard/asset/${assetUUID}/checks/`, {
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                          },
                        }).then((checksRes) => checksRes.json())
                          .then((checksResponse) => {
                            /*
                            let resRatingResult = [];
                            if (
                              responseAssetData &&
                              responseAssetData.rating &&
                              responseAssetData.rating[0] &&
                              responseAssetData.rating[0].rating_result
                            ) {
                              resRatingResult = responseAssetData.rating[0].rating_result;
                            }
                            */
                            setViews({
                              issues: issuesData?.issues ? Object.values(issuesData.issues) : [],
                              cves: cvesData?.issues ? Object.values(cvesData.issues) : [],
                              graph: graphResponse,
                              asset: responseAssetData,
                              timeline: historyState,
                              // ratingCurrent: responseRatingCurrentData,
                              // from_to
                              // to_from
                              ratingCurrent: responseAssetData.rating,
                              // related: responseRelatedCurrentData,
                              related: {
                                // from: responseAssetData.to_from,
                                from: responseAssetData.asset_from,
                                // to: responseAssetData.from_to,
                                to: responseAssetData.asset_to,
                              },
                              ratingdetails: checksResponse.ratingdetails,
                              hideGoToAssetChecks: true,
                              highlights: responseAssetData.highlights,
                              cysr_tags_active: activeTags,
                              cysr_tags_active_issues: activeTagsIssues,
                              cysr_tags_active_cves: activeTagsCves,
                            });
                          })
                          .catch((cherr) => console.error(cherr));
                      })
                      .catch((gerr) => console.error(gerr));
                  }
                })
                .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err));
        })
        .catch((cverr) => console.error(cverr))
      })
        .catch((iserr) => console.error(iserr));

    }, [location]);
  } else {
    useEffect(() => {
      setViews();
    });
  }
  return (
    <DashboardLayout>
      <DashboardNavbar cysr_company features={features} setFeatures={setFeatures} />
      <Grid container spacing={3}>
        {
          (views &&
            views.asset &&
            views.asset.generalasset &&
            views.asset.generalasset.generalasset_type &&
            views.asset.generalasset.generalasset_type === "domain" &&
            views.asset.assetsrc &&
            views.asset.assetsrc.assetsrc_name === "manual" &&
            isThisCompanyWithCTIEnabled()) ? (
            <Grid item container spacing={3} alignItems="center" xs={12}>
              <Grid item xs={12}>
                <div style={{ paddingTop: "24px", position: "relative" }}>
                  <InfoButton cysr_info={cysrConfig().i("asset", "header", "help")} cysr_info_link={cysrConfig().i("asset", "header", "help_link")} />
                </div>
                <MDTypography fontWeight="medium" style={{ lineHeight: "1", display: "block", marginBottom: "0", marginTop: "0", fontSize: "24px", color: localStorage.getItem("cysr_dark_mode") === "false" ? "#354767" : "#AEC2E5" }}>
                  {views.asset.generalasset.generalasset_type}:
                  <span style={{ fontWeight: "bold", display: "inline-block", fontSize: "32px", marginLeft: "8px", color: localStorage.getItem("cysr_dark_mode") === "false" ? "#354767" : "#AEC2E5" }}>
                    {views.asset.generalasset.generalasset_val}
                  </span>
                </MDTypography>
              </Grid>
              <Grid item container spacing={3}>
                {(views && views.ctiAsset) && (
                  <Grid item xs={12} lg={3}>
                    <Card>
                      <InfoButton cysr_info={cysrConfig().i("asset", "score", "help")} cysr_info_link={cysrConfig().i("asset", "score", "help_link")} />
                      <MDBox py={5} px={3} fullWidth>
                        {cysrConfig().i("asset", "score", "title") && (
                          <MDTypography variant="h5">{cysrConfig().i("asset", "score", "title")}</MDTypography>
                        )}
                        {cysrConfig().i("asset", "score", "sub_title") && (
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {cysrConfig().i("asset", "score", "sub_title")}
                          </MDTypography>
                        )}
                        <MDBox mt={1.5} p={1}>
                          {views.ctiAsset && views.ctiAsset.rating_last && <ScoreItem scoreValue={views.ctiAsset.rating_last} textual={true} item_style="big" />}
                        </MDBox>
                        <MDBox p={3}>
                          <Grid container spacing={3}>
                            <Grid item my="auto" ml="auto">
                              <MDTypography variant="button" color="text" fontWeight="regular">
                                <strong>updated</strong> on <strong>{
                                  (views.ctiAsset && views.ctiAsset.last_update_date) ? format
                                    (
                                      new Date(views.ctiAsset.last_update_date),
                                      process.env.NEXT_PUBLIC_DATE_FORMAT ? process.env.NEXT_PUBLIC_DATE_FORMAT : "dd/MM/yyyy"
                                    ) : process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE

                                }</strong>
                              </MDTypography>
                            </Grid>

                          </Grid>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Grid>
                )}

                <Grid item xs={12} lg={9}>
                  <Card sx={{ height: "100%" }}>
                    <InfoButton cysr_info={cysrConfig().i("asset", "progress", "help")} cysr_info_link={cysrConfig().i("asset", "progress", "help_link")} />
                    <MDBox py={5} px={3} fullWidth>
                      {cysrConfig().i("asset", "progress", "title") && (
                        <MDTypography variant="h5">{cysrConfig().i("asset", "progress", "title")}</MDTypography>
                      )}
                      {cysrConfig().i("asset", "progress", "sub_title") && (
                        <MDTypography variant="button" color="text" fontWeight="regular">
                          {cysrConfig().i("asset", "progress", "sub_title")}
                        </MDTypography>
                      )}
                      <MDBox>
                        {views && views.ctiAssetTimeline && views.ctiAssetTimeline.data ? (
                          <>
                            {
                              false &&
                              <MDBox style={{ paddingTop: "1em", height: "129px" }}>
                                <LineChart
                                  data={views.ctiAssetTimeline.data}
                                  options={views.ctiAssetTimeline.options}
                                />
                              </MDBox>
                            }
                            <div style={{
                              /*
                              overflowX: "auto",
                              // so it start at the end of overflow:
                              display: "flex",
                              flexDirection: "row-reverse"
                              */
                              width: "100%",
                            }}>
                              <Timeline
                                // data={company.assets.graphs.timeline} 
                                data={views.ctiAssetTimeline.data.datasets[0].data}
                                maxHeight={"129px"}
                                minHeight={"unset"}
                              />
                            </div>
                          </>
                        ) : (
                          <MDBox style={{ paddingTop: "1em", height: "129px" }}>
                            <MDTypography variant="button" color="text">
                              There is no timeline available for this asset yet.
                            </MDTypography>
                          </MDBox>
                        )}
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <a href="cti" style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign: "center",
                    display: "block",
                    width: "100%"
                  }}>
                    <ImportantMessage
                      button={"Go to CTI module"}
                      className={`text-left w-full border pl-11 pr-4 py-3 rounded border-cyan-500/50 bg-cyan-500/5`}
                      title={
                        "Explore Cyber Threats details in the CTI module."
                      }
                      description="The CTI module provides comprehensive cybersecurity threat intelligence and allows management of individual threats, influencing company's rating."
                    />
                  </a>

                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <InfoButton cysr_info={cysrConfig().i("asset", "related", "help")} cysr_info_link={cysrConfig().i("asset", "related", "help_link")} />
                  <MDBox py={5} px={3} fullWidth>
                    {relatedData(views)}
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          )
            : (<>
              <Grid item xs={12}>
                <div style={{ paddingTop: "24px", position: "relative" }}>
                  <InfoButton cysr_info={cysrConfig().i("asset", "header", "help")} cysr_info_link={cysrConfig().i("asset", "header", "help_link")} />
                </div>
                <RatingData views={views} features={features} />
              </Grid>
              <Grid item container spacing={3}>
                <Grid item xs={12} lg={3}>
                  <Card>
                    <InfoButton cysr_info={cysrConfig().i("asset", "score", "help")} cysr_info_link={cysrConfig().i("asset", "score", "help_link")} />
                    <MDBox py={5} px={3} fullWidth>
                      {cysrConfig().i("asset", "score", "title") && (
                        <MDTypography variant="h5">{cysrConfig().i("asset", "score", "title")}</MDTypography>
                      )}
                      {cysrConfig().i("asset", "score", "sub_title") && (
                        <MDTypography variant="button" color="text" fontWeight="regular">
                          {cysrConfig().i("asset", "score", "sub_title")}
                        </MDTypography>
                      )}
                      <MDBox mt={1.5} p={1}>
                        {views && views.ratingCurrent && <ScoreItem circle scoreValue={views.ratingCurrent} textual={true} item_style="big" />}
                      </MDBox>
                      <MDBox p={3}>
                        <Grid container spacing={3}>
                          <Grid item my="auto" ml="auto">
                            <MDTypography variant="button" color="text" fontWeight="regular">
                              <strong>created</strong> on <strong>{views && views.asset && views.asset.generalasset && views.asset.generalasset.mu_created_at && new Date(views.asset.generalasset.mu_created_at).toLocaleDateString("it-IT")}</strong>
                            </MDTypography>
                            <br />
                            <MDTypography variant="button" color="text" fontWeight="regular">
                              <strong>updated</strong> on <strong>{views && views.asset && views.asset.generalasset && views.asset.generalasset.generalasset_lastcheck_datetime && new Date(views.asset.generalasset.generalasset_lastcheck_datetime).toLocaleDateString("it-IT")}</strong>
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12} lg={9}>
                  <Card>
                    <InfoButton cysr_info={cysrConfig().i("asset", "progress", "help")} cysr_info_link={cysrConfig().i("asset", "progress", "help_link")} />
                    <MDBox py={5} px={3} fullWidth>
                      {cysrConfig().i("asset", "progress", "title") && (
                        <MDTypography variant="h5">{cysrConfig().i("asset", "progress", "title")}</MDTypography>
                      )}
                      {cysrConfig().i("asset", "progress", "sub_title") && (
                        <MDTypography variant="button" color="text" fontWeight="regular">
                          {cysrConfig().i("asset", "progress", "sub_title")}
                        </MDTypography>
                      )}
                      <MDBox p={3}>
                        {views && views.timeline && views.timeline.data ? (
                          <>
                            {
                              false &&
                              <MDBox style={{ paddingTop: "1em", height: "129px" }}>
                                <LineChart
                                  data={views.timeline.data}
                                  options={views.timeline.options}
                                />
                              </MDBox>
                            }
                            <div style={{
                              /*
                              overflowX: "auto",
                              // so it start at the end of overflow:
                              display: "flex",
                              flexDirection: "row-reverse"
                              */
                              width: "100%",
                            }}>
                              <Timeline
                                // data={company.assets.graphs.timeline} 
                                data={views.timeline.data.datasets[0].data}
                                maxHeight={"129px"}
                                minHeight={"unset"}
                              />
                            </div>
                          </>
                        ) : (
                          <MDBox style={{ paddingTop: "1em", height: "129px" }}>
                            <MDTypography variant="button" color="text">
                              There is no timeline available for this asset yet.
                            </MDTypography>
                          </MDBox>
                        )}
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <InfoButton cysr_info={cysrConfig().i("asset", "related", "help")} cysr_info_link={cysrConfig().i("asset", "related", "help_link")} />
                  <MDBox py={5} px={3} fullWidth>
                    {relatedData(views)}
                  </MDBox>
                </Card>
              </Grid>
              {views.asset && views.asset.generalasset && views.asset.generalasset.generalasset_type && (
                <AssetTypeTypologyDetail asset={views.asset} />
              )}
              {
                // issues s
              }
              {views && views.issues &&
                <Grid item xs={12}>
                  <Card>
                      <MDBox py={5} px={3} fullWidth>
                      <MDTypography mb={2} variant="h5">Issues</MDTypography>
                        {views.issues && views.issues.length > 0 ?
                        <CysrTableIssues data={
                          {
                            isAsset: true,
                            search: params.get('si') ? params.get('si') : "",
                            hideCompleted: params.get('hci') ? true : false,
                            tableTagsOptions: [],
                            nodes: views.issues,
                            tableTagsActive: views.cysr_tags_active_issues,
                          }
                        }
                        />:<MDTypography variant="button" color="text">No Issues found.</MDTypography>}
                    </MDBox>
                  </Card>
                </Grid>
              }
              {views && views.cves &&
                <Grid item xs={12}>
                  <Card>
                      <MDBox py={5} px={3} fullWidth>
                      <MDTypography mb={2} variant="h5">Vulnerabilities</MDTypography>
                      {views.cves && views.cves.length > 0 ?
                        <CysrTableIssues data={
                          {
                            isVulnerabilities: true,
                            isAsset: true,
                            search: params.get('sv') ? params.get('sv') : "",
                            hideCompleted: params.get('hcv') ? true : false,
                            tableTagsOptions: [],
                            nodes: views.cves,
                            tableTagsActive: views.cysr_tags_active_cves,
                          }
                        }
                        />:<MDTypography variant="button" color="text">
                          No Vulnerabilities found.
                        </MDTypography>}
                    </MDBox>
                  </Card>
                </Grid>
              }
              {
                // issues e
              }
              {
                (views &&
                  views.asset &&
                  views.asset.generalasset &&
                  views.asset.generalasset.generalasset_type &&
                  views.asset.generalasset.generalasset_type === "domain" &&
                  views.asset.assetsrc &&
                  views.asset.assetsrc.assetsrc_name === "manual"
                  // && isThisCompanyWithCTIEnabled() === false
                ) ? null // do not add checks to manual domain (WITHOUT CTI because with CTI already not going here)
                  : (
                    <Grid item xs={12}>
                      <Card>
                        <MDBox py={5} px={3} fullWidth>
                          <MDTypography mb={2} variant="h5">Checks</MDTypography>
                          <InfoButton cysr_info={cysrConfig().i("asset", "checks", "help")} cysr_info_link={cysrConfig().i("asset", "checks", "help_link")} />
                          {views && views.ratingdetails && views.ratingdetails.length > 0 ? (
                            <>
                              <CysrTableChecks data={
                                {
                                  withoutAssetLink: true,
                                  search: params.get('s') ? params.get('s') : "",
                                  hideCompleted: params.get('hc') ? true : false,
                                  tableTagsOptions: [],
                                  nodes: views.ratingdetails,
                                  tableTagsActive: views.cysr_tags_active,
                                }
                              }
                              />
                              {
                                // <DataTable  entriesPerPage={{ defaultValue: 50, entries: [25, 50, 100, 200, 400] }} table={checksData(views)} canSearch />
                              }
                            </>
                          ) : (
                            <MDTypography variant="button" color="text">
                              There are no checks related to this asset yet.
                            </MDTypography>
                          )}
                        </MDBox>
                      </Card>
                    </Grid>
                  )
              }
            </>
            )
        }
      </Grid>
    </DashboardLayout >
  );
};
export default App;
// me
/*
function DataTables() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Datatable Search
            </MDTypography>
            <MDTypography variant="button" color="text">
              A lightweight, extendable, dependency-free javascript HTML table plugin.
            </MDTypography>
          </MDBox>
          <DataTable table={dataTableData} canSearch />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DataTables;
*/
/* eslint-disable */
