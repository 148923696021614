/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import ScoreItem from "layouts/cysr/components/ScoreItem";
import cysrConfig from "layouts/cysr/config";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";
import React, { useState, useEffect, useRef } from 'react';

function DefaultStatisticsCard({ title, count, percentage, warning = 0, ratings = false }) {
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current) setWidth(elementRef.current.offsetWidth);
  }, [elementRef.current?.offsetWidth]);

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  if (ratings) {
    let temp1 = {};
    try {
      // se array (old)
      ratings.forEach(r => {
        if (r.rating_val && r.group && r.group == title.toLowerCase()) {
          const scoreValue = r.rating_val;
          let textValue = "gray";
          if (scoreValue < 30) {
            textValue = "red";
          } else if (scoreValue < 50) {
            textValue = "orange";
          } else if (scoreValue < 70) {
            textValue = "yellow";
          } else if (scoreValue < 90) {
            textValue = "green";
          } else if (scoreValue < 100 || scoreValue == 100) {
            textValue = "blue";
          }

          if (temp1[textValue]) {
            // already in
          } else {
            temp1[textValue] = []
          }
          temp1[textValue].push(r.rating_val)
        }
      });
      // console.log(temp1)
      ratings = {
        red: temp1.red ? temp1.red.length : 0,
        orange: temp1.orange ? temp1.orange.length : 0,
        yellow: temp1.yellow ? temp1.yellow.length : 0,
        green: temp1.green ? temp1.green.length : 0,
        blue: temp1.blue ? temp1.blue.length : 0,
        gray: temp1.gray ? temp1.gray.length : 0
      }
      // console.log(ratings)
    } catch (error) {
      // se nuovo rating_count_by_type_and_groupval[assetTypeLabel]
      // ratings è già corretto
    }
  }

  return (
    <Card style={{ height: "100%", overflow: ratings ? "hidden" : "" }} ref={elementRef}>
      <MDBox py={5} px={3} fullWidth>
        {cysrConfig().i("overview", title, "title") && (
          <MDTypography variant="h5">{cysrConfig().i("overview", title, "title")}</MDTypography>
        )}
        {cysrConfig().i("overview", title, "sub_title") && (
          <MDTypography variant="button" color="text" fontWeight="regular">
            {cysrConfig().i("overview", title, "sub_title")}
          </MDTypography>
        )}
        {ratings ?
          // asset card with 250 px / 40 px image at bottom
          <Grid container>
            <Grid item xs={12}>
              <MDBox lineHeight={1} style={{ display: "flex", flexWrap: "wrap", gap: "8px", alignItems: "end", marginBottom: 8 }}>
                <MDTypography variant="h5" fontWeight="bold">
                  {count}
                </MDTypography>
                <span style={{ fontSize: "12px" }}>
                  (
                  <MDTypography variant="button" fontWeight="bold" color={percentage.color}>
                    {percentage.value}&nbsp;
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color={darkMode ? "text" : "secondary"}
                    >
                      {percentage.label}
                    </MDTypography>
                  </MDTypography>
                  )
                </span>
                <img style={{
                  bottom: "0",
                  height: "auto",
                  left: 0,
                  position: "absolute",
                  right: 0,
                  width: "100%",
                  maxHeight: "30px",
                  objectPosition: "100% 100%",
                  objectFit: "auto"
                }}
                  // src="https://placehold.co/250x40"
                  src={`https://cysr.com/static/ruler/?red=${ratings.red}&orange=${ratings.orange}&yellow=${ratings.yellow}&green=${ratings.green}&blue=${ratings.blue}&size=${width}`} />
              </MDBox>
            </Grid>
          </Grid>
          :
          <Grid container>
            <Grid item xs={12}>
              {
                /*
                hide smaller title
              }
              <MDBox mb={0.5} lineHeight={1}>
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  color="text"
                  textTransform="capitalize"
                >
                  {title}
                </MDTypography>
              </MDBox>
              {
                */
              }
              <MDBox lineHeight={1}>
                <MDTypography variant="h5" fontWeight="bold">
                  {count}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="button" fontWeight="bold" color={percentage.color}>
                {percentage.value}&nbsp;
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color={darkMode ? "text" : "secondary"}
                >
                  {percentage.label}
                </MDTypography>
              </MDTypography>
              {false && warning && warning !== 0 && warning > 0 && (
                <Grid item xs={12}>
                  <MDTypography variant="caption" fontWeight="bold" color="warning">
                    <Icon>warning</Icon>&nbsp;{warning} <ScoreItem scoreValue={0} textual={true} item_style={"mini"} /><span style={{ fontSize: 12 }}> assets</span>
                  </MDTypography>
                </Grid>
              )}
            </Grid>
          </Grid>
        }
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultStatisticsCard
DefaultStatisticsCard.defaultProps = {
  warning: 0,
  percentage: {
    color: "success",
    value: "",
    label: "",
  },
};

// Typechecking props for the DefaultStatisticsCard
DefaultStatisticsCard.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  warning: PropTypes.number,
  ratings: PropTypes.any,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
};

export default DefaultStatisticsCard;

/* eslint-disable */