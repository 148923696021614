/* eslint-disable */
// prettier-ignore
"use client";

import { Row } from "@tanstack/react-table";
import { BookOpenCheck } from "lucide-react";

import { useToast } from "layouts/cysr/components/ui/use-toast";
import cysrConfig, { process } from "layouts/cysr/config";
import MDButton from "components/MDButton";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "layouts/cysr/components/ui/select";

import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "layouts/cysr/components/ui/alert-dialog";
import { useState } from "react";

export function DataTableRowButton({
    darkMode,
    row,
    rows,
    status,
}) {
    const accountUUID = window.location.pathname.substring(
        window.location.pathname.indexOf("/company/") + "/company/".length,
        window.location.pathname.lastIndexOf("/")
    );
    // const task = taskSchema.parse(row.original);
    const { toast } = useToast();
    let rowsDefined = true
    let ackWays = [
        {
            label: process.env.NEXT_PUBLIC_STRING_USER_PASSWORD_DATE,
            entries: 1,
        }
    ]
    if (row) {
        ackWays.push(
            {
                // check password is not null 
                disabled: !(row["original"].password),
                label: process.env.NEXT_PUBLIC_STRING_USER_PASSWORD,
                entries: "?",
                list: [
                    {
                        domain: row["original"].ki_q,
                        entries: "?",
                    }
                ],
            }
        )

        ackWays.push(
            {
                label: process.env.NEXT_PUBLIC_STRING_USER_DATE,
                entries: "?",
            }
        );
        ackWays.push(
            {
                label: process.env.NEXT_PUBLIC_STRING_USER,
                entries: "?",
            }
        );
    }
    if (rows) {
        ackWays = []
        ackWays.push(
            {
                label: process.env.NEXT_PUBLIC_STRING_USER_PASSWORD_DATE,
                entries: rows.length,
            },
        );

        ackWays.push(
            {
                // check password is not null 
                disabled: !(rows[0]["original"].password),
                label: process.env.NEXT_PUBLIC_STRING_USER_PASSWORD,
                entries: "?",
                list: [
                    {
                        domain: rows[0]["original"].ki_q,
                        entries: "?",
                    },
                    {
                        domain: (rows[1] ? rows[1]["original"] : rows[0]["original"]).ki_q,
                        entries: "?",
                    },
                ],
            },
        );

        ackWays.push(
            {
                label: process.env.NEXT_PUBLIC_STRING_USER_DATE,
                entries: "?",
            },
        );
        ackWays.push(
            {
                label: process.env.NEXT_PUBLIC_STRING_USER,
                entries: "?",
            }
        );
    } else {
        rows = [row]
        rowsDefined = false
    }
    if (true) {
        const [chosenOption, setChosenOption] = useState(ackWays[0].label);
        return (
            <>
                {
                    // mettere solo opzione di unacknowledged dei selezionati
                    false && rowsDefined && <Select onValueChange={(x) => setChosenOption(x)}>
                        <SelectTrigger className="w-full">
                            <SelectValue
                                placeholder={`${ackWays[0].label}${ackWays[0].entries && " (" + ackWays[0].entries + ")"
                                    }`}
                            />
                        </SelectTrigger>
                        <SelectContent>
                            {ackWays.map((w) => (
                                <SelectItem disabled={w.disabled} value={w.label}>{`${w.label}${w.entries && " (" + w.entries + ")"
                                    }`}</SelectItem>
                            ))}
                        </SelectContent>
                    </Select>}
                <div className="flex gap-2 items-center">
                    <MDButton
                        type="button"
                        style={cysrConfig().button_style} size="medium" variant="default"
                        className="flex h-8 w-full gap-2 data-[state=open]:bg-muted whitespace-nowrap"
                        onClick={() => {
                            let rowsSelected = rows
                            console.log([
                                chosenOption,
                                rowsSelected
                            ])
                            // fetch s
                            let entries = []
                            rowsSelected.forEach(r => {
                                let ob = {}
                                ob["accountack_key"] = r.original.key;
                                //ob["accountack_key"] = r.original.key;
                                /*
                                1.1 User = niente
                                1.2 User-Password = base64 della password
                                1.3 User-Date = (ISO string) 2023-09-12
                                1.4 User-Password-Date = base64 della password,2023-09-12 : MIMAD==,2014-12-24
                                */
                                if (chosenOption === ackWays[2].label) {
                                    // 1.1 User-Date
                                    //ob["accountack_val"] = "None";
                                    ob["accountack_type"] = "User-Date"
                                } else if (chosenOption === ackWays[1].label) {
                                    // 1.2 User-Password
                                    //ob["accountack_val"] = btoa(r.original.password)
                                    ob["accountack_type"] = "User-Password"
                                } else if (chosenOption === ackWays[3].label) {
                                    // 1.3 User-Date
                                    //ob["accountack_val"] = r.original.accountack_creation_datetime;
                                    ob["accountack_type"] = "User"
                                } else if (chosenOption === ackWays[0].label) {
                                    // 1.4 User-Password-Date
                                    //ob["accountack_val"] = btoa(r.original.password) + "," + r.original.accountack_creation_datetime;
                                    ob["accountack_type"] = "User-Password-Date"
                                }
                                ob.accountack_uuid = r.original.accountack_uuid
                                entries.push(ob)
                            });

                            fetch(`${cysrConfig().api_base_url}cti/${accountUUID}/ack/destroy/`, {
                                body: JSON.stringify(entries),
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                    "content-type": "application/json",
                                },
                                method: "DELETE"
                            })
                                .then((r) => {
                                    if (r.ok) {
                                        // window.location.reload();
                                        return r.json();
                                    } else {
                                        console.error(r);
                                        return r.json().then(err => {
                                            throw JSON.stringify(err)
                                        })
                                        return r.json()
                                    }
                                })
                                .then((response) => {
                                    console.log(response)
                                    window.location.reload()
                                }
                                ).catch((err) => {
                                    console.log(err);
                                    console.log("err3:", err);
                                    if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
                                        console.error(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
                                    } else if (JSON.parse(err) && JSON.parse(err).error) {
                                        console.error("Error", JSON.parse(err).error, "error", "error", "Error");
                                    } else {
                                        console.error("Error", "Some error happened", "error", "error", "Error");
                                    }
                                })
                            // fetch e
                        }
                        }
                    >
                        <BookOpenCheck className="h-4 w-4" />
                        <span className="!whitespace-nowrap">
                            {status === "new"
                                ? process.env.NEXT_PUBLIC_STRING_FEATURE_700_FEATURE
                                : process.env.NEXT_PUBLIC_STRING_FEATURE_750_FEATURE}{" "}
                            {false && ((chosenOption &&
                                ackWays.find((x) => x.label === chosenOption).entries > 1) ||
                                (rows && rows.length > 1)
                                ? process.env.NEXT_PUBLIC_STRING_TERM_1400_PLURAL
                                : process.env.NEXT_PUBLIC_STRING_TERM_1400_SINGLE)}
                        </span>
                    </MDButton>
                </div>
            </>
        );
    } else {
        return <span className="text-xs">DPO not enabled for this domain</span>
    }
}

