
/* eslint-disable */
// prettier-ignore
import { ChevronsUpDown, EyeOff, SortAsc, SortDesc } from "lucide-react";

import { cn } from "lib/utils";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "layouts/cysr/components/ui/dropdown-menu";
// import cysrConfig, { process } from "layouts/cysr/config";
// import MDButton from "components/MDButton";

export function DataTableColumnHeader({
    column,
    title,
    className,
}) {
    if (!column.getCanSort()) {
        return <div className={cn(className)}>{title}</div>;
    }

    return (
        <div className={cn("flex items-center px-2", className)}>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <button
                        // variant="ghost"
                        // size="sm"
                        // style={cysrConfig().button_style} size="medium"
                        style={{
                            background: "transparent",
                            textTransform: "none"
                        }}
                        className="-ml-3 h-8 data-[state=open]:bg-accent flex items-center space-x-2 text-turquoise-500 dark:text-turquoise-400"
                    >
                        <span>{title}</span>
                        {column.getIsSorted() === "desc" ? (
                            <SortDesc className="ml-2 h-4 w-4" />
                        ) : column.getIsSorted() === "asc" ? (
                            <SortAsc className="ml-2 h-4 w-4" />
                        ) : (
                            <ChevronsUpDown className="ml-2 h-4 w-4" />
                        )}
                    </button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                    <DropdownMenuItem onClick={() => column.toggleSorting(false)}>
                        <SortAsc className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                        Asc
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => column.toggleSorting(true)}>
                        <SortDesc className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                        Desc
                    </DropdownMenuItem>
                    {false && [
                        <DropdownMenuSeparator />,
                        <DropdownMenuItem onClick={() => column.toggleVisibility(false)}>
                            <EyeOff className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                            Hide
                        </DropdownMenuItem>,
                    ]}
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
}
