/* eslint-disable */
import { Base64 } from 'js-base64';
import { useState, useEffect } from "react";
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/material-ui';
import { Collapse, Paper, Stack, TablePagination } from '@mui/material';

import { usePagination } from '@table-library/react-table-library/pagination';
import { useSort } from '@table-library/react-table-library/sort';

// custom stuffs s
import cysrConfig from "layouts/cysr/config";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ScoreItem from "layouts/cysr/components/ScoreItem";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import StatusItem from "layouts/cysr/components/StatusItem";
import CysrTags from "layouts/cysr/components/CysrTags";
import { toast } from "sonner"
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
// import FormField from "layouts/pages/account/components/FormField";

import { useMaterialUIController } from "context";
// custom stuffs e

const Component = ({ data }) => {
  // console.log('data')
  // console.log(data)

  const actionsPixelWidth = 72;
  const tableRowsPerPage = [25, 50, 100, 200, 400];
  const [tableTagsActive, setTableTagsActive] = useState(data.tableTagsActive);
  const [tableTagsOptions, setTableTagsOptions] = useState(data.tableTagsOptions);
  const [hideCompleted, setHideCompleted] = useState(data.hideCompleted);
  const [search, setSearch] = useState(data.search);

  let hasEnvironment = false;
  let hasCategory = false;
  if (data.nodes && data.nodes.rows && data.nodes.rows[0] && data.nodes.rows[0].environment) {
    hasEnvironment = true;
  }
  if (data.nodes && data.nodes.rows && data.nodes.rows[0] && data.nodes.rows[0].category) {
    hasCategory = true;
  }
  // data.nodes
  /*
  {
    "help": "# Technologies grouped by environment",
    "rows": [
        {
            "cve": 0,
            "name": "Vimeo",
            "environment": "Backend",
            "version": "-",
            "category": "Video players",
            "detail": []
        },
        {
            "cve": 0,
            "name": "VideoJS",
            "environment": "Backend",
            "version": "-",
            "category": "Video players",
            "detail": []
        },
        {
            "cve": 0,
            "name": "MediaElement.js",
            "environment": "Backend",
            "version": "2.9.5",
            "category": "Video players",
            "detail": []
        },
        {
            "cve": 0,
            "name": "Mustache",
            "environment": "Backend",
            "version": "2.1.3",
            "category": "JavaScript frameworks",
            "detail": []
        },
        {
            "cve": 0,
            "name": "Lodash",
            "environment": "Backend",
            "version": "1.8.3",
            "category": "JavaScript libraries",
            "detail": []
        },
        {
            "cve": 6,
            "name": "jQuery",
            "environment": "Frontend",
            "version": "1.8.3",
            "category": "JavaScript libraries",
            "detail": []
        },
        {
            "cve": 0,
            "name": "Hammer.js",
            "environment": "Frontend",
            "version": "2.0.4",
            "category": "JavaScript libraries",
            "detail": []
        },
        {
            "cve": 1,
            "name": "FancyBox",
            "environment": "External",
            "version": "2.1.0",
            "category": "JavaScript libraries",
            "detail": [
                {
                    "score": 3.5,
                    "impact": 1.4,
                    "identifier": "CVE-2015-10072",
                    "exploitability": 2.1
                }
            ]
        },
        {
            "cve": 3,
            "name": "Google Font API",
            "environment": "Other",
            "version": "-",
            "category": "Font scripts",
            "detail": [
                {
                    "score": 9.8,
                    "impact": 5.9,
                    "identifier": "CVE-2022-47629",
                    "exploitability": 3.9
                },
                {
                    "score": 6.1,
                    "impact": 2.7,
                    "identifier": "CVE-2019-11358",
                    "exploitability": 2.8
                },
                {
                    "score": 3.5,
                    "impact": 1.4,
                    "identifier": "CVE-2015-10072",
                    "exploitability": 2.1
                }
            ]
        }
    ],
    "sort": [
        {
            "environment": "desc"
        },
        {
            "name": "desc"
        }
    ],
    "type": "TABLE-GROUP",
    "title": "Technologies grouped by environment",
    "columns": [
        {
            "align": null,
            "Header": "Name",
            "accessor": "name"
        },
        {
            "align": null,
            "Header": "Version",
            "accessor": "version"
        },
        {
            "align": null,
            "Header": "Category",
            "accessor": "category"
        },
        {
            "align": null,
            "Header": "Environment",
            "accessor": "environment"
        },
        {
            "align": null,
            "Header": "Number of CVE",
            "accessor": "cve"
        },
        {
            "align": null,
            "Header": "Lista CVE",
            "accessor": "cve detail"
        }
    ],
    "help_link": "",
    "sub_title": ""
}
  */

  /*
              "cve": 3,
              "name": "Google Font API",
              "environment": "Other",
              "version": "-",
              "category": "Font scripts",
              "detail": [
                  {
                      "score": 9.8,
                      "impact": 5.9,
                      "identifier": "CVE-2022-47629",
                      "exploitability": 3.9
  */
  let COLUMNS = [];
  let customColumns = []
  if (hasEnvironment) {
    customColumns.push(
      {
        label: 'Environment',
        renderCell: (item) => item.environment,
        sort: { sortKey: 'ENVIRONMENT' },
        resize: true,
        cysrTag: "environment"
      }
    )
  } else {
    customColumns.push(
      {
        label: 'Port',
        renderCell: (item) => item.port,
        sort: { sortKey: 'PORT' },
        resize: true,
        cysrTag: "port"
      },
      {
        label: 'Vendor',
        renderCell: (item) => item.vendor,
        sort: { sortKey: 'VENDOR' },
        resize: true,
        cysrTag: "vendor"
      },
      {
        label: 'Product',
        renderCell: (item) => item.product,
        sort: { sortKey: 'PRODUCT' },
        resize: true,
        cysrTag: "product"
      }
    )
  }
  customColumns.push(
    {
      label: 'Name',
      renderCell: (item) => item.name,
      sort: { sortKey: 'NAME' },
      cysrTag: "name"
    },
    {
      label: 'Version',
      renderCell: (item) => item.version,
      sort: { sortKey: 'VERSION' },
      cysrTag: "version"
    }
  );
  if (hasCategory) {
    customColumns.push(
      {
        label: 'Category',
        renderCell: (item) => item.category,
        sort: { sortKey: 'CATEGORY' },
        cysrTag: "category"
      }
    )
  }
  customColumns.push(
    {
      label: 'Number of CVE',
      renderCell: (item) => item.cve,
      sort: { sortKey: 'CVE' },
      resize: true,
      cysrTag: "cve"
    },
    {
      label: 'Actions',
      renderCell: (item) => (
        item.cve && item.cve > 0 ? <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '8px', width: `${actionsPixelWidth}px` }}>
          <MDButton onClick={() => handleExpand(item)} style={cysrConfig().button_style} size="medium">
            {ids && ids.includes(item.name) ? <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} /> : <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />}
          </MDButton>
        </div>
          :
          null
      ),
      pinRight: true
      // sort: { sortKey: 'RATING' }
    },
  );
  // prendi ordine dalla lista columns passata
  /*
  [
    {
        "align": null,
        "Header": "Name",
        "accessor": "name"
    },
    {
        "align": null,
        "Header": "Version",
        "accessor": "version"
    },
    {
        "align": null,
        "Header": "Category",
        "accessor": "category"
    },
    {
        "align": null,
        "Header": "Environment",
        "accessor": "environment"
    },
    {
        "align": null,
        "Header": "Number of CVE",
        "accessor": "cve"
    },
    {
        "align": null,
        "Header": "Lista CVE",
        "accessor": "cve detail"
    }
  ]
  */
  let isActionsIn = false;
  data.columns.forEach(c => {
    customColumns.forEach(cc => {
      if (c.accessor == cc.cysrTag) {
        // sostituisci label
        cc.label = c.Header;
        COLUMNS.push(cc)
        if (c.accessor == "action") {
          isActionsIn = true;
        }
      }
    });
    if (isActionsIn === false && c.accessor == "detail") {
      isActionsIn = true;
      customColumns[customColumns.length - 1].label = c.Header;
      COLUMNS.push(customColumns[customColumns.length - 1])
    }
  });
  // se non c'è actions buttalo dentro
  if (isActionsIn === false) {
    isActionsIn = true;
    COLUMNS.push(customColumns[customColumns.length - 1])
  }
  let tData = { nodes: data.rows };
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  let accountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/company/") + "/company/".length,
    window.location.pathname.lastIndexOf("/")
  );
  if (localStorage.getItem("cysr_useraccountrole_selected")) {
    accountUUID = localStorage.getItem("cysr_useraccountrole_selected");
  }

  let filterableColumnsValues = [];

  // expandable
  const [ids, setIds] = useState([]);
  let autoString = "";
  let firstAuto = 0;
  COLUMNS.forEach(c => {
    if (firstAuto === 0) {
      autoString += "auto ";
    } else {
      firstAuto--;
    }
  });
  const expandedTableStyle = `
    --data-table-library_grid-template-columns: ${autoString}!important;
    .animate {
      grid-column: 1 / -1;
      display: flex;
    }
    .animate {
        visibility: visible;
        border-bottom: 1px solid #${darkMode ? "4a4545" : "f0f2f5"};
    }
    .animate > div {
      flex: 1;
      display: flex;
    }
    
    @media (min-width: 768px){
      .pin-right {
        right: 0;
      }
    }
  `;

  const handleExpand = (item) => {
    if (ids.includes(item.name)) {
      setIds(ids.filter((id) => id !== item.name));
    } else {
      setIds(ids.concat(item.name));
    }
  };
  async function fetchCveDetails(identifier) {
    const response = await fetch(`https://cvesearch.sq.biz/api/cve/${identifier}`);
    const data = await response.json();
    return data;
  }
  function tableDetailsRow(sid, item) {
    // const des = JSON.stringify(await fetch(`https://cvesearch.sq.biz/api/cve/${item.identifier}`))
    // const des = JSON.stringify(await fetch(`https://cvesearch.sq.biz/api/cve/CVE-2023-5020`))
    const [cveDetails, setCveDetails] = useState(null);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const details = await fetchCveDetails(item.identifier);
          setCveDetails(details);
        } catch (error) {
          console.error('Error fetching CVE details:', error);
          setCveDetails('ERROR')
        }
      };
      fetchData();
    }, [item.identifier]);
    return (
      <MDBox
        key={sid}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        {
          // <MDTypography variant="body2" color="text"> 
        }
        <div className={`flex flex-col relative${cveDetails && cveDetails === 'ERROR'
          ? null : " pl-3"}`}>
          <MDTypography variant="button" color="text">
            {item.identifier}
          </MDTypography>
          {cveDetails && cveDetails === 'ERROR'
            ? null
            : <div className="absolute" style={{
              left: -8,
              top: -9,
              zIndex: 8,
            }}>
              <MDTypography variant="button" color="text">
                <InfoSharpIcon onClick={() => toast(
                  <div className='flex flex-col gap-1 w-full'>
                    <MDTypography variant="text" fontWeight="regular">
                      {item.identifier}
                    </MDTypography>
                    <p>{cveDetails ? cveDetails.summary : 'Loading...'}</p>
                  </div>,
                  {
                    duration: 20000
                  }
                )}
                  className='w-4 h-4 text-inherit cursor-pointer' />
              </MDTypography>
            </div>
          }
        </div>
        <MDTypography sx={{
          textAlign: "left",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          flex: 1,
          marginLeft: "8px",
        }} variant="caption" color="text" fontWeight="regular">
          {cveDetails ? cveDetails.summary : ""}
        </MDTypography>
        <MDBox
          display="flex"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
            <MDTypography mr={1} variant="caption" color="text" fontWeight="regular">
              Score:
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="regular">
              {
                item.score
              }
            </MDTypography>
          </MDBox>
          <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
            <MDTypography mr={1} variant="caption" color="text" fontWeight="regular">
              Impact:
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="regular">
              {
                item.impact
              }
            </MDTypography>
          </MDBox>
          <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
            <MDTypography mr={1} variant="caption" color="text" fontWeight="regular">
              Exploitability:
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="regular">
              {
                item.exploitability
              }
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox >
    )
  }
  const ROW_OPTIONS = {
    renderAfterRow: (item) => (
      <Collapse className="animate" in={ids.includes(item.name)}>
        <div style={{ flex: '1', display: 'flex' }}>
          <div style={{ flex: '1', backgroundColor: darkMode ? "#121619" : "#edf0f2" }}>
            <MDBox style={{ position: "sticky", left: 0, right: 0 }} maxWidth={{ xs: "calc(100vw - (48px + 64px))", xl: "calc(100vw - (282px + 48px + 58px))" }}>
              <Card style={{ backgroundColor: darkMode ? "#121619" : "#edf0f2", color: darkMode ? "#7B809A" : "#7B809A", borderRadius: 0, boxShadow: "none", padding: "1em", margin: 0 }}>
                <MDBox width="100%">
                  {item.detail.map((item, index) => {
                    return tableDetailsRow(index, item)
                  })}
                </MDBox>
              </Card>
            </MDBox>
          </div>
        </div>
      </Collapse>
    ),
  };

  // filter list
  tData = {
    nodes: tData.nodes && tData.nodes.filter((item) => {
      /*
      // check filter first
      if(hideCompleted && item.isComplete){
        return false
      }
      */
      // check tags
      // da fare tags con chiave valore
      if (tableTagsActive && tableTagsActive.length > 0) {
        let trueCount = tableTagsActive.length;
        tableTagsActive.forEach(ta => {
          let notFound = true;
          // X:item.cysrTag s
          if (ta && typeof ta === 'string' && ta.includes(":")) {
            const tag = ta.toLowerCase().split(":");
            const tagKey = tag[0].toLowerCase();
            const tagValue = tag[1].toString().trim();
            COLUMNS.forEach(col => {
              if (col.cysrTag && item[col.cysrTag] && col.label.toLowerCase() === tagKey && item[col.cysrTag.toLowerCase()] && item[col.cysrTag.toLowerCase()]) {
                // check cysrTagDateFormat
                if (col.cysrTagDateFormat) {
                  const vd = new Date(item[col.cysrTag.toLowerCase()].toString()).toLocaleDateString(col.cysrTagDateFormat, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })
                  if (vd.toString().includes(tagValue) && notFound) {
                    trueCount--;
                    notFound = false;
                  }
                } else if (item[col.cysrTag.toLowerCase()].toString().toLowerCase().includes(tagValue)) {
                  if (notFound) {
                    trueCount--;
                    notFound = false;
                  }
                }
              }
            })
          }
          // X:item.cysrTag e
          // compare (for freeSolo) s
          filterableColumnsValues.forEach(property => {
            if (ta && ta[property]) {
              // a default option (with [property])
              if (ta && ta[property] && item[property] && item[property].toLowerCase().includes(ta[property].toLowerCase())) {
                if (notFound) {
                  trueCount--;
                  notFound = false;
                }
              }
            } else {
              if (ta && ta.rating) {
                // avoid checking again ta with title (rating Excellent, Good...)
              } else {
                // not a default option (with [property])
                if (ta && item[property] && item[property].toLowerCase().includes(ta.toLowerCase())) {
                  if (notFound) {
                    trueCount--;
                    notFound = false;
                  }
                }
              }
            }
          });
          // compare (for freeSolo) e
        });
        if (trueCount !== 0) {
          return false;
        }
      }
      // check search at last
      // ..
      return true;
    }
    ),
  };

  const materialTheme = getTheme(DEFAULT_OPTIONS, {
    isVirtualized: true
  });

  // to set row border bottom style:
  // border-bottom: 1px solid #${darkMode ? "4a4545" : "f0f2f5"};

  const customTheme = {
    Table: expandedTableStyle,
    BaseCell: `
      border: 0!important;
      padding: 4px;
    `,
  };
  let theme = useTheme([materialTheme, customTheme]);
  if (darkMode) {
    // provide custom theme
    theme = useTheme([materialTheme, {
      Table: expandedTableStyle,
      BaseCell: `
        color: #ffffff;
        background-color: #192328;
        border: 0!important;
        padding: 4px;
      `,
    }
    ]);
  }

  const pagination = usePagination(tData, {
    state: {
      page: 0,
      // size: 2,
      // size: tData.nodes.length,
      size: tableRowsPerPage[0],
    },
    onChange: onPaginationChange,
  });

  function onPaginationChange(action, state) {
    // console.log(action, state);
  }

  const sort = useSort(
    tData,
    {
      state: {
        sortKey: hasEnvironment ? "ENVIRONMENT" : "PORT",
        reverse: data.isSorted && data.isSorted[0] && (hasEnvironment ? data.isSorted[0].environment === "desc" : data.isSorted[0].port === "desc") ? true : false
      },
      onChange: onSortChange,
    },
    {
      sortIcon: {
        iconDefault: null,
        iconUp: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
        </svg>
        ,
        iconDown: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
        ,
      },
      sortFns: {
        NAME: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
        VERSION: (array) => array.sort((a, b) => a.version.localeCompare(b.version)),
        VENDOR: (array) => array.sort((a, b) => a.vendor.localeCompare(b.vendor)),
        PRODUCT: (array) => array.sort((a, b) => a.product.localeCompare(b.product)),
        CATEGORY: (array) => array.sort((a, b) => a.category.localeCompare(b.category)),
        ENVIRONMENT: (array) => array.sort((a, b) => a.environment.localeCompare(b.environment)),
        PORT: (array) => array.sort((a, b) => a.port - b.port),
        CVE: (array) => array.sort((a, b) => a.cve - b.cve),
      },
    },
  );

  function onSortChange(action, state) {
    // console.log(action, state);
  }

  const handleSetTableTags = (event, t) => {
    // console.log(t)
    setTableTagsActive(t);
    // replace url s
    let params = new URLSearchParams(window.location.search)
    params.delete('t')
    if (t != "" && t.length > 0) {
      params.append("t", Base64.encode(JSON.stringify(t)))
    }
    window.history.replaceState(null, '', '?' + params + window.location.hash)
    // replace url e
    // go to first page
    pagination.fns.onSetPage(0)
  };

  const VIRTUALIZED_OPTIONS = {
    rowHeight: (_item, _index) => {
      return 54
    }
  };

  return (
    <>
      <Stack spacing={10} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CysrTags tableTagsOptions={tableTagsOptions} tableTagsActive={tableTagsActive} handleSetTableTags={handleSetTableTags} darkMode={darkMode} />
          </Grid>
        </Grid>
      </Stack>
      <Paper style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none', height: 'auto' }}>
        {
          // <Paper style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none', height: '360px' }}>
        }
        <CompactTable
          columns={COLUMNS}
          // passando le virtualizedOptions non funziona l'expand abilitato, penso perchè imposta l'height da solo
          // virtualizedOptions={VIRTUALIZED_OPTIONS}
          data={tData}
          sort={sort}
          theme={theme}
          rowOptions={ROW_OPTIONS}
          layout={{
            isDiv: true,
            fixedHeader: true
          }}
          pagination={pagination}
          style={{ borderColor: 'transparent' }}
        // horizontalScroll={true}
        />
      </Paper>
      <Stack spacing={10}>
        <TablePagination
          component="div"
          style={{ color: darkMode ? "#ffffff" : "#000000", border: 0 }}
          count={tData.nodes.length}
          page={pagination.state.page}
          rowsPerPage={pagination.state.size}
          rowsPerPageOptions={tableRowsPerPage}
          onRowsPerPageChange={(event) =>
            pagination.fns.onSetSize(parseInt(event.target.value, 10))
          }
          onPageChange={(event, page) => pagination.fns.onSetPage(page)}
        />
      </Stack>
    </>
  );
};
export default Component;
/* eslint-disable */