/* eslint-disable */
// prettier-ignore
"use client";

import { X } from "lucide-react";

import cysrConfig, { process } from "layouts/cysr/config";
import MDButton from "components/MDButton";
import { Input } from "layouts/cysr/components/ui/input";
import { DataTableFacetedFilter } from "./data-table-faceted-filter";

export function DataTableToolbar({
    table,
    data,
    darkMode
}) {
    const isFiltered = table.getState().columnFilters.length > 0;
    /*
    let groups = [
        {
            label: `Search key`,
            searchKeys: [],
        },
    ];

    data && data.forEach((d) => {
        const kiQ = d["accountack_group"];
        if (groups[0].searchKeys.find((k) => k.label === kiQ)) {
            // already in
        } else {
            groups[0].searchKeys.push({
                label: kiQ,
                value:
                    kiQ.trim() && kiQ.trim().toLowerCase()
                        ? kiQ.trim().toLowerCase()
                        : kiQ,
            });
        }
    });
    */

    return (
        <div className="flex items-center justify-between">
            <div className="flex flex-1 items-center space-x-2">
                <Input
                    placeholder={`Filter ${process.env.NEXT_PUBLIC_STRING_TERM_1400_PLURAL}...`}
                    value={
                        (table.getColumn("accountack_acknowledger")?.getFilterValue()) ?? ""
                    }
                    onChange={(event) =>
                        table.getColumn("accountack_acknowledger")?.setFilterValue(event.target.value)
                    }
                    className="h-8 w-[150px] lg:w-[250px]"
                />
                {table.getColumn("accountack_type") && (
                    <DataTableFacetedFilter
                        darkMode={darkMode}
                        column={table.getColumn("accountack_type")}
                        title={"Type"}
                        options={[
                            {
                                label: process.env.NEXT_PUBLIC_STRING_USER_PASSWORD_DATE,
                                value: "User-Password-Date",
                                icon: undefined,
                            },
                            {
                                label: process.env.NEXT_PUBLIC_STRING_USER_PASSWORD,
                                value: "User-Password",
                                icon: undefined,
                            },
                            {
                                label: process.env.NEXT_PUBLIC_STRING_USER_DATE,
                                value: "User-Date",
                                icon: undefined,
                            },
                            {
                                label: process.env.NEXT_PUBLIC_STRING_USER,
                                value: "User",
                                icon: undefined,
                            },
                        ]}
                    />
                )}
                {isFiltered && (
                    <MDButton
                        style={cysrConfig().button_style} size="medium"
                        variant="ghost"
                        onClick={() => table.resetColumnFilters()}
                        className="h-8 px-2 lg:px-3"
                    >
                        Reset
                        <X className="ml-2 h-4 w-4" />
                    </MDButton>
                )}
            </div>
            <span className="ml-auto block text-sm text-muted-foreground">
                {table.getFilteredSelectedRowModel().rows.length} of{" "}
                {table.getFilteredRowModel().rows.length} row(s) selected
            </span>
            {
                // <DataTableViewOptions table={table} />
            }
        </div>
    );
}
