/* eslint-disable */
// prettier-ignore
import * as React from "react";
import { Check, PlusCircle } from "lucide-react";

import { cn } from "lib/utils";
import { Badge } from "layouts/cysr/components/ui/badge";

// import cysrConfig, { process } from "layouts/cysr/config";
// import MDButton from "components/MDButton";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
} from "layouts/cysr/components/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "layouts/cysr/components/ui/popover";
import { Separator } from "layouts/cysr/components/ui/separator";

export function DataTableFacetedFilter({
    column,
    title,
    options,
    darkMode
}) {
    const facets = column?.getFacetedUniqueValues();
    const selectedValues = new Set(column?.getFilterValue());

    return (
        <Popover>
            <PopoverTrigger asChild>
                <button
                    // style={cysrConfig().button_style} size="medium" variant="outline" className="h-8 border-dashed"
                    className="inline-flex items-center justify-center text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-turquoise-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-turquoise-950 dark:focus-visible:ring-turquoise-800 border border-turquoise-200 bg-white hover:bg-turquoise-100 hover:text-turquoise-900 dark:border-turquoise-800 dark:bg-[rgb(34,39,42)] dark:hover:bg-turquoise-800 dark:hover:text-turquoise-50 rounded-md px-3 h-8 border-dashed"
                >
                    <PlusCircle className="mr-2 h-4 w-4" />
                    {title}
                    {selectedValues?.size > 0 && (
                        <>
                            <Separator orientation="vertical" className="mx-2 h-4" />
                            <Badge
                                variant="secondary"
                                className="rounded-sm px-1 font-normal lg:hidden"
                            >
                                {selectedValues.size}
                            </Badge>
                            <div className="hidden space-x-1 lg:flex">
                                {selectedValues.size > 2 ? (
                                    <Badge
                                        variant="secondary"
                                        className="rounded-sm px-1 font-normal"
                                    >
                                        {selectedValues.size} selected
                                    </Badge>
                                ) : (
                                    options
                                        .filter((option) => selectedValues.has(option.value))
                                        .map((option) => (
                                            <Badge
                                                variant="secondary"
                                                key={option.value}
                                                className="rounded-sm px-1 font-normal"
                                            >
                                                {option.label}
                                            </Badge>
                                        ))
                                )}
                            </div>
                        </>
                    )}
                </button>
            </PopoverTrigger>
            <PopoverContent
                style={{ borderRadius: ".45rem" }}
                className={`${darkMode ? "dark" : "light"} w-[200px] p-0 border-turquoise-200 dark:border-turquoise-800 text-turquoise-950 dark:text-turquoise-50`} align="start">
                <Command>
                    <CommandInput placeholder={title} className="outline-0" />
                    <CommandList>
                        <CommandEmpty>No results found.</CommandEmpty>
                        <CommandGroup>
                            {options && options.map((option) => {
                                const isSelected = selectedValues.has(option.value);
                                return (
                                    <CommandItem
                                        key={option.value}
                                        onSelect={() => {
                                            if (isSelected) {
                                                selectedValues.delete(option.value);
                                            } else {
                                                selectedValues.add(option.value);
                                            }
                                            const filterValues = Array.from(selectedValues);
                                            column?.setFilterValue(
                                                filterValues.length ? filterValues : undefined
                                            );
                                        }}
                                    >
                                        <div
                                            className={cn(
                                                "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                                                isSelected
                                                    ? "bg-primary text-primary-foreground"
                                                    : "opacity-50 [&_svg]:invisible"
                                            )}
                                        >
                                            <Check className={cn("h-4 w-4")} />
                                        </div>
                                        {option.icon && (
                                            <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                                        )}
                                        <span>{option.label}</span>
                                        {facets?.get(option.value) && (
                                            <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">
                                                {facets.get(option.value)}
                                            </span>
                                        )}
                                    </CommandItem>
                                );
                            })}
                        </CommandGroup>
                        {(selectedValues && selectedValues.size > 0) && (
                            <>
                                <CommandSeparator />
                                <CommandGroup>
                                    <CommandItem
                                        onSelect={() => column?.setFilterValue(undefined)}
                                        className="justify-center text-center"
                                    >
                                        Clear filters
                                    </CommandItem>
                                </CommandGroup>
                            </>
                        )}
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
}
