/* eslint-disable */
// prettier-ignore
"use client";

import { X } from "lucide-react";

import cysrConfig, { process } from "layouts/cysr/config";
import MDButton from "components/MDButton";
import { Input } from "layouts/cysr/components/ui/input";
import { DataTableFacetedFilter } from "./data-table-faceted-filter";

import { Icons } from "layouts/cysr/components/Icons";
import { format } from "date-fns";

export function DataTableToolbar({
    table,
    data,
    darkMode,
    selectedDays
}) {
    const isFiltered = table.getState().columnFilters.length > 0;

    let groups = [
        {
            label: `Search key`,
            searchKeys: [],
        },
    ];
    /*
        data.forEach((d) => {
            const kiQ = d["ki_q"];
            if (groups[0].searchKeys.find((k) => k.label === kiQ)) {
                // already in
            } else {
                groups[0].searchKeys.push({
                    label: kiQ,
                    value:
                        kiQ.trim() && kiQ.trim().toLowerCase()
                            ? kiQ.trim().toLowerCase()
                            : kiQ,
                });
            }
        });
    */

    return (
        <div className="flex items-center justify-between">
            <div className="flex flex-1 items-center space-x-2">
                <Input
                    placeholder={`Filter Assets by Name`}
                    value={
                        (table.getColumn("name")?.getFilterValue()) ?? ""
                    }
                    onChange={(event) =>
                        table.getColumn("name")?.setFilterValue(event.target.value)
                    }
                    className="h-8 w-[150px] lg:w-[250px]"
                />
                {
                    /*
                    table.getColumn("status") && (
                        <DataTableFacetedFilter
                            darkMode={darkMode}
                            column={table.getColumn("status")}
                            title="Status"
                            options={[
                                {
                                    value: "acknowledged",
                                    label: "Acknowledged",
                                    icon: Icons.acknowledged,
                                },
                                {
                                    value: "new",
                                    label: "To check",
                                    icon: Icons.new,
                                },
                            ]}
                        />
                    )
                    */
                }

                {
                    // score
                }
                <div className="flex gap-1 relative items-center justify-center text-xs">
                    <span className="text-turquoise-500 dark:text-turquoise-400 text-center absolute left-0 right-0 text-xs w-full -top-[20px]">Score</span>
                    {table.getColumn("first_score") && (
                        <DataTableFacetedFilter
                            darkMode={darkMode}
                            column={table.getColumn("first_score")}
                            title={`${selectedDays[0] && format(new Date(selectedDays[0]), process.env.NEXT_PUBLIC_DATE_FORMAT)}`}
                            options={[
                                {
                                    value: "bad",
                                    label: "Bad",
                                },
                                {
                                    value: "poor",
                                    label: "Poor",
                                },
                                {
                                    value: "fair",
                                    label: "Fair",
                                },
                                {
                                    value: "good",
                                    label: "Good",
                                },
                                {
                                    value: "excellent",
                                    label: "Excellent",
                                },
                            ]}
                        />
                    )}
                    {table.getColumn("second_score") && (
                        <DataTableFacetedFilter
                            darkMode={darkMode}
                            column={table.getColumn("second_score")}
                            title={`${selectedDays[1] && format(new Date(selectedDays[1]), process.env.NEXT_PUBLIC_DATE_FORMAT)}`}
                            options={[
                                {
                                    value: "bad",
                                    label: "Bad",
                                },
                                {
                                    value: "poor",
                                    label: "Poor",
                                },
                                {
                                    value: "fair",
                                    label: "Fair",
                                },
                                {
                                    value: "good",
                                    label: "Good",
                                },
                                {
                                    value: "excellent",
                                    label: "Excellent",
                                },
                            ]}
                        />
                    )}
                </div>

                {(
                    <DataTableFacetedFilter
                        darkMode={darkMode}
                        column={table.getColumn("type")}
                        title="Type"
                        options={[
                            {
                                label: "AS",
                                value: "as",
                            },
                            {
                                label: "DNS",
                                value: "dns",
                            },
                            {
                                label: "Domain",
                                value: "domain",
                            },
                            {
                                label: "Email",
                                value: "email",
                            },
                            {
                                label: "Host",
                                value: "host",
                            },
                            {
                                label: "IP",
                                value: "ip",
                            },
                            {
                                label: "Network",
                                value: "network",
                            },
                            {
                                label: "Website",
                                value: "website",
                            }
                        ]}
                    />
                )}
                {
                /*
                table.getColumn("threat_type") && (
                    <DataTableFacetedFilter
                        darkMode={darkMode}
                        column={table.getColumn("threat_type")}
                        title={"Category"}
                        options={[
                            {
                                label: process.env.NEXT_PUBLIC_STRING_TERM_1400_SINGLE,
                                value: process.env.NEXT_PUBLIC_STRING_TERM_1400_SINGLE.toLowerCase(),
                                icon: undefined,
                            },
                            {
                                label: process.env.NEXT_PUBLIC_STRING_TERM_1300_SINGLE,
                                value: process.env.NEXT_PUBLIC_STRING_TERM_1300_SINGLE.toLowerCase(),
                                icon: undefined,
                            },
                        ]}
                    />
                )
                */
                }
                {isFiltered && (
                    <MDButton
                        style={cysrConfig().button_style} size="medium"
                        variant="ghost"
                        onClick={() => table.resetColumnFilters()}
                        className="h-8 px-2 lg:px-3"
                    >
                        Reset
                        <X className="ml-2 h-4 w-4" />
                    </MDButton>
                )}
            </div>
            {false && (
                <span className="ml-auto block text-sm text-muted-foreground">
                    {table.getFilteredSelectedRowModel().rows.length} of{" "}
                    {table.getFilteredRowModel().rows.length} row(s) selected
                </span>
            )
            }
            {
                // <DataTableViewOptions table={table} />
            }
        </div>
    );
}
