/* eslint-disable */
// prettier-ignore



import { toast } from "sonner"

import cysrConfig, { process } from "layouts/cysr/config";

import { triggerAction } from "layouts/cysr/components/buttonActions";

import MDButton from "components/MDButton";

export function ComplyDownloadReport({ questionnaireID, language }) {

    let r = {
        label: 'Questionnaire report',
        value: "Generate report",
        key: 0,
        options: [
            {
                code: 'en',
                label: "English",
                color: "info",
                value: "Generate report",
                onClick: "go_to_report_comply",
                key: 0,
                // icon: cysrConfig().report.icon.svg
            },
            {
                code: 'it',
                label: "Italiano",
                color: "info",
                value: "Generate report (IT)",
                onClick: "go_to_report_comply_it",
                key: 20,
                // icon: cysrConfig().report.icon.svg
            },
            {
                code: 'de',
                label: "Deutsch",
                color: "info",
                value: "Generate report (DE)",
                onClick: "go_to_report_comply_de",
                key: 21,
                // icon: cysrConfig().report.icon.svg
            },
            {
                code: 'es',
                label: "Español",
                color: "info",
                value: "Generate report (ES)",
                onClick: "go_to_report_comply_es",
                key: 23,
                // icon: cysrConfig().report.icon.svg
            },
            {
                code: 'fr',
                label: "Français",
                color: "info",
                value: "Generate report (FR)",
                onClick: "go_to_report_comply_fr",
                key: 26,
                // icon: cysrConfig().report.icon.svg
            }
        ]
    };

    return (
        <MDButton
            type='button'
            title='Download questionnaire'
            style={{ width: '100%', ...cysrConfig().button_style }}
            size="medium"
            onClick={() => {
                const selectedOption = (r.options).find((o) => {
                    return o.code === language;
                })
                if (selectedOption) {
                    triggerAction(selectedOption.onClick, questionnaireID)
                } else {
                    toast.error("Not a valid option.")
                }
            }}
        >
            <svg style={cysrConfig().button_icon_left_style} className='!h-6 !w-6' fill="currentColor" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FileDownloadSharpIcon">
                <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
            </svg>
            Download
        </MDButton>
    )
}