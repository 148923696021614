/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { RadarChart } from "@carbon/charts-react";

import(`@carbon/charts/styles-${localStorage.getItem("cysr_dark_mode") && localStorage.getItem("cysr_dark_mode") === "false" ? "g10" : "g90"}.css`);

import cysrConfig from "layouts/cysr/config";

/* eslint-disable */
function Radar() {
  const params = new URLSearchParams(window.location.search);
  // Our State.
  const accountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/company/") + "/company/".length,
    window.location.pathname.lastIndexOf("/")
  );
  const dashboardCallUrl = `${cysrConfig().api_base_url}dashboard/mainpage/${accountUUID}/`;
  // useEffect(() => {
    //setLoading(true);
  let chartData = false;
  const request = new XMLHttpRequest();
  request.open('GET', dashboardCallUrl, false);  // `false` makes the request synchronous
  request.setRequestHeader("Authorization", `Bearer ${params.get('t')}`);
  request.send(null);

  if (request.status === 200) {
    chartData = JSON.parse(request.responseText).assets.graphs.radar;
  }
    // very similar to handleDownloadPdf e
  // }, []);
  return (
    <div style={{background: "#ffff00", display: "block", textAlign: "center", position: "fixed", zIndex: "99999", left: "0", right: "0", top:"0", bottom:"0", padding:"2em", overflow:"auto"}}>
        <Grid item container spacing={3}>
            <Grid item container>
                <Grid item>
                  <MDBox my={1}>
                    <RadarChart
                          data={chartData}
                          options={{
                              "animations": false,
                              "resizable": false,
                              "title": false,
                              "radar": {
                                "axes": {
                                    "angle": "type",
                                    "value": "rating"
                                },
                                "alignment": "center"
                              },
                              "data": {
                              "groupMapsTo": "label"
                              },
                              "legend": {
                              "enabled": false,
                              },
                              // "height": "400px",
                              "height": "500px",
                              "width": "500px",
                              "toolbar": { "enabled": false },
                          }}
                      />
                  </MDBox>
                </Grid>
            </Grid>
        </Grid>
    </div>
  );
}
/* eslint-disable */
export default Radar;
