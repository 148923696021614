/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import DataTable from "layouts/cysr/components/DataTable";
import { useLocation} from "react-router-dom";
// Data
import ScoreItem from "layouts/cysr/components/ScoreItem";
import ratingData from "layouts/cysr/components/ratingData";
import relatedData from "layouts/cysr/components/relatedData";
import AssetTypeTypologyDetail from "layouts/cysr/asset/AssetTypeTypologyDetail";

import InfoButton from "layouts/cysr/components/infoButton";
// import checksData from "layouts/cysr/components/assetChecksDataTableData";
// import checksData from "layouts/cysr/components/lastchecksDataTableData";
// new
import checksData from "layouts/cysr/components/checksData";
// import BubbleChartLabel from "layouts/cysr/asset/BubbleChartLabel";
// import BubbleChartValue from "layouts/cysr/asset/BubbleChartValue";

import cysrConfig from "layouts/cysr/config";
/* eslint-disable */
// import { BubbleChart } from "@carbon/charts-react";
import { LineChart } from "@carbon/charts-react";
import(`@carbon/charts/styles-${localStorage.getItem("cysr_dark_mode") && localStorage.getItem("cysr_dark_mode") === "false" ? "g10" : "g100"}.css`);
// Or
// import "@carbon/charts/styles/styles.scss";

// IBM Plex should either be imported in your project by using Carbon
// or consumed manually through an import
// import "./plex-and-carbon-components.css";

// ms
const App = function app () {
  const location = useLocation();
  const [expandedTable, setExpandedTableOpen] = useState(false);
  const [views, setViews] = useState([]);
  let historyState = {};
  // get hashtag
  let assetUUID = 0;
  // extract id
  if (window.location.hash !== undefined) {
    assetUUID = window.location.hash.replace("#", "");
  }

  const assetCallUrl = `${cysrConfig().api_base_url}dashboard/asset/${assetUUID}/`;
  if (assetUUID && assetUUID !== "") {
    useEffect(() => {
      const timelineUrl = `${cysrConfig().api_base_url}asset/${assetUUID}/rating/history/`;
      fetch(timelineUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
      })
        .then((response) => response.json())
        .then((timeLineData) => {
          const tData = []; 
          if (Array.isArray(timeLineData) === false){
            timeLineData = [];
          }
          timeLineData.forEach(t => {
            // "date": "2020-12-10T21:00:00.000Z",
            // "value": 10
            tData.push({
              date: t.mu_created_at,
              value: parseInt(t.rating_val, 10)
            });
          });
          historyState = {
            data: {
              "labels": [
                "Rating score"
              ],
              "datasets": [
                {
                  "label": "Rating",
                  "data": tData
                }
              ]
            },
            options: {
            // "title": "Line (time series) - Time interval hourly with custom ticks formats ('MMM d, HH:mm' and 'HH:mm')",
            "title": false,
            "axes": {
              "left": {},
              "bottom": {
                "scaleType": "time"
              }
            },
            "timeScale": {
              "timeIntervalFormats": {
                "hourly": {
                  "primary": "MMM d, HH:mm",
                  "secondary": "HH:mm"
                }
              }
            },
            "legend": {
              "enabled": false
            },
            "height": "100%",
            "toolbar": { "enabled": false },
          }};
          fetch(assetCallUrl, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
            },
          })
            .then((response) => response.json())
            .then((responseAssetData) => {
              fetch(`${cysrConfig().api_base_url}assettype/`, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                },
              })
                .then((graphRes) => {
                  graphRes.json();
                })
                .then((graphResponse) => {
                  // checks
                  // /dashboard/asset/{asset_req_uuid}/checks/
                  fetch(`${cysrConfig().api_base_url}dashboard/asset/${assetUUID}/checks/`, {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                    },
                  }).then((checksRes) => checksRes.json())
                  .then((checksResponse) => {
                    setViews({
                      graph: graphResponse,
                      asset: responseAssetData,
                      timeline: historyState,
                      // ratingCurrent: responseRatingCurrentData,
                      // from_to
                      // to_from
                      ratingCurrent: responseAssetData.rating,
                      // related: responseRelatedCurrentData,
                      related: {
                        // from: responseAssetData.to_from,
                        from: responseAssetData.asset_from,
                        // to: responseAssetData.from_to,
                        to: responseAssetData.asset_to,
                      },
                      ratingdetails: checksResponse.ratingdetails,
                      hideGoToAssetChecks: true,
                      highlights: responseAssetData.highlights,
                      // for the moment do not display checks
                      // checks: [],
                      // checks: resRatingResult,
                    });
                  })
                  .catch((cherr) => console.error(cherr));
                })
                .catch((gerr) => console.error(gerr));
            })
            .catch((err) => console.error(err));
        })
      .catch((err) => console.error(err));

    }, [location]);
  } else {
    useEffect(() => {
      setViews();
    });
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div style={{paddingTop: "24px", position: "relative"}}>
            <InfoButton cysr_info={cysrConfig().i("asset","header","help")} cysr_info_link={cysrConfig().i("asset","header","help_link")} />
          </div>
          {ratingData(views)}
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs={12} md={3}>
            <Card>
              <InfoButton cysr_info={cysrConfig().i("asset","score","help")} cysr_info_link={cysrConfig().i("asset","score","help_link")} />
              <MDBox py={5} px={3} fullWidth>
                {cysrConfig().i("asset","score","title") && (
                  <MDTypography variant="h5">{cysrConfig().i("asset","score","title")}</MDTypography>
                )}
                {cysrConfig().i("asset","score","sub_title") && (
                  <MDTypography variant="button" color="text" fontWeight="regular">
                    {cysrConfig().i("asset","score","sub_title")}
                  </MDTypography>
                )}
                <MDBox mt={1.5} p={1}>
                  {views && views.ratingCurrent && views.ratingCurrent[0] && views.ratingCurrent[0].rating_val && <ScoreItem scoreValue={views.ratingCurrent[0].rating_val} textual={true} item_style="big" />}
                </MDBox>
                <MDBox p={3}>
                  <Grid container spacing={3}>
                    <Grid item my="auto" ml="auto">
                      <MDTypography variant="button"  color="text" fontWeight="regular">
                        <strong>created</strong> on <strong>{views && views.asset && views.asset.generalasset && views.asset.generalasset.mu_created_at && new Date(views.asset.generalasset.mu_created_at).toLocaleDateString("it-IT")}</strong>
                      </MDTypography>
                      <br />
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        <strong>updated</strong> on <strong>{views && views.ratingCurrent && views.ratingCurrent[0] && views.ratingCurrent[0].mu_updated_at && new Date(views.ratingCurrent[0].mu_updated_at).toLocaleDateString("it-IT")}</strong>
                      </MDTypography>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Card>
              <InfoButton cysr_info={cysrConfig().i("asset","progress","help")} cysr_info_link={cysrConfig().i("asset","progress","help_link")} />
              <MDBox py={5} px={3} fullWidth>
                {cysrConfig().i("asset","progress","title") && (
                  <MDTypography variant="h5">{cysrConfig().i("asset","progress","title")}</MDTypography>
                )}
                {cysrConfig().i("asset","progress","sub_title") && (
                  <MDTypography variant="button" color="text" fontWeight="regular">
                    {cysrConfig().i("asset","progress","sub_title")}
                  </MDTypography>
                )}
                <MDBox p={3}>
                {views && views.timeline && views.timeline.data ? (
                  <MDBox style={{ paddingTop: "1em", height: "129px"}}>
                    <LineChart
                      data={views.timeline.data}
                      options={views.timeline.options}
                    />
                  </MDBox>
                ) : (
                  <MDBox style={{ paddingTop: "1em", height: "129px"}}>
                    <MDTypography variant="button" color="text">
                      There is no timeline available for this asset yet.
                    </MDTypography>
                  </MDBox>
                )}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        {views.asset && views.asset.generalasset && views.asset.generalasset.generalasset_type && (
            <AssetTypeTypologyDetail asset={views.asset} />
        )}
        {
          /*
        }
        <Grid item xs={12}>
          <Card>
            <InfoButton cysr_info={cysrConfig().i("asset","related","help")} cysr_info_link={cysrConfig().i("asset","related","help_link")} />
            <MDBox py={5} px={3} fullWidth>
              {
                views ? <BubbleChartLabel data={[
                  {
                    "label": "Dataset 1",
                    "date": "2020-09-12T23:00:00.000Z",
                    "value": 8,
                    "gravity": 50
                  },
                  {
                    "label": "Dataset 1",
                    "date": "2022-10-04T23:00:00.000Z",
                    "value": 11,
                    "gravity": 15
                  },
                  {
                    "label": "Dataset 1",
                    "date": "2022-10-06T23:00:00.000Z",
                    "value": 22,
                    "gravity": 32
                  },
                  {
                    "label": "Dataset 1",
                    "date": "2022-10-01T23:00:00.000Z",
                    "value": 42,
                    "gravity": 53
                  },
                  {
                    "label": "Dataset 1",
                    "date": "2021-10-12T23:00:00.000Z",
                    "value": 32,
                    "gravity": 34
                  },
                  {
                    "label": "Dataset 2",
                    "date": "2020-09-12T23:00:00.000Z",
                    "value": 18,
                    "gravity": 23
                  },
                  {
                    "label": "Dataset 2",
                    "date": "2022-10-04T23:00:00.000Z",
                    "value": 12,
                    "gravity": 31
                  },
                  {
                    "label": "Dataset 2",
                    "date": "2022-10-06T23:00:00.000Z",
                    "value": 8,
                    "gravity": 13
                  },
                  {
                    "label": "Dataset 2",
                    "date": "2022-10-01T23:00:00.000Z",
                    "value": 13,
                    "gravity": 55
                  },
                  {
                    "label": "Dataset 2",
                    "date": "2021-10-12T23:00:00.000Z",
                    "value": 0,
                    "gravity": 12
                  },
                  {
                    "label": "Dataset 3",
                    "date": "2020-09-12T23:00:00.000Z",
                    "value": 12,
                    "gravity": 32
                  },
                  {
                    "label": "Dataset 3",
                    "date": "2022-10-04T23:00:00.000Z",
                    "value": 2,
                    "gravity": 12
                  },
                  {
                    "label": "Dataset 3",
                    "date": "2022-10-06T23:00:00.000Z",
                    "value": 3,
                    "gravity": 18
                  },
                  {
                    "label": "Dataset 3",
                    "date": "2022-10-01T23:00:00.000Z",
                    "value": 14,
                    "gravity": 21
                  },
                  {
                    "label": "Dataset 3",
                    "date": "2021-10-12T23:00:00.000Z",
                    "value": 4,
                    "gravity": 22
                  },
                  {
                    "label": "Dataset 4",
                    "date": "2020-09-12T23:00:00.000Z",
                    "value": 22,
                    "gravity": 32
                  },
                  {
                    "label": "Dataset 4",
                    "date": "2022-10-04T23:00:00.000Z",
                    "value": 4,
                    "gravity": 32
                  },
                  {
                    "label": "Dataset 4",
                    "date": "2022-10-06T23:00:00.000Z",
                    "value": 9,
                    "gravity": 43
                  },
                  {
                    "label": "Dataset 4",
                    "date": "2022-10-01T23:00:00.000Z",
                    "value": 24,
                    "gravity": 43
                  },
                  {
                    "label": "Dataset 4",
                    "date": "2022-01-01T23:00:00.000Z",
                    "value": 1,
                    "gravity": 100
                  },
                  {
                    "label": "Dataset 4",
                    "date": "2022-10-01T23:00:00.000Z",
                    "value": 100,
                    "gravity": 100
                  },
                  {
                    "label": "Dataset 4",
                    "date": "2021-10-12T23:00:00.000Z",
                    "value": 7,
                    "gravity": 21
                  }
                ]} /> : <p>No bubble chart</p>
              }
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <InfoButton cysr_info={cysrConfig().i("asset","related","help")} cysr_info_link={cysrConfig().i("asset","related","help_link")} />
            <MDBox py={5} px={3} fullWidth>
              {
                views ? <BubbleChartValue data={[
                  {
                    "group": "Dataset 1",
                    "date": "2020-09-12T23:00:00.000Z",
                    "value": 8,
                    "gravity": 50
                  },
                  {
                    "group": "Dataset 1",
                    "date": "2022-10-04T23:00:00.000Z",
                    "value": 11,
                    "gravity": 15
                  },
                  {
                    "group": "Dataset 1",
                    "date": "2022-10-06T23:00:00.000Z",
                    "value": 22,
                    "gravity": 32
                  },
                  {
                    "group": "Dataset 1",
                    "date": "2022-10-01T23:00:00.000Z",
                    "value": 42,
                    "gravity": 53
                  },
                  {
                    "group": "Dataset 1",
                    "date": "2021-10-12T23:00:00.000Z",
                    "value": 32,
                    "gravity": 34
                  },
                  {
                    "group": "Dataset 2",
                    "date": "2020-09-12T23:00:00.000Z",
                    "value": 18,
                    "gravity": 23
                  },
                  {
                    "group": "Dataset 2",
                    "date": "2022-10-04T23:00:00.000Z",
                    "value": 12,
                    "gravity": 31
                  },
                  {
                    "group": "Dataset 2",
                    "date": "2022-10-06T23:00:00.000Z",
                    "value": 8,
                    "gravity": 13
                  },
                  {
                    "group": "Dataset 2",
                    "date": "2022-10-01T23:00:00.000Z",
                    "value": 13,
                    "gravity": 55
                  },
                  {
                    "group": "Dataset 2",
                    "date": "2021-10-12T23:00:00.000Z",
                    "value": 0,
                    "gravity": 12
                  },
                  {
                    "group": "Dataset 3",
                    "date": "2020-09-12T23:00:00.000Z",
                    "value": 12,
                    "gravity": 32
                  },
                  {
                    "group": "Dataset 3",
                    "date": "2022-10-04T23:00:00.000Z",
                    "value": 2,
                    "gravity": 12
                  },
                  {
                    "group": "Dataset 3",
                    "date": "2022-10-06T23:00:00.000Z",
                    "value": 3,
                    "gravity": 18
                  },
                  {
                    "group": "Dataset 3",
                    "date": "2022-10-01T23:00:00.000Z",
                    "value": 14,
                    "gravity": 21
                  },
                  {
                    "group": "Dataset 3",
                    "date": "2021-10-12T23:00:00.000Z",
                    "value": 4,
                    "gravity": 22
                  },
                  {
                    "group": "Dataset 4",
                    "date": "2020-09-12T23:00:00.000Z",
                    "value": 22,
                    "gravity": 32
                  },
                  {
                    "group": "Dataset 4",
                    "date": "2022-10-04T23:00:00.000Z",
                    "value": 4,
                    "gravity": 32
                  },
                  {
                    "group": "Dataset 4",
                    "date": "2022-10-06T23:00:00.000Z",
                    "value": 9,
                    "gravity": 43
                  },
                  {
                    "group": "Dataset 4",
                    "date": "2022-10-01T23:00:00.000Z",
                    "value": 24,
                    "gravity": 43
                  },
                  {
                    "group": "Dataset 4",
                    "date": "2022-01-01T23:00:00.000Z",
                    "value": 1,
                    "gravity": 100
                  },
                  {
                    "group": "Dataset 4",
                    "date": "2022-10-01T23:00:00.000Z",
                    "value": 100,
                    "gravity": 100
                  },
                  {
                    "group": "Dataset 4",
                    "date": "2021-10-12T23:00:00.000Z",
                    "value": 7,
                    "gravity": 21
                  }
                ]} /> : <p>No bubble chart</p>
              }
            </MDBox>
          </Card>
        </Grid>
        {
          */
        }
        <Grid item xs={12}>
          <Card>
            <InfoButton cysr_info={cysrConfig().i("asset","related","help")} cysr_info_link={cysrConfig().i("asset","related","help_link")} />
            <MDBox py={5} px={3} fullWidth>
              {relatedData(views)}
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <InfoButton cysr_info={cysrConfig().i("asset","checks","help")} cysr_info_link={cysrConfig().i("asset","checks","help_link")} />
            <MDBox py={5} px={3} fullWidth>
              {views && views.ratingdetails && views.ratingdetails.length > 0 ? (
                <DataTable  entriesPerPage={{ defaultValue: 50, entries: [25, 50, 100, 200, 400] }} table={checksData(views)} canSearch />
              ) : (
                <MDTypography variant="button" color="text">
                  There are no checks related to this asset yet.
                </MDTypography>
              )}
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};
export default App;
// me
/*
function DataTables() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Datatable Search
            </MDTypography>
            <MDTypography variant="button" color="text">
              A lightweight, extendable, dependency-free javascript HTML table plugin.
            </MDTypography>
          </MDBox>
          <DataTable table={dataTableData} canSearch />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DataTables;
*/
/* eslint-disable */
