/* eslint-disable */
// prettier-ignore
"use client";

import cysrConfig, { process } from "layouts/cysr/config";
import MDButton from "components/MDButton";
import { Badge } from "layouts/cysr/components/ui/badge";
import { DataTableRowButton } from "./data-table-row-button-go";

import { Link } from "react-router-dom";
import { format } from "date-fns";


function rowCheck(x) {
    return <div className="flex flex-col lg:flex-row mt-1">
        <span className="w-16 font-medium text-sm">{x.label}</span>
        {x.value ?
            <p className="w-16 font-light text-xs opacity-60">Value: <span className="lg:ml-2 lg:mt-2 font-medium text-base">
                {x.value}
            </span>
            </p>
            : null
        }
        {
            /*
            <span className="lg:ml-2 lg:mt-2 font-medium text-base">
                {x.descr}
            </span>
            */
        }
        <p className="w-16 font-light text-xs opacity-60">Weight: <span className="lg:ml-2 lg:mt-2 font-medium text-base">
            {x.weight && (!isNaN(x.weight)) ? (x.weight / 100).toFixed(4) : x.weight}
        </span>
        </p>
    </div>;
}

export function DataTableExpandedContent({
    row,
    selectedDays
}) {
    let accountUUID = window.location.pathname.substring(
        window.location.pathname.indexOf("/company/") + "/company/".length,
        window.location.pathname.lastIndexOf("/")
    );
    if (localStorage.getItem("cysr_useraccountrole_selected")) {
        accountUUID = localStorage.getItem("cysr_useraccountrole_selected");
    }
    return (
        <div className="w-full flex flex-col md:flex-row gap-2">
            <div className="w-full">
                <p className="w-full block my-2 text-turquoise-500 dark:text-turquoise-400">{
                    selectedDays && selectedDays[0]
                        ? format(new Date(selectedDays[0].toString()), process.env.NEXT_PUBLIC_DATE_FORMAT
                            ? process.env.NEXT_PUBLIC_DATE_FORMAT
                            : "dd/MM/yyyy")
                        : "unselected"
                }</p>
                <div className="flex flex-col gap-4">
                    {
                        (row.original.first_checks && row.original.first_checks.length > 0) ? row.original.first_checks
                            .map((x => {
                                return (
                                    rowCheck(x)
                                )
                            }))
                            : `no checks available for this asset as of ${selectedDays && selectedDays[0]
                                ? format(new Date(selectedDays[0].toString()), process.env.NEXT_PUBLIC_DATE_FORMAT
                                    ? process.env.NEXT_PUBLIC_DATE_FORMAT
                                    : "dd/MM/yyyy")
                                : "unselected"
                            }`

                    }
                </div>
            </div>
            <div className="w-full">
                <p className="w-full block my-2 text-turquoise-500 dark:text-turquoise-400">{
                    selectedDays && selectedDays[1]
                        ? format(new Date(selectedDays[1].toString()), process.env.NEXT_PUBLIC_DATE_FORMAT
                            ? process.env.NEXT_PUBLIC_DATE_FORMAT
                            : "dd/MM/yyyy")
                        : "unselected"
                }</p>
                <div className="flex flex-col gap-4">
                    {
                        (row.original.second_checks && row.original.second_checks.length > 0) ? row.original.second_checks
                            .map((x => {
                                return (
                                    rowCheck(x)
                                )
                            }))
                            : `no checks available for this asset as of ${selectedDays && selectedDays[1]
                                ? format(new Date(selectedDays[1].toString()), process.env.NEXT_PUBLIC_DATE_FORMAT
                                    ? process.env.NEXT_PUBLIC_DATE_FORMAT
                                    : "dd/MM/yyyy")
                                : "unselected"
                            }`
                    }
                </div>
            </div>
        </div >
    );
}

