
/* eslint-disable */
// prettier-ignore
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Group } from "@visx/group";
import { Tree, hierarchy } from "@visx/hierarchy";
// import { HierarchyPointNode } from "@visx/hierarchy/lib/types";
import { LinkVerticalStep } from "@visx/shape";
import { LinearGradient } from "@visx/gradient";
import { toast } from "sonner"
import { valueFormatter } from "..";
const minWidthForFullNames = {
    parentNode: 800,
    // childrenNode: 1720,
    childrenNode: 800,
};
/*
{
    label: "Calculated",
    color: "#5C959F"
},
{
    label: "Supplied",
    color: "#AED2D6"
},
{
    label: "Not required",
    color: "#394444"
}
*/
// const peach = "#fd9b93";

const peach = "#0F6066";
// const transparentPeach = "rgba(253,155,147,.25)";
// const transparentPeach = "rgb(98,72,71)";
const transparentPeach = "#0F6066";

const pink = "#fe6e9e";
// const red = "rgb(239,68,68)";
const red = "#AED2D6";

const gray = "#394444";
const transparentGray = "#394444";


// const blue = "#03c0dc";
const blue = "#0F6066";
// const transparentBlue = "rgba(3,192,220,.25)";
// const transparentBlue = "rgb(38,82,92)";
const transparentBlue = "#0F6066";

// const green = "#26deb0";
const green = "#5C959F";
// const transparentGreen = "rgba(44,155,126,.25)";
// const transparentGreen = "rgb(40,72,65)";
const transparentGreen = "#5C959F";

const plum = "#71248e";
const lightpurple = "#374469";
const white = "#ffffff";
const letterCoefficient = 4.5
// export const background = "#272b4d";
// export const background = "#111827";

/*
interface TreeNode {
    name: string;
    shortName?: string;
    children?: this[];
}

type HierarchyNode = HierarchyPointNode<TreeNode>;

const rawTree: TreeNode = {
*/

/*
export type TreeProps = {
    width: number;
    height: number;
    margin?: { top: number; right: number; bottom: number; left: number };
};
*/

function getExplanation(shortName, euroValue = null) {
    switch (shortName) {
        case "TEF":
            return euroValue ? false : "The probability that a Threat Actor will act against an asset in the scenario's time frame (1 year).";
        case "TC":
            return euroValue ? false : "A percentage that represents the capabilities of cybercriminal groups, where a Threat Capability of 50% indicates that cybercriminal groups can attack with success half of the companies.";
        case "CS":
            return euroValue ? false : "Percentage of resistance to threats.";
        case "RS":
            return euroValue ? false : "Percentage of resistance to threats.";
        case "PL":
            return euroValue ? true : "Loss directly linked to the event.";
        case "SLEF":
            return euroValue ? false : "Probability that secondary losses may occur.";
        case "SL":
            return euroValue ? true : "Losses that can occur as a reaction to the event.";
        case "SLM":
            return euroValue ? true : "Losses that can occur as a reaction to the event.";
        case "LEF":
            return euroValue ? false : "The probability that a harmful event or loss occurs in the scenario's time frame (1 year).";
        case "V":
            return euroValue ? false : "Vulnerabilities are considered together with threats and assets to determine the frequency and potential impact of harmful events.";
        case "LM":
            return euroValue ? true : "Refers to the measurement of financial losses associated with a harmful event.";
        case "A":
            return euroValue ? true : "Refers to the likelihood that an attacker (or threat) will perform a harmful action that could cause financial loss to an organization.";
        case "C":
            return euroValue ? true : "Refers to the frequency with which contact occurs between a threat (or attacker) and an asset.";
        case "R":
            return euroValue ? true : "The probability of how many times a harmful event can occur and, if it occurs, how much we will lose in monetary terms.";
        default:
            return "";
    }
}

function getNodeMinAvgMax(x, withAverage = true) {
    return getExplanation(x.shortName, true)
        ? <>
            {valueFormatter(x.minValue)} {withAverage && x.averageValue ? <>- <strong className="text-base">{valueFormatter(x.averageValue)}</strong></> : null} - {valueFormatter(x.maxValue)}
        </>
        : x.name === "Vulnerability"
            ?
            <strong className="text-base">{`${(x.averageValue * 100).toFixed(2)}%`}</strong>
            :
            <>
                {`${(x.minValue * 100).toFixed(2)}%`} {withAverage && x.averageValue ? <>- <strong className="text-base">{`${(x.averageValue * 100).toFixed(2)}%`}</strong></> : null} - {`${(x.maxValue * 100).toFixed(2)}%`}
            </>
}

export default function ChartTree({
    background,
    textColor,
    width,
    height,
    margin = defaultMargin,
    rawTree,
    // valueFormatter
    // }: TreeProps) {
}) {
    // function RootNode({ node }: { node: HierarchyNode }) {
    function RootNode({ node }) {
        const width = 164; // 40 + 8;
        const height = 34;
        const centerX = -width / 2;
        const centerY = -height / 2;
        return (
            // <Group top={node.x} left={node.y}>
            <Group top={node.y} left={node.x}>
                <rect
                    height={
                        height * 2.25
                    }
                    width={width}
                    y={centerY}
                    x={centerX}
                    // fill={background}
                    fill={transparentPeach}
                    stroke={peach}
                    strokeWidth={1}
                    // strokeDasharray="2,2"
                    strokeOpacity={0.6}
                    // rx={10}
                    rx={5}
                    onClick={() => {
                        toast(
                            <div>
                                <p>
                                    {`${node.data.name} (${node.data.shortName}) - ${getExplanation(node.data.shortName)}`}
                                </p>
                                {getNodeMinAvgMax(node.data)}
                            </div>);
                    }}
                />
                {
                    // <circle r={16} fill="url('#lg')" />
                }
                <text
                    dy=".33em"
                    fontSize={16}
                    fontFamily="Ubuntu"
                    textAnchor="middle"
                    style={{ pointerEvents: "none" }}
                    // fill={peach}
                    fill={white}
                >
                    {node.data.name}
                    <tspan dy="1.62em" x="0" textAnchor="middle" fontSize={16}>
                        {
                            // node.data.minValue > 1 ? valueFormatter(node.data.minValue) : `${(node.data.minValue * 100).toFixed(2)}%`} - <tspan className='font-medium' fontWeight="medium" fontSize={18} fill={red}>{getExplanation(node.data.shortName, true) ? valueFormatter(node.data.averageValue) : `${(node.data.averageValue * 100).toFixed(2)}%`}</tspan> - {node.data.maxValue > 1 ? valueFormatter(node.data.maxValue) : `${(node.data.maxValue * 100).toFixed(2)}%`
                        }
                        <tspan className='font-medium' fontWeight="medium" fontSize={24} fill={red}>{getExplanation(node.data.shortName, true) ? valueFormatter(node.data.averageValue) : `${(node.data.averageValue * 100).toFixed(2)}%`}</tspan>
                    </tspan>
                    <tspan dy="1.2em" x="0" textAnchor="middle" fontSize={16}>
                        <tspan>
                            {getNodeMinAvgMax(node.data, false)}
                        </tspan>
                    </tspan>
                </text>
            </Group>
        );
    }

    // function ParentNode({ node }: { node: HierarchyNode }) {
    function ParentNode({ node }) {
        /*
        // calculate width s
        // const background = "#f9f9f9";
        // const blue = "#3182bd";
        // const white = "#ffffff";

        // const textRef = useRef < SVGTextElement | null > (null);
        const textRef = useRef(null);

        // const [width, setWidth] = useState < number > (40);
        const defaultWidth = (
            (window.innerWidth > minWidthForFullNames.parentNode
                ? node.data.name.length
                : node.data.shortName.length
            ) * letterCoefficient) + 10
        const [width, setWidth] = useState(defaultWidth);

        useEffect(() => {
            if (textRef.current) {
                const bbox = textRef.current.getBBox();
                const textWidth = bbox.width;
                // You may want to add some padding or margin to the calculated width
                const padding = 5;
                const newWidth = textWidth + padding * 2;

                // Update the width using useState
                if (newWidth != defaultWidth) {
                    setWidth(newWidth);
                }
            }
            // }, [node.data.name]);
        }, [window.innerWidth]);
        // calculate width e
        // const width = node.data.width || 40;
        */
        const width = window.innerWidth > minWidthForFullNames.parentNode
            ? 164 : 80;
        const height = 34;
        const centerX = -width / 2;
        const centerY = -height / 2;

        return (
            // <Group top={node.x} left={node.y}>
            <Group top={node.y} left={node.x}>
                <rect
                    height={
                        height * (
                            window.innerWidth > minWidthForFullNames.parentNode
                                ? 2.25
                                : 1.85//1.24
                        )
                    }
                    width={width}
                    y={centerY}
                    x={centerX}
                    rx={5}
                    // fill={background}
                    fill={transparentBlue}
                    stroke={blue}
                    strokeWidth={1}
                    onClick={() => {
                        toast(
                            <div>
                                {node.data.name}
                                <p>
                                    {`${node.data.name} (${node.data.shortName}) - ${getExplanation(node.data.shortName)}`}
                                </p>
                                {node.data.name === "Vulnerability"
                                    ? (
                                        <strong className="text-base">{getExplanation(node.data.shortName, true) ? valueFormatter(node.data.averageValue) : `${(node.data.averageValue * 100).toFixed(2)}%`}</strong>
                                    ) : (
                                        <>
                                            {
                                                getExplanation(node.data.shortName, true)
                                                    ? valueFormatter(node.data.minValue)
                                                    : `${(node.data.minValue * 100).toFixed(2)}%`
                                            } - <strong className="text-base">{
                                                getExplanation(node.data.shortName, true)
                                                    ? valueFormatter(node.data.averageValue)
                                                    : `${(node.data.averageValue * 100).toFixed(2)}%`
                                            }</strong> - {
                                                getExplanation(node.data.shortName, true)
                                                    ? valueFormatter(node.data.maxValue)
                                                    : `${(node.data.maxValue * 100).toFixed(2)}%`
                                            }
                                        </>
                                    )
                                }
                            </div>);
                    }}
                />
                <text
                    // ref={textRef}
                    dy=".53em"
                    fontSize={13}
                    fontFamily="Ubuntu"
                    textAnchor="middle"
                    style={{ pointerEvents: "none" }}
                    fill={textColor}
                >
                    {
                        window.innerWidth > minWidthForFullNames.parentNode
                            ? <>
                                {node.data.name}
                                {
                                    /*
                                }
                                <tspan dy="1.36em" x="0" textAnchor="middle" fontSize={16}>
                                    {node.data.minValue > 1 ? valueFormatter(node.data.minValue) : `${(node.data.minValue * 100).toFixed(2)}%`} - <tspan className='font-medium' fontWeight="medium" fontSize={18} fill={red}>{getExplanation(node.data.shortName, true) ? valueFormatter(node.data.averageValue) : `${(node.data.averageValue * 100).toFixed(2)}%`}</tspan> - {node.data.maxValue > 1 ? valueFormatter(node.data.maxValue) : `${(node.data.maxValue * 100).toFixed(2)}%`}
                                </tspan>
                                {
                                    */
                                }
                                <tspan dy="1.62em" x="0" textAnchor="middle" fontSize={16}>
                                    <tspan className='font-medium' fontWeight="medium" fontSize={24} fill={red}>{getExplanation(node.data.shortName, true) ? valueFormatter(node.data.averageValue) : `${(node.data.averageValue * 100).toFixed(2)}%`}</tspan>
                                </tspan>
                                <tspan dy="1.2em" x="0" textAnchor="middle" fontSize={16}>
                                    <tspan>
                                        {getNodeMinAvgMax(node.data, false)}
                                    </tspan>
                                </tspan>
                            </>
                            : <>
                                {node.data.shortName}
                                <tspan dy="1.62em" x="0" textAnchor="middle" fontSize={12}>
                                    <tspan className='font-medium' fontWeight="medium" fontSize={14} fill={red}>{getExplanation(node.data.shortName, true) ? valueFormatter(node.data.averageValue) : `${(node.data.averageValue * 100).toFixed(2)}%`}</tspan>
                                </tspan>
                                <tspan dy="1.2em" x="0" textAnchor="middle" fontSize={9}>
                                    <tspan>
                                        {getNodeMinAvgMax(node.data, false)}
                                    </tspan>
                                </tspan>
                            </>
                    }
                </text>
            </Group>
        );
    }

    /** Handles rendering Root, Parent, and other Nodes. */
    // function Node({ node }: { node: HierarchyNode }) {
    function Node({ node }) {
        /*
        // calculate width s
        // const background = "#f9f9f9";
        // const blue = "#3182bd";
        // const white = "#ffffff";

        // const textRef = useRef < SVGTextElement | null > (null);

        const textRef = useRef(null);

        // const [width, setWidth] = useState < number > (40);
        const defaultWidth = (
            (window.innerWidth > minWidthForFullNames.childrenNode
                ? node.data.name.length
                : node.data.shortName.length
            ) * letterCoefficient) + 10
        const [width, setWidth] = useState(defaultWidth);

        useEffect(() => {
            if (textRef.current) {
                const bbox = textRef.current.getBBox();
                const textWidth = bbox.width;
                // You may want to add some padding or margin to the calculated width
                const padding = 5;
                const newWidth = textWidth + padding * 2;

                // Update the width using useState
                if (newWidth != defaultWidth) {
                    setWidth(newWidth);
                }
            }
            // }, [node.data.name]);
        }, [window.innerWidth]);
        // calculate width e
        // const width = node.data.width || 40;
        */
        const width = window.innerWidth > minWidthForFullNames.childrenNode
            ? (node.data.maxValue === 0 ? 120 : 164) : (node.data.maxValue === 0 ? 60 : 80);
        const height = node.data.maxValue === 0 ? 28 : 34;
        const centerX = -width / 2;
        const centerY = -height / 2;
        const isRoot = node.depth === 0;
        const isParent = node.children && ((node.children && node.children.some((x) => x.data?.maxValue === 0)) === false);

        if (isRoot) return <RootNode node={node} />;
        if (isParent) return <ParentNode node={node} />;

        return (
            // <Group top={node.x} left={node.y}>
            <Group top={node.y} left={node.x}>
                <rect
                    height={
                        height * (
                            window.innerWidth > minWidthForFullNames.childrenNode
                                ? 2.25
                                : 1.85//1.24
                        )
                    }
                    width={width}
                    y={centerY}
                    x={centerX}
                    // fill={background}
                    fill={node.data.maxValue === 0 ? transparentGray : transparentGreen}
                    stroke={node.data.maxValue === 0 ? gray : green}
                    strokeWidth={1}
                    strokeDasharray="2,2"
                    strokeOpacity={0.6}
                    // rx={10}
                    rx={5}
                    onClick={() => {
                        toast(
                            <div>
                                <p>
                                    {`${node.data.name} (${node.data.shortName}) - ${getExplanation(node.data.shortName)}`}
                                </p>
                                {node.data.maxValue === 0
                                    ? null
                                    : <>
                                        {
                                            getExplanation(node.data.shortName, true)
                                                ? valueFormatter(node.data.minValue)
                                                : `${(node.data.minValue * 100).toFixed(2)}%`} - <strong className="text-base">{node.data.averageValue > 1
                                                    ? valueFormatter(node.data.averageValue)
                                                    : `${(node.data.averageValue * 100).toFixed(2)}%`}</strong> - {
                                            getExplanation(node.data.shortName, true)
                                                ? valueFormatter(node.data.maxValue)
                                                : `${(node.data.maxValue * 100).toFixed(2)}%`
                                        }
                                    </>
                                }
                            </div>
                        );
                    }}
                />
                <text
                    // ref={textRef}
                    dy=
                    {node.data.maxValue === 0 ? window.innerWidth > minWidthForFullNames.childrenNode ? "2em" : "1.53em" : ".53em"}
                    fontSize={10.5}
                    fontFamily="Ubuntu"
                    textAnchor="middle"
                    fill={textColor}
                    style={{ pointerEvents: "none" }}
                >
                    {window.innerWidth > minWidthForFullNames.childrenNode
                        ? <>
                            {node.data.name}
                            {
                                /*
                            }
                            <tspan dy="1.36em" x="0" textAnchor="middle" fontSize={16}>
                                {node.data.minValue > 1 ? valueFormatter(node.data.minValue) : `${(node.data.minValue * 100).toFixed(2)}%`} - <tspan className='font-medium' fontWeight="medium" fontSize={18} fill={red}>{getExplanation(node.data.shortName, true) ? valueFormatter(node.data.averageValue) : `${(node.data.averageValue * 100).toFixed(2)}%`}</tspan> - {node.data.maxValue > 1 ? valueFormatter(node.data.maxValue) : `${(node.data.maxValue * 100).toFixed(2)}%`}
                            </tspan>
                            {
                                */
                            }
                            {node.data.maxValue === 0 ? null : <>
                                <tspan dy="1.62em" x="0" textAnchor="middle" fontSize={16}>
                                    <tspan className='font-medium' fontWeight="medium" fontSize={24} fill={red}>{getExplanation(node.data.shortName, true) ? valueFormatter(node.data.averageValue) : `${(node.data.averageValue * 100).toFixed(2)}%`}</tspan>
                                </tspan>
                                <tspan dy="1.2em" x="0" textAnchor="middle" fontSize={16}>
                                    <tspan>
                                        {getNodeMinAvgMax(node.data, false)}
                                    </tspan>
                                </tspan></>}
                        </>
                        : <>
                            {node.data.shortName}
                            {node.data.maxValue === 0 ? null : <>
                                <tspan dy="1.62em" x="0" textAnchor="middle" fontSize={12}>
                                    <tspan className='font-medium' fontWeight="medium" fontSize={14} fill={red}>{getExplanation(node.data.shortName, true) ? valueFormatter(node.data.averageValue) : `${(node.data.averageValue * 100).toFixed(2)}%`}</tspan>
                                </tspan>
                                <tspan dy="1.2em" x="0" textAnchor="middle" fontSize={9}>
                                    <tspan>
                                        {getNodeMinAvgMax(node.data, false)}
                                    </tspan>
                                </tspan></>}
                        </>}
                </text>
            </Group>
        );
    }

    const data = useMemo(() => hierarchy(rawTree), [rawTree]);
    const yMax = height - margin.top - margin.bottom - 24;
    const xMax = width - margin.left - margin.right;

    return width < 10 ? null : (
        <svg width={width} height={height}>
            <LinearGradient id="lg" from={peach} to={pink} />
            <rect width={width} height={height} rx={14} fill={background} />
            {
                // <Tree<TreeNode> root={data} size={[yMax, xMax]}>
            }
            {
                // <Tree<TreeNode>
            }
            <Tree
                root={data}
                size={[xMax, yMax]}
                separation={(a, b) => (a.parent === b.parent ? 1 : 1) / a.depth}
            >
                {(tree) => (
                    <Group top={margin.top} left={margin.left}>
                        {tree.links().map((link, i) => (
                            // <LinkHorizontal
                            <LinkVerticalStep
                                key={`link-${i}`}
                                data={link}
                                stroke={lightpurple}
                                strokeWidth="1"
                                fill="none"
                            />
                        ))}
                        {tree.descendants().map((node, i) => (
                            <Node key={`node-${i}`} node={node} />
                        ))}
                    </Group>
                )}
            </Tree>
        </svg>
    );
}
