/* eslint-disable */
// prettier-ignore
import React from 'react'
import ReactMarkdown from 'react-markdown'
import MDBox from "components/MDBox";
import './RenderMarkdown.css';

export default function RenderMarkdown(props) {
    return (
        <div className="RenderMarkdown">
            <MDBox color="text">
                <ReactMarkdown components={props.components}>{props.children}</ReactMarkdown>
            </MDBox>
        </div>
    )
}
/* eslint-disable */
// prettier-ignore