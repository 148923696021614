/* eslint-disable */
"use client";
import React from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Bubble } from 'react-chartjs-2';

ChartJS.register(
    LinearScale,
    PointElement,
    Tooltip,
    Legend
);

export const options = {
    clip: 100,
    maintainAspectRatio: false,
    aspectRatio: "32/9",
    scales: {
        y: {
            min: 0,
            max: 50,
            // display: false,
            ticks: {
                stepsSize: 10,
                callback: function (label, index, labels) {
                    let text = false;
                    if (label === 40) {
                        text = "New Botnets"
                    } else if (label === 30) {
                        text = "Acknowledged Botnets"
                    } else if (label === 20) {
                        text = "New Leaks"
                    } else if (label === 10) {
                        text = "Acknowledged Leaks"
                    }
                    return text ? text : null
                    // return label / 1000 + 'k';
                }
            },
        },
        x: {
            ticks: {
                font: { size: 14, color: "#cccccc" },
            },
            type: 'time',
            time: {
                parser: process.env.NEXT_PUBLIC_DATE_FORMAT
                    ? process.env.NEXT_PUBLIC_DATE_FORMAT
                    : "dd/MM/yyyy",
                // unit: "quarter",
                tooltipFormat: process.env.NEXT_PUBLIC_DATE_FORMAT
                    ? process.env.NEXT_PUBLIC_DATE_FORMAT
                    : "dd/MM/yyyy",
                unit: 'quarter'
            }
        }
    },
};

export default function CYSRBubbles({ data, maxHeight = "400px", minHeight = "400px" }) {
    //console.warn("bubdata")
    //console.warn(data)
    return (
        <div
            style={{
                position: "relative",
                maxHeight: maxHeight,
                minHeight: minHeight,
                height: "100%",
                width: "100%",
            }}
        >
            <Bubble options={options} data={data} />
        </div>
    )
}
