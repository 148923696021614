/* eslint-disable */
// prettier-ignore
"use client";

import { cn } from "lib/utils";
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "@radix-ui/react-collapsible";
import { Icons } from "./Icons";
import { useState } from "react";

export function ExpandableList({
    children,
    previewChildren,
    className,
    ...props
}) {
    const [open, setOpen] = useState(false);

    return (
        <Collapsible open={open} onOpenChange={setOpen}>
            <CollapsibleContent
                className={cn(
                    "overflow-y-auto px-2 border-l border-r border-dashed border-white/10",
                    className
                )}
                {...props}
            >
                {children}
            </CollapsibleContent>
            <CollapsibleTrigger className="w-full px-2 relative">
                {open === false ? previewChildren : null}
                <button
                    size="sm"
                    variant="link"
                    className={`rounded-t-none border-l border-r border-b border-dashed border-white/10 pb-1 z-10 to-white/10 bg-gradient-to-b from-transparent w-full text-right justify-end flex-col absolute left-0 right-0 items-center${open ? " -bottom-1" : " -bottom-1.5"
                        }`}
                >
                    {/*
            }
          view{" "}
          {process.env.NEXT_PUBLIC_STRING_TERM_50_PLURAL
            ? process.env.NEXT_PUBLIC_STRING_TERM_50_PLURAL.toLowerCase()
            : "entries"}
            {
                */}
                    {open ? (
                        <Icons.collapse className="h-3.5 w-3.5" />
                    ) : (
                        <Icons.expand className="h-3.5 w-3.5" />
                    )}
                </button>
            </CollapsibleTrigger>
        </Collapsible>
    );
}
