/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { getCompany } from "db";
import { useEffect, useState } from 'react';

function Breadcrumbs({ icon, title, route, light }) {
  const [isCompany, setIsCompany] = useState(false);
  const [companyName, setCompanyName] = useState('Company');
  const [isInProfile, setIsInProfile] = useState(false);
  const routes = route.slice(0, -1);
  const isInProfileManageSubscription =
    routes.indexOf('manage-subscriptions') > -1;
  const companyID = routes[1];

  useEffect(() => {
    const fetchData = async () => {
      const firstRoute = routes[0];
      setIsInProfile(route.indexOf('manage-subscriptions') > -1);

      if (firstRoute === 'company') {
        try {
          const element = await getCompany(companyID);
          if (element) {
            setIsCompany(true);
            const cName = element.account_name;
            setCompanyName(cName);
            const word = title.replace('-', ' ');
            const capitalized =
              word.charAt(0).toUpperCase() + word.slice(1);
            const pageTitle = `${cName} / ${capitalized}`;
            document.title = `${pageTitle} - ${document.title.split(' - ')[1]
              ? document.title.split(' - ')[1]
              : document.title
              }`;
          }
        } catch (error) {
          console.error('Error fetching company data:', error);
        }
      }
    };

    fetchData();
  }, [route, title]); // Add dependencies if needed
  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}
      >
        <Link to="/">
          <MDTypography
            component="span"
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <Icon>{icon}</Icon>
          </MDTypography>
        </Link>
        {isCompany && (
          <Link to={`/company/${companyID}/overview`}>
            <MDTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
              {companyName || "Company"}
            </MDTypography>
          </Link>
        )
        }
        {isInProfile && (
          <Link to={"/cysr/profile"}>
            <MDTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
              Profile
            </MDTypography>
          </Link>
        )
        }
        {isInProfile && isInProfileManageSubscription && (
          <Link to={"/cysr/profile/manage-subscriptions"}>
            <MDTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
              Manage Subscriptions
            </MDTypography>
          </Link>
        )
        }
        <MDTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{ lineHeight: 0 }}
        >
          {title.replace("-", " ")}
        </MDTypography>
      </MuiBreadcrumbs>
      {
        /*
      }
      <MDTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        color={light ? "white" : "dark"}
        noWrap
      >
        {companyLongName || title.replace("-", " ")}
      </MDTypography>
      {
        */
      }
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;

/* eslint-disable */