/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
// prettier-ignore
import { Base64 } from 'js-base64';
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
import InfoButton from "layouts/cysr/components/infoButton";
// import Footer from "examples/Footer";
import Loader from "layouts/cysr/components/Loader";
import Autocomplete from "@mui/material/Autocomplete";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// calendar
import { add, format, isDate } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

// import KeyboardArrowDownIcon from '@mui/icons-material/Add';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Card from "@mui/material/Card";

import CysrTableSubscriptions from "layouts/cysr/components/CysrTable/subscriptions";

import { useMaterialUIController } from "context";
import { useSnackbar } from 'notistack';
// import DataTable from "layouts/cysr/components/DataTable";
// import subscriptionsData from "layouts/cysr/subscriptions/subscriptionsData";

// import { LineChart } from "@carbon/charts-react";
// import "@carbon/charts/styles-g100.css";


import cysrConfig from "layouts/cysr/config";
import CysrAutocomplete from "layouts/cysr/components/CysrAutocomplete";
import CysrStepper from "layouts/cysr/components/CysrStepper";

function Subscriptions() {
  if (localStorage.getItem("cysr_useraccountrole_selected")) {
    localStorage.removeItem("cysr_useraccountrole_selected")
    window.location.reload()
  }
  const [controller] = useMaterialUIController();
  //snackBar s
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // add action to an individual snackbar
  const action = snackbarId => (
    <>
      <MDButton onClick={() => { closeSnackbar(snackbarId) }} style={cysrConfig().button_style} size="medium">
        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().close.icon.svg }} />
        Dismiss
      </MDButton>
    </>
  );
  //snackBar e
  const { darkMode } = controller;

  const [canManageBillingInfo, setCanManageBillingInfo] = useState(false);
  const [canManageSubscriptions, setCanManageSubscriptions] = useState(false);

  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  // [{"subscriptiontype_uuid":02301302193, "SME":,...]
  const [subscriptionType, setSubscriptionType] = useState("");
  const [subscriptionTypeDescription, setSubscriptionTypeDescription] = useState("");
  const [subscriptionExpiration, setSubscriptionTypeExpiration] = useState("");
  const [subscriptionPeriod, setSubscriptionPeriod] = useState("MONTHLY");

  const [subscriptionBillingContact, setSubscriptionBillingContact] = useState("");
  const [subscriptionHolderAsBillingContact, setSubscriptionHolderAsBillingContact] = useState(false);
  const [subscriptionHolder, setSubscriptionHolder] = useState("");
  // const [subscriptionBillingContractorsOptions, setSubscriptionBillingContractorsOptions] = useState([]);
  let subscriptionBillingContractorsOptions = [];
  const [subscriptionContractorsOptions, setSubscriptionContractorsOptions] = useState([]);
  // const [subscriptionStartingDate, setSubscriptionStartingDate] = useState(new Date().toJSON().slice(0,10));
  const [subscriptionStartingDate, setSubscriptionStartingDate] = useState(new Date());
  const [subscriptionCreationLoading, setSubscriptionCreationLoading] = useState(false);
  // filter test s
  const params = new URLSearchParams(window.location.search);
  let activeTags = [];
  try {
    activeTags = params.get('t') && (decodeURI(params.get('t'))) && Base64.decode(decodeURI(params.get('t'))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('t')))) : [];
  } catch (error) {
    console.log("Error in setting active tags:")
    console.log(error)
  }
  // filter test e

  // Our State.
  const [views, setViews] = useState([]);
  const [createOpen, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Tutti gli subscription di un account (/subscription/) senza UUID?
  if (localStorage.getItem("cysr_useraccountroles") === null) {
    window.location.href = "/cysr/login";
  }
  // add subscription creation load  
  const btnCreateSubscriptionDefaultString = "Create subscription";
  const [stringBtnCreateSubscription, setStringBtnCreateSubscription] = useState(btnCreateSubscriptionDefaultString);


  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };
  // getFormattedEndingDate(subscriptionStartingDate, subscriptionPeriod, subscriptionExpiration)
  function getFormattedEndingDate(starting, period, expiration) {
    let formattedString = "";
    switch (period) {
      case "DAILY":
        formattedString = `${expiration} day${expiration > 1 ? "s" : ""} (${format(
          add(
            starting, {
            days: expiration
          }), 'dd/MM/yyyy')
          })`;
        break;
      case "WEEKLY":
        formattedString = `${expiration} week${expiration > 1 ? "s" : ""} (${format(
          add(
            starting, {
            weeks: expiration
          }), 'dd/MM/yyyy')
          })`;
        break;
      case "MONTHLY":
        formattedString = `${expiration} month${expiration > 1 ? "s" : ""} (${format(
          add(
            starting, {
            months: expiration
          }), 'dd/MM/yyyy')
          })`;
        break;
      case "YEARLY":
        formattedString = `${expiration} year${expiration > 1 ? "s" : ""} (${format(
          add(
            starting, {
            years: expiration
          }), 'dd/MM/yyyy')
          })`;
        break;

      default:
        formattedString = "the period defined in the subscription type"
        break;
    }
    return formattedString
  }

  function createSubscriptionConfirmed() {
    setDialogOpen(false);
    setSubscriptionCreationLoading(true)
    setStringBtnCreateSubscription('Creating subscription...')
    const t = subscriptionType;
    const holder = subscriptionHolderAsBillingContact ? subscriptionBillingContact : subscriptionHolder;
    if (t && t !== "" && holder && holder !== "") {
      // create
      const createSubscriptionUrl = `${cysrConfig().api_base_url}user/newsubscription/`;
      /*
      "subscriptiontype_uuid': "3fa85764-5717-4562-b3fc-26963f66afab",
      "starting_date": "2023-02-02".
      "contractor_holder_uuid": "3fa8564-5717-4562-b3fc-2c963f66afa6",
      "contractor_holder_name": "string",
      "contractor_holder_vat_country": "string",
      "contractor_holder_vat_number"; "string"
      "contractor_billing_uuid": "3fa85f64-5717-4562-b3fc-26963f66afab",
      "contractor_billing_name"; "string",
      "contractor_billing_vat_country": "string"
      "contractor_billing_vat_number": "string"
      */
      const datacreateSubscription = {

        subscriptiontype_uuid: t.subscriptiontype_uuid,
        // "starting_date": "2023-02-02"
        starting_date: subscriptionStartingDate && isDate(subscriptionStartingDate) ? format(subscriptionStartingDate, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
        contractor_holder_uuid: holder.id,
        contractor_holder_name: holder.name,
        contractor_holder_vat_country: holder.vat_country,
        contractor_holder_vat_number: holder.vat_number,

        contractor_billing_uuid: subscriptionBillingContact && subscriptionBillingContact.id ? subscriptionBillingContact.id : "",
        // contractor_billing_name: subscriptionBillingContact.name,
        // contractor_billing_vat_country: subscriptionBillingContact.vat_country,
        // contractor_billing_vat_number: subscriptionBillingContact.vat_number,
      };
      /*
      console.log("subscriptionBillingContact")
      console.log(subscriptionBillingContact)
      */
      if (datacreateSubscription.contractor_holder_vat_country == "other") {
        datacreateSubscription.contractor_holder_vat_country = "XX";
        if (datacreateSubscription.contractor_holder_vat_number && datacreateSubscription.contractor_holder_vat_number === "") {
          // put N/A
          datacreateSubscription.contractor_holder_vat_number = "N/A";
        } else if (datacreateSubscription.contractor_holder_vat_number) {
          // ok
        } else {
          // empty or not even existing:
          datacreateSubscription.contractor_holder_vat_number = "N/A";
        }
      }
      console.log("datacreateSubscription");
      console.log(datacreateSubscription);
      fetch(createSubscriptionUrl, {
        body: JSON.stringify(datacreateSubscription),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
          "content-type": "application/json",
        },
        method: "POST",
      })
        .then((response) => {
          console.log("response")
          console.log(response)
          if (response.status && (response.status === 200 || response.status === 201)) {
            enqueueSnackbar(
              <div style={{ display: "flex", flexDirection: "column" }}>
                <MDTypography variant="caption" style={{ fontWeight: "600" }}>Subscription Created</MDTypography>
                <MDTypography variant="caption">The Subscription was successfully created</MDTypography>
              </div>, {
              // action,
              variant: 'success'
            }
            )
            enqueueSnackbar(
              <div style={{ display: "flex", flexDirection: "column" }}>
                <MDTypography variant="caption" style={{ fontWeight: "600" }}>Reloading..</MDTypography>
                <MDTypography variant="caption">The page will reload shortly</MDTypography>
              </div>, {
              // action,
              variant: 'info'
            }
            )
            window.location.reload();
          } else if (response.status && response.status === 500) {
            enqueueSnackbar(
              <div style={{ display: "flex", flexDirection: "column" }}>
                <MDTypography variant="caption" style={{ fontWeight: "600" }}>Server Error</MDTypography>
                <MDTypography variant="caption">The server was not able to resolve the request</MDTypography>
              </div>, {
              action,
              variant: 'error',
              persist: true
            }
            )
            setStringBtnCreateSubscription(btnCreateSubscriptionDefaultString)
            setSubscriptionCreationLoading(false)
          } else {
            return response.json().then(err => {
              throw JSON.stringify(err);
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setStringBtnCreateSubscription(btnCreateSubscriptionDefaultString)

          let snackErrorTitle = "Error";
          let snackErrorDetail = "Some error happened";
          if (err && err.ErrorMessage) {
            snackErrorTitle = err.ErrorMessage;
            snackErrorDetail = err.ErrorDetail;
          } else if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
            snackErrorTitle = JSON.parse(err).ErrorMessage;
            snackErrorDetail = JSON.parse(err).ErrorDetail;
          } else if (JSON.parse(err) && JSON.parse(err).error) {
            // snackErrorTitle = "Error";
            snackErrorDetail = JSON.parse(err).error;
          }
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>{snackErrorTitle}</MDTypography>
              <MDTypography variant="caption">{snackErrorDetail}</MDTypography>
            </div>, {
            action,
            variant: 'error',
            persist: true
          })
          setSubscriptionCreationLoading(false)
        });
    } else {
      // ask to fill fields
      setStringBtnCreateSubscription(btnCreateSubscriptionDefaultString)
      let snackErrorTitle = "Error";
      let snackErrorDetail = "Missing fields";
      enqueueSnackbar(
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <MDTypography variant="caption" style={{ fontWeight: "600" }}>{snackErrorTitle}</MDTypography>
          <MDTypography variant="caption">{snackErrorDetail}</MDTypography>
        </div>, {
        action,
        variant: 'error',
        persist: true
      })
      setSubscriptionCreationLoading(false)
    }
  }

  function createSubscription(event) {
    event.preventDefault();
    const t = subscriptionType;
    const holder = subscriptionHolderAsBillingContact ? subscriptionBillingContact : subscriptionHolder;
    if (t && t !== "" && holder && holder !== "") {
      handleClickOpen();
    } else {
      // ask to fill fields
      setStringBtnCreateSubscription(btnCreateSubscriptionDefaultString)
      let snackErrorTitle = "Error";
      let snackErrorDetail = "There are missing fields";
      enqueueSnackbar(
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <MDTypography variant="caption" style={{ fontWeight: "600" }}>{snackErrorTitle}</MDTypography>
          <MDTypography variant="caption">{snackErrorDetail}</MDTypography>
        </div>, {
        action,
        variant: 'error',
        persist: true
      })
      setSubscriptionCreationLoading(false)
    }
  }

  // const accountsRoleCallUrl = `${cysrConfig().api_base_url}user/accountrole/`;
  const subscriptionsCallUrl = `${cysrConfig().api_base_url}user/mydata/`;
  useEffect(() => {
    const pageTitle = "Subscriptions";
    document.title = `${pageTitle} - ${document.title.split(" - ")[1] ? document.title.split(" - ")[1] : document.title}`;
    if (localStorage.getItem("cysr_useraccountroles")) {
      // call table of subscription + call table of checks
      fetch(subscriptionsCallUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
      })
        .then((response) => response.json())
        .then((responseAccountsRoleData) => {
          // copied as in Sidenav s
          const manageBillingInfoValue = true;
          const manageSubscriptionsInfoValue = true;
          setCanManageBillingInfo(manageBillingInfoValue)
          setCanManageSubscriptions(manageSubscriptionsInfoValue)
          let popVariant = 'info';
          if (!manageBillingInfoValue || !manageSubscriptionsInfoValue) {
            popVariant = 'warning'
          }
          /*
          enqueueSnackbar(
            <div style={{display: "flex", flexDirection: "column", gap: "12px"}}>
              <MDTypography variant="caption" style={{fontWeight: "600"}}>Cheched permissions</MDTypography>
              <MDTypography variant="caption">{(manageBillingInfoValue && manageSubscriptionsInfoValue) ? `You can manage billing info and subscriptions` : (manageBillingInfoValue ? `You can manage only billing info` : (manageSubscriptionsInfoValue ? `You can manage only subscriptions` : `You can not manage billing info nor subscriptions`))}</MDTypography>
            </div>, { 
              action,
              variant: popVariant,
              persist: true
            }
          )
          */
          // copied as in Sidenav e
          if (manageSubscriptionsInfoValue === false) {
            window.location.href = "/cysr/profile";
            return false;
          }
          /*
          console.log("subscription_list");
          console.log(responseAccountsRoleData);
          */
          // set values of boxes
          let subs = [];
          if (responseAccountsRoleData.contractors_subscriptions) {
            for (const key in responseAccountsRoleData.contractors_subscriptions) {
              if (Object.hasOwnProperty.call(responseAccountsRoleData.contractors_subscriptions, key)) {
                const s = responseAccountsRoleData.contractors_subscriptions[key];
                subs.push(
                  {
                    subscription_id: key,
                    type: s.subscription_contracttype,
                    status: s.subscription_status,
                    start: s.subscription_start_datetime,
                    end: s.subscription_end_datetime,
                    companies_count: s.companies_active_count,
                    subscription_holder: s.subscription_holder,
                    subscription_billingcontact: s.subscription_billingcontact,
                    companies_active_count: s.companies_active_count,
                    thirdparty_active_count: s.thirdparty_active_count,
                    subscription_check_count: s.subscription_check_count
                  },
                );
              }
            }
          }
          // fetch of /subscription/types/
          fetch(`${cysrConfig().api_base_url}subscription/types/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
            },
          })
            .then((response) => response.json())
            .then((responseSubTypes) => {
              console.log("responseSubTypes")
              console.log(responseSubTypes)
              setSubscriptionTypes(responseSubTypes);
            })
            .catch((err) => console.log(err));
          var contractorsList = [];
          /*
          {
            "397c6969-747f-4021-ab4f-3d78d75e67ae": {
              "contractor_name": "kitsune",
              "contractor_vat_name": "kitsune/mAiLBi Partners ltd"
            }
          }
          */
          for (const key in responseAccountsRoleData.contractors) {
            if (Object.hasOwnProperty.call(responseAccountsRoleData.contractors, key)) {
              const element = responseAccountsRoleData.contractors[key];
              let contractorObject = {};
              /*
              contractor_name: "kitsune"
              contractor_vat: null
              contractor_vat_address: null
              contractor_vat_city: null
              contractor_vat_country: null
              contractor_vat_country_code: "IT"
              contractor_vat_name: "kitsune/mAiLBi Partners ltd"
              */
              contractorObject.id = key;
              contractorObject.name = element.contractor_name;
              contractorObject.vat = element.contractor_vat;
              contractorObject.vat_number = element.contractor_vat_number;
              contractorObject.vat_country = element.contractor_vat_country;
              contractorObject.vat_country_code = element.contractor_vat_country_code;
              contractorObject.vat_name = element.contractor_vat_name;
              contractorObject.vat_city = element.contractor_vat_city;
              contractorObject.vat_address = element.contractor_vat_address;
              contractorsList.push(contractorObject);
              if (element.contractor_is_payer) {
                subscriptionBillingContractorsOptions.push(contractorObject)
              }
            }
          }
          // setSubscriptionBillingContractorsOptions(contractorsList)
          setSubscriptionContractorsOptions(contractorsList)
          setViews({
            subscriptions: subs,
            subscriptionBillingContractorsOptions: subscriptionBillingContractorsOptions
          });
        })
        .catch((err) => console.log(err));
    } else {
      setViews({});
    }
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <InfoButton cysr_info={cysrConfig().i("subscriptions", "add_a_subscription", "help")} cysr_info_link={cysrConfig().i("subscriptions", "add_a_subscription", "help_link")} />
            <MDBox py={5} px={3} fullWidth>
              <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <div>
                  {cysrConfig().i("subscriptions", "add_a_subscription", "title") && (
                    <MDTypography variant="h5">{cysrConfig().i("subscriptions", "add_a_subscription", "title")}</MDTypography>
                  )}
                  {cysrConfig().i("subscriptions", "add_a_subscription", "sub_title") && (
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      {cysrConfig().i("subscriptions", "add_a_subscription", "sub_title")}
                    </MDTypography>
                  )}
                </div>
                <MDButton
                  aria-label="expand row"
                  onClick={() => setOpen(!createOpen)} style={cysrConfig().button_style} size="medium">
                  {createOpen ? <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} /> : <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />
                  }
                  Add a subscription
                </MDButton>
              </MDBox>

              <Collapse in={createOpen} style={{ marginTop: ".5em" }} timeout="auto" unmountOnExit>
                <div className={`${darkMode ? "dark" : "light"}`}>
                  <div className="mt-2 bg-zinc-100 dark:bg-zinc-900 rounded-xl border-zinc-100 dark:border-zinc-700 border-b border-t">
                    <form onSubmit={createSubscription}>
                      <CysrStepper darkMode isLoading={subscriptionCreationLoading} steps={
                        [
                          {
                            /*
                            billing contact #chi paga->lista mydata (contractors) +add new
                            usa lo stesso per holder? [v]
                            holder #intestata->lista mydata (contractors) +add new
                            +add new:
                            name
                            VAT
                            */
                            label: 'Subscription management',
                            // value: subscriptionHolderAsBillingContact ? `${subscriptionBillingContact && subscriptionBillingContact.name ? subscriptionBillingContact.name : ""}` : `${subscriptionBillingContact && subscriptionBillingContact.name ? subscriptionBillingContact.name : ""} ${subscriptionHolder && subscriptionHolder.name ? subscriptionHolder.name : ""}`,
                            valueBoxes:
                              <Grid container spacing={1}>{boxSubscriptionHolders()}</Grid>,
                            content:
                              <Grid container spacing={1}>
                                <Grid item xs={6} lg={6}>
                                  <CysrAutocomplete
                                    optionSchema={{
                                      name: '',
                                      vat: '',
                                    }}
                                    callbackOnAdding={setSubscriptionBillingContact}
                                    /*
                                    // do not have creation of billing contractor here
                                    // dialogTitle="Add new Billing Contact"
                                    // dialogText="Fill the values to add a new Billing Contact"
                                    dialogInputs={
                                      [
                                        {
                                          label: "Name",
                                          key: "name",
                                        },
                                        {
                                          label: "VAT Country",
                                          key: "vat_country",
                                        },
                                        {
                                          label: "VAT Number",
                                          key: "vat_number",
                                        }
                                      ]
                                    }
                                    */
                                    optionKeyForDisplay="name"
                                    defaultValue={subscriptionBillingContact}
                                    darkMode={darkMode}
                                    inputLabel="Subscriber/Payer"
                                    options={views.subscriptionBillingContractorsOptions}
                                  />
                                  {canManageBillingInfo && <MDTypography variant="overline">
                                    You can <strong><a href={"/cysr/profile/subscribers"}>manage Subscribers here</a></strong>.
                                  </MDTypography>
                                  }
                                  <FormControlLabel onChange={() => {
                                    if (subscriptionHolderAsBillingContact === false) {
                                      // è false quindi diventa true essendo in  onchange, reset l'holder
                                      setSubscriptionHolder("")
                                    }
                                    setSubscriptionHolderAsBillingContact(!subscriptionHolderAsBillingContact)
                                  }} control={<Checkbox defaultChecked={subscriptionHolderAsBillingContact} />} label="Holder/Beneficiary and Subscriber/Payer are the same" />
                                </Grid>
                                {!subscriptionHolderAsBillingContact &&
                                  <Grid item xs={6} lg={6}>
                                    <CysrAutocomplete
                                      optionSchema={{
                                        name: '',
                                        vat: '',
                                      }}
                                      callbackOnAdding={setSubscriptionHolder}
                                      dialogTitle="Add new Holder/Beneficiary"
                                      dialogText="Fill the values to add a new Holder/Beneficiary"
                                      dialogInputs={
                                        [
                                          {
                                            label: "Name",
                                            key: "name",
                                          },
                                          {
                                            label: "VAT Country",
                                            key: "vat_country",
                                            countryCodeSelector: true
                                          },
                                          {
                                            label: "VAT Number",
                                            key: "vat_number",
                                          }
                                        ]
                                      }
                                      optionKeyForDisplay="name"
                                      defaultValue={subscriptionHolder}
                                      darkMode={darkMode}
                                      inputLabel="Holder/Beneficiary"
                                      options={subscriptionContractorsOptions}
                                    />
                                    <MDTypography variant="overline">
                                      Select the Holder/Beneficiary or type to add a new one.
                                    </MDTypography>
                                  </Grid>
                                }
                                {boxSubscriptionHolders()}
                                {
                                  /*
                                  <Grid item xs={6} lg={6}>
                                    <MDInput
                                      fullWidth
                                      defaultValue={subscriptionValue}
                                      sx={{color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                                      onChange={(e) => {
                                        setSubscriptionValue(e.target.value);
                                      }}
                                      size="small"
                                      label="Value"
                                      id="c_generalsubscription_val"
                                    />
                                  </Grid>
                                  */
                                }
                              </Grid>
                          },
                          {
                            label: 'Select the type of the subscription',
                            value: subscriptionType && subscriptionType.subscriptiontype_name ? subscriptionType.subscriptiontype_name : "",
                            valueBoxes:
                              <Grid container spacing={1}>{boxSubscriptionModules()}</Grid>,
                            content:
                              <Grid container spacing={1}>
                                <Grid item xs={6} lg={3}>
                                  <Autocomplete
                                    defaultValue={subscriptionType && subscriptionType.subscriptiontype_name ? subscriptionType : ""}
                                    onChange={(e, x) => {
                                      setSubscriptionType(x);
                                      setSubscriptionTypeDescription(x.subscriptiontype_longname);
                                      setSubscriptionTypeExpiration(x.subscriptiontype_period_qty);
                                      setSubscriptionPeriod(x.subscriptiontype_payment_period);
                                    }}
                                    size="small"
                                    // label="generalsubscription_type"
                                    id="c_generalsubscription_type"
                                    options={subscriptionTypes}
                                    getOptionLabel={(s) => s && s.subscriptiontype_name ? s.subscriptiontype_name : ""}
                                    renderInput={(params) => <MDInput
                                      sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                                      size="small" label="Type" {...params} />}
                                  />
                                </Grid>
                                {boxSubscriptionModules()}
                              </Grid>
                          },
                          {
                            label: 'Starting date of the Subscription',
                            value: subscriptionStartingDate && isDate(subscriptionStartingDate) ? format(subscriptionStartingDate, 'dd/MM/yyyy') : "",
                            content:
                              <Grid container spacing={1}>
                                <Grid item xs={6} lg={6}>
                                  <MDBox color="text">
                                    <DayPicker
                                      // fromDate={new Date()}
                                      mode="single"
                                      selected={subscriptionStartingDate}
                                      onSelect={(x) => {
                                        if (x && isDate(x)) {
                                          setSubscriptionStartingDate(x)
                                        }
                                      }}
                                    // footer={footer}
                                    />
                                  </MDBox>
                                  {false && <MDInput
                                    fullWidth
                                    defaultValue={subscriptionStartingDate}
                                    sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                                    onChange={(e) => {
                                      setTimeout(() => {
                                        if (subscriptionStartingDate != e.target.value) {
                                          setSubscriptionStartingDate(e.target.value);
                                        }
                                      }, 200);
                                    }}
                                    size="small"
                                    type="date"
                                    label="Value"
                                    id="c_generalsubscription_startingdate"
                                  />}
                                </Grid>
                                {
                                  /*
                                  <Grid item xs={6} lg={6}>
                                    <MDInput
                                      fullWidth
                                      defaultValue={subscriptionValue}
                                      sx={{color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                                      onChange={(e) => {
                                        setSubscriptionValue(e.target.value);
                                      }}
                                      size="small"
                                      label="Value"
                                      id="c_generalsubscription_val"
                                    />
                                  </Grid>
                                  */
                                }
                              </Grid>,
                            submitIcon: "add",
                            submitText: stringBtnCreateSubscription
                          },
                          /*
                          {
                            label: 'Subscription value',
                            value: subscriptionValue,
                            content: 
                            <Grid container spacing={1}>
                              <Grid item xs={6} lg={6}>
                                <MDInput
                                  fullWidth
                                  defaultValue={subscriptionValue}
                                  sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                                  onChange={(e) => {
                                    setSubscriptionValue(e.target.value);
                                  }}
                                  size="small"
                                  label="Value"
                                  id="c_generalsubscription_val"
                                />
                              </Grid>
                            </Grid>,
                            submitIcon: "add",
                            submitText: stringBtnCreateSubscription
                          },
                          */
                        ]
                      } />
                    </form>
                    <Dialog
                      fullScreen={false}
                      open={dialogOpen}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                      style={{
                        background: darkMode ? 'rgba(0,0,0,0.25)' : 'rgba(255, 255, 255, 0.25)',
                        backdropFilter: "blur(12px)"
                      }}>
                      <div style={{ background: darkMode ? '#192328' : '#ffffff' }}>
                        <DialogTitle id="responsive-dialog-title">
                          {"Confirm subscription creation?"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            <MDTypography variant="body2">
                              You are about to create a <strong>{subscriptionType && subscriptionType.subscriptiontype_name}</strong> subscription ({subscriptionTypeDescription}) managed by {subscriptionHolderAsBillingContact ? `${subscriptionBillingContact && subscriptionBillingContact.name ? subscriptionBillingContact.name : ""}` : `${subscriptionBillingContact && subscriptionBillingContact.name ? subscriptionBillingContact.name : ""} and ${subscriptionHolder && subscriptionHolder.name ? subscriptionHolder.name : ""}`}.
                            </MDTypography>
                            <MDTypography variant="body2">
                              The subscription will start on <strong>{subscriptionStartingDate && isDate(subscriptionStartingDate) ? format(subscriptionStartingDate, 'dd/MM/yyyy') : ""}</strong> and end after <strong>{getFormattedEndingDate(subscriptionStartingDate, subscriptionPeriod, subscriptionExpiration)}</strong>.
                            </MDTypography>
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <MDButton style={cysrConfig().button_style} size="medium" autoFocus onClick={handleClose}>
                            Disagree
                          </MDButton>
                          <MDButton style={cysrConfig().button_style} size="medium" onClick={createSubscriptionConfirmed} autoFocus>
                            Agree
                          </MDButton>
                        </DialogActions>
                      </div>
                    </Dialog>
                  </div>
                </div>
              </Collapse>
            </MDBox>
            <div style={{ position: "relative" }}>
              {views && views.subscriptions &&
                <MDBox pb={5} px={3} fullWidth>
                  <CysrTableSubscriptions data={
                    {
                      search: params.get('s') ? params.get('s') : "",
                      hideCompleted: params.get('hc') ? true : false,
                      tableTagsOptions: [],
                      nodes: views.subscriptions,
                      tableTagsActive: activeTags,
                    }
                  }
                  />
                </MDBox>
              }
              {
                // <DataTable  entriesPerPage={{ defaultValue: 50, entries: [25, 50, 100, 200, 400] }} table={subscriptionsData(views)} canSearch />
              }
            </div>
          </Card>
        </Grid>
      </Grid>

      {
        /*
      }
        <MDBox py={3}>
          {false && views && views.timeline && (
            <LineChart
              data={views.timeline.data}
              options={views.timeline.options}
            />
          )}
        </MDBox>
        <MDBox py={3} fullWidth>
          <MDBox mb={3} fullWidth>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MDBox pb={3} lineHeight={1} fullWidth>
                  <MDBox mb={1} fullWidth>
                    <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                      <MDTypography variant="h5">Companies list</MDTypography>
                      <MDButton
                        aria-label="expand row"
                        color="dark"
                        size="medium"
                        onClick={() => setOpen(!createOpen)}
                        >
                        Add a subscription&nbsp;{createOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </MDButton>
                    </MDBox>
                    <Collapse in={createOpen} style={{width:"100%"}} timeout="auto" unmountOnExit>        
                      <form onSubmit={createSubscription} py={3} style={{ display:"block", padding: ".5em 0"}}>
                        <Grid container spacing={1}>
                          <Grid item xs={3} lg={2}>
                            <Autocomplete
                              defaultValue="IT"
                              // label="generalsubscription_type"
                              id="c_account_vat_code"
                              size="small"
                              renderInput={(params) => <MDInput style={{height:"100%"}} label="Subscription VAT Code" {...params} />}
                              style={{height:"100%", display:"block"}}
                            />
                          </Grid>
                          <Grid item xs={9} lg={4}>
                            <MDInput
                              size="small"
                              label="Subscription VAT"
                              id="c_account_data_national_id"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <MDInput
                              fullWidth
                              size="small"
                              label="Subscription name"
                              id="c_account_name"
                            />
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <MDButton fullWidth size="small" color="dark" type="submit" style={{height:"100%"}}>
                              Create Subscription
                            </MDButton>
                          </Grid>
                        </Grid>
                      </form>
                      <MDSnackbar
                        color="error"
                        icon="error"
                        title="Subscription not created"
                        content="Check that all the fields are compiled correctly and try again"
                        dateTime="Error"
                        open={show}
                        close={toggleSnackbar}
                      />
                    </Collapse>
                    
                  </MDBox>
                  <MDTypography variant="button" color="text" fontWeight="regular">
                    The list of companies this account has access to.
                  </MDTypography>
                </MDBox>
                {views && views.accounts_role && views.accounts_role.length > 0 ? (
                  <MDBox
                    // display="flex"
                    // justifyContent="space-between"
                    // alignItems="center"
                    width={{ xs: "max-content", sm: "100%" }}
                  >
                    {views.accounts_role.map(
                      (role) =>
                        role ? (
                          <div key={role.account.account_uuid}>
                            <Grid container spacing={3}>
                              {role.account && role.account.rating && role.account.rating[0] && role.account.rating[0].rating_val ? (
                              <Grid item xs={3} md={2}>
                                <img
                                  alt="Rating"
                                  src={`${cysrConfig().image_rating_base_url}${role.account.rating[0].rating_val}.svg`}
                                  width="100%"
                                />
                              </Grid>
                              ) : (
                                <Grid item xs={3} md={2}>
                                  <p>No rating</p>
                                </Grid>
                              )}
                              <Grid item xs={9} md={10}>
                                <DashboardSubscriptionInfo useraccountrole={role} />
                              </Grid>
                            </Grid>
                            <Divider />
                          </div>
                        ) : <p>No role</p>
                    )}
                  </MDBox>
                ) : (
                  <MDTypography variant="caption" color="text">
                    This account does not have any subscription
                  </MDTypography>
                )}
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        {
          */
      }

    </DashboardLayout>
  );

  function boxSubscriptionModules() {
    return <Grid item xs={12} container spacing={1}>
      {subscriptionType && subscriptionType.subscriptiontype_name &&
        <MDTypography mt={1} ml={1} variant="body2" style={{ width: "100%" }}>
          Modules included in the subscription type {subscriptionType.subscriptiontype_name}:
        </MDTypography>}
      {subscriptionType && subscriptionType.modules && subscriptionType.modules.map((mod) =>
        /*
          {
              "module_name": "CYSR_TIME_SME",
              "module_description": "CYSR Module for Small&Medium Enterprises",
              "module_group": "CYSR",
              "module_order": 1,
              "module_configuration": {
                  "company_max": "1",
                  "recheck_period_days": "15",
                  "asset_per_company_max": "200",
                  "manual_asset_per_company_max": "2"
              }
          }
          ,
          {
              "module_name": "CTI_SME",
              "module_description": "CTI_SME",
              "module_group": "CTI",
              "module_order": 1,
              "module_configuration": {
                  "leak_domain_max": "5",
                  "botnet_domain_max": "5",
                  "recheck_period_days": "7"
              }
          }..
        */
        <Grid key={mod.module_name} item xs={6}>
          <div
            cysr_module_name={mod.module_name}
            style={{
              border: "1px solid rgb(91 148 159)",
              padding: "12px",
              background: `${darkMode ? "#131516" : "#e6f6f9"}`,
              borderRadius: "5px",
              height: "100%",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <MDTypography variant="caption" mb={1} fontWeight="bold" color="info">
              {mod.module_group}
            </MDTypography>
            <MDTypography variant="text">
              {mod.module_description}
            </MDTypography>
            {
              // CYSR:
            }
            {mod.module_configuration && mod.module_configuration.company_max &&
              <MDTypography variant="button">
                Max companies: <strong>{mod.module_configuration.company_max}</strong>
              </MDTypography>}
            {mod && mod.thirdparty_max &&
              <MDTypography variant="button">
                Max third parties: <strong>{mod.thirdparty_max}</strong>
              </MDTypography>}
            {mod.module_configuration && mod.module_configuration.recheck_period_days &&
              <MDTypography variant="button">
                Recheck period (days): <strong>{mod.module_configuration.recheck_period_days}</strong>
              </MDTypography>}
            {mod.module_configuration && mod.module_configuration.asset_per_company_max &&
              <MDTypography variant="button">
                Max assets per company: <strong>{mod.module_configuration.asset_per_company_max}</strong>
              </MDTypography>}
            {mod.module_configuration && mod.module_configuration.manual_asset_per_company_max &&
              <MDTypography variant="button">
                Max manual asset per company: <strong>{mod.module_configuration.manual_asset_per_company_max}</strong>
              </MDTypography>}
            {
              // CTI:
            }
            {mod.module_configuration && mod.module_configuration.leak_domain_max &&
              <MDTypography variant="button">
                Max leak domain: <strong>{mod.module_configuration.leak_domain_max}</strong>
              </MDTypography>}
            {mod.module_configuration && mod.module_configuration.botnet_domain_max &&
              <MDTypography variant="button">
                Max botnet domain: <strong>{mod.module_configuration.botnet_domain_max}</strong>
              </MDTypography>}
          </div>
        </Grid>
      )}
    </Grid>;
  }

  function boxSubscriptionHolders() {
    return <Grid item xs={12} container spacing={1}>
      {(
        (subscriptionBillingContact && subscriptionBillingContact.name)
        ||
        (subscriptionHolder && subscriptionHolder.name)
      ) &&
        <MDTypography mt={1} ml={1} color="text" variant="body2" style={{ fontSize: "14px", width: "100%" }}>
          Contractors details of the subscription:
        </MDTypography>}
      {subscriptionBillingContact && subscriptionBillingContact.name &&
        <Grid item xs={6}>
          <div
            style={{
              border: "1px solid rgb(91 148 159)",
              padding: "12px",
              background: `${darkMode ? "#131516" : "#e6f6f9"}`,
              borderRadius: "5px",
              height: "100%",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <MDTypography variant="caption" mb={1} fontWeight="bold" color="info">
              Subscriber
            </MDTypography>
            <MDTypography variant="button">
              {subscriptionBillingContact.name}
            </MDTypography>
            <MDTypography variant="text">
              {subscriptionBillingContact.vat_name}
            </MDTypography>
            {subscriptionBillingContact.vat_country_code ?
              <MDTypography variant="button">
                {`${((
                  subscriptionBillingContact.vat_country_code
                  && subscriptionBillingContact.vat
                  && (
                    (
                      (subscriptionBillingContact.vat)
                        .indexOf(subscriptionBillingContact.vat_country_code) > -1
                    ) === false
                  )
                )
                  ? subscriptionBillingContact.vat_country_code : "") + (subscriptionBillingContact.vat
                    ? subscriptionBillingContact.vat
                    : subscriptionBillingContact.vat_country_code + " "
                    + (
                      subscriptionBillingContact.vat_number
                        ? subscriptionBillingContact.vat_number
                        : ""
                    ))
                  }`}
              </MDTypography>
              :
              <MDTypography variant="button">
                {subscriptionBillingContact.vat_country}
              </MDTypography>
            }

            {subscriptionBillingContact.vat_address ?
              <MDTypography variant="button">
                {subscriptionBillingContact.vat_address}
              </MDTypography>
              : null}

            {subscriptionBillingContact.vat_city ?
              <MDTypography variant="button">
                {subscriptionBillingContact.vat_city}
              </MDTypography>
              : null}
          </div>
        </Grid>}
      {subscriptionHolder && subscriptionHolder.name &&
        <Grid item xs={6}>
          <div
            style={{
              border: "1px solid rgb(91 148 159)",
              padding: "12px",
              background: `${darkMode ? "#131516" : "#e6f6f9"}`,
              borderRadius: "5px",
              height: "100%",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <MDTypography variant="caption" mb={1} fontWeight="bold" color="info">
              Holder/Beneficiary
            </MDTypography>
            <MDTypography variant="button">
              {subscriptionHolder.name}
            </MDTypography>
            {subscriptionHolder.vat_name ?
              <MDTypography variant="text">
                {subscriptionHolder.vat_name}
              </MDTypography>
              :
              subscriptionHolder.vat ?
                <MDTypography variant="text">
                  {subscriptionHolder.vat}
                </MDTypography> : null
            }
            {subscriptionHolder.vat_country_code ?
              <MDTypography variant="button">
                {`${subscriptionHolder.vat_country_code} ${subscriptionHolder.vat_number}`}
              </MDTypography>
              :
              <MDTypography variant="button">
                {`${subscriptionHolder.vat_country && subscriptionHolder.vat_country + " "}${subscriptionHolder.vat_number && subscriptionHolder.vat_number}`}
              </MDTypography>
            }
            <MDTypography variant="button">
              {subscriptionHolder.vat_address}
            </MDTypography>
            <MDTypography variant="button">
              {subscriptionHolder.vat_city}
            </MDTypography>
          </div>
        </Grid>}
    </Grid>;
  }
}

export default Subscriptions;

/* eslint-disable */
// prettier-ignore
