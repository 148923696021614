/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
// prettier-ignore
import { Base64 } from 'js-base64';
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
import InfoButton from "layouts/cysr/components/infoButton";
// import Footer from "examples/Footer";
import Loader from "layouts/cysr/components/Loader";

// import KeyboardArrowDownIcon from '@mui/icons-material/Add';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Card from "@mui/material/Card";
import CysrTableCompanies from "layouts/cysr/components/CysrTable/companies";
// import DataTable from "layouts/cysr/components/DataTable";
// import dashboardData from "layouts/cysr/dashboard/dashboardData";

// import { LineChart } from "@carbon/charts-react";
// import "@carbon/charts/styles-g90.css";

import cysrConfig from "layouts/cysr/config";

import { updateCompanies } from "db";
import CompaniesTop from "./CompaniesTop";
function Sales() {
  if (localStorage.getItem("cysr_useraccountrole_selected")) {
    localStorage.removeItem("cysr_useraccountrole_selected")
    window.location.reload()
  }
  // filter test s
  const params = new URLSearchParams(window.location.search);
  let activeTags = [];
  try {
    activeTags = params.get('t') && (decodeURI(params.get('t'))) && Base64.decode(decodeURI(params.get('t'))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('t')))) : [];
  } catch (error) {
    console.error("Error in setting active tags:")
    console.error(error)
  }
  // filter test e

  // Our State.
  const [isLoading, setLoading] = useState(true);
  const [views, setViews] = useState([]);
  const accountsRoleCallUrl = `${cysrConfig().api_base_url}user/mycompanies/`;
  useEffect(() => {
    setLoading(true);
    const pageTitle = "Dashboard";
    document.title = `${pageTitle} - ${document.title.split(" - ")[1] ? document.title.split(" - ")[1] : document.title}`;
    // if (localStorage.getItem("cysr_useraccountroles")) {
    if (localStorage.getItem("cysr_access")) {
      // call table of asset + call table of checks
      fetch(accountsRoleCallUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
      })
        .then((response) => response.json())
        .then(async (responseAccountsRoleData) => {
          if (responseAccountsRoleData.code && responseAccountsRoleData.code === "token_not_valid") {
            // remove localstorage and go to login
            const dMode = localStorage.getItem('cysr_dark_mode') ? localStorage.getItem('cysr_dark_mode') : "true";
            localStorage.clear();
            localStorage.setItem('cysr_dark_mode', dMode);
            window.location.href = `/cysr/login`;
            return
          }
          // add here to localStorage(cysr_useraccountroles) so to remove from login s
          // localStorage.setItem("cysr_***useraccountroles", JSON.stringify(responseAccountsRoleData));
          // up DB
          await updateCompanies({ data: responseAccountsRoleData })
          // set as true to verify it has data
          localStorage.setItem("cysr_useraccountroles", true);
          // add here to localStorage(cysr_useraccountroles) so to remove from login e
          // console.log(responseAccountsRoleData);

          // do here s
          const userAccounts = responseAccountsRoleData;
          userAccounts.sort((a, b) =>
            a.account_name
              .toLowerCase()
              .localeCompare(b.account_name.toLowerCase())
          );
          // timeline
          const tData = [];
          // change with actual data
          const timeLineData = [
            {
              mu_created_at: "2020-12-10T21:00:00.000Z",
              rating_val: 10
            },
            {
              mu_created_at: "2020-12-10T21:00:00.000Z",
              rating_val: 10
            },
            {
              mu_created_at: "2020-12-10T21:00:00.000Z",
              rating_val: 10
            }
          ];
          timeLineData.forEach(t => {
            // "date": "2020-12-10T21:00:00.000Z",
            // "value": 10
            tData.push({
              date: t.mu_created_at,
              value: parseInt(t.rating_val, 10)
            });
          });
          // set values of boxes
          setViews({
            accounts_role: userAccounts,
            timeline: {
              data: {
                "labels": [
                  "Rating score"
                ],
                "datasets": [
                  {
                    "label": "Rating",
                    "data": tData
                  }
                ]
              },
              options: {
                // "title": "Line (time series) - Time interval hourly with custom ticks formats ('MMM d, HH:mm' and 'HH:mm')",
                "title": false,
                "axes": {
                  "left": {},
                  "bottom": {
                    "scaleType": "time"
                  }
                },
                "timeScale": {
                  "timeIntervalFormats": {
                    "hourly": {
                      "primary": "MMM d, HH:mm",
                      "secondary": "HH:mm"
                    }
                  }
                },
                "legend": {
                  "enabled": false,
                },
                "height": "400px",
                "toolbar": { "enabled": false },
              }
            },
            cysr_tags_active: activeTags
          });
          setLoading(false);
          // do here e
        })
        .catch((err) => {
          console.error(err)
          setLoading(false);
        });
    } else {
      setViews({});
      setLoading(false);
    }
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <InfoButton cysr_info={cysrConfig().i("dashboard", "add_a_company", "help")} cysr_info_link={cysrConfig().i("dashboard", "add_a_company", "help_link")} />
            <CompaniesTop />
            <div style={{ position: "relative" }}>
              {false && <InfoButton cysr_info={cysrConfig().i("dashboard", "companies_list", "help")} cysr_info_link={cysrConfig().i("dashboard", "companies_list", "help_link")} />}
              {false && <MDBox pb={5} px={3} fullWidth>
                <MDBox mb={1} fullWidth>
                  <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <div>
                      {cysrConfig().i("dashboard", "companies_list", "title") && (
                        <MDTypography variant="h5">{cysrConfig().i("dashboard", "companies_list", "title")}</MDTypography>
                      )}
                      {cysrConfig().i("dashboard", "companies_list", "sub_title") && (
                        <MDTypography variant="button" color="text" fontWeight="regular">
                          {cysrConfig().i("dashboard", "companies_list", "sub_title")}
                        </MDTypography>
                      )}
                    </div>
                  </MDBox>
                </MDBox>
              </MDBox>}
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <MDBox pb={5} pt={0} px={3} fullWidth>
                    <CysrTableCompanies data={
                      {
                        search: params.get('s') ? params.get('s') : "",
                        hideCompleted: params.get('hc') ? true : false,
                        tableTagsOptions: [],
                        nodes: views.accounts_role,
                        tableTagsActive: views.cysr_tags_active,
                      }
                    }
                    />
                  </MDBox>
                  {
                    // <DataTable  entriesPerPage={{ defaultValue: 50, entries: [25, 50, 100, 200, 400] }} table={dashboardData(views)} canSearch />
                  }
                </>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>

      {
        /*
      }
        <MDBox py={3}>
          {false && views && views.timeline && (
            <LineChart
              data={views.timeline.data}
              options={views.timeline.options}
            />
          )}
        </MDBox>
        <MDBox py={3} fullWidth>
          <MDBox mb={3} fullWidth>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MDBox pb={3} lineHeight={1} fullWidth>
                  <MDBox mb={1} fullWidth>
                    <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                      <MDTypography variant="h5">Companies list</MDTypography>
                      <MDButton
                        aria-label="expand row"
                        color="dark"
                        size="medium"
                        onClick={() => setOpen(!createOpen)}
                        >
                        Add a company&nbsp;{createOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </MDButton>
                    </MDBox>
                    <Collapse in={createOpen} style={{width:"100%"}} timeout="auto" unmountOnExit>        
                      <form onSubmit={createAccount} py={3} style={{ display:"block", padding: ".5em 0"}}>
                        <Grid container spacing={1}>
                          <Grid item xs={3} lg={2}>
                            <Autocomplete
                              defaultValue="IT"
                              // label="generalasset_type"
                              id="c_account_vat_code"
                              options={countriesCodes}
                              size="small"
                              renderInput={(params) => <MDInput style={{height:"100%"}} label="Company VAT Code" {...params} />}
                              style={{height:"100%", display:"block"}}
                            />
                          </Grid>
                          <Grid item xs={9} lg={4}>
                            <MDInput
                              size="small"
                              label="Company VAT"
                              id="c_account_data_national_id"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <MDInput
                              fullWidth
                              size="small"
                              label="Company name"
                              id="c_account_name"
                            />
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <MDButton fullWidth size="small" color="dark" type="submit" style={{height:"100%"}}>
                              Create Company
                            </MDButton>
                          </Grid>
                        </Grid>
                      </form>
                      <MDSnackbar
                        color="error"
                        icon="error"
                        title="Company not created"
                        content="Check that all the fields are compiled correctly and try again"
                        dateTime="Error"
                        open={show}
                        close={toggleSnackbar}
                      />
                    </Collapse>
                    
                  </MDBox>
                  <MDTypography variant="button" color="text" fontWeight="regular">
                    The list of companies this account has access to.
                  </MDTypography>
                </MDBox>
                {views && views.accounts_role && views.accounts_role.length > 0 ? (
                  <MDBox
                    // display="flex"
                    // justifyContent="space-between"
                    // alignItems="center"
                    width={{ xs: "max-content", sm: "100%" }}
                  >
                    {views.accounts_role.map(
                      (role) =>
                        role ? (
                          <div key={role.account_uuid}>
                            <Grid container spacing={3}>
                              {role.account && role.rating && role.rating[0] && role.rating[0].rating_val ? (
                              <Grid item xs={3} md={2}>
                                <img
                                  alt="Rating"
                                  src={`${cysrConfig().image_rating_base_url}${role.rating[0].rating_val}.svg`}
                                  width="100%"
                                />
                              </Grid>
                              ) : (
                                <Grid item xs={3} md={2}>
                                  <p>No rating</p>
                                </Grid>
                              )}
                              <Grid item xs={9} md={10}>
                                <DashboardCompanyInfo useraccountrole={role} />
                              </Grid>
                            </Grid>
                            <Divider />
                          </div>
                        ) : <p>No role</p>
                    )}
                  </MDBox>
                ) : (
                  <MDTypography variant="caption" color="text">
                    This account does not have any company
                  </MDTypography>
                )}
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        {
          */
      }

    </DashboardLayout>
  );
}

export default Sales;

/* eslint-disable */
// prettier-ignore
