/* eslint-disable */
import React, { useEffect, useState } from 'react';
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import cysrConfig from "layouts/cysr/config";

//+
const passwordRequirements = [
    "Your password can’t be too similar to your other personal information.",
    "Your password must contain at least 10 characters.",
    "Your password can’t be a commonly used password.",
    "Your password can’t be entirely numeric."
];

const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
        <MDBox key={itemKey} component="li" color="text" fontSize="0.875rem" lineHeight={1}>
            <MDTypography variant="caption" color="text" fontWeight="regular" verticalAlign="middle">
                {item}
            </MDTypography>
        </MDBox>
    );
});

function LoginResetPassword({ token, toggleSnackbar, setResettingPassword }) {
    const [verificationLoading, setVerificationLoading] = useState(true);
    const [verifiedEmail, setVerifiedEmail] = useState(false);
    const resetPassword = (newPasswordValue = false) => {
        if (newPasswordValue === false) {
            toggleSnackbar("Error", "No new password provided", "error", "error", "Error");
            return false;
        }
        const newPasswordCallUrl = `${cysrConfig().api_base_url}password-reset/${token}`;
        const dataNewPassword = {
            new_password: newPasswordValue,
        };
        fetch(newPasswordCallUrl, {
            body: JSON.stringify(dataNewPassword),
            headers: {
                "content-type": "application/json",
            },
            method: "POST",
        })
            .then((response) => {
                if (response.ok) {
                    // window.location.reload();
                    toggleSnackbar("Password changed", `Your new password has been set.`, "info", "info", "");
                    setResettingPassword(false)
                    // return response.json();
                } else {
                    console.error(response);
                    return response.json().then(err => {
                        throw JSON.stringify(err)
                    })
                }
            })
            .catch((err) => {
                console.log(err);
                console.log("err3:", err);
                if (JSON.parse(err) && JSON.parse(err).ErrorMessage && JSON.parse(err).ErrorDetail.indexOf("<") > -1) {
                    toggleSnackbar(JSON.parse(err).ErrorMessage,
                        <div dangerouslySetInnerHTML={{ __html: JSON.parse(err).ErrorDetail }} />, "error", "error", "Error");
                } else if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
                    toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
                } else if (JSON.parse(err) && JSON.parse(err).error) {
                    toggleSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
                } else {
                    toggleSnackbar("Error", "Some error happened", "error", "error", "Error");
                }
            })
    };

    const verifyPasswordCallUrl = `${cysrConfig().api_base_url}password-reset/${token}`;
    useEffect(() => {
        fetch(verifyPasswordCallUrl)
            .then((response) => response.json())
            .then((data) => {
                if (data && data.ErrorDetail
                    ||
                    data && data.code && data.code === "token_not_valid") {
                    console.log('data')
                } else if (data) {
                    console.log(data)
                    setVerifiedEmail(data.email)
                }
                setVerificationLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setVerificationLoading(false)
            });
    }, []);
    return (
        <div className='cysr-auth-container'>
            {verificationLoading
                ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={cysrConfig().button_icon_left_style} className="spin" dangerouslySetInnerHTML={{ __html: cysrConfig().loading.icon.svg }} />
                        <span style={{ fontSize: "16px", lineHeight: "1" }}>Verifying link</span>
                    </div>
                )
                : verifiedEmail ?
                    <>
                        <MDTypography variant="h4" fontWeight="medium" mb={1}>
                            Reset password
                        </MDTypography>
                        <MDBox mt={1} mb={1}>
                            <MDTypography variant="subtitle2">
                                Enter the new password for the email</MDTypography>
                            <MDTypography variant="h6">
                                {verifiedEmail}</MDTypography>
                        </MDBox>
                        <MDBox mb={.5}>
                            <MDTypography variant="button" color="text">
                                Please follow this guide for a strong password
                            </MDTypography>
                        </MDBox>
                        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
                            {
                                //<MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                            }
                            <ul className="flex flex-col gap-1 list-disc list-inside marker:text-teal-500">
                                {renderPasswordRequirements}
                            </ul>
                            {
                                // </MDBox>
                            }
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                if (document.getElementById("cysr_new_password").value && document.getElementById("cysr_new_password").value.length > 9) {
                                    if (document.getElementById("cysr_new_password").value === document.getElementById("cysr_new_password-2").value) {
                                        resetPassword(document.getElementById("cysr_new_password").value)
                                    } else {
                                        toggleSnackbar("Error", "Repeated new password does not match the new password", "error", "error", "Error");
                                    }
                                } else {
                                    toggleSnackbar("Error", "Invalid new password", "error", "error", "Error");
                                }
                            }} style={{ width: "100%", marginTop: "16px", display: "flex", flexDirection: "column", flexDirection: "column", gap: "12px" }}>
                                <MDInput autoComplete="new-password" type="password" label="New password" id="cysr_new_password" fullWidth />
                                <MDInput autoComplete="new-password" type="password" label="Repeat new password" id="cysr_new_password-2" fullWidth />

                                <div style={{ display: "flex", gap: "12px" }}>
                                    <MDButton onClick={() => setResettingPassword(false)} style={cysrConfig().button_style} size="medium">
                                        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().exit.icon.svg }} />
                                        Back
                                    </MDButton>
                                    <MDButton type="submit" style={cysrConfig().button_style} size="medium">
                                        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().update.icon.svg }} />
                                        Update password
                                    </MDButton>
                                </div>
                            </form>
                        </MDBox>
                    </>
                    :
                    <>
                        <MDTypography variant="h4" fontWeight="medium" mb={1}>
                            Invalid reset link
                        </MDTypography>
                        <MDBox mb={1}>
                            <MDTypography variant="body2" color="text">
                                The link is not valid or expired.
                            </MDTypography>
                        </MDBox>
                        <MDTypography variant="caption" my={4}>
                            Go back and request a new one
                        </MDTypography>
                        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">

                            <div style={{ display: "flex", flexDirection: "column", flexDirection: "column", gap: "12px" }}>
                                <div style={{ display: "flex", gap: "12px" }}>
                                    <MDButton onClick={() => setResettingPassword(false)} style={cysrConfig().button_style} size="medium">
                                        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().exit.icon.svg }} />
                                        Back
                                    </MDButton>
                                </div>
                            </div>
                        </MDBox>
                    </>
            }
        </div>

    )
}
export default LoginResetPassword;
/* eslint-disable */