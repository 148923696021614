/* eslint-disable */
// prettier-ignore
"use client";
import React, { useState } from 'react';
import { Calendar } from "layouts/cysr/components/ui/calendar";
import { cn } from "lib/utils"
import { isBefore, format, startOfToday, closestIndexTo, isSameDay, compareAsc } from 'date-fns';
import { process, getColor } from "layouts/cysr/config";
// import { DayClickEventHandler, DayPicker } from 'react-day-picker';

const calendarNumberOfMonths = 3;

export default function TimeshiftCompareCalendarDatePicker({ timeshiftData = [],
    selectedDays, setSelectedDays }) {

    if (selectedDays === null && timeshiftData && timeshiftData.length > 0) {
        let datesArray = []
        timeshiftData.forEach(element => {
            if (element.report_date && isBefore(new Date(element.report_date), startOfToday())) {
                datesArray.push(new Date(element.report_date))
            }
        });
        const closestIndex = closestIndexTo(startOfToday(), datesArray)
        setSelectedDays([
            datesArray && datesArray.length > 0 ? datesArray[closestIndex] : startOfToday(),
            startOfToday(),
        ].sort(compareAsc))
    }

    const handleDayClick = (day, modifiers) => {
        const formattedSelectedDate = day ? format(
            day,
            process.env.NEXT_PUBLIC_DATE_FORMAT_TIMESHIFT
        ) : null;
        if (
            (timeshiftData.find((td) => td.report_date === formattedSelectedDate
            )) || formattedSelectedDate ===
            format(startOfToday(), process.env.NEXT_PUBLIC_DATE_FORMAT_TIMESHIFT)) {
            // valid day
        } else {
            // not valid day
            return false
        }
        const newSelectedDays = [...selectedDays];
        if (modifiers.selected) {
            const index = selectedDays.findIndex((selectedDay) =>
                isSameDay(day, selectedDay)
            );
            newSelectedDays.splice(index, 1);
        } else {
            if (selectedDays.length < 2) {
                newSelectedDays.push(day);
            } else {
                // alert("Tap a selected date to unselect it")
            }
        }
        setSelectedDays(newSelectedDays.sort(compareAsc));
    };
    const formatDay
        // : DateFormatter 
        = (d, options) => {
            let itemFound = false;
            timeshiftData.forEach(element => {
                if (element.report_date === format(d, process.env.NEXT_PUBLIC_DATE_FORMAT_TIMESHIFT)) {
                    itemFound = element;
                }
            });
            if (itemFound === false) {
                // check if same day of today to not reduce opacity
                const isItemToday =
                    format(d, process.env.NEXT_PUBLIC_DATE_FORMAT_TIMESHIFT) ===
                    format(startOfToday(), process.env.NEXT_PUBLIC_DATE_FORMAT_TIMESHIFT);
                return (
                    <span
                        aria-label={d.toDateString()}
                        className={cn(
                            "pb-1 h-[34px] w-[34px] items-center inline-flex justify-center",
                            isItemToday
                                ? (
                                    (selectedDays && ((selectedDays[0] && selectedDays[0].toDateString()) === d.toDateString()
                                        || (selectedDays[1] && selectedDays[1].toDateString()) === d.toDateString()))
                                        ? "ring-2 rounded-md font-bold relative ring-black dark:ring-white"
                                        : "font-normal"
                                )
                                : "opacity-20 cursor-not-allowed")}
                    >
                        {format(d, process.env.NEXT_PUBLIC_DATE_FORMAT_TIMESHIFT_DAY)}
                        {(selectedDays && ((selectedDays[0] && selectedDays[0].toDateString()) === d.toDateString()
                            || (selectedDays[1] && selectedDays[1].toDateString()) === d.toDateString())) ? <svg className="absolute bottom-1 left-0 right-0 w-1.5 h-1.5 mx-auto" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CircleSharpIcon" fill="currentColor"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"></path></svg> : null}
                    </span>
                );
            } else {
                return (
                    <>
                        <span
                            className={cn(
                                "pb-1 ring-2 h-[34px] w-[34px] items-center inline-flex justify-center rounded-md text-white",
                                (selectedDays && ((selectedDays[0] && selectedDays[0].toDateString()) === d.toDateString()
                                    || (selectedDays[1] && selectedDays[1].toDateString()) === d.toDateString()))
                                    ? "font-bold ring-black dark:ring-white hover:ring-black/75 dark:hover:ring-white/75 relative"
                                    : "font-normal ring-black/25 hover:ring-black/80 dark:ring-white/25 hover:dark:ring-white/80"
                            )}
                            style={{
                                background: getColor(itemFound.rating_val),
                                // color: getColor(itemFound.companies[0].rating),
                            }}
                            aria-label={d.toDateString()}
                        >
                            {format(d, process.env.NEXT_PUBLIC_DATE_FORMAT_TIMESHIFT_DAY)}
                            {(selectedDays && ((selectedDays[0] && selectedDays[0].toDateString()) === d.toDateString()
                                || (selectedDays[1] && selectedDays[1].toDateString()) === d.toDateString())) ? <svg className="absolute bottom-1 left-0 right-0 w-1.5 h-1.5 mx-auto" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CircleSharpIcon" fill="currentColor"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"></path></svg> : null}
                        </span>
                    </>
                );
            }
        };
    return (
        <Calendar
            mode="multiple"
            onDayClick={handleDayClick}
            selected={selectedDays}
            formatters={{ formatDay }}
            numberOfMonths={calendarNumberOfMonths}
            showOutsideDays={false}
            toMonth={startOfToday()}
            min={2}
            max={2}
        />
    );
}