/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import ScoreItem from "layouts/cysr/components/ScoreItem";
import StatusItem from "layouts/cysr/components/StatusItem";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

function DataTableBodyCell({ noBorder, align, children }) {
  let showScore = false;
  if (children.props.column.id === "company_rating") {
    showScore = children.props.value;
  }
  let showStatus = false;
  if (children.props.column.id === "asset_status_val") {
    showStatus = children.props.value;
  }
  let doNotShowFullText = false;
  if (children.props.column.id === "account_vat_name" || children.props.column.id === "account_name") {
    doNotShowFullText = children.props.value;
  }
  return (
    <MDBox
      component="td"
      textAlign={align}
      py={1.5}
      px={1}
      sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
        fontSize: size.sm,
        borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
      })}
    >
      <MDBox
        display="inline-block"
        width="max-content"
        color="text"
        sx={{ verticalAlign: "middle" }}
      >
        {showScore ? (
          <ScoreItem scoreValue={showScore} />
        ) : (
          doNotShowFullText ? (
            <p style={{overflow: "hidden",maxWidth: "15rem", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{doNotShowFullText}</p>
            ) : (
            showStatus ? (
              <StatusItem statusValue={showStatus} />          
            ) : children
          )
        )
        }
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;
/* eslint-disable */
