/* eslint-disable */
// prettier-ignore

export function riskColors(query = undefined) {
    const colorsData = [
        {
            background: process.env.NEXT_PUBLIC_RATING_EXCELLENT_COLOR
                ? process.env.NEXT_PUBLIC_RATING_EXCELLENT_COLOR
                : "#2466B0",
            color: process.env.NEXT_PUBLIC_RATING_EXCELLENT_COLOR
                ? process.env.NEXT_PUBLIC_RATING_EXCELLENT_COLOR
                : "#2466B0",
            label: process.env.NEXT_PUBLIC_RATING_EXCELLENT_LABEL,
            hsl: process.env.NEXT_PUBLIC_RATING_EXCELLENT_HSL ?? '211.71, 66.04%, 41.57%',
            from: process.env.NEXT_PUBLIC_RATING_EXCELLENT_FROM ? parseInt(process.env.NEXT_PUBLIC_RATING_EXCELLENT_FROM, 10) : 90,
            to: process.env.NEXT_PUBLIC_RATING_EXCELLENT_TO ? parseInt(process.env.NEXT_PUBLIC_RATING_EXCELLENT_TO, 10) : 100
        },
        {
            background: process.env.NEXT_PUBLIC_RATING_GOOD_COLOR
                ? process.env.NEXT_PUBLIC_RATING_GOOD_COLOR
                : "#57A6D9",
            color: process.env.NEXT_PUBLIC_RATING_GOOD_COLOR
                ? process.env.NEXT_PUBLIC_RATING_GOOD_COLOR
                : "#57A6D9",
            label: process.env.NEXT_PUBLIC_RATING_GOOD_LABEL,
            hsl: process.env.NEXT_PUBLIC_RATING_GOOD_HSL ?? '203.54, 63.11%, 59.61%',
            from: process.env.NEXT_PUBLIC_RATING_GOOD_FROM ? parseInt(process.env.NEXT_PUBLIC_RATING_GOOD_FROM, 10) : 70,
            to: process.env.NEXT_PUBLIC_RATING_GOOD_TO ? parseInt(process.env.NEXT_PUBLIC_RATING_GOOD_TO, 10) : 89
        },
        {
            background: process.env.NEXT_PUBLIC_RATING_FAIR_COLOR
                ? process.env.NEXT_PUBLIC_RATING_FAIR_COLOR
                : "#F6921F",
            color: process.env.NEXT_PUBLIC_RATING_FAIR_COLOR
                ? process.env.NEXT_PUBLIC_RATING_FAIR_COLOR
                : "#F6921F",
            label: process.env.NEXT_PUBLIC_RATING_FAIR_LABEL,
            hsl: process.env.NEXT_PUBLIC_RATING_FAIR_HSL ?? '32.09, 92.27%, 54.31%',
            from: process.env.NEXT_PUBLIC_RATING_FAIR_FROM ? parseInt(process.env.NEXT_PUBLIC_RATING_FAIR_FROM, 10) : 50,
            to: process.env.NEXT_PUBLIC_RATING_FAIR_TO ? parseInt(process.env.NEXT_PUBLIC_RATING_FAIR_TO, 10) : 69
        },
        {
            background: process.env.NEXT_PUBLIC_RATING_POOR_COLOR
                ? process.env.NEXT_PUBLIC_RATING_POOR_COLOR
                : "#F05E24",
            color: process.env.NEXT_PUBLIC_RATING_POOR_COLOR
                ? process.env.NEXT_PUBLIC_RATING_POOR_COLOR
                : "#F05E24",
            label: process.env.NEXT_PUBLIC_RATING_POOR_LABEL,
            hsl: process.env.NEXT_PUBLIC_RATING_POOR_HSL ?? '17.06, 87.18%, 54.12%',
            from: process.env.NEXT_PUBLIC_RATING_POOR_FROM ? parseInt(process.env.NEXT_PUBLIC_RATING_POOR_FROM, 10) : 30,
            to: process.env.NEXT_PUBLIC_RATING_POOR_TO ? parseInt(process.env.NEXT_PUBLIC_RATING_POOR_TO, 10) : 49
        },
        {
            background: process.env.NEXT_PUBLIC_RATING_BAD_COLOR
                ? process.env.NEXT_PUBLIC_RATING_BAD_COLOR
                : "#E7212E",
            color: process.env.NEXT_PUBLIC_RATING_BAD_COLOR
                ? process.env.NEXT_PUBLIC_RATING_BAD_COLOR
                : "#E7212E",
            label: process.env.NEXT_PUBLIC_RATING_BAD_LABEL,
            hsl: process.env.NEXT_PUBLIC_RATING_BAD_HSL ?? '356.06, 80.49%, 51.76%',
            from: process.env.NEXT_PUBLIC_RATING_BAD_FROM ? parseInt(process.env.NEXT_PUBLIC_RATING_BAD_FROM, 10) : 0,
            to: process.env.NEXT_PUBLIC_RATING_BAD_TO ? parseInt(process.env.NEXT_PUBLIC_RATING_BAD_TO, 10) : 29
        }
    ];
    if (query) {
        const notAvailableObject = {
            background: "#000000",
            color: "#000000",
            label: process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE ? process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE : "N/A"
        };
        let queried = false;
        if (isNaN(query)) {
            queried = colorsData.find((x) => x.label?.toUpperCase() === query.toUpperCase()
            )
            return queried ? queried : notAvailableObject
        } else {
            const q = parseInt(query, 10)
            queried = colorsData.find((x) => ((x.from < q || x.from === q) && (x.to === q || x.to > q)))
            return queried ? queried : notAvailableObject
        }
    }
    return colorsData;
}

export function assetTypeColors(query = undefined) {
    const colorsData = [
        {
            type: "domain",
            color: process.env.NEXT_PUBLIC_ASSET_TYPE_DOMAIN_COLOR ?? "#5B949F"
        },
        {
            type: "email",
            color: process.env.NEXT_PUBLIC_ASSET_TYPE_EMAIL_COLOR ?? "#095F66"
        },
        {
            type: "dns",
            color: process.env.NEXT_PUBLIC_ASSET_TYPE_DNS_COLOR ?? "#ADD3D6"
        },
        {
            type: "host",
            color: process.env.NEXT_PUBLIC_ASSET_TYPE_HOST_COLOR ?? "#0A918E"
        },
        {
            type: "website",
            color: process.env.NEXT_PUBLIC_ASSET_TYPE_WEBSITE_COLOR ?? "#0A918E"
        },
        {
            type: "ip",
            color: process.env.NEXT_PUBLIC_ASSET_TYPE_IP_COLOR ?? "#ADD3D6"
        },
        {
            type: "network",
            color: process.env.NEXT_PUBLIC_ASSET_TYPE_NETWORK_COLOR ?? "#095F66"
        },
        {
            type: "AS",
            color: process.env.NEXT_PUBLIC_ASSET_TYPE_AS_COLOR ?? "#5B949F"
        }
    ];
    if (query) {
        const notAvailableObject = {
            color: "#000000",
            type: process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE ? process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE : "N/A"
        };
        let queried = false;
        if (isNaN(query)) {
            queried = colorsData.find((x) => x.type?.toUpperCase() === query.toUpperCase()
            )
            return queried ? queried : notAvailableObject
        }
    }
    return colorsData;
}

export const palette = {
    primary: {
        // turquoise: {
        "50": "#e7f4f4",
        "100": "#cfe9e8",
        "200": "#9ed3d2",
        "300": "#6ebdbb",
        "400": "#3da7a5",
        "500": "#0d918e",
        "600": "#0a7472",
        "700": "#085755",
        "800": "#053a39",
        "900": "#031d1c"
    },
    secondary: {
        "50": "#eff4f5",
        "100": "#deeaec",
        "200": "#bed5d9",
        "300": "#9dbfc5",
        "400": "#7daab2",
        "500": "#5c959f",
        "600": "#4a777f",
        "700": "#37595f",
        "800": "#253c40",
        "900": "#121e20"
    },
    card: {
        // dark: "#232728",
        dark: "#192328",
        light: "#f1f3f3"
    },
    back: {
        "50": "#f7fbfb",
        "100": "#eff6f7",
        "200": "#dfedef",
        "300": "#cee4e6",
        "400": "#bedbde",
        "500": "#aed2d6",
        "600": "#8ba8ab",
        "700": "#687e80",
        "800": "#465456",
        "900": "#232a2b"
    }
}