/* eslint-disable */
import QRCode from "react-qr-code";
function QRCodeImage(value) {
    console.log("QRCode:")
    console.log(value)
    if(value && value.value){
        value = value.value;
    }
    return (
        // Can be anything instead of `maxWidth` that limits the width.
        <div style={{
            background: 'white', 
            padding: '8px', 
            height: "auto", 
            margin: "0 auto", 
            maxWidth: 160, 
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "4px solid rgba(0, 0, 0, 0.7)",
            borderRadius: "4px"
        }}>
            <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={value}
                viewBox={`0 0 256 256`}
            />
        </div>
    )
}
export default QRCodeImage;
/* eslint-disable */