/* eslint-disable */
import { Base64 } from 'js-base64';
import { useEffect, useState } from "react";
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/material-ui';
import { Collapse, Paper, Stack, TablePagination } from '@mui/material';

import { usePagination } from '@table-library/react-table-library/pagination';
import { useSort } from '@table-library/react-table-library/sort';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "layouts/cysr/components/ui/dropdown-menu"
import { Button } from "layouts/cysr/components/ui/button"

import cysrConfig from "layouts/cysr/config";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import ScoreItem from "layouts/cysr/components/ScoreItem";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import StatusItem from "layouts/cysr/components/StatusItem";
import CysrTags from "layouts/cysr/components/CysrTags";
import CysrTableButtonActions from "layouts/cysr/components/cysrTableButtonActions";
// import FormField from "layouts/pages/account/components/FormField";

import { useMaterialUIController } from "context";
import { assetAdditionalInfo, assetTags } from "../../assetAdditionalInfo";
import { KeyboardArrowDownSharp } from "@mui/icons-material";

const Component = ({ defaultData, defaultSearch, defaultTagsActive, defaultTagsOptions }) => {

  let accountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/company/") + "/company/".length,
    window.location.pathname.lastIndexOf("/")
  );
  if (localStorage.getItem("cysr_useraccountrole_selected")) {
    accountUUID = localStorage.getItem("cysr_useraccountrole_selected");
  }
  // const [data, setData] = useState(({ nodes: defaultData}))
  const data = { nodes: defaultData };
  const actionsPixelWidth = 148;
  const tableRowsPerPage = [25, 50, 100, 200, 400];
  const [tableTagsActive, setTableTagsActive] = useState(defaultTagsActive);
  const [tableTagsOptions, setTableTagsOptions] = useState(defaultTagsOptions);
  // https://react-table-library.com/?path=/docs/compact-table--columnhide
  const initialHiddenColumns = window.innerWidth > 1680 ? [
    "Created",
    "Source",
    "Tags",
  ] : [
    "Additional info",
    "Created",
    "Source",
    "Tags",
  ]
  const [hiddenColumns, setHiddenColumns] = useState(initialHiddenColumns);
  const toggleColumn = (column) => {
    if (hiddenColumns.includes(column)) {
      setHiddenColumns(hiddenColumns.filter((v) => v !== column));
    } else {
      setHiddenColumns(hiddenColumns.concat(column));
    }
  };
  const [search, setSearch] = useState(defaultSearch);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const callUrl = `${cysrConfig().api_base_url}account/${accountUUID}/asset/`;

  /*
  useEffect(() => {
    fetch(callUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      },
    })
      .then((response) => response.json())
      .then((d) => {
        if (d.code && d.code === "token_not_valid") {
          // remove localstorage and go to login
          const dMode = localStorage.getItem('cysr_dark_mode') ? localStorage.getItem('cysr_dark_mode') : "true";
          localStorage.clear();
          localStorage.setItem('cysr_dark_mode', dMode);
          window.location.href = `/cysr/login`;
          return
        }
        setData({ nodes: d })
      })
      .catch((err) => {
        console.error(err)
      });
  }, []);
  */
  // console.log('data')
  // console.log(data)

  const COLUMNS = [
    {
      label: 'Status',
      renderCell: (item) => <StatusItem statusValue={item.asset_status} />,
      sort: { sortKey: 'STATUS' },
      resize: true,
      cysrTag: "asset_status",
      hide: hiddenColumns.includes("Status"),
    },
    {
      label: 'Rating',
      renderCell: (item) => item.asset_last_rating && item.asset_last_rating.rating_val ? <ScoreItem scoreValue={item.asset_last_rating.rating_val} circle={true} textual={true} item_style="small" /> : "N/A",
      sort: { sortKey: 'RATING' },
      resize: true,
      hide: hiddenColumns.includes("Rating"),
    },
    {
      label: 'Type',
      renderCell: (item) => item.generalasset_type,
      sort: { sortKey: 'TYPE' },
      resize: true,
      cysrTag: "generalasset_type",
      hide: hiddenColumns.includes("Type"),
    },
    {
      label: 'Value',
      renderCell: (item) => item.generalasset_val,
      sort: { sortKey: 'VALUE' },
      resize: true,
      cysrTag: "generalasset_val",
      hide: hiddenColumns.includes("Value"),
    },
    {
      label: 'Tags',
      renderCell: (item) => <div style={{ display: "flex", flexWrap: "wrap", gap: "8px", paddingTop: "8px", marginTop: "auto" }}>
        {item.asset_usertags && item.asset_usertags.map((t) => <MDBadge style={{ cursor: "pointer" }} key={t} variant="contained" badgeContent={t} size="xs" container onClick={
          () => {
            let newTags = [];
            tableTagsActive && tableTagsActive.forEach(x => {
              if (x !== t && x !== "tag:" + t) {
                newTags.push(x)
              }
            });
            newTags.push("tag:" + t)
            handleSetTableTags(null, newTags)
          }
        } />)}
      </div>,
      sort: false,
      resize: true,
      cysrTag: "asset_usertags",
      hide: hiddenColumns.includes("Tags"),
    },
    {
      label: 'Additional info',
      renderCell: (item) => assetAdditionalInfo(item.generalasset_additional_info),
      sort: false,
      resize: true,
      cysrTag: "generalasset_additional_info",
      hide: hiddenColumns.includes("Additional info"),
    },
    {
      label: 'Highlights',
      renderCell: (item) => assetTags(item.generalasset_tags),
      sort: false,
      resize: true,
      cysrTag: "generalasset_tags",
      hide: hiddenColumns.includes("Highlights"),
    },
    {
      label: 'Created',
      renderCell: (item) =>
        item.mu_created_at ? new Date(item.mu_created_at).toLocaleDateString('it-IT', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }) : "",
      sort: { sortKey: 'CREATED' },
      resize: true,
      cysrTag: "mu_created_at",
      cysrTagDateFormat: 'it-IT',
      hide: hiddenColumns.includes("Created"),
    },
    {
      label: 'Updated',
      renderCell: (item) =>
        item.generalasset_lastcheck_datetime ? new Date(item.generalasset_lastcheck_datetime).toLocaleDateString('it-IT', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }) : "",
      sort: { sortKey: 'UPDATED' },
      resize: true,
      cysrTag: "generalasset_lastcheck_datetime",
      cysrTagDateFormat: 'it-IT',
      hide: hiddenColumns.includes("Updated"),
    },
    {
      label: 'Source',
      renderCell: (item) => (item.assetsrc_name.toString() === "checker") ? "auto" : item.assetsrc_name.toString(),
      sort: { sortKey: 'SOURCE' },
      cysrTag: "assetsrc_name",
      hide: hiddenColumns.includes("Source"),
      // resize: true
    },
    {
      label: 'Actions',
      renderCell: (item) => (
        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '8px', width: `${actionsPixelWidth}px` }}>
          <Link to={`/company/${accountUUID}/${"asset"}#${item.asset_uuid}`}>
            <MDButton style={cysrConfig().button_style} size="medium">
              Go to asset
              <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
            </MDButton>
          </Link>
        </div>
      ),
      pinRight: true
      // sort: { sortKey: 'RATING' }
    },
  ];


  // csv download s
  const escapeCsvCell = (cell) => {
    if (cell == null) {
      return '';
    }
    const sc = cell.toString().trim();
    if (sc === '' || sc === '""') {
      return sc;
    }
    if (sc.includes('"') || sc.includes(',') || sc.includes('\n') || sc.includes('\r')) {
      return '"' + sc.replace(/"/g, '""') + '"';
    }
    return sc;
  };

  const makeCsvData = (columns, data) => {
    return data.reduce((csvString, rowItem) => {
      return (
        csvString +
        columns.map(({ accessor }) => escapeCsvCell(accessor(rowItem))).join(',') +
        '\r\n'
      );
    }, columns.map(({ name }) => escapeCsvCell(name)).join(',') + '\r\n');
  };

  const downloadAsCsv = (columns, data, filename) => {
    const csvData = makeCsvData(columns, data);
    const csvFile = new Blob([csvData], { type: 'text/csv' });
    const downloadLink = document.createElement('a');

    downloadLink.display = 'none';
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  function getValue(scoreValue) {
    let textValue = "N/A";
    if (isNaN(scoreValue)) {
      // non è un numero
    } else if (scoreValue < 30) {
      textValue = "Bad";
    } else if (scoreValue < 50) {
      textValue = "Poor";
    } else if (scoreValue < 70) {
      textValue = "Fair";
    } else if (scoreValue < 90) {
      textValue = "Good";
    } else if (scoreValue < 100 || scoreValue == 100) {
      textValue = "Excellent";
    }
    return textValue;
  }

  const handleDownloadCsv = () => {
    const columns = [
      { accessor: (item) => item.asset_status, name: 'Status' },
      { accessor: (item) => item.asset_last_rating && item.asset_last_rating.rating_val && getValue(item.asset_last_rating.rating_val), name: 'Rating' },
      { accessor: (item) => item.generalasset_type, name: 'Type' },
      { accessor: (item) => item.generalasset_val, name: 'Value' },
      { accessor: (item) => item.asset_usertags && JSON.stringify(item.asset_usertags), name: 'Tags' },
      { accessor: (item) => item.generalasset_additional_info && JSON.stringify(item.generalasset_additional_info), name: 'Additional info' },
      { accessor: (item) => item.generalasset_tags && JSON.stringify(item.generalasset_tags), name: 'Highlights' },
      { accessor: (item) => item.mu_created_at, name: 'Created' },
      { accessor: (item) => item.generalasset_lastcheck_datetime, name: 'Updated' },
      { accessor: (item) => item.assetsrc_name, name: 'Source' },
    ];

    downloadAsCsv(columns, data.nodes, 'Assets');
  };
  // csv download e

  // expandable
  const [ids, setIds] = useState([]);
  let autoString = "";
  // actions is hide undefined so not pass filter
  COLUMNS.filter((y) => y.hide !== undefined && y.hide !== true).forEach(c => {
    // set 50px for status
    if (c.label === "Value") {
      autoString += " minmax(360px, 1fr)";
    }
    // set 50px for status
    else if (c.label === "Status") {
      autoString += " 50px";
    }
    // set 60px for source
    else if (c.label === "Source") {
      autoString += " 60px";
    }
    // set 70px for type
    else if (c.label === "Type") {
      autoString += " 70px";
    }
    // set 100px for created/updated
    else if (c.label === "Created" || c.label === "Updated") {
      autoString += " 100px";
      // set 112px for rating
    } else if (c.label === "Rating") {
      autoString += " 112px";
    }
    // set 320px for info
    else if (c.label === "Additional info") {
      autoString += " 320px";
    } else {
      autoString += " minmax(0, 1fr)";
    }
  });
  const expandedTableStyle = `    
    --data-table-library_grid-template-columns:${autoString} ${actionsPixelWidth}px!important;

    .animate {
      grid-column: 1 / -1;
      display: flex;
    }
    .animate {
        visibility: visible;
        border-bottom: 1px solid #${darkMode ? "4a4545" : "f0f2f5"};
    }
    .animate > div {
      flex: 1;
      display: flex;
    }
    
    @media (min-width: 768px){
      .pin-right {
        right: 0;
      }
    }
  `;

  const handleExpand = (item) => {
    if (ids.includes(item.id)) {
      setIds(ids.filter((id) => id !== item.id));
    } else {
      setIds(ids.concat(item.id));
    }
  };

  const ROW_OPTIONS = {}
  /* this for expand/collapse
  const ROW_OPTIONS = {
    renderAfterRow: (item) => (
      <Collapse className="animate" in={ids.includes(item.id)}>
        <div style={{ flex: '1', display: 'flex' }}>
          <div style={{ flex: '1', backgroundColor: darkMode ? "#121619" : "#edf0f2" }}>
            <MDBox style={{ position: "sticky", left: 0, right: 0 }} maxWidth={{ xs: "calc(100vw - (48px + 64px))", xl: "calc(100vw - (282px + 48px + 58px))" }}>
              <Card style={{ backgroundColor: darkMode ? "#121619" : "#edf0f2", color: darkMode ? "#7B809A" : "#7B809A", borderRadius: 0, boxShadow: "none", padding: "1em", margin: 0 }}>
                <MDBox width="100%">
                
                </MDBox>
              </Card>
            </MDBox>
          </div>
        </div>
      </Collapse>
    ),
  };
  */

  const materialTheme = getTheme(DEFAULT_OPTIONS, {
    isVirtualized: true
  });

  // to set row border bottom style:
  // border-bottom: 1px solid #${darkMode ? "4a4545" : "f0f2f5"};

  const customTheme = {
    Table: expandedTableStyle,
    BaseCell: `
      border-color: #f0f2f5!important;
      padding: 4px;
    `,
  };
  let theme = useTheme([materialTheme, customTheme]);
  if (darkMode) {
    // provide custom theme
    theme = useTheme([materialTheme, {
      Table: expandedTableStyle,
      BaseCell: `
      color: #ffffff;
      background-color: #192328;
      border-color: #4a4545!important;
      padding: 4px;
      `,
    }
    ]);
  }

  const pagination = usePagination(data, {
    state: {
      page: 0,
      // size: 2,
      // size: tData.nodes.length,
      size: tableRowsPerPage[0],
    },
    // onChange: onPaginationChange,
  });

  /*
  function onPaginationChange(action, state) {
    console.log(action, state);
  }
  */

  const sort = useSort(
    data,
    {
      state: {
        sortKey: 'RATING',
        reverse: false
      },
      // onChange: onSortChange,
    },
    {
      sortIcon: {
        iconDefault: null,
        iconUp: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
        </svg>
        ,
        iconDown: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
        ,
      },
      sortFns: {
        STATUS: (array) => array.sort((a, b) => a.asset_status.localeCompare(b.asset_status)),
        TYPE: (array) => array.sort((a, b) => a.generalasset_type.localeCompare(b.generalasset_type)),
        RATING: (array) => array.sort(function (a, b) {
          let aR = a.asset_last_rating && a.asset_last_rating.rating_val ? a.asset_last_rating.rating_val : "N/A";
          let bR = b.asset_last_rating && b.asset_last_rating.rating_val ? b.asset_last_rating.rating_val : "N/A";
          if (!aR || aR === null || aR === undefined || aR === "" || aR === "N/A") {
            aR = 101;
          }
          if (!bR || bR === null || bR === undefined || bR === "" || bR === "N/A") {
            bR = 101;
          }
          return aR - bR
        }),
        VALUE: (array) => array.sort((a, b) => a.generalasset_val.localeCompare(b.generalasset_val)),
        CREATED: (array) => array.sort((a, b) =>
          new Date(b.mu_created_at).getTime() - new Date(a.mu_created_at).getTime()
        ),
        UPDATED: (array) => array.sort((a, b) =>
          new Date(b.generalasset_lastcheck_datetime).getTime() - new Date(a.generalasset_lastcheck_datetime).getTime()
        ),
        SOURCE: (array) => array.sort((a, b) => a.assetsrc_name.localeCompare(b.assetsrc_name)),
        // TYPE: (array) => array.sort((a, b) => a.type.localeCompare(b.type)),
        // COMPLETE: (array) => array.sort((a, b) => a.isComplete - b.isComplete),
        // TASKS: (array) => array.sort((a, b) => a.tasks || 0 - b.tasks || 0),
        // TASKS: (array) => array.sort((a, b) => (a.nodes || []).length - (b.nodes || []).length),
      },
    },
  );

  /*
  function onSortChange(action, state) {
    console.log(action, state);
  }
  */

  const handleSetTableTags = (event, t) => {
    // console.log(t)
    setTableTagsActive(t);
    // replace url s
    let params = new URLSearchParams(window.location.search)
    params.delete('t')
    if (t != "" && t.length > 0) {
      params.append("t", Base64.encode(JSON.stringify(t)))
    }
    window.history.replaceState(null, '', '?' + params + window.location.hash)
    // replace url e
    // go to first page
    pagination.fns.onSetPage(0)
  };

  const VIRTUALIZED_OPTIONS = {
    rowHeight: (_item, _index) => {
      return 64
    }
  };

  return data && data.nodes ? (
    <>
      <Stack spacing={10} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CysrTags tableTagsOptions={tableTagsOptions} tableTagsActive={tableTagsActive} handleSetTableTags={handleSetTableTags} darkMode={darkMode} />
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={darkMode ? "dark" : "light"}>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" className="ml-auto text-black dark:text-white">
                    Columns <KeyboardArrowDownSharp className="ml-2 h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent style={{
                  backgroundColor: darkMode ? "#50666b" : "#cddddf",
                  color: darkMode ? "#fff" : "#000"
                }} align="end">
                  {COLUMNS
                    .filter((column) => column.hide !== undefined)
                    .map((column) => {
                      return (
                        <DropdownMenuCheckboxItem
                          key={column.label}
                          className="cursor-pointer hover:bg-turquoise-100 hover:text-turquoise-900"
                          checked={!(column.hide)}
                          onCheckedChange={() => {
                            toggleColumn(column.label)
                          }}
                        >
                          {column.label}
                        </DropdownMenuCheckboxItem>
                      )
                    })}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </Grid>
        </Grid>
      </Stack>
      {
        // not virtualized
        // <Paper style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none', height: 'auto' }}>
        // (virtualized/not virtualized) fixed header
        // <Paper style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none', height: '720px' }}>
      }
      <Paper style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none', height: '720px' }}>
        <CompactTable
          columns={COLUMNS}
          // passando le virtualizedOptions non funziona l'expand abilitato, penso perchè imposta l'height da solo
          virtualizedOptions={VIRTUALIZED_OPTIONS}
          data={{
            nodes: data.nodes.filter((item) => {
              /*
              // check filter first
              if(hideCompleted && item.isComplete){
                return false
              }
              */
              // check tags
              // da fare tags con chiave valore
              if (tableTagsActive && tableTagsActive.length > 0) {
                let trueCount = tableTagsActive.length;
                const scoreValue = item.asset_last_rating && item.asset_last_rating.rating_val ? item.asset_last_rating.rating_val : "N/A";
                let textValue = "Bad";
                if (scoreValue === "N/A") {
                  textValue = "N/A";
                } else if (scoreValue < 30) {
                  textValue = "Bad";
                } else if (scoreValue < 50) {
                  textValue = "Poor";
                } else if (scoreValue < 70) {
                  textValue = "Fair";
                } else if (scoreValue < 90) {
                  textValue = "Good";
                } else if (scoreValue < 100 || scoreValue == 100) {
                  textValue = "Excellent";
                }
                tableTagsActive.forEach(ta => {
                  let notFound = true;
                  // X:item.cysrTag s
                  if (ta && typeof ta === 'string' && ta.includes(":")) {
                    const tag = ta.toLowerCase().split(":");
                    const tagKey = tag[0].toLowerCase();
                    const tagValue = tag[1].toString().trim();
                    if (tagKey === "rating" && tagValue.toLowerCase() === textValue.toLowerCase()) {
                      if (notFound) {
                        trueCount--;
                        notFound = false;
                      }
                    }
                    if (tagKey === "tag" && item.asset_usertags.find(x => tagValue.toLowerCase() === x.toLowerCase())) {
                      if (notFound) {
                        trueCount--;
                        notFound = false;
                      }
                    }
                    COLUMNS.forEach(col => {
                      if (col.cysrTag && item[col.cysrTag] && col.label.toLowerCase() === tagKey && item[col.cysrTag.toLowerCase()] && item[col.cysrTag.toLowerCase()]) {
                        // check cysrTagDateFormat
                        if (col.cysrTagDateFormat) {
                          const vd = new Date(item[col.cysrTag.toLowerCase()].toString()).toLocaleDateString(col.cysrTagDateFormat, {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          })
                          if (vd.toString().includes(tagValue) && notFound) {
                            trueCount--;
                            notFound = false;
                          }
                        } else if (item[col.cysrTag.toLowerCase()].toString().toLowerCase().includes(tagValue)) {
                          if (notFound) {
                            trueCount--;
                            notFound = false;
                          }
                        }
                      }
                    })
                  }
                  // X:item.cysrTag e
                  // compare (for freeSolo) s
                  if (ta && ta.rating && ta.rating === textValue) {
                    if (notFound) {
                      trueCount--;
                      notFound = false;
                    }
                  }
                  [
                    "asset_status",
                    "assetsrc_name",
                    "generalasset_type",
                    "generalasset_val"
                  ].forEach(property => {
                    if (ta && ta[property]) {
                      // a default option (with [property])
                      if (ta && ta[property] && item[property] && item[property].toLowerCase().includes(ta[property].toLowerCase())) {
                        if (notFound) {
                          trueCount--;
                          notFound = false;
                        }
                      }
                    } else {
                      if (ta && ta.rating) {
                        // avoid checking again ta with title (rating Excellent, Good...)
                      } else if (ta === textValue) {
                        // checking ta without title written by user ("Excellent", "Good"...)
                        if (notFound) {
                          trueCount--;
                          notFound = false;
                        }
                      } else {
                        // not a default option (with [property])
                        if (ta && item[property] && item[property].toLowerCase().includes(ta.toLowerCase())) {
                          if (notFound) {
                            trueCount--;
                            notFound = false;
                          }
                        }
                      }
                    }
                  });
                  // compare (for freeSolo) e
                });
                if (trueCount !== 0) {
                  return false;
                }
              }
              // check search at last
              // ..
              return true;
            }
            )
          }}
          sort={sort}
          theme={theme}
          rowOptions={ROW_OPTIONS}
          layout={{
            isDiv: true,
            fixedHeader: true
          }}
          pagination={pagination}
          style={{ borderColor: 'transparent' }}
        // horizontalScroll={true}
        />
      </Paper >
      <Grid mt={0} container style={{
        alignItems: "center",
        borderTop: `1px solid #${darkMode ? "4a4545" : "f0f2f5"}`,
        justifyContent: "space-between"
      }} width="100%" spacing={1}>
        <Grid item>
          <CysrTableButtonActions
            options={
              [
                {
                  label: "CSV",
                  color: "info",
                  value: "CSV",
                  onClick: "download_csv",
                  key: 0,
                  icon: cysrConfig().report.icon.svg
                }
              ]
            }
            triggerAction={
              function (fun) {
                switch (fun) {
                  case "download_csv":
                    handleDownloadCsv()
                    break;

                  default:
                    break;
                }
              }
            } />
        </Grid>
        <Grid item ml={"auto"}>
          <TablePagination
            component="div"
            style={{ color: darkMode ? "#ffffff" : "#000000", border: 0 }}
            count={data && data.nodes ? data.nodes.length : 0}
            page={pagination.state.page}
            rowsPerPage={pagination.state.size}
            rowsPerPageOptions={tableRowsPerPage}
            onRowsPerPageChange={(event) =>
              pagination.fns.onSetSize(parseInt(event.target.value, 10))
            }
            onPageChange={(event, page) => pagination.fns.onSetPage(page)}
          />
        </Grid>
      </Grid>
    </>
  ) : null;
};
export default Component;
/* eslint-disable */