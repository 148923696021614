/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import cysrConfig from "layouts/cysr/config";

function ChangePassword({ toggleSnackbar }) {
  const passwordRequirements = [
    "Your password can’t be too similar to your other personal information.",
    "Your password must contain at least 10 characters.",
    "Your password can’t be a commonly used password.",
    "Your password can’t be entirely numeric."
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox key={itemKey} component="li" color="text" fontSize="0.875rem" lineHeight={1}>
        <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card id="change-password">
      <MDBox p={3}>
        <MDTypography variant="h5">Change Password</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="Current Password"
              id="profile_old_password"
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="New Password"
              id="profile_new_password"
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="Confirm New Password"
              id="profile_confirm_new_password"
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
        </Grid>
        <MDBox mt={6} mb={1}>
          <MDTypography variant="h5">Password requirements</MDTypography>
        </MDBox>
        <MDBox mb={1}>
          <MDTypography variant="body2" color="text">
            Please follow this guide for a strong password
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          {
            //<MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
          }
          <ul className="flex flex-col gap-1 list-disc list-inside marker:text-teal-500">
            {renderPasswordRequirements}
          </ul>
          {
            // </MDBox>
          }
          <MDBox ml="auto">

            <MDButton style={cysrConfig().button_style} size="medium" onClick={() => {
              if (
                document.getElementById("profile_old_password")
                && document.getElementById("profile_old_password").value
                && document.getElementById("profile_old_password").value != ""
                && (
                  (
                    document.getElementById("profile_new_password")
                    && document.getElementById("profile_new_password").value
                    && document.getElementById("profile_new_password").value != ""
                  ) || (
                    document.getElementById("profile_confirm_new_password")
                    && document.getElementById("profile_confirm_new_password").value
                    && document.getElementById("profile_confirm_new_password").value != ""
                  )
                )
              ) {
                if (document.getElementById("profile_confirm_new_password").value != document.getElementById("profile_new_password").value) {
                  toggleSnackbar("Passwords mismatch", "The new password fields do not match", "error", "error", "Error");
                } else {
                  const editProfileUrl = `${cysrConfig().api_base_url}user/myprofile/`;
                  fetch(editProfileUrl, {
                    body: JSON.stringify({
                      user_old_password: document.getElementById("profile_old_password").value,
                      user_new_password: document.getElementById("profile_new_password").value,
                    }),
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                      "content-type": "application/json",
                    },
                    method: "PATCH",
                  })
                    .then((response) => {
                      if (response.status === 200) {
                        response.json()
                          .then((responseData) => {
                            toggleSnackbar(responseData.Message, responseData.Detail, "info", "info", "Info");
                          })
                      } else if (response.ok || (response.status > 199 && response.status < 300)) {
                        response.json()
                          .then((responseData) => {
                            toggleSnackbar(responseData.Message, responseData.Detail, "success", "checkmark", "Saved");
                          })
                      } else {
                        return response.json().then(err => {
                          throw JSON.stringify(err)
                        })
                      }
                    })
                    .catch((err) => {
                      console.log("errP:", err);
                      toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
                      console.log(err);
                    });
                }
              } else {
                toggleSnackbar("Nothing to update", "Nothing to update", "info", "info", "Info");
              }
            }}>
              <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().update.icon.svg }} />
              Update password
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ChangePassword;
/* eslint-disable */