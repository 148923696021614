/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import cysrConfig from "layouts/cysr/config";
/* eslint-disable */
export default function StatusItem(score) {
  const statusValue = score.statusValue;
  const statusWithLabel = score.withLabel;
  const statusWithText = score.withText;
  /*
  ENABLED = "ENABLED", _("Enabled")  # normale / default
DISABLED = "DISABLED", _("Disabled")  # non vengono fatti ulteriori check, non concorre al rating, è comunque visibile nelle liste e nel grafico  ma è "ingrigiato". L'unica azione possibile è di riabilitarlo.
NOT_MINE = "NOT_MINE", _("Not mine")  # implica mu_deleted = True e sparisce da rating, liste e grafici. Non può essere raggiunto dal checker.
ACKNOWLEDGED = "ACKNOWLEDGED", _("Acknowledged")  # vengono fatti ulteriori check, ma non concorre al rating - E' marchiato con un colore particolare e nel report viene messo in evidenza.
BANNED = "BANNED ", _("Banned")  # Asset bannato d'ufficio. E' come DISABLED ma non può essere riabilitato dall'utente

ENABLED -> DISABLED 
ENABLED -> ACK
ENABLED -> NOT_MINE
DISABLED -> ENABLED
DISABLED -> ACK
DISABLED -> NOT_MINE
ACK -> ENABLED
ACK -> DISABLED
ACK -> NOT_MINE
BANNED -> NOT_MINE

SELECT:
en/dis/ack

REMOVE:
NOT_MINE
  */
  const s = cysrConfig().get_status(statusValue);
  let statusIcon = s.icon;
  let statusColor = s.color;
  // per ora tenere senza colore
  statusColor = "text";
  let statusLabel = s.label;
  let statusText = s.text;
  return (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <MDTypography variant="caption" color={statusColor} fontWeight="bold" title={statusLabel}
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Tooltip title={statusText} placement="top">
          <Icon>{statusIcon}</Icon>
        </Tooltip>
        {statusWithLabel && (
          <span>&nbsp;{statusLabel}</span>
        )}
      </MDTypography>
      {statusWithText && (
        <Tooltip title={statusText} placement="top">
          <MDTypography variant="caption" color="inherit">
            &nbsp;&nbsp;<Icon>info</Icon>
          </MDTypography>
        </Tooltip>
      )}
    </MDBox>
  );
}
/* eslint-disable */
