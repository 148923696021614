/* eslint-disable */
// prettier-ignore
import { Dialog, DialogPanel, Text, Textarea, Title } from '@tremor/react';
import { useState } from 'react';

import cysrConfig from "layouts/cysr/config";
import MDButton from "components/MDButton";
import { CloseSharp, UploadSharp, WarningSharp } from '@mui/icons-material';
import { toast } from "sonner"

export default function MultiAssetCreation({ accountUUID, darkMode, types }) {
    const [multiAssetValues, setMultiAssetValues] = useState([]);
    const [results, setResults] = useState([]);
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const handleChange = (e) => {
        const file = e.target.files[0];

        let reader = new FileReader();

        reader.onload = (e) => {
            let dataFromFile = null;
            let valuesFromFile = [];
            const file = e.target.result;
            let foundSeaparator = false;
            console.log(file);
            ["\r\n", ";", ","].forEach(separator => {
                if (foundSeaparator === false && file.indexOf(separator) > - 1) {
                    dataFromFile = splitFileData(file, separator)
                    foundSeaparator = true
                }
            });
            let textareaVal = "";
            dataFromFile.forEach((ite, index) => {
                if (types.indexOf(ite) > -1 && dataFromFile[index + 1]) {
                    textareaVal += `${ite}:${dataFromFile[index + 1]}\r\n`;
                    valuesFromFile.push({
                        type: ite,
                        value: dataFromFile[index + 1]
                    })
                }
            });
            // setValue(JSON.stringify(dataFromFile));
            setMultiAssetValues(valuesFromFile)
            setValue(textareaVal)
        };

        reader.onerror = (e) => alert(e.target.error.name);
        reader.readAsText(file);
    };
    return (
        <>
            <div className='relative'>
                <div className="absolute right-0 bottom-0 my-auto flex items-center" style={{ top: "-52px" }}>
                    <MDButton onClick={() => setIsOpen(true)} type="button" style={cysrConfig().button_style} size="medium">
                        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().upgrade.icon.svg }} />
                        Add multiple assets
                    </MDButton>
                </div>
            </div>
            <Dialog className={darkMode ? 'dark' : 'light'} style={{
                zIndex: 999999
            }} open={isOpen} onClose={(val) => setIsOpen(val)} static={true}>
                <DialogPanel className='bg-turquoise-100 dark:bg-turquoise-800 border-turquoise-50 dark:border-turquoise-900'>
                    <button onClick={() => setIsOpen(false)} className='absolute right-4 top-4'>
                        <CloseSharp className='h-4 w-4' />
                    </button>
                    <div
                    >
                        <div className="flex flex-col gap-2">
                            <Title>Import assets list from CSV</Title>
                            <Text>Add multiple assets to the company</Text>
                            <Text className='text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis'>
                                <h3>CSV Format</h3>
                                <ul className='flex flex-col gap-1 list-disc list-inside marker:text-teal-500'>
                                    <li>Format your CSV the same way <a className="cursor-pointer font-medium text-turquoise-400" onClick={() => {
                                        /*
                                        ;;;;;;
                                        domain;4found.it;;;;;
                                        website;www.4found.it;;;;;
                                        ip;155.220.63.186;;;;;
                                        */
                                        const csvData = [
                                            ["domain", "my-company-domain.com"],
                                            ["website", "www.my-company-domain.com"],
                                            ["ip", "185.164.178.42"]
                                        ];

                                        const csvContent = csvData.map(row => row.join(',')).join('\r\n');
                                        const csvFile = new Blob([csvContent], { type: 'text/csv' });
                                        const downloadLink = document.createElement('a');

                                        downloadLink.style.display = 'none';
                                        downloadLink.download = 'multi-asset-sample.csv';
                                        downloadLink.href = window.URL.createObjectURL(csvFile);
                                        document.body.appendChild(downloadLink);
                                        downloadLink.click();
                                        document.body.removeChild(downloadLink);

                                    }}>as this example</a></li>
                                    <li>Your CSV columns should be separated by commas</li>
                                    <li>Invalid values will be ignored, use correct values to avoid errors</li>
                                </ul>
                            </Text>
                            <label htmlFor="description" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                                Import a file to overwrite the textarea field value
                            </label>
                            <input type="file" name="input" onChange={handleChange} style={{
                                border: 0,
                                fontSize: 12
                            }} />
                            <label htmlFor="description" className="mt-4 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                                Your assets data to add
                            </label>
                            <Textarea
                                onChange={(e) => {
                                    setValue(e.target.value)
                                    setTimeout(() => {
                                        if (value != e.target.value) {
                                            setLoading(true)
                                            const val = e.target.value;
                                            let valuesFromTextarea = [];

                                            val.split('\n').forEach(ele => {
                                                if (ele.indexOf(":") > -1) {
                                                    ele = ele.trim();
                                                    valuesFromTextarea.push({
                                                        type: ele.split(":")[0],
                                                        value: ele.split(":")[1]
                                                    })
                                                }
                                            });
                                            setMultiAssetValues(valuesFromTextarea)
                                            setLoading(false)
                                        }
                                    }, 200);
                                }}
                                id="description"
                                placeholder="Start typing here..."
                                rows={6}
                                value={value}
                            />
                        </div>
                        <div className="mt-4 flex justify-end">
                            <MDButton onClick={() => {
                                if (loading === false) {
                                    setLoading(true)
                                    console.log(multiAssetValues)
                                    // create
                                    // {version}/account/{account_req_uuid}/asset/
                                    const createAssetUrl = `${cysrConfig().api_base_url}account/${accountUUID}/asset/`;
                                    let assetsToCreate = []
                                    multiAssetValues.forEach(a => {
                                        assetsToCreate.push({
                                            generalasset_type: a.type,
                                            generalasset_val: a.value,
                                            assetsrc_name: "manual",
                                            asset_status: "ENABLED",
                                            // ErrorMessage: 'Error', "ErrorDetail: ": `(1048, "Column 'generalasset_aggressivity' cannot be null")`
                                            generalasset_aggressivity: 5,
                                        })
                                    });
                                    const promises = assetsToCreate.map(data => {
                                        return fetch(createAssetUrl, {
                                            body: JSON.stringify(data),
                                            headers: {
                                                Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                                "content-type": "application/json",
                                            },
                                            method: "POST",
                                        }).then(response => {
                                            if (response.ok) {
                                                return response.json();
                                            } else {
                                                return response.json().then(err => {
                                                    throw { data, error: JSON.stringify(err) };
                                                });
                                            }
                                        }).catch(err => {
                                            setLoading(false)
                                            return { data, error: err };
                                        });
                                    });

                                    Promise.all(promises)
                                        .then(results => {
                                            setResults(results)
                                            const successfulResults = results.filter(result => !result.error);
                                            const failedResults = results.filter(result => result.error);

                                            if (failedResults.length > 0) {
                                                if (failedResults.length === results.length) {
                                                    toast.error("All requests failed.");
                                                } else {
                                                    toast.warning("Some requests failed.");
                                                }
                                                console.error("Some requests failed:", failedResults);
                                                // alert("Some requests failed:", failedResults);
                                                setLoading(false)
                                            } else {
                                                if (results.length > 0) {
                                                    toast.success("All requests succeeded.");
                                                    console.log("Succesful requests:", successfulResults);
                                                } else {
                                                    toast.info("No valid value provided.");
                                                }
                                                setLoading(false)
                                                // window.location.reload();
                                            }

                                        })
                                        .catch(err => {
                                            // setStringBtnCreateAsset(btnCreateAssetDefaultString);
                                            console.error("Error:", err);
                                            setLoading(false)
                                            // toggleSnackbar(
                                            //     err.ErrorMessage ? err.ErrorMessage : err.CheckStatus,
                                            //     err.ErrorDetail ? err.ErrorDetail : err.CheckDetail
                                            // );
                                        });
                                }
                            }} fullWidth type="button" {...(value === "" && { disabled: true })} style={cysrConfig().button_style} size="medium">
                                {loading ? (
                                    /*
                                    <div style={{height: "20px", width: "20px", marginRight: "8px"}}><Loader /></div>
                                    */
                                    <span style={cysrConfig().button_icon_left_style} className="spin" dangerouslySetInnerHTML={{ __html: cysrConfig().loading.icon.svg }} />
                                ) : (
                                    <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().add.icon.svg }} />
                                )}Create multiple assets</MDButton>
                        </div>
                        {results && results.length > 0 ?
                            <div className="mt-4 flex flex-col gap-2">
                                <label htmlFor="description" style={{ fontSize: 16 }} className="mt-4 text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis font-medium">
                                    {`New assets status (${(results.filter(result => !result.error)).length}/${results.length} successful)`}
                                </label>
                                <ul className='flex flex-col text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis gap-1 list-disc list-inside marker:text-teal-500'>
                                    {results && results.map((a) => <li key={a.data ? a.data.generalasset_val : a.generalasset_val} className='text-xs w-full'>
                                        {a.data ? a.data.generalasset_type : a.generalasset_type}:{a.data ? a.data.generalasset_val : a.generalasset_val} <span className='ml-auto font-medium'>{a.error && a.error.error
                                            ? <strong className='font-medium'><WarningSharp className='text-red-500 h-4 w-4' />{JSON.parse(a.error.error).ErrorDetail}</strong> : "succesfully created"}</span>
                                    </li>
                                    )}
                                </ul>
                                <MDButton onClick={() => {
                                    if (loading === false) {
                                        window.location.reload()
                                    }
                                }} fullWidth type="button" {...(value === "" && { disabled: true })} style={cysrConfig().button_style} size="medium">
                                    {loading ? (
                                        /*
                                        <div style={{height: "20px", width: "20px", marginRight: "8px"}}><Loader /></div>
                                        */
                                        <span style={cysrConfig().button_icon_left_style} className="spin" dangerouslySetInnerHTML={{ __html: cysrConfig().loading.icon.svg }} />
                                    ) : (
                                        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().update.icon.svg }} />
                                    )}{`Reload to see the new assets (${(results.filter(result => !result.error)).length}/${results.length})`}</MDButton>
                            </div>
                            :
                            null
                        }
                    </div>
                </DialogPanel>
            </Dialog>
        </>
    );
}

function splitFileData(file, separator) {
    let arr = []
    if (separator === '\r\n') {
        file = file.split('\r\n').join(',')
        separator = ','
    }
    if (file.indexOf(separator) > -1) {
        file.split(separator).forEach(element => {
            if (element && element.trim() != "") {
                console.log(element.trim());
                arr.push(element.trim());
            }
        });
    }
    return arr
}
