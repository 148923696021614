/* eslint-disable */
// prettier-ignore
// import { Table } from "@tanstack/react-table";
import {
    ChevronLeft,
    ChevronRight,
    ChevronsLeft,
    ChevronsRight,
} from "lucide-react";

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "layouts/cysr/components/ui/select";
// to open selected rows sheet s 1/2
// import { DataTableSelectedSheet } from "./data-table-selected-sheet";
// to open selected rows sheet e 1/2

import cysrConfig, { process } from "layouts/cysr/config";
import MDButton from "components/MDButton";

export function DataTablePagination({
    table, darkMode
}) {
    return (
        <div
            style={{
                zIndex: 999999
            }}
            className={`flex items-center justify-between px-2${table.getFilteredSelectedRowModel().rows.length > 0
                ? // selected mode
                " fixed left-0 right-0 bottom-0 p-2 bg-turquoise-100 dark:bg-turquoise-700 w-full"
                : ""
                }`}
        >
            <div className="flex items-center space-x-6 lg:space-x-8">
                <div className="flex items-center space-x-2">
                    <p className="text-sm font-medium">Rows per page</p>
                    <Select
                        value={`${table.getState().pagination.pageSize}`}
                        onValueChange={(value) => {
                            table.setPageSize(Number(value));
                        }}
                    >
                        <SelectTrigger className="h-8 w-[70px]">
                            <SelectValue placeholder={table.getState().pagination.pageSize} />
                        </SelectTrigger>
                        <SelectContent side="top">
                            {[20, 30, 40, 50, 100].map((pageSize) => (
                                <SelectItem key={pageSize} value={`${pageSize}`}>
                                    {pageSize}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div className="flex w-[100px] items-center justify-center text-sm font-medium">
                    Page {table.getState().pagination.pageIndex + 1} of{" "}
                    {table.getPageCount()}
                </div>
                <div className="flex items-center space-x-2">
                    <MDButton
                        style={cysrConfig().button_style} size="medium"
                        variant="outline"
                        className="hidden h-8 w-8 p-0 lg:flex"
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <span className="sr-only">Go to first page</span>
                        <ChevronsLeft className="h-4 w-4" />
                    </MDButton>
                    <MDButton
                        style={cysrConfig().button_style} size="medium"
                        variant="outline"
                        className="h-8 w-8 p-0"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <span className="sr-only">Go to previous page</span>
                        <ChevronLeft className="h-4 w-4" />
                    </MDButton>
                    <MDButton
                        style={cysrConfig().button_style} size="medium"
                        variant="outline"
                        className="h-8 w-8 p-0"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        <span className="sr-only">Go to next page</span>
                        <ChevronRight className="h-4 w-4" />
                    </MDButton>
                    <MDButton
                        style={cysrConfig().button_style} size="medium"
                        variant="outline"
                        className="hidden h-8 w-8 p-0 lg:flex"
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        disabled={!table.getCanNextPage()}
                    >
                        <span className="sr-only">Go to last page</span>
                        <ChevronsRight className="h-4 w-4" />
                    </MDButton>
                </div>
            </div>
        </div>
    );
}
