// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/waffle

/* eslint-disable */
import './style.css'
import React from 'react';
import { WaffleChart } from "waffle-chart";

// https://github.com/chiangs/waffle-chart
export default function App({ data }) {
    const isMirrored = data[0].value > 50;
    return (
        <div style={{
            transform: isMirrored ? 'rotateX(180deg)' : ''
        }}>
            <WaffleChart
                partA={data[0].value}
                partB={100 - data[0].value}
                // partC={8}
                partAlabel={data[0].label}
                partAcolor={data[0].color}
                partBcolor={'#cccccc'}
                //partCcolor={'#439090'}
                showDataDisplay={false}
                isSquareFill={false}
                isFilledFromLeft={isMirrored ? false : true}
            />
        </div>
    )
}
/*
import React, { useMemo } from "react";
import { ResponsiveWaffle } from "@nivo/waffle";

const MyResponsiveWaffle = function (data) {
    const roundedData = useMemo(() => {
        let roundedData = [];
        if (data && data.data.length > 0) {
            data.data.forEach((element) => {
                // decrease the value down to the next integer
                if (element.value) {
                    element.value = Math.floor(element.value);
                }
                roundedData.push(element);
            });
        }
        return roundedData;
    }, [data.data]);

    console.log("roundedData");
    console.log(roundedData);

    return (
        <ResponsiveWaffle
            data={roundedData}
            total={100}
            rows={10}
            columns={10}
            colors={
                data && data.data[0] && data.data[0].color ? [data.data[0].color] : ["#000000"]
            }
        />
    );
};

export default function App(data) {
    return (
        <div style={{ height: 80, width: "100%", display: "inline-flex", justifyContent: "flex-start" }}>
            <MyResponsiveWaffle data={data.data} />
        </div>
    );
}
*/
/* eslint-disable */