import { useState } from "react";
import { Base64 } from 'js-base64';
import Grid from "@mui/material/Grid";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// import AlluvialAssetChart from "layouts/cysr/asset/AssetTypeTypologyDetail/AlluvialChart";
/* eslint-disable */
import AssetChart from "layouts/cysr/asset/AssetTypeTypologyDetail/AssetChart";
import WaffleChart from "layouts/cysr/asset/AssetTypeTypologyDetail/WaffleChart";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";

import {
    Dialog,
    DialogContent,
    DialogTrigger,
} from "layouts/cysr/components/ui/dialog";

import {
    Metric,
    Text,
} from "@tremor/react";

import MDButton from "components/MDButton";
import Collapse from "@mui/material/Collapse";
import InfoButton from "layouts/cysr/components/infoButton";
import ScoreItem from "layouts/cysr/components/ScoreItem";
import MDTypography from "components/MDTypography";
import DataTable from "layouts/cysr/components/DataTable";
import CysrTable from "layouts/cysr/components/CysrTable/highlights";
import CysrTableGroup from "layouts/cysr/components/CysrTable/highlightsTableGroup";

import BubbleChartLabel from "layouts/cysr/asset/BubbleChartLabel";
import BubbleChartValue from "layouts/cysr/asset/BubbleChartValue";
// @mui material components
// import Icon from "@mui/material/Icon";
import(`@carbon/charts/styles-${localStorage.getItem("cysr_dark_mode") && localStorage.getItem("cysr_dark_mode") === "false" ? "g10" : "g90"}.css`);
import { differenceInYears, format } from "date-fns";
import cysrConfig from "layouts/cysr/config";
import "layouts/cysr/overview/App.css"
import { CloseFullscreenSharp, FullscreenExitSharp, FullscreenSharp, OpenInFullSharp } from "@mui/icons-material";

const digitsAfterCommaToFixed = 1
const numericFormatter = (number) =>
    number < 1000
        ? `${Intl.NumberFormat("it").format(number.toFixed(digitsAfterCommaToFixed)).toString()}`
        : number < 1000000
            ? `${Intl.NumberFormat("it").format((number / 1000).toFixed(digitsAfterCommaToFixed)).toString()}K`
            : number < 1000000000
                ? `${Intl.NumberFormat("it").format((number / 1000000).toFixed(digitsAfterCommaToFixed)).toString()}M`
                : `${Intl.NumberFormat("it").format((number / 1000000000).toFixed(digitsAfterCommaToFixed)).toString()}B`;


// import cysrConfig from "layouts/cysr/config";
export default function AssetTypeTypologyDetail(asset) {
    const [expandedTable, setExpandedTableOpen] = useState(0);
    const skipDatesOlderThanYearsAmount = 4;
    let text = "";
    let main_blocks = [];
    let blocks = [];
    let gauges = [];
    let securityIssues = [];
    let alluvialData = [];
    let alluvialOptions = {};
    let waffles = [];
    let networkUrlTitle = "";
    let networkUrlValue = "";
    const assetType = asset.asset.generalasset.generalasset_type;

    // filter test s
    const params = new URLSearchParams(window.location.search);
    let activeTags = [];
    try {
        activeTags = params.get('t') && (decodeURI(params.get('t'))) && Base64.decode(decodeURI(params.get('t'))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('t')))) : [];
    } catch (error) {
        console.error("Error in setting active tags:")
        console.error(error)
    }
    // filter test e

    let highlights = asset.asset.highlights;
    // sort by highlightGA_sortorder
    highlights.sort(function (a, b) {
        return parseInt(a.highlightGA_sortorder, 10) - parseInt(b.highlightGA_sortorder, 10);
    });
    switch (assetType) {
        case ("as" || "asn"):
            if (asset.asset && asset.asset.asset_from && asset.asset.asset_from[0] && asset.asset.asset_from[0].generalasset && asset.asset.asset_from[0].generalasset.generalasset_val) {
                networkUrlTitle = "Related network";
                networkUrlValue = "https://www.cybersecurityrating.eu/_tmp/sankey-route/data_colored_v3.php?net=" + asset.asset.asset_from[0].generalasset.generalasset_val + "&aggregate=5&resolve=&rand=817728";
                // add waffles
                waffles = [
                    {
                        "title": "Direct connection",
                        "description": "43 route",
                        "data": [{
                            "id": "men",
                            "label": "men",
                            "value": 22,
                            "color": cysrConfig().rating_colors[4].color
                        }
                        ]
                    },
                    {
                        "title": "1 ASN hop",
                        "description": "13 route",
                        "data": [{
                            "id": "men",
                            "label": "men",
                            "value": 22,
                            "color": cysrConfig().rating_colors[3].color
                        }
                        ]
                    },
                    {
                        "title": "2 ASN hops",
                        "description": "39 route",
                        "data": [{
                            "id": "men",
                            "label": "men",
                            "value": 22,
                            "color": cysrConfig().rating_colors[2].color
                        }
                        ]
                    },
                    {
                        "title": "3 ASN hops",
                        "description": "30 route",
                        "data": [
                            {
                                "id": "men",
                                "label": "men",
                                "value": 22,
                                "color": cysrConfig().rating_colors[1].color
                            }
                        ]
                    },
                    {
                        "title": "More ASN hops",
                        "description": "30 route",
                        "data": [{
                            "id": "men",
                            "label": "men",
                            "value": 22,
                            "color": cysrConfig().rating_colors[0].color
                        }
                        ]
                    }
                ];
                gauges = [
                    {
                        "title": "% Traffico diretto",
                        "description": "42%",
                        "data": [{
                            "id": "men",
                            "label": "men",
                            "value": 22,
                            "color": cysrConfig().rating_colors[4].color
                        }
                        ]
                    },
                    {
                        "title": "Traffico Tier 1",
                        "description": "60%",
                        "data": [{
                            "id": "men",
                            "label": "men",
                            "value": 48,
                            "color": cysrConfig().rating_colors[3].color
                        }
                        ]
                    },
                    {
                        "title": "Traffico Tier 2",
                        "description": "86%",
                        "data": [{
                            "id": "men",
                            "label": "men",
                            "value": 66,
                            "color": cysrConfig().rating_colors[2].color
                        }
                        ]
                    },
                    {
                        "title": "Traffico altri provider",
                        "description": "65%",
                        "data": [{
                            "id": "men",
                            "label": "men",
                            "value": 28,
                            "color": cysrConfig().rating_colors[1].color
                        }
                        ]
                    }
                ]
            } else {
                console.warn(asset)
            }
            main_blocks = [
                {
                    "warning": true,
                    "title": "MANRS",
                    "description": "yes",
                    "date": ""
                },
                {
                    "warning": true,
                    "title": "AntiDDoS",
                    "description": "yes",
                    "date": ""
                },
                {
                    "title": "ASRank",
                    "description": "Tier1, Tier2, other providers",
                    "date": ""
                }
            ];
            blocks = [
                {
                    "title": "IRR",
                    "description": "",
                    "date": ""
                },
                {
                    "title": "RPKI",
                    "description": "",
                    "date": ""
                },
                {
                    "warning": true,
                    "title": "Blacklist",
                    "description": "yes",
                    "date": "07/08/'22"
                },
                {
                    "warning": true,
                    "title": "Botnet",
                    "description": "yes",
                    "date": "11/08/'22"
                }
            ]
            securityIssues = [
                {
                    "warning": true,
                    "value": "4.74k",
                    "title": "Route Leaks"
                },
                {
                    "warning": true,
                    "value": "4",
                    "title": "Hijacks"
                },
                {
                    "value": "0",
                    "title": "Bogons"
                },
                {
                    "value": "9",
                    "title": "Routing Loops"
                },
                {
                    "warning": true,
                    "value": "61",
                    "title": "Vulnerable Ports"
                },
                {
                    "warning": true,
                    "value": "19",
                    "title": "DDoS Amplifiers"
                }
            ]
            break;
        case "ip":
            break;
        case "network":
            networkUrlValue = "https://www.cybersecurityrating.eu/_tmp/sankey-route/data_colored_v3.php?net=" + asset.asset.generalasset.generalasset_val + "&aggregate=5&resolve=&rand=817728";
            blocks = [
                {
                    "title": "IRR",
                    "description": "",
                    "date": ""
                },
                {
                    "title": "RPKI",
                    "description": "",
                    "date": ""
                },
                {
                    "title": "Blacklist",
                    "description": "yes",
                    "date": "07/08/'22"
                },
                {
                    "title": "Botnet",
                    "description": "yes",
                    "date": "11/08/'22"
                }
            ]
            gauges = [
                {
                    "title": "% Traffico diretto",
                    "description": "42%",
                    "data": [
                        {
                            "group": "value",
                            "value": 42,
                            "color": cysrConfig().rating_colors[0].color
                        }
                    ]
                },
                {
                    "title": "Traffico Tier 1",
                    "description": "60%",
                    "data": [
                        {
                            "group": "value",
                            "value": 60,
                            "color": cysrConfig().rating_colors[1].color
                        }
                    ]
                },
                {
                    "title": "Traffico Tier 2",
                    "description": "86%",
                    "data": [
                        {
                            "group": "value",
                            "value": 86,
                            "color": cysrConfig().rating_colors[2].color
                        }
                    ]
                },
                {
                    "title": "Traffico altri provider",
                    "description": "65%",
                    "data": [
                        {
                            "group": "value",
                            "value": 65,
                            "color": cysrConfig().rating_colors[3].color
                        }
                    ]
                }
            ]
            alluvialData = [
                {
                    "source": "About Modal",
                    "target": "Data and AI, AI Apps",
                    "value": 5
                },
                {
                    "source": "About Modal",
                    "target": "Data and AI, Info Architecture",
                    "value": 4
                },
                {
                    "source": "About Modal",
                    "target": "Public Cloud",
                    "value": 3
                },
                {
                    "source": "About Modal",
                    "target": "Security",
                    "value": 4
                },
                {
                    "source": "About Modal",
                    "target": "Automation",
                    "value": 8
                },
                {
                    "source": "Cards",
                    "target": "Data and AI, AI Apps",
                    "value": 6
                },
                {
                    "source": "Cards",
                    "target": "Data and AI, Info Architecture",
                    "value": 15
                },
                {
                    "source": "Cards",
                    "target": "Public Cloud",
                    "value": 2
                },
                {
                    "source": "Cards",
                    "target": "Security",
                    "value": 10
                },
                {
                    "source": "Cards",
                    "target": "Automation",
                    "value": 13
                },
                {
                    "source": "Create Flow",
                    "target": "Data and AI, AI Apps",
                    "value": 2
                },
                {
                    "source": "Create Flow",
                    "target": "Data and AI, Info Architecture",
                    "value": 15
                },
                {
                    "source": "Create Flow",
                    "target": "Public Cloud",
                    "value": 1
                },
                {
                    "source": "Create Flow",
                    "target": "Security",
                    "value": 6
                },
                {
                    "source": "Create Flow",
                    "target": "Automation",
                    "value": 15
                },
                {
                    "source": "Notifications",
                    "target": "Data and AI, Info Architecture",
                    "value": 14
                },
                {
                    "source": "Notifications",
                    "target": "Public Cloud",
                    "value": 3
                },
                {
                    "source": "Notifications",
                    "target": "Security",
                    "value": 3
                },
                {
                    "source": "Page Header",
                    "target": "Data and AI, AI Apps",
                    "value": 4
                },
                {
                    "source": "Page Header",
                    "target": "Data and AI, Info Architecture",
                    "value": 8
                },
                {
                    "source": "Page Header",
                    "target": "Automation",
                    "value": 13
                }
            ]
            alluvialOptions = {
                "title": false,
                "alluvial": {
                    /*
                    // "nodes": chartDataNodes,
                    "nodes": chartDataNodesOptions,
                    */
                    "nodes": [
                        {
                            "name": "About Modal",
                            "category": "Pattern"
                        },
                        {
                            "name": "Cards",
                            "category": "Pattern"
                        },
                        {
                            "name": "Create Flow",
                            "category": "Pattern"
                        },
                        {
                            "name": "Page Header",
                            "category": "Pattern"
                        },
                        {
                            "name": "Notifications",
                            "category": "Pattern"
                        },
                        {
                            "name": "Data and AI, AI Apps",
                            "category": "Group"
                        },
                        {
                            "name": "Data and AI, Info Architecture",
                            "category": "Group"
                        },
                        {
                            "name": "Public Cloud",
                            "category": "Group"
                        },
                        {
                            "name": "Security",
                            "category": "Group"
                        },
                        {
                            "name": "Automation",
                            "category": "Group"
                        }
                    ],
                    "nodeAlignment": "left"
                },
                "height": "400px",
                "toolbar": {
                    "enabled": false
                }
            };
            waffles = [
                {
                    "title": "Direct connection",
                    "description": "43 route",
                    "data": [
                        {
                            "group": "value",
                            "value": 42,
                            "color": cysrConfig().rating_colors[4].color
                        }
                    ]
                },
                {
                    "title": "1 ASN hop",
                    "description": "13 route",
                    "data": [
                        {
                            "group": "value",
                            "value": 60,
                            "color": cysrConfig().rating_colors[3].color
                        }
                    ]
                },
                {
                    "title": "2 ASN hops",
                    "description": "39 route",
                    "data": [
                        {
                            "group": "value",
                            "value": 86,
                            "color": cysrConfig().rating_colors[2].color
                        }
                    ]
                },
                {
                    "title": "3 ASN hops",
                    "description": "30 route",
                    "data": [
                        {
                            "group": "value",
                            "value": 65,
                            "color": cysrConfig().rating_colors[1].color
                        }
                    ]
                },
                {
                    "title": "More ASN hops",
                    "description": "30 route",
                    "data": [
                        {
                            "group": "value",
                            "value": 65,
                            "color": cysrConfig().rating_colors[0].color
                        }
                    ]
                }
            ];
            break;

        default:
            text = ""
            break;
    }
    /*
    // temp s
    if (highlights.some(
        (h) => h.highlightGA_content?.type === "STATS_DESCR"
    )) {
        // no push more
    } else {
        highlights.push(
            {
                highlightGA_content: {
                    "type": "STATS",
                    "title": "AS Stats",
                    "help": "",
                    "help_link": "",
                    "data": [
                        {
                            "label": "Announced IPv4 addressess",
                            "value": "1024"
                        },
                        {
                            "label": "Announced IPv6 addressess",
                            "value": "347298437289"
                        },
                    ]
                }
            }
        )
        highlights.push(

            {
                highlightGA_content: {
                    "type": "STATS_DESCR",
                    "title": "AS Stats",
                    "help": "",
                    "help_link": "",
                    "data": [
                        "Tier 1",
                        "AntiDDOS Capabilities",
                        "CDN Provider"
                    ]
                }
            }
        )
    }
    // temp e
    */
    if (highlights && highlights.length > 0) {
        return (
            <Grid item xs={12}>
                {
                    /*
                    <Card>
                    */
                }
                <>
                    <InfoButton cysr_info={cysrConfig().i("asset", "typology", "help")} cysr_info_link={cysrConfig().i("asset", "typology", "help_link")} />
                    {
                        //<MDBox py={5} px={3} fullWidth>
                    }
                    <MDBox py={0} px={0} fullWidth>
                        {cysrConfig().i("asset", "typology", "title") && (
                            <MDTypography variant="h5">{cysrConfig().i("asset", "typology", "title")}</MDTypography>
                        )}
                        {cysrConfig().i("asset", "typology", "sub_title") && (
                            <MDTypography variant="button" color="text" fontWeight="regular">
                                {cysrConfig().i("asset", "typology", "sub_title")}
                            </MDTypography>
                        )}
                        <Grid container spacing={3}>
                            {highlights && highlights.length > 0 && highlights.map((h) => {
                                return h.highlightGA_show_on_web === false ? (
                                    <Grid item></Grid>
                                ) : (
                                    h.highlightGA_content.type === "TEXT" ? (
                                        <Grid key={h.highlightGA_uuid} item xs={12}>
                                            <Card>
                                                {h.highlightGA_content.help && (
                                                    <InfoButton cysr_info={h.highlightGA_content.help} cysr_info_link={h.highlightGA_content.help_link} />
                                                )}
                                                <MDBox p={3}>
                                                    <MDTypography variant="button" color="text">
                                                        {h.highlightGA_content.text}
                                                    </MDTypography>
                                                    <Collapse in={false} style={{ width: "100%" }} timeout="auto">
                                                        {h.highlightGA_content.text}
                                                    </Collapse>
                                                </MDBox>
                                            </Card>
                                        </Grid>
                                    ) : (
                                        h.highlightGA_content.type === "CARD" ? (
                                            <Grid key={h.highlightGA_uuid} item xs={12} md={3} lg={2}>
                                                <Card>
                                                    {h.highlightGA_content.help && (
                                                        <InfoButton cysr_info={h.highlightGA_content.help} cysr_info_link={h.highlightGA_content.help_link} />
                                                    )}
                                                    <MDBox p={3}>
                                                        <MDTypography style={{ fontSize: ".9rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} variant="body2" color="text">
                                                            {h.highlightGA_content.title ? h.highlightGA_content.title : <span style={{ color: "transparent" }}>#</span>}
                                                        </MDTypography>
                                                        <MDBox mt={2} mb={1} lineHeight={0}>
                                                            <MDTypography style={{ fontSize: "1.25rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} variant="h3" fontWeight="bold">
                                                                {h.highlightGA_content.text ? h.highlightGA_content.text : <span style={{ color: "transparent" }}>#</span>}
                                                            </MDTypography>
                                                            <MDTypography style={{ fontSize: "0.7em", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} variant="h4" fontWeight="regular">
                                                                {h.highlightGA_content.text2 ? h.highlightGA_content.text2 : <span style={{ color: "transparent" }}>#</span>}
                                                            </MDTypography>
                                                        </MDBox>
                                                        <ScoreItem scoreValue={h.highlightGA_content.value} textual={true} />
                                                        {
                                                            //<MDBadge variant="contained" color={h.warning ? "warning" : "success"} badgeContent={h.date && h.date !== "" ? `last seen ${h.date}` : "no data to show"} container />
                                                        }
                                                    </MDBox>
                                                </Card>
                                            </Grid>
                                        ) :
                                            h.highlightGA_content.type === "SCREENSHOT"
                                                || h.highlightGA_content.type === "STATS"
                                                || h.highlightGA_content.type === "STATS_DESCR"
                                                ? null
                                                /*
                                                    (
                                                    // moved in components/ratingData
                                                    
                                                    <Grid key={h.highlightGA_uuid} item xs={12} md={3} lg={2}>
                                                        <Card>
                                                            {h.highlightGA_content.help && (
                                                                <InfoButton cysr_info={h.highlightGA_content.help} cysr_info_link={h.highlightGA_content.help_link} />
                                                            )}
                                                            <MDBox p={3}>
                                                                <MDTypography style={{ fontSize: ".9rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} variant="body2" color="text">
                                                                    {h.highlightGA_content.title ? h.highlightGA_content.title : <span style={{ color: "transparent" }}>#</span>}
                                                                </MDTypography>
                                                                <MDBox mt={2} mb={1}>
                                                                    <Dialog>
                                                                        <DialogTrigger asChild>
                                                                            <div className="relative bg-zinc-300 dark:bg-zinc-700 cursor-pointer rounded-md">
                                                                                <img
                                                                                    style={{
                                                                                        objectFit: 'contain',
                                                                                        height: '100%',
                                                                                        width: '100%',
                                                                                    }}
                                                                                    alt={`Screenshot for ${h.highlightGA_content.title}`}
                                                                                    src={h.highlightGA_content.url_thumbnail ? h.highlightGA_content.url_thumbnail : h.highlightGA_content.url}
                                                                                />
                                                                                <div className="absolute bottom-0 right-0 p-2 inline-flex items-center justify-center">
                                                                                    <div style={{
                                                                                        backgroundColor: "#1C7170"
                                                                                    }} className="p-2 rounded-full inline-flex items-center justify-center">
                                                                                        <OpenInFullSharp className="h-10 w-10 text-white" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </DialogTrigger>
                                                                        <DialogContent style={
                                                                            {
                                                                                background: `url(${h.highlightGA_content.url ? h.highlightGA_content.url : h.highlightGA_content.url_thumbnail})`,
                                                                                backgroundPosition: '50% 50%',
                                                                                backgroundRepeat: "no-repeat",
                                                                                backgroundSize: 'contain',
                                                                                height: '100%',
                                                                                width: 'auto',
                                                                                aspectRatio: '16/9',
                                                                                border: 0,
                                                                                maxWidth: '90vw',
                                                                                maxHeight: '90vh',
                                                                                zIndex: 9999
                                                                            }
                                                                        } />
                                                                    </Dialog>
                                                                </MDBox>
                                                            </MDBox>
                                                        </Card>
                                                    </Grid>
                                                )
                                                */
                                                : (
                                                    h.highlightGA_content.type === "SCORELESS_CARD" ? (
                                                        <Grid key={h.highlightGA_uuid} item xs={12} md={3} lg={2}>
                                                            <Card>
                                                                {h.highlightGA_content.help && (
                                                                    <InfoButton cysr_info={h.highlightGA_content.help} cysr_info_link={h.highlightGA_content.help_link} />
                                                                )}
                                                                <MDBox p={3}>
                                                                    <MDTypography style={{ fontSize: ".9rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} variant="body2" color="text">
                                                                        {h.highlightGA_content.title ? h.highlightGA_content.title : <span style={{ color: "transparent" }}>#</span>}
                                                                    </MDTypography>
                                                                    <MDBox mt={2} mb={1} lineHeight={0}>
                                                                        <MDTypography style={{ fontSize: "1.25rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} variant="h3" fontWeight="bold">
                                                                            {h.highlightGA_content.text ? h.highlightGA_content.text : <span style={{ color: "transparent" }}>#</span>}
                                                                        </MDTypography>
                                                                        <MDTypography style={{ marginBottom: "0", fontSize: "0.7em", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} variant="h4" fontWeight="regular">
                                                                            {h.highlightGA_content.text2 ? h.highlightGA_content.text2 : <span style={{ color: "transparent" }}>#</span>}
                                                                        </MDTypography>
                                                                    </MDBox>
                                                                </MDBox>
                                                            </Card>
                                                        </Grid>

                                                    ) : (
                                                        h.highlightGA_content.type === "SEPARATOR" ? (
                                                            <Grid key={h.highlightGA_uuid} item xs={12}>
                                                                <MDBox mt={4} style={{ width: "100%" }}>
                                                                    <MDTypography variant="h5" fontWeight="medium">{h.highlightGA_content.title}</MDTypography>
                                                                </MDBox>
                                                            </Grid>
                                                        ) : (
                                                            //bubble s
                                                            h.highlightGA_content.type === "BUBBLE" ? (
                                                                <Grid key={h.highlightGA_uuid} item xs={12}>
                                                                    <Card>
                                                                        {h.highlightGA_content.help && (
                                                                            <InfoButton cysr_info={h.highlightGA_content.help} cysr_info_link={h.highlightGA_content.help_link} />
                                                                        )}
                                                                        <MDBox py={5} px={3} fullWidth>
                                                                            <div style={{ marginBottom: "12px" }}>
                                                                                {h.highlightGA_content.title && (
                                                                                    <MDTypography variant="h5">{h.highlightGA_content.title}</MDTypography>
                                                                                )}
                                                                                {h.highlightGA_content.sub_title && (
                                                                                    <MDTypography variant="button" color="text" fontWeight="regular">
                                                                                        {h.highlightGA_content.sub_title}
                                                                                    </MDTypography>
                                                                                )}
                                                                            </div>
                                                                            {
                                                                                h.highlightGA_content.data ? <BubbleChartLabel data={h.highlightGA_content.data} /> : <p>No bubble chart</p>
                                                                            }
                                                                        </MDBox>
                                                                    </Card>
                                                                </Grid>
                                                            ) : (
                                                                h.highlightGA_content.type === "BUBBLE_VALUES" ? (
                                                                    <Grid key={h.highlightGA_uuid} item xs={12}>
                                                                        <Card>
                                                                            {h.highlightGA_content.help && (
                                                                                <InfoButton cysr_info={h.highlightGA_content.help} cysr_info_link={h.highlightGA_content.help_link} />
                                                                            )}
                                                                            <MDBox py={5} px={3} fullWidth>
                                                                                <div style={{ marginBottom: "12px" }}>
                                                                                    {h.highlightGA_content.title && (
                                                                                        <MDTypography variant="h5">{h.highlightGA_content.title}</MDTypography>
                                                                                    )}
                                                                                    {h.highlightGA_content.sub_title && (
                                                                                        <MDTypography variant="button" color="text" fontWeight="regular">
                                                                                            {h.highlightGA_content.sub_title}
                                                                                        </MDTypography>
                                                                                    )}
                                                                                </div>
                                                                                {
                                                                                    h.highlightGA_content.data ? <BubbleChartValue data={
                                                                                        h.highlightGA_content.data.filter((n) => {
                                                                                            // "date": "2023-06-01",
                                                                                            if (differenceInYears(new Date(), new Date(n.date)) < skipDatesOlderThanYearsAmount) {
                                                                                                return true
                                                                                            }
                                                                                            return false
                                                                                        })
                                                                                    } /> : <p>No bubble chart</p>
                                                                                }
                                                                            </MDBox>
                                                                        </Card>
                                                                    </Grid>
                                                                ) : (
                                                                    //bubble e
                                                                    h.highlightGA_content.type === "TABLE-GROUP" ? (
                                                                        <Grid key={h.highlightGA_uuid} item xs={12}>
                                                                            <Card>
                                                                                {h.highlightGA_content.help && (
                                                                                    <InfoButton cysr_info={h.highlightGA_content.help} cysr_info_link={h.highlightGA_content.help_link} />
                                                                                )}
                                                                                <MDBox py={5} px={3} fullWidth>
                                                                                    <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                                                                        <div>
                                                                                            {h.highlightGA_content.title && (
                                                                                                <MDTypography variant="h5">{h.highlightGA_content.title}</MDTypography>
                                                                                            )}
                                                                                            {h.highlightGA_content.sub_title && (
                                                                                                <MDTypography variant="button" color="text" fontWeight="regular">
                                                                                                    {h.highlightGA_content.sub_title}
                                                                                                </MDTypography>
                                                                                            )}
                                                                                        </div>
                                                                                        <MDButton
                                                                                            style={cysrConfig().button_style}
                                                                                            size="medium"
                                                                                            onClick={() => setExpandedTableOpen(expandedTable === h.highlightGA_uuid ? 0 : h.highlightGA_uuid)}
                                                                                        >
                                                                                            {expandedTable === h.highlightGA_uuid ? <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} /> : <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />}
                                                                                        </MDButton>
                                                                                    </MDBox>
                                                                                    <Collapse in={expandedTable === h.highlightGA_uuid} style={cysrConfig().collapse_style} timeout="auto" unmountOnExit>
                                                                                        <Grid container spacing={12}>
                                                                                            <Grid item xs={12}>
                                                                                                {(h.highlightGA_content && h.highlightGA_content.rows && h.highlightGA_content.columns) ? <CysrTableGroup data={
                                                                                                    {
                                                                                                        search: params.get('s') ? params.get('s') : "",
                                                                                                        hideCompleted: false,
                                                                                                        // options available
                                                                                                        tableTagsOptions: [],
                                                                                                        columns: h.highlightGA_content.columns,
                                                                                                        isSorted: h.highlightGA_content.sort,
                                                                                                        // rows: JSON.parse(JSON.stringify(h.highlightGA_content.rows).replaceAll("true", '"true"').replaceAll("false", '"false"')),
                                                                                                        rows: JSON.parse(JSON.stringify(h.highlightGA_content.rows)),
                                                                                                    }
                                                                                                }
                                                                                                /> : <span>no group table available for this highlight</span>
                                                                                                }
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Collapse>
                                                                                </MDBox>
                                                                            </Card>
                                                                        </Grid>
                                                                    ) : (
                                                                        h.highlightGA_content.type === "TABLE" ? (
                                                                            <Grid key={h.highlightGA_uuid} item xs={12}>
                                                                                <Card>
                                                                                    {h.highlightGA_content.help && (
                                                                                        <InfoButton cysr_info={h.highlightGA_content.help} cysr_info_link={h.highlightGA_content.help_link} />
                                                                                    )}
                                                                                    <MDBox py={5} px={3} fullWidth>
                                                                                        <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                                                                            <div>
                                                                                                {h.highlightGA_content.title && (
                                                                                                    <MDTypography variant="h5">{h.highlightGA_content.title}</MDTypography>
                                                                                                )}
                                                                                                {h.highlightGA_content.sub_title && (
                                                                                                    <MDTypography variant="button" color="text" fontWeight="regular">
                                                                                                        {h.highlightGA_content.sub_title}
                                                                                                    </MDTypography>
                                                                                                )}
                                                                                            </div>
                                                                                            <MDButton
                                                                                                style={cysrConfig().button_style}
                                                                                                size="medium"
                                                                                                onClick={() => setExpandedTableOpen(expandedTable === h.highlightGA_uuid ? 0 : h.highlightGA_uuid)}
                                                                                            >
                                                                                                {expandedTable === h.highlightGA_uuid ? <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} /> : <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />}
                                                                                            </MDButton>
                                                                                        </MDBox>
                                                                                        <Collapse in={expandedTable === h.highlightGA_uuid} style={cysrConfig().collapse_style} timeout="auto" unmountOnExit>
                                                                                            <Grid container spacing={12}>
                                                                                                <Grid item xs={12}>
                                                                                                    {(h.highlightGA_content && h.highlightGA_content.rows && h.highlightGA_content.columns) ?
                                                                                                        <CysrTable data={
                                                                                                            {
                                                                                                                search: params.get('s') ? params.get('s') : "",
                                                                                                                hideCompleted: false,
                                                                                                                // options available
                                                                                                                tableTagsOptions: [],
                                                                                                                columns: h.highlightGA_content.columns,
                                                                                                                isSorted: h.highlightGA_content.sort,
                                                                                                                //rows: JSON.parse(JSON.stringify(h.highlightGA_content.rows).replaceAll("true", '"true"').replaceAll("false", '"false"')),
                                                                                                                rows: JSON.parse(JSON.stringify(h.highlightGA_content.rows)),
                                                                                                            }
                                                                                                        }
                                                                                                        /> : <span>no table available for this highlight</span>
                                                                                                    }
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Collapse>
                                                                                    </MDBox>
                                                                                </Card>
                                                                            </Grid>
                                                                        ) : (
                                                                            h.highlightGA_content.type === "CHART-ROUTING" && networkUrlValue && networkUrlValue !== "" ? (
                                                                                <Grid item xs={12} key={h.highlightGA_uuid}>
                                                                                    <MDBox style={{ width: "100%" }}>
                                                                                        <Card>
                                                                                            {h.highlightGA_content.help && (
                                                                                                <InfoButton cysr_info={h.highlightGA_content.help} cysr_info_link={h.highlightGA_content.help_link} />
                                                                                            )}
                                                                                            <MDBox p={3}>
                                                                                                <MDTypography variant="body2" color="text">
                                                                                                    {h.highlightGA_content.title}
                                                                                                </MDTypography>
                                                                                                <AssetChart fig={h.highlightGA_content.chart} style={{ width: "100%" }} />
                                                                                                <Grid mt={2} container>
                                                                                                    <Grid style={{ width: "100%" }} item container spacing={3}>
                                                                                                        <Grid item container xs={12} lg={6} spacing={3} style={{ marginRight: "auto" }}>
                                                                                                            {h.highlightGA_content.chart && h.highlightGA_content.chart.data && h.highlightGA_content.chart.data[0] && h.highlightGA_content.chart.data[0].waffle.hop_o && h.highlightGA_content.chart.data[0].waffle.hop_o && h.highlightGA_content.chart.data[0].waffle.hop_o.length > 0 && h.highlightGA_content.chart.data[0].waffle.hop_o.map((w, i) => {
                                                                                                                const wLastStyle = w.title === waffles[waffles.length - 1].title ? { marginRight: "auto", textAlign: "center", width: 80 } : { textAlign: "center", width: 80 };
                                                                                                                return (
                                                                                                                    <Grid key={w.title} item style={wLastStyle}>
                                                                                                                        {
                                                                                                                            /*
                                                                                                                            "hop": ">4",
                                                                                                                            "percentage": 0.002596053997923157,
                                                                                                                            "percentage_round": 0,
                                                                                                                            "title": "> 4 hops",
                                                                                                                            "title_long": "more then 4 hops",
                                                                                                                            "value": 5
                                                                                                                            */
                                                                                                                        }
                                                                                                                        <WaffleChart style={{ opacity: "0.3" }} data={[
                                                                                                                            {
                                                                                                                                "id": "hop_o" + w.hop,
                                                                                                                                "label": w.title,
                                                                                                                                "value": w.percentage * 100,
                                                                                                                                "color": cysrConfig().rating_colors[4 - i].color
                                                                                                                            }
                                                                                                                        ]} />
                                                                                                                        <p style={{ fontSize: "14px" }}>{parseInt(w.percentage_round * 100, 10)}%</p>
                                                                                                                        <MDTypography color="text" style={{ fontSize: "14px", lineHeight: "1" }}>{w.title}</MDTypography>                                                                            </Grid>
                                                                                                                )
                                                                                                            })}
                                                                                                        </Grid>
                                                                                                        <Grid item container xs={12} md="auto" spacing={3}>
                                                                                                            {h.highlightGA_content.chart && h.highlightGA_content.chart.data && h.highlightGA_content.chart.data[0] && h.highlightGA_content.chart.data[0].waffle.path_o && h.highlightGA_content.chart.data[0].waffle.path_o && h.highlightGA_content.chart.data[0].waffle.path_o.length > 0 && h.highlightGA_content.chart.data[0].waffle.path_o.map((w, i) => {
                                                                                                                const wLastStyle = w.title === waffles[waffles.length - 1].title ? { marginRight: "auto", textAlign: "center", width: 80 } : { textAlign: "center", width: 80 };
                                                                                                                return (
                                                                                                                    <Grid key={w.title} item style={wLastStyle}>
                                                                                                                        {
                                                                                                                            /*
                                                                                                                            "hop": ">4",
                                                                                                                            "percentage": 0.002596053997923157,
                                                                                                                            "percentage_round": 0,
                                                                                                                            "title": "> 4 hops",
                                                                                                                            "title_long": "more then 4 hops",
                                                                                                                            "value": 5
                                                                                                                            */
                                                                                                                        }
                                                                                                                        <WaffleChart style={{ opacity: "0.3" }} data={[
                                                                                                                            {
                                                                                                                                "id": "hop_o" + w.hop,
                                                                                                                                "label": w.title,
                                                                                                                                "value": w.percentage * 100,
                                                                                                                                "color": cysrConfig().waffle_colors[w.type_asn].color
                                                                                                                            }
                                                                                                                        ]} />
                                                                                                                        <p style={{ fontSize: "14px" }}>{parseInt(w.percentage_round * 100)}%</p>
                                                                                                                        <MDTypography color="text" style={{ fontSize: "14px", lineHeight: "1" }}>{w.title}</MDTypography>
                                                                                                                        <MDTypography color="text" style={{ fontSize: "10px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{w.title2}</MDTypography>
                                                                                                                    </Grid>
                                                                                                                )
                                                                                                            })}
                                                                                                        </Grid>

                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </MDBox>
                                                                                        </Card>
                                                                                    </MDBox>
                                                                                </Grid>
                                                                            ) : (
                                                                                <Grid item></Grid>
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                    )
                                )
                            })
                            }
                            {
                                highlights.some(
                                    (h) => h.highlightGA_content?.type === "STATS"
                                ) ? (
                                    highlights
                                        .filter((h) => h.highlightGA_content?.type === "STATS").map((h) =>
                                            <Grid item xs={highlights.some(
                                                (h) => h.highlightGA_content?.type === "STATS_DESCR"
                                            ) ? 6 : 12}>
                                                <Card style={{
                                                    height: "100%"
                                                }}>
                                                    {h.highlightGA_content.help && (
                                                        <InfoButton cysr_info={h.highlightGA_content.help} cysr_info_link={h.highlightGA_content.help_link} />
                                                    )}
                                                    <MDBox p={3}>
                                                        <MDTypography style={{ fontSize: ".9rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} variant="body2" color="text">
                                                            {h.highlightGA_content.title ? h.highlightGA_content.title : <span style={{ color: "transparent" }}>#</span>}
                                                        </MDTypography>
                                                        {
                                                            h.highlightGA_content.data.map((y, index) => (
                                                                <div key={index}>
                                                                    <Text className='text-turquoise-500 dark:text-turquoise-400'>{y.label}</Text>
                                                                    <MDTypography color="text">
                                                                        <Metric style={{
                                                                            color: "inherit"
                                                                        }}>{y.format ? numericFormatter(y.value) : y.value}</Metric>
                                                                    </MDTypography>
                                                                </div>
                                                            ))}
                                                    </MDBox>
                                                </Card>
                                            </Grid>
                                        )
                                ) : null
                            }
                            {
                                highlights.some(
                                    (h) => h.highlightGA_content?.type === "STATS_DESCR"
                                ) ? (highlights
                                    .filter((h) => h.highlightGA_content?.type === "STATS_DESCR").map((h) =>
                                        <Grid item xs={highlights.some(
                                            (h) => h.highlightGA_content?.type === "STATS"
                                        ) ? 6 : 12}>
                                            <Card style={{
                                                height: "100%"
                                            }}>
                                                {h.highlightGA_content.help && (
                                                    <InfoButton cysr_info={h.highlightGA_content.help} cysr_info_link={h.highlightGA_content.help_link} />
                                                )}
                                                <MDBox p={3}>
                                                    <MDTypography style={{ fontSize: ".9rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} variant="body2" color="text">
                                                        {h.highlightGA_content.title ? h.highlightGA_content.title : <span style={{ color: "transparent" }}>#</span>}
                                                    </MDTypography>
                                                    <ul className="flex flex-col gap-1 list-disc list-inside marker:text-teal-500">

                                                        {
                                                            h.highlightGA_content.data.map((y, index) => (
                                                                <li key={index}>
                                                                    <MDTypography style={{ display: "inline" }} color="text">
                                                                        {y}
                                                                    </MDTypography>
                                                                </li>
                                                            ))}
                                                    </ul>
                                                </MDBox>
                                            </Card>
                                        </Grid>
                                    )
                                ) : null
                            }

                        </Grid>
                    </MDBox>
                </>
                {
                    /*
                    </Card>
                    */
                }
            </Grid >
        )
    } else {
        return null
    }
};
/* eslint-disable */