/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components

/* eslint-disable */
import { useState, useEffect } from "react";
import { BubbleChart } from "@carbon/charts-react";
// import "@carbon/charts/styles-g90.css";
/* eslint-disable */
import(`@carbon/charts/styles-${localStorage.getItem("cysr_dark_mode") && localStorage.getItem("cysr_dark_mode") === "false" ? "g10" : "g90"}.css`);

export default function BubbleChartDashboard(source) {
    
  return (
    <>
  {source && source.data ? <BubbleChart 
    data={source.data}
    options= {
        {
        "title": false,
        "axes": {
            "bottom": {
            "title": false,
            "scaleType": "time",
            "mapsTo": "date"
            },
            "left": {
            "mapsTo": "gravity"
            }
        },
        "bubble": {
            "radiusMapsTo": "value"
        },
        "height": "400px",
        "legend": {
          "enabled": false
        },
        "toolbar": {
          "enabled": false
        }
      }
    }
    /> : <p>No bubble chart</p>}
    </>
  );
}
/* eslint-disable */
              