/* eslint-disable */
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import cysrConfig from "layouts/cysr/config";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

export default function CysrStepper({ isLoading, steps, darkMode, defaultActiveStep = 0 }) {
  const [activeStep, setActiveStep] = React.useState(defaultActiveStep);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onKeyDownHandler = e => {
    if (e.keyCode === 13 && e.shiftKey) {
      if (activeStep !== steps.length - 1) {
        e.preventDefault();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  return (
    <Stepper onKeyDown={onKeyDownHandler} connector={null} activeStep={activeStep} orientation="vertical" sx={{ background: "transparent!important;", boxShadow: "none", padding: 0 }}>
      {steps.map((step, index) => (
        step && <Step key={step.label} p={0} expanded={step.expanded ? true : false}>
          <StepLabel icon={<></>} sx={{
            margin: 0,
          }}>
            <div style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              opacity: activeStep === index ? "1" : ".5",
            }}>
              {steps && steps.length && steps.length > 1 && <MDTypography variant="text">{index + 1}</MDTypography>}<MDTypography variant="h6" mb={0}>{step.label}</MDTypography>{step.value && step.value != "" && <>: <MDTypography color="info" variant="h6" mb={0}>{step.value}</MDTypography></>}
            </div>
            {step.valueBoxes && activeStep !== index && step.valueBoxes}
          </StepLabel>
          <StepContent sx={{
            border: 0,
            marginLeft: "8px",
            marginTop: "4px",
            paddingLeft: 0
            // borderLeft:"2px solid rgba(159 201 255, .5)",marginLeft:"6px"
          }}>
            <Box>
              {step.content}
            </Box>
            <Box sx={{ mb: 2 }}>
              <div style={{ display: "flex", gap: "8px", marginTop: "8px" }}>
                {index !== 0 &&
                  <MDButton
                    disabled={index === 0}
                    sx={index === 0 && {
                      background: `${darkMode ? "#bdc7d0" : "#17191c"}!important`,
                      color: `${darkMode ? "#EEF0F1" : "#666666"}!important`
                    }}
                    onClick={handleBack}
                    style={cysrConfig().button_style}
                    size="medium"
                  >
                    <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().back.icon.svg }} />
                    Back
                  </MDButton>
                }
                {((index === steps.length - 1) === false) ? (
                  // check next step does not have error
                  (steps[index + 1] && steps[index + 1].error)
                    ? <MDButton disabled style={cysrConfig().button_style}>{steps[index + 1].error}</MDButton>
                    : (
                      <MDButton
                        disabled={index === steps.length - 1}
                        // variant="contained"
                        onClick={handleNext}
                        style={cysrConfig().button_style}
                        sx={index === steps.length - 1 && {
                          background: `${darkMode ? "#bdc7d0" : "#17191c"}!important`,
                          color: `${darkMode ? "#EEF0F1" : "#666666"}!important`
                        }}
                        size="medium"
                      >
                        Next <kbd className="ml-2 pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border border-white bg-muted px-1.5 text-[10px] font-sans font-medium text-muted-foreground opacity-100">
                          <span className="text-xs tracking-widest">
                            ⇧
                          </span>
                          + ENTER
                        </kbd>
                        <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
                      </MDButton>
                    ))
                  : (index === steps.length - 1 &&
                    <MDButton
                      type={isLoading ? "button" : "submit"}
                      disabled={isLoading ? true : false}
                      // variant="contained"
                      style={cysrConfig().button_style}
                      size="medium"
                    // sx={"margin-left:auto!important"}
                    >
                      {isLoading ?
                        <span style={cysrConfig().button_icon_left_style} className="spin" dangerouslySetInnerHTML={{ __html: cysrConfig().loading.icon.svg }} />
                        :
                        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig()[step.submitIcon].icon.svg }} />
                      }
                      {step.submitText}
                    </MDButton>)
                }
              </div>
            </Box>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
}
/* eslint-disable */