/* eslint-disable */
// prettier-ignore
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetFooter,
    SheetClose,
    SheetTitle,
    SheetTrigger,
} from "layouts/cysr/components/ui/sheet";
import { useState } from "react";

import { BookOpenCheck } from "lucide-react";
import { DataTableRowButton } from "./data-table-row-button-acknowledge";
import { format } from "date-fns";
import cysrConfig, { process } from "layouts/cysr/config";
import MDButton from "components/MDButton";

import { ExpandableList } from "layouts/cysr/components/ExpandableList";

export function DataTableSelectedSheet({
    table,
    darkMode
}) {
    const [open, setOpen] = useState(false);
    return (
        <Sheet open={open} onOpenChange={setOpen}>
            <SheetTrigger>
                <button className="mr-2 h-8 inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-turquoise-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-turquoise-950 dark:focus-visible:ring-turquoise-800 bg-turquoise-800 text-turquoise-50 hover:bg-turquoise-900/90 dark:bg-turquoise-200 dark:text-turquoise-900 dark:hover:bg-turquoise-50/90 px-4 py-2 mr-2 h-8">
                    <BookOpenCheck className="mr-2 h-3 w-3" />
                    Bulk {process.env.NEXT_PUBLIC_STRING_FEATURE_500_FEATURE}
                </button>
            </SheetTrigger>
            <SheetContent className={`${darkMode ? "dark-dialog dark" : "light-dialog light"}`} >
                <SheetHeader>
                    <SheetTitle>
                        {process.env.NEXT_PUBLIC_STRING_FEATURE_500_FEATURE} confirmation
                    </SheetTitle>
                    <SheetDescription>
                        All the selected{" "}
                        {process.env.NEXT_PUBLIC_STRING_TERM_50_PLURAL
                            ? process.env.NEXT_PUBLIC_STRING_TERM_50_PLURAL.toLowerCase()
                            : "entries"}{" "}
                        are grouped depending on their status, select the desired actions
                        accordingly.
                    </SheetDescription>
                </SheetHeader>
                <div className="my-6 flex flex-col gap-2 overflow-y-auto max-h-[calc(100%-120px)]">
                    {" "}
                    <span className="block text-sm text-muted-foreground">
                        {table.getFilteredSelectedRowModel().rows.length} of{" "}
                        {table.getFilteredRowModel().rows.length}{" "}
                        {process.env.NEXT_PUBLIC_STRING_TERM_50_PLURAL
                            ? process.env.NEXT_PUBLIC_STRING_TERM_50_PLURAL.toLowerCase()
                            : "entries"}{" "}
                        selected:
                    </span>
                    <div className="flex flex-col gap-6 my-4">
                        {table
                            .getFilteredSelectedRowModel()
                            .rows.filter((r) => r.original && r.original.status === "new")
                            .length > 0 && (
                                <div className="flex flex-col gap-2">
                                    <h2>
                                        {process.env.NEXT_PUBLIC_STRING_TERM_1400_STATUS_100
                                            ? process.env.NEXT_PUBLIC_STRING_TERM_1400_STATUS_100.charAt(
                                                0
                                            ).toUpperCase() +
                                            process.env.NEXT_PUBLIC_STRING_TERM_1400_STATUS_100.slice(
                                                1
                                            ).toLowerCase()
                                            : "new/unread"}{" "}
                                        {process.env.NEXT_PUBLIC_STRING_TERM_50_PLURAL
                                            ? process.env.NEXT_PUBLIC_STRING_TERM_50_PLURAL.toLowerCase()
                                            : "entries"}{" "}
                                        (
                                        {
                                            table
                                                .getFilteredSelectedRowModel()
                                                .rows.filter(
                                                    (r) => r.original && r.original.status === "new"
                                                ).length
                                        }{" "}
                                        selected )
                                    </h2>
                                    <SheetDescription>
                                        Pressing "{process.env.NEXT_PUBLIC_STRING_FEATURE_700_FEATURE}
                                        " will set all the selected{" "}
                                        {process.env.NEXT_PUBLIC_STRING_TERM_50_PLURAL
                                            ? process.env.NEXT_PUBLIC_STRING_TERM_50_PLURAL.toLowerCase()
                                            : "entries"}{" "}
                                        as{" "}
                                        {process.env.NEXT_PUBLIC_STRING_TERM_1400_STATUS_300
                                            ? process.env.NEXT_PUBLIC_STRING_TERM_1400_STATUS_300.toLowerCase()
                                            : "acknowledged/read"}
                                        .
                                    </SheetDescription>

                                    <ExpandableList
                                        previewChildren={
                                            <div className="bg-turquoise-100 dark:bg-turquoise-900 rounded p-2 divide-y divide-dashed">
                                                {[
                                                    table
                                                        .getFilteredSelectedRowModel()
                                                        .rows.find(
                                                            (r) => r.original && r.original.status === "new"
                                                        ),
                                                ].map((row) => (
                                                    <div className="flex gap-2 py-1.5 items-center w-full flex-wrap">
                                                        <h2 className="text-xs font-medium">
                                                            {row.getValue("user")
                                                                ? row.getValue("user")
                                                                : process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE}
                                                        </h2>
                                                        &middot;
                                                        <h3 className="text-xs">
                                                            {row.original.added
                                                                ? format(
                                                                    new Date(row.original.added),
                                                                    process.env.NEXT_PUBLIC_DATE_FORMAT
                                                                        ? process.env
                                                                            .NEXT_PUBLIC_DATE_FORMAT
                                                                        : "dd/MM/yyyy"
                                                                )
                                                                : row.original.date
                                                                    ? format(
                                                                        new Date(row.original.date),
                                                                        process.env.NEXT_PUBLIC_DATE_FORMAT
                                                                            ? process.env
                                                                                .NEXT_PUBLIC_DATE_FORMAT
                                                                            : "dd/MM/yyyy"
                                                                    )
                                                                    : ""}
                                                        </h3>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    >
                                        <div className="bg-turquoise-100 dark:bg-turquoise-900 rounded p-2 divide-y divide-dashed">
                                            {table
                                                .getFilteredSelectedRowModel()
                                                .rows.filter(
                                                    (r) => r.original && r.original.status === "new"
                                                )
                                                .map((row) => (
                                                    <div className="flex gap-2 py-1.5 items-center w-full flex-wrap">
                                                        <h2 className="text-xs font-medium">
                                                            {row.getValue("user")
                                                                ? row.getValue("user")
                                                                : process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE}
                                                        </h2>
                                                        &middot;
                                                        <h3 className="text-xs">
                                                            {row.original.added
                                                                ? format(
                                                                    new Date(row.original.added),
                                                                    process.env.NEXT_PUBLIC_DATE_FORMAT
                                                                        ? process.env
                                                                            .NEXT_PUBLIC_DATE_FORMAT
                                                                        : "dd/MM/yyyy"
                                                                )
                                                                : row.original.date
                                                                    ? format(
                                                                        new Date(row.original.date),
                                                                        process.env.NEXT_PUBLIC_DATE_FORMAT
                                                                            ? process.env
                                                                                .NEXT_PUBLIC_DATE_FORMAT
                                                                            : "dd/MM/yyyy"
                                                                    )
                                                                    : ""}
                                                        </h3>
                                                    </div>
                                                ))}
                                        </div>
                                    </ExpandableList>
                                    <div className="flex gap-2 flex-col mt-2">  <DataTableRowButton
                                        darkMode={darkMode}
                                        rows={table.getFilteredSelectedRowModel().rows}
                                        row={undefined}
                                        status="new"
                                    />
                                    </div>
                                </div>
                            )}
                        {table
                            .getFilteredSelectedRowModel()
                            .rows.filter(
                                (r) => r.original && r.original.status === "acknowledged"
                            ).length > 0 && (
                                <div className="flex flex-col gap-2">
                                    <h2>
                                        {process.env.NEXT_PUBLIC_STRING_TERM_1400_STATUS_300
                                            ? process.env.NEXT_PUBLIC_STRING_TERM_1400_STATUS_300.charAt(
                                                0
                                            ).toUpperCase() +
                                            process.env.NEXT_PUBLIC_STRING_TERM_1400_STATUS_300.slice(
                                                1
                                            ).toLowerCase()
                                            : "acknowledged/read"}{" "}
                                        {process.env.NEXT_PUBLIC_STRING_TERM_50_PLURAL
                                            ? process.env.NEXT_PUBLIC_STRING_TERM_50_PLURAL.toLowerCase()
                                            : "entries"}{" "}
                                        (
                                        {
                                            table
                                                .getFilteredSelectedRowModel()
                                                .rows.filter(
                                                    (r) =>
                                                        r.original && r.original.status === "acknowledged"
                                                ).length
                                        }{" "}
                                        selected )
                                    </h2>
                                    <SheetDescription>
                                        Pressing "{process.env.NEXT_PUBLIC_STRING_FEATURE_750_FEATURE}
                                        " will set all the selected{" "}
                                        {process.env.NEXT_PUBLIC_STRING_TERM_50_PLURAL
                                            ? process.env.NEXT_PUBLIC_STRING_TERM_50_PLURAL.toLowerCase()
                                            : "entries"}{" "}
                                        as{" "}
                                        {process.env.NEXT_PUBLIC_STRING_TERM_1400_STATUS_100
                                            ? process.env.NEXT_PUBLIC_STRING_TERM_1400_STATUS_100.toLowerCase()
                                            : "new/unread"}
                                        .
                                    </SheetDescription>

                                    <ExpandableList
                                        previewChildren={
                                            <div className="bg-turquoise-100 dark:bg-turquoise-900 rounded p-2 divide-y divide-dashed">
                                                {[
                                                    table
                                                        .getFilteredSelectedRowModel()
                                                        .rows.find(
                                                            (r) =>
                                                                r.original && r.original.status === "acknowledged"
                                                        ),
                                                ].map((row) => (
                                                    <div className="flex gap-2 py-1.5 items-center w-full flex-wrap">
                                                        <h2 className="text-xs font-medium">
                                                            {row.getValue("user")
                                                                ? row.getValue("user")
                                                                : process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE}
                                                        </h2>
                                                        &middot;
                                                        <h3 className="text-xs">
                                                            {row.original.added
                                                                ? format(
                                                                    new Date(row.original.added),
                                                                    process.env.NEXT_PUBLIC_DATE_FORMAT
                                                                        ? process.env.NEXT_PUBLIC_DATE_FORMAT
                                                                        : "dd/MM/yyyy"
                                                                )
                                                                : row.original.date
                                                                    ? format(
                                                                        new Date(row.original.date),
                                                                        process.env.NEXT_PUBLIC_DATE_FORMAT
                                                                            ? process.env.NEXT_PUBLIC_DATE_FORMAT
                                                                            : "dd/MM/yyyy"
                                                                    )
                                                                    : ""}
                                                        </h3>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    >
                                        <div className="bg-turquoise-100 dark:bg-turquoise-900 rounded p-2 divide-y divide-dashed">
                                            {table
                                                .getFilteredSelectedRowModel()
                                                .rows.filter(
                                                    (r) =>
                                                        r.original && r.original.status === "acknowledged"
                                                )
                                                .map((row) => (
                                                    <div className="flex gap-2 py-1.5 items-center w-full flex-wrap">
                                                        <h2 className="text-xs font-medium">
                                                            {row.getValue("user")
                                                                ? row.getValue("user")
                                                                : process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE}
                                                        </h2>
                                                        &middot;
                                                        <h3 className="text-xs">
                                                            {row.original.added
                                                                ? format(
                                                                    new Date(row.original.added),
                                                                    process.env.NEXT_PUBLIC_DATE_FORMAT
                                                                        ? process.env.NEXT_PUBLIC_DATE_FORMAT
                                                                        : "dd/MM/yyyy"
                                                                )
                                                                : row.original.date
                                                                    ? format(
                                                                        new Date(row.original.date),
                                                                        process.env.NEXT_PUBLIC_DATE_FORMAT
                                                                            ? process.env.NEXT_PUBLIC_DATE_FORMAT
                                                                            : "dd/MM/yyyy"
                                                                    )
                                                                    : ""}
                                                        </h3>
                                                    </div>
                                                ))}
                                        </div>
                                    </ExpandableList>
                                    <DataTableRowButton
                                        darkMode={darkMode}
                                        rows={table.getFilteredSelectedRowModel().rows}
                                        row={undefined}
                                        status="acknowledged"
                                    />
                                </div>
                            )}
                    </div>
                </div>
                <SheetFooter>
                    <SheetClose asChild>
                        <MDButton style={cysrConfig().button_style} size="medium"
                            className="h-8 whitespace-nowrap" variant="secondary">
                            Cancel and close
                        </MDButton>
                    </SheetClose>
                </SheetFooter>
            </SheetContent>
        </Sheet>
    );
}
