/* eslint-disable */
import { Base64 } from 'js-base64';
import { toast } from "sonner"
// import { Link } from "react-router-dom";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import cysrConfig from "layouts/cysr/config";
import ButtonActions from "layouts/cysr/components/buttonActions";
import MDBadge from "components/MDBadge";
import Grid from "@mui/material/Grid";
import MDSnackbar from "components/MDSnackbar";
import { useMemo, useState } from "react";

import { useMaterialUIController } from "context";
/*
checkboxed tags (autocomplete) s
*/
import OptionDeleteIcon from '@mui/icons-material/Close';
import Chip from "@mui/material/Chip";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// import Select, { components, defaultTheme } from 'react-select';
import ReactMultiSelectCheckboxes, { components } from 'react-multiselect-checkboxes';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';

import { Link } from "react-router-dom";

import VirtualizedCheckboxes from "layouts/cysr/components/VirtualizedCheckboxes";
import { getCompany } from "../../../../db";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
/*
checkboxed tags (autocomplete) e
*/

// const { colors } = defaultTheme;

const colourStyles = {
  placeholder: (styles) => ({
    ...styles,
    fontSize: '16px',
    color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white"
  }),
  multiValueRemove: (base) => ({
    ...base,
    backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#f8f9fa" : "#23272E",
    borderRadius: 0
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
    backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#f8f9fa" : "#23272E",
    height: "18px",
    minHeight: "18px",
    lineHeight: "1",
    fontSize: '12px',
    borderRadius: 0
  }),
  DropdownIndicator: (base) => ({
    ...base,
    backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b",
    color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
    width: '100%',
    minWidth: '160px',
    // display: "flex",
  }),
  valueContainer: (base) => ({
    ...base,
    backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b",
    color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
    width: '100%',
    minWidth: '160px',
    // display: "flex",
  }),
  container: (base) => ({
    ...base,
    backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b",
    padding: 2,
    width: "100%",
    minWidth: '360px',
  }),

  indicatorsContainer: (styles) => ({ ...styles, height: "100%" }),
  indicatorSeparator: (styles) => ({ ...styles, border: "0", backgroundColor: "transparent" }),
  control: (styles, { isDisabled }) => ({
    ...styles, border: "0", color: isDisabled
      ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#bdc7d0" : "#666666") : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: isDisabled
        ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#EEF0F1" : "#17191c") : (localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b"), height: "32px", minHeight: "32px", minWidth: "160px"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused
            ? undefined
            : undefined,
      color: isDisabled
        ? '#ccc'
        : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
          && data.color
          : undefined,
      },
    };
  },
  menuList: (styles) => ({ ...styles, position: "fixed", color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b" }),
  input: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
  singleValue: (styles, { data }) => ({ ...styles }),
};


const CustomSelectValue = props => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: props.data.icon }} />
    <span style={{ fontSize: "16px", lineHeight: "1" }}>{props.data.label}</span>
  </div>
)
// const { Option } = components
const CustomSelectOption = props => (
  <Option {...props}>
    <div style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
      <span style={{ display: "inline-flex", alignItems: "center", transform: "scale(0.75)", marginRight: "12px" }} dangerouslySetInnerHTML={{ __html: props.data.icon }} />
      <span style={{ fontSize: "14px", lineHeight: "1" }}>{props.data.label}</span>
    </div>
  </Option>
)

const selectStyles = {

  valueContainer: (base) => ({
    ...base,
    width: '100%',
    display: "flex"
  }),
  indicatorSeparator: (styles) => ({ ...styles, border: "0", backgroundColor: "transparent" }),
  control: (styles) => ({ ...styles, display: "none" }),
  /*
      control: (provided) => ({
          ...provided,
          minWidth: 240,
          margin: 8,
      }),
      */
  // menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused
            ? undefined
            : undefined,
      color: isDisabled
        ? '#aaa'
        : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
          && data.color
          : undefined,
      },
    };
  },
  menuList: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b" }),
  input: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
  placeholder: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
  singleValue: (styles, { data }) => ({ ...styles }),
};


// styled components

const Menu = (props) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      css={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
      }}
      {...props}
    />
  );
};
const Blanket = (props) => (
  <div
    css={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({
  children,
  isOpen,
  target,
  onClose,
}) => (
  <div css={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div css={{ color: colors.neutral20, height: 20, width: 20 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);

const ChoiceDropdownIndicator = () => (
  <div style={{ color: "#0D918F", height: 20, width: 20, display: "flex", alignItems: "center" }}>
    <Svg>
      <path d="M10 13.75L3.75 7.5L4.625 6.625L10 12L15.375 6.625L16.25 7.5L10 13.75Z" fill="currentColor" />
    </Svg>
  </div>
);


function ThirdpartyLinkedModal({ dialogOpen, setDialogOpen, thirdpartyName, thirdpartyReqUuid, accountLinkedCompanies, setAccountLinkedCompanies }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let accounts = [];
  const [thirdpartyLinkedModalData, setThirdpartyLinkedModalData] = useState(false);
  const [linkableCompanies, setLinkableCompanies] = useState(false);
  const [thirdpartyLinkedValue, setThirdpartyLinkedValue] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const handleClose = () => {
    setDialogOpen(false);
  };
  useMemo(() => {
    // set values of select linked companies
    const companiesRoleCallUrl = `${cysrConfig().api_base_url}user/mycompanies/`;
    fetch(companiesRoleCallUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      },
    })
      .then((response) => response.json())
      .then((responseCompaniesRoleData) => {
        // console.log(responseAccountsRoleData);
        if (responseCompaniesRoleData.code && responseCompaniesRoleData.code === "token_not_valid") {
          // remove localstorage and go to login
          const dMode = localStorage.getItem('cysr_dark_mode') ? localStorage.getItem('cysr_dark_mode') : "true";
          localStorage.clear();
          localStorage.setItem('cysr_dark_mode', dMode);
          window.location.href = `/cysr/login`;
          return
        }
        const userCompanies = responseCompaniesRoleData;
        userCompanies.sort((a, b) =>
          a.account_name
            .toLowerCase()
            .localeCompare(b.account_name.toLowerCase())
        )
        let lCs = [];
        userCompanies.forEach(lC => {
          /*
          lCs.push(lC.account_name);
          */
          let acName = lC.account_name;
          let acVatName = lC.account_vat_name;
          if (acName && acName.length > 24) {
            acName = (acName.substr(0, 21) + "\u2026");
          }
          if (acVatName && acVatName.length > 24) {
            acVatName = (acVatName.substr(0, 21) + "\u2026");
          }
          const acVat = (lC.account_vat && lC.account_vat !== "N/A") ? (" " + lC.account_vat) : "";
          lCs.push({
            country: lC.account_vat_country_code,
            label: `${acName} ${acVatName && `(${acVatName})`} - ${lC.account_vat_country_code === "XX" ? "other" : lC.account_vat_country_code}${acVat}`,
            name: lC.account_name,
            linked_thirdparties: lC.linked_thirdparties,
            value: lC.account_uuid,
            id: lC.account_uuid,
            checked: lC.linked_thirdparties ? (Object.keys(lC.linked_thirdparties).indexOf(thirdpartyReqUuid) > -1) : false
          })
        });
        setLinkableCompanies(lCs)
        setAccountLinkedCompanies(lCs.filter((lcc) => lcc.checked === true))
        if (lCs.length === 0) {
          setThirdpartyLinkedModalData(1);
        } else {
          setThirdpartyLinkedModalData(lCs[0])
        }
      })
      .catch((err) => {
        console.error("err1:", err);
        console.error(err);
        enqueueSnackbar(
          <div style={{ display: "flex", flexDirection: "column" }}>
            <MDTypography variant="caption" style={{ fontWeight: "600" }}>Reading failed</MDTypography>
            <MDTypography variant="caption">Couldn't find companies that this thirdparty can be linked to</MDTypography>
          </div>, {
          variant: 'error',
          persist: false
        })
      });
  }, [])
  return (thirdpartyLinkedModalData && thirdpartyLinkedModalData !== false) ?
    <Dialog
      fullScreen={false}
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="remove-user-dialog-title-linkeds"
      style={{
        background: darkMode ? 'rgba(0,0,0,0.25)' : 'rgba(255, 255, 255, 0.25)',
        backdropFilter: "blur(12px)",
        zIndex: 999999
      }}>
      <div style={{ background: darkMode ? '#192328' : '#ffffff' }}>
        <DialogTitle id="remove-user-dialog-title-linkeds">
          {"Linked Companies"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {thirdpartyLinkedModalData === 1 ?
              <MDTypography variant="body2">
                Do you want to activate a special configuration?
              </MDTypography>
              :
              <>
                <MDTypography variant="body2">
                  Linked companies configuration for {thirdpartyName}
                </MDTypography>
                <MDTypography variant="caption" color="text" mb={2} style={{ display: "block" }}>
                  select companies to link this Thirdparty to, then update to save the change
                </MDTypography>
                {false && <MDTypography variant="caption" color="text">
                  type to filter companies
                </MDTypography>}
              </>
            }
            <div style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              marginTop: 4,
            }}>
              <div key={thirdpartyLinkedModalData.usernotificationtopic_uuid} style={{ display: "flex", flexDirection: "column", gap: 12 }}>

                {false && <ReactMultiSelectCheckboxes isDisabled={thirdpartyLinkedModalData === 1} isSearchable={false} options={linkableCompanies} defaultValue={linkableCompanies.filter(l => l.linked_thirdparties && l.linked_thirdparties[thirdpartyReqUuid])}
                  styles={colourStyles}
                  onChange={(newValue) => {
                    console.warn(newValue)
                    setThirdpartyLinkedValue(newValue)
                  }}
                  components={{
                    ChoiceDropdownIndicator,
                    // Option: CustomSelectOption, 
                    SingleValue: CustomSelectValue
                  }}
                />}

                <VirtualizedCheckboxes items={linkableCompanies} darkMode={darkMode} label="type to filter companies" onChange={(newValues) => {
                  setThirdpartyLinkedValue(newValues)
                }} />
                {displayErrorMessage && <MDTypography color="warning" variant="caption">{displayErrorMessage}</MDTypography>}
                <div style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "8px",
                }}
                >
                  <MDButton style={cysrConfig().button_style} size="medium" onClick={handleClose}>
                    <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().close.icon.svg }} />
                    Cancel and close
                  </MDButton>
                  <MDButton style={cysrConfig().button_style} size="medium" onClick={() => {
                    {
                      if (thirdpartyLinkedValue && (thirdpartyLinkedValue.value || thirdpartyLinkedValue.length > 0)) {
                        const topicsPatchUrl = `${cysrConfig().api_base_url}thirdparty/${thirdpartyReqUuid}/accounts/`;
                        let thirdparty_account_list_value = thirdpartyLinkedValue;
                        if (thirdpartyLinkedValue.value) {
                          // only 1
                          thirdparty_account_list_value = [thirdpartyLinkedValue.value];
                        } else {
                          let temp = [];
                          thirdparty_account_list_value.forEach(element => {
                            temp.push(element.id)
                          });
                          thirdparty_account_list_value = temp;
                        }
                        fetch(topicsPatchUrl, {
                          body: JSON.stringify({
                            thirdparty_account_list: thirdparty_account_list_value,
                          }),
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                            "content-type": "application/json",
                          },
                          method: "PUT",
                        })
                          .then((response) => {
                            if (response.ok) {
                              response.json()
                                .then((responseData) => {
                                  console.log("responseData")
                                  console.log(responseData)
                                  // window.location.reload()
                                  let t = [];
                                  if (thirdpartyLinkedValue.value) {
                                    t.push(thirdpartyLinkedValue)
                                  } else {
                                    thirdpartyLinkedValue.forEach(tpv => {
                                      t.push(tpv)
                                    });
                                  }
                                  setAccountLinkedCompanies(t)
                                  handleClose()
                                })
                            } else {
                              return response.json().then(err => {
                                throw JSON.stringify(err)
                              })
                            }
                          })
                          .catch((err) => {
                            console.error("enabled err:", err);
                            console.error(err);
                          });
                      } else {
                        // empty
                        setDisplayErrorMessage("Must select at least one company to update")
                      }
                    }
                  }}
                  >
                    <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().update.icon.svg }} />
                    Update linked companies
                  </MDButton>
                </div>

              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </div>
    </Dialog> : null
}
export default function companyInfo(item = false) {
  if (item) {
    const [accountLinkedCompanies, setAccountLinkedCompanies] = useState([]);
    const [linkedThirdParties, setLinkedThirdParties] = useState(false)

    useMemo(() => {
      async function fetchData() {
        if (
          item.useraccountrole
          && item.useraccountrole.accounts_role
          && item.useraccountrole.accounts_role.account_uuid

          // && localStorage.getItem("cysr_useraccountroles")
          // && JSON.parse(localStorage.getItem("cysr_useraccountroles"))
          // && (JSON.parse(localStorage.getItem("cysr_useraccountroles"))).filter((x) => x.account_uuid == item.useraccountrole.accounts_role.account_uuid)
          // && (JSON.parse(localStorage.getItem("cysr_useraccountroles"))).filter((x) => x.account_uuid == item.useraccountrole.accounts_role.account_uuid)[0]
          // && (JSON.parse(localStorage.getItem("cysr_useraccountroles"))).filter((x) => x.account_uuid == item.useraccountrole.accounts_role.account_uuid)[0].linked_thirdparties
        ) {
          const gc = await getCompany(item.useraccountrole.accounts_role.account_uuid)
          if (gc.linked_thirdparties) {
            setLinkedThirdParties(Object.entries(gc.linked_thirdparties))
          }
        }
      }
      fetchData()
      //?
      //// Object.entries((JSON.parse(localStorage.getItem("cysr_useraccountroles"))).filter((x) => x.account_uuid == item.useraccountrole.accounts_role.account_uuid)[0].linked_thirdparties)
      //:
      //false
    }, [])

    const third_party = item.useraccountrole.third_party;
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const [show, setShow] = useState(false);
    const defaultToggleSnackbar = () => {
      setShow(!show);
    }
    const [editMode, setEditMode] = useState(false);
    const [accountTags, setAccountTags] = useState(item.useraccountrole && item.useraccountrole.accounts_role && item.useraccountrole.accounts_role.company_profile && item.useraccountrole.accounts_role.company_profile.account_tags ? item.useraccountrole.accounts_role.company_profile.account_tags : []);
    const [editingAccountTags, setEditingAccountTags] = useState(item.useraccountrole && item.useraccountrole.accounts_role && item.useraccountrole.accounts_role.company_profile && item.useraccountrole.accounts_role.company_profile.account_tags ? item.useraccountrole.accounts_role.company_profile.account_tags : []);

    const [msgDateTime, setMsgDateTime] = useState("Information");
    const [msgIcon, setMsgIcon] = useState("info");
    const [msgTheme, setMsgTheme] = useState("light");
    const [msgTitle, setMsgTitle] = useState("Recheck not executed");
    // reacthtml msgContent:
    const [msgContent, setMsgContent] = useState(
      <></>
    )
    const [dialogOpen, setDialogOpen] = useState(false);

    function askConfirmRecheck(accountUuid, accountName) {
      const isWithLimitedCheckCount = (subscriptionCheckCount && (subscriptionCheckCount.indexOf("&infin;") > -1 === false));

      setMsgDateTime("Information");
      if (isWithLimitedCheckCount) {
        setMsgIcon("warning");
      } else {
        setMsgIcon("info");
      }
      setMsgTheme("light");
      setMsgTitle("Recheck confirmation");
      // reacthtml msgContent:
      setMsgContent(
        <>
          <Grid container spacing={1} my={1}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <MDTypography variant="text" fontWeight="regular" style={{ color: localStorage.getItem("cysr_dark_mode") === "false" ? "#354767" : "#7B809A" }}>
                    Recheck Company {accountName}?
                  </MDTypography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {isWithLimitedCheckCount ?
                  <Grid item xs={12}>
                    <MDTypography style={{
                      alignItems: "center",
                      display: "flex",
                      gap: "4px"
                    }} variant="button" color="error" fontWeight="medium">This will reduce your recheck count, tap <span style={{
                      ...cysrConfig().button_icon_style,
                      color: "inherit!important"
                    }} dangerouslySetInnerHTML={{ __html: cysrConfig().update.icon.svg }} /> to proceed.</MDTypography>
                  </Grid>
                  : null
                }
                <Grid item>
                  <MDButton onClick={() => executeRecheck(accountUuid)} style={{
                    ...cysrConfig().button_style,
                    background: isWithLimitedCheckCount ? "#e7212e" : null
                  }} size="small" color="info"><span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().recheck.icon.svg }} />Confirm Recheck Request {isWithLimitedCheckCount ? `(${subscriptionCheckCount})` : ""}</MDButton>
                </Grid>
                {isWithLimitedCheckCount ?
                  <Grid item>
                    <MDButton onClick={() => setShow(false)} style={cysrConfig().button_style} size="small" color="info">
                      Cancel
                    </MDButton>
                  </Grid>
                  : null
                }
              </Grid>
            </Grid>
          </Grid>
        </>
      )
      setShow(true)
    }
    function executeRecheck(accountUuid) {
      setMsgContent(
        <>
          <Grid container spacing={1} my={1}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <MDTypography variant="text" fontWeight="regular" style={{ color: localStorage.getItem("cysr_dark_mode") === "false" ? "#354767" : "#7B809A" }}>
                    Rechecking Company {accountName}..
                  </MDTypography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <MDButton style={cysrConfig().button_style} size="small" color="info" disabled><span style={cysrConfig().button_icon_left_style} className="spin" dangerouslySetInnerHTML={{ __html: cysrConfig().loading.icon.svg }} />Recheck Requested</MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )

      const recheckCompanyUrl = `${cysrConfig().api_base_url}account/${accountUuid}/recheck/`;
      fetch(recheckCompanyUrl, {
        // body: JSON.stringify({}),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
          "content-type": "application/json",
        },
        method: "PATCH",
      })
        .then((response) => {
          if (response.ok) {
            // window.location.reload();
            return response.json();
          } else {
            console.error(response);
            return response.json().then(err => {
              throw JSON.stringify(err)
            })
            return response.json()
          }
        })
        .then((recheckData) => {
          console.log(recheckData);

          setMsgIcon("checkmark")
          setMsgTheme("success")
          if (recheckData && recheckData.CheckDetail) {
            setMsgContent(recheckData.CheckDetail)
          } else if (recheckData && recheckData[0] && recheckData[0].CheckDetail) {
            setMsgContent(recheckData[0].CheckDetail)
          } else {
            setMsgContent("Server received the request")
          }
          if (recheckData && recheckData.CheckStatus) {
            setMsgTitle(recheckData.CheckStatus)
          } else if (recheckData && recheckData[0] && recheckData[0].CheckStatus) {
            setMsgTitle(recheckData[0].CheckStatus)
          } else {
            setMsgTitle("Success")
          }
          setShow(true)
        })
        .catch((err) => {
          console.log(err);
          console.log("errQ:", err);

          if (err && err.CheckDetail) {
            if (err && err.CheckStatus) {
              executeError(err.CheckStatus, err.CheckDetail);
            } else {
              executeError("Error Message", err.CheckDetail);
            }
          } else if (err) {
            if (JSON.parse(err) && JSON.parse(err).CheckDetail) {
              if (JSON.parse(err) && JSON.parse(err).CheckStatus) {
                executeError(JSON.parse(err).CheckStatus, JSON.parse(err).CheckDetail)
              } else {
                executeError("Error Message", JSON.parse(err).CheckDetail)
              }
            } else {
              executeError("Error", err);
            }
          } else {
            executeError("Error", "An error happened");
          }
        });
    };

    function executeError(t = "Error", e = "Check your subscription") {
      setMsgTitle(t)
      setMsgContent(e)
      setMsgIcon("error")
      setMsgTheme("error")

      setShow(true)
    }

    let showbuttons = true;
    if ((item.nobuttons && item.nobuttons === true) || (item.useraccountrole && item.useraccountrole.isSubscriptionPreview && item.useraccountrole.isSubscriptionPreview === true)) {
      showbuttons = false;
    }
    let features = null;
    let accountName = false;
    let accountVat = "";
    let accountVatName = "";
    let accountUuid = "";
    let accountVatAddress = "";
    let accountVatCity = "";
    let accountVatCountryCode = "";
    let accountCompanySize = "";
    let accountIndustryDescription = "";
    let accountSubscription = "";
    let accountAssetsCount = 50;
    if (item && item.useraccountrole && item.useraccountrole.assets && item.useraccountrole.assets.summarized && item.useraccountrole.assets.summarized.asset_count_total) {
      accountAssetsCount = item.useraccountrole.assets.summarized.asset_count_total;
      features = item.useraccountrole.accounts_role.features;
    }
    // api mainpage dashboard
    if (item && item.useraccountrole && item.useraccountrole.accounts_role && item.useraccountrole.accounts_role.company_profile) {
      /*
      OK account_name: "Matrix Srl"
      OK account_uuid: "30951ba5-b07b-4692-be87-d090432489ee"
      OK account_data_national_id: "02134910351"
      OK account_vat_address: "VIA VIVALDI 16/18"
      OK account_vat_city: "GATTATICO"
      OK account_vat_name: "MATRIX S.R.L."
      mu_created_at: "2022-03-29T16:27:18.305991"
      */
      features = item.useraccountrole.accounts_role.features;
      accountName = item.useraccountrole.accounts_role.company_profile.account_name;
      accountVat = item.useraccountrole.accounts_role.company_profile.account_data_national_id;
      accountVatCountryCode = item.useraccountrole.accounts_role.company_profile.account_vat_country_code;
      accountVatName = item.useraccountrole.accounts_role.company_profile.account_vat_name;
      accountUuid = item.useraccountrole.accounts_role.company_profile.account_uuid;
      // new fields
      accountVatAddress = item.useraccountrole.accounts_role.company_profile.account_vat_address;
      accountVatCity = item.useraccountrole.accounts_role.company_profile.account_vat_city;
      accountCompanySize = item.useraccountrole.accounts_role.company_profile.account_company_size;
      accountIndustryDescription = item.useraccountrole.accounts_role.company_profile.account_data_industry_descr;
      accountSubscription = item.useraccountrole.accounts_role.company_profile.account_subscription;
    } else if (item && item.useraccountrole.account && item.useraccountrole.account_uuid) {
      features = item.useraccountrole.accounts_role.features;
      accountName = item.useraccountrole.account_name;
      accountVat = item.useraccountrole.account_data_national_id;
      accountVatCountryCode = item.useraccountrole.account_vat_country_code;
      accountVatName = item.useraccountrole.account_vat_name;
      accountUuid = item.useraccountrole.account_uuid;
      // new fields
      accountVatAddress = item.useraccountrole.account_vat_address;
      accountVatCity = item.useraccountrole.account_vat_city;
      accountCompanySize = item.useraccountrole.account_company_size;
      accountIndustryDescription = item.useraccountrole.account_data_industry_descr;
      accountSubscription = item.useraccountrole.account_subscription;
    } else if (item && item.useraccountrole.accounts_role && item.useraccountrole.accounts_role.account_name) {
      features = item.useraccountrole.accounts_role.features;
      accountName = item.useraccountrole.accounts_role.account_name;
      accountVat = item.useraccountrole.accounts_role.account_data_national_id;
      accountVatCountryCode = item.useraccountrole.accounts_role.account_vat_country_code;
      accountVatName = item.useraccountrole.accounts_role.account_vat_name;
      accountUuid = item.useraccountrole.accounts_role.account_uuid;
      // new fields
      accountVatAddress = item.useraccountrole.accounts_role.account_vat_address;
      accountVatCity = item.useraccountrole.accounts_role.account_vat_city;
      accountCompanySize = item.useraccountrole.accounts_role.account_company_size;
      accountIndustryDescription = item.useraccountrole.accounts_role.account_data_industry_descr;
      accountSubscription = item.useraccountrole.accounts_role.account_subscription;
    } else if (item && item.useraccountrole && item.useraccountrole.account_name) {
      features = item.useraccountrole.features;
      accountName = item.useraccountrole.account_name;
      accountVat = item.useraccountrole.account_data_national_id;
      accountVatCountryCode = item.useraccountrole.account_vat_country_code;
      accountVatName = item.useraccountrole.account_vat_name;
      accountUuid = item.useraccountrole.account_uuid;
      // new fields
      accountVatAddress = item.useraccountrole.account_vat_address;
      accountVatCity = item.useraccountrole.account_vat_city;
      accountCompanySize = item.useraccountrole.account_company_size;
      accountIndustryDescription = item.useraccountrole.account_data_industry_descr;
      accountSubscription = item.useraccountrole.account_subscription;
    } else {
      console.warn(item);
    }
    if (accountName === null) {
      accountName = "";
    }
    if (accountVatName === null) {
      accountVatName = "";
    }
    if (accountVat === null) {
      accountVat = "";
    }

    // get subscription id and info from mydata s
    let subscriptionCheckCount = false;
    // if (accountUuid && (third_party === undefined || third_party === false) && cysrConfig().feature_available("company_recheck")) {
    if (accountUuid && (third_party === undefined || third_party === false) && features && features.company_recheck != "false") {
      if (localStorage.getItem("cysr_mydata") && JSON.parse(localStorage.getItem("cysr_mydata"))) {
        const myData = JSON.parse(localStorage.getItem("cysr_mydata"));
        const subscriptionsData = myData.subscriptions;
        for (const key in subscriptionsData) {
          if (Object.hasOwnProperty.call(subscriptionsData, key)) {
            const s = subscriptionsData[key];
            if (s.subscription_status === "ACTIVE") {
              if (key === accountSubscription) {
                // subscriptionCheckCount = ((s.subscription_check_count).replace("&infin;", "∞"));
                subscriptionCheckCount = s.subscription_check_count;
              }
            }
          }
        }
      }
    }
    // get subscription id and info from mydata e
    return (
      <MDBox pt={0}>
        {accountUuid ? (
          <>
            {
              /*
              <MDBox>
                {
                  // <Link to={`/company/${accountUuid}/overview`}>
                }
                <MDTypography style={{cursor:"pointer"}} onClick={() => {
                  // if available set the current account uuuid as selected
                  if(accountUuid) {
                    localStorage.setItem("cysr_useraccountrole_selected", accountUuid)
                    window.location.href = `/company/${accountUuid}/overview`
                  }
                  // if available set the current account uuuid as selected e
                }} variant="h5">{accountName}</MDTypography>
                {
                  // </Link>
                }
              </MDBox>
              {
                */
            }
            <MDBox
              mt={showbuttons ? -2 : 0}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              style={{ position: "relative" }}>
              <MDBox display="flex" alignItems="center">
                <MDBox height="100%" lineHeight={1} mr={2}>

                  {
                    /*
                  }
                  <MDTypography display="block" variant="button" fontWeight="regular" color="text">
                    {accountVatName}
                  </MDTypography>
                  {
                    */
                  }
                  {accountVatAddress && accountVatAddress !== "" && accountVatCity && accountVatCity !== "" ? (
                    <MDTypography display="block" variant="caption" color="text">
                      {accountVatAddress}, {accountVatCity}
                    </MDTypography>
                  ) : (accountVatAddress && accountVatAddress !== "" ? (
                    <MDTypography display="block" variant="caption" color="text">
                      {accountVatAddress}
                    </MDTypography>
                  ) : accountVatCity && accountVatCity !== "" && (
                    <MDTypography display="block" variant="caption" color="text">
                      {accountVatCity}
                    </MDTypography>
                  ))}
                  {accountVat && accountVat !== "" && (
                    <MDBox>
                      <MDTypography variant="caption" color="text">ID:&nbsp;</MDTypography>
                      <MDTypography variant="caption" fontWeight="bold" color="text">
                        {accountVat}
                      </MDTypography>
                    </MDBox>
                  )}
                  {accountVatCountryCode && accountVatCountryCode !== "" && (
                    <MDBox>
                      <MDTypography variant="caption" color="text">Country:&nbsp;</MDTypography>
                      <MDTypography variant="caption" fontWeight="bold" color="text">
                        {accountVatCountryCode === "XX" ? "N/A" : accountVatCountryCode}
                      </MDTypography>
                    </MDBox>
                  )}
                  {accountIndustryDescription &&
                    <MDBox>
                      <MDTypography variant="caption" color="text">Industry:&nbsp;</MDTypography>
                      <MDTypography variant="caption" fontWeight="bold" color="text">
                        {accountIndustryDescription}
                      </MDTypography>
                    </MDBox>
                  }
                  {accountCompanySize &&
                    <MDBox>
                      <MDTypography variant="caption" color="text">Size:&nbsp;</MDTypography>
                      <MDTypography variant="caption" fontWeight="bold" color="text">
                        {accountCompanySize}
                      </MDTypography>
                    </MDBox>
                  }
                </MDBox>
              </MDBox>
            </MDBox>
            {showbuttons && accountTags &&
              <div style={{ display: "flex", alignItems: "flex-start", flexWrap: "wrap", marginTop: "8px" }}>
                <MDTypography variant="caption" color="text">Tags:&nbsp;</MDTypography>
                {editMode ?
                  <div style={{
                    display: "flex",
                    width: "100%",
                    maxWidth: '480px',
                    flexDirection: "column",
                    gap: "4px"
                  }}>
                    <MDTypography variant="body" color="text" style={{ fontSize: "13px", lineHeight: "1.2" }}>Add tags by typing. Use comma or press enter to insert. Tap <span style={{
                      ...cysrConfig().button_icon_style,
                      transform: "translateY(5px)"
                    }} dangerouslySetInnerHTML={{ __html: cysrConfig().update.icon.svg }} /> to update them</MDTypography>
                    <Autocomplete
                      color="info"
                      multiple
                      limitTags={5}
                      freeSolo
                      size="small"
                      id="multiple-company-tags"
                      disableCloseOnSelect
                      onChange={(e, v) => setEditingAccountTags(v)}
                      options={accountTags}
                      getOptionLabel={(option) => option}
                      // disable dropdown
                      PopperComponent={() => (
                        null
                      )}
                      renderOption={(props, option, { selected }) => (
                        <li {...props} style={{ marginBottom: "4px", zIndex: 9999999 }}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      // defaultValue={accountTags}
                      // https://stackoverflow.com/a/59038435
                      value={editingAccountTags}
                      clearOnBlur={true}
                      renderTags={(value, getTagProps) => value.map(function (option, index) {
                        let label = option.rating ? option.rating : option;
                        let descriptiveLabel = false;
                        // let chipColor = darkMode ? 'rgba(0,0,0,.85)' : 'rgb(250, 250, 250)';
                        // let color = "secondary";
                        let icon = null;
                        // "outlined", "filled";
                        let variant = "filled";
                        // color = "default";
                        // chipColor = darkMode ? 'hsl(205deg 73% 6%)' : 'hsl(214deg 82% 94%)';
                        return (
                          <Chip
                            // sx={{ color: `${darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgba(0, 0, 0, 0.85)'}!important`, border: `${darkMode ? '1px solid rgb(48, 48, 48)' : '1px solid rgb(232, 232, 232)'}!important`, background: `${chipColor}!important`, borderRadius: 0, fontFamily: "Ubuntu Mono, monospace" }}
                            sx={{ color: `${darkMode ? '#095bc6' : '#095bc6'}!important`, border: `${darkMode ? '1px solid rgb(48, 48, 48)' : '1px solid rgb(232, 232, 232)'}!important`, background: `#aecef7!important`, borderRadius: 0, fontFamily: "Ubuntu Mono, monospace" }}
                            icon={icon}
                            // color={color}
                            variant={variant}
                            label={label}
                            size="small"
                            deleteIcon={<OptionDeleteIcon sx={{ color: `${darkMode ? '#095bc6' : '#095bc6'}!important` }} />}
                            {...getTagProps({ index })} />
                        );
                      })}
                      renderInput={(params) => {
                        params.inputProps.onKeyDown = event => {
                          switch (event.key) {
                            case "Enter":
                            case ".":
                            case ",":
                            case ";": {
                              event.preventDefault();
                              event.stopPropagation();
                              if (event.target.value.length > 0) {
                                if (editingAccountTags.length < 5) {
                                  setEditingAccountTags([...editingAccountTags, event.target.value]);
                                } else {
                                  toast.warning("No more than 5 tags can be added.")
                                }
                              }
                              break;
                            }
                            default:
                          }
                        };
                        return (

                          <TextField
                            {...params}
                            // variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            // sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#121619' : '#f3f4f7' }}
                            sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? 'rgb(18 22 25)' : '#f3f4f7' }}
                            placeholder="Tags"
                          />
                        )
                      }}
                      sx={{ width: "100%", maxWidth: '480px' }}
                    />
                  </div>
                  :
                  <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                    {accountTags && accountTags.map((t) =>
                      <MDBadge style={{ cursor: "pointer" }} key={t} variant="contained" badgeContent={
                        <>
                          <span>{t}</span>
                        </>
                      } size="xs" container />
                    )}
                  </div>
                }
                {editMode ?
                  <div style={{ display: "inline-flex", alignItems: "end", height: "44px", flexWrap: "wrap", marginTop: "auto", marginLeft: "8px", gap: "8px", paddingBottom: "1px" }}>
                    <MDButton style={cysrConfig().button_style} size="medium" onClick={
                      () => {
                        const updateTagsUrl = `${cysrConfig().api_base_url}account/${accountUuid}/tags/`;


                        fetch(updateTagsUrl, {
                          body: JSON.stringify({ tags: editingAccountTags }),
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                            "content-type": "application/json",
                          },
                          method: "PUT",
                        })
                          .then((response) => {
                            if (response.ok) {
                              // window.location.reload();
                              return response.json();
                            } else {
                              console.error(response);
                              return response.json().then(err => {
                                throw JSON.stringify(err)
                              })
                              return response.json()
                            }
                          })
                          .then((updateTagsData) => {
                            console.log(updateTagsData);
                            setAccountTags(editingAccountTags)
                            setEditMode(false)

                            setMsgIcon("checkmark")
                            setMsgTheme("success")
                            if (updateTagsData && updateTagsData.CheckDetail) {
                              setMsgContent(updateTagsData.CheckDetail)
                            } else if (updateTagsData && updateTagsData[0] && updateTagsData[0].CheckDetail) {
                              setMsgContent(updateTagsData[0].CheckDetail)
                            } else {
                              setMsgContent("Server received the request")
                            }
                            if (updateTagsData && updateTagsData.CheckStatus) {
                              setMsgTitle(updateTagsData.CheckStatus)
                            } else if (updateTagsData && updateTagsData[0] && updateTagsData[0].CheckStatus) {
                              setMsgTitle(updateTagsData[0].CheckStatus)
                            } else {
                              setMsgTitle("Success")
                            }
                            setShow(true)
                          })
                          .catch((err) => {
                            console.log(err);
                            console.log("errQ:", err);

                            if (err && err.CheckDetail) {
                              if (err && err.CheckStatus) {
                                executeError(err.CheckStatus, err.CheckDetail);
                              } else {
                                executeError("Error Message", err.CheckDetail);
                              }
                            } else if (err) {
                              if (JSON.parse(err) && JSON.parse(err).CheckDetail) {
                                if (JSON.parse(err) && JSON.parse(err).CheckStatus) {
                                  executeError(JSON.parse(err).CheckStatus, JSON.parse(err).CheckDetail)
                                } else {
                                  executeError("Error Message", JSON.parse(err).CheckDetail)
                                }
                              } else {
                                executeError("Error", err);
                              }
                            } else {
                              executeError("Error", "An error happened");
                            }
                          });
                      }
                    }>
                      <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().update.icon.svg }} />
                      Update tags
                    </MDButton>
                    <MDButton style={cysrConfig().button_style} size="medium" onClick={
                      () => { setEditMode(!editMode) }
                    }>
                      <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().close.icon.svg }} />
                      Cancel
                    </MDButton>
                  </div>
                  :
                  <MDButton
                    onClick={() => {
                      setEditMode(!editMode)
                    }} style={{
                      background: "transparent",
                      color: darkMode ? "#5c959f" : "#0f6066",
                      height: "20px",
                      padding: "0",
                      fontSize: "16px",
                      fontWeight: 400,
                      textTransform: "none",
                      minHeight: "unset",
                      minWidth: "unset"
                    }} size="medium">
                    <span style={{
                      color: darkMode ? "#5c959f" : "#0f6066",
                      display: "inline-flex",
                      alignItems: "center",
                      transform: "scale(0.6)"
                    }} dangerouslySetInnerHTML={{ __html: cysrConfig().edit.icon.svg }} />
                  </MDButton>
                }
              </div>
            }
            {third_party ?
              (
                showbuttons && accountLinkedCompanies &&
                <div style={{ display: "flex", alignItems: "flex-start", flexWrap: "wrap", marginTop: "8px" }}>
                  <MDTypography variant="caption" color="text">linked companies:&nbsp;</MDTypography>
                  {accountLinkedCompanies && accountLinkedCompanies.length > 0 &&
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                      {accountLinkedCompanies && accountLinkedCompanies.map((t) =>
                        <a key={t.value} style={{ display: "inline-flex" }} href={`/company/${t.id}/overview`}>
                          <MDBadge style={{ cursor: "pointer" }} variant="contained" badgeContent={
                            <>
                              <span>{((t.name ? t.name : t.label) && (t.name ? t.name : t.label).length > 24) ? (t.name ? t.name : t.label).substr(0, 21) + "\u2026" : (t.name ? t.name : t.label)}</span>
                            </>
                          } size="xs" container />
                        </a>
                      )}
                      <a style={{ display: "inline-flex" }} href={`/cysr/companies?t=${Base64.encode(`["linked_third_party:${accountName}"]`)}`}>
                        <MDBadge style={{ cursor: "pointer" }} variant="gradient" badgeContent={
                          <>
                            <span>view all</span>
                          </>
                        } size="xs" container />
                      </a>
                    </div>
                  }

                  <MDButton
                    onClick={() => {
                      setDialogOpen(true)
                    }} style={{
                      background: "transparent",
                      color: darkMode ? "#5c959f" : "#0f6066",
                      height: "20px",
                      padding: "0",
                      fontSize: "16px",
                      fontWeight: 400,
                      textTransform: "none",
                      minHeight: "unset",
                      minWidth: "unset"
                    }} size="medium">
                    <span style={{
                      color: darkMode ? "#5c959f" : "#0f6066",
                      display: "inline-flex",
                      alignItems: "center",
                      transform: "scale(0.6)"
                    }} dangerouslySetInnerHTML={{ __html: cysrConfig().edit.icon.svg }} />
                  </MDButton>
                </div>
              )
              :
              (
                linkedThirdParties
                && (linkedThirdParties.length > 0)
                && < div style={{ display: "flex", alignItems: "flex-start", flexWrap: "wrap", marginTop: "8px" }}>
                  <MDTypography variant="caption" color="text">linked third parties:&nbsp;</MDTypography>
                  <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                    {linkedThirdParties && linkedThirdParties.map((t) =>
                      <a key={t[0]} style={{ display: "inline-flex" }} href={`/third-party/${t[0]}/overview`}>
                        <MDBadge style={{ cursor: "pointer" }} variant="contained" badgeContent={
                          <>
                            <span>{
                              (t[1] && t[1].length > 24) ? t[1].substr(0, 21) + "\u2026" : t[1]
                            }</span>
                          </>
                        } size="xs" container />
                      </a>
                    )}<a style={{ display: "inline-flex" }} href={`/cysr/third-parties?t=${Base64.encode(`["linked_companies:${accountName}"]`)}`}>
                      <MDBadge style={{ cursor: "pointer" }} variant="gradient" badgeContent={
                        <>
                          <span>view all</span>
                        </>
                      } size="xs" container />
                    </a>
                  </div>
                </div>
              )
            }
            {(item.useraccountrole && item.useraccountrole.isSubscriptionPreview && item.useraccountrole.isSubscriptionPreview === true) ?
              <Grid mt={1} pt={0} container style={{ alignItems: "flex-end" }} width="100%" spacing={1}>
                <Grid item pt={0}>
                  <Link to={`/company/${accountUuid}/report?starter=true`} target="_blank">
                    <MDButton
                      size="medium"
                      style={cysrConfig().button_style}
                      type="button"
                    // onClick={() => cysrConfig().more_actions.functions.toggle_archive(accountUuid)}
                    >
                      <span style={{ display: "inline-flex", alignItems: "center", transform: "scale(0.75)", marginRight: "12px" }} dangerouslySetInnerHTML={{ __html: cysrConfig().report.icon.svg }} />
                      Download report as PDF
                    </MDButton>
                  </Link>
                </Grid>
              </Grid> : showbuttons && (
                <>
                  {
                    /*
                  }
                  <Grid item container spacing={1}>
                    <Grid item>
       
                      {
                        // <Link to={`/company/${accountUuid}/assets`}>
                      }
                      <MDButton
                          variant="contained"
                          size="medium"
                          color="info"
                          type="button" onClick={() => {
                        // if available set the current account uuuid as selected
                        if(accountUuid) {
                          localStorage.setItem("cysr_useraccountrole_selected", accountUuid)
                          window.location.href = `/company/${accountUuid}/assets`
                        }
                        // if available set the current account uuuid as selected e
                      }}><Icon>arrow_forward</Icon>&nbsp;&nbsp;Go to Assets</MDButton>
                      {
                        // </Link>
                      }                  
                    </Grid>
                    <Grid item>
                      
                      {
                        // <Link to={`/company/${accountUuid}/checks`}>
                      }
                      <MDButton
                          variant="contained"
                          size="medium"
                          color="info"
                          type="button" onClick={() => {
                        // if available set the current account uuuid as selected
                        if(accountUuid) {
                          console.warn("accountUuid")
                          console.warn(accountUuid)
                          localStorage.setItem("cysr_useraccountrole_selected", accountUuid)
                          window.location.href = `/company/${accountUuid}/checks`
                        }
                        // if available set the current account uuuid as selected e
                      }}><Icon>arrow_forward</Icon>&nbsp;&nbsp;Go to Checks</MDButton>
                      {
                        // </Link>
                      }
                    </Grid>
                  </Grid>
                  {
                    // <Grid item container spacing={1}>
                    */
                  }
                  <Grid mt={1} pt={0} container style={{ alignItems: "flex-end" }} width="100%" spacing={1}>
                    {
                      false && (
                        <Grid item pt={0}>
                          <MDButton
                            size="medium"
                            style={cysrConfig().button_style}
                            type="button"
                            onClick={() => cysrConfig().more_actions.functions.toggle_archive(accountUuid)}
                          >
                            <span style={{ display: "inline-flex", alignItems: "center", transform: "scale(0.75)", marginRight: "12px" }} dangerouslySetInnerHTML={{ __html: cysrConfig().archive.icon.svg }} />
                            Archive
                          </MDButton>
                        </Grid>
                      )}
                    {
                      /*
                    }
                    <Grid item>
                      <Link to={`/company/${accountUuid}/report${accountAssetsCount && accountAssetsCount > 0 && "?assets_count="+accountAssetsCount}`} target="_blank">
                        <MDButton style={buttonStyle} size="medium" color="info"><span style={{display: "inline-flex", alignItems: "center", transform: "scale(0.75)", marginRight: "12px"}} dangerouslySetInnerHTML={{__html: cysrConfig().report.icon.svg }} />Download report as PDF</MDButton>
                      </Link>
                    </Grid>
                    {
                      */
                    }
                    {
                      // (third_party === undefined || third_party === false) && cysrConfig().feature_available("company_recheck") && (
                    }
                    {(third_party === undefined || third_party === false) && features && features.company_recheck != "false" && (
                      <Grid item pt={0}>
                        <MDButton onClick={() => askConfirmRecheck(accountUuid, accountName)} style={cysrConfig().button_style} size="medium" color="info"><span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().recheck.icon.svg }} />Recheck</MDButton>
                        <MDSnackbar
                          color={msgTheme}
                          icon={msgIcon}
                          title={msgTitle}
                          content={msgContent}
                          dateTime={msgDateTime}
                          open={show}
                          close={defaultToggleSnackbar}
                        />
                      </Grid>
                    )}
                    {
                      // third party buttons
                    }
                    {third_party && [
                      <Grid item pt={0}>
                        <MDButton onClick={() => cysrConfig().more_actions.functions.toggle_archive(accountUuid)} style={cysrConfig().button_style} size="medium" color="info"><span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().archive.icon.svg }} />Archive third party</MDButton>
                      </Grid>,
                      <Grid item pt={0}>
                        <MDButton onClick={() => setDialogOpen(true)} style={cysrConfig().button_style} size="medium" color="info"><span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().edit.icon.svg }} />Manage linked companies</MDButton>
                        <ThirdpartyLinkedModal thirdpartyName={accountName} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} thirdpartyReqUuid={accountUuid} accountLinkedCompanies={accountLinkedCompanies} setAccountLinkedCompanies={setAccountLinkedCompanies}
                        />
                      </Grid>,
                      <Grid item pt={0}>
                        <MDButton onClick={() => cysrConfig().more_actions.functions.go_to_third_party_report(accountUuid)} style={cysrConfig().button_style} size="medium" color="info"><span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().report.icon.svg }} />Generate synthetic report</MDButton>
                      </Grid>,
                      <MDSnackbar
                        color={msgTheme}
                        icon={msgIcon}
                        title={msgTitle}
                        content={msgContent}
                        dateTime={msgDateTime}
                        open={show}
                        close={defaultToggleSnackbar}
                      />
                    ]}
                    {(third_party === undefined || third_party === false) && features &&
                      <Grid item pt={0} style={{ zIndex: 999999 }}>
                        <ButtonActions features={features} />
                      </Grid>
                    }
                  </Grid>
                </>
              )}
          </>
        ) : <p>This item does not have an ID </p>
        }
      </MDBox >
    );
  }
  return null;
}

/* eslint-disable */