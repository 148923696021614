/* eslint-disable */
// prettier-ignore
import { Input } from "layouts/cysr/components/ui/input";
import MDButton from "components/MDButton";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "layouts/cysr/components/ui/form";

import { ImportantMessage } from "layouts/cysr/components/ImportantMessage";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "layouts/cysr/components/ui/alert-dialog";
import MDTypography from "components/MDTypography";
import { useSnackbar } from 'notistack';
import cysrConfig, { process } from "layouts/cysr/config";
import { format } from "date-fns";

export function CompanyDPO(
  { dpo_not_approved_count = -1, domains = null },
  DPOData,
  setDPOData,
  form,
  // onSubmit: (data) => void,
  onSubmit,
  darkMode,
  open,
  setOpen
) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // add action to an individual snackbar
  const action = snackbarId => (
    <>
      <MDButton onClick={() => {
        window.location.reload()
        closeSnackbar(snackbarId)
      }} style={cysrConfig().button_style} size="medium">
        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().close.icon.svg }} />
        Dismiss and reload page
      </MDButton>
    </>
  );
  const alertDialogAnimationTimeout = 200;
  const importantMessageClasses = "text-left w-full border pl-11 pr-4 py-3 rounded";
  return (
    <div className={darkMode ? "dpo-dark dark" : "dpo-light light"}>
      <div className="flex gap-6">
        <AlertDialog open={open} onOpenChange={setOpen}>
          <AlertDialogTrigger className="w-full">
            {
              (DPOData && DPOData.revocation_link) ? (
                (dpo_not_approved_count > 0) ? (
                  <ImportantMessage
                    button={"Request"}
                    className={`${importantMessageClasses} border-red-500/50 bg-red-500/25`}
                    title={`${process.env
                      .NEXT_PUBLIC_STRING_MODULE_200_DPO_AUTH_MESSAGE_NO_DPO_SET_ALL_TITLE} for ${dpo_not_approved_count} domain${dpo_not_approved_count > 1 ? "s" : ""}`
                    }
                    description={`${process.env
                      .NEXT_PUBLIC_STRING_MODULE_200_DPO_AUTH_MESSAGE_NO_DPO_SET_ALL_DESCRIPTION} for ${dpo_not_approved_count} domain${dpo_not_approved_count > 1 ? "s" : ""}`
                    }
                  // title={"non tutti approvati"}
                  // description={`${dpo_not_approved_count} domini non sono ancora stati approvati dal DPO`}
                  />) :
                  (
                    <ImportantMessage
                      button={"Revoke"}
                      className={`${importantMessageClasses} border-green-500/50 bg-green-500/25`}
                      title={`The Data Protection Officer (DPO) of the company, ${DPOData && DPOData.validator_name
                        }, approved the management of personal data by the platform on ${(DPOData && DPOData.confermation_date) ? format
                          (
                            new Date(DPOData.confermation_date),
                            process.env.NEXT_PUBLIC_DATE_FORMAT ? process.env.NEXT_PUBLIC_DATE_FORMAT : "dd/MM/yyyy"
                          ) : process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE

                        }`}
                      description={``}
                    />
                  )
              ) : (dpo_not_approved_count != -1) ? (
                (
                  <ImportantMessage
                    button={"Request"}
                    className={`${importantMessageClasses} border-red-500/50 bg-red-500/25`}
                    description={
                      process.env
                        .NEXT_PUBLIC_STRING_MODULE_200_DPO_AUTH_MESSAGE_NO_DPO_SET_DESCRIPTION
                    }
                    title={
                      process.env
                        .NEXT_PUBLIC_STRING_MODULE_200_DPO_AUTH_MESSAGE_NO_DPO_SET_TITLE
                    }
                  />
                )
              ) : null
            }
          </AlertDialogTrigger>
          <AlertDialogContent className={`dpo-box ${darkMode ? "dpo-dark dark" : "dpo-light light"}`}>
            {(DPOData && DPOData.revocation_link) ? (
              <>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    {process.env.NEXT_PUBLIC_STRING_MODULE_200_DPO_REVOCATION_TITLE}
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    {
                      process.env
                        .NEXT_PUBLIC_STRING_MODULE_200_DPO_REVOCATION_DESCRIPTION
                    }
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel asChild>
                    <MDButton className="dpo-box-cancel" style={cysrConfig().button_style} size="medium">Cancel</MDButton>
                  </AlertDialogCancel>
                  <MDButton style={cysrConfig().button_style} size="medium"
                    onClick={(event) => {
                      event.preventDefault();

                      fetch(`${cysrConfig().api_base_url}dpo/revoke/${DPOData.revocation_link}/`, {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                        },
                        method: "DELETE"
                      })
                        // +
                        .then((r) => {
                          if (r.ok) {
                            // window.location.reload();
                            return r.json();
                          } else {
                            console.error(r);
                            return r.json().then(err => {
                              throw JSON.stringify(err)
                            })
                            return r.json()
                          }
                        })
                        .then((response) => {

                          // You have successfully revoked the authorization for the processing of personal data. To regain access to the full cybersecurity threat data, please initiate a new authorization process through the Data Protection Officer (DPO) of the company.
                          setDPOData(null)
                          if (setOpen) {
                            setOpen(false);
                          }
                          enqueueSnackbar(
                            <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                              <MDTypography variant="caption" style={{ fontWeight: "600" }}>Authorization Revoked</MDTypography>
                              <MDTypography variant="caption">You have successfully revoked the authorization for the processing of personal data. To regain access to the full cybersecurity threat data, please initiate a new authorization process through the DPO.</MDTypography>
                            </div>, {
                            action,
                            variant: 'success',
                            persist: true
                          }
                          )
                          // window.location.reload()
                        }
                        )
                        // -
                        .catch((err) => {
                          console.log(err);
                          console.log("err3:", err);
                          let errTitle = "Error";
                          let errDesc = "Some error happened";
                          if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
                            errTitle = JSON.parse(err).ErrorMessage;
                            errDesc = JSON.parse(err).ErrorDetail;
                          } else if (JSON.parse(err) && JSON.parse(err).error) {
                            enqueueSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
                            errTitle = "Error";
                            errDesc = JSON.parse(err).error;
                          }
                          enqueueSnackbar(
                            <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                              <MDTypography variant="caption" style={{ fontWeight: "600" }}>{errTitle}</MDTypography>
                              <MDTypography variant="caption">{errDesc}</MDTypography>
                            </div>,
                            {
                              variant: 'error',
                              persist: true
                            }
                          )

                        });

                    }}
                    title={
                      process.env.NEXT_PUBLIC_STRING_MODULE_200_DPO_AUTH_SUBMIT
                    }
                    type="button"
                  >
                    <span>
                      {
                        process.env
                          .NEXT_PUBLIC_STRING_MODULE_200_DPO_REVOCATION_BUTTON
                      }
                    </span>
                  </MDButton>
                </AlertDialogFooter>
              </>
            ) : (dpo_not_approved_count != -1) ? (
              <>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    {process.env.NEXT_PUBLIC_STRING_MODULE_200_DPO_AUTH_TITLE}
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    {process.env.NEXT_PUBLIC_STRING_MODULE_200_DPO_AUTH_DESCRIPTION}
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <div>
                  <div className="mb-6 py-3 px-4 w-full nolg:w-6/12 border border-teal-500/50 bg-teal-500/25 rounded-lg flex flex-col gap-2">
                    <ul className="flex flex-col gap-1 list-disc list-inside marker:text-teal-500">
                      {domains && domains.map((domain) => (
                        <li>{domain[0]}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="text-sm mb-4">
                    {
                      process.env
                        .NEXT_PUBLIC_STRING_MODULE_200_DPO_AUTH_FORM_DESCRIPTION
                    }
                  </div>
                  <div className="mt-4 flex flex-col gap-4 items-center">
                    <div className="w-full nolg:w-6/12 nolg:border-l nolg:border-l-turquoise-300 nolg:dark:border-l-turquoise-700 nolg:pl-4">
                      <Form {...form}>
                        <form
                          onSubmit={form.handleSubmit(onSubmit)}
                          className="w-full flex flex-col gap-4"
                        >
                          <div className="w-full">
                            <FormField
                              control={form.control}
                              name="nominative"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="font-regular">
                                    <FormDescription>
                                      {
                                        process.env
                                          .NEXT_PUBLIC_STRING_MODULE_200_DPO_AUTH_NOMINATIVE_DESCRIPTION
                                      }
                                    </FormDescription>
                                  </FormLabel>

                                  <FormControl onChange={(x) => console.warn(x.target.value)}>
                                    <Input {...field} />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                          <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel className="font-regular">
                                  <FormDescription>
                                    {
                                      process.env
                                        .NEXT_PUBLIC_STRING_MODULE_200_DPO_AUTH_EMAIL_DESCRIPTION
                                    }
                                  </FormDescription>
                                </FormLabel>
                                <FormControl onChange={(a) => console.log(a.target.value)}>
                                  <Input
                                    // placeholder="example@muscope.com"
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />

                          <AlertDialogFooter className="items-center">
                            <AlertDialogCancel asChild>
                              <MDButton className="dpo-box-cancel" style={cysrConfig().button_style} size="medium">Cancel</MDButton>
                            </AlertDialogCancel>
                            <MDButton
                              className="dpo-send-button-icon px-4 py-3 gap-2"
                              size="medium"
                              style={cysrConfig().button_style}
                              title={
                                process.env
                                  .NEXT_PUBLIC_STRING_MODULE_200_DPO_AUTH_SUBMIT
                              }
                              type="submit"
                            >
                              <svg
                                className="w-4 h-4"
                                width="16"
                                height="16"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.20308 1.04312C1.00481 0.954998 0.772341 1.0048 0.627577 1.16641C0.482813 1.32802 0.458794 1.56455 0.568117 1.75196L3.92115 7.50002L0.568117 13.2481C0.458794 13.4355 0.482813 13.672 0.627577 13.8336C0.772341 13.9952 1.00481 14.045 1.20308 13.9569L14.7031 7.95693C14.8836 7.87668 15 7.69762 15 7.50002C15 7.30243 14.8836 7.12337 14.7031 7.04312L1.20308 1.04312ZM4.84553 7.10002L2.21234 2.586L13.2689 7.50002L2.21234 12.414L4.84552 7.90002H9C9.22092 7.90002 9.4 7.72094 9.4 7.50002C9.4 7.27911 9.22092 7.10002 9 7.10002H4.84553Z"
                                  fill="currentColor"
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                ></path>
                              </svg>
                              <span>
                                {
                                  process.env
                                    .NEXT_PUBLIC_STRING_MODULE_200_DPO_AUTH_SUBMIT
                                }
                              </span>
                            </MDButton>
                          </AlertDialogFooter>
                        </form>
                      </Form>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </div>
  );
}
