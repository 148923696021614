/* eslint-disable */
// prettier-ignore
"use client";

import { X } from "lucide-react";

import cysrConfig, { process } from "layouts/cysr/config";
import MDButton from "components/MDButton";
import { Input } from "layouts/cysr/components/ui/input";
import { DataTableFacetedFilter } from "./data-table-faceted-filter";

import { Icons } from "layouts/cysr/components/Icons";

export function DataTableToolbar({
    table,
    data,
    darkMode
}) {
    const isFiltered = table.getState().columnFilters.length > 0;

    let groups = [
        {
            label: `Search key`,
            searchKeys: [],
        },
    ];

    data.forEach((d) => {
        const kiQ = d["ki_q"];
        if (groups[0].searchKeys.find((k) => k.label === kiQ)) {
            // already in
        } else {
            groups[0].searchKeys.push({
                label: kiQ,
                value:
                    kiQ.trim() && kiQ.trim().toLowerCase()
                        ? kiQ.trim().toLowerCase()
                        : kiQ,
            });
        }
    });

    return (
        <div className="flex items-center justify-between">
            <div className="flex flex-1 items-center space-x-2">
                <Input
                    placeholder={`Filter ${process.env.NEXT_PUBLIC_STRING_TERM_1400_PLURAL}...`}
                    value={
                        (table.getColumn("user")?.getFilterValue()) ?? ""
                    }
                    onChange={(event) =>
                        table.getColumn("user")?.setFilterValue(event.target.value)
                    }
                    className="h-8 w-[150px] lg:w-[250px]"
                />
                {table.getColumn("domain") && (
                    <DataTableFacetedFilter
                        darkMode={darkMode}
                        column={table.getColumn("domain")}
                        title="Domain"
                        options={
                            groups && groups[0] && groups[0].searchKeys
                                ? groups[0].searchKeys
                                : []
                        }
                    />
                )}
                {table.getColumn("status") && (
                    <DataTableFacetedFilter
                        darkMode={darkMode}
                        column={table.getColumn("status")}
                        title="Status"
                        options={[
                            {
                                value: "acknowledged",
                                label: "Acknowledged",
                                icon: Icons.acknowledged,
                            },
                            {
                                value: "new",
                                label: "To check",
                                icon: Icons.new,
                            },
                        ]}
                    />
                )}

                {false && table.getColumn("type") && (
                    <DataTableFacetedFilter
                        darkMode={darkMode}
                        column={table.getColumn("type")}
                        title="Type"
                        options={[
                            {
                                label: process.env.NEXT_PUBLIC_STRING_TERM_1400_SINGLE
                                    ? process.env.NEXT_PUBLIC_STRING_TERM_1400_SINGLE
                                    : "Company",
                                value: process.env.NEXT_PUBLIC_STRING_TERM_1400_SINGLE
                                    ? process.env.NEXT_PUBLIC_STRING_TERM_1400_SINGLE
                                    : "Company",
                                icon: undefined,
                            },
                            {
                                label: process.env.NEXT_PUBLIC_STRING_TERM_300_SINGLE
                                    ? process.env.NEXT_PUBLIC_STRING_TERM_300_SINGLE
                                    : "Third Party",
                                value: process.env.NEXT_PUBLIC_STRING_TERM_300_SINGLE
                                    ? process.env.NEXT_PUBLIC_STRING_TERM_300_SINGLE
                                    : "Third Party",
                                icon: undefined,
                            },
                        ]}
                    />
                )}
                {table.getColumn("threat_type") && (
                    <DataTableFacetedFilter
                        darkMode={darkMode}
                        column={table.getColumn("threat_type")}
                        title={"Category"}
                        options={[
                            {
                                label: process.env.NEXT_PUBLIC_STRING_TERM_1400_SINGLE,
                                value: process.env.NEXT_PUBLIC_STRING_TERM_1400_SINGLE.toLowerCase(),
                                icon: undefined,
                            },
                            {
                                label: process.env.NEXT_PUBLIC_STRING_TERM_1300_SINGLE,
                                value: process.env.NEXT_PUBLIC_STRING_TERM_1300_SINGLE.toLowerCase(),
                                icon: undefined,
                            },
                        ]}
                    />
                )}
                {isFiltered && (
                    <MDButton
                        style={cysrConfig().button_style} size="medium"
                        variant="ghost"
                        onClick={() => table.resetColumnFilters()}
                        className="h-8 px-2 lg:px-3"
                    >
                        Reset
                        <X className="ml-2 h-4 w-4" />
                    </MDButton>
                )}
            </div>
            <span className="ml-auto block text-sm text-muted-foreground">
                {table.getFilteredSelectedRowModel().rows.length} of{" "}
                {table.getFilteredRowModel().rows.length} row(s) selected
            </span>
            {
                // <DataTableViewOptions table={table} />
            }
        </div>
    );
}
