/* eslint-disable */
import { AddTaskSharp, ErrorOutlineSharp, InfoSharp, HighlightOffSharp, PauseCircleOutlineSharp, TaskAltSharp } from "@mui/icons-material";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import { toast } from "sonner"

export function assetAdditionalInfo(data) {
    /*
    Additional info
    (max 3 elementi, title + rows)
    + title
    + rows
    + details è una stringa se ci passi sopra (i)

{
  "title": "",
  "rows": [
    "CIDR: 1.2.3.0/24",
    "Organization: pippo SRL",
    "Country: IT"
  ],
  "detail": ""
}
*/
    // temp overwrite data s
    /*
    if (Math.random() < 0.33) {
        data = {
            "title": "some bold text",
            "rows": [
                "CIDR: 1.2.3.0/24",
                "Country: IT"
            ],
            "detail": "(temp overwrite data)"
        }
    } else if (Math.random() > 0.66) {
        data = {

            "rows": [
                "CIDR: 1.2.3.0/24",
                "Organization: pippo SRL",
                "Country: IT"
            ]
        }
    } else {
        data = {

            "rows": [
                "CIDR: 1.2.3.0/24",
                "Organization: pippo SRL",
                "Country: IT"
            ],
            "detail": "(temp overwrite data)"
        }
    }
    */
    // temp overwrite data e
    return <div className="flex gap-2">
        {data && data.rows
            ? (
                <>
                    {data.detail
                        ? <MDTypography variant="caption" color="text">
                            <InfoSharp onClick={
                                () => toast(<div className='toast-content flex flex-col gap-1 w-full'>
                                    <MDTypography variant="text" fontWeight="regular">
                                        <pre>
                                            {data.detail}
                                        </pre>
                                    </MDTypography>
                                </div>,
                                    {
                                        className: 'full-window-scrollable-toast',
                                        expand: true
                                    }
                                )
                            }
                                className='w-4 h-4 text-inherit cursor-pointer'
                            />
                        </MDTypography>
                        : <div className="w-4 h-4 inline-block"></div>
                    }
                    <div className="flex flex-col gap-0.5">
                        {data.title && data.title.length > 0 ?
                            <MDTypography fontWeight="bold" variant="caption">{data.title}</MDTypography>
                            : null
                        }
                        {data.rows?.map((r) =>
                            <MDTypography variant="caption">{r}</MDTypography>
                        )}
                    </div>
                </>
            )
            : null /*(<MDTypography variant="caption" color="text">
                no additional info
            </MDTypography>)*/
        }
    </div>
}
export function assetCustomTags(data) {
    /*
    Customn tags
    (come company)
*/
    // temp overwrite data s
    //data = ["1"]
    // temp overwrite data e
    return data.map((b) => <MDBadge badgeContent={b} size="xs" container />);
}
export function assetTags(data = [], withLabel = false) {
    /*
    Tags
    ["Fail", "Partial", "Hardfail]
    + fail=tentato check ma non riuscito, il check da interfaccia è quello precedente con successo, check è messo in coda automaticamente da sistema
    + partial(fail)=check fallito ma non del tutto, si mostra ma incompleto
    + hardfail=come fail ma non messo in coda
    */
    // temp overwrite data s
    // data = ["Ok", "Add", "Fail", "Partial", "Hardfail",];
    // data = ["SPF_ABSENT", "PARTIAL",];
    // temp overwrite data e
    function valuesToComponent(val) {
        let label = val;
        let text = null;
        let ico = null;
        const positiveColor = "#13A8DD";
        const negativeColor = "#F69220";
        let color = "currentColor";
        if (
            val.indexOf("COUNTRY=") > -1
            || val.indexOf("COUNTRY:") > -1
        ) {
            // flag for country
            return <span style={
                {
                    border: `1px solid ${color}`,
                    borderRadius: 20,
                    padding: "1px 1px",
                    height: 20,
                    width: 40,
                    backgroundSize: "cover",
                    backgroundPosition: "50% 50%",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(https://flagcdn.com/${val.replace(":", "=").split("=")[1].toLowerCase()}.svg)`
                }
            }>
                {
                    // <img height={10} width={20} src={`https://flagcdn.com/${val.replace(":", "=").split("=")[1].toLowerCase()}.svg`}                />
                }
            </span>
        }
        switch (val) {

            case "OK_CERTIFICATE":
                label = "CERTIFICATE";
                color = positiveColor;
                break;

            case "EXPIRED_CERTIFICATE":
                label = "CERTIFICATE";
                color = negativeColor;
                break;

            case "INVALID_CERTIFICATE":
                label = "CERTIFICATE";
                color = negativeColor;
                break;

            case "INVALID_CERTIFICATE":
                label = "CERTIFICATE";
                color = negativeColor;
                break;

            case "HTTP":
                label = "HTTP";
                color = negativeColor;
                break;

            case "DNSSEC_ENABLED":
                label = "DNSSEC";
                color = positiveColor;
                break;

            case "NSEC_VULN":
                label = "NSEC";
                color = negativeColor;
                break;

            case "FEW_IPS":
                label = "FEW_IPS";
                // color = negativeColor;
                break;

            case "SPF":
                label = "SPF";
                color = positiveColor;
                break;

            case "SPF_ABSENT":
                label = "SPF";
                color = negativeColor;
                break;

            case "SPF_INVALID":
                label = "SPF";
                color = negativeColor;
                break;

            case "DMARC":
                label = "DMARC";
                color = positiveColor;
                break;

            case "DMARC_ABSENT":
                label = "DMARC";
                color = negativeColor;
                break;

            case "DMARC_INVALID":
                label = "DMARC";
                color = negativeColor;
                break;

            case "RPKI_ABSENT":
                label = "RPKI";
                color = negativeColor;
                break;

            case "RPKI_OK":
                label = "RPKI";
                color = positiveColor;
                break;

            case "IRR_ABSENT":
                label = "IRR";
                color = negativeColor;
                break;

            case "IRR_OK":
                label = "IRR";
                color = positiveColor;
                break;

            case "MULTIPLE_AS":
                label = "MULTI_AS";
                color = negativeColor;
                break;

            case "DDOS/CDN":
                label = "DDOS/CDN";
                color = negativeColor;
                break;

            case "CDN":
                label = "CDN";
                color = positiveColor;
                break;

            case "DDOS":
                label = "DDOS";
                color = negativeColor;
                break;

            case "FAIL":
                label = "FAILED"
                color = negativeColor;
                text = "The past check has failed, and has been put in queue again."
                ico = <ErrorOutlineSharp className="!h-6 !w-6" onClick={
                    () => toast(lbl)
                } />
                break;

            case "HARDFAIL":
                label = "FAILED"
                color = negativeColor;
                text = "The past check has failed, and has not been put in queue."
                ico = <HighlightOffSharp className="!h-6 !w-6" onClick={
                    () => toast(lbl)
                } />
                break;

            case "PARTIAL":
                label = "PARTIALLY FAILED"
                color = negativeColor;
                text = "The past check has partially failed, and has been put in queue again."
                ico = <PauseCircleOutlineSharp className="!h-6 !w-6" onClick={
                    () => toast(lbl)
                } />
                break;

            default:
                label = label.replaceAll("(", "").replaceAll(")", "");
                break;
        }
        if (label.indexOf("EXPOSED_SVC=") > -1) {
            label = label.replace("EXPOSED_SVC=", "")
        }
        return <span onClick={text ?
            () => toast(text)
            : null} style={
                {
                    border: `1px solid ${color}`,
                    borderRadius: 20,
                    padding: "1px 8px",
                }
            }><MDTypography fontSize={10} color="text">
                {label}
            </MDTypography>
        </span>
    }
    return <div className="flex gap-2 flex-wrap">
        {data && data.length > 0 ? data.map((z) => {
            return valuesToComponent(z);
            /*
        {data && data.length > 0 ? data.map((z) => {
            let ico = <TaskAltSharp className="!h-6 !w-6" onClick={
                () => toast(z)
            } />;
            let lbl = "";
            switch (z.toUpperCase()) {
                case "FAIL":

                    lbl = "The past check has failed, and has been put in queue again."
                    ico = <ErrorOutlineSharp className="!h-6 !w-6" onClick={
                        () => toast(lbl)
                    } />
                    break;

                case "HARDFAIL":
                    lbl = "The past check has failed, and has not been put in queue."
                    ico = <HighlightOffSharp className="!h-6 !w-6" onClick={
                        () => toast(lbl)
                    } />
                    break;

                case "PARTIAL":
                    lbl = "The past check has partially failed, and has been put in queue again."
                    ico = <PauseCircleOutlineSharp className="!h-6 !w-6" onClick={
                        () => toast(lbl)
                    } />
                    break;

                case "ADD":
                    lbl = "The past check has been succesfully added."
                    ico = <AddTaskSharp className="!h-6 !w-6" onClick={
                        () => toast(lbl)
                    } />
                    break;

                default:
                    lbl = "The past check has succeded."
                    ico = <TaskAltSharp className="!h-6 !w-6" onClick={
                        () => toast(lbl)
                    } />
                    break;
            }
            return <>
                {ico}
                {withLabel ? <MDTypography variant="caption">{lbl}</MDTypography> : null}
            </>
            */
        }) : null /*(<MDTypography variant="caption" color="text">
            no tags
        </MDTypography>
        )*/
        }
    </div>
}