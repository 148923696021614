/* eslint-disable */
// prettier-ignore
import { HorizontalBars } from "layouts/cysr/components/bars-horizontal";
import { riskColors } from "../colors";
import React, { useState, useEffect } from "react";

import ScoreItem from "layouts/cysr/components/ScoreItem";
// import StatusItem from "layouts/cysr/components/StatusItem";
import { TimeshiftCalendarDatePicker } from "layouts/cysr/timeshift/timeshift-compare-dates-picker";
import TimeshiftCompareCalendarDatePicker from "layouts/cysr/timeshift/timeshift-compare-calendar-date-picker";
import { ImportantMessage } from "layouts/cysr/components/ImportantMessage";

import { Checkbox } from "layouts/cysr/components/ui/checkbox";
import { Badge } from "layouts/cysr/components/ui/badge";
import { useMaterialUIController } from "context";
import Card from "@mui/material/Card";
import {
    Card as CardIn,
    CardHeader,
    CardTitle,
    CardDescription,
    CardContent,
} from "layouts/cysr/components/ui/card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { cn } from "lib/utils"
import { Button } from "layouts/cysr/components/ui/button";

// Data
import InfoButton from "layouts/cysr/components/infoButton";
// Asset creation
import Grid from "@mui/material/Grid";

import cysrConfig, { process } from "layouts/cysr/config";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
import { DataTable } from "layouts/cysr/timeshift/data-table";

import { DataTableColumnHeader } from "layouts/cysr/timeshift/data-table-column-header";
import { DataTableRowExpand } from "layouts/cysr/timeshift/data-table-row-expand";

import { useToast } from "layouts/cysr/components/ui/use-toast";

import { Link } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { format, startOfToday } from "date-fns";

const tornadoAssetsToDisplayCount = 20;

const App = function () {
    const accountUUID = window.location.pathname.substring(
        window.location.pathname.indexOf("/company/") + "/company/".length,
        window.location.pathname.lastIndexOf("/")
    );
    const [showCalendar, setShowCalendar] = useState(false);

    const { toast } = useToast();

    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const [views, setViews] = useState([]);

    const [selectedDays, setSelectedDays] = useState([]);

    const columnHelper = createColumnHelper()
    const columns = [

        {
            accessorKey: "type",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Type" />
            ),
            cell: ({ row }) => {
                return row.original.type;
            },
            filterFn: (row, id, value) => {
                return value.includes(row.original.type);
            },
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: "name",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Name" />
            ),
            cell: ({ row }) => {
                return row.original.name;
            },
            filterFn: (row, id, value) => {
                return row.original.name.includes(value);
            },
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: "first_score",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="First score" />
            ),
            cell: ({ row }) => {
                return row.original.first_score;
            },
            filterFn: (row, id, value) => {
                const scoreV = row.original.first_score ? row.original.first_score.toLowerCase() : false;
                return value.includes(scoreV);
            },
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: "second_score",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Second score" />
            ),
            cell: ({ row }) => {
                return row.original.second_score;
            },
            filterFn: (row, id, value) => {
                const scoreV = row.original.second_score ? row.original.second_score.toLowerCase() : false;
                return value.includes(scoreV);
            },
            enableSorting: false,
            enableHiding: false,
        },
        columnHelper.group({
            id: 'date_1',
            header: () => <span>{selectedDays && selectedDays[0] ? format(new Date(selectedDays[0].toString()), process.env.NEXT_PUBLIC_DATE_FORMAT
                ? process.env.NEXT_PUBLIC_DATE_FORMAT
                : "dd/MM/yyyy") : "unselected"}</span>,
            columns: [
                /*
                columnHelper.accessor('Status', {
                    accessorKey: "asset_status_1",
                    header: ({ column }) => (
                        <DataTableColumnHeader column={column} title="Status" />
                    ),
                    cell: ({ row }) => <StatusItem statusValue={row.original.asset_status} />,
                    filterFn: (row, id, value) => {
                        const statusValue = row.original.asset_status;
                        return value.includes(statusValue);
                    },
                    enableSorting: true,
                    enableHiding: false,
                }),
                */
                columnHelper.accessor('Score', {
                    accessorKey: "first_rating",
                    header: ({ column }) => (
                        <DataTableColumnHeader column={column} title="Score" />
                    ),
                    cell: ({ row }) => <ScoreItem scoreValue={row.original && row.original.first_rating ? row.original.first_rating : "N/A"} textual={true} />,
                    enableSorting: true,
                    sortingFn: function (a, b) {
                        let aOFR = a.original.first_rating;
                        let bOFR = b.original.first_rating;
                        if (aOFR === null) {
                            aOFR = 101
                        }
                        if (bOFR === null) {
                            bOFR = 101
                        }
                        return aOFR - bOFR
                    },
                    enableHiding: false,
                }),
            ]
        }),
        // new
        columnHelper.group({
            id: 'date_2',
            header: () => <span>{selectedDays && selectedDays[1] ? format(new Date(selectedDays[1].toString()), process.env.NEXT_PUBLIC_DATE_FORMAT
                ? process.env.NEXT_PUBLIC_DATE_FORMAT
                : "dd/MM/yyyy") : "unselected"}</span>,
            columns: [
                /*
                columnHelper.accessor('Status', {
                    accessorKey: "asset_status_2",
                    header: ({ column }) => (
                        <DataTableColumnHeader column={column} title="Status" />
                    ),
                    cell: ({ row }) => <StatusItem statusValue={row.original.asset_status} />,
                    filterFn: (row, id, value) => {
                        const statusValue = row.original.asset_status;
                        return value.includes(statusValue);
                    },
                    enableSorting: true,
                    enableHiding: false,
                }),
                */
                columnHelper.accessor('Score ', {
                    id: 'second_rating',
                    accessorKey: "second_rating",
                    header: ({ column }) => (
                        <DataTableColumnHeader column={column} title="Score" />
                    ),
                    cell: ({ row }) => <ScoreItem scoreValue={row.original && row.original.second_rating ? row.original.second_rating : "N/A"} textual={true} />,
                    enableSorting: true,
                    sortingFn: function (a, b) {

                        let aOSR = a.original.second_rating;
                        let bOSR = b.original.second_rating;
                        if (aOSR === null) {
                            aOSR = 101
                        }
                        if (bOSR === null) {
                            bOSR = 101
                        }
                        return aOSR - bOSR
                    },
                    enableHiding: false,
                })]
        }),
        columnHelper.group({
            id: 'actions',
            // header: () => <span>Actions</span>,
            columns: [
                columnHelper.accessor(
                    'Actions',
                    {
                        id: 'actions',
                        cell: ({ row }) => (
                            <div style={{
                                display: 'flex', justifyContent: 'flex-end', gap: '8px', marginLeft: "auto", width: row.original.asset_uuid ? "216px" : "184px"
                            }}>
                                {row.original.asset_uuid
                                    ? <Link to={`/company/${accountUUID}/${"asset"}#${row.original.asset_uuid}`}>
                                        <MDButton style={cysrConfig().button_style} size="medium">
                                            Go to asset
                                            <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
                                        </MDButton>
                                    </Link>
                                    : null /*
                                <MDButton style={cysrConfig().button_style} size="medium" disabled>
                                    <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
                                    No link
                                </MDButton>
                                */
                                }
                                <div className="flex gap-2 justify-end">
                                    <DataTableRowExpand row={row} />
                                </div>
                            </div>
                        ),
                        pinRight: true
                    })]
        }),
    ];
    // const accountUUID = "8c75a44b-0faa-4e83-9bef-4ee47d21883a";
    // const accountUUID = window.location.pathname.substring(window.location.pathname.indexOf(":") + 1);
    // all assets:
    useEffect(() => {
        // setReportsTimeshiftData s
        const getReportsTimeshiftCallUrl = `${cysrConfig().api_base_url}timeshift/${accountUUID}/dates/`;
        fetch(getReportsTimeshiftCallUrl, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
            },
        })
            .then((responseReportsTimeshift) => responseReportsTimeshift.json())
            .then((callResponseReportsTimeshift) => {
                // archived e
                if (callResponseReportsTimeshift.code && callResponseReportsTimeshift.code === "token_not_valid") {
                    // remove localstorage and go to login
                    const dMode = localStorage.getItem('cysr_dark_mode') ? localStorage.getItem('cysr_dark_mode') : "true";
                    localStorage.clear();
                    localStorage.setItem('cysr_dark_mode', dMode);
                    window.location.href = `/cysr/login`;
                    return
                }

                if (selectedDays && selectedDays[0] && selectedDays && selectedDays[1]) {
                    const callUrl = `${cysrConfig().api_base_url}timeshift/${accountUUID}/diff/${selectedDays && selectedDays[0] ? format(new Date(selectedDays[0].toString()), "yyyy-MM-dd") : ""}/${selectedDays && selectedDays[0] ? format(new Date(selectedDays[1].toString()), "yyyy-MM-dd") : ""}/`;
                    fetch(callUrl, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                        },
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.ErrorDetail) {
                                toast({
                                    title: data.ErrorMessage,
                                    description: data.ErrorDetail,
                                    variant: "destructive",
                                });
                            } else {
                                setViews({
                                    assets: data,
                                    tornadoAssets: data.sort((a, b) => b.order - a.order).slice(0, tornadoAssetsToDisplayCount),
                                    reportsTimeshiftData: callResponseReportsTimeshift
                                });
                            }
                        })
                        .catch((aerr) => {
                            console.error(aerr)
                        });
                } else {
                    setViews({
                        reportsTimeshiftData: callResponseReportsTimeshift
                    });
                }
            })
        // setReportsTimeshiftData e
    }, [location, selectedDays]);
    return (
        <DashboardLayout>
            <DashboardNavbar cysr_company />
            <div className={`${darkMode ? "dark " : ""}w-full`}>
                <div className="flex flex-col w-full gap-4 mb-4">
                    <CardIn className="w-full rounded-md" id="select_dates">
                        <CardHeader>
                            <CardTitle>Timeshift dates</CardTitle>
                            <CardDescription>
                                Select two dates to compare.
                            </CardDescription>
                        </CardHeader>
                        <CardContent className="text-black dark:text-white">
                            <MDBox pb={2} px={1} fullWidth>
                                {views && views.reportsTimeshiftData
                                    ? (
                                        (views.reportsTimeshiftData && views.reportsTimeshiftData.length < 2 && (
                                            views.reportsTimeshiftData.length === 0 || views.reportsTimeshiftData[0].report_date === format(startOfToday(), "yyyy-MM-dd")
                                        )
                                        )

                                            ?
                                            <ImportantMessage
                                                // button={"Select two dates"}
                                                className={`${darkMode ? "text-white" : "text-black"} text-left w-full border pl-11 pr-4 py-3 rounded border-red-500/50 bg-red-500/25`}
                                                title={"No dates selectable"}
                                                description="This company does not have any older check for comparison"
                                            />
                                            : (
                                                <div className="p-2 rounded-md" style={
                                                    {
                                                        background: darkMode ? "#121619" : "#f0f2f5"
                                                    }
                                                }>
                                                    <div className="flex justify-between gap-2 items-center">
                                                        <div className="flex gap-1 items-center">
                                                            <Button
                                                                variant={"outline"}
                                                                size="sm"
                                                                onClick={() => setShowCalendar(!showCalendar)}
                                                                className={cn(
                                                                    "text-black dark:text-white w-[240px] flex items-center justify-start text-left font-normal",
                                                                    `${selectedDays && selectedDays.length > 1 ? "bg-white dark:bg-[rgb(34,39,42)]"
                                                                        : null
                                                                    }`,
                                                                    !(selectedDays && selectedDays.length > 1 ? true : false)
                                                                    && "text-muted-foreground"
                                                                )}
                                                            >
                                                                {showCalendar ? <svg
                                                                    className="mr-2 h-4 w-4"
                                                                    width="16"
                                                                    height="16"
                                                                    fill="currentColor"
                                                                    focusable="false"
                                                                    aria-hidden="true"
                                                                    viewBox="0 0 24 24"
                                                                    data-testid="CalendarMonthSharpIcon"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM9 14H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm-8 4H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z"></path>
                                                                </svg>
                                                                    : <svg
                                                                        className="mr-2 h-4 w-4"
                                                                        width="16"
                                                                        height="16"
                                                                        fill="currentColor"
                                                                        focusable="false"
                                                                        aria-hidden="true"
                                                                        viewBox="0 0 24 24" data-testid="EditCalendarSharpIcon"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12 22H3V4h3V2h2v2h8V2h2v2h3v8h-2v-2H5v10h7v2zm10.13-5.01 1.41-1.41-2.12-2.12-1.41 1.41 2.12 2.12zm-.71.71-5.3 5.3H14v-2.12l5.3-5.3 2.12 2.12z"></path>
                                                                    </svg>
                                                                }
                                                                {selectedDays && selectedDays.length > 1 ? <span>{`${format(new Date(selectedDays[0]), process.env.NEXT_PUBLIC_DATE_FORMAT)} - ${format(new Date(selectedDays[1]), process.env.NEXT_PUBLIC_DATE_FORMAT)}`}</span> : <span>Pick a date</span>}
                                                            </Button>
                                                            {
                                                                /*
                                                                selectedDays && selectedDays.length > 1
                                                                    ? <Button onClick={() => {
                                                                        setSelectedDays([])
                                                                        toggleSnackbar(`${process.env.NEXT_PUBLIC_STRING_CONTENT_TIMESHIFT_TODAY_TITLE}`, `${process.env.NEXT_PUBLIC_STRING_CONTENT_TIMESHIFT_TODAY}`, "info", "info", "Info")
                                                                    }} size="sm" variant="ghost" 
                                                                    // className="text-black dark:text-white flex items-center justify-start text-left font-normal"
                                                                    >
                                                                        <svg
                                                                            className="mr-2 h-4 w-4"
                                                                            width="16"
                                                                            height="16"
                                                                            fill="currentColor"
                                                                            focusable="false"
                                                                            aria-hidden="true"
                                                                            viewBox="0 0 24 24" data-testid="TodaySharpIcon"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M21 3h-3V1h-2v2H8V1H6v2H3v18h18V3zm-2 16H5V8h14v11zM7 10h5v5H7v-5z"></path>
                                                                        </svg>Use today</Button> : null
                                                                */
                                                            }
                                                        </div>
                                                        <MDButton style={{
                                                            //borderRadius: ".375rem",
                                                            maxHeight: "32px",
                                                            minHeight: "32px",
                                                            // minWidth: "32px",
                                                            padding: 0,
                                                            // flex: 1
                                                        }} size="medium"

                                                            onClick={() => setShowCalendar(!showCalendar)}
                                                            className="muscope-cta"
                                                        >
                                                            <span className="sr-only">Toggle calendar</span>
                                                            <svg fill="currentColor" className="inline h-4 w-4" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ExpandMoreSharpIcon">
                                                                {showCalendar
                                                                    ? <path d="m12 8-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z"></path>
                                                                    : <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                                                                }
                                                            </svg>
                                                        </MDButton>
                                                    </div>
                                                    <div className={showCalendar ? "" : "hidden"}>
                                                        <TimeshiftCompareCalendarDatePicker
                                                            selectedDays={selectedDays && selectedDays.length > 0 ? selectedDays : null}
                                                            setSelectedDays={setSelectedDays}
                                                            timeshiftData={views.reportsTimeshiftData}
                                                        />
                                                    </div>
                                                </div>)
                                    )
                                    : <span>Loading calendar</span>
                                }
                            </MDBox>
                        </CardContent>
                    </CardIn>
                    {views.assets ?
                        <CardIn className="w-full rounded-md">
                            <CardHeader>
                                <CardTitle>Assets Timeshift</CardTitle>
                                <CardDescription>
                                    {tornadoAssetsToDisplayCount} worst performing assets between two dates.
                                </CardDescription>
                            </CardHeader>
                            <CardContent>
                                {
                                    selectedDays && selectedDays[1]
                                        ?
                                        <MDBox pb={2} px={1} fullWidth>
                                            {
                                                // assets tornado s
                                            }
                                            {views.assets ? <HorizontalBars
                                                selectedDays={selectedDays}
                                                labels={(views.tornadoAssets)
                                                    .map((x) => x.complete_name ? x.complete_name : `${x.type}|${x.name}`)}
                                                datasets={[

                                                    {
                                                        label: selectedDays && selectedDays[0] ? format(new Date(selectedDays[0].toString()), process.env.NEXT_PUBLIC_DATE_FORMAT
                                                            ? process.env.NEXT_PUBLIC_DATE_FORMAT
                                                            : "dd/MM/yyyy") : "unselected",
                                                        data: (views.tornadoAssets)
                                                            .map(
                                                                (x) => ((x.first_rating ? 100 : 0) - (x.first_rating ? x.first_rating : 0)) * -1
                                                            ),
                                                        backgroundColor: (views.tornadoAssets)
                                                            .map((x) => riskColors(x.first_rating).color),
                                                        borderColor: (views.tornadoAssets)
                                                            .map((x) => riskColors(x.first_rating).color),
                                                    },
                                                    {
                                                        label: selectedDays && selectedDays[1] ? format(new Date(selectedDays[1].toString()), process.env.NEXT_PUBLIC_DATE_FORMAT
                                                            ? process.env.NEXT_PUBLIC_DATE_FORMAT
                                                            : "dd/MM/yyyy") : "unselected",
                                                        data: (views.tornadoAssets)
                                                            .map(
                                                                (x) => ((x.second_rating ? 100 : 0) - (x.second_rating ? x.second_rating : 0))
                                                            ),
                                                        backgroundColor: (views.tornadoAssets)
                                                            .map((x) => riskColors(x.second_rating).color),
                                                        borderColor: (views.tornadoAssets)
                                                            .map((x) => riskColors(x.second_rating).color),
                                                    },
                                                    /*
                                                    {
                                                      label: "Dataset 2",
                                                      data: (forceDataNow).map(
                                                        (x) => (x.value ? 100 : 0) - x.value
                                                      ),
                                                      // borderColor: "rgb(53, 162, 235)",
                                                      backgroundColor: (forceDataNow).map(
                                                        (x) => x.hex
                                                      ),
                                                      borderColor: (forceDataNow).map((x) => x.hex),
                                                    },
                                                    */
                                                ]}
                                                darkMode={darkMode}
                                            /> : null}
                                            {
                                                // assets tornado e
                                            }
                                        </MDBox>
                                        : <a href="#select_dates" className="w-full" style={
                                            {
                                                color: "inherit"
                                            }
                                        }>
                                            <ImportantMessage
                                                button={"Select two dates"}
                                                className={`text-left w-full border pl-11 pr-4 py-3 rounded border-red-500/50 bg-red-500/25`}
                                                title={"No dates selected"}
                                                description="Select two dates to compare"
                                            />
                                        </a>
                                }
                            </CardContent>
                        </CardIn>
                        : null
                    }
                </div> {views.assets ?

                    <div className="flex flex-col w-full gap-4 mb-4">
                        <CardIn className="w-full rounded-md">
                            <CardHeader>
                                <CardTitle>Assets comparison</CardTitle>
                                <CardDescription>
                                    Compare assets between two dates
                                </CardDescription>
                            </CardHeader>

                            <CardContent className="flex gap-2 w-full">
                                {
                                    selectedDays && selectedDays[1]
                                        ? <div className={`${darkMode ? "dark" : "light"} cti-table text-turquoise-950 dark:text-turquoise-50 h-full flex-1 flex-col space-y-8 flex`}>
                                            <DataTable data={views.assets} columns={columns} darkMode={darkMode} selectedDays={selectedDays} />
                                        </div>
                                        : <a href="#select_dates" className="w-full" style={
                                            {
                                                color: "inherit"
                                            }
                                        }>
                                            <ImportantMessage
                                                button={"Select two dates"}
                                                className={`text-left w-full border pl-11 pr-4 py-3 rounded border-red-500/50 bg-red-500/25`}
                                                title={"No dates selected"}
                                                description="Select two dates to compare"
                                            />
                                        </a>
                                }</CardContent>

                        </CardIn>
                    </div>
                    : null
                }
            </div>
        </DashboardLayout >
    )
}
export default App;