/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
// prettier-ignore
import { Base64 } from 'js-base64';
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
import InfoButton from "layouts/cysr/components/infoButton";
// import Footer from "examples/Footer";
import Loader from "layouts/cysr/components/Loader";
// import KeyboardArrowDownIcon from '@mui/icons-material/Add';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Card from "@mui/material/Card";
import CysrTableThirdParties from "layouts/cysr/components/CysrTable/thirdParties";

import cysrConfig from "layouts/cysr/config";
import ThirdPartiesTop from "./ThirdPartiesTop";

function Sales() {
  if (localStorage.getItem("cysr_useraccountrole_selected")) {
    localStorage.removeItem("cysr_useraccountrole_selected")
    window.location.reload()
  }
  // filter test s
  const params = new URLSearchParams(window.location.search);
  let activeTags = [];
  try {
    activeTags = params.get('t') && (decodeURI(params.get('t'))) && Base64.decode(decodeURI(params.get('t'))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('t')))) : [];
  } catch (error) {
    console.error("Error in setting active tags:")
    console.error(error)
  }
  // filter test e

  // Our State.
  const [isLoading, setLoading] = useState(true);
  const [views, setViews] = useState([]);
  const accountsRoleCallUrl = `${cysrConfig().api_base_url}user/mythirdparties/`;
  useEffect(() => {
    setLoading(true);
    const pageTitle = "Dashboard";
    document.title = `${pageTitle} - ${document.title.split(" - ")[1] ? document.title.split(" - ")[1] : document.title}`;
    if (localStorage.getItem("cysr_useraccountroles")) {
      // call table of asset + call table of checks
      fetch(accountsRoleCallUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
      })
        .then((response) => response.json())
        .then((responseAccountsRoleData) => {
          // console.log(responseAccountsRoleData);
          if (responseAccountsRoleData.code && responseAccountsRoleData.code === "token_not_valid") {
            // remove localstorage and go to login
            const dMode = localStorage.getItem('cysr_dark_mode') ? localStorage.getItem('cysr_dark_mode') : "true";
            localStorage.clear();
            localStorage.setItem('cysr_dark_mode', dMode);
            window.location.href = `/cysr/login`;
            return
          }
          const userAccounts = responseAccountsRoleData;
          userAccounts.sort((a, b) =>
            a.account_name
              .toLowerCase()
              .localeCompare(b.account_name.toLowerCase())
          );
          /*
          // provare a non chiamare mycompanies in thirdparties p1/2
                    // set values of select linked companies
                    const companiesRoleCallUrl = `${cysrConfig().api_base_url}user/mycompanies/`;
                    fetch(companiesRoleCallUrl, {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                      },
                    })
                      .then((response) => response.json())
                      .then((responseCompaniesRoleData) => {
                        // console.log(responseAccountsRoleData);
                        if (responseCompaniesRoleData.code && responseCompaniesRoleData.code === "token_not_valid") {
                          // remove localstorage and go to login
                          const dMode = localStorage.getItem('cysr_dark_mode') ? localStorage.getItem('cysr_dark_mode') : "true";
                          localStorage.clear();
                          localStorage.setItem('cysr_dark_mode', dMode);
                          window.location.href = `/cysr/login`;
                          return
                        }
                        const userCompanies = responseCompaniesRoleData;
                        userCompanies.sort((a, b) =>
                          a.account_name
                            .toLowerCase()
                            .localeCompare(b.account_name.toLowerCase())
                        )
                        let lCs = [];
                        userCompanies.forEach(lC => {
                          // lCs.push(lC.account_name);
                          let acName = lC.account_name;
                          let acVatName = lC.account_vat_name;
                          if (acName && acName.length > 24) {
                            acName = (acName.substr(0, 21) + "\u2026");
                          }
                          if (acVatName && acVatName.length > 24) {
                            acVatName = (acVatName.substr(0, 21) + "\u2026");
                          }
                          const acVat = (lC.account_vat && lC.account_vat !== "N/A") ? (" " + lC.account_vat) : "";
                          lCs.push({
                            country: lC.account_vat_country_code,
                            label: `${acName} ${acVatName && `(${acVatName})`} - ${lC.account_vat_country_code === "XX" ? "other" : lC.account_vat_country_code}${acVat}`,
                            value: lC.account_uuid,
                            id: lC.account_uuid
                          })
                        });
                        setLinkableCompanies(lCs)
          */
          setViews({
            accounts_role: userAccounts,
            cysr_tags_active: activeTags
          });
          setLoading(false);
        })
        /*
        // provare a non chiamare mycompanies in thirdparties p2/2
                    .catch((err) => {
                      console.error(err)
                      setLoading(false);
                    })
                  })
        */
        .catch((err) => {
          console.error(err)
          setLoading(false);
        });
    } else {
      setViews({});
      setLoading(false);
    }
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <InfoButton cysr_info={cysrConfig().i("dashboard", "add_a_third_party", "help")} cysr_info_link={cysrConfig().i("dashboard", "add_a_third_party", "help_link")} />
            <ThirdPartiesTop />
            <div style={{ position: "relative" }}>
              {false && <InfoButton cysr_info={cysrConfig().i("dashboard", "companies_list", "help")} cysr_info_link={cysrConfig().i("dashboard", "companies_list", "help_link")} />}
              {false && <MDBox pb={5} px={3} fullWidth>
                <MDBox mb={1} fullWidth>
                  <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <div>
                      {cysrConfig().i("dashboard", "companies_list", "title") && (
                        <MDTypography variant="h5">{cysrConfig().i("dashboard", "companies_list", "title")}</MDTypography>
                      )}
                      {cysrConfig().i("dashboard", "companies_list", "sub_title") && (
                        <MDTypography variant="button" color="text" fontWeight="regular">
                          {cysrConfig().i("dashboard", "companies_list", "sub_title")}
                        </MDTypography>
                      )}
                    </div>
                  </MDBox>
                </MDBox>
              </MDBox>}
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <MDBox pb={5} pt={0} px={3} fullWidth>
                    <CysrTableThirdParties data={
                      {
                        search: params.get('s') ? params.get('s') : "",
                        hideCompleted: params.get('hc') ? true : false,
                        tableTagsOptions: [],
                        nodes: views.accounts_role,
                        tableTagsActive: views.cysr_tags_active,
                      }
                    }
                    />
                  </MDBox>
                  {
                    // <DataTable  entriesPerPage={{ defaultValue: 50, entries: [25, 50, 100, 200, 400] }} table={dashboardData(views)} canSearch />
                  }
                </>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>

      {
        /*
      }
        <MDBox py={3} fullWidth>
          <MDBox mb={3} fullWidth>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MDBox pb={3} lineHeight={1} fullWidth>
                  <MDBox mb={1} fullWidth>
                    <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                      <MDTypography variant="h5">Companies list</MDTypography>
                      <MDButton
                        aria-label="expand row"
                        color="dark"
                        size="medium"
                        onClick={() => setOpen(!createOpen)}
                        >
                        Add a company&nbsp;{createOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </MDButton>
                    </MDBox>
                    <Collapse in={createOpen} style={{width:"100%"}} timeout="auto" unmountOnExit>        
                      <form onSubmit={createAccount} py={3} style={{ display:"block", padding: ".5em 0"}}>
                        <Grid container spacing={1}>
                          <Grid item xs={3} lg={2}>
                            <Autocomplete
                              defaultValue="IT"
                              // label="generalasset_type"
                              id="c_account_vat_code"
                              options={countriesCodes}
                              size="small"
                              renderInput={(params) => <MDInput style={{height:"100%"}} label="Company VAT Code" {...params} />}
                              style={{height:"100%", display:"block"}}
                            />
                          </Grid>
                          <Grid item xs={9} lg={4}>
                            <MDInput
                              size="small"
                              label="Company VAT"
                              id="c_account_data_national_id"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <MDInput
                              fullWidth
                              size="small"
                              label="Company name"
                              id="c_account_name"
                            />
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <MDButton fullWidth size="small" color="dark" type="submit" style={{height:"100%"}}>
                              Create Company
                            </MDButton>
                          </Grid>
                        </Grid>
                      </form>
                      <MDSnackbar
                        color="error"
                        icon="error"
                        title="Company not created"
                        content="Check that all the fields are compiled correctly and try again"
                        dateTime="Error"
                        open={show}
                        close={toggleSnackbar}
                      />
                    </Collapse>
                    
                  </MDBox>
                  <MDTypography variant="button" color="text" fontWeight="regular">
                    The list of companies this account has access to.
                  </MDTypography>
                </MDBox>
                {views && views.accounts_role && views.accounts_role.length > 0 ? (
                  <MDBox
                    // display="flex"
                    // justifyContent="space-between"
                    // alignItems="center"
                    width={{ xs: "max-content", sm: "100%" }}
                  >
                    {views.accounts_role.map(
                      (role) =>
                        role ? (
                          <div key={role.account_uuid}>
                            <Grid container spacing={3}>
                              {role.account && role.rating && role.rating[0] && role.rating[0].rating_val ? (
                              <Grid item xs={3} md={2}>
                                <img
                                  alt="Rating"
                                  src={`${cysrConfig().image_rating_base_url}${role.rating[0].rating_val}.svg`}
                                  width="100%"
                                />
                              </Grid>
                              ) : (
                                <Grid item xs={3} md={2}>
                                  <p>No rating</p>
                                </Grid>
                              )}
                              <Grid item xs={9} md={10}>
                                <DashboardCompanyInfo useraccountrole={role} />
                              </Grid>
                            </Grid>
                            <Divider />
                          </div>
                        ) : <p>No role</p>
                    )}
                  </MDBox>
                ) : (
                  <MDTypography variant="caption" color="text">
                    This account does not have any company
                  </MDTypography>
                )}
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        {
          */
      }

    </DashboardLayout>
  );
}

export default Sales;

/* eslint-disable */
// prettier-ignore
