/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Box from '@mui/material/Box';
// import TableHead from '@mui/material/TableHead';
// import IconButton from '@mui/material/IconButton';
import Collapse from "@mui/material/Collapse";
import TableCell from '@mui/material/TableCell';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
// import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "layouts/cysr/components/DataTable/DataTableHeadCell";
import DataTableBodyCell from "layouts/cysr/components/DataTable/DataTableBodyCell";

import RenderMarkdown from "layouts/cysr/components/RenderMarkdown"
import cysrConfig from "layouts/cysr/config"
import './App.css';
/* eslint-disable */
function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  let isExpanded = false;
  let isExpandedInfoOnRight = false;
  if (page[0] && page[0].original.expanded_info) {
    isExpanded = true;
  }
  if (isExpanded && page[0] && page[0].original.expanded_info_right && page[0].original.expanded_info_right === true) {
    isExpandedInfoOnRight = true;
  }
  function Row(props) {
    const { row, rows } = props;
    const [open, setOpen] = useState(false);

    prepareRow(row);
    let rowData = {};
    if (row.cells && row.cells[0] && row.cells[0].row.original) {
      rowData = row.cells[0].row.original;
    }
    return (
      <>
        <TableRow {...row.getRowProps()}>
          {isExpanded && (
            isExpandedInfoOnRight && isExpandedInfoOnRight === true ? null :
              <TableCell>
                <MDButton
                  style={cysrConfig().button_style}
                  size="medium"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} /> : <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />}
                </MDButton>
              </TableCell>
          )
          }
          {row.cells.map((cell) => (
            <DataTableBodyCell
              align={cell.column.align ? cell.column.align : "left"}
              {...cell.getCellProps()}
            >
              {cell.render("Cell")}
            </DataTableBodyCell>
          ))}
          {isExpandedInfoOnRight && isExpandedInfoOnRight === true &&
            <TableCell style={{ paddingLeft: 0 }}>
              <MDButton
                style={cysrConfig().button_style}
                size="medium"
                onClick={() => setOpen(!open)}
              >
                {open ? <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} /> : <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />}
              </MDButton>
            </TableCell>
          }
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0, border: 0 }} colSpan={columns.length + 1}>
            <Collapse in={open} style={{ background: localStorage.getItem("cysr_dark_mode") && localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#192328", width: "100%" }} timeout="auto" unmountOnExit>
              <Box>
                <Table {...getTableProps()}>
                  <TableBody>
                    <TableRow key={rowData.asset_uuid}>
                      <TableCell>
                        {
                          /*
                        }
                        <MDTypography variant="h4" fontWeight="bold">
                          {rowData.expanded_info.title}
                        </MDTypography>
                        <MDBadge variant="contained" color={rowData.expanded_info.badge.color} size="xs" badgeContent={rowData.expanded_info.badge.badgeContent} container />
                        <MDBox mt={1} mb={1} ml={0.5}>
                          <MDTypography variant="button" fontWeight="regular" color="text">
                            {rowData.expanded_info.subtitle}
                          </MDTypography>
                        </MDBox>
                        {
                          */
                        }
                        {rowData.expanded_info && rowData.expanded_info.react_code && (
                          <>
                            {rowData.expanded_info.react_code}
                          </>
                        )}
                        {rowData.expanded_info && rowData.expanded_info.detailed_description && (
                          <MDBox mt={1} mb={1} ml={0.5}>
                            <div className="content">
                              <RenderMarkdown>{rowData.expanded_info.detailed_description}</RenderMarkdown>
                            </div>
                          </MDBox>
                        )}
                        {rowData.expanded_info && rowData.expanded_info.detailed_header && (
                          <div dangerouslySetInnerHTML={{ __html: rowData.expanded_info.detailed_header }}>
                          </div>
                        )}
                        {
                          /*
                        }
                        <MDBox component="ul" m={0} pl={4} mb={2}>
                          {rowData.expanded_info.list && rowData.expanded_info.list.map((listItem) => (
                            <MDBox key={listItem.key} component="li" color="text" fontSize="1.25rem" mb={1} lineHeight={1}>
                              <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
                                {listItem.text}
                              </MDTypography>
                            </MDBox>
                          ))}
                        </MDBox>
                        {
                          */
                        }
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {entriesPerPage || canSearch ? (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" px={3}>
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {isExpanded && (
                isExpandedInfoOnRight && isExpandedInfoOnRight === true ? null : <TableCell />
              )
              }
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
              {isExpandedInfoOnRight && isExpandedInfoOnRight === true && <TableCell
                width="64px" />}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row) => {
            return (
              <Row key={row.id} row={row} rows={rows} />
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;
/* eslint-disable */
