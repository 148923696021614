/* eslint-disable */
import React, { useState } from 'react';
import MDTypography from "components/MDTypography";
import AuthCode from 'react-auth-code-input';
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import cysrConfig from "layouts/cysr/config";

function LoginWithAuth({toggleSnackbar, show2FACode, manageLogin, ephemeral_token}) {
    const [authInputCode, setAuthInputCode] = useState("123");
    const [showBackupCodeDialog, setShowBackupCodeDialog] = useState(false);
    // handleOnChange (diverso da quello in Authentication)
    const handleOnChange = (res) => {      
      console.log(res)
      console.log(res.length)
      console.log('ephemeral_token')
      console.log(ephemeral_token)
      // automatically do login after input all the 6 inputs
      if(res.length === 6){
        if(ephemeral_token){
            setAuthInputCode(res);
            authLoginCode(res)
        } else {
            toggleSnackbar("App Error", "No ephemeral token passed, please contact the support for assistance", "error", "error", "");
        }
      }
    };
    const authLoginCode = (authCode = false) => {
        if(authCode === false){
            authCode = authInputCode;
        }
        const authLoginCallUrl = `${cysrConfig().api_base_url}auth/login/code/`;
        const dataLogin = {
            ephemeral_token: ephemeral_token,
            code: authCode,
        };
        console.warn('dataLogin')
        console.warn(dataLogin)
        fetch(authLoginCallUrl, {
            body: JSON.stringify(dataLogin),
            headers: {
            "content-type": "application/json",
            },
            method: "POST",
        })
        .then((response) => {
            if (response.ok) {
                // window.location.reload();
                return response.json();
            } else {
                console.error(response);
                return response.json().then(err => {
                throw JSON.stringify(err)
                })
                return response.json()
            }
        })
        .then((loginData) => {
            manageLogin(loginData, show2FACode)
        })
        .catch((err) => {
            console.log(err);
            console.log("err3:", err);
            if(JSON.parse(err) && JSON.parse(err).ErrorMessage){
                toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
            } else if(JSON.parse(err) && JSON.parse(err).error){
                toggleSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
            } else {
                toggleSnackbar("Error", "Some error happened", "error", "error", "Error");
            }
        })
    };
    return (
        <div className='cysr-auth-container'>
            <MDTypography variant="h4" fontWeight="medium" mb={1}>
                Two-Factor Authentication
            </MDTypography>
            <div className='cysr-auth-code'>
                <MDTypography variant="caption" mb={1}>
                    Enter the code to continue
                </MDTypography>
                <AuthCode onChange={handleOnChange} style={{display:"flex"}} />
            </div>
            <div style={{display:"flex", gap:"12px"}}>
                <MDButton onClick={()=>show2FACode(false)} style={cysrConfig().button_style} size="medium">
                    <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{__html: cysrConfig().exit.icon.svg }} />
                    Back
                </MDButton>
                <MDButton onClick={()=>authLoginCode()} style={cysrConfig().button_style} size="medium">
                    <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{__html: cysrConfig().login.icon.svg }} />
                    Access
                </MDButton>
            </div>
            <MDTypography variant="caption" mb={1}>
                Problem with auth app? <MDButton variant="text" onClick={()=>setShowBackupCodeDialog(true)}>Use backup code</MDButton>
            </MDTypography>
            {showBackupCodeDialog && 
                <form style={{display:"flex", flexDirection:"column", flexDirection:"column", gap:"12px"}}>
                    <MDInput type="password" label="Backup code" id="cysr_backup_code" fullWidth />
                    <MDButton onClick={()=>{
                        authLoginCode(document.getElementById("cysr_backup_code").value)
                    }} style={cysrConfig().button_style} size="medium">
                        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{__html: cysrConfig().login.icon.svg }} />
                        Log in using Backup
                    </MDButton>
                </form>
            }
        </div>
    )
}
export default LoginWithAuth;
/* eslint-disable */