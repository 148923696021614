/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Material Dashboard 2 PRO React components
/* eslint-disable */

import MDTypography from "components/MDTypography";
import cysrConfig from "layouts/cysr/config";
export default function ScoreBadgeTextual(data) {
    const textValue = data.data.value;
    const scoreColor = data.data.color;
    const badgeStyle = data.data.badge_style;
    const circle = data.data.circle;
    const emoji_selection = textValue.toLowerCase();
    return (
        <>
            {badgeStyle === "boxless" ? (
                <h3
                    className="text-right"
                    style={{
                        fontSize: "inherit",
                        color: scoreColor,
                    }}
                >
                    {textValue}
                </h3>
            ) : badgeStyle === "mini" ? (
                <div className="cysr-ratings-by-type-textual-item-badge-container" style={{
                    display: "inline-flex",
                    alignItems: "center",
                    // justifyContent: "center",
                    // line Height : vertical padding
                    lineHeight: "4.8px",
                    minWidth: "unset",
                    margin: "0 4.8px"
                }}
                >
                    <div className="cysr-ratings-by-type-textual-item-badge" style={{
                        padding: "4.8px 5.5px",
                        // borderRadius: "3px",
                        display: "flex",
                        alignItems: "center",
                        height: "14.4px",
                        width: "auto",
                        margin: 0,
                        backgroundColor: scoreColor,
                        // color: textValue === "Fair" ? "#000000" : "#FFFFFF"
                        // always white now
                        color: "#FFFFFF"
                    }}>
                        {
                            // <span style={{height: "14.4px", width: "14.4px", marginRight: "2.4px"}} dangerouslySetInnerHTML={{__html: cysrConfig().emoji[emoji_selection].icon.svg }} />
                        }
                        <h3 className="cysr-ratings-by-type-textual-item-value" style={{
                            textAlign: "right",
                            fontSize: "12px",
                            fontWeight: "bold",
                            height: "100%",
                            width: "auto",
                            margin: 0
                        }}>{textValue}</h3>
                    </div>
                </div>
            ) : (
                badgeStyle === "big" ? (
                    circle ?
                        <div className="cysr-ratings-by-type-textual-item-badge-container" style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // line Height : vertical padding
                            lineHeight: "12px",
                            minWidth: "204px",
                            margin: 0
                        }}
                        >
                            <div className="flex items-center gap-4">
                                <div className="cysr-ratings-by-type-textual-item-badge rounded-full" style={{
                                    backgroundColor: scoreColor,
                                    height: "48px",
                                    width: "48px",
                                }}></div>
                                <MDTypography fontWeight="medium" color="text" style={{
                                    textAlign: "right",
                                    fontSize: "30px",
                                    fontWeight: "medium",
                                    height: "100%",
                                    width: "auto",
                                    margin: 0
                                }}>
                                    {textValue}
                                </MDTypography>
                            </div>
                        </div>
                        :
                        <div className="cysr-ratings-by-type-textual-item-badge-container" style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // line Height : vertical padding
                            lineHeight: "12px",
                            minWidth: "204px",
                            margin: 0
                        }}
                        >
                            <div className="cysr-ratings-by-type-textual-item-badge" style={{
                                padding: "12px 16px",
                                // borderRadius: "7.5px",
                                height: "36px",
                                display: "flex",
                                alignItems: "center",
                                width: "auto",
                                margin: 0,
                                backgroundColor: scoreColor,
                                // color: textValue === "Fair" ? "#000000" : "#FFFFFF"
                                // always white now
                                color: "#FFFFFF"
                            }}>
                                {
                                    //<span style={{height: "36x", width: "36px", marginRight: "6px"}} dangerouslySetInnerHTML={{__html: cysrConfig().emoji[emoji_selection].icon.svg }} />
                                }
                                <h3 className="cysr-ratings-by-type-textual-item-value" style={{
                                    textAlign: "right",
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    height: "100%",
                                    width: "auto",
                                    margin: 0
                                }}>{textValue}</h3>
                            </div>
                        </div>
                ) : (
                    circle ? <div className="flex items-center gap-2">
                        <div className="rounded-full h-5 w-5" style={{
                            backgroundColor: scoreColor
                        }}></div>
                        <span>{textValue}</span>
                    </div>
                        : <div className="cysr-ratings-by-type-textual-item-badge-container" style={{
                            display: "flex",
                            alignItems: "center",
                            // justifyContent: "center",
                            // line Height : vertical padding
                            lineHeight: "8px",
                            minWidth: "102px",
                            margin: 0
                        }}
                        >
                            <div className="cysr-ratings-by-type-textual-item-badge" style={{
                                padding: "8px 11px",
                                // borderRadius: "5px",
                                height: "24px",
                                display: "flex",
                                alignItems: "center",
                                width: "auto",
                                margin: 0,
                                backgroundColor: scoreColor,
                                // color: textValue === "Fair" ? "#000000" : "#FFFFFF"
                                // always white now
                                color: "#FFFFFF"
                            }}>
                                {
                                    //<span style={{height: "24px", width: "24px", marginRight: "4px"}} dangerouslySetInnerHTML={{__html: cysrConfig().emoji[emoji_selection].icon.svg }} />
                                }
                                <h3 className="cysr-ratings-by-type-textual-item-value" style={{
                                    textAlign: "right",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    height: "100%",
                                    width: "auto",
                                    margin: 0
                                }}>{textValue}</h3>
                            </div>
                        </div>
                )
            )
            }
        </>
    );
}
/* eslint-disable */
