/* eslint-disable */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function RiskDialog({ darkMode, title, trigger, content }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <button type='button' className='w-full' onClick={handleClickOpen}>
                {trigger}
            </button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className={`risk-dialog ${darkMode ? "risk-dialog-dark" : "risk-dialog-light"}`}
                sx={{ zIndex: 999999 }}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent
                    style={{
                        background: darkMode ? "#192328" : "#ffffff",
                        color: darkMode ? "#ffffff" : "#000000"
                    }}>
                    <DialogTitle sx={{ m: 0, pb: 1, pt: 0, pl: 0 }} id="customized-dialog-title">
                        {title}
                    </DialogTitle>
                    {content}
                </DialogContent>
            </BootstrapDialog>
        </React.Fragment>
    );
}