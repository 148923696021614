/* eslint-disable */
// prettier-ignore
// https://master--63da8268a0da9970db6992aa.chromatic.com/?path=/story/api-chart-sankey--custom-node-and-link
import { useMemo, useState } from 'react';
import { ResponsiveContainer, Sankey, Tooltip } from 'recharts';
const colors = ['#5B949F', '#6F50E5', '#3C898E'];
export function specificColor(val, defaultFill){
    let fillColor = defaultFill;
    switch (val?.toUpperCase()) {
        case 'GOVERN':
            fillColor = '#F9F39B'
            break;

        case 'IDENTIFY':
            fillColor = '#4CB3E0'
            break;

        case 'PROTECT':
            fillColor = '#918CEA'
            break;

        case 'DETECT':
            fillColor = '#FAB647'
            break;

        case 'RESPOND':
            fillColor = '#E47677'
            break;

        case 'RECOVER':
            fillColor = '#7EF49E'
            break;

        case 'UNANSWERED':
            fillColor = '#ef4444'
            break;
        case 'ANSWERED':
            fillColor = '#10b981'
            break;

        case 'DRAFT':
            fillColor = '#f97316'
            break;

        case 'L0 NOT PERFORMED':
            fillColor = '#f43f5e'
            break;

        case 'L1 PERFORMED INFORMALLY':
            fillColor = '#f97316'
            break;

        case 'L2 PLANNED & TRACKED':
            fillColor = '#8b5cf6'
            break;

        case 'L3 WELL DEFINED':
            fillColor = '#0ea5e9'
            break;

        case 'L4 QUANTITATIVELY CONTROLLED':
            fillColor = '#3b82f6'
            break;
        
        case 'L5 CONTINUOUSLY IMPROVING':
            fillColor = '#14b8a6'
        break;

        default:
            fillColor = defaultFill;
            break;
    }
    return fillColor
}
const CustomNode = (props) => {

    let fillColor = specificColor(props.payload?.name,colors[props.payload.depth % colors.length])
    
    return <g>
        <rect
            x={props.x}
            y={props.y}
            width={props.width}
            height={props.height}
            fill={fillColor}
            rx={2.5}
        />
        <foreignObject
            x={props.x + 12}
            y={props.y}
            width={160}
            height={props.height} // Use the full height of the rectangle
            style={{ overflow: 'visible' }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start', // Center the content horizontally
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                    padding: '0.5em',
                    //transform: `translateY(${Math.max(0, (props.height - 20) / 2)}px)`, // Vertically center text
                }}
            >
                <div
                    style={{
                        fontSize: 12,
                        fontFamily: 'sans-serif',
                        textAlign: 'center',
                        backgroundColor: '#f1f5fe',
                        padding: '0.25em 0.5em',
                        borderRadius: 4,
                        maxWidth: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {props.payload?.name}
                </div>
            </div>
        </foreignObject>
    </g>
    /*
        return <rect x={props.x// + 4
            } y={props.y //- 2
                } width={props.width //- 8
                    } height={props.height //+ 4
                        } fill={fillColor} rx={2.5} />;
                         */
};
const CustomLink = (props) => {
    let strokeColor = specificColor(props.payload?.source?.name?.toUpperCase(),colors[props.payload.source.depth % colors.length])
    return <g>
        <path d={`
        M${props.sourceX},${props.sourceY}
        C${props.sourceControlX},${props.sourceY} ${props.targetControlX},${props.targetY} ${props.targetX},${props.targetY}`} fill="none" stroke={strokeColor} strokeOpacity={0.4} strokeWidth={props.linkWidth} strokeLinecap="butt" />
        <foreignObject x={props.sourceX} y={props.targetY - props.linkWidth / 2} width={Math.max(props.targetX, props.sourceX) - Math.min(props.targetX, props.sourceX)} height={props.linkWidth} style={{
            overflow: 'visible'
        }}>
            <div style={{
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: '100%',
                height: '100%',
                overflow: 'visible',
                padding: '0.5em',
                gap: 8
            }}>
                {
                    /*
                <div style={{
                    fontSize: 10,
                    fontFamily: 'sans-serif',
                    textAlign: 'center',
                    backgroundColor: '#f1f5fe80',
                    padding: '0.25em 0.5em',
                    borderRadius: 4,
                    position: 'relative',
                    zIndex: 1
                }}>
                    {props.payload.target.name ? `${props.payload.target.name}: ` : ''}
                    {props.payload.value}
                    &nbsp;Objectives
                </div>
                {
                */
                }
            </div>
        </foreignObject>
    </g>;
};
export const ComplySankeyCharts = ({ noSecondLevel = false, controls, controlsAnswers, startingProperty = "function_group" }) => {
    const [data, setData] = useState(null)


    useMemo(() => {
        let nodes = [];
        let links = [];
        let linkMap = new Map();  // To keep track of existing links

        // Helper function to create or update a link
        function createOrUpdateLink(source, target, objCount = 1) {
            const linkKey = `${source}-${target}`;
            if (!linkMap.has(linkKey)) {
                links.push({ source, target, value: objCount });
                linkMap.set(linkKey, links.length - 1);
            } else {
                links[linkMap.get(linkKey)].value = links[linkMap.get(linkKey)].value + objCount;
            }
        }

        Object.entries(controls).forEach((control) => {
            const cK = control[0];
            const c = control[1];
            const objCount = Object.keys(c.objectives).length;
            const objectives = controlsAnswers[cK]?.objectives || {};

            const v1 = c[startingProperty];
            let index1 = nodes.findIndex(x => x.name === v1);
            if (index1 === -1) {
                index1 = nodes.length;
                nodes.push({ name: v1 });
            }
            const v2 = controlsAnswers[cK]?.answer_status;
            let index2 = nodes.findIndex(x => x.name === v2);

            if(noSecondLevel){
                // do not add
            } else {
                if (index2 === -1) {
                    index2 = nodes.length;
                    nodes.push({ name: v2 });
                }
            }
            
            const v3 = controlsAnswers[cK]?.answer;
            let index3 = nodes.findIndex(x => x.name === v3);
            if (index3 === -1) {
                index3 = nodes.length;
                nodes.push({ name: v3 });
            }
            
            
            if(noSecondLevel){
                // Prevent circular reference between the same source and target
                if (index1 !== index3) {
                    createOrUpdateLink(index1, index3, objCount);
                }
            } else {
                // Prevent circular reference between the same source and target
                if (index1 !== index2) {
                    createOrUpdateLink(index1, index2, objCount);
                }

                if (index2 !== index3) {
                    createOrUpdateLink(index2, index3, objCount);
                }
            }

            console.log(objectives)
            console.log(c.objectives)
            // Now map to the objective answer
            Object.keys(c.objectives).forEach(oKey => {
                const objective = objectives[oKey];
                const objectiveAnswer = objective?.objective_answer ?? "Not answered";
                let index4 = nodes.findIndex(x => x.name === objectiveAnswer);
                if (index4 === -1) {
                    index4 = nodes.length;
                    nodes.push({ name: objectiveAnswer });
                }

                // Link the answer node to the objective answer node
                if (index3 !== index4) {
                    createOrUpdateLink(index3, index4);
                }
            });

        });
        setData({
            nodes: nodes/*[
            { name: 'Govern' },                // Node 0
            { name: 'Performed Informally' },  // Node 1
            { name: 'Not Met' },               // Node 2

            { name: 'Govern_2' },              // Node 3
            { name: 'Performed Defined' },     // Node 4
            { name: 'Met Partially' },         // Node 5

            { name: 'Function_Group_1' },      // Node 6
            { name: 'ControlMaturity_Answer_1' },    // Node 7
            { name: 'Objective_Answer_1' },    // Node 8
        ]*/,
            links: links/*[
            { source: 0, target: 1, value: 300 },
            { source: 1, target: 2, value: 50 }, // not met
            { source: 1, target: 5, value: 200 }, // met partially
            // { source: 2, target: 3, value: 50 },
            // { source: 2, target: 7, value: 150 },

            { source: 3, target: 4, value: 200 },
            { source: 4, target: 5, value: 200 }, // met partially

            // { source: 5, target: 7, value: 200 },
        ]*/
        }
        )

    }, [controls, controlsAnswers])

    return (
        <ResponsiveContainer width="100%" height={700}>
            <Sankey
                data={data}

                node={CustomNode}
                link={CustomLink}

                width={960}
                height={960}

                // node={<div />}
                nodeWidth={16}
                nodePadding={14}
                margin={{
                    left: 20,
                    right: 200,
                    top: 20,
                    bottom: 20,
                }}
            // link={{ stroke: '#cccccc' }}
            >
                <Tooltip />
            </Sankey>
        </ResponsiveContainer>
    );
};