"use client"

/* eslint-disable */
// prettier-ignore
import * as React from "react"
import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group"

const ToggleGroupContext = React.createContext({
    size: "default",
    variant: "default",
})

const ToggleGroup = React.forwardRef(({ className, variant, size, children, ...props }, ref) => (
    <ToggleGroupPrimitive.Root
        ref={ref}
        className={`flex flex-wrap items-center gap-1${className && className.length > 0 ? " " + className : ""}`}
        {...props}
    >
        <ToggleGroupContext.Provider value={{ variant, size }}>
            {children}
        </ToggleGroupContext.Provider>
    </ToggleGroupPrimitive.Root>
))

ToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName

const ToggleGroupItem = React.forwardRef(({ className, children, variant, size, ...props }, ref) => {
    const context = React.useContext(ToggleGroupContext)

    return (
        <ToggleGroupPrimitive.Item
            ref={ref}
            className={`data-[state='off']:bg-teal-800/80 data-[state='on']:bg-teal-600/80 leading-none px-2 py-1 rounded-md${className && className.length > 0 ? " " + className : ""}`}
            {...props}
        >
            {children}
        </ToggleGroupPrimitive.Item>
    )
})

ToggleGroupItem.displayName = ToggleGroupPrimitive.Item.displayName

export { ToggleGroup, ToggleGroupItem }
