/* eslint-disable */
import { Base64 } from 'js-base64';
import { useState, useEffect } from "react";
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/material-ui';
import { Collapse, Paper, Stack, TablePagination } from '@mui/material';

import { usePagination } from '@table-library/react-table-library/pagination';
import { useSort } from '@table-library/react-table-library/sort';

// custom stuffs s
import cysrConfig from "layouts/cysr/config";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import CysrTags from "layouts/cysr/components/CysrTags";

import { useMaterialUIController } from "context";
import RenderMarkdown from "layouts/cysr/components/RenderMarkdown"
import { format, subDays } from 'date-fns'
// import { zonedTimeToUtc } from 'date-fns-tz'
// custom stuffs e

const AsyncImage = (props) => {
  const [loadedSrc, setLoadedSrc] = useState(null);
  useEffect(() => {
    setLoadedSrc(null);
    if (props.src) {
      const handleLoad = () => {
        setLoadedSrc(props.src);
      };
      const image = new Image();
      image.addEventListener('load', handleLoad);
      image.src = props.src;
      return () => {
        image.removeEventListener('load', handleLoad);
      };
    }
  }, [props.src]);
  if (loadedSrc === props.src) {
    return (
      <img {...props} />
    );
  }
  return null;
};

const Component = ({ data, tableTagsActive, setTableTagsActive, tData, setTData, COLUMNS, filterNotifications, ids, toggleRead, toggleArchive, notificationsDaysRange, notificationsShowArchived, showUnread, setShowUnread }) => {
  // console.log('data')
  // console.log(data)

  const actionsPixelWidth = 80;
  const tableRowsPerPage = [25, 50, 100, 200, 400];
  const [tableTagsOptions, setTableTagsOptions] = useState(data.tableTagsOptions);
  const [hideCompleted, setHideCompleted] = useState(data.hideCompleted);
  const [search, setSearch] = useState(data.search);

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // date - severity (numero - come rating) - nome azienda - type of event - target host - dettaglio (messaggio)



  // expandable
  let autoString = "";
  let firstAuto = 4;
  COLUMNS.forEach(c => {
    if (firstAuto === 0) {
      autoString += "auto ";
    } else {
      firstAuto--;
    }
  });
  const expandedTableStyle = `
    --data-table-library_grid-template-columns: 80px 160px auto ${autoString} ${actionsPixelWidth}px!important;
    .animate {
      grid-column: 1 / -1;
      display: flex;
    }
    .animate {
        visibility: visible;
        border-bottom: 1px solid #${darkMode ? "4a4545" : "f0f2f5"};
    }
    .animate > div {
      flex: 1;
      display: flex;
    }
    
    @media (min-width: 768px){
      .pin-right {
        right: 0;
      }
    }
  `;

  const ROW_OPTIONS = {
    renderAfterRow: (item) => (
      <Collapse key={item.timestamp} className="animate" in={ids.includes(item.timestamp)}>
        <div style={{ flex: '1', display: 'flex' }}>
          <div style={{ flex: '1', backgroundColor: darkMode ? "#121619" : "#edf0f2" }}>
            <MDBox style={{ position: "sticky", left: 0, right: 0 }} maxWidth={{ xs: "calc(100vw - (48px + 64px))", xl: "calc(100vw - (282px + 48px + 58px))" }}>
              <Card style={{ backgroundColor: darkMode ? "#121619" : "#edf0f2", color: darkMode ? "#7B809A" : "#7B809A", borderRadius: 0, boxShadow: "none", padding: "1em", margin: 0 }}>
                <MDBox width="100%">
                  <Grid container spacing={3} flex style={{
                    // maxWidth: "1234.5px", paddingLeft: "2em"
                    // maxWidth: "100%"
                  }}>
                    <Grid item style={{ display: "flex", flexDirection: "column" }}>
                      <MDTypography variant="caption">{
                        item.timestamp ? `${format(new Date(item.timestamp.indexOf("Z") > -1 ? item.timestamp : item.timestamp + "Z"), "yyyy/MM/dd HH:mm")} UTC` : "Unknown timestamp"}
                      </MDTypography>
                      <MDTypography variant="h6" mb={1}><RenderMarkdown>{item.title}</RenderMarkdown></MDTypography>
                      {item && item.image ? (
                        <Grid item container spacing={2}>
                          <Grid item>
                            <AsyncImage alt="Preview" src={item.image} width="auto" height="96" />
                          </Grid>
                          <Grid item>
                            {item.content ?
                              <MDTypography color="inherit" style={{ fontSize: "14px", width: "600px", maxWidth: "80vw" }}><RenderMarkdown>{item.content}</RenderMarkdown></MDTypography>
                              :
                              <MDTypography variant="caption">No content provided</MDTypography>
                            }
                          </Grid>
                        </Grid>
                      ) : (
                        item.content ?
                          <MDTypography color="inherit" style={{ fontSize: "14px", width: "600px", maxWidth: "80vw" }}><RenderMarkdown>{item.content}</RenderMarkdown></MDTypography>
                          :
                          <MDTypography variant="caption">No content provided</MDTypography>

                      )
                      }

                      <div style={{ display: "flex", flexWrap: "wrap", gap: "8px", marginTop: "8px" }}>
                        {item.tags.map((t) => <MDBadge style={{ cursor: "pointer" }} key={t} variant="contained" badgeContent={t} size="xs" container onClick={
                          () => {
                            let newTags = [];
                            tableTagsActive && tableTagsActive.forEach(x => {
                              if (x !== t && x !== "tag:" + t) {
                                newTags.push(x)
                              }
                            });
                            newTags.push("tag:" + t)
                            handleSetTableTags(null, newTags)
                          }
                        } />)}
                      </div>
                    </Grid>

                    <Grid item container spacing={1} ml={"auto"} style={{ paddingLeft: 0, width: "auto" }}>
                      <Grid item>
                        <MDButton onClick={() => {
                          // if available set the current account uuuid as selected
                          if (item.timestamp) {
                            toggleArchive(item.timestamp, item.archived)
                          }
                          // if available set the current account uuuid as selected e
                        }} style={cysrConfig().button_style} size="medium">
                          <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().archive.icon.svg }} />
                          {item.archived ? "Unarchive" : "Archive"}
                        </MDButton>
                      </Grid>
                      <Grid item>
                        <MDButton onClick={() => {
                          // if available set the current account uuuid as selected
                          if (item.timestamp) {
                            toggleRead(item.timestamp, item.read)
                          }
                          // if available set the current account uuuid as selected e
                        }} style={cysrConfig().button_style} size="medium">
                          <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().recheck.icon.svg }} />
                          {item.read ? "Set as Unread" : "Set as Read"}
                        </MDButton>
                      </Grid>
                      {
                        // additional actions
                        false && <>
                          <Grid item>
                            <MDButton onClick={() => {
                              // if available set the current account uuuid as selected
                              if (item.timestamp) {
                                localStorage.setItem("cysr_useraccountrole_selected", item.timestamp)
                                window.location.href = `/company/${item.timestamp}/assets`
                              }
                              // if available set the current account uuuid as selected e
                            }} style={cysrConfig().button_style} size="medium">
                              Go to Assets
                              <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
                            </MDButton>
                          </Grid>
                          <Grid item>
                            <MDButton onClick={() => {
                              // if available set the current account uuuid as selected
                              if (item.timestamp) {
                                localStorage.setItem("cysr_useraccountrole_selected", item.timestamp)
                                window.location.href = `/company/${item.timestamp}/checks`
                              }
                              // if available set the current account uuuid as selected e
                            }} style={cysrConfig().button_style} size="medium">
                              Go to Checks
                              <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
                            </MDButton>
                          </Grid>
                        </>
                      }
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </MDBox>
          </div>
        </div>
      </Collapse>
    ),
  };

  const materialTheme = getTheme(DEFAULT_OPTIONS, {
    isVirtualized: true
  });

  // to set row border bottom style:
  // border-bottom: 1px solid #${darkMode ? "4a4545" : "f0f2f5"};

  const customTheme = {
    Table: expandedTableStyle,
    BaseCell: `
      border: 0!important;
      padding: 4px;
    `,
  };
  let theme = useTheme([materialTheme, customTheme]);
  if (darkMode) {
    // provide custom theme
    theme = useTheme([materialTheme, {
      Table: expandedTableStyle,
      BaseCell: `
        color: #ffffff;
        background-color: #192328;
        border: 0!important;
        padding: 4px;
      `,
    }
    ]);
  }

  const pagination = usePagination(tData, {
    state: {
      page: 0,
      // size: 2,
      // size: tData.nodes.length,
      size: tableRowsPerPage[0],
    },
    onChange: onPaginationChange,
  });

  function onPaginationChange(action, state) {
    console.log(action, state);
  }

  const sort = useSort(
    tData,
    {
      state: {
        sortKey: 'LAST_CHECK',
        reverse: false
      },
      onChange: onSortChange,
    },
    {
      sortIcon: {
        iconDefault: null,
        iconUp: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
        </svg>
        ,
        iconDown: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
        ,
      },
      sortFns: {
        /*
        RATING: (array) => array.sort((a, b) =>
          (a.severity ? a.severity : 0) - (b.severity ? b.severity : 0)
        ),
        */
        SEVERITY: (array) => array.sort(function (a, b) {
          let aR = a.severity ? a.severity : "N/A";
          let bR = b.severity ? b.severity : "N/A";
          if (!aR || aR === null || aR === undefined || aR === "" || aR === "N/A") {
            aR = 101;
          }
          if (!bR || bR === null || bR === undefined || bR === "" || bR === "N/A") {
            bR = 101;
          }
          return aR - bR
        }),
        CREATED: (array) => array.sort((a, b) =>
          new Date(b.mu_created_at).getTime() - new Date(a.mu_created_at).getTime()
        ),
        LAST_CHECK: (array) => array.sort((a, b) =>
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        ),
        NAME: (array) => array.sort((a, b) => a.account_name.localeCompare(b.account_name)),
        TYPE: (array) => array.sort((a, b) => a.asset_type.localeCompare(b.asset_type)),
        VAT: (array) => array.sort((a, b) => a.account_data_national_id.localeCompare(b.account_data_national_id)),
        TITLE: (array) => array.sort((a, b) => a.title.localeCompare(b.title)),
        SUMMARY: (array) => array.sort((a, b) => a.summary.localeCompare(b.summary)),
        TOPIC: (array) => array.sort((a, b) => a.topic_descr.localeCompare(b.topic_descr)),
        SUBSCRIPTION: (array) => array.sort((a, b) => a.contractor_subscription.localeCompare(b.contractor_subscription))
      },
    },
  );

  function onSortChange(action, state) {
    console.log(action, state);
  }

  const handleSetTableTags = (event, t) => {
    // console.log(t)
    // replace url s
    let params = new URLSearchParams(window.location.search)
    params.delete('t')
    if (t != "" && t.length > 0) {
      params.append("t", Base64.encode(JSON.stringify(t)))
    }
    window.history.replaceState(null, '', '?' + params + window.location.hash)
    setTableTagsActive(t);
    //s
    fetch(`${cysrConfig().api_base_url}notification/myfeed/?archived=${notificationsShowArchived}&start_date=${format(subDays(new Date(), notificationsDaysRange ? notificationsDaysRange : 8), "yyyy-MM-dd'T'hh:mm:ss.SSS")}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          response.json()
            .then((responseData) => {
              setTData(
                {
                  nodes: responseData.events.filter(filterNotifications(t, COLUMNS)
                  )
                }
              );
            })
        } else {
          return response.json().then(err => {
            throw JSON.stringify(err)
          })
        }
      })
      .catch((err) => {
        console.error("enabled err:", err);
        console.error(err);
      });
    // setTData({ nodes: tData.nodes.filter(filterNotifications(t, COLUMNS)) })
    //e
    // replace url e
    // go to first page
    pagination.fns.onSetPage(0)
  };
  const handleSearch = (event) => {
    const s = event.target.value;
    setSearch(s);
    // replace url s
    let params = new URLSearchParams(window.location.search)
    params.delete('s')
    if (s != "") {
      params.append("s", s)
    }
    window.history.replaceState(null, '', '?' + params + window.location.hash)
    // replace url e
  };
  const handleSetHideCompleted = (event) => {
    const c = event.target.checked;
    setHideCompleted(c);
    // replace url s
    let params = new URLSearchParams(window.location.search)
    params.delete('hc')
    if (c) {
      params.append("hc", "true")
    }
    window.history.replaceState(null, '', '?' + params + window.location.hash)
    // replace url e
  };

  const VIRTUALIZED_OPTIONS = {
    rowHeight: (_item, _index) => {
      return 54
    }
  };

  return (
    <>
      <Stack spacing={10} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CysrTags tableTagsOptions={tableTagsOptions} tableTagsActive={tableTagsActive} handleSetTableTags={handleSetTableTags} darkMode={darkMode} />
          </Grid>
        </Grid>
      </Stack>
      <Paper style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none', height: 'auto' }}>
        {
          // <Paper style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none', height: '360px' }}>
        }
        <CompactTable
          columns={COLUMNS}
          // passando le virtualizedOptions non funziona l'expand abilitato, penso perchè imposta l'height da solo
          // virtualizedOptions={VIRTUALIZED_OPTIONS}
          data={tData}
          sort={sort}
          theme={theme}
          rowOptions={ROW_OPTIONS}
          layout={{
            isDiv: true,
            fixedHeader: true
          }}
          pagination={pagination}
          style={{ borderColor: 'transparent' }}
        // horizontalScroll={true}
        />
      </Paper>
      <Stack spacing={10}>
        <TablePagination
          component="div"
          style={{ color: darkMode ? "#ffffff" : "#000000", border: 0 }}
          count={tData && tData.nodes && tData.nodes.length}
          page={pagination.state.page}
          rowsPerPage={pagination.state.size}
          rowsPerPageOptions={tableRowsPerPage}
          onRowsPerPageChange={(event) =>
            pagination.fns.onSetSize(parseInt(event.target.value, 10))
          }
          onPageChange={(event, page) => pagination.fns.onSetPage(page)}
        />
      </Stack>
    </>
  );
};
export default Component;
/* eslint-disable */