/* eslint-disable */
import { Base64 } from 'js-base64';
import { useState } from "react";
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/material-ui';
import { Collapse, Paper, Stack, TablePagination, Checkbox, FormControlLabel, Icon } from '@mui/material';

import { usePagination } from '@table-library/react-table-library/pagination';
import { useSort } from '@table-library/react-table-library/sort';

// custom stuffs s
import Autocomplete from "@mui/material/Autocomplete";
import CysrStepper from "layouts/cysr/components/CysrStepper";
import cysrConfig from "layouts/cysr/config";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ScoreItem from "layouts/cysr/components/ScoreItem";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import CysrTags from "layouts/cysr/components/CysrTags";
import MDInput from "components/MDInput";
// import FormField from "layouts/pages/account/components/FormField";

import Radio from "@mui/material/Radio";

import CysrTableUsers from "layouts/cysr/components/CysrTable/users";
import { useMaterialUIController } from "context";
import { useSnackbar } from 'notistack';

import {
  // countries,
  countriesCodes
} from "layouts/cysr/components/CYSRCountries"
// custom stuffs e

const Component = ({ data }) => {
  // console.log('data')
  // console.log(data)

  const [createOpen, setOpen] = useState(false);
  const btnCreateUserDefaultString = "Send the invitation email";
  const [btnCreateUserString, setStringBtnCreateUser] = useState(btnCreateUserDefaultString);
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userTech, setUserTech] = useState(false);
  const [userAdmin, setUserAdmin] = useState(false);
  const [userCreationLoading, setUserCreationLoading] = useState(false);

  // filter test s
  const params = new URLSearchParams(window.location.search);
  let activeTags = [];
  let userActiveTags = [];
  // user filtered:
  try {
    activeTags = params.get('t') && (decodeURI(params.get('t'))) && Base64.decode(decodeURI(params.get('t'))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('t')))) : [];
  } catch (error) {
    console.log("Error in setting active tags:")
    console.log(error)
  }
  try {
    userActiveTags = params.get('u') && (decodeURI(params.get('u'))) && Base64.decode(decodeURI(params.get('u'))) && JSON.parse(Base64.decode(decodeURI(params.get('u')))) && JSON.parse(Base64.decode(decodeURI(params.get('u')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('u')))) : [];
  } catch (error) {
    console.log("Error in setting active tags:")
    console.log(error)
  }
  // filter test e
  function inviteUser(event) {
    event.preventDefault();
    setStringBtnCreateUser('Sending invite to user...')
    console.log(userAdmin) // false/true
    console.log(userTech) // false/true
    // if (userEmail && userEmail != "" && userName && userName != "" && userLastName && userLastName != "") {
    if (userEmail && userEmail != "") {
      // invite
      // /subscription/<uuid>/askuser/ POST <email> 
      // const inviteUserUrl = `${cysrConfig().api_base_url}user/newsubuser/`;
      const inviteUserUrl = `${cysrConfig().api_base_url}contractor/${editingContractorId}/user/ask/`;
      const datainviteUser = {
        invited_email: userEmail,
      };
      console.log(datainviteUser);
      fetch(inviteUserUrl, {
        body: JSON.stringify(datainviteUser),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
          "content-type": "application/json",
        },
        method: "POST",
      })
        .then((response) => {
          console.log(response);
          if (response.status === 500) {
            enqueueSnackbar(
              <div style={{ display: "flex", flexDirection: "column" }}>
                <MDTypography variant="caption" style={{ fontWeight: "600" }}>Error</MDTypography>
                <MDTypography variant="caption">{response.statusText}</MDTypography>
              </div>, {
              action,
              variant: 'error',
              persist: true
            })
            setStringBtnCreateUser(btnCreateUserDefaultString)
            return false
          } else if (response.ErrorMessage) {
            throw ({
              ErrorMessage: response.ErrorMessage,
              ErrorDetail: response.ErrorDetail
            });
          } else {
            return response.json();
          }
        })
        .then((r) => {
          console.log(r);
          // refresh accounts s
          if (r && r.ResponseMetadata && r.ResponseMetadata.HTTPStatusCode && r.ResponseMetadata.HTTPStatusCode === 200) {
            enqueueSnackbar(
              <div style={{ display: "flex", flexDirection: "column" }}>
                <MDTypography variant="caption" style={{ fontWeight: "600" }}>Sent successfully</MDTypography>
                <MDTypography variant="caption">Invitation has been sent</MDTypography>
              </div>, {
              // action,
              variant: 'success',
              persist: false
            })
            enqueueSnackbar(
              <div style={{ display: "flex", flexDirection: "column" }}>
                <MDTypography variant="caption" style={{ fontWeight: "600" }}>Reloading..</MDTypography>
                <MDTypography variant="caption">The page will reload shortly</MDTypography>
              </div>, {
              // action,
              variant: 'info'
            }
            )
            window.location.reload()
          } else {
            enqueueSnackbar(
              <div style={{ display: "flex", flexDirection: "column" }}>
                <MDTypography variant="caption" style={{ fontWeight: "600" }}>{r.Message}</MDTypography>
                <MDTypography variant="caption">{r.Detail}</MDTypography>
              </div>, {
              // action,
              variant: 'warning',
              persist: false
            })
          }
          setStringBtnCreateUser(btnCreateUserDefaultString)
        })
        .catch((err) => {
          setStringBtnCreateUser(btnCreateUserDefaultString)
          let snackErrorTitle = "Invite error";
          let snackErrorDetail = "Invite could not be sent at this time";
          if (err && err.ErrorMessage) {
            snackErrorTitle = err.ErrorMessage;
            snackErrorDetail = err.ErrorDetail;
          } else if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
            snackErrorTitle = JSON.parse(err).ErrorMessage;
            snackErrorDetail = JSON.parse(err).ErrorDetail;
          } else if (JSON.parse(err) && JSON.parse(err).error) {
            // snackErrorTitle = "Error";
            snackErrorDetail = JSON.parse(err).error;
          }
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>{snackErrorTitle}</MDTypography>
              <MDTypography variant="caption">{snackErrorDetail}</MDTypography>
            </div>, {
            action,
            variant: 'error',
            persist: true
          })
          console.log("err...");
          console.log(err);
        });
    } else {
      // ask to fill fields
      setStringBtnCreateUser(btnCreateUserDefaultString)
      let snackErrorTitle = "Invite error";
      let snackErrorDetail = "Email missing";
      if (err && err.ErrorMessage) {
        snackErrorTitle = err.ErrorMessage;
        snackErrorDetail = err.ErrorDetail;
      } else if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
        snackErrorTitle = JSON.parse(err).ErrorMessage;
        snackErrorDetail = JSON.parse(err).ErrorDetail;
      } else if (JSON.parse(err) && JSON.parse(err).error) {
        // snackErrorTitle = "Error";
        snackErrorDetail = JSON.parse(err).error;
      }
      enqueueSnackbar(
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <MDTypography variant="caption" style={{ fontWeight: "600" }}>{snackErrorTitle}</MDTypography>
          <MDTypography variant="caption">{snackErrorDetail}</MDTypography>
        </div>, {
        action,
        variant: 'error',
        persist: true
      })
    }
  }

  const actionsPixelWidth = 185;
  const tableRowsPerPage = [25, 50, 100, 200, 400];
  const [tableTagsActive, setTableTagsActive] = useState(data.tableTagsActive);
  const [tableTagsOptions, setTableTagsOptions] = useState(data.tableTagsOptions);
  const [hideCompleted, setHideCompleted] = useState(data.hideCompleted);

  const [editingContractorId, setEditingContractorId] = useState("");
  const [editingContractorUsers, setEditingContractorUsers] = useState([]);
  const [editingContractorName, setEditingContractorName] = useState("");
  const [editingContractorVatName, setEditingContractorVatName] = useState("");
  const [editingContractorVatCity, setEditingContractorVatCity] = useState("");
  const [editingContractorVatCountry, setEditingContractorVatCountry] = useState("");
  const [editingContractorVatAddress, setEditingContractorVatAddress] = useState("");
  const [editingContractorZipCode, setEditingContractorZipCode] = useState("");
  const [editingContractorEvatSdiNumber, setEditingContractorEvatSdiNumber] = useState("");

  const [contractorPaymentMethod, setContractorPaymentMethod] = useState("");
  let tData = { nodes: data.nodes };
  // const [tData, setData] = useState({ nodes: data.nodes });

  const [controller] = useMaterialUIController();

  //snackBar s
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // add action to an individual snackbar
  const action = snackbarId => (
    <>
      <MDButton onClick={() => { closeSnackbar(snackbarId) }} style={cysrConfig().button_style} size="medium">
        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().close.icon.svg }} />
        Dismiss
      </MDButton>
    </>
  );
  //snackBar e

  const { darkMode } = controller;

  let accountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/company/") + "/company/".length,
    window.location.pathname.lastIndexOf("/")
  );
  if (localStorage.getItem("cysr_useraccountrole_selected")) {
    accountUUID = localStorage.getItem("cysr_useraccountrole_selected");
  }

  const COLUMNS = [
    {
      label: 'Billing',
      renderCell: (item) => item.contractor_is_payer ?
        <svg style={{ display: "flex", height: 16, margin: "auto", width: 16 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
        </svg>
        :
        <svg style={{ display: "flex", height: 16, margin: "auto", width: 16 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
        </svg>
      ,
      sort: { sortKey: 'ISPAYER' },
      resize: false,
      cysrTag: "contractor_is_payer"
    },
    {
      label: 'Name',
      renderCell: (item) => <p style={{ fontWeight: "bold", overflow: "hidden", maxWidth: "100%", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
        {
          // item.vat_name ? item.name + " ("+item.vat_name+")" : item.name
        }
        {item.vat_name ? item.name : item.name}
      </p>,
      sort: { sortKey: 'NAME' },
      resize: true,
      cysrTag: ["vat_name", "name"],
    },
    {
      label: 'VAT',
      renderCell: (item) => item.vat_country_code === "XX" ? "other" : item.vat_country_code + " " + item.vat_number,
      sort: { sortKey: 'VAT' },
      resize: true,
      cysrTag: ["vat_country_code", "vat_number"]
    },/*
    {
      label: 'Created',
      renderCell: (item) =>
      item.mu_created_at ? new Date(item.mu_created_at).toLocaleDateString('it-IT', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }) : "",
      sort: { sortKey: 'CREATED' },
      resize: true,
      cysrTag: "mu_created_at",
      cysrTagDateFormat:'it-IT'
    },*/
    {
      label: 'Actions',
      renderCell: (item) => (
        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '8px', width: `${actionsPixelWidth}px` }}>
          <MDButton onClick={() => handleExpand(item, "reset")} style={cysrConfig().button_style} size="medium">
            {ids.includes(item.name) ?
              <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} />
              :
              <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />
            }
            Edit subscriber
          </MDButton>
        </div>
      ),
      pinRight: true
      // sort: { sortKey: 'RATING' }
    },
  ];

  // expandable
  const [ids, setIds] = useState([]);



  // filter list
  tData = {
    nodes: tData.nodes.filter((item) => {
      // check tags
      // da fare tags con chiave valore
      if (tableTagsActive && tableTagsActive.length > 0) {
        let trueCount = tableTagsActive.length;
        const scoreValue = item.asset_last_rating && item.asset_last_rating.rating_val ? item.asset_last_rating.rating_val : "N/A";
        let textValue = "Bad";
        if (scoreValue === "N/A") {
          textValue = "N/A";
        } else if (scoreValue < 30) {
          textValue = "Bad";
        } else if (scoreValue < 50) {
          textValue = "Poor";
        } else if (scoreValue < 70) {
          textValue = "Fair";
        } else if (scoreValue < 90) {
          textValue = "Good";
        } else if (scoreValue < 100 || scoreValue == 100) {
          textValue = "Excellent";
        }
        tableTagsActive.forEach(ta => {
          let notFound = true;
          // X:item.cysrTag s
          if (ta && typeof ta === 'string' && ta.includes(":")) {
            const tag = ta.toLowerCase().split(":");
            const tagKey = tag[0].toLowerCase();
            const tagValue = tag[1].toString().trim();
            if (tagKey === "rating" && tagValue.toLowerCase() === textValue.toLowerCase()) {
              if (notFound) {
                trueCount--;
                notFound = false;
              }
            }
            COLUMNS.forEach(col => {
              if (col.cysrTag && item[col.cysrTag] && col.label.toLowerCase() === tagKey && item[col.cysrTag.toLowerCase()] && item[col.cysrTag.toLowerCase()]) {
                // check cysrTagDateFormat
                if (col.cysrTagDateFormat) {
                  const vd = new Date(item[col.cysrTag.toLowerCase()].toString()).toLocaleDateString(col.cysrTagDateFormat, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })
                  if (vd.toString().includes(tagValue) && notFound) {
                    trueCount--;
                    notFound = false;
                  }
                } else if (item[col.cysrTag.toLowerCase()].toString().toLowerCase().includes(tagValue)) {
                  if (notFound) {
                    trueCount--;
                    notFound = false;
                  }
                }
              }
            })
          }
          // X:item.cysrTag e
          // compare (for freeSolo) s
          if (ta && ta.rating && ta.rating === textValue) {
            if (notFound) {
              trueCount--;
              notFound = false;
            }
          }
          [
            "name",
            "vat_name",
            "vat_number",
            "vat_country_code"
          ].forEach(property => {
            if (ta && ta[property]) {
              // a default option (with [property])
              if (ta && ta[property] && item[property] && item[property].toLowerCase().includes(ta[property].toLowerCase())) {
                if (notFound) {
                  trueCount--;
                  notFound = false;
                }
              }
            } else {
              if (ta && ta.rating) {
                // avoid checking again ta with title (rating Excellent, Good...)
              } else if (ta === textValue) {
                // checking ta without title written by contractor ("Excellent", "Good"...)
                if (notFound) {
                  trueCount--;
                  notFound = false;
                }
              } else {
                // not a default option (with [property])
                if (ta && item[property] && item[property].toLowerCase().includes(ta.toLowerCase())) {
                  if (notFound) {
                    trueCount--;
                    notFound = false;
                  }
                }
              }
            }
          });
          // compare (for freeSolo) e
        });
        if (trueCount !== 0) {
          return false;
        }
      }
      // check search at last
      // ..
      return true;
    }
    ),
  };

  let autoString = "";
  let firstAuto = 2;
  COLUMNS.forEach(c => {
    if (firstAuto === 0) {
      autoString += "auto ";
    } else {
      firstAuto--;
    }
  });
  const expandedTableStyle = `
    --data-table-library_grid-template-columns: 50px ${autoString} ${actionsPixelWidth}px!important;
    .animate {
      grid-column: 1 / -1;
      display: flex;
    }
    .animate {
        visibility: visible;
        border-bottom: 1px solid #${darkMode ? "4a4545" : "f0f2f5"};
    }
    .animate > div {
      flex: 1;
      display: flex;
    }
    
    @media (min-width: 768px){
      .pin-right {
        right: 0;
      }
    }
  `;

  const handleExpand = (item, reset = false) => {
    setEditingContractorId(item.id);
    if (reset) {
      const callUrl = `${cysrConfig().api_base_url}contractor/${item.id}/`;
      fetch(callUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("editingContractorUsers");
          console.log("contractor");
          console.log(data);

          let subUsers = data.users;
          if (data.pending_users) {
            data.pending_users.forEach(ele => {
              subUsers.push(ele)
            });
          }
          setEditingContractorUsers(subUsers)
        })
        .catch((aerr) => {
          console.log(aerr)
        });
      setEditingContractorName("");
      setEditingContractorVatName("");
      setEditingContractorVatCity("");
      setEditingContractorVatCountry("");
      setEditingContractorVatAddress("");
      setEditingContractorEvatSdiNumber("");
    }
    if (ids.includes(item.name)) {
      setIds(ids.filter((id) => id !== item.name));
    } else {
      setIds(ids.concat(item.name));
    }
  };

  const saveContractorEdited = (item) => {
    // /contractor/<uuid>/ PATCH
    console.log("saveContractorEdited item")
    console.log(item)
    let contractorObject = {};
    if (editingContractorName != "") {
      contractorObject.contractor_name = editingContractorName;
    }
    if (editingContractorVatName != "") {
      contractorObject.contractor_vat_name = editingContractorVatName;
    }
    if (editingContractorVatCity != "") {
      contractorObject.contractor_vat_city = editingContractorVatCity;
    }
    if (editingContractorVatCountry != "") {
      if (editingContractorVatCountry === "other") {
        contractorObject.contractor_vat_country_code = "XX";
      } else {
        contractorObject.contractor_vat_country_code = editingContractorVatCountry;
      }
    }
    if (editingContractorVatAddress != "") {
      contractorObject.contractor_vat_address = editingContractorVatAddress;
    }
    if (editingContractorZipCode != "") {
      contractorObject.contractor_vat_zip_code = editingContractorZipCode;
    }
    if (editingContractorEvatSdiNumber != "") {
      contractorObject.contractor_evat_sdi_number = editingContractorEvatSdiNumber;
    }

    console.log("contractorObject:")
    console.log(contractorObject)
    console.log('editingContractorVatCountry')
    console.log(editingContractorVatCountry)

    fetch(`${cysrConfig().api_base_url}contractor/${item.id}/`, {
      body: JSON.stringify(contractorObject),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      method: "PATCH",
    })
      .then((response) => response.json())
      .then((r) => {
        if (r.ErrorMessage) {
          throw ({
            ErrorMessage: r.ErrorMessage,
            ErrorDetail: r.ErrorDetail
          });
        } else {
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>Updates Applied</MDTypography>
              <MDTypography variant="caption">Subscriber succesfully updated</MDTypography>
            </div>, {
            // action,
            variant: 'success',
            persist: false
          }
          )
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>Reloading..</MDTypography>
              <MDTypography variant="caption">The page will reload shortly</MDTypography>
            </div>, {
            // action,
            variant: 'info'
          }
          )
          window.location.reload()
        }
        console.log("r")
        console.log(r)
      })
      .catch((err) => {
        console.error("err")
        console.error(err)
        let snackErrorTitle = "Error";
        let snackErrorDetail = "Some error happened";
        if (err && err.ErrorMessage) {
          snackErrorTitle = err.ErrorMessage;
          snackErrorDetail = err.ErrorDetail;
        } else if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
          snackErrorTitle = JSON.parse(err).ErrorMessage;
          snackErrorDetail = JSON.parse(err).ErrorDetail;
        } else if (JSON.parse(err) && JSON.parse(err).error) {
          // snackErrorTitle = "Error";
          snackErrorDetail = JSON.parse(err).error;
        }
        enqueueSnackbar(
          <div style={{ display: "flex", flexDirection: "column" }}>
            <MDTypography variant="caption" style={{ fontWeight: "600" }}>{snackErrorTitle}</MDTypography>
            <MDTypography variant="caption">{snackErrorDetail}</MDTypography>
          </div>, {
          action,
          variant: 'error',
          persist: true
        })
      });
    // replace url e
  };

  const deleteContractorEdited = (item) => {
    // /contractor/<uuid>/ DELETE      
    fetch(`${cysrConfig().api_base_url}contractor/${item.id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((r) => {
        if (r.ErrorMessage) {
          throw ({
            ErrorMessage: r.ErrorMessage,
            ErrorDetail: r.ErrorDetail
          });
        } else {
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>Subscriber Removed</MDTypography>
              <MDTypography variant="caption">Subscriber succesfully removed</MDTypography>
            </div>, {
            // action,
            variant: 'success',
            persist: false
          }
          )
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>Reloading..</MDTypography>
              <MDTypography variant="caption">The page will reload shortly</MDTypography>
            </div>, {
            // action,
            variant: 'info'
          }
          )
          window.location.reload()
        }
        console.log("r")
        console.log(r)
      })
      .catch((err) => {
        console.error("err")
        console.error(err)
        let snackErrorTitle = "Error";
        let snackErrorDetail = "Some error happened";
        if (err && err.ErrorMessage) {
          snackErrorTitle = err.ErrorMessage;
          snackErrorDetail = err.ErrorDetail;
        } else if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
          snackErrorTitle = JSON.parse(err).ErrorMessage;
          snackErrorDetail = JSON.parse(err).ErrorDetail;
        } else if (JSON.parse(err) && JSON.parse(err).error) {
          // snackErrorTitle = "Error";
          snackErrorDetail = JSON.parse(err).error;
        }
        enqueueSnackbar(
          <div style={{ display: "flex", flexDirection: "column" }}>
            <MDTypography variant="caption" style={{ fontWeight: "600" }}>{snackErrorTitle}</MDTypography>
            <MDTypography variant="caption">{snackErrorDetail}</MDTypography>
          </div>, {
          action,
          variant: 'error',
          persist: true
        })
      });
    // replace url e
  };

  const ROW_OPTIONS = {
    renderAfterRow: (item) => (
      <Collapse className="animate" in={ids.includes(item.name)}>
        <div style={{ flex: '1', display: 'flex' }}>
          <div style={{ flex: '1', backgroundColor: darkMode ? "#121619" : "#edf0f2" }}>
            <MDBox style={{ position: "sticky", left: 0, right: 0 }} maxWidth={{ xs: "calc(100vw - (48px + 64px))", xl: "calc(100vw - (282px + 48px + 58px))" }}>
              <Card style={{ backgroundColor: darkMode ? "#121619" : "#edf0f2", color: darkMode ? "#7B809A" : "#7B809A", borderRadius: 0, boxShadow: "none", padding: "1em", margin: 0 }}>
                <MDBox width="100%">
                  {false && <MDTypography variant="h6">Name</MDTypography>}
                  {false && <MDTypography variant="caption" mb={1.5} style={{ display: "block" }}>Edit subscriber name</MDTypography>}

                  {false && <MDTypography variant="h6">VAT Details</MDTypography>}
                  {false && <MDTypography variant="caption" style={{ display: "block" }}>Edit subscriber VAT Details</MDTypography>}

                  <Grid container spacing={1} mt={1.5}>
                    <Grid item xs={6} lg={6}>
                      <MDInput
                        defaultValue={item.name}
                        sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                        onChange={(e) => {
                          setTimeout(() => {
                            if (editingContractorName != e.target.value) {
                              setEditingContractorName(e.target.value);
                            }
                          }, 200);
                        }}
                        size="small"
                        type="text"
                        label="Common Name"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                      <MDInput
                        fullWidth
                        value={`${item.vat_number ? item.vat_number : ""}`}
                        sx={{ marginRight: ".5em", color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                        readOnly
                        size="small"
                        type="text"
                        label="VAT Number"
                      />
                      <MDTypography variant="caption" style={{ display: "block", marginTop: ".5em" }}>
                        You can <strong><a href={"mailto:support@cysr.com"}>contact support</a></strong> to edit VAT.
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container spacing={1} mt={1.5}>
                    <Grid item xs={6} lg={6}>
                      <MDInput
                        fullWidth
                        defaultValue={item.vat_name}
                        sx={{ marginRight: ".5em", color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                        onChange={(e) => {
                          setTimeout(() => {
                            if (editingContractorVatName != e.target.value) {
                              setEditingContractorVatName(e.target.value);
                            }
                          }, 200);
                        }}
                        size="small"
                        type="text"
                        label="Billing / Full Name"
                      // id="c_generalsubscription_startingdate"
                      />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                      <MDInput
                        fullWidth
                        defaultValue={item.contractor_vat_city}
                        sx={{ marginRight: ".5em", color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                        onChange={(e) => {
                          setTimeout(() => {
                            if (editingContractorVatCity != e.target.value) {
                              setEditingContractorVatCity(e.target.value);
                            }
                          }, 200);
                        }}
                        size="small"
                        type="text"
                        label="City"
                      // id="c_generalsubscription_startingdate"
                      />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                      <Autocomplete
                        fullWidth
                        defaultValue={item.vat_country_code}
                        style={{ height: "36.5px" }}
                        sx={{ height: "36.5px", color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                        onChange={(e, x) => {
                          setTimeout(() => {
                            if (editingContractorVatCountry != x) {
                              setEditingContractorVatCountry(x);
                            }
                          }, 200);
                        }}
                        size="small"
                        options={countriesCodes}
                        renderInput={(params) => <MDInput
                          style={{ height: "36.5px" }}
                          sx={{ height: "36.5px", color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                          size="small" label="Country" {...params} />}
                      />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                      <MDInput
                        fullWidth
                        defaultValue={item.contractor_vat_address}
                        sx={{ marginRight: ".5em", color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                        onChange={(e) => {
                          setTimeout(() => {
                            if (editingContractorVatAddress != e.target.value) {
                              setEditingContractorVatAddress(e.target.value);
                            }
                          }, 200);
                        }}
                        size="small"
                        type="text"
                        label="Address"
                      // id="c_generalsubscription_startingdate"
                      />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                      <MDInput
                        fullWidth
                        defaultValue={item.contractor_vat_zip_code}
                        sx={{ marginRight: ".5em", color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                        onChange={(e) => {
                          setTimeout(() => {
                            if (editingContractorZipCode != e.target.value) {
                              setEditingContractorZipCode(e.target.value);
                            }
                          }, 200);
                        }}
                        size="small"
                        type="text"
                        label="Zip Code"
                      // id="c_generalsubscription_startingdate"
                      />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                      <MDInput
                        fullWidth
                        defaultValue={item.contractor_evat_sdi_number}
                        sx={{ marginRight: ".5em", color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                        onChange={(e) => {
                          setTimeout(() => {
                            if (editingContractorEvatSdiNumber != e.target.value) {
                              setEditingContractorEvatSdiNumber(e.target.value);
                            }
                          }, 200);
                        }}
                        size="small"
                        type="text"
                        label="SDI Code (italian entities only)"
                      // id="c_generalsubscription_startingdate"
                      />
                    </Grid>
                  </Grid>
                  {false && <>
                    <Divider />

                    <MDTypography variant="h6">Managers</MDTypography>
                    <MDTypography variant="caption" mb={1.5} style={{ display: "block" }}>Accounts that can see this contractor</MDTypography>

                    <FormControlLabel
                      control={
                        <Checkbox defaultChecked={item.user_admin} />
                      }
                      label="Name of account"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox defaultChecked={item.user_admin} />
                      }
                      label="Name of another account"
                    />
                    <MDTypography variant="overline">
                      Contact the support to create a new linked account.
                    </MDTypography>
                  </>
                  }
                  {
                    // payment method
                    false && <>
                      <Divider />
                      <Grid container spacing={1}>
                        <Grid item>
                          <MDBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            flexDirection={{ xs: "column", sm: "row" }}
                          >
                            <div>
                              <Radio
                                checked={false}
                                onChange={() => {
                                  // subscriptionRadioChange(sid)
                                }
                                }
                                value={true}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': "hi" }}
                              />
                              <div style={{ display: "inline-flex", gap: "8px", alignItems: "center" }}>
                                <MDTypography variant="text" fontSize="small">
                                  text
                                </MDTypography>
                                <MDTypography variant="text" fontSize="small" color="text"> - </MDTypography>
                                <MDTypography variant="text" fontSize="small">
                                  type
                                </MDTypography>
                                <MDTypography variant="text" fontSize="small" color="text"> - </MDTypography>
                                <MDTypography variant="tex" fontSize="small">
                                  status
                                </MDTypography>
                              </div>
                            </div>
                          </MDBox>
                          <MDInput
                            fullWidth
                            defaultValue={contractorPaymentMethod}
                            sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                            onChange={(e) => {
                              setTimeout(() => {
                                if (contractorPaymentMethod != e.target.value) {
                                  setContractorPaymentMethod(e.target.value);
                                }
                              }, 200);
                            }}
                            size="small"
                            type="text"
                            label="Payment Method"
                            id="c_payment_method_val"
                          />
                        </Grid>
                      </Grid>
                    </>}
                  {item.contractor_is_payer &&
                    <>
                      <Divider />
                      {false && <MDTypography variant="h6"> Subscriber Administrators</MDTypography>}
                      {false && <MDTypography variant="caption" mb={1.5} style={{ display: "block" }}>Edit subscriber Administrators</MDTypography>}
                      <MDBox p={0} fullWidth>
                        <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                          <div>
                            {cysrConfig().i("contractor", "add_a_user", "title") && (
                              <MDTypography variant="h5">{cysrConfig().i("contractor", "add_a_user", "title")}</MDTypography>
                            )}
                            {cysrConfig().i("contractor", "add_a_user", "sub_title") && (
                              <MDTypography variant="button" color="text" fontWeight="regular">
                                {cysrConfig().i("contractor", "add_a_user", "sub_title")}
                              </MDTypography>
                            )}
                          </div>
                          <MDButton
                            aria-label="expand row"
                            onClick={() => setOpen(!createOpen)} style={cysrConfig().button_style} size="medium">
                            {createOpen ? <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} /> : <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />
                            }
                            Add administrator
                          </MDButton>
                        </MDBox>
                        <Collapse in={createOpen} style={{ marginTop: ".5em" }} timeout="auto" unmountOnExit>
                          <div style={{
                            borderBottom: `1px solid ${darkMode ? "#4a4545" : "#f0f2f5"}`,
                            borderTop: `1px solid ${darkMode ? "#4a4545" : "#f0f2f5"}`,
                            marginTop: "8px"
                          }}>
                            <form onSubmit={inviteUser}>
                              <CysrStepper darkMode isLoading={userCreationLoading} steps={
                                [
                                  {
                                    label: '',
                                    value: userEmail,
                                    content:
                                      <Grid container spacing={1}>
                                        <Grid item xs={6} lg={6}>
                                          <MDInput
                                            fullWidth
                                            defaultValue={userEmail}
                                            sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                                            onChange={(e) => {
                                              setTimeout(() => {
                                                if (userEmail != e.target.value) {
                                                  setUserEmail(e.target.value);
                                                }
                                              }, 200);
                                            }}
                                            size="small"
                                            type="email"
                                            label="Email address of the new administrator"
                                            id="c_email_val"
                                          />
                                        </Grid>
                                      </Grid>,
                                    submitIcon: "add",
                                    submitText: btnCreateUserString
                                  }
                                ]
                              } />
                            </form>
                          </div>
                        </Collapse>
                        <div style={{
                          backgroundColor: darkMode ? "#192328" : "#ffffff",
                          borderRadius: "5px",
                          marginTop: "12px",
                          padding: "16px 12px 12px"
                        }}>
                          <CysrTableUsers data={
                            {
                              contractor: { item },
                              search: "",
                              hideCompleted: false,
                              tableTagsOptions: [],
                              nodes: editingContractorUsers,
                              tableTagsActive: userActiveTags,
                            }
                          }
                          />
                        </div>
                      </MDBox>
                    </>
                  }
                  <Divider />
                  <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '8px', width: "100%" }}>
                    <MDButton onClick={() => saveContractorEdited(item)} style={cysrConfig().button_style} size="medium">
                      <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().edit.icon.svg }} />
                      Save subscriber
                    </MDButton>
                    <MDButton onClick={() => deleteContractorEdited(item)} style={cysrConfig().button_style} size="medium">
                      <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().remove.icon.svg }} />
                      Remove subscriber
                    </MDButton>
                  </div>
                  {/*}
                  {tableDetailsRow(item.name, "Name", item.name)}
                  <Divider />
                  {tableDetailsRow(item.name, "Deadline", item.deadline, "date")}
                  <Divider />
                  {tableDetailsRow(item.name, "Type", item.generalasset_type)}
                  <Divider />
                  {tableDetailsRow(item.name, "Completed", item.isComplete.toString())}
                  {*/}
                </MDBox>
              </Card>
            </MDBox>
          </div>
        </div>
      </Collapse>
    ),
  };


  const materialTheme = getTheme(DEFAULT_OPTIONS, {
    isVirtualized: true
  });

  // to set row border bottom style:
  // border-bottom: 1px solid #${darkMode ? "4a4545" : "f0f2f5"};

  const customTheme = {
    Table: expandedTableStyle,
    BaseCell: `
      border: 0!important;
      padding: 4px;
    `,
  };
  let theme = useTheme([materialTheme, customTheme]);
  if (darkMode) {
    // provide custom theme
    theme = useTheme([materialTheme, {
      Table: expandedTableStyle,
      BaseCell: `
        color: #ffffff;
        background-color: #192328;
        border: 0!important;
        padding: 4px;
      `,
    }
    ]);
  }

  const pagination = usePagination(tData, {
    state: {
      page: 0,
      // size: 2,
      // size: tData.nodes.length,
      size: tableRowsPerPage[0],
    },
    onChange: onPaginationChange,
  });

  function onPaginationChange(action, state) {
    console.log(action, state);
  }

  const sort = useSort(
    tData,
    {
      state: {
        sortKey: 'RATING',
        reverse: false
      },
      onChange: onSortChange,
    },
    {
      sortIcon: {
        iconDefault: null,
        iconUp: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
        </svg>
        ,
        iconDown: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
        ,
      },
      sortFns: {
        STATUS: (array) => array.sort((a, b) => a.asset_status.localeCompare(b.asset_status)),
        ISPAYER: (array) => array.sort((a, b) => a.contractor_is_payer - b.contractor_is_payer),
        TYPE: (array) => array.sort((a, b) => a.generalasset_type.localeCompare(b.generalasset_type)),
        RATING: (array) => array.sort(function (a, b) {
          let aR = a.asset_last_rating && a.asset_last_rating.rating_val ? a.asset_last_rating.rating_val : "N/A";
          let bR = b.asset_last_rating && b.asset_last_rating.rating_val ? b.asset_last_rating.rating_val : "N/A";
          if (!aR || aR === null || aR === undefined || aR === "" || aR === "N/A") {
            aR = 101;
          }
          if (!bR || bR === null || bR === undefined || bR === "" || bR === "N/A") {
            bR = 101;
          }
          return aR - bR
        }),
        NAME: (array) => array.sort((a, b) => (a.name ? a.name : "").localeCompare(b.name ? b.name : "")),
        VAT: (array) => array.sort((a, b) => a.vat_name.localeCompare(b.vat_name)),
        VAT_NAME: (array) => array.sort((a, b) => (a.vat_name ? a.vat_name : "").localeCompare(b.vat_name ? b.vat_name : "")),
        VAT_COUNTRY: (array) => array.sort((a, b) => (a.vat_country_code ? a.vat_country_code : "").localeCompare(b.vat_country_code ? b.vat_country_code : "")),
        VAT_NUMBER: (array) => array.sort((a, b) => (a.vat_number ? a.vat_number : "").localeCompare(b.vat_number ? b.vat_number : "")),
        CREATED: (array) => array.sort((a, b) =>
          new Date(b.mu_created_at).getTime() - new Date(a.mu_created_at).getTime()
        ),
        UPDATED: (array) => array.sort((a, b) => (a.generalasset_lastcheck_datetime ? a.generalasset_lastcheck_datetime : "") - (b.generalasset_lastcheck_datetime ? b.generalasset_lastcheck_datetime : "")),
        SOURCE: (array) => array.sort((a, b) => (a.assetsrc_name ? a.assetsrc_name : "").localeCompare(b.assetsrc_name ? b.assetsrc_name : "")),
        // TYPE: (array) => array.sort((a, b) => a.type.localeCompare(b.type)),
        // COMPLETE: (array) => array.sort((a, b) => a.isComplete - b.isComplete),
        // TASKS: (array) => array.sort((a, b) => a.tasks || 0 - b.tasks || 0),
        // TASKS: (array) => array.sort((a, b) => (a.nodes || []).length - (b.nodes || []).length),
      },
    },
  );

  function onSortChange(action, state) {
    console.log(action, state);
  }

  const handleSetTableTags = (event, t) => {
    // console.log(t)
    setTableTagsActive(t);
    // replace url s
    let params = new URLSearchParams(window.location.search)
    params.delete('t')
    if (t != "" && t.length > 0) {
      params.append("t", Base64.encode(JSON.stringify(t)))
    }
    window.history.replaceState(null, '', '?' + params + window.location.hash)
    // replace url e
    // go to first page
    pagination.fns.onSetPage(0)
  };

  const VIRTUALIZED_OPTIONS = {
    rowHeight: (_item, _index) => {
      return 54
    }
  };

  return (
    <>
      <Stack spacing={10} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CysrTags tableTagsOptions={tableTagsOptions} tableTagsActive={tableTagsActive} handleSetTableTags={handleSetTableTags} darkMode={darkMode} />
          </Grid>
        </Grid>
      </Stack>
      <Paper style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none', height: 'auto' }}>
        {
          // <Paper style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none', height: '360px' }}>
        }
        <CompactTable
          columns={COLUMNS}
          // passando le virtualizedOptions non funziona l'expand abilitato, penso perchè imposta l'height da solo
          // virtualizedOptions={VIRTUALIZED_OPTIONS}
          data={tData}
          sort={sort}
          theme={theme}
          rowOptions={ROW_OPTIONS}
          layout={{
            isDiv: true,
            fixedHeader: true
          }}
          pagination={pagination}
          style={{ borderColor: 'transparent' }}
        // horizontalScroll={true}
        />
      </Paper>
      <Stack spacing={10}>
        <TablePagination
          component="div"
          style={{ color: darkMode ? "#ffffff" : "#000000", border: 0 }}
          count={tData.nodes.length}
          page={pagination.state.page}
          rowsPerPage={pagination.state.size}
          rowsPerPageOptions={tableRowsPerPage}
          onRowsPerPageChange={(event) =>
            pagination.fns.onSetSize(parseInt(event.target.value, 10))
          }
          onPageChange={(event, page) => pagination.fns.onSetPage(page)}
        />
      </Stack>
    </>
  );
};
export default Component;
/* eslint-disable */