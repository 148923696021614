/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Authentication layout components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import RenderMarkdown from "layouts/cysr/components/RenderMarkdown";

function Basic() {
  const pageTitle = "Terms and Conditions";
  document.title = `${pageTitle} - ${document.title.split(" - ")[1] ? document.title.split(" - ")[1] : document.title}`;
  return (
    <PageLayout image={bgImage}>
      <Card style={{ width: "800px", maxWidth: "80vw", margin: "32px auto" }}>
        <MDBox pt={2} pb={3} px={3}>
          <MDTypography variant="h4" fontWeight="medium" mb={2}>
            Terms and Conditions
          </MDTypography>
          <MDBox color="text" style={{ display: "flex", flexDirection: "column", fontSize: "14px", gap: "12px" }}>
            <p>This document outlines the terms of use and compliance with the <strong>General Data Protection Regulation (GDPR)</strong> regarding the processing of personal data of users on the Muscope|Risk platform. <strong>Muscope Cybersecurity S.r.l.</strong>, the operator of the Muscope|Risk platform, is fully committed to ensuring that all personal data is processed in accordance with GDPR requirements.</p>

            <MDTypography variant="h5" fontWeight="medium" mt={2}>Data Processing and Storage</MDTypography>
            <p>The personal data collected through the Muscope|Risk platform are processed by <strong>Muscope Cybersecurity S.r.l.</strong> and stored within the European Union. Muscope Cybersecurity S.r.l. implements appropriate technical and organizational measures to ensure the security, confidentiality, and integrity of personal data throughout the data lifecycle. For more details on how we handle personal data, please refer to our <strong>Privacy Policy</strong>, which is available at: <a href="https://www.muscope.com/privacy-policy/" target="_blank">https://www.muscope.com/privacy-policy</a>.</p>

            <MDTypography variant="h5" fontWeight="medium" mt={2}>User Responsibilities</MDTypography>
            <p>
            By creating an account and using the Muscope|Risk platform, the user agrees to:</p>
            <ul className="flex flex-col gap-1 list-disc list-inside marker:text-teal-500">
              <li>Use the platform exclusively for managing their own assets or those for which they have legal authorization.</li>
              <li>Not use the platform for any illegal activities or in a manner that infringes upon the rights of others.</li>
              <li>Maintain the confidentiality of any data obtained from the platform and not share or forward this data to unauthorized third parties.</li>
              <li>Avoid publicly disclosing any information obtained from the platform without proper authorization.</li>
            </ul>
            <p>The user acknowledges full responsibility for their use of the platform and any data derived from it. <strong>Muscope Cybersecurity S.r.l.</strong> disclaims any liability for the use of data outside the scope of the platform or in ways that violate these terms.</p>

            <MDTypography variant="h5" fontWeight="medium" mt={2}>Service Availability</MDTypography>
            <p>The Muscope|Risk platform may be subject to interruptions or deactivation at any time, without prior notice, at the discretion of <strong>Muscope Cybersecurity S.r.l.</strong> is not liable for any loss or damage resulting from service outages or platform unavailability.</p>

            <MDTypography variant="h5" fontWeight="medium" mt={2}>Limitation of Liability</MDTypography>
            <p>Muscope Cybersecurity S.r.l. will not be responsible for any indirect, incidental, or consequential damages arising from the use of the platform, including but not limited to, data loss, unauthorized data access, or service interruptions.</p>
            
            <MDTypography variant="h5" fontWeight="medium" mt={2}>Acceptance of Terms</MDTypography>
            <p>By creating an account and using the Muscope|Risk platform, the user confirms they have read, understood, and agreed to these <strong>Terms and Conditions</strong>. The use of the platform implies full acceptance of these terms, as well as the responsibilities and obligations outlined herein.</p>

            <p>For any further inquiries or concerns, please contact us at: <a href="mailto:dpo@muscope.com">dpo@muscope.com</a>.</p>
        </MDBox>
      </MDBox>
    </Card>
    </PageLayout >
  );
}

export default Basic;
/* eslint-disable */