
/* eslint-disable */
// prettier-ignore
"use client";
import MDButton from "components/MDButton";
import cysrConfig, { process } from "layouts/cysr/config";

export function DataTableRowExpand({
    row,
    rows,
}) {
    // const task = taskSchema.parse(row.original);

    return (
        <MDButton style={cysrConfig().button_style} size="medium" className="h-8" onClick={() => row.toggleExpanded()}>
            {row.getIsExpanded() ? (
                <svg
                    className="h-4 w-4"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M4 9H11L7.5 4.5L4 9Z" fill="currentColor"></path>
                </svg>
            ) : (
                <svg
                    className="h-4 w-4"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M4 6H11L7.5 10.5L4 6Z" fill="currentColor"></path>
                </svg>
            )}
        </MDButton>
    );
}
