/* eslint-disable */
// prettier-ignore
import { Link } from "react-router-dom";
import { Fragment, useState } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { styled } from '@mui/material/styles';
import cysrConfig from "layouts/cysr/config";
import RenderMarkdown from "layouts/cysr/components/RenderMarkdown"

export default function InfoButton(data) {
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };
    const buttonColor = localStorage.getItem("cysr_dark_mode") === "false" ? "#7B809A" : "#7B809A";
    const buttonHeight = "32px";
    const buttonWidth = "32px";
    const buttonInfoStyle = {
        borderRadius: "50%", backgroundColor: "transparent", color: buttonColor, height: buttonHeight, padding: "0", fontSize: "20px", fontWeight: "400", textTransform: "none", position: "absolute", right: "5px", top: "5px", margin: 0, minWidth: buttonWidth, minHeight: buttonHeight, zIndex: 9
    };
    const backColor = localStorage.getItem("cysr_dark_mode") === "false" ? "#ffffff" : "#000000";
    const borderColor = localStorage.getItem("cysr_dark_mode") === "false" ? "#232728" : "#ffffff";
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            // backgroundColor: '#f5f5f9',
            backgroundColor: backColor,
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 1200,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid ' + borderColor,
            padding: "12px",
            textAlign: "left"
        },
    }));
    // https://mui.com/material-ui/react-tooltip/
    return (
        <>
            {data && (data.cysr_info || data.cysr_info_link) && (
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div>
                        <HtmlTooltip
                            arrow={false}
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            placement="right"
                            title={
                                <Fragment>
                                    {data.cysr_info && (
                                        <MDTypography color="inherit" style={{ fontSize: "12px", width: "600px", maxWidth: "80vw" }}><RenderMarkdown>{data.cysr_info}</RenderMarkdown></MDTypography>
                                    )}
                                    {data.cysr_info_link && (
                                        data.cysr_info_link.startsWith("http") ? (
                                            <a href={data.cysr_info_link} style={{ display: "inline-block", marginTop: "8px", width: "600px", maxWidth: "80vw" }}>
                                                <MDButton style={cysrConfig().button_style} size="medium">
                                                    <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
                                                    Learn more
                                                </MDButton>
                                            </a>
                                        ) : (
                                            <Link to={data.cysr_info_link} style={{ display: "inline-block", marginTop: "8px", width: "600px", maxWidth: "80vw" }}>
                                                <MDButton style={cysrConfig().button_style} size="medium">
                                                    <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
                                                    Learn more
                                                </MDButton>
                                            </Link>
                                        )
                                    )}
                                </Fragment>
                            }
                        >
                            <MDButton onClick={handleTooltipOpen} type="button" style={buttonInfoStyle}>
                                <svg className="h-5 w-5" fill="currentColor" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="InfoSharpIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg>                            </MDButton>
                        </HtmlTooltip>
                    </div>
                </ClickAwayListener>
            )}
        </>
    );
}
/* eslint-disable */