/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import ScoreBadgeTextual from "layouts/cysr/components/scoreBadgeTextual";
import cysrConfig from "layouts/cysr/config";
/* eslint-disable */
export default function ScoreItem(score) {
  let scoreValue = score.scoreValue;
  let textual = false;
  let circle = false;
  let item_style = "default";
  if (score.textual) {
    textual = score.textual;
  }
  if (score.circle) {
    circle = score.circle;
  }
  if (score.item_style) {
    item_style = score.item_style;
  }
  try {
    if (!isNaN(scoreValue) && parseInt(scoreValue, 10) === Number(scoreValue)) {
      scoreValue = parseInt(score.scoreValue, 10);
    }
  } catch (error) {
    //nothing
    scoreValue = score.scoreValue;
  }
  let textValue = "N/A";
  if (isNaN(scoreValue)) {
    // n/a
  } else if (scoreValue < 30) {
    textValue = "Bad";
  } else if (scoreValue < 50) {
    textValue = "Poor";
  } else if (scoreValue < 70) {
    textValue = "Fair";
  } else if (scoreValue < 90) {
    textValue = "Good";
  } else if (scoreValue < 100 || scoreValue == 100) {
    textValue = "Excellent";
  }
  let scoreColor = "#000000";
  let scoreColorFound = false;
  cysrConfig().rating_colors.forEach(color => {
    if (!scoreColorFound) {
      if ((color.from === scoreValue || color.from < scoreValue) && (color.to === scoreValue || color.to > scoreValue)) {
        scoreColor = color.color;
        scoreColorFound = true;
      }
    }
  });
  return (
    textual ? (
      circle
        ?
        <>
          <ScoreBadgeTextual data={{ circle: true, value: textValue, color: scoreColor, badge_style: item_style }} />
        </>
        : <>
          <ScoreBadgeTextual data={{ value: textValue, color: scoreColor, badge_style: item_style }} />
        </>
    ) : (
      <MDTypography variant="button" style={{ color: scoreColor, fontWeight: 500, fontSize: 18 }}>{scoreValue}</MDTypography>
    )
  );
}
/* eslint-disable */
