/* eslint-disable */
import { openDB, deleteDB } from 'idb';

// companies s
/*
    {
        "account_uuid": "3454193e-5038-4199-9478-f6940d69686d",
        "account_name": "Buy a Gift",
        "account_is_third_party": false,
        "account_vat_country_code": "XX",
        "account_vat_address": null,
        "account_vat_city": null,
        "account_vat_name": null,
        "account_oldest_check_time_on_manual_asset": "2023-12-16T11:02:11.509797",
        "account_last_ratingACC_datetime": "2023-12-16T13:06:15.358118",
        "account_last_rating_val": 43,
        "account_tags": [],
        "account_data_national_id": null,
        "contractor_subscription": "4Securitas IE - MSSP",
        "linked_thirdparties": {},
        "modules": [
            "CTI",
            "CYSR"
        ]
    }
*/
async function openCompaniesDB() {
    return await openDB(
        // dbname
        'companies',
        // version
        1,
        // called only if the version number is higher than the current installed database version
        {
            upgrade(db) {
                // Create a store of objects
                const store = db.createObjectStore('companies', {
                    // The 'account_uuid' property of the object will be the key.
                    keyPath: 'account_uuid',
                    // If it isn't explicitly set, create a value by auto incrementing.
                    autoIncrement: false,
                });
                // Create an index on the 'account_last_rating_val' property of the objects.
                store.createIndex('account_last_rating_val', 'account_last_rating_val');
            },
        });
}
export function authorised() {
    return openCompaniesDB()
        .then(db => {
            const objectStoreNames = db.objectStoreNames;
            return objectStoreNames.contains('companies');
        });
}

function example_usage() {
    // get all DB
    getCompanies().then((z) => {
        console.warn('ok get companies indexed in DB')
        console.warn(z)
    }).catch((e) => {
        console.error('fail get companies indexed in DB')
        console.warn(e)
    })
    // get id DB
    // getCompany({ id: "774c8d89-4903-4c95-85ba-7be4e0deabc1" }).then((z) => {
    getCompany("774c8d89-4903-4c95-85ba-7be4e0deabc1").then((z) => {
        console.warn('ok get company indexed in DB')
        console.warn(z)
    }).catch((e) => {
        console.error('fail get company indexed in DB')
        console.warn(e)
    })
}

export async function removeCompaniesDB() {
    // Delete an entire IndexedDB database
    return await deleteDB('companies')
}

export async function updateCompanies({ data }) {
    const db = await openCompaniesDB();

    // Add multiple companies in one transaction:
    const tx = db.transaction('companies', 'readwrite');
    const transactionData = []
    data.forEach(c => {
        transactionData.push(
            // .put === Replaces any item with the same key.
            tx.store.put({
                account_uuid: c.account_uuid,
                account_name: c.account_name,
                account_is_third_party: c.account_is_third_party,
                account_vat_country_code: c.account_vat_country_code,
                account_vat_address: c.account_vat_address,
                account_vat_city: c.account_vat_city,
                account_vat_name: c.account_vat_name,
                account_oldest_check_time_on_manual_asset: c.account_oldest_check_time_on_manual_asset,
                account_last_ratingACC_datetime: c.account_last_ratingACC_datetime,
                account_last_rating_val: c.account_last_rating_val,
                account_tags: c.account_tags,
                account_data_national_id: c.account_data_national_id,
                contractor_subscription: c.contractor_subscription,
                account_has_starter_subscription: c.account_has_starter_subscription,
                linked_thirdparties: c.linked_thirdparties,
                modules: c.modules
            })
        )
    });
    transactionData.push(
        tx.done
    );
    await Promise.all(transactionData);
}
export async function getCompanies() {
    const db = await openCompaniesDB();
    //if (id === undefined) {
    // Get all the companies in account_last_rating_val order:
    return await db.getAllFromIndex('companies', 'account_last_rating_val');
    /*} else {
        // Get company with account_uuid === id
        return await db.get('companies', id);
    }*/
}
export async function getCompaniesCount() {
    const db = await openCompaniesDB();
    return await db.count('companies');
}
// export async function getCompany({ id }) {
export async function getCompany(id) {
    if (id === undefined) {
        return undefined
    }
    const db = await openCompaniesDB();
    // Get company with account_uuid === id
    return await db.get('companies', id);
}

// https://www.freecodecamp.org/news/a-quick-but-complete-guide-to-indexeddb-25f030425501/
async function demo() {
    const db = await openDB(
        // dbname
        'companies',
        // version
        1,
        // called only if the version number is higher than the current installed database version
        {
            upgrade(db) {
                // Create a store of objects
                const store = db.createObjectStore('companies', {
                    // The 'account_uuid' property of the object will be the key.
                    keyPath: 'account_uuid',
                    // If it isn't explicitly set, create a value by auto incrementing.
                    autoIncrement: false,
                });
                // Create an index on the 'date' property of the objects.
                store.createIndex('date', 'date');
            },
        });

    // Add an article:
    await db.add('companies', {
        title: 'Article 1',
        date: new Date('2019-01-01'),
        body: '…',
    });

    // Add multiple companies in one transaction:
    {
        const tx = db.transaction('companies', 'readwrite');
        await Promise.all([
            tx.store.add({
                title: 'Article 2',
                date: new Date('2019-01-01'),
                body: '…',
            }),
            tx.store.add({
                title: 'Article 3',
                date: new Date('2019-01-02'),
                body: '…',
            }),
            tx.done,
        ]);
    }

    // Get all the companies in date order:
    console.log(await db.getAllFromIndex('companies', 'date'));

    // Add 'And, happy new year!' to all companies on 2019-01-01:
    {
        const tx = db.transaction('companies', 'readwrite');
        const index = tx.store.index('date');

        for await (const cursor of index.iterate(new Date('2019-01-01'))) {
            const article = { ...cursor.value };
            article.body += ' And, happy new year!';
            cursor.update(article);
        }

        await tx.done;
    }
}
// companies e
/*
// This is very similar to localStorage, but async. If this is all you need, you may be interested in idb-keyval. You can always upgrade to this library later.
const dbPromise = openDB('keyval-store', 1, {
    upgrade(db) {
        db.createObjectStore('keyval');
    },
});
export async function get(key) {
    return (await dbPromise).get('keyval', key);
}
export async function set(key, val) {
    return (await dbPromise).put('keyval', val, key);
}
export async function del(key) {
    return (await dbPromise).delete('keyval', key);
}
export async function clear() {
    return (await dbPromise).clear('keyval');
}
export async function keys() {
    return (await dbPromise).getAllKeys('keyval');
}
*/