/* eslint-disable */
// prettier-ignore

import { LawRender } from "./law-render";
import { LawNavigatorListItem } from "./law-navigator-list-item";
import { useEffect, useMemo, useRef, useState } from "react";

export function RegulationContent({ lawHTML, items, setScrollToID, scrollToID }) {
    // create a `ref` to the Card component to which we want to scroll to.
    const [scrolledToID, setScrolledToID] = useState(null);
    // const scrollToRef = useRef(null);
    const navContainerRef = useRef(null);
    const scrollingContainerRef = useRef(null);


    useMemo(() => {
        // on changing law
        if (scrollToID != 'FIRST') {
            setScrollToID('FIRST')
        }
    }, [items]);

    useEffect(() => {
        const navbarHeight = 220 //96; // padding offset
        if (scrollToID && scrollToID === 'ZERO') {
            scrollingContainerRef.current.scrollTo({ top: 0 });
            setScrollToID(null); // Reset scrollToID after scrolling
        } else if (scrollToID === 'FIRST') {
            // scroll to first article
            if (items[0]?.id) {
                const scrollPosY = scrollingContainerRef.current.querySelector(`[id="${items[0]?.id}"`)?.offsetTop - navbarHeight;
                scrollingContainerRef.current.scrollTo({
                    behavior: 'instant',
                    top: scrollPosY
                });
                setScrolledToID(items[0]?.id)
            }
            // Reset scrollToID after scrolling
            setScrollToID(null);
        }
        //// else if (scrollToID && scrollToRef.current) {
        ////   setScrolledToID(scrollToID)
        ////   const scrollPosY = scrollToRef.current.offsetTop - navbarHeight;
        ////   scrollingContainerRef.current.scrollTo({
        ////     behavior: 'smooth',
        ////     top: scrollPosY
        ////   });
        ////   setScrollToID(null); // Reset scrollToID after scrolling
        else if (scrollToID) {
            setScrolledToID(scrollToID)
            //  const scrollPosY = document.getElementById(scrollToID)?.offsetTop - navbarHeight;
            const scrollPosY = scrollingContainerRef.current.querySelector(`[id="${scrollToID}"`)?.offsetTop - navbarHeight;
            scrollingContainerRef.current.scrollTo({
                behavior: 'smooth',
                top: scrollPosY
            });
            // change color
            /*
            if (scrollingContainerRef.current.querySelector(`[id="${scrollToID}"`)) {
              const beforeColor = scrollingContainerRef.current.querySelector(`[id="${scrollToID}"`).style.color;
              scrollingContainerRef.current.querySelector(`[id="${scrollToID}"`).style.color = 'rgb(20, 184, 166)'
              setTimeout(() => {
                if (scrollingContainerRef.current.querySelector(`[id="${scrollToID}"`)) {
                  scrollingContainerRef.current.querySelector(`[id="${scrollToID}"`).style.color = beforeColor;
                }
              }, 2500);
            }
            */
            setScrollToID(null); // Reset scrollToID after scrolling
        }
    }, [scrollToID]);

    return <div>
        <ul className="flex flex-wrap gap-0.5" ref={navContainerRef}>
            {items
                /*
                //items is already sorted before
                ?.sort((a, b) => {
                  return a.article_order - b.article_order;      
                })
                */
                ?.map((article, i) => (
                    <LawNavigatorListItem scrolledToID={scrolledToID} article={article} setScrollToID={setScrollToID} />
                ))}
        </ul>
        <div ref={scrollingContainerRef} className="py-4 flex flex-col gap-4 border-t border-tremor-border dark:border-dark-tremor-border" style={{
            minHeight: "320px",
            maxHeight: "calc(100vh - 465px)",
            overflowY: "auto"
        }}>
            {lawHTML ? <LawRender lawHTML={lawHTML} /> : null
            }
        </div>
    </div>
}