
/* eslint-disable */
"use client";

// https://www.chartjs.org/docs/latest/samples/advanced/linear-gradient.html
// https://react-chartjs-2.js.org/examples/gradient-chart
import { useRef, useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from "chart.js";
import 'chartjs-adapter-date-fns';
import { Line } from "react-chartjs-2";
import { format } from "date-fns";
import { process } from "layouts/cysr/config";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend
);
function createGradient(ctx, area) {
    let width, height, gradient;

    const chartWidth = area.right - area.left;
    const chartHeight = area.bottom - area.top;
    // if (!gradient || width !== chartWidth || height !== chartHeight) {
    // gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
    gradient = ctx.createLinearGradient(0, chartHeight, 0, 0);

    gradient.addColorStop(0, process.env.NEXT_PUBLIC_RATING_BAD_COLOR);
    gradient.addColorStop(process.env.NEXT_PUBLIC_RATING_POOR_FROM / 100, process.env.NEXT_PUBLIC_RATING_POOR_COLOR);
    gradient.addColorStop(process.env.NEXT_PUBLIC_RATING_FAIR_FROM / 100, process.env.NEXT_PUBLIC_RATING_FAIR_COLOR);
    gradient.addColorStop(process.env.NEXT_PUBLIC_RATING_GOOD_FROM / 100, process.env.NEXT_PUBLIC_RATING_GOOD_COLOR);
    gradient.addColorStop(1, process.env.NEXT_PUBLIC_RATING_EXCELLENT_COLOR);
    // }
    return gradient;
}

export function Timeline({ data, maxHeight = "400px", minHeight = "400px" }) {
    // const { resolvedTheme } = useTheme();
    const resolvedTheme = "light";
    const fontSize = 14;
    const timelineAspectRatio = "16 / 6";
    let timelineDataLabels = [];
    let timelineDataDatasetData = [];

    const chartRef = useRef(null);
    const [chartData, setChartData] = useState({
        datasets: [],
    });

    data.forEach((d, i) => {
        /*
        // avoid duplicates:
        if (data[i - 1] &&
            data[i - 1].value == d.value) {
            // do not push
        } else {
            */
        timelineDataLabels.push(
            format(
                new Date(d.date),
                process.env.NEXT_PUBLIC_DATE_FORMAT
                    ? process.env.NEXT_PUBLIC_DATE_FORMAT
                    : "dd/MM/yyyy"
            )
        );
        timelineDataDatasetData.push(d.value);
        // }
    });

    const timeLineData = {
        labels: timelineDataLabels,
        datasets: [
            {
                label: data && data[0] && data[0].group ? data[0].group : "Timeline",
                data: timelineDataDatasetData,
            },
        ],
    };

    useEffect(() => {
        const chart = chartRef.current;
        if (!chart) {
            return;
        }

        const chartData = {
            ...timeLineData,
            datasets: timeLineData.datasets.map((dataset) => ({
                ...dataset,
                borderColor: createGradient(chart.ctx, chart.chartArea),
            })),
        };
        setChartData(chartData);
    }, [data]);
    return (
        <div style={{
            aspectRatio: timelineAspectRatio,
            position: "relative",
            maxHeight: maxHeight,
            minHeight: minHeight,
            /*
            no more scroll...
            height: "100%",
            width: "auto",
            paddingBottom: 20
            */
            height: "100%",
            width: "100%",
        }}>
            <Line
                ref={chartRef}
                type="line"
                data={chartData ? chartData : null}
                options={{
                    // responsive: true,
                    maintainAspectRatio: false,
                    aspectRatio: timelineAspectRatio,
                    plugins: {
                        legend: {
                            labels: "none",
                        },
                    },
                    scales: {
                        // have two for y to have left and right
                        "left-y-axis":
                        {
                            suggestedMin: 0,
                            suggestedMax: 100,
                            ticks: {
                                // stepSize: 10,
                                font: { size: fontSize, }
                            },
                            position: "left"
                        },
                        "right-y-axis":
                        {
                            suggestedMin: 0,
                            suggestedMax: 100,
                            ticks: {
                                // stepSize: 10,
                                font: { size: fontSize, }
                            },
                            position: "right"
                        }
                        ,
                        x: {
                            ticks: {
                                font: { size: fontSize, color: "#cccccc" },
                            },
                            type: 'time',
                            time: {
                                parser: process.env.NEXT_PUBLIC_DATE_FORMAT
                                    ? process.env.NEXT_PUBLIC_DATE_FORMAT
                                    : "dd/MM/yyyy",
                                // unit: "quarter",
                                unit: (chartData && chartData.datasets && chartData.datasets.length < 2) ? "day" : "quarter",  // Adjust the unit based on the number of data points
                                tooltipFormat: process.env.NEXT_PUBLIC_DATE_FORMAT
                                    ? process.env.NEXT_PUBLIC_DATE_FORMAT
                                    : "dd/MM/yyyy"
                            }
                        },
                    },
                }}
            />
        </div>
    );
}
