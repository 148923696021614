/* eslint-disable */
import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default Example => {
  // static demoUrl = 'https://codesandbox.io/s/tiny-bar-chart-35meb';

    return (
        <div style={{background: "#ffffff", display: "block", textAlign: "center", position: "fixed", zIndex: "99999", left: "0", right: "0", top:"0", bottom:"0", padding:"0", overflow:"auto", height: "100%", width: "100%", minHeight: 650, minWidth: 650}}>
            <ResponsiveContainer isAnimationActive={false} width="100%" height="100%">
                <BarChart isAnimationActive={false} width={150} height={40} data={data}>
                    <Bar isAnimationActive={false} dataKey="uv" fill="#8884d8" />
                    <Bar isAnimationActive={false} dataKey="uv" fill="#ff0000" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}
