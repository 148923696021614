
/* eslint-disable */
"use client";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { process, getColor } from "layouts/cysr/config";
/*
import { useTheme } from "next-themes";
const colors = ["#e81c2b", "#f5881d", "#eace2a", "#0ca34f", "#459fe2"];
const cache = new Map();
let width = null;
let height = null;
*/
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export function CYSRCompanyRadar({ data, radarIndustryAverage, maxHeight = "400px", minHeight = "400px", darkMode, labels = null, hideDefaults = null }) {
  // const { resolvedTheme } = useTheme();
  const resolvedTheme = darkMode ? "dark" : "light";

  let radarData = {};
  let radarDataLabels = [];
  let radarDataDatasetData = [];
  let radarDataDatasetIndustryAverageData = [];
  let radarDataDatasets = [];
  const fontSize = 14;
  // const pointLabelColor = process.env.NEXT_PUBLIC_RATING_RADAR_TEXT_COLOR;
  const pointLabelColor = darkMode ? process.env.NEXT_PUBLIC_RATING_RADAR_DARK_TEXT_COLOR : process.env.NEXT_PUBLIC_RATING_RADAR_LIGHT_TEXT_COLOR;
  /*
  const stepsColor =
    resolvedTheme === "dark"
      ? "rgba(255, 255, 255, 0.25)"
      : "rgba(0, 0, 0, 0.25)";
  */
  const stepsColor = (ctx) => {
    const valueTickScore = ctx.tick ? ctx.tick.value : false;
    if (valueTickScore === false) {
      return resolvedTheme === "dark"
        ? "rgba(255, 255, 255, 0.25)"
        : "rgba(0, 0, 0, 0.25)";
    } else {
      // round to nearest 10
      return getColor(Math.round(valueTickScore / 10) * 10);
    }
  };
  // "inherit";
  // const datasetsOpacity = resolvedTheme === "dark" ? 0.65 : 0.44;
  const datasetsOpacity = 1;
  const datasetsBorderWidth = 2.5;
  // const ratingsDataAreaOpacity = resolvedTheme === "dark" ? 0.46 : 0.22;
  const ratingsDataAreaOpacity = 1;
  // const ratingsDataBorderOpacity = resolvedTheme === "dark" ? 1 : 0.4;
  // const ratingsDataBorderOpacity = resolvedTheme === "dark" ? 0.0 : 0.25;
  const ratingsDataBorderOpacity = 1;
  const ratingsDataBorderWidth = 1;
  let badData = [];
  let poorData = [];
  let fairData = [];
  let goodData = [];
  let excellentData = [];
  data.forEach((d) => {
    radarDataLabels.push(d.type);
    radarDataDatasetData.push(d.rating);
    if (radarIndustryAverage && radarIndustryAverage[0]) {
      const x = radarIndustryAverage.filter((ia) => ia.type === d.type)[0];
      if (x) {
        radarDataDatasetIndustryAverageData.push(x.rating);
      }
    }
  });
  let dataset = {
    // label: data[0].label,
    label: labels
      ? labels[0]
      : "This Company",
    data: radarDataDatasetData,
    // backgroundColor: resolvedTheme === "dark" ? `rgba(212, 187, 255, ${datasetsOpacity})` : `rgba(105, 41, 196, ${datasetsOpacity})`,
    backgroundColor: "transparent",
    // `rgba(224, 224, 224, ${datasetsOpacity})`,
    //  `hsla(${process.env.NEXT_PUBLIC_RATING_BAD_HSL}, ${datasetsOpacity})`,
    // : `rgba(31, 31, 31, ${datasetsOpacity})`,
    // borderColor: resolvedTheme === "dark" ? "#d4bbff" : "#6929c4",
    // ### borderColor: "white",
    borderColor: resolvedTheme === "dark" ? "#FFFFFF" : "#000000",
    // borderColor: resolvedTheme === "dark" ? "#d4bbff" : "#E0E0E0",
    // borderColor: resolvedTheme === "dark" ? "#d4bbff" : "#1F1F1F",
    //borderColor:      resolvedTheme === "dark"        ? "#d4bbff"        : process.env.NEXT_PUBLIC_RATING_BAD_COLOR,
    borderWidth: datasetsBorderWidth,
    pointRadius: 6,
    pointBackgroundColor: (ctx) => {
      const assetTypeScore = radarDataDatasetData[ctx.index];
      return getColor(assetTypeScore);
    },
    pointBorderColor: (ctx) => {
      const assetTypeScore = radarDataDatasetData[ctx.index];
      return getColor(assetTypeScore);
    },
    /*
          backgroundColor: function (context) {
            let c = colors[context.dataIndex];
            if (!c) {
              return;
            }
            if (context.active) {
              // c = helpers.getHoverColor(c);
            }
            // const mid = helpers.color(c).desaturate(0.2).darken(0.2).rgbString();
            // const start = helpers.color(c).lighten(0.2).rotate(270).rgbString();
            // const end = helpers.color(c).lighten(0.1).rgbString();
            return createRadialGradient3(
              context,
              colors[0],
              colors[1],
              colors[2],
              colors[3],
              colors[4]
            );
          },
        */
  };
  radarDataDatasets.push(dataset);
  // compared area
  if (
    radarDataDatasetIndustryAverageData &&
    radarDataDatasetIndustryAverageData[0]
  ) {
    dataset = {
      label: labels ? labels[1] : radarIndustryAverage[0].label
        ? radarIndustryAverage[0].label
        : "Industry Average",
      data: radarDataDatasetIndustryAverageData,
      borderColor: "transparent",
      // borderColor: "#D6D6D6",
      backgroundColor: resolvedTheme === "dark" ? process.env.NEXT_PUBLIC_RATING_RADAR_INDUSTRY_AVERAGE_COLOR_DARK : process.env.NEXT_PUBLIC_RATING_RADAR_INDUSTRY_AVERAGE_COLOR_LIGHT,
      // backgroundColor: resolvedTheme === "dark" ? `rgba(212, 187, 255, ${datasetsOpacity})` : // : `rgba(150, 214, 59, ${datasetsOpacity})`, // : `rgba(224, 224, 224, ${datasetsOpacity})`, // `rgba(13, 13, 13, ${datasetsOpacity})`, `rgba(230, 230, 230, ${datasetsOpacity})`,
      // borderColor: resolvedTheme === "dark" ? "#d4bbff" : "#8CA054",
      // borderColor: resolvedTheme === "dark" ? "#d4bbff" : "#1F1F1F",
      // borderColor: resolvedTheme === "dark" ? "#d4bbff" : "#777777",
      // borderColor: resolvedTheme === "dark" ? "#d4bbff" : "#E0E0E0",
      borderWidth: datasetsBorderWidth,
      pointRadius: 0,
      // hide by default
      hidden: hideDefaults ? hideDefaults[1] : true,
    };
    radarDataDatasets.push(dataset);
  }
  data.forEach(() => {
    badData.push(process.env.NEXT_PUBLIC_RATING_BAD_TO);
    poorData.push(process.env.NEXT_PUBLIC_RATING_POOR_TO);
    fairData.push(process.env.NEXT_PUBLIC_RATING_FAIR_TO);
    goodData.push(process.env.NEXT_PUBLIC_RATING_GOOD_TO);
    excellentData.push(process.env.NEXT_PUBLIC_RATING_EXCELLENT_TO);
  });

  const itNeedsLinesOfRating = false;
  if (itNeedsLinesOfRating) {
    radarDataDatasets.push({
      data: badData,
      // backgroundColor: `hsla(${process.env.NEXT_PUBLIC_RATING_BAD_HSL}, ${ratingsDataAreaOpacity})`,
      // backgroundColor: `hsla(${ resolvedTheme === "dark" ? process.env.NEXT_PUBLIC_RATING_BAD_HSL_DARK : // : process.env.NEXT_PUBLIC_RATING_BAD_HSL_LIGHT process.env.NEXT_PUBLIC_RATING_BAD_HSL }, ${ratingsDataAreaOpacity})`,
      backgroundColor: "transparent",
      borderColor: `hsla(${
    /*resolvedTheme === "dark"
      ? process.env.NEXT_PUBLIC_RATING_BAD_HSL_DARK
      :*/ process.env.NEXT_PUBLIC_RATING_BAD_HSL
        }, ${ratingsDataBorderOpacity})`,
      borderWidth: ratingsDataBorderWidth,
      label: "none",
      pointRadius: 0,
      animation: false,
    });
    radarDataDatasets.push({
      data: poorData,
      // backgroundColor: `hsla(${process.env.NEXT_PUBLIC_RATING_POOR_HSL}, ${ratingsDataAreaOpacity})`,
      // backgroundColor: `hsla(${ resolvedTheme === "dark" ? process.env.NEXT_PUBLIC_RATING_POOR_HSL_DARK : // : process.env.NEXT_PUBLIC_RATING_POOR_HSL_LIGHT process.env.NEXT_PUBLIC_RATING_POOR_HSL }, ${ratingsDataAreaOpacity})`,
      backgroundColor: "transparent",
      borderColor: `hsla(${
    /*
    resolvedTheme === "dark"
      ? process.env.NEXT_PUBLIC_RATING_POOR_HSL_DARK
      : */ process.env.NEXT_PUBLIC_RATING_POOR_HSL
        }, ${ratingsDataBorderOpacity})`,
      borderWidth: ratingsDataBorderWidth,
      label: "none",
      pointRadius: 0,
      animation: false,
    });
    radarDataDatasets.push({
      data: fairData,
      // backgroundColor: `hsla(${process.env.NEXT_PUBLIC_RATING_FAIR_HSL}, ${ratingsDataAreaOpacity})`,
      // backgroundColor: `hsla(${ resolvedTheme === "dark" ? process.env.NEXT_PUBLIC_RATING_FAIR_HSL_DARK : // : process.env.NEXT_PUBLIC_RATING_FAIR_HSL_LIGHT process.env.NEXT_PUBLIC_RATING_FAIR_HSL }, ${ratingsDataAreaOpacity})`,
      backgroundColor: "transparent",
      borderColor: `hsla(${
    /*
    resolvedTheme === "dark"
      ? process.env.NEXT_PUBLIC_RATING_FAIR_HSL_DARK
      : */ process.env.NEXT_PUBLIC_RATING_FAIR_HSL
        }, ${ratingsDataBorderOpacity})`,
      borderWidth: ratingsDataBorderWidth,
      label: "none",
      pointRadius: 0,
      animation: false,
    });
    radarDataDatasets.push({
      data: goodData,
      // backgroundColor: `hsla(${process.env.NEXT_PUBLIC_RATING_GOOD_HSL}, ${ratingsDataAreaOpacity})`,
      // backgroundColor: `hsla(${ resolvedTheme === "dark" ? process.env.NEXT_PUBLIC_RATING_GOOD_HSL_DARK : // : process.env.NEXT_PUBLIC_RATING_GOOD_HSL_LIGHT process.env.NEXT_PUBLIC_RATING_GOOD_HSL }, ${ratingsDataAreaOpacity})`,
      backgroundColor: "transparent",
      borderColor: `hsla(${
    /*
    resolvedTheme === "dark"
      ? process.env.NEXT_PUBLIC_RATING_GOOD_HSL_DARK
      : */ process.env.NEXT_PUBLIC_RATING_GOOD_HSL
        }, ${ratingsDataBorderOpacity})`,
      borderWidth: ratingsDataBorderWidth,
      label: "none",
      pointRadius: 0,
      animation: false,
    });
    radarDataDatasets.push({
      data: excellentData,
      // backgroundColor: `hsla(${process.env.NEXT_PUBLIC_RATING_EXCELLENT_HSL}, ${ratingsDataAreaOpacity})`,
      // backgroundColor: `hsla(${ resolvedTheme === "dark" ? process.env.NEXT_PUBLIC_RATING_EXCELLENT_HSL_DARK : // : process.env.NEXT_PUBLIC_RATING_EXCELLENT_HSL_LIGHT process.env.NEXT_PUBLIC_RATING_EXCELLENT_HSL }, ${ratingsDataAreaOpacity})`,
      backgroundColor: "transparent",
      borderColor: `hsla(${
    /*
    resolvedTheme === "dark"
      ? process.env.NEXT_PUBLIC_RATING_EXCELLENT_HSL_DARK
      : */ process.env.NEXT_PUBLIC_RATING_EXCELLENT_HSL
        }, ${ratingsDataBorderOpacity})`,
      borderWidth: ratingsDataBorderWidth,
      label: "none",
      pointRadius: 0,
      animation: false,
    });
  }
  radarData.labels = radarDataLabels;
  radarData.datasets = radarDataDatasets;

  return (
    <div
      style={{
        position: "relative",
        maxHeight: maxHeight,
        minHeight: minHeight,
        height: "100%",
        width: "100%",
      }}
    >
      <Radar
        data={radarData}
        options={{
          plugins: {
            legend: {
              labels: {
                // hide legend to ratingsData datasets (so to keep only backgrounds)
                filter: (item) => item.text !== "none",
                font: {
                  size: fontSize,
                },
                // color: process.env.NEXT_PUBLIC_RATING_RADAR_TEXT_COLOR,
                color: darkMode ? process.env.NEXT_PUBLIC_RATING_RADAR_DARK_TEXT_COLOR : process.env.NEXT_PUBLIC_RATING_RADAR_LIGHT_TEXT_COLOR,
              },
              align: "start",
              position: "top",
            },
          },
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            r: {
              angleLines: {
                color: stepsColor,
                // z: 1,
              },
              grid: {
                // here steps color
                color: stepsColor,
                // z: 1,
              },
              suggestedMin: 0,
              suggestedMax: 100,
              pointLabels: {
                color: (ctx) => {
                  const assetTypeScore = radarDataDatasetData[ctx.index];
                  // only if worse than fair s
                  return assetTypeScore <
                    process.env.NEXT_PUBLIC_RATING_FAIR_FROM
                    ? getColor(assetTypeScore)
                    : pointLabelColor;
                  // only if worse than fair e
                  return getColor(assetTypeScore);
                },
                font: {
                  size: fontSize,
                  // family: "var(--font-ubuntu)",
                  weight: (ctx) => {
                    const assetTypeScore = radarDataDatasetData[ctx.index];
                    // more weight only if worse than fair s
                    return assetTypeScore <
                      process.env.NEXT_PUBLIC_RATING_FAIR_FROM
                      ? "700"
                      : "400";
                    // more weight only if worse than fair e
                  },
                },
                padding: 20,
              },
              ticks: {
                display: false,
                stepSize: 10,
              },
            },
          },
        }}
      />
    </div>
  );
}