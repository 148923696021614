/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
// prettier-ignore
import { Base64 } from 'js-base64';
import React, { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
// import DataTable from "layouts/cysr/assets/DataTable";
import Collapse from "@mui/material/Collapse";

// Data
import InfoButton from "layouts/cysr/components/infoButton";
// import assetsData from "layouts/cysr/components/assetsDataTableData";
import { useLocation } from "react-router-dom";
// Asset creation
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import CysrTableAssets from "layouts/cysr/components/CysrTable/assets";
import { useMaterialUIController } from "context";

import cysrConfig from "layouts/cysr/config";
import CysrStepper from "layouts/cysr/components/CysrStepper";
import MultiAssetCreation from "./MultiAssetCreation";
// import MuscopeVirtualizedTable from "layouts/cysr/components/MuscopeVirtualizedTable/assets";
// ms

const App = function () {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  // filter test s
  const params = new URLSearchParams(window.location.search);
  let activeTags = [];
  try {
    activeTags = params.get('t') && (decodeURI(params.get('t'))) && Base64.decode(decodeURI(params.get('t'))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('t')))) : [];
  } catch (error) {
    console.error("Error in setting active tags:")
    console.error(error)
  }
  // filter test e
  const location = useLocation();
  const accountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/company/") + "/company/".length,
    window.location.pathname.lastIndexOf("/")
  );
  // Snackbars
  const [show, setShow] = useState(false);
  const [errorTitle, setErrorTitle] = useState("Asset not created");
  const [errorMsg, setErrorMsg] = useState("Check that all the fields are compiled correctly and try again");

  const defaultToggleSnackbar = () => {
    setShow(!show);
  }
  const toggleSnackbar = (title = errorTitle, msg = errorMsg) => {
    setErrorTitle(title);
    setErrorMsg(msg);
    setShow(true);
  }
  const [createOpen, setOpen] = useState(false);
  // asset creation states
  const [assetType, setAssetType] = useState("domain");
  const [assetValue, setAssetValue] = useState("");
  const [assetCreationLoading, setAssetCreationLoading] = useState(false);

  // add asset creation load
  const btnCreateAssetDefaultString = "Create asset";
  const [btnCreateAssetString, setStringBtnCreateAsset] = useState(btnCreateAssetDefaultString);

  function createAsset(event) {
    event.preventDefault();
    setShow(false);
    setAssetCreationLoading(true)
    setStringBtnCreateAsset('Creating asset...')
    // const t = event.currentTarget.elements.c_generalasset_type.value;
    const t = assetType;
    // const v = event.currentTarget.elements.c_generalasset_val.value;
    const v = assetValue;
    if (t && t !== "" && v && v !== "") {
      // create
      // {version}/account/{account_req_uuid}/asset/
      const createAssetUrl = `${cysrConfig().api_base_url}account/${accountUUID}/asset/`;
      const dataCreateAsset = {
        generalasset_type: t,
        generalasset_val: v,
        assetsrc_name: "manual",
        asset_status: "ENABLED",
        // ErrorMessage: 'Error', "ErrorDetail: ": `(1048, "Column 'generalasset_aggressivity' cannot be null")`
        generalasset_aggressivity: 5,
      };
      fetch(createAssetUrl, {
        body: JSON.stringify(dataCreateAsset),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
          "content-type": "application/json",
        },
        method: "POST",
      })
        .then((response) => {
          if (response.ok) {
            // response.json();
            window.location.reload();
          } else {
            return response.json().then(err => {
              throw JSON.stringify(err)
            })
          }
        })
        .catch((err) => {
          setStringBtnCreateAsset(btnCreateAssetDefaultString);
          console.error("err1:", err);
          toggleSnackbar(
            JSON.parse(err).ErrorMessage ? JSON.parse(err).ErrorMessage : JSON.parse(err).CheckStatus,
            JSON.parse(err).ErrorDetail ? JSON.parse(err).ErrorDetail : JSON.parse(err).CheckDetail
          );
          setAssetCreationLoading(false)
        });
    } else {
      // ask to fill fields
      setStringBtnCreateAsset(btnCreateAssetDefaultString);
      toggleSnackbar("Asset not created", "Check that all the fields are compiled correctly and try again");
      setAssetCreationLoading(false)
    }
  }
  // Our State.
  const [views, setViews] = useState([]);
  // const accountUUID = "8c75a44b-0faa-4e83-9bef-4ee47d21883a";
  // const accountUUID = window.location.pathname.substring(window.location.pathname.indexOf(":") + 1);
  // all assets:
  const callUrl = `${cysrConfig().api_base_url}account/${accountUUID}/asset/`;

  useEffect(() => {
    fetch(callUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code && data.code === "token_not_valid") {
          // remove localstorage and go to login
          const dMode = localStorage.getItem('cysr_dark_mode') ? localStorage.getItem('cysr_dark_mode') : "true";
          localStorage.clear();
          localStorage.setItem('cysr_dark_mode', dMode);
          window.location.href = `/cysr/login`;
          return
        }
        fetch(`${cysrConfig().api_base_url}assettype/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
          },
        })
          .then((assettypeResponse) => assettypeResponse.json())
          .then((assettypeData) => {
            const assettypeDataArray = [];
            if (assettypeData && assettypeData.length > 0) {
              assettypeData.forEach((assettype) => {
                if (assettype.assettype_longname !== "as" &&
                  assettype.assettype_longname !== "host" &&
                  assettype.assettype_longname !== "dns" &&
                  assettype.assettype_longname !== "email" &&
                  assettype.assettype_longname !== "network") {
                  assettypeDataArray.push(assettype.assettype_longname);
                }
              });
            }
            setViews({
              assets: data,
              assettypes: assettypeDataArray,
              useraccountrole_account: {
                account_uuid: accountUUID,
              },
              cysr_tags_active: activeTags
            });
          })
          .catch((aerr) => {
            console.error(aerr)
          });
      })
      .catch((err) => {
        console.error(err)
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar cysr_company />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <InfoButton cysr_info={cysrConfig().i("assets", "assets_list", "help")} cysr_info_link={cysrConfig().i("assets", "assets_list", "help_link")} />
            <MDBox py={5} px={3} fullWidth>
              <MDBox mb={1} fullWidth>
                <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  <div>
                    {cysrConfig().i("assets", "assets_list", "title") && (
                      <MDTypography variant="h5">{cysrConfig().i("assets", "assets_list", "title")}</MDTypography>
                    )}
                    {cysrConfig().i("assets", "assets_list", "sub_title") && (
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        {cysrConfig().i("assets", "assets_list", "sub_title")}
                      </MDTypography>
                    )}
                  </div>
                  {
                    // check views.assets > 0, altrimenti mostrare comunque il pulsante senza controllare feature
                  }
                  {
                    (
                      views.assets && views.assets.length > 0 && cysrConfig().feature_available("assets_add_new_asset")
                      ||
                      (!views.assets || views.assets && views.assets.length < 1)
                    ) &&
                    (
                      <MDButton
                        aria-label="expand row"
                        onClick={() => setOpen(!createOpen)} style={cysrConfig().button_style} size="medium">
                        {createOpen ? <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} /> : <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />
                        }
                        Add an asset
                      </MDButton>
                    )}
                </MDBox>
                {cysrConfig().feature_available("assets_add_new_asset") && (
                  <Collapse in={createOpen} style={{ marginTop: ".5em" }} timeout="auto" unmountOnExit>
                    <div className={`${darkMode ? "dark" : "light"}`}>
                      <div className="mt-2 bg-zinc-100 dark:bg-zinc-900 rounded-xl border-zinc-100 dark:border-zinc-700 border-b border-t">
                        <form onSubmit={createAsset}>
                          <CysrStepper darkMode isLoading={assetCreationLoading} steps={
                            [
                              {
                                label: 'Select the type of the asset',
                                value: assetType + " " + assetValue,
                                content: <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <MultiAssetCreation darkMode={darkMode} accountUUID={accountUUID} types={views.assettypes} />
                                  </Grid>
                                  <Grid item xs={6} lg={3}>
                                    {views && views.assettypes && (
                                      <Autocomplete
                                        defaultValue={assetType}
                                        onChange={(e, x) => {
                                          setAssetType(x);
                                        }}
                                        size="small"
                                        // label="generalasset_type"
                                        id="c_generalasset_type"
                                        options={views.assettypes}
                                        renderInput={(params) => <MDInput
                                          sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#232728' : '#ffffff' }}
                                          size="small" label="Type" {...params} />}
                                      />
                                    )}
                                  </Grid>
                                  <Grid item xs={6} lg={6}>
                                    <MDInput
                                      fullWidth
                                      defaultValue={assetValue}
                                      sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#232728' : '#ffffff' }}
                                      onChange={(e) => {

                                        setTimeout(() => {
                                          if (assetValue != e.target.value) {
                                            setAssetValue(e.target.value);
                                          }
                                        }, 200);
                                      }}
                                      size="small"
                                      label="Value"
                                      id="c_generalasset_val"
                                    />
                                  </Grid>
                                </Grid>,
                                submitIcon: "add",
                                submitText: btnCreateAssetString
                              },
                              /*
                              {
                                label: 'Asset value',
                                value: assetValue,
                                content: 
                                <Grid container spacing={1}>
                                  <Grid item xs={6} lg={6}>
                                    <MDInput
                                      fullWidth
                                      defaultValue={assetValue}
                                      sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#232728' : '#ffffff' }}
                                      onChange={(e) => {
                                        setAssetValue(e.target.value);
                                      }}
                                      size="small"
                                      label="Value"
                                      id="c_generalasset_val"
                                    />
                                  </Grid>
                                </Grid>,
                                submitIcon: "add",
                                submitText: btnCreateAssetString
                              },
                              */
                            ]
                          } />
                        </form>
                      </div>
                    </div>
                    {false && <Card style={{ backgroundColor: darkMode ? "#121619" : "#edf0f2", color: darkMode ? "#7B809A" : "#7B809A", borderRadius: 0, boxShadow: "none", padding: "1em", margin: 0 }}>
                      <MDBox width="100%">
                        <form onSubmit={createAsset}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} lg={3}>
                              {views && views.assettypes && (
                                <Autocomplete
                                  // defaultValue="domain"
                                  // label="generalasset_type"
                                  id="c_generalasset_type"
                                  size="small"
                                  options={views.assettypes}
                                  renderInput={(params) => <MDInput size="small" label="Type" {...params} />}
                                  fullWidth
                                />
                              )}
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <MDInput
                                fullWidth
                                size="small"
                                label="Value"
                                id="c_generalasset_val"
                              />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                              {
                                /*
                              }
                              <MDButton fullWidth size="small" color="dark" type="submit">
                                Create Asset
                              </MDButton>
                              {
                                */
                              }
                              <MDButton fullWidth type="submit" {...(btnCreateAssetString != btnCreateAssetDefaultString && { disabled: true })} style={cysrConfig().button_style} size="medium">
                                {btnCreateAssetString != btnCreateAssetDefaultString ? (
                                  /*
                                  <div style={{height: "20px", width: "20px", marginRight: "8px"}}><Loader /></div>
                                  */
                                  <span style={cysrConfig().button_icon_left_style} className="spin" dangerouslySetInnerHTML={{ __html: cysrConfig().loading.icon.svg }} />
                                ) : (
                                  <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().add.icon.svg }} />
                                )}{btnCreateAssetString}</MDButton>
                              {
                                /*
                              }
                              <MDButton fullWidth size="small" style={cysrConfig().button_icon_left_style} type="submit" {...(btnCreateAssetString !== btnCreateAssetDefaultString && {disabled: true})}>{btnCreateAssetString !== btnCreateAssetDefaultString && (
                                  <div style={{height: "20px", width: "20px", marginRight: "8px"}}><Loader /></div>
                              )}{btnCreateAssetString}</MDButton>
                              {
                                */
                              }
                            </Grid>
                          </Grid>
                        </form>
                      </MDBox>
                    </Card>
                    }
                    <MDSnackbar
                      color="error"
                      icon="error"
                      title={errorTitle}
                      content={errorMsg}
                      dateTime="Error"
                      open={show}
                      close={defaultToggleSnackbar}
                    />
                  </Collapse>
                )}
                {
                  // <DataTable entriesPerPage={{ defaultValue: 50, entries: [25, 50, 100, 200, 400] }} table={assetsData(views)} canSearch />
                }
                {views && views.assets ?
                  <MDBox pt={5} px={0} fullWidth>
                    {
                      /*
                      false && <MuscopeVirtualizedTable
                        darkMode={darkMode}
                        defaultData={views.assets}
                        defaultTags={views.cysr_tags_active}
                        search={params.get('s') ? params.get('s') : ""}
                      />
                      */
                    }

                    <CysrTableAssets
                      defaultData={views.assets}
                      defaultSearch={params.get('s') ? params.get('s') : ""}
                      defaultTagsActive={views.cysr_tags_active}
                      defaultTagsOptions={[
                        { "rating": "Excellent" },
                        { "rating": "Good" },
                        { "rating": "Poor" },
                        { "rating": "Fair" },
                        { "rating": "Bad" }
                      ]}
                    />
                  </MDBox>
                  : null
                }
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};
export default App;

/* eslint-disable */