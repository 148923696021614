import React, { useEffect, useState, useRef } from "react";
import { Network } from "vis-network";
import Grid from "@mui/material/Grid";
import MDSnackbar from "components/MDSnackbar";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
// import Icon from '@mui/material/Icon';
import StatusItem from "layouts/cysr/components/StatusItem";
import ScoreItem from "layouts/cysr/components/ScoreItem";
import cysrConfig from "layouts/cysr/config";
import MDBadgeDot from "components/MDBadgeDot";
/* eslint-disable */
export default function GraphNetwork(data) {
  const [show, setShow] = useState(false);
  const container = useRef(null);
  const nodesData = data.data.nodes;
  const edges = data.data.arrows;
  const toggleSnackbar = () => setShow(!show);
  // edit colors in /layouts/cysr/config.js
  const groupColors = [
    cysrConfig().styles.domain.status.enabled.color.default,
    cysrConfig().styles.host.status.enabled.color.default,
    cysrConfig().styles.ip.status.enabled.color.default,
    cysrConfig().styles.website.status.enabled.color.default,
    cysrConfig().styles.mail.status.enabled.color.default
  ];
  const groupColorsHighlight = [
    cysrConfig().styles.domain.status.enabled.color.highlight,
    cysrConfig().styles.host.status.enabled.color.highlight,
    cysrConfig().styles.ip.status.enabled.color.highlight,
    cysrConfig().styles.website.status.enabled.color.highlight,
    cysrConfig().styles.mail.status.enabled.color.highlight
  ];
  const groupColorsDisabled = [
    cysrConfig().styles.domain.status.disabled.color.default,
    cysrConfig().styles.host.status.disabled.color.default,
    cysrConfig().styles.ip.status.disabled.color.default,
    cysrConfig().styles.website.status.disabled.color.default,
    cysrConfig().styles.mail.status.disabled.color.default
  ];
  const groupColorsDisabledHighlight = [
    cysrConfig().styles.domain.status.disabled.color.highlight,
    cysrConfig().styles.host.status.disabled.color.highlight,
    cysrConfig().styles.ip.status.disabled.color.highlight,
    cysrConfig().styles.website.status.disabled.color.highlight,
    cysrConfig().styles.mail.status.disabled.color.highlight
  ];
  const groupColorsAcknowledged = [
    cysrConfig().styles.domain.status.acknowledged.color.default,
    cysrConfig().styles.host.status.acknowledged.color.default,
    cysrConfig().styles.ip.status.acknowledged.color.default,
    cysrConfig().styles.website.status.acknowledged.color.default,
    cysrConfig().styles.mail.status.acknowledged.color.default
  ];
  const groupColorsAcknowledgedHighlight = [
    cysrConfig().styles.domain.status.acknowledged.color.highlight,
    cysrConfig().styles.host.status.acknowledged.color.highlight,
    cysrConfig().styles.ip.status.acknowledged.color.highlight,
    cysrConfig().styles.website.status.acknowledged.color.highlight,
    cysrConfig().styles.mail.status.acknowledged.color.highlight
  ];
  const accountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/company/") + "/company/".length,
    window.location.pathname.lastIndexOf("/")
  );
  const navigationStyles = `div.vis-network div.vis-navigation {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
  }
  div.vis-network div.vis-navigation div.vis-button.vis-up, 
  div.vis-network div.vis-navigation div.vis-button.vis-down, 
  div.vis-network div.vis-navigation div.vis-button.vis-left, 
  div.vis-network div.vis-navigation div.vis-button.vis-right, 
  div.vis-network div.vis-navigation div.vis-button.vis-zoomIn, 
  div.vis-network div.vis-navigation div.vis-button.vis-zoomOut, 
  div.vis-network div.vis-navigation div.vis-button.vis-zoomExtends {
    background-image: none !important;
  }
  
  div.vis-network div.vis-navigation div.vis-button:hover {
    box-shadow: none !important;
  }
  
  .vis-button:after {
    font-size: 2em;
    color: gray;
  }
  
  .vis-button:hover:after {
    font-size: 2em;
    color: lightgray;
  }
  /*
  .vis-button.vis-up:after {
    content: "▲";
  }
  
  .vis-button.vis-down:after {
    content: "▼";
  }
  
  .vis-button.vis-left:after {
    content: "◀";
  }
  
  .vis-button.vis-right:after {
    content: "▶";
  }
  */
  
  .vis-button.vis-zoomIn:after {
    content: "+";
    font-weight: bold;
  }
  
  .vis-button.vis-zoomOut:after {
    content: "−";
    font-weight: bold;
  }
  
  .vis-button.vis-zoomExtends:after {
    content: "⟲";
  }`;
  const options = {
    autoResize: true,
    height: '100%',
    width: '100%',
    interaction: {
      navigationButtons: true,
      zoomView: false,
    },
    /*
    edges: {
      smooth: false
    },
*/
    edges: {
      //arrowStrikethrough: false,
      physics: false,
      color: {
        // inherit: "from",
        // inherit: "to",
        inherit: "both",
      },
      width: 1.3,
      smooth: {
        type: "cubicBezier",
        roundness: 0.5,
        forceDirection: true,
      },
    },
    /*physics: false,*/
    physics: {
      barnesHut: {
        "gravitationalConstant": -3000,
        "avoidOverlap": 0.5,
        "damping": 0.5,
        "springConstant": 0,
        "springLength": 15,
        "theta": 0.5,
      },
      stabilization: {
        enabled: false,
        iterations: 100,
        onlyDynamicEdges: true,
        fit: false
      },
    },

    nodes: {
      borderWidth: 0,
      font: {
        color: "#000000",
        face: "Ubuntu",
        size: 14,
      },
    }
  }
  const opt = {
    /*
    physics: {
      barnesHut: {
        "gravitationalConstant": -1150,
        "avoidOverlap": 0.5,
        "damping": 0.5,
        "springConstant": 0,
        "springLength": 20,
      },
    },
    */
    autoResize: true,
    height: '100%',
    width: '100%',
    interaction: {
      navigationButtons: true,
      zoomView: false,
    },
    nodes: {
      borderWidth: 0,
      font: {
        color: "#000000",
        face: "Ubuntu",
        size: 14,
      },
      shapeProperties: {
        borderRadius: 0,
        useImageSize: true,
      },
      scaling: {
        min: 10,
        max: 30,
        label: {
          enabled: true,
        }
      },
      // heightConstraint: 10,
      // widthConstraint: 10,
    },
    /*
    edges: {
      //arrowStrikethrough: false,
      physics: false,
      color: {
        inherit: "from",
      },
      width: 0.75,
      smooth: {
        type: "cubicBezier",
        roundness: 0.5,
        forceDirection: true,
      },
    },
    */
    groups: {
      domain: {
        color: {
          border: groupColors[0],
          background: groupColors[0],
          highlight: {
            border: groupColorsHighlight[0],
            background: groupColorsHighlight[0],
          },
        }
      },
      host: {
        color: {
          border: groupColors[1],
          background: groupColors[1],
          highlight: {
            border: groupColorsHighlight[1],
            background: groupColorsHighlight[1],
          },
        }
      },
      ip: {
        color: {
          border: groupColors[2],
          background: groupColors[2],
          highlight: {
            border: groupColorsHighlight[2],
            background: groupColorsHighlight[2],
          },
        }
      },
      website: {
        color: {
          border: groupColors[3],
          background: groupColors[3],
          highlight: {
            border: groupColorsHighlight[3],
            background: groupColorsHighlight[3],
          },
        }
      },
      mail: {
        color: {
          border: groupColors[4],
          background: groupColors[4],
          highlight: {
            border: groupColorsHighlight[4],
            background: groupColorsHighlight[4],
          },
        }
      },
      as: {
        color: {
          border: groupColors[5],
          background: groupColors[5],
          highlight: {
            border: groupColorsHighlight[5],
            background: groupColorsHighlight[5],
          },
        }
      },
      network: {
        color: {
          border: groupColors[6],
          background: groupColors[6],
          highlight: {
            border: groupColorsHighlight[6],
            background: groupColorsHighlight[6],
          },
        }
      },
      domain__disabled: {
        color: {
          border: groupColorsDisabled[0],
          background: groupColorsDisabled[0],
          highlight: {
            border: groupColorsDisabledHighlight[0],
            background: groupColorsDisabledHighlight[0],
          },
        }
      },
      host__disabled: {
        color: {
          border: groupColorsDisabled[1],
          background: groupColorsDisabled[1],
          highlight: {
            border: groupColorsDisabledHighlight[1],
            background: groupColorsDisabledHighlight[1],
          },
        }
      },
      ip__disabled: {
        color: {
          border: groupColorsDisabled[2],
          background: groupColorsDisabled[2],
          highlight: {
            border: groupColorsDisabledHighlight[2],
            background: groupColorsDisabledHighlight[2],
          },
        }
      },
      website__disabled: {
        color: {
          border: groupColorsDisabled[3],
          background: groupColorsDisabled[3],
          highlight: {
            border: groupColorsDisabledHighlight[3],
            background: groupColorsDisabledHighlight[3],
          },
        }
      },
      mail__disabled: {
        color: {
          border: groupColorsDisabled[4],
          background: groupColorsDisabled[4],
          highlight: {
            border: groupColorsDisabledHighlight[4],
            background: groupColorsDisabledHighlight[4],
          },
        }
      },
      domain__acknowledged: {
        color: {
          border: groupColorsAcknowledged[0],
          background: groupColorsAcknowledged[0],
          highlight: {
            border: groupColorsAcknowledgedHighlight[0],
            background: groupColorsAcknowledgedHighlight[0],
          },
        }
      },
      host__acknowledged: {
        color: {
          border: groupColorsAcknowledged[1],
          background: groupColorsAcknowledged[1],
          highlight: {
            border: groupColorsAcknowledgedHighlight[1],
            background: groupColorsAcknowledgedHighlight[1],
          },
        }
      },
      ip__acknowledged: {
        color: {
          border: groupColorsAcknowledged[2],
          background: groupColorsAcknowledged[2],
          highlight: {
            border: groupColorsAcknowledgedHighlight[2],
            background: groupColorsAcknowledgedHighlight[2],
          },
        }
      },
      website__acknowledged: {
        color: {
          border: groupColorsAcknowledged[3],
          background: groupColorsAcknowledged[3],
          highlight: {
            border: groupColorsAcknowledgedHighlight[3],
            background: groupColorsAcknowledgedHighlight[3],
          },
        }
      },
      mail__acknowledged: {
        color: {
          border: groupColorsAcknowledged[4],
          background: groupColorsAcknowledged[4],
          highlight: {
            border: groupColorsAcknowledgedHighlight[4],
            background: groupColorsAcknowledgedHighlight[4],
          },
        }
      },
    },
  };
  let nodes = [];
  console.warn(nodesData);
  nodesData.forEach(n => {
    if (n) {
      const node = n;
      let label = "";
      if (n.label) {
        label = n.label;
      }
      let status = "DISABLED";
      if (n.status) {
        status = n.status;
      }
      let depth = "0";
      if (n.depth) {
        depth = n.depth;
      }
      let origin = "checker";
      if (n.origin) {
        origin = n.origin;
      }
      let type = "domain";
      if (n.group) {
        if (n.group.indexOf("_") > - 1) {
          type = n.group.split("_")[0];
        } else {
          type = n.group;
        }
      }
      const fill = cysrConfig().get_color_by_type(type);
      let ratingFill = "#cccccc";
      let scoreValue = 0;
      if (n.rating_val) {
        scoreValue = n.rating_val;
        // take border-color from rating value s
        let scoreColor = "#eeeeee";
        let scoreColorFound = false;
        cysrConfig().rating_colors.forEach(color => {
          if (!scoreColorFound) {
            if ((color.from === scoreValue || color.from < scoreValue) && (color.to === scoreValue || color.to > scoreValue)) {
              scoreColor = color.color;
              scoreColorFound = true;
            }
          }
        });
        ratingFill = scoreColor;
        // take border-color from rating value s
      }

      // var monospacedWidth = label.length * 14 + 15;
      // let monospacedWidth = label.length * 15 + 80;
      let monospacedWidth = label.length * 13 + 85;
      if (monospacedWidth < 260) {
        monospacedWidth = 260;
      }
      let svg = '<svg xmlns="http://www.w3.org/2000/svg" width="' + monospacedWidth + '" height="68"><rect x="0"' +
        ' y="0" width="100%" height="100%" fill="transparent" style="stroke-width:0; stroke:' + ratingFill + ';"></rect><foreignObject x="4" y="4" width="calc(100% - 8px)" height="calc(100% - 8px)"' +
        ' style="flex-direction:column;display:flex;justify-content:center;align-items:center;"><div' +
        ' xmlns="http://www.w3.org/1999/xhtml" style="padding-left: 80px;position:relative;background:' + fill + ';padding-top:8px;padding-right:8px;padding-bottom:8px;display:inline-flex;flex-direction:column;justify-content:center;margin:auto;color:#000000;font-size:20px;font-family:\'Jetbrains Mono\', monospace;font-weight:800;">';

      // svg += '<div style="font-size:22px;color:black;position:absolute;left:0;bottom:0;top:0;height:100%;min-width:50px;padding-left:8px;padding-right:8px;display:inline-flex;justify-content:center;align-items:center;background:' + ratingFill + ';">' + scoreValue + '</div>';
      svg += '<div style="font-size:22px;color:white;position:absolute;left:0;bottom:0;top:0;height:100%;min-width:50px;padding-left:8px;padding-right:8px;display:inline-flex;justify-content:center;align-items:center;background:' + ratingFill + ';">' + scoreValue + '</div>';
      svg += '<div style="display:flex;flex-direction:row;text-align:left;gap:8px;">';
      /*
      svg += cysrConfig().styles[type].svg;
      svg += ' '+cysrConfig().origin[origin].svg;
      svg += ' '+cysrConfig().get_status(status.toUpperCase()).svg;
      */
      svg += '<span>' + type + '</span>';
      svg += '<b style="opacity:.75;font-family:\'Ubuntu Mono\';">(' + depth + ')</b> ';
      svg += origin[0].toUpperCase();
      svg += '<span style="display:inline-flex;width:20px;padding-right:8px;">' + cysrConfig().get_status(status.toUpperCase()) && cysrConfig().get_status(status.toUpperCase()).svg ? cysrConfig().get_status(status.toUpperCase()).svg : "" + '</span>';
      svg += '</div><div style="text-align:left;width:100%;display:flex;"><span style="text-align:left;word-break:break-all;">' + label + '</span></div>';
      svg += '</div></foreignObject></svg>';
      if (svg) {
        const url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg);
        if (node.color) {
          node.color.border = ratingFill;
          node.color.highlight.border = ratingFill;
        } else {
          node.color = {
            border: ratingFill,
            highlight: {
              border: ratingFill
            }
          };
        }
        node.image = url;
        node.font = {
          color: "transparent"
        };
        node.shape = "image";
        nodes.push(node);
      }
    }
  });
  useEffect(() => {
    const network = container.current && new Network(container.current, { nodes, edges }, options);
    network.on("doubleClick", function (params) {
      params.event = "[original event]";
      setShow(false);
      const assetId = params.nodes[0];
      if (!assetId) {
        return;
      }
      let currentAsset = {};
      window.snackUrl = `/company/${accountUUID}/asset#${assetId}`;
      nodes.forEach(a => {
        if (a.id === assetId) {
          currentAsset = a;
        }
      });
      // window.snackTitle = `Want to go to ${currentAsset.title}?`;
      window.snackLabel = currentAsset.label;
      window.snackTitle = currentAsset.title;
      window.snackDepth = currentAsset.depth;
      window.snackStatus = currentAsset.status;
      window.snackOrigin = currentAsset.origin;
      /*
      window.snackRatingCheckDate = currentAsset.check_date;
      try {
        const d = new Date(window.snackRatingCheckDate);
        if (d instanceof Date && !isNaN(d.valueOf())) {
          window.snackRatingCheckDate = `last check on ${d.toLocaleDateString("it-IT")} ${d.toLocaleTimeString('it-IT')} UTC`;
        } else {
          window.snackRatingCheckDate = `last check not available`;
        }
      } catch (error) {
        window.snackRatingCheckDate = `last check not available`;
      }
      */
      window.snackType = currentAsset.group.split("_")[0];
      if (currentAsset.rating_val && currentAsset.rating_val != "n/a" && currentAsset.rating_val != "N/A") {
        window.snackRatingVal = currentAsset.rating_val;
      } else {
        window.snackRatingVal = null;
      }
      window.snackIconType = cysrConfig().styles[window.snackType] && cysrConfig().styles[window.snackType].svg ? cysrConfig().styles[window.snackType].svg : "";
      window.snackIconOrigin = cysrConfig().origin[window.snackOrigin] && cysrConfig().origin[window.snackOrigin].svg ? cysrConfig().origin[window.snackOrigin].svg : "";
      window.snackIconStatus = cysrConfig().get_status(window.snackStatus.toUpperCase()) && cysrConfig().get_status(window.snackStatus.toUpperCase()).svg ? cysrConfig().get_status(window.snackStatus.toUpperCase()).svg : "";
      setShow(true);
    });

    // fullscreen s
    var fullscreenEnabled = !document.location.hash.match(/^#nofullscreen/);

    if (fullscreenEnabled) {
      setTimeout(function () {
        const button = document.createElement("button");
        document.getElementById("gg-viz").parentNode.appendChild(button);
        button.setAttribute("id", "fullscreen");
        button.setAttribute("style", "cursor:pointer;");
        button.innerHTML = "Fullscreen";
        button.addEventListener("click", goFullscreen);
      }, 200);
    }

    function goFullscreen() {
      this._exitFired = false;
      if (fullScreenApi.supportsFullScreen) {
        if (fullScreenApi.isFullScreen(document.getElementById("gg-viz"))) {
          fullScreenApi.cancelFullScreen(document.getElementById("gg-viz"));
        } else {
          fullScreenApi.requestFullScreen(document.getElementById("gg-viz"));
        }
        setTimeout(redraw, 200);
      }
    }

    /*
    Native FullScreen JavaScript API
    -------------
    Assumes Mozilla naming conventions instead of W3C for now
    
    source : http://johndyer.name/native-fullscreen-javascript-api-plus-jquery-plugin/
    
    */

    (function () {
      var fullScreenApi = {
        supportsFullScreen: false,
        isFullScreen: function () {
          return false;
        },
        requestFullScreen: function () { },
        cancelFullScreen: function () { },
        fullScreenEventName: "",
        prefix: ""
      },
        browserPrefixes = "webkit moz o ms khtml".split(" ");

      // check for native support
      if (typeof document.cancelFullScreen != "undefined") {
        fullScreenApi.supportsFullScreen = true;
      } else {
        // check for fullscreen support by vendor prefix
        for (var i = 0, il = browserPrefixes.length; i < il; i++) {
          fullScreenApi.prefix = browserPrefixes[i];
          if (
            typeof document[fullScreenApi.prefix + "CancelFullScreen"] !=
            "undefined"
          ) {
            fullScreenApi.supportsFullScreen = true;

            break;
          }
        }
      }

      // update methods to do something useful
      if (fullScreenApi.supportsFullScreen) {
        fullScreenApi.fullScreenEventName =
          fullScreenApi.prefix + "fullscreenchange";

        fullScreenApi.isFullScreen = function () {
          switch (this.prefix) {
            case "":
              return document.fullScreen;
            case "webkit":
              return document.webkitIsFullScreen;
            default:
              return document[this.prefix + "FullScreen"];
          }
        };
        fullScreenApi.requestFullScreen = function (el) {
          return this.prefix === ""
            ? el.requestFullScreen()
            : el[this.prefix + "RequestFullScreen"]();
        };
        fullScreenApi.cancelFullScreen = function (el) {
          return this.prefix === ""
            ? document.cancelFullScreen()
            : document[this.prefix + "CancelFullScreen"]();
        };

        document.addEventListener(fullScreenApi.fullScreenEventName, function () {
          if (fullScreenApi.isFullScreen(document.body)) {
            document.getElementById("fullscreen").innerHTML = "&times;";
          } else {
            document.getElementById("fullscreen").innerHTML = "Fullscreen";
          }
        });
      }

      // export api
      window.fullScreenApi = fullScreenApi;
    })();

    // fullscreen e

  }, [container, edges]);
  return (
    <>
      <style>
        {navigationStyles}
      </style>
      <div id="gg-viz" ref={container} style={{ backgroundColor: "transparent", height: "800px", padding: "1em", width: "100%" }} />
      <MDSnackbar
        // bgWhite
        color="light"
        title={window.snackLabel || ""}
        icon="info"
        content={
          <>
            <MDBox>
              {window.snackRatingVal ? (
                <MDBox display="flex" alignItems="center" color="text">
                  <ScoreItem scoreValue={window.snackRatingVal} textual={true} />
                  {false && <MDBadgeDot
                    badgeContent={window.snackRatingCheckDate || ""}
                    font={{ color: localStorage.getItem("cysr_dark_mode") === "false" ? "#354767" : "#7B809A" }}
                  />}
                </MDBox>
              ) : (
                <MDBadge
                  color="dark"
                  size="xs"
                  badgeContent="not rated"
                  container
                />
              )}
            </MDBox>
            <Grid container spacing={1} my={1}>
              {window.snackType && (
                <>
                  <Grid item textAlign="left">
                    <MDBox display="flex" px={2} py={1} style={{ alignItems: "center" }}>
                      <MDTypography variant="text" fontWeight="bold" textTransform="capitalize" style={{ color: "#000000" }}>
                        Depth: &nbsp;
                      </MDTypography>
                      <MDTypography variant="text" fontWeight="regular" style={{ color: "#000000" }}>
                        <b>{window.snackDepth}</b>
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item textAlign="left">
                    <MDBox display="flex" px={2} py={1} style={{ alignItems: "center" }}>
                      <MDTypography variant="text" fontWeight="bold" textTransform="capitalize" style={{ color: "#000000" }}>
                        Type: &nbsp;
                      </MDTypography>
                      <MDTypography variant="text" fontWeight="regular" style={{ color: "#000000" }}>
                        {/*
                      <img height="12" src={"data:image/svg+xml;charset=utf-8," + encodeURIComponent(window.snackIconType)} />&nbsp;
                      */}{window.snackType}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item textAlign="left">
                    <MDBox display="flex" px={2} py={1} style={{ alignItems: "center" }}>
                      <MDTypography variant="text" fontWeight="bold" textTransform="capitalize" style={{ color: "#000000" }}>
                        Origin: &nbsp;
                      </MDTypography>
                      <MDTypography variant="text" fontWeight="regular" style={{ color: "#000000" }}>
                        {/*
                      <img height="12" src={"data:image/svg+xml;charset=utf-8," + encodeURIComponent(window.snackIconOrigin)} />&nbsp;
                      */}{window.snackOrigin}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item textAlign="left">
                    <MDBox display="flex" px={2} py={1} style={{ alignItems: "center" }}>
                      <MDTypography variant="text" fontWeight="bold" textTransform="capitalize" style={{ color: "#000000" }}>
                        &nbsp;
                      </MDTypography>
                      <StatusItem statusValue={window.snackStatus} withLabel withText />
                    </MDBox>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container spacing={1}>
              {false && <Grid item xs={12} textAlign="left">
                <div style={{ backgroundColor: "rgba(0,0,0,0.125)", padding: "12px", fontSize: "12px", maxHeight: "200px", overflow: "auto" }} dangerouslySetInnerHTML={{ __html: window.snackTitle || "" }} />
              </Grid>}
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item>
                    <Link to={window.snackUrl || "#"}>
                      <MDButton style={cysrConfig().button_style} size="medium">
                        Go to asset
                        <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
                      </MDButton>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
        dateTime=""
        open={show}
        close={toggleSnackbar}
      />
    </>
  );
}
/* eslint-disable */
