/* eslint-disable */
import { useState } from "react";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import Collapse from "@mui/material/Collapse";
import {
    countries,
    countriesCodes
} from "layouts/cysr/components/CYSRCountries"
import cysrConfig from "layouts/cysr/config";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CysrStepper from "layouts/cysr/components/CysrStepper";
import Action from "layouts/cysr/components/Action";
import { useSnackbar } from 'notistack';
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import Box from '@mui/material/Box';
import CysrDropdown from "layouts/cysr/components/CysrDropdown";
import { getCompanies, updateCompanies } from "db";

import { useMaterialUIController } from "context";


export function getIndustry(i) {
    /*
    https://www.hithorizons.com/services/api/code-lists#industry

    Industry	Description
    A	Agriculture, Forestry, and Fishing
    B	Mining
    C	Construction
    D	Manufacturing
    E	Transportation, Communications, Electric, Gas, and Sanitary Services
    F	Wholesale Trade
    G	Retail Trade
    H	Finance, Insurance, and Real Estate
    I	Services
    J	Public Sector
    Y	Unknown industry
    */
    switch (i) {
        case "A":
            return "Agriculture, Forestry, and Fishing";
            break;
        case "B":
            return "Mining";
            break;
        case "C":
            return "Construction";
            break;
        case "D":
            return "Manufacturing";
            break;
        case "E":
            return "Transportation, Communications, Electric, Gas, and Sanitary Services";
            break;
        case "F":
            return "Wholesale Trade";
            break;
        case "G":
            return "Retail Trade";
            break;
        case "H":
            return "Finance, Insurance, and Real Estate";
            break;
        case "I":
            return "Services";
            break;
        case "J":
            return "Public Sector";
            break;
        case "Y":
            return "Unknown industry";
            break;

        default:
            return "Unknown industry";
            break;
    }
}
export function getIndustryCode(i) {
    /*
    https://www.hithorizons.com/services/api/code-lists#industry

    Industry	Description
    A	Agriculture, Forestry, and Fishing
    B	Mining
    C	Construction
    D	Manufacturing
    E	Transportation, Communications, Electric, Gas, and Sanitary Services
    F	Wholesale Trade
    G	Retail Trade
    H	Finance, Insurance, and Real Estate
    I	Services
    J	Public Sector
    Y	Unknown industry
    */
    switch (i) {
        case "Agriculture, Forestry, and Fishing":
            return "A";
            break;
        case "Mining":
            return "B";
            break;
        case "Construction":
            return "C";
            break;
        case "Manufacturing":
            return "D";
            break;
        case "Transportation, Communications, Electric, Gas, and Sanitary Services":
            return "E";
            break;
        case "Wholesale Trade":
            return "F";
            break;
        case "Retail Trade":
            return "G";
            break;
        case "Finance, Insurance, and Real Estate":
            return "H";
            break;
        case "Services":
            return "I";
            break;
        case "Public Sector":
            return "J";
            break;
        case "Unknown industry":
            return "Y";
            break;

        default:
            // return "Unknown industry";
            return "Y";
            break;
    }
}

export function getCountryCode(countryName) {
    let code = "JP"
    countries.forEach((c) => {
        if (c.name.toLowerCase() === countryName.toLowerCase()) {
            code = c.codes[0];
        }
    });
    return code.toLowerCase()
}
export function getCountryName(countryCode) {
    let name = "other"
    countries.forEach((c) => {
        if (c.codes && c.codes[0].toLowerCase() === countryCode.toLowerCase()) {
            name = c.name;
        }
    });
    return name
}
export function getActivityType(activityCodeType) {
    switch (activityCodeType) {
        case "060":
            return "Corporation";
            break;
        case "452":
            return "Partnership";
            break;

        default:
            return "Unknown Activity Type";
            break;
    }
}
export default function CompaniesTop() {
    const foundCompaniesPerPage = 10;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    // company creation states
    const [companyVatCode, setCompanyVatCode] = useState("IT");
    const [companyName, setCompanyName] = useState("");
    const [companyMainAsset, setCompanyMainAsset] = useState("");
    // Tutti gli asset di un account (/asset/) senza UUID?
    /*
    if (localStorage.getItem("cysr_useraccountroles") === null) {
      window.location.href = "/cysr/login";
    }
    */
    // subscriptions companies counting s
    let subscriptions = [];
    let activeSubscription = false;
    if (localStorage.getItem("cysr_mydata") && JSON.parse(localStorage.getItem("cysr_mydata"))) {
        const myData = JSON.parse(localStorage.getItem("cysr_mydata"));
        const subscriptionsData = myData.subscriptions;
        for (const key in subscriptionsData) {
            if (Object.hasOwnProperty.call(subscriptionsData, key)) {
                const s = subscriptionsData[key];
                if (s.subscription_status === "ACTIVE") {
                    const sObject = {
                        // label: `${s.subscription_billingcontact} - ${s.subscription_contracttype} ${s.companies_active_count && "(" + s[(s.subscription_type_group === "TIME" ? "companies_active_count" : "subscription_check_count")].replace("&infin;", "∞") + (s.subscription_type_group === "TIME" ? " companies" : " checks") + ")"}`,
                        label: `${s.subscription_holder} - ${s.subscription_contracttype} ${s.companies_active_count && "(" + s[(s.subscription_type_group === "TIME" ? "companies_active_count" : "subscription_check_count")].replace("&infin;", "∞") + (s.subscription_type_group === "TIME" ? " companies" : " checks") + ")"}`,
                        color: "info",
                        value: key,
                        key: key,
                        isDisabled: s.companies_active_count.indexOf("&infin;") > -1 ? false : ((parseInt(s.companies_active_count.split("/")[0], 10) < parseInt(s.companies_active_count.split("/")[1], 10)) ? false : true)
                    };
                    subscriptions.push(sObject);
                    if (key === myData.user.subscription_default) {
                        activeSubscription = sObject;
                    }
                }
            }
        }
    }
    if (subscriptions.length > 0) {
        subscriptions.sort((a, b) => a.label.localeCompare(b.label))
    }
    const [selectedSubscription, setSelectedSubscription] = useState([activeSubscription])
    function setSelectedSubscriptionFromID(id) {
        setSelectedSubscription([subscriptions.find((s) => s.value === id)])
    }
    // subscriptions companies counting e

    const [accountAutodiscovery, setAccountAutodiscovery] = useState(true);
    const [foundCompaniesCurrentPage, setFoundCompaniesCurrentPage] = useState(1)
    const [selectedCompany, setSelectedCompany] = useState(false);
    const [show, setShow] = useState(false);

    const [errorTitle, setErrorTitle] = useState("Company not created");
    const [errorMsg, setErrorMsg] = useState("Check that all the fields are compiled correctly and try again");

    const defaultToggleSnackbar = () => {
        setShow(!show);
    }
    const toggleSnackbar = (title = errorTitle, msg = errorMsg) => {
        setErrorTitle(title);
        setErrorMsg(msg);
        setShow(true);
    }
    // add company creation load
    const btnCreateCompanyDefaultString = "Create company";
    const [btnCreateCompanyString, setStringBtnCreateCompany] = useState(btnCreateCompanyDefaultString);


    function createAccount(event) {
        event.preventDefault();
        setShow(false);
        setCompanyCreationLoading(true)
        setStringBtnCreateCompany('Creating company...')
        // const t = event.currentTarget.elements.c_account_name.value;
        const t = companyName;
        // let vc = event.currentTarget.elements.c_account_vat_code.value;
        let vc = companyVatCode;
        // let v = event.currentTarget.elements.c_account_data_national_id.value;
        // let v = companyVat;
        let v = companyName;
        // const genasset = event.currentTarget.elements.c_account_main_asset.value;
        const genasset = companyMainAsset.trim();
        console.log([
            t, vc, v, genasset
        ])
        // console.warn(checkVAT(vc+v, countries));
        // if (genasset && genasset !== "" && ((t && t !== "" && ((v && v !== "") || vc === "other") && checkVAT(vc+v, countries).isValid) || (vc === "other" && t && t !== ""))) {
        if (genasset && genasset !== "" && ((t && t !== "" && ((v && v !== "") || vc === "other")))) {
            if (vc === "other") {
                vc = "XX";
                v = "N/A"
            }
            // create
            const createAccountUrl = `${cysrConfig().api_base_url}user/newaccount/`;
            let datacreateAccount = {
                // account_data_national_id: v,
                account_vat_country_code: vc,
                generalasset_type: "domain",
                generalasset_val: genasset,
            };
            if (selectedSubscription && selectedSubscription[0] && selectedSubscription[0].value) {
                datacreateAccount.account_subscription = selectedSubscription[0].value;
            } else {
                console.log("no subscription selected")
                console.log(selectedSubscription)
            }
            if (selectedCompany) {
                datacreateAccount.account_hithorizon_id = selectedCompany;
            } else {
                datacreateAccount.account_name = t;
            }
            if (accountAutodiscovery === false) {
                // messo a "False" perchè account_autodiscovery_enabled: ["Not a valid string."]datacreateAccount.account_autodiscovery_enabled = "false";
                datacreateAccount.account_autodiscovery_enabled = "False";
            }
            // console.log(datacreateAccount);
            fetch(createAccountUrl, {
                body: JSON.stringify(datacreateAccount),
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                    "content-type": "application/json",
                },
                method: "POST",
            })
                .then((response) => {
                    if (response.ok) {
                        response.json();
                    } else {
                        return response.json().then(err => {
                            throw JSON.stringify(err)
                        })
                    }
                })
                .then(async (r) => {
                    // console.log(r);
                    // refresh accounts s
                    // if (localStorage.getItem("cysr_useraccountroles")) {
                    if (await getCompanies()) {
                        // s
                        // get accountrole of user s
                        const callUrl = `${cysrConfig().api_base_url}user/mycompanies/`;
                        fetch(callUrl, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                            },
                        })
                            .then((response) => {
                                if (response && response.ok) {
                                    return response.json();
                                } else {
                                    return response.json().then(err => {
                                        throw JSON.stringify(err)
                                    })
                                }
                                /*
                                setStringBtnCreateCompany(btnCreateCompanyDefaultString);
                                toggleSnackbar(response.statusText);
                                // throw new Error(response);
                                return false
                                */
                            })
                            .then(async data => {
                                if (data) {
                                    // localStorage.setItem("cysr_***useraccountroles", JSON.stringify(data));
                                    // up DB
                                    await updateCompanies({ data: Array.isArray(data) ? data : [data] })
                                    // set as true to verify it has data
                                    localStorage.setItem("cysr_useraccountroles", true);
                                    // console.log("cysr_useraccountroles refreshed for creation of new company");
                                    // refresh mydata in storage because count changed s
                                    const myDataCallUrl = `${cysrConfig().api_base_url}user/mydata/`;
                                    fetch(myDataCallUrl, {
                                        headers: {
                                            Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                        },
                                    })
                                        .then((response) => {
                                            if (response.ok) {
                                                // window.location.reload();
                                                return response.json();
                                            } else {
                                                return response.json();
                                            }
                                        })
                                        .then((mydataData) => {

                                            if (mydataData) {
                                                localStorage.setItem("cysr_mydata", JSON.stringify(mydataData));
                                            }
                                            window.location.reload();
                                        })
                                    // refresh mydata in storage because count changed e
                                } else {
                                    console.error(data);
                                    setCompanyCreationLoading(false)
                                }
                            })
                            .catch((err) => {
                                setStringBtnCreateCompany(btnCreateCompanyDefaultString);
                                console.error("err1:", err);
                                toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail);
                                setCompanyCreationLoading(false)
                            })
                    }
                    // refresh accounts e
                })
                .catch((err) => {
                    setStringBtnCreateCompany(btnCreateCompanyDefaultString)
                    console.error("err2:", err);
                    toggleSnackbar(
                        JSON.parse(err).ErrorMessage ? JSON.parse(err).ErrorMessage : JSON.parse(err).CheckStatus,
                        JSON.parse(err).ErrorDetail ? JSON.parse(err).ErrorDetail : JSON.parse(err).CheckDetail
                    );
                    setCompanyCreationLoading(false)
                });
        } else {
            // ask to fill fields
            setStringBtnCreateCompany(btnCreateCompanyDefaultString)
            toggleSnackbar("Company not created", "Check that all the fields are compiled correctly and try again");
            setCompanyCreationLoading(false)
        }
    }

    function filterFoundCompanies(companies) {
        let filteredCompanies = [];

        companies.forEach((c, i) => {
            if (filteredCompanies.length < foundCompaniesPerPage) {
                if (
                    i < (foundCompaniesCurrentPage * foundCompaniesPerPage + 1)
                    &&
                    // i > (foundCompaniesCurrentPage * companies.length / foundCompaniesPerPage)
                    i > (foundCompaniesCurrentPage * foundCompaniesPerPage - foundCompaniesPerPage - 1)
                ) {
                    filteredCompanies.push(c);
                }
            }
        })
        return filteredCompanies;
    }
    const [companyMainAssetLabel, setCompanyMainAssetLabel] = useState("");
    const [companyCreationLoading, setCompanyCreationLoading] = useState(false);

    const [foundCompanies, setFoundCompanies] = useState([])
    const [loadingCompaniesSearch, setLoadingCompaniesSearch] = useState(false)
    const [companyCreationActiveStep, setCompanyCreationActiveStep] = useState(0);
    const [selectedCompanyFinderOpen, setSelectedCompanyFinderOpen] =
        useState(false);
    const [createOpen, setOpen] = useState(false);
    const [companyVat, setCompanyVat] = useState("");
    return (
        <MDBox py={5} px={3} fullWidth>
            <MDBox mb={1} fullWidth>
                <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <div>
                        {cysrConfig().i("dashboard", "add_a_company", "title") && (
                            <MDTypography variant="h5">{cysrConfig().i("dashboard", "add_a_company", "title")}</MDTypography>
                        )}
                        {cysrConfig().i("dashboard", "add_a_company", "sub_title") && (
                            <MDTypography variant="button" color="text" fontWeight="regular">
                                {cysrConfig().i("dashboard", "add_a_company", "sub_title")}
                            </MDTypography>
                        )}
                    </div>
                    {cysrConfig().feature_available("dashboard_add_new_company") && (
                        <MDButton
                            aria-label="expand row"
                            onClick={() => setOpen(!createOpen)} style={cysrConfig().button_style} size="medium">
                            {createOpen ? <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} /> : <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />
                            }
                            Add a company
                        </MDButton>
                    )}
                </MDBox>
                {cysrConfig().feature_available("dashboard_add_new_company") && (
                    <Collapse style={{ marginTop: ".5em" }} in={createOpen} timeout="auto" unmountOnExit>
                        <div className={`${darkMode ? "dark" : "light"}`}>
                            <div className="mt-2 bg-zinc-100 dark:bg-zinc-900 rounded-xl border-zinc-100 dark:border-zinc-700 border-b border-t">
                                {(selectedCompanyFinderOpen === false && loadingCompaniesSearch === false) ? (
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            if (companyVatCode && companyName) {
                                                setLoadingCompaniesSearch(true)
                                                // call hithorizons s
                                                const callUrl = `${cysrConfig().api_base_url}info/companysearch/?country=${companyVatCode}&searchtext=${companyName}`;
                                                fetch(callUrl, {
                                                    headers: {
                                                        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                                    },
                                                })
                                                    .then((response) => {
                                                        if (response && response.ok) {
                                                            return response.json();
                                                        } else {
                                                            return response.json().then(err => {
                                                                throw JSON.stringify(err)
                                                            })
                                                        }
                                                    })
                                                    .then(data => {
                                                        if (data && data.Result && data.Result.Results) {
                                                            setFoundCompanies(data.Result.Results)
                                                            setFoundCompaniesCurrentPage(1)
                                                            setSelectedCompanyFinderOpen(true);
                                                        } else {
                                                            console.error(data);
                                                        }
                                                        setLoadingCompaniesSearch(false)
                                                    })
                                                    .catch((err) => {
                                                        console.error("err1:", err);
                                                        toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail);
                                                        setLoadingCompaniesSearch(false)
                                                    })
                                                // call hithorizons e
                                            } else {
                                                enqueueSnackbar(
                                                    // "Company not created",
                                                    "Check that all the fields are compiled correctly and try search again"
                                                );
                                                setLoadingCompaniesSearch(false)
                                            }
                                        }}
                                    >
                                        <CysrStepper
                                            isLoading={companyCreationLoading}
                                            defaultActiveStep={companyCreationActiveStep}
                                            steps={[
                                                {
                                                    label: "Select a subscription to add the new Company to",
                                                    value: (selectedSubscription && selectedSubscription[0] && selectedSubscription[0].isDisabled === false && selectedSubscription[0].label) ? selectedSubscription[0].label : "",
                                                    content:
                                                        <div style={{ maxWidth: 480, marginRight: "auto", position: "relative", zIndex: 5 }}>
                                                            {false && <MDTypography color="text" variant="caption" style={{
                                                                left: 0,
                                                                position: "absolute",
                                                                top: "-16px"
                                                            }}>select a subscription</MDTypography>}
                                                            <CysrDropdown data={
                                                                {
                                                                    asset: null,
                                                                    // isDisabled: !(subscriptions.length > 1),
                                                                    isDisabled: false,
                                                                    isLoading: false,
                                                                    options: subscriptions,
                                                                    value: selectedSubscription[0] && selectedSubscription[0].isDisabled === false && selectedSubscription,
                                                                    defaultValue:
                                                                        selectedSubscription[0] && selectedSubscription[0].isDisabled === false && selectedSubscription,
                                                                    onChange: (x) => {
                                                                        setSelectedSubscriptionFromID(x)
                                                                    }
                                                                }
                                                            }
                                                            />
                                                        </div>
                                                },
                                                /*
                                                {
                                                  label: "Select the country",
                                                  value: companyVatCode,
                                                  content: (
                                                    <div className="w-full sm:w-6/12 lg:w-3/12">
                                                      <Autocomplete
                                                        defaultValue={companyVatCode}
                                                        onChange={( e, x ) => {
                                                          setCompanyVatCode(x);
                                                        }}
                                                        size="small"
                                                        // label="generalasset_type"
                                                        id="c_account_vat_code"
                                                        options={countriesCodes}
                                                        renderInput={(params) => <MDInput
                                                          sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#232728' : '#ffffff' }}
                                                          size="small" label="VAT Country" {...params} />}
                                                      />
                                                    </div>
                                                  ),
                                                },
                                                */
                                                /*
                                                {
                                                  label: "Select the country of the company",
                                                  value: companyVatCode,
                                                  content: (
                                                    <div className="w-full sm:w-6/12 lg:w-3/12">
                                                      <Autocomplete
                                                        defaultValue={companyVatCode}
                                                        onChange={(e, x) => {
                                                          setCompanyVatCode(x);
                                                        }}
                                                        size="small"
                                                        id="c_account_vat_code"
                                                        options={countriesCodes}
                                                        renderInput={(params) => (
                                                          <TextField
                                                            {...params}
                                                            InputLabelProps={{ shrink: true }}
                                                            // className="text-sm rounded-md bg-zinc-100 dark:bg-zinc-900 text-zinc-900 dark:text-zinc-100"
                                                            className="text-sm rounded-md bg-zinc-100 text-zinc-900 dark:text-zinc-100"
                                                            label="VAT Country"
                                                            size="small"
                                                            placeholder="VAT Country"
                                                          />
                                                        )}
                                                      />
                                                    </div>
                                                  ),
                                                },
                                                */
                                                {
                                                    label: "Select the country and enter the name or ID",
                                                    value: `${companyName &&
                                                        companyName != "" &&
                                                        companyVatCode &&
                                                        companyVatCode != "" &&
                                                        companyVatCode.toLowerCase() != "other"
                                                        ? companyVatCode && companyVatCode != ""
                                                            ? companyName.toUpperCase() + ", " + companyVatCode
                                                            : companyName.toUpperCase()
                                                        : companyName && companyName != "" && companyName.toUpperCase()
                                                        }`,
                                                    content: (
                                                        <div className="flex w-full gap-2 items-center">
                                                            <div className="w-full sm:w-6/12 lg:w-3/12">
                                                                <Autocomplete
                                                                    defaultValue={companyVatCode}
                                                                    onChange={(e, x) => {
                                                                        setCompanyVatCode(x);
                                                                    }}
                                                                    size="small"
                                                                    // label="generalasset_type"
                                                                    id="c_account_vat_code"
                                                                    options={countriesCodes}
                                                                    renderInput={(params) => <MDInput
                                                                        // sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#232728' : '#ffffff' }}
                                                                        sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? 'rgb(9 9 11)' : 'rgb(250 250 250)', borderColor: darkMode ? 'rgb(63 63 70)' : 'rgb(212 212 216)', borderRadius: "6px" }}
                                                                        size="small" label="VAT Country" {...params} />}
                                                                    renderOption={(props, option) =>
                                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                            {option && option.toLowerCase() != "xx" && option.toLowerCase() != "other" && <img
                                                                                loading="lazy"
                                                                                width="20"
                                                                                src={`https://flagcdn.com/w20/${option.toLowerCase()}.png`}
                                                                                srcSet={`https://flagcdn.com/w40/${option.toLowerCase()}.png 2x`}
                                                                                alt=""
                                                                            />}
                                                                            {getCountryName(option)}
                                                                        </Box>
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="w-full sm:w-6/12">
                                                                <Action
                                                                    placeholder="Company name or ID"
                                                                    type="text"
                                                                    defaultValue={companyName}
                                                                    onChange={(e) => {
                                                                        setTimeout(() => {
                                                                            if (companyName != e.target.value) {
                                                                                setCompanyName(e.target.value);
                                                                            }
                                                                        }, 200);
                                                                    }}
                                                                    id="c_account_name"
                                                                />
                                                            </div>
                                                        </div>
                                                    ),
                                                    submitIcon: `${companyVatCode && (companyVatCode.toLowerCase() === "other") ? "go" : "expand"}`,
                                                    submitText: `${companyVatCode && (companyVatCode.toLowerCase() === "other") ? "Next" : "Search company"}`
                                                },
                                            ]}
                                        />
                                    </form>
                                ) : (
                                    <div className="my-2 px-2 flex flex-col gap-4 flex-wrap">
                                        <div className="flex flex-col items-start gap-4">
                                            {loadingCompaniesSearch
                                                ?
                                                <Action
                                                    loading={true}
                                                    leftIcon="back"
                                                    text="Loading results"
                                                />
                                                :
                                                <Action
                                                    onClick={() => {
                                                        setCompanyCreationActiveStep(1)
                                                        setSelectedCompanyFinderOpen(false);
                                                        setSelectedCompany(false);
                                                        setCompanyMainAsset("")
                                                        setCompanyMainAssetLabel("")
                                                    }}
                                                    leftIcon="back"
                                                    text="Change company details"
                                                />
                                            }
                                            {(selectedCompanyFinderOpen && companyVatCode && (companyVatCode.toLowerCase() === "other"))
                                                ?
                                                <span className="border-t border-zinc-200 dark:border-zinc-800 w-full pt-2 text-sm text-zinc-700 dark:text-zinc-300">
                                                    You have set <strong>{companyName}</strong> as company name and selected <strong>Other</strong> as country
                                                </span>
                                                :
                                                <span className="border-t border-zinc-200 dark:border-zinc-800 w-full pt-2 text-sm text-zinc-700 dark:text-zinc-300">
                                                    {
                                                        loadingCompaniesSearch
                                                            ? <>
                                                                Loading results for
                                                                <strong className="text-cyan-600 dark:text-cyan-400 text-lg uppercase">
                                                                    {companyName}, {companyVatCode}
                                                                </strong>
                                                                <div className="animate-pulse flex space-x-4 w-full">
                                                                    <div className="flex flex-col gap-4 mb-4 w-full"><div className="p-3 bg-zinc-50 dark:bg-zinc-950 border-zinc-200 dark:border-zinc-800 border rounded-lg relative"><div className="flex items-center"><div className="inline"><h2 className="font-bold text-zinc-950 dark:text-zinc-50 inline">...</h2></div><div className="ml-auto flex items-center gap-2 my-0.5"><span className="text-sm uppercase text-zinc-600 dark:text-zinc-400"></span></div></div><div className="flex flex-wrap justify-between items-center"><div className="flex items-center gap-2 text-zinc-700 dark:text-zinc-300"><span className="font-bold text-sm"></span><span className="min-w-[200px] md:min-w-0 text-sm font-light"><strong className="font-bold text-sm"></strong> </span><span className="font-bold text-sm"></span></div><div className="absolute -bottom-1.5 right-2"></div></div></div></div>
                                                                </div>
                                                            </>
                                                            : <>
                                                                Viewing {foundCompaniesCurrentPage} of {Math.ceil(foundCompanies.length / foundCompaniesPerPage)} page{foundCompanies.length > foundCompaniesPerPage ? "s" : ""} from {foundCompanies.length} results for{" "}
                                                                <strong className="text-cyan-600 dark:text-cyan-400 text-lg uppercase">
                                                                    {companyName}, {companyVatCode}
                                                                </strong>
                                                            </>
                                                    }
                                                </span>
                                            }
                                        </div>
                                        {(selectedCompanyFinderOpen && companyVatCode && (companyVatCode.toLowerCase() === "other"))
                                            ?
                                            null
                                            :
                                            loadingCompaniesSearch === false && <>
                                                <div className="flex flex-col gap-4 mb-4 pl-3">
                                                    {
                                                        /*
                                                        AddressStreetLine1
                                                        : 
                                                        "VIA EZIO ANDOLFATO 10"
                                                        AddressStreetLine2
                                                        : 
                                                        ""
                                                        City
                                                        : 
                                                        "MILANO"
                                                        CompanyName
                                                        : 
                                                        "MUSCOPE CYBERSECURITY SRL"
                                                        CompanyProfileURL
                                                        : 
                                                        "https://www.hithorizons.com/eu/companies/H-IT0962342428/muscope-cybersecurity-srl"
                                                        CompanySecondaryName
                                                        : 
                                                        ""
                                                        Country
                                                        : 
                                                        "ITALY"
                                                        DUNSNumber
                                                        : 
                                                        "442261924"
                                                        EmployeesNumber
                                                        : 
                                                        2
                                                        EstablishmentOfOwnership
                                                        : 
                                                        2021
                                                        HitHorizonsId
                                                        : 
                                                        "H-IT0962342428"
                                                        Industry
                                                        : 
                                                        "I"
                                                        LocalActivityCode
                                                        : 
                                                        "7372"
                                                        LocalActivityCodeType
                                                        : 
                                                        "060"
                                                        NationalId
                                                        : 
                                                        "IT12136410961"
                                                        PostalCode
                                                        : 
                                                        "20126"
                                                        SICCode
                                                        : 
                                                        "7372"
                                                        SalesEUR
                                                        : 
                                                        42365
                                                        StateProvince
                                                        : 
                                                        "MILANO"
                                                        TaxId
                                                        : 
                                                        null
                                                        */
                                                    }
                                                    {foundCompanies && filterFoundCompanies(foundCompanies).map((c) => (
                                                        <div
                                                            key={c.HitHorizonsId}
                                                            className={
                                                                selectedCompany === c.HitHorizonsId
                                                                    ? "p-3 ring-cyan-300 dark:ring-cyan-700 border-cyan-200 dark:border-cyan-800 border ring-2 bg-cyan-50 dark:bg-cyan-950 rounded-lg relative max-w-4xl cursor-pointer"
                                                                    : "p-3 hover:ring-cyan-200 dark:hover:ring-cyan-800 bg-zinc-50 dark:bg-zinc-950 hover:border-cyan-200 dark:hover:border-cyan-800 hover:ring-2 border-zinc-200 dark:border-zinc-800 border rounded-lg relative max-w-4xl cursor-pointer"
                                                            }
                                                            onClick={() => {
                                                                setCompanyMainAsset(
                                                                    selectedCompany === c.HitHorizonsId
                                                                        ? ""
                                                                        : (c.Websites && c.Websites.length > 0 ? c.Websites[0] : "")
                                                                );
                                                                setCompanyMainAssetLabel(
                                                                    selectedCompany === c.HitHorizonsId
                                                                        ? ""
                                                                        : (c.Websites && c.Websites.length > 0 ? c.Websites[0] : "")
                                                                );
                                                                setSelectedCompany(
                                                                    selectedCompany === c.HitHorizonsId
                                                                        ? false
                                                                        : c.HitHorizonsId
                                                                );
                                                            }}
                                                        >
                                                            <div className="flex items-center">
                                                                <div className="flex" style={{
                                                                    display: "flex",
                                                                    // flexWrap: "wrap",
                                                                    wordBreak: "break-all",
                                                                    paddingRight: "12px"
                                                                }}>
                                                                    <h2 className="text-base font-bold text-zinc-950 dark:text-zinc-50 inline" style={{
                                                                        display: "inline",
                                                                        lineHeight: "1.1618"
                                                                    }}>{c.CompanyName}

                                                                    </h2>
                                                                </div>
                                                                <div className="ml-auto pl-2">
                                                                    <img
                                                                        className="h-4"
                                                                        onError={() => this.style.visibility = 'hidden'}
                                                                        src={`https://flagicons.lipis.dev/flags/4x3/${getCountryCode(c.Country)}.svg`}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-wrap justify-between items-center">
                                                                <div className="flex items-center gap-1 text-zinc-700 dark:text-zinc-300">
                                                                    {false && <span className="min-w-[200px] md:min-w-0 text-sm font-light">
                                                                        <strong className="font-bold text-sm">{getActivityType(c.LocalActivityCodeType)}</strong>
                                                                    </span>}
                                                                    <span className="text-sm">Id: </span>
                                                                    <span className="font-bold text-sm">
                                                                        {c.NationalId}
                                                                    </span>
                                                                    <span className="text-sm"> - Industry: </span>
                                                                    <span className="font-bold text-sm" style={{
                                                                        maxWidth: "120px",
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                        whiteSpace: "nowrap"
                                                                    }}>
                                                                        {getIndustry(c.Industry)}
                                                                    </span>
                                                                    <span className="text-sm"> - Established: </span>
                                                                    <span className="font-bold text-sm">
                                                                        {c.EstablishmentOfOwnership}
                                                                    </span>
                                                                </div>
                                                                <div className="ml-auto">
                                                                    <span className="text-xs uppercase text-zinc-600 dark:text-zinc-400">
                                                                        {c.AddressStreetLine1 &&
                                                                            `${c.AddressStreetLine1} - `}
                                                                        {c.City
                                                                            ? `${c.City}, ${c.Country}`
                                                                            : `${c.Country}`}
                                                                    </span>
                                                                </div>
                                                                {false && <div className="flex flex-col md:flex-row gap-0.5 md:gap-x-4">
                                                                    <div className="flex items-center md:gap-2">
                                                                        <span className="min-w-[200px] md:min-w-0 text-sm font-light text-zinc-700 dark:text-zinc-300">
                                                                            Establishment Of Ownership
                                                                        </span>
                                                                        <span className="font-bold text-sm">
                                                                            {c.establishment_of_ownership}
                                                                        </span>
                                                                    </div>
                                                                    <div className="flex items-center md:gap-2">
                                                                        <span className="min-w-[200px] md:min-w-0 text-sm font-light text-zinc-700 dark:text-zinc-300">
                                                                            Type
                                                                        </span>
                                                                        <span className="font-bold text-sm">
                                                                            {c.search_result_type}
                                                                        </span>
                                                                    </div>
                                                                </div>}
                                                                {selectedCompany === c.HitHorizonsId ? (
                                                                    <div className="absolute -left-2 -top-3">
                                                                        <CheckCircleRoundedIcon
                                                                            className="text-cyan-600 dark:text-cyan-400 ring-cyan-300 dark:ring-cyan-700 ring rounded-full bg-cyan-50"
                                                                            fontSize="8"
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div className="absolute -left-2 -top-3">
                                                                        <CircleRoundedIcon
                                                                            className="text-cyan-100 dark:text-cyan-900 ring-cyan-200 dark:ring-cyan-800 ring rounded-full bg-cyan-50 dark:bg-cyan-950"
                                                                            fontSize="8"
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="flex gap-2">
                                                    {
                                                        Array.from(Array(
                                                            Math.ceil(foundCompanies.length / foundCompaniesPerPage)
                                                        ).keys()).map((n) => (
                                                            <div style={{ opacity: (n + 1) === foundCompaniesCurrentPage ? "0.5" : "1" }}>
                                                                <Action
                                                                    onClick={() => {
                                                                        setFoundCompaniesCurrentPage(n + 1);
                                                                    }}
                                                                    leftIcon={null}
                                                                    text={n + 1}
                                                                />
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </>
                                        }
                                    </div>
                                )}
                                {(selectedCompanyFinderOpen && companyVatCode && (companyVatCode.toLowerCase() === "other"))
                                    ?
                                    <form onSubmit={createAccount} className="my-2">
                                        <CysrStepper
                                            isLoading={companyCreationLoading}
                                            steps={[
                                                {
                                                    label: "Main domain / Website",
                                                    // value: companyMainAsset,
                                                    value: companyMainAssetLabel,
                                                    content: (
                                                        <div className="flex gap-4 w-full sm:w-6/12 items-end">
                                                            {
                                                                // <div className="w-full sm:w-6/12">
                                                            }
                                                            <Action
                                                                className="flex-1"
                                                                type={"text"}
                                                                // defaultValue={companyMainAsset}
                                                                value={companyMainAsset}
                                                                width="full"
                                                                onChange={(e) => {
                                                                    // setTimeout(() => {
                                                                    if (companyMainAsset != e.target.value) {
                                                                        setCompanyMainAsset(e.target.value);
                                                                    }
                                                                    // }, 200);
                                                                    setTimeout(() => {
                                                                        if (companyMainAsset != e.target.value) {
                                                                            setCompanyMainAssetLabel(e.target.value);
                                                                        }
                                                                    }, 200);
                                                                }}
                                                                text="Main domain/Website"
                                                                id="c_account_main_asset"
                                                            />
                                                            <FormControlLabel onChange={() => {
                                                                setAccountAutodiscovery(!accountAutodiscovery)
                                                            }} control={<Checkbox defaultChecked={accountAutodiscovery} />} label="Auto discovery" />
                                                        </div>
                                                    ),
                                                    submitIcon: "add",
                                                    submitText: btnCreateCompanyString,
                                                },
                                            ]}
                                        />
                                    </form>
                                    :
                                    <>
                                        {selectedCompanyFinderOpen && (
                                            <span className="my-2 px-4 flex items-center gap-1 text-sm text-zinc-600 dark:text-zinc-400">
                                                Company not listed?{" "}
                                                <button
                                                    className="text-cyan-600 dark:text-cyan-400 underline"
                                                    onClick={() => {
                                                        setCompanyCreationActiveStep(1)
                                                        setSelectedCompanyFinderOpen(false);
                                                        setSelectedCompany(false);
                                                        setCompanyMainAsset("")
                                                        setCompanyMainAssetLabel("")
                                                    }}
                                                >
                                                    Change company details
                                                </button>{" "}
                                                and select <strong>Other</strong> as country
                                            </span>
                                        )}
                                        {selectedCompany ? (
                                            <>
                                                <span className="my-2 px-4 flex items-center gap-1 text-xs text-emerald-600 dark:text-emerald-400">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        className="w-4 h-4"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M16.403 12.652a3 3 0 000-5.304 3 3 0 00-3.75-3.751 3 3 0 00-5.305 0 3 3 0 00-3.751 3.75 3 3 0 000 5.305 3 3 0 003.75 3.751 3 3 0 005.305 0 3 3 0 003.751-3.75zm-2.546-4.46a.75.75 0 00-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                    Selected company for creation
                                                </span>
                                                <form onSubmit={createAccount} className="my-2">
                                                    <CysrStepper
                                                        isLoading={companyCreationLoading}
                                                        steps={[
                                                            {
                                                                label: "Main domain / Website",
                                                                value: companyMainAssetLabel,
                                                                content: (
                                                                    <div className="flex gap-4 w-full sm:w-6/12 items-end">
                                                                        <Action
                                                                            className="flex-1"
                                                                            type={"text"}
                                                                            value={companyMainAsset}
                                                                            width="full"
                                                                            onChange={(e) => {
                                                                                // setTimeout(() => {
                                                                                if (companyMainAsset != e.target.value) {
                                                                                    setCompanyMainAsset(e.target.value);
                                                                                }
                                                                                // }, 200);
                                                                                setTimeout(() => {
                                                                                    if (companyMainAsset != e.target.value) {
                                                                                        setCompanyMainAssetLabel(e.target.value);
                                                                                    }
                                                                                }, 200);
                                                                            }}
                                                                            text="Main domain/Website"
                                                                            id="c_account_main_asset"
                                                                        />
                                                                        <FormControlLabel onChange={() => {
                                                                            setAccountAutodiscovery(!accountAutodiscovery)
                                                                        }} control={<Checkbox defaultChecked={accountAutodiscovery} />} label="Auto discovery" />
                                                                    </div>
                                                                ),
                                                                submitIcon: "add",
                                                                submitText: btnCreateCompanyString,
                                                            },
                                                        ]}
                                                    />
                                                </form>
                                            </>
                                        ) : (
                                            selectedCompanyFinderOpen && (
                                                <span className="my-2 px-4 flex items-center gap-1 text-xs text-amber-600 dark:text-amber-400">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        className="w-4 h-4"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                    Select a company to continue
                                                </span>
                                            )
                                        )}
                                    </>
                                }
                            </div>
                        </div>
                        {
                            /*
                                }
                                {
                                    // working backup s
                                }
                                {false && <div style={{
                                    borderBottom: `1px solid ${darkMode ? "#4a4545" : "#f0f2f5"}`,
                                    borderTop: `1px solid ${darkMode ? "#4a4545" : "#f0f2f5"}`,
                                    marginTop: "8px"
                                }}>
                                    <form onSubmit={createAccount}>
                                        <CysrStepper darkMode isLoading={companyCreationLoading} steps={
                                            [
                                                {
                                                    label: "Select the country of the company",
                                                    value: companyVatCode,
                                                    content:
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={6} lg={3}>
                                                                <Autocomplete
                                                                    defaultValue={companyVatCode}
                                                                    onChange={(e, x) => {
                                                                        setCompanyVatCode(x);
                                                                    }}
                                                                    size="small"
                                                                    // label="generalasset_type"
                                                                    id="c_account_vat_code"
                                                                    options={countriesCodes}
                                                                    renderInput={(params) => <MDInput
                                                                        sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#232728' : '#ffffff' }}
                                                                        size="small" label="VAT Country" {...params} />}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                },
                                                {
                                                    label: "Company name",
                                                    value: `${companyName && companyName != "" && companyVatCode && companyVatCode != "" && companyVatCode.toLowerCase() != "other" ? (companyVat && companyVat != "" ? companyName + " (" + companyVat + ")" : companyName) : companyName && companyName != "" && companyName}`,
                                                    content:
                                                        <Grid container spacing={1} maxWidth="800px">
                                                            <Grid item xs={6} lg={6}>
                                                                <MDInput
                                                                    fullWidth
                                                                    defaultValue={companyName}
                                                                    onChange={(e) => {
                                                                        setTimeout(() => {
                                                                            if (companyName != e.target.value) {
                                                                                setCompanyName(e.target.value);
                                                                            }
                                                                        }, 200);
                                                                    }}
                                                                    sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#232728' : '#ffffff' }}
                                                                    size="small"
                                                                    label="Company name"
                                                                    id="c_account_name"
                                                                />
                                                            </Grid>
                                                            {companyVatCode && companyVatCode.toLowerCase() != "other" && <Grid item xs={6} lg={6}>
                                                                <MDInput
                                                                    label="Company VAT"
                                                                    id="c_account_data_national_id"
                                                                    defaultValue={companyVat}
                                                                    onChange={(e) => {
                                                                        setTimeout(() => {
                                                                            if (companyVat != e.target.value) {
                                                                                setCompanyVat(e.target.value);
                                                                            }
                                                                        }, 200);
                                                                    }}
                                                                    sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#232728' : '#ffffff' }}
                                                                    size="small"
                                                                    fullWidth
                                                                />
                                                            </Grid>}
                                                        </Grid>
                                                },
                                                {
                                                    label: 'Main domain / Website',
                                                    value: companyMainAsset,
                                                    content:
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={6} lg={6}>
                                                                <MDInput
                                                                    fullWidth
                                                                    defaultValue={companyMainAsset}
                                                                    onChange={(e) => {
                                                                        setTimeout(() => {
                                                                            if (companyMainAsset != e.target.value) {
                                                                                setCompanyMainAsset(e.target.value);
                                                                            }
                                                                        }, 200);
                                                                    }}
                                                                    sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#232728' : '#ffffff' }}
                                                                    size="small"
                                                                    label="Main domain/Website"
                                                                    id="c_account_main_asset"
                                                                />
                                                            </Grid>
                                                        </Grid>,
                                                    submitIcon: "add",
                                                    submitText: btnCreateCompanyString
                                                },
                                            ]
                                        } />
                                    </form>
                                </div>
                                }
                                {
                                    // working backup e
                                }
                                {false && <Card style={{ backgroundColor: darkMode ? "#121619" : "#edf0f2", color: darkMode ? "#7B809A" : "#7B809A", borderRadius: 0, boxShadow: "none", padding: "1em", margin: 0 }}>
                                    <MDBox width="100%">
                                        <form onSubmit={createAccount} style={{ display: "block", paddingLeft: ".5em" }}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={3} lg={1}>
                                                    <Autocomplete
                                                        defaultValue="IT"
                                                        size="small"
                                                        // label="generalasset_type"
                                                        id="c_account_vat_code"
                                                        options={countriesCodes}
                                                        renderInput={(params) => <MDInput
                                                            size="small" label="VAT Country" {...params} />}
                                                    />
                                                </Grid>
                                                <Grid item xs={9} lg={2}>
                                                    <MDInput
                                                        label="Company VAT"
                                                        id="c_account_data_national_id"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={3}>
                                                    <MDInput
                                                        fullWidth
                                                        size="small"
                                                        label="Company name"
                                                        id="c_account_name"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={3}>
                                                    <MDInput
                                                        fullWidth
                                                        size="small"
                                                        label="Main domain/Website"
                                                        id="c_account_main_asset"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={3}>
                                                    <MDButton type="submit" {...(btnCreateCompanyString != btnCreateCompanyDefaultString && { disabled: true })} style={cysrConfig().button_style} size="medium" fullWidth>
                                                        {btnCreateCompanyString != btnCreateCompanyDefaultString ? (                                                    
                                                            <span style={cysrConfig().button_icon_left_style} className="spin" dangerouslySetInnerHTML={{ __html: cysrConfig().loading.icon.svg }} />
                                                        ) : (
                                                            <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().add.icon.svg }} />
                                                        )}{btnCreateCompanyString}</MDButton>

                                                </Grid>
                                            </Grid>
                                        </form>
                                    </MDBox>
                                </Card>
                                }
                                {
                            */
                        }
                        <MDSnackbar
                            color="error"
                            icon="error"
                            title={errorTitle}
                            content={errorMsg}
                            dateTime="Error"
                            open={show}
                            close={defaultToggleSnackbar}
                        />
                    </Collapse>
                )}

            </MDBox>
        </MDBox>
    )
}