/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

/* eslint-disable */
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import cysrConfig from "layouts/cysr/config";

function Transaction({ color, icon, name, description, value }) {
  // icon explains the direction
  console.log(icon)
  return (
    <MDBox key={`${name}-${description}`} component="li" py={0.5}>
      {icon && icon === "current" ? (
      <MDBox display="flex" alignItems="center" style={{color: "inherit", width:"100%",overflow:"hidden",whiteSpace: "nowrap",textOverflow:"ellipsis"}}>
        <MDTypography fontWeight="medium" style={{color: "inherit", fontSize: "12px"}} mr={1}>
          {value}
        </MDTypography>
        <MDTypography style={{color: "inherit", fontSize: "20px", overflow:"hidden", whiteSpace: "nowrap",textOverflow:"ellipsis"}} fontWeight="bold">
          {name}
        </MDTypography>
      </MDBox>
      ) : (
        <MDButton style={cysrConfig().button_style} size="medium" fullWidth>
            <MDBox display="flex" alignItems="center" style={{color: "inherit", width:"100%",overflow:"hidden",whiteSpace: "nowrap",textOverflow:"ellipsis"}}>
              <MDTypography fontWeight="medium" style={{color: "inherit", fontSize: "12px"}} mr={1}>
                {value}
              </MDTypography>
              <MDTypography style={{color: "inherit", fontSize: "20px", overflow:"hidden", whiteSpace: "nowrap",textOverflow:"ellipsis"}} fontWeight="bold">
                {name}
              </MDTypography>
            </MDBox>
            {
              /*
            }
            <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{__html: cysrConfig().go.icon.svg }} />
            {
              */
            }
        </MDButton>
      )}
    </MDBox>
  );
}

// Typechecking props of the Transaction
Transaction.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]).isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Transaction;

/* eslint-disable */