/* eslint-disable */
// prettier-ignore
"use client"
import { Field, Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverPanel, Radio, RadioGroup } from '@headlessui/react'
import { ChevronRightSharp, CircleSharp, EditSharp, MoreVertSharp } from '@mui/icons-material'
import { CtrlInfoDialog } from './info-dialog';
import { InfoSharp } from '@mui/icons-material';

export function PopoverControl({
  a = null,
  className,
  ghost,
  label,
  statuses,
  status,
  setStatus,
  darkMode,
  children
}) {
  return (
    <div className={className}>
      <Menu>
        {
          ghost 
          ?
        <MenuButton className="inline-flex items-center gap-2 bg-transparent text-sm/6 font-medium">
          {children ?
            <MoreVertSharp style={{
              height: "1.5rem",
              width: "1.5rem"
            }} className="size-6 shrink-0 text-black dark:text-white" aria-hidden="true" />
            :
            <EditSharp className="size-6 shrink-0 text-white" aria-hidden="true" />
          }
          {label ? label : null}
        </MenuButton>
        :
        <MenuButton className="inline-flex items-center gap-2 rounded-md bg-turquoise-600 py-1 px-3 text-sm/6 font-medium text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-700 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white">
          {children ?
            <MoreVertSharp className="size-4 shrink-0 text-white" aria-hidden="true" />
            :
            <EditSharp className="size-4 shrink-0 text-white" aria-hidden="true" />
          }
          {label ? label : null}
        </MenuButton>
        }

        <MenuItems
          transition
          portal={false}
          // anchor="bottom end"
          className="mt-1 min-w-[200px] absolute w-52 origin-top-right rounded-xl border border-white/5 bg-tremor-background dark:bg-turquoise-900 p-1 text-sm/6 dark:text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
        >
          {statuses ? (
            
              <MenuItem onClick={e => e.preventDefault()}>
                <RadioGroup
                  value={status}
                  onChange={setStatus}
                >
                  <div className="px-5 mt-4 mb-1 text-xs text-zinc-800 dark:text-zinc-300">Set control status</div>
                  {statuses.map((item) => (

                    <Field
                      aria-label="Switch status"

                      key={item.value}
                      value={item.value}
                      disabled={item.disabled}
                    >
                      {
                        // <LightModeSharp className="size-4 shrink-0" aria-hidden="true" />
                      }
                      <Radio
                        value={item.value}
                        className="mb-1 group relative flex cursor-pointer rounded-lg py-1 px-5 dark:text-white transition focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-white/20 data-[hover]:bg-white/10"
                      >
                        <div className="flex w-full items-center gap-2">
                          <CircleSharp className="size-6 opacity-25 transition group-checked:opacity-100" />
                          <div className="text-sm/6">
                            <p className="font-medium dark:text-white">{item.label}</p>
                          </div>
                        </div> </Radio>
                    </Field>
                  ))
                  }
                </RadioGroup>
              </MenuItem>
              
          ) : null}    
          {statuses && children ?
          <div className="my-1 h-px bg-turquoise-100 dark:bg-white/5" />
           : null}    

          {children ?

            <MenuItem onClick={e => e.preventDefault()}>
              <Popover className="relative">
                  <CtrlInfoDialog className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10" darkMode={darkMode} a={a} type={"answer"}>
                    <InfoSharp className="size-4 fill-white/30" />
                    Maturity level info
                  </CtrlInfoDialog>
                <PopoverButton className="w-full">
                  <button className="group flex w-full justify-between items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">
                    Target maturity level
                    <ChevronRightSharp className="size-4 fill-white/30" />
                  </button>
                </PopoverButton>
                <PopoverPanel
                  transition
                  portal={false}
                  // anchor="bottom"
                  className="mt-1 min-w-[280px] absolute divide-y divide-white/5 rounded-xl bg-tremor-background dark:bg-turquoise-800 text-sm/6 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
                >
                  <div className="p-3">
                    {children}
                  </div>
                </PopoverPanel>
              </Popover>
            </MenuItem>
            : null}
        </MenuItems>
      </Menu>
    </div>
  )
}
