/* eslint-disable */
// prettier-ignore
import { LinkSharp } from '@mui/icons-material';
import { MultiSelect, MultiSelectItem, TextInput } from '@tremor/react';

/*
{
    "framework_id": "b988bd20-643d-4950-b2ff-dbc977cb03d9",
    "framework_name": "BSI_Standard_2001",
    "framework_public": true,
    "framework_available": true,
    "framework_geography": "Universal",
    "framework_source": "BSI",
    "framework_version": "2022",
    "framework_url": "https_//www.bsi.bund.de/DE/Themen/UnternehmenundOrganisationen/StandardsundZertifizierung/ITGrundschutz/BSIStandards/BSIStandard2001ManagementsystemefuerInformationssicherheit/bsistandard2001managementsystemefuerinformationssicherheit_node.html",
    "framework_authoritative": "Standard_2001",
    "framework_strm": "N/A"
},
*/

export function CtrlWizardQuestionnaire({ questionnaireName, setQuestionnaireName, frameworks, setFrameworks, options }) {
    return (
        <>
            <div className='max-w-lg w-full'>
                <p className="mb-2 text-left text-sm text-zinc-500 dark:text-zinc-300">
                    Choose frameworks to add to the questionnaire
                </p>
                <MultiSelect placeholder='Select frameworks' value={frameworks} onValueChange={(x)=>{
                    setFrameworks(x)
                    if(x.length > 1){
                        // ok
                    } else {
                        setQuestionnaireName(options.find(f=>f.framework_id === x[0])?.framework_name)
                    }
                }}>
                    {options ? options.map(
                        f => <MultiSelectItem value={f.framework_id}>
                            <span className='text-xs font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong'>
                                {f.framework_name}
                            </span>{
                                /*
                                <div className='flex flex-col'>
                                    <div className='flex gap-2'>
                                        <span className='text-xs font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong'>
                                            {f.framework_name}
                                        </span>
                                        <a href={f.framework_url} title='Learn more'><LinkSharp /></a>
                                    </div>
                                    <span className='text-xs text-tremor-content dark:text-dark-tremor-content'>
                                        {f.framework_authoritative}
                                    </span>
                                </div>
                                */
                            }
                        </MultiSelectItem>
                    ) : <span>No frameworks available</span>}
                </MultiSelect>
            </div>
            <div className='max-w-xs w-full'>
                <p className="mb-2 text-left text-sm text-zinc-500 dark:text-zinc-300">
                    Set a name
                </p>
                <TextInput className="max-w-xs" onValueChange={setQuestionnaireName} value={questionnaireName} />
            </div>
        </>
    )
}
/* eslint-disable */
// prettier-ignore