/* eslint-disable */
import { Base64 } from 'js-base64';
import { useState } from "react";
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/material-ui';
import { Collapse, Paper, Stack, TablePagination } from '@mui/material';

import { usePagination } from '@table-library/react-table-library/pagination';
import { useSort } from '@table-library/react-table-library/sort';

// custom stuffs s
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import cysrConfig from "layouts/cysr/config";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";

import { Checkbox, FormControlLabel } from '@mui/material';

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import CysrTags from "layouts/cysr/components/CysrTags";
import CysrTableButtonActions from "layouts/cysr/components/cysrTableButtonActions";
// import FormField from "layouts/pages/account/components/FormField";

import Radio from "@mui/material/Radio";
import { useMaterialUIController } from "context";
// custom stuffs e

const Component = ({ data, defaultSubscriptionID, setDefaultSubscriptionID, radioChangeCallback }) => {


  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedItemForDialog, setSelectedItemForDialog] = useState(null);

  const actionsPixelWidth = 202;
  const tableRowsPerPage = [25, 50, 100, 200, 400];
  const [tableTagsActive, setTableTagsActive] = useState(data.tableTagsActive);
  const [tableTagsOptions, setTableTagsOptions] = useState(data.tableTagsOptions);
  const [hideCompleted, setHideCompleted] = useState(data.hideCompleted);
  const [search, setSearch] = useState(data.search);
  const [isHide, setHide] = useState(true);
  // let tData = { nodes: isHide ? data.nodes.filter((node) => node.status != "ENDED" && node.subscription_status != "ENDED") : data.nodes };
  let tData = { nodes: isHide ? data.nodes.filter((node) => node.status != "ENDED" && node.status != "INTERRUPTED" && node.subscription_status != "ENDED" && node.subscription_status != "INTERRUPTED") : data.nodes };
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleSwitchSubscription = (item) => {
    setSelectedItemForDialog(item)
    handleClickOpen();
  }

  let accountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/company/") + "/company/".length,
    window.location.pathname.lastIndexOf("/")
  );
  if (localStorage.getItem("cysr_useraccountrole_selected")) {
    accountUUID = localStorage.getItem("cysr_useraccountrole_selected");
  }

  let COLUMNS = [];
  if (data.isOptions) {
    COLUMNS = [
      {
        label: 'Switch',
        renderCell: (item) =>
          <Radio
            checked={defaultSubscriptionID === item.subscription_uuid}
            onChange={() => handleSwitchSubscription(item)}
            value={item.subscription_uuid}
            name="radio-buttons"
            inputProps={{ 'aria-label': item.subscription_uuid }}
          />,
        sort: false,
        resize: false
      },
      {
        label: 'Status',
        renderCell: (item) => item.subscription_status,
        sort: { sortKey: 'STATUS' },
        resize: true,
        cysrTag: "status"
      },
      {
        label: 'Holder',
        renderCell: (item) => item.subscription_holder,
        sort: { sortKey: 'HOLDER' },
        resize: true,
        cysrTag: "subscription_holder"
      }
    ]
    if (data.showBillings) {
      COLUMNS.push({
        label: 'Billing',
        renderCell: (item) => item.subscription_billingcontact,
        sort: { sortKey: 'BILLING' },
        resize: true,
        cysrTag: "subscription_billingcontact"
      })
    }
    COLUMNS.push(
      {
        label: 'Type',
        renderCell: (item) => item.subscription_subscriptiontype && item.subscription_subscriptiontype.subscriptiontype_name ? item.subscription_subscriptiontype.subscriptiontype_name : "",
        sort: { sortKey: 'TYPE' },
        resize: true,
        cysrTag: "type"
      },
      {
        label: 'Start',
        renderCell: (item) =>
          item.start ? new Date(item.start).toLocaleDateString('it-IT', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }) : (
            item.subscription_start_datetime ? new Date(item.subscription_start_datetime).toLocaleDateString('it-IT', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }) : ""
          ),
        sort: { sortKey: 'START' },
        resize: true,
        cysrTag: "start",
        cysrTagDateFormat: 'it-IT'
      }
    )
  } else {
    COLUMNS = [
      {
        label: 'Status',
        renderCell: (item) => item.status,
        sort: { sortKey: 'STATUS' },
        resize: true,
        cysrTag: "status"
      },
      {
        label: 'Holder',
        renderCell: (item) => item.subscription_holder,
        sort: { sortKey: 'HOLDER' },
        resize: true,
        cysrTag: "subscription_holder"
      }
    ]
    if (data.showBillings) {
      COLUMNS.push(
        {
          label: 'Billing',
          renderCell: (item) => item.subscription_billingcontact,
          sort: { sortKey: 'BILLING' },
          resize: true,
          cysrTag: "subscription_billingcontact"
        }
      )
    }
    COLUMNS.push(
      {
        label: 'Type',
        renderCell: (item) => item.type,
        sort: { sortKey: 'TYPE' },
        resize: true,
        cysrTag: "type"
      },
      {
        label: 'Start',
        renderCell: (item) =>
          item.start ? new Date(item.start).toLocaleDateString('it-IT', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }) : "",
        sort: { sortKey: 'START' },
        resize: true,
        cysrTag: "start",
        cysrTagDateFormat: 'it-IT'
      },
      {
        label: 'End',
        renderCell: (item) =>
          item.end ? new Date(item.end).toLocaleDateString('it-IT', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }) : "",
        sort: { sortKey: 'END' },
        resize: true,
        cysrTag: "end",
        cysrTagDateFormat: 'it-IT'
      },
      /*
      {
        label: 'Companies',
        renderCell: (item) => item.companies_count ? item.companies_count : 0,
        // sort: { sortKey: 'COMPANIES_COUNT' },
        // resize: true,
        cysrTag: "companies_count"
      },
      */
      {
        label: 'Companies',
        renderCell: (item) => item.companies_active_count ? item.companies_active_count.replace("&infin;", "∞") : 0,
        // sort: { sortKey: 'COMPANIES_COUNT' },
        // resize: true,
        cysrTag: "companies_active_count"
      },
      {
        label: 'Third Parties',
        renderCell: (item) => item.thirdparty_active_count ? item.thirdparty_active_count.replace("&infin;", "∞") : 0
      },
      {
        label: 'Checks',
        renderCell: (item) => item.subscription_check_count ? item.subscription_check_count.replace("&infin;", "∞") : 0
      },
      {
        label: 'Actions',
        renderCell: (item) => (
          item.notVisitable ? "not visitable" :
            item.status != "ENDED" &&
            <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '8px', width: `${actionsPixelWidth}px` }}>
              <Link to={`/cysr/profile/manage-subscriptions/subscription#${(item.subscription_id ? item.subscription_id : item.subscription_uuid)}`}>
                <MDButton style={cysrConfig().button_style} size="medium">
                  Go to subscription
                  <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
                </MDButton>
              </Link>
            </div>
        ),
        pinRight: true
        // sort: { sortKey: 'RATING' }
      },
    );
  }

  // csv download s
  const escapeCsvCell = (cell) => {
    if (cell == null) {
      return '';
    }
    const sc = cell.toString().trim();
    if (sc === '' || sc === '""') {
      return sc;
    }
    if (sc.includes('"') || sc.includes(',') || sc.includes('\n') || sc.includes('\r')) {
      return '"' + sc.replace(/"/g, '""') + '"';
    }
    return sc;
  };

  const makeCsvData = (columns, data) => {
    return data.reduce((csvString, rowItem) => {
      return (
        csvString +
        columns.map(({ accessor }) => escapeCsvCell(accessor(rowItem))).join(',') +
        '\r\n'
      );
    }, columns.map(({ name }) => escapeCsvCell(name)).join(',') + '\r\n');
  };

  const downloadAsCsv = (columns, data, filename) => {
    const csvData = makeCsvData(columns, data);
    const csvFile = new Blob([csvData], { type: 'text/csv' });
    const downloadLink = document.createElement('a');

    downloadLink.display = 'none';
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDownloadCsv = () => {
    let columns = [
      { accessor: (item) => item.status, name: 'Status' },
      { accessor: (item) => item.subscription_holder, name: 'Holder' },
    ]
    if (data.showBillings) {
      columns.push({ accessor: (item) => item.subscription_billingcontact, name: 'Billing' })
    }
    columns.push(
      { accessor: (item) => item.type, name: 'Type' },
      { accessor: (item) => item.start, name: 'Start' },
      { accessor: (item) => item.end, name: 'End' },
      { accessor: (item) => item.companies_active_count.replace("&infin;", "∞"), name: 'Companies' },
      { accessor: (item) => item.thirdparty_active_count.replace("&infin;", "∞"), name: 'Third Parties' },
      { accessor: (item) => item.subscription_check_count.replace("&infin;", "∞"), name: 'Checks' },
    );

    downloadAsCsv(columns, data.nodes, 'Subscriptions');
  };
  // csv download e

  // expandable
  const [ids, setIds] = useState([]);
  let autoString = "";
  let firstAuto = 1;
  COLUMNS.forEach(c => {
    if (firstAuto === 0) {
      autoString += "auto ";
    } else {
      firstAuto--;
    }
  });
  const expandedTableStyle = `
    --data-table-library_grid-template-columns:  ${autoString} ${actionsPixelWidth}px!important;
    .animate {
      grid-column: 1 / -1;
      display: flex;
    }
    .animate {
        visibility: visible;
        border-bottom: 1px solid #${darkMode ? "4a4545" : "f0f2f5"};
    }
    .animate > div {
      flex: 1;
      display: flex;
    }
    
    @media (min-width: 768px){
      .pin-right {
        right: 0;
      }
    }
  `;

  const handleExpand = (item) => {
    if (ids.includes(item.subscription_id) || ids.includes(item.subscription_uuid)) {
      setIds(ids.filter((id) => id !== (item.subscription_id ? item.subscription_id : item.subscription_uuid)));
    } else {
      setIds(ids.concat((item.subscription_id ? item.subscription_id : item.subscription_uuid)));
    }
  };

  const switchSubscriptionConfirmed = (item) => {
    if (radioChangeCallback) {
      radioChangeCallback(item.subscription_uuid)
    } else {
      subscriptionRadioChange(item.subscription_uuid)
    }
  }

  const ROW_OPTIONS = {
    renderAfterRow: (item) => (
      <Collapse className="animate" in={ids.includes((item.subscription_id ? item.subscription_id : item.subscription_uuid))}>
        <div style={{ flex: '1', display: 'flex' }}>
          <div style={{ flex: '1', backgroundColor: darkMode ? "#121619" : "#edf0f2" }}>
            <MDBox style={{ position: "sticky", left: 0, right: 0 }} maxWidth={{ xs: "calc(100vw - (48px + 64px))", xl: "calc(100vw - (282px + 48px + 58px))" }}>
              <Card style={{ backgroundColor: darkMode ? "#121619" : "#edf0f2", color: darkMode ? "#7B809A" : "#7B809A", borderRadius: 0, boxShadow: "none", padding: "1em", margin: 0 }}>
                <MDBox width="100%">
                  {/*}
                    {tableDetailsRow(item.id, "Name", item.name)}
                    <Divider />
                    {tableDetailsRow(item.id, "Deadline", item.deadline, "date")}
                    <Divider />
                    {tableDetailsRow(item.id, "Type", item.generalasset_type)}
                    <Divider />
                    {tableDetailsRow(item.id, "Completed", item.isComplete.toString())}
                    {*/}
                </MDBox>
              </Card>
            </MDBox>
          </div>
        </div>
      </Collapse>
    ),
  };

  // filter list
  tData = {
    nodes: tData.nodes.filter((item) => {
      if (isHide && item.status == "ENDED" && node.subscription_status == "ENDED") {
        return false;
      }
      /*
      // check filter first
      if(hideCompleted && item.isComplete){
        return false
      }
      */
      // check tags
      // da fare tags con chiave valore
      if (tableTagsActive && tableTagsActive.length > 0) {
        let trueCount = tableTagsActive.length;
        const scoreValue = item.asset_last_rating && item.asset_last_rating.rating_val ? item.asset_last_rating.rating_val : "N/A";
        let textValue = "Bad";
        if (scoreValue === "N/A") {
          textValue = "N/A";
        } else if (scoreValue < 30) {
          textValue = "Bad";
        } else if (scoreValue < 50) {
          textValue = "Poor";
        } else if (scoreValue < 70) {
          textValue = "Fair";
        } else if (scoreValue < 90) {
          textValue = "Good";
        } else if (scoreValue < 100 || scoreValue == 100) {
          textValue = "Excellent";
        }
        tableTagsActive.forEach(ta => {
          let notFound = true;
          // X:item.cysrTag s
          if (ta && typeof ta === 'string' && ta.includes(":")) {
            const tag = ta.toLowerCase().split(":");
            const tagKey = tag[0].toLowerCase();
            const tagValue = tag[1].toString().trim();
            if (tagKey === "rating" && tagValue.toLowerCase() === textValue.toLowerCase()) {
              if (notFound) {
                trueCount--;
                notFound = false;
              }
            }
            COLUMNS.forEach(col => {
              if (col.cysrTag && item[col.cysrTag] && col.label.toLowerCase() === tagKey && item[col.cysrTag.toLowerCase()] && item[col.cysrTag.toLowerCase()]) {
                // check cysrTagDateFormat
                if (col.cysrTagDateFormat) {
                  const vd = new Date(item[col.cysrTag.toLowerCase()].toString()).toLocaleDateString(col.cysrTagDateFormat, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })
                  if (vd.toString().includes(tagValue) && notFound) {
                    trueCount--;
                    notFound = false;
                  }
                } else if (item[col.cysrTag.toLowerCase()].toString().toLowerCase().includes(tagValue)) {
                  if (notFound) {
                    trueCount--;
                    notFound = false;
                  }
                }
              }
            })
          }
          // X:item.cysrTag e
          // compare (for freeSolo) s
          if (ta && ta.rating && ta.rating === textValue) {
            if (notFound) {
              trueCount--;
              notFound = false;
            }
          }
          (data.showBillings ? [
            "status",
            "type",
            "subscription_status",
            "subscription_holder",
            "subscription_billingcontact",
            "start",
            "end"
          ] : [
            "status",
            "type",
            "subscription_status",
            "subscription_holder",
            "start",
            "end"
          ])
            .forEach(property => {
              if (ta && ta[property]) {
                // a default option (with [property])
                if (ta && ta[property] && item[property] && item[property].toLowerCase().includes(ta[property].toLowerCase())) {
                  if (notFound) {
                    trueCount--;
                    notFound = false;
                  }
                }
              } else {
                if (ta && ta.rating) {
                  // avoid checking again ta with title (rating Excellent, Good...)
                } else if (ta === textValue) {
                  // checking ta without title written by user ("Excellent", "Good"...)
                  if (notFound) {
                    trueCount--;
                    notFound = false;
                  }
                } else {
                  // not a default option (with [property])
                  if (ta && item[property] && item[property].toLowerCase().includes(ta.toLowerCase())) {
                    if (notFound) {
                      trueCount--;
                      notFound = false;
                    }
                  }
                }
              }
            });
          // compare (for freeSolo) e
        });
        if (trueCount !== 0) {
          return false;
        }
      }
      // check search at last
      // ..
      return true;
    }
    ),
  };

  const materialTheme = getTheme(DEFAULT_OPTIONS, {
    isVirtualized: true
  });

  // to set row border bottom style:
  // border-bottom: 1px solid #${darkMode ? "4a4545" : "f0f2f5"};

  const customTheme = {
    Table: expandedTableStyle,
    BaseCell: `
      border: 0!important;
      padding: 4px;
    `,
  };
  let theme = useTheme([materialTheme, customTheme]);
  if (darkMode) {
    // provide custom theme
    theme = useTheme([materialTheme, {
      Table: expandedTableStyle,
      BaseCell: `
        color: #ffffff;
        background-color: #192328;
        border: 0!important;
        padding: 4px;
      `,
    }
    ]);
  }

  const pagination = usePagination(tData, {
    state: {
      page: 0,
      // size: 2,
      // size: tData.nodes.length,
      size: tableRowsPerPage[0],
    },
    onChange: onPaginationChange,
  });

  function onPaginationChange(action, state) {
    console.log(action, state);
  }

  const sort = useSort(
    tData,
    {
      state: {
        sortKey: 'START',
        reverse: false
      },
      onChange: onSortChange,
    },
    {
      sortIcon: {
        iconDefault: null,
        iconUp: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
        </svg>
        ,
        iconDown: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
        ,
      },
      sortFns: {
        STATUS: (array) => array.sort((a, b) => (a.status ? a.status : a.subscription_status).localeCompare((b.status ? b.status : b.subscription_status))),
        HOLDER: (array) => array.sort((a, b) => a.subscription_holder.localeCompare(b.subscription_holder)),
        BILLING: (array) => array.sort((a, b) => a.subscription_billingcontact.localeCompare(b.subscription_billingcontact)),
        TYPE: (array) => array.sort((a, b) => (
          a.type ? a.type : (
            a.subscription_subscriptiontype && a.subscription_subscriptiontype.subscriptiontype_name ? a.subscription_subscriptiontype.subscriptiontype_name : ""
          )
        ).localeCompare(
          b.type ? b.type : (
            b.subscription_subscriptiontype && b.subscription_subscriptiontype.subscriptiontype_name ? b.subscription_subscriptiontype.subscriptiontype_name : ""
          )
        )),
        START: (array) => array.sort((a, b) =>
          new Date(b.start ? b.start : b.subscription_start_datetime).getTime() - new Date(a.start ? a.start : a.subscription_start_datetime).getTime()
        ),
        END: (array) => array.sort((a, b) =>
          new Date(b.end).getTime() - new Date(a.end).getTime()
        ),
        COMPANIES_COUNT: (array) => array.sort((a, b) => a.companies_count - b.companies_count),
      },
    },
  );

  function onSortChange(action, state) {
    console.log(action, state);
  }

  const handleSetTableTags = (event, t) => {
    // console.log(t)
    setTableTagsActive(t);
    // replace url s
    let params = new URLSearchParams(window.location.search)
    params.delete('t')
    if (t != "" && t.length > 0) {
      params.append("t", Base64.encode(JSON.stringify(t)))
    }
    window.history.replaceState(null, '', '?' + params + window.location.hash)
    // replace url e
    // go to first page
    pagination.fns.onSetPage(0)
  };

  const VIRTUALIZED_OPTIONS = {
    rowHeight: (_item, _index) => {
      return 54
    }
  };

  return (
    <>
      <Stack spacing={10} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CysrTags tableTagsOptions={tableTagsOptions} tableTagsActive={tableTagsActive} handleSetTableTags={handleSetTableTags} darkMode={darkMode} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox defaultChecked={isHide} onChange={() => setHide(!isHide)} />
              }
              label="Hide Ended"
            />
          </Grid>
        </Grid>
      </Stack>
      <Paper style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none', height: 'auto' }}>
        {
          // <Paper style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none', height: '360px' }}>
        }
        <CompactTable
          columns={COLUMNS}
          // passando le virtualizedOptions non funziona l'expand abilitato, penso perchè imposta l'height da solo
          // virtualizedOptions={VIRTUALIZED_OPTIONS}
          data={tData}
          sort={sort}
          theme={theme}
          rowOptions={ROW_OPTIONS}
          layout={{
            isDiv: true,
            fixedHeader: true
          }}
          pagination={pagination}
          style={{ borderColor: 'transparent' }}
        // horizontalScroll={true}
        />
      </Paper>
      <Grid mt={0} container style={{ alignItems: "center", justifyContent: "space-between" }} width="100%" spacing={1}>
        {data.isOptions ?
          <Dialog
            fullScreen={false}
            open={dialogOpen}
            onClose={handleClose}
            aria-labelledby="remove-user-dialog-title"
            style={{
              background: darkMode ? 'rgba(0,0,0,0.25)' : 'rgba(255, 255, 255, 0.25)',
              backdropFilter: "blur(12px)"
            }}>
            <div style={{ background: darkMode ? '#192328' : '#ffffff' }}>
              <DialogTitle id="remove-user-dialog-title">
                {"Confirm switching subscription?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {selectedItemForDialog &&
                    <>
                      <MDTypography variant="body2">
                        If you agree this company subscription will change {`${selectedItemForDialog.subscription_holder ? ` to ${selectedItemForDialog.subscription_holder}'s ` : ""}subscription${selectedItemForDialog.subscription_subscriptiontype && selectedItemForDialog.subscription_subscriptiontype.subscriptiontype_name ? ` of type ${selectedItemForDialog.subscription_subscriptiontype.subscriptiontype_name}` : ""}`}
                      </MDTypography>
                      <MDTypography variant="body2" color="error">
                        You may lose permission to view the current page
                      </MDTypography>
                      <MDTypography variant="body2">
                        Do you want to proceed?
                      </MDTypography>
                    </>
                  }
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <MDButton style={cysrConfig().button_style} size="medium" autoFocus onClick={handleClose}>
                  Disagree
                </MDButton>
                <MDButton style={cysrConfig().button_style} size="medium" onClick={() => switchSubscriptionConfirmed(selectedItemForDialog)} autoFocus>
                  Agree
                </MDButton>
              </DialogActions>
            </div>
          </Dialog>
          :
          <Grid item>
            <CysrTableButtonActions
              options={
                [
                  {
                    label: "CSV",
                    color: "info",
                    value: "CSV",
                    onClick: "download_csv",
                    key: 0,
                    icon: cysrConfig().report.icon.svg
                  }
                ]
              }
              triggerAction={
                function (fun) {
                  switch (fun) {
                    case "download_csv":
                      handleDownloadCsv()
                      break;

                    default:
                      break;
                  }
                }
              } />
          </Grid>
        }
        <Grid item ml={"auto"}>
          <TablePagination
            component="div"
            style={{ color: darkMode ? "#ffffff" : "#000000", border: 0 }}
            count={tData.nodes.length}
            page={pagination.state.page}
            rowsPerPage={pagination.state.size}
            rowsPerPageOptions={tableRowsPerPage}
            onRowsPerPageChange={(event) =>
              pagination.fns.onSetSize(parseInt(event.target.value, 10))
            }
            onPageChange={(event, page) => pagination.fns.onSetPage(page)}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default Component;
/* eslint-disable */