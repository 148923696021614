
import { useState, useEffect } from "react";
import Select, { components } from 'react-select'
import MDSnackbar from "components/MDSnackbar";
import cysrConfig from "layouts/cysr/config";

/* eslint-disable */
// custom select
const { Option } = components
const CustomSelectOption = props => (
  <Option {...props}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={{ color: localStorage.getItem("cysr_dark_mode") === "false" ? "#000000" : "#FFFFFF", display: "inline-flex", alignItems: "center", transform: "scale(0.75)", marginRight: "12px" }} dangerouslySetInnerHTML={{ __html: props.data.icon }} />
      <span style={{ fontSize: "14px", lineHeight: "1" }}>{props.data.label}</span>
    </div>
  </Option>
)

const CustomSelectValue = props => (
  <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", display: "flex", alignItems: "center" }}>
    <span style={cysrConfig().button_icon_left_style_select} dangerouslySetInnerHTML={{ __html: props.data.icon }} />
    <span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", fontSize: "16px" }}>{props.data.label}</span>
  </div>
)
const CustomSelectValueLoading = props => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <span style={cysrConfig().button_icon_left_style} className="spin" dangerouslySetInnerHTML={{ __html: cysrConfig().loading.icon.svg }} />
    <span style={{ fontSize: "16px", lineHeight: "1" }}>{props.data.label}</span>
  </div>
)

const Svg = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div style={{ color: localStorage.getItem("cysr_dark_mode") === "false" ? "#0D918F" : "#bdc7d0", height: 20, width: 20, display: "flex", alignItems: "center" }}>
    <Svg>
      <path d="M10 13.75L3.75 7.5L4.625 6.625L10 12L15.375 6.625L16.25 7.5L10 13.75Z" fill="currentColor" />
    </Svg>
  </div>
);

const colourStyles = {
  valueContainer: (base) => ({
    ...base,
    width: '100%',
    minWidth: '160px',
    display: "flex",
  }),
  indicatorsContainer: (styles) => ({ ...styles, height: "100%" }),
  indicatorSeparator: (styles) => ({ ...styles, border: "0", backgroundColor: "transparent" }),
  control: (styles, { isDisabled }) => ({
    ...styles, border: "0", color: isDisabled
      ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#bdc7d0" : "#666666") : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: isDisabled
        ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#EEF0F1" : "#17191c") : (localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b"), height: "32px", minHeight: "32px", minWidth: "160px"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused
            ? undefined
            : undefined,
      color: isDisabled
        ? '#ccc'
        : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
          && data.color
          : undefined,
      },
    };
  },
  menuList: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b" }),
  input: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
  placeholder: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
  singleValue: (styles, { data }) => ({ ...styles }),
};
const loadingStyles = {
  valueContainer: (base) => ({
    ...base,
    width: '100%',
    display: "flex",
  }),
  indicatorsContainer: (styles) => ({ ...styles, height: "100%" }),
  indicatorSeparator: (styles) => ({ ...styles, border: "0", backgroundColor: "transparent" }),
  control: (styles, { isDisabled }) => ({
    ...styles, border: "0", color: isDisabled
      ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#bdc7d0" : "#666666") : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: isDisabled
        ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#EEF0F1" : "#17191c") : (localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b"), height: "32px", minHeight: "32px"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused
            ? undefined
            : undefined,
      color: isDisabled
        ? '#ccc'
        : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
          && data.color
          : undefined,
      },
    };
  },
  menuList: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b" }),
  input: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
  placeholder: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
  singleValue: (styles, { data }) => ({ ...styles }),
};

export default function CysrDropdown(data) {
  // snackbar (source/status) s
  const [sshow, setSShow] = useState(false);


  const [msgDateTime, setMsgDateTime] = useState("Information");
  const [msgIcon, setMsgIcon] = useState("info");
  const [msgTheme, setMsgTheme] = useState("light");
  const [msgTitle, setMsgTitle] = useState("Recheck not executed");
  // reacthtml msgContent:
  const [msgContent, setMsgContent] = useState(
    <></>
  )

  const defaultToggleSnackbar = () => {
    setSShow(!sshow);
  }
  const toggleSnackbar = (title = errorTitle, msg = errorMsg, theme = "error", icon = "error", dateTime = "Error") => {
    setMsgDateTime(dateTime);
    setMsgIcon(icon);
    setMsgTheme(theme);

    setMsgTitle(title);
    setMsgContent(msg);
    setSShow(!sshow);
  }

  // snackbar (source/status) e

  // const [dropdownAsset, setDropdownAsset] = useState(data.data.asset);
  let dropdownAsset = data.data.asset;

  const [dropdownDisabled, setDropdownDisabled] = useState(data.data.isDisabled);
  const [dropdownLoading, setDropdownLoading] = useState(data.data.isLoading);
  const [dropdownOptions, setDropdownOptions] = useState(data.data.options);
  // const [dropdownDefaultValue, setDropdownDefaultValue] = useState(data.data.defaultValue);
  const [dropdownValue, setDropdownValue] = useState(data.data.value);
  // const [dropdownOnChange, setOnChange] = useState(data.data.onChange);
  let dropdownOnChange = data.data.onChange;

  const handleChangeStatus = (newValue) => {
    const changeStatusAssetUrl = `${cysrConfig().api_base_url}asset/${dropdownAsset.asset_uuid}/check/status/`;
    fetch(changeStatusAssetUrl, {
      body: JSON.stringify({ "asset_status": newValue }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      method: "PATCH",
    })
      .then((response) => {
        if (response.ok) {
          setDropdownValue(dropdownOptions.find((opt) => opt.value === newValue))
          setDropdownLoading(false)
          // setRadioValue(newValue);

        } else {
          setDropdownLoading(false)
          return response.json().then(err => {
            throw JSON.stringify(err)
          })
        }
      })
      .then((statusData) => {
        console.log(statusData);
        setDropdownLoading(false)
        // window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        console.log("err4:", err);
        toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail);
        setDropdownLoading(false)
      });
  }
  const assetToChecker = () => {
    const assetUUID = dropdownAsset.asset_uuid;
    const dataAsset = {
      source: "checker"
    };
    // /{version}/asset/{asset_req_uuid}/recheck/

    const assetToManualUrl = `${cysrConfig().api_base_url}asset/${assetUUID}/source/`;
    fetch(assetToManualUrl, {
      body: JSON.stringify(dataAsset),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      method: "PATCH",
    })
      .then((response) => {
        if (response.ok) {
          setDropdownValue(dropdownOptions.find((opt) => opt.value === "auto" || opt.value === "checker"))
          setDropdownLoading(false)
          return response.json();
        } else {
          console.error(response);
          return response.json().then(err => {
            throw JSON.stringify(err)
          })
          // setDropdownLoading(false)
          // return response.json()
        }
      })
      .then((recheckData) => {
        console.log(recheckData);
        if (recheckData && recheckData.CheckDetail && recheckData.CheckDetail !== "OK") {
          alert(recheckData.CheckDetail)
          console.warn(recheckData.CheckDetail);
        }
      })
      .catch((err) => {
        console.log("err1:", err);
        toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail);
        setDropdownLoading(false)
        console.log(err);
      });
  };
  const assetToManual = () => {
    const assetUUID = dropdownAsset.asset_uuid;
    const dataAsset = {
      source: "manual"
    };
    // /{version}/asset/{asset_req_uuid}/recheck/

    const assetToManualUrl = `${cysrConfig().api_base_url}asset/${assetUUID}/source/`;
    fetch(assetToManualUrl, {
      body: JSON.stringify(dataAsset),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      method: "PATCH",
    })
      .then((response) => {
        console.log(response)
        if (response.ok) {
          setDropdownValue(dropdownOptions.find((opt) => opt.value === "manual"))
          setDropdownLoading(false)
          // setRadioValue(newValue);
          return response.json();
        } else {
          console.error(response);
          return response.json().then(err => {
            throw JSON.stringify(err)
          })
          setDropdownLoading(false)
          return response.json()
        }
      })
      .then((recheckData) => {
        console.log(recheckData);
        if (recheckData && recheckData.CheckDetail && recheckData.CheckDetail !== "OK") {
          alert(recheckData.CheckDetail)
          console.warn(recheckData.CheckDetail);
        }
      })
      .catch((err) => {
        console.log("err2:", err);
        toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail);
        setDropdownLoading(false)
        console.log(err);
      });
  };
  const handleChangeSource = (newValue) => {
    setDropdownLoading(true)
    if (newValue === "auto" || newValue === "checker") {
      assetToChecker()
    } else if (newValue === "manual") {
      assetToManual()
    }
  }

  const handleChangeValue = (event, newValue) => {
    setDropdownLoading(true)
    if (event instanceof Function) {
      event(newValue)
      setDropdownValue(dropdownOptions.find((opt) => opt.value === newValue))
      setDropdownLoading(false)
      return newValue
    }
    switch (event) {
      case "source":
        handleChangeSource(newValue)
        break;

      case "status":
        handleChangeStatus(newValue)
        break;

      default:
        break;
    }
  }
  useEffect(() => {
    setDropdownValue(data.data.value)
  }, [dropdownAsset]);
  // setDropdownValue() is called whenever dropdownAsset is updated.

  return (
    <>
      {dropdownDisabled ?
        <Select cacheOptions={false} isSearchable={false} options={dropdownOptions} value={dropdownValue} styles={colourStyles} isDisabled components={{ DropdownIndicator, Option: CustomSelectOption, SingleValue: CustomSelectValue }} />
        :
        <Select cacheOptions={false} isSearchable={false} options={dropdownOptions} value={dropdownValue} styles={dropdownLoading ? loadingStyles : colourStyles} onChange={(newValue) => {
          handleChangeValue(dropdownOnChange, newValue.value)
        }}
          components={{ DropdownIndicator, Option: CustomSelectOption, SingleValue: dropdownLoading ? CustomSelectValueLoading : CustomSelectValue }} />
      }
      <MDSnackbar
        color={msgTheme}
        icon={msgIcon}
        title={msgTitle}
        content={msgContent}
        dateTime={msgDateTime}
        open={sshow}
        close={defaultToggleSnackbar}
      />
    </>
  );
}
/* eslint-disable */