/* eslint-disable */
// prettier-ignore

import {
    // checkVAT,
    // eu 27
    austria, belgium, bulgaria, croatia, cyprus, czechRepublic, denmark, estonia, finland, france, germany, greece, hungary, ireland, italy, latvia, lithuania, luxembourg, malta, netherlands, poland, portugal, romania, slovakiaRepublic, slovenia, spain, sweden
    ,// noneu27
    // albania, 
    andorra,
    // belarus, bosnia, faroe, gibraltar, greenland, iceland, kosovo, liechtenstein, macedonia, moldova, monaco, montenegro, 
    norway,
    // sanMarino, 
    serbia, switzerland,
    // ukraine, 
    unitedKingdom
    //,// asia armenia, azerbaijan, georgia, russia, turkey

} from 'jsvat';
// add missing countries 
// https://github.com/se-panfilov/jsvat#extend-countries-list---add-your-own-country
// using ISO 3166 
// https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
export const albania = {
    name: 'Albania',
    codes: ['AL', 'ALB', '008'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const belarus = {
    name: 'Belarus',
    codes: ['BY', 'BLR', '112'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const bosnia = {
    name: 'Bosnia and Herzegovina',
    codes: ['BA', 'BIH', '070'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const faroe = {
    name: 'Faroe Islands',
    codes: ['FO', 'FRO', '234'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const gibraltar = {
    name: 'Gibraltar',
    codes: ['GI', 'GIB', '292'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const greenland = {
    name: 'Greenland',
    codes: ['GL', 'GRL', '304'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const iceland = {
    name: 'Iceland',
    codes: ['IS', 'ISL', '352'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const kosovo = {
    name: 'Kosovo',
    codes: ['XK', 'RKS', '383'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const liechtenstein = {
    name: 'Liechtenstein',
    codes: ['LI', 'LIE', '438'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const macedonia = {
    name: 'Macedonia',
    codes: ['MK', 'MKD', '807'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const moldova = {
    name: 'Moldova',
    codes: ['MD', 'MDA', '498'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const monaco = {
    name: 'Monaco',
    codes: ['MC', 'MCO', '492'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const montenegro = {
    name: 'Montenegro',
    codes: ['ME', 'MNE', '499'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const sanMarino = {
    name: 'San Marino',
    codes: ['SM', 'SMR', '674'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const ukraine = {
    name: 'Ukraine',
    codes: ['UA', 'UKR', '804'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const armenia = {
    name: 'Armenia',
    codes: ['AM', 'ARM', '051'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const azerbaijan = {
    name: 'Azerbaijan',
    codes: ['AZ', 'AZE', '031'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const georgia = {
    name: 'Georgia',
    codes: ['GE', 'GEO', '268'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const russia = {
    name: 'Russian Federation',
    codes: ['RU', 'RUS', '643'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const turkey = {
    name: 'Turkey',
    codes: ['TR', 'TUR', '792'],
    calcFn: (vat) => {
        return false
        // return vat.length === 10;
    },
    rules: {
        // regex: [/^(WD)(\d{8})$/]
        regex: [/^(SOMETHINGTHATDOESNOTMATCH)(\d{8})$/]
    }
};
export const countries = [
    // eu 27
    austria, belgium, bulgaria, croatia, cyprus, czechRepublic, denmark, estonia, finland, france, germany, greece, hungary, ireland, italy, latvia, lithuania, luxembourg, malta, netherlands, poland, portugal, romania, slovakiaRepublic, slovenia, spain, sweden
    ,// noneu27
    albania, andorra, belarus, bosnia, faroe, gibraltar, greenland, iceland, kosovo, liechtenstein, macedonia, moldova, monaco, montenegro, norway, sanMarino, serbia, switzerland, ukraine, unitedKingdom
    ,// asia
    armenia, azerbaijan, georgia, russia, turkey
].sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
});
let countriesAlpha2Codes = [];
countries.forEach(c => {
    // push 2 letter code (Alpha-2 code)
    countriesAlpha2Codes.push(c.codes[0])
});
countriesAlpha2Codes.push("other");
export const countriesCodes = countriesAlpha2Codes;