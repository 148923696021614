/* eslint-disable */
import { Base64 } from 'js-base64';
import { useState } from "react";
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/material-ui';
import { Checkbox, Collapse, FormControlLabel, Paper, Stack, TablePagination, TextField } from '@mui/material';
import { Separator } from "layouts/cysr/components/ui/separator";

import { usePagination } from '@table-library/react-table-library/pagination';
import { useSort } from '@table-library/react-table-library/sort';

import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "layouts/cysr/components/ui/dropdown-menu"
import { Button } from "layouts/cysr/components/ui/button"
import CysrTableAssets from "./issues-assets"

// custom stuffs s
import cysrConfig from "layouts/cysr/config";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ScoreItem from "layouts/cysr/components/ScoreItem";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import StatusItem from "layouts/cysr/components/StatusItem";
import CysrTags from "layouts/cysr/components/CysrTags";
import RenderMarkdown from "layouts/cysr/components/RenderMarkdown"
// import FormField from "layouts/pages/account/components/FormField";

import CysrTableButtonActions from "layouts/cysr/components/cysrTableButtonActions";
import { useMaterialUIController } from "context";
import { assetAdditionalInfo, assetTags } from "../../assetAdditionalInfo";
import { KeyboardArrowDownSharp } from "@mui/icons-material";
// custom stuffs e

export function IssueBadge({ text }) {
    return (
        text.toUpperCase() === "INFO" ? (
            <span className="inline-flex items-center gap-x-1.5 py-0.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 dark:bg-white/10 dark:text-white">
                {text}
            </span>
        ) : text.toUpperCase() === "POSITIVE" ? (
            <span className="inline-flex items-center gap-x-1.5 py-0.5 px-3 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-800/30 dark:text-blue-500">
                {text}
            </span>
        ) : text.toUpperCase() === "LOW" ? (
            <span className="inline-flex items-center gap-x-1.5 py-0.5 px-3 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 dark:bg-yellow-800/30 dark:text-yellow-500">
                {text}
            </span>
        ) : text.toUpperCase() === "MEDIUM" ? (
            <span className="inline-flex items-center gap-x-1.5 py-0.5 px-3 rounded-full text-xs font-medium bg-orange-100 text-orange-800 dark:bg-orange-800/30 dark:text-orange-500">
                {text}
            </span>
        ) : text.toUpperCase() === "HIGH" ? (
            <span className="inline-flex items-center gap-x-1.5 py-0.5 px-3 rounded-full text-xs font-medium bg-red-100 text-red-800 dark:bg-red-800/30 dark:text-red-500">
                {text}
            </span>
        ) : (
            <span className="inline-flex items-center gap-x-1.5 py-0.5 px-3 rounded-full text-xs font-medium bg-white/10 text-white">
                {text}
            </span>
        )
    );
}

const Component = ({ data }) => {
    const actionsPixelWidth = 228//data.withoutAssetLink ? 72 : 228;
    const tableRowsPerPage = [25, 50, 100, 200, 400];
    const [tableTagsActive, setTableTagsActive] = useState(data.tableTagsActive);
    const [tableTagsOptions, setTableTagsOptions] = useState(data.tableTagsOptions);
    const [hideCompleted, setHideCompleted] = useState(data.hideCompleted);
    const [search, setSearch] = useState(data.search);
    // https://react-table-library.com/?path=/docs/compact-table--columnhide
    const [hiddenColumns, setHiddenColumns] = useState([
        "First Seen"
    ]);
    const toggleColumn = (column) => {
        if (hiddenColumns.includes(column)) {
            setHiddenColumns(hiddenColumns.filter((v) => v !== column));
        } else {
            setHiddenColumns(hiddenColumns.concat(column));
        }
    };
    // console.log(data)
    let tData = { nodes: data.nodes };
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    let accountUUID = window.location.pathname.substring(
        window.location.pathname.indexOf("/company/") + "/company/".length,
        window.location.pathname.lastIndexOf("/")
    );
    if (localStorage.getItem("cysr_useraccountrole_selected")) {
        accountUUID = localStorage.getItem("cysr_useraccountrole_selected");
    }
    let customColumns = [
        {
            label: 'Severity',
            renderCell: (item) => <IssueBadge text={item?.higher_severity_descr ?? ""} />,
            sort: { sortKey: 'SEVERITY' },
            resize: true,
            cysrTag: "higher_severity",
            hide: hiddenColumns.includes("Severity"),
        },
    ];
    if (data.isVulnerabilities) {
        customColumns.push(
            {
                label: 'Vulnerability',
                renderCell: (item) => item.issuedescr_shortname ?? item.issuedescr_key,
                sort: { sortKey: 'NAME' },
                resize: true,
                cysrTag: "issuedescr_shortname",
                hide: hiddenColumns.includes("Issue"),
            },
            {
                label: 'CVE Severity',
                renderCell: (item) => item.categories.map(c => <div key={c}><span>{c.category_longname ?? c.category}</span>{
                    item.cve_score
                        ? <span> ({item.cve_score})</span>
                        : item.issuedescr_details?.cvss3_base
                            ? <span> ({item.issuedescr_details?.cvss3_base})</span>
                            : null
                }</div>

                ),
                sort: { sortKey: 'CVESCORE' },
                resize: true,
                // cysrTag: "higher_severity_descr",
                hide: hiddenColumns.includes("CVE Severity"),
            })
    } else {
        customColumns.push(
            {
                label: 'Issue',
                renderCell: (item) => item.issuedescr_shortname ?? item.issuedescr_key,
                sort: { sortKey: 'NAME' },
                resize: true,
                cysrTag: "issuedescr_shortname",
                hide: hiddenColumns.includes("Issue"),
            })
    }
    if (data.isAsset) {
        // nothing
    } else {
        customColumns.push(
            {
                label: 'Findings',
                renderCell: (item) => item.assets?.length,
                sort: { sortKey: 'FINDINGS' },
                resize: true,
                // cysrTag: "higher_severity_descr",
                hide: hiddenColumns.includes("Findings"),
            })
    }
    customColumns.push(
        {
            label: 'First Seen',
            renderCell: (item) =>
                item.issue_first_seen || item.issue_first_seen_on_account ? new Date(item.issue_first_seen ?? item.issue_first_seen_on_account).toLocaleDateString('it-IT', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                }) : "",
            // sort: { sortKey: 'CREATED' },
            resize: true,
            cysrTag: "issue_first_seen",
            cysrTagDateFormat: 'it-IT',
            hide: hiddenColumns.includes("First Seen"),
        },
        {
            label: 'Last Seen',
            renderCell: (item) =>
                item.issue_last_seen || item.issue_last_seen_on_account ? new Date(item.issue_last_seen ?? item.issue_last_seen_on_account).toLocaleDateString('it-IT', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                }) : "",
            // sort: { sortKey: 'CREATED' },
            resize: true,
            cysrTag: "issue_last_seen",
            cysrTagDateFormat: 'it-IT',
            hide: hiddenColumns.includes("Last Seen"),
        },
        {
            label: 'Actions',
            renderCell: (item) => (
                <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '8px', width: `${actionsPixelWidth}px` }}>
                    {item.issuedescr_key ?
                        <Link to={`/company/${accountUUID}/${"issue"}#${item.issuedescr_key}`}>
                            <MDButton style={cysrConfig().button_style} size="medium">
                                {data.isVulnerabilities ? "CVE" : "Issue"} detail
                                <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
                            </MDButton>
                        </Link> : null
                    }
                    <MDButton onClick={() => handleExpand(item)} style={cysrConfig().button_style} size="medium">
                        {ids && ids.includes(item.issuedescr_key) ? <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} /> : <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />}
                    </MDButton>
                </div>
            ),
            pinRight: true
            // sort: { sortKey: 'RATING' }
        }
    );
    /*
        // per ora far vedere anche colonna asset
        // if(data.withoutAssetLink === undefined || data.withoutAssetLink === false) {
        customColumns.push(
            {
                label: 'Asset',
                renderCell: (item) => item.generalasset_val,
                sort: { sortKey: 'ASSET' },
                resize: true,
                cysrTag: "generalasset_val",
                hide: hiddenColumns.includes("Asset"),
            }
        )
        // };
        customColumns.push(
            {
                label: 'Name',
                renderCell: (item) => item.checkdetaildescr_name,
                sort: { sortKey: 'NAME' },
                resize: true,
                cysrTag: "checkdetaildescr_name",
                hide: hiddenColumns.includes("Name"),
            },
            {
                label: 'Value',
                renderCell: (item) => item.ratingdetail_val && item.ratingdetail_val != "" ? item.ratingdetail_val : "False",
                sort: { sortKey: 'VALUE' },
                resize: true,
                cysrTag: "ratingdetail_val",
                hide: hiddenColumns.includes("Value"),
            }
        )
        if (data.withoutAssetLink) {
            // non aggiungere additional info + highlights in checks sotto assets p1/2
        } else {
            customColumns.push(
                {
                    label: 'Additional info',
                    renderCell: (item) => assetAdditionalInfo(item.generalasset_additional_info),
                    sort: false,
                    resize: true,
                    cysrTag: "generalasset_additional_info",
                    hide: hiddenColumns.includes("Additional info"),
                },
                {
                    label: 'Highlights',
                    renderCell: (item) => assetTags(item.generalasset_tags),
                    sort: false,
                    resize: true,
                    cysrTag: "generalasset_tags",
                    hide: hiddenColumns.includes("Highlights"),
                }
            )
        }
        customColumns.push(
            {
                label: 'Weight',
                renderCell: (item) => Number(item.ratingdetail_rating).toFixed(4),
                sort: { sortKey: 'WEIGHT' },
                resize: true,
                cysrTag: "ratingdetail_rating",
                hide: hiddenColumns.includes("Weight"),
            },
            {
                label: 'Actions',
                renderCell: (item) => (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '8px', width: `${actionsPixelWidth}px` }}>
                        {data.withoutAssetLink ? null :
                            <Link to={`/company/${accountUUID}/${"asset"}#${item.asset_uuid}`}>
                                <MDButton style={cysrConfig().button_style} size="medium">
                                    Go to asset
                                    <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
                                </MDButton>
                            </Link>
                        }
                        <MDButton onClick={() => handleExpand(item)} style={cysrConfig().button_style} size="medium">
                            {ids && ids.includes(item.issuedescr_key) ? <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} /> : <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />}
                        </MDButton>
                    </div>
                ),
                pinRight: true
                // sort: { sortKey: 'RATING' }
            }
        );
    */
    // csv download s
    const escapeCsvCell = (cell) => {
        if (cell == null) {
            return '';
        }
        const sc = cell.toString().trim();
        if (sc === '' || sc === '""') {
            return sc;
        }
        if (sc.includes('"') || sc.includes(',') || sc.includes('\n') || sc.includes('\r')) {
            return '"' + sc.replace(/"/g, '""') + '"';
        }
        return sc;
    };

    const makeCsvData = (columns, data) => {
        return data.reduce((csvString, rowItem) => {
            return (
                csvString +
                columns.map(({ accessor }) => escapeCsvCell(accessor(rowItem))).join(',') +
                '\r\n'
            );
        }, columns.map(({ name }) => escapeCsvCell(name)).join(',') + '\r\n');
    };

    const downloadAsCsv = (columns, data, filename) => {
        const csvData = makeCsvData(columns, data);
        const csvFile = new Blob([csvData], { type: 'text/csv' });
        const downloadLink = document.createElement('a');

        downloadLink.display = 'none';
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const handleDownloadCsv = () => {
        let columns = [
            { accessor: (item) => item?.higher_severity_descr, name: 'Severity' },
            { accessor: (item) => item?.issuedescr_shortname ?? item?.issuedescr_key, name: data.isVulnerabilities ? 'Vulnerability' : 'Issue' },
            // { accessor: (item) => item.generalasset_type, name: 'Type' },
            // { accessor: (item) => item.generalasset_val, name: 'Asset' },
            // { accessor: (item) => item.ratingdetail_val && item.ratingdetail_val != "" ? item.ratingdetail_val : "False", name: 'Value' }
        ]

        if (data.isVulnerabilities) {
            columns.push(
                { accessor: (item) => item.categories && item.categories.map(c => `${c.category_longname ?? c.category}${item.cve_score 
                    ? ` (${item.cve_score})` 
                    : item.issuedescr_details?.cvss3_base ?
                    ` (${item.issuedescr_details?.cvss3_base})` 
                    : ""}`), name: 'CVE Severity' }
            )
        }
        if (data.isAsset) {
            // nothing
        } else {
            columns.push(
                { accessor: (item) => item.assets && item.assets.length, name: 'Findings' },
            )
        }
        columns.push(
            {
                accessor: (item) => item.issue_first || item.issue_first_seen_on_account && (
                    new Date(item.issue_first_seen ?? item.issue_first_seen_on_account).toLocaleDateString('it-IT', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    }) ?? ""
                ), name: 'First Seen'
            },
            {
                accessor: (item) => item.issue_last_seen || item.issue_last_seen_on_account && (
                    new Date(item.issue_first_seen ?? item.issue_last_seen_on_account).toLocaleDateString('it-IT', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    }) ?? ""
                ), name: 'Last Seen'
            },
        )
        downloadAsCsv(columns, data.nodes, 'Issues');
    };
    // csv download e
    const COLUMNS = customColumns;

    // expandable
    const [ids, setIds] = useState([]);
    let autoString = "";
    // actions is hide undefined so not pass filter
    COLUMNS.filter((y) => y.hide !== undefined && y.hide !== true).forEach(c => {
        // set 50px for status
        if (c.label === "Status") {
            autoString += " 50px";
        }
        // set 60px for source
        else if (c.label === "Source") {
            autoString += " 60px";
        }
        // set 70px for type
        else if (c.label === "Type") {
            autoString += " 70px";
        }
        // set 88px for weight
        else if (c.label === "Weight") {
            autoString += " 88px";
        }
        // set 100px for created/updated
        else if (c.label === "First Seen" || c.label === "Last Seen") {
            autoString += " 100px";
            // set 112px for rating
        } else if (c.label === "Rating") {
            autoString += " 112px";
        }
        // set 320px for info
        else if (c.label === "Additional info") {
            autoString += " 320px";
        } else {
            autoString += " minmax(0, 1fr)";
        }
    });
    const expandedTableStyle = `    
    --data-table-library_grid-template-columns:${autoString} ${actionsPixelWidth}px!important;

    .animate {
      grid-column: 1 / -1;
      display: flex;
    }
    .animate {
        visibility: visible;
        border-bottom: 1px solid #${darkMode ? "4a4545" : "f0f2f5"};
    }
    .animate > div {
      flex: 1;
      display: flex;
    }
    
    @media (min-width: 768px){
      .pin-right {
        right: 0;
      }
    }
  `;

    const handleExpand = (item) => {
        if (ids.includes(item.issuedescr_key)) {
            setIds(ids.filter((id) => id !== item.issuedescr_key));
        } else {
            setIds(ids.concat(item.issuedescr_key));
        }
    };

    function tableDetailsRow(sid, title, value, formatValue = false) {
        return (
            <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
            >
                {
                    // <MDTypography variant="body2" color="text"> 
                }
                <MDTypography variant="button" color="text">
                    {title}
                </MDTypography>
                <MDBox
                    display="flex"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                >
                    <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                        <MDTypography variant="button" color="text" fontWeight="regular">
                            {formatValue && formatValue === "date" ? (
                                new Date(value).toLocaleDateString('it-IT', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                })
                            ) :
                                value
                            }
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </MDBox>
        )
    }

    const ROW_OPTIONS = {
        renderAfterRow: (item) => (
            <Collapse className="animate" in={ids.includes(item.issuedescr_key)}>
                <Card style={{ backgroundColor: darkMode ? "#192328" : "#f0f2f5", color: darkMode ? "#7B809A" : "#7B809A", borderRadius: 0, boxShadow: "none", padding: "1em", margin: 0 }}>
                    <MDBox width="100%">
                        <div className='mb-1 flex gap-4'>
                            {data.isVulnerabilities ? null : item.categories?.map(c => {
                                return <div className='flex gap-2 items-center text-black dark:text-white'>
                                    <span className='text-sm'>{c.category_longname ?? c.category}</span><IssueBadge text={c.severity_descr} /></div>
                            })}
                        </div>
                        <MDTypography variant="h4" className="text-2xl text-black dark:text-white">{item.issuedescr_shortname ?? item.issuedescr_key}</MDTypography>

                        {item.issuedescr_description && <p className="max-w-3xl mb-4" style={{ color: darkMode ? "#ffffff" : "#000000", fontSize: "14px" }}><RenderMarkdown>{item.issuedescr_description}</RenderMarkdown></p>}

                        {data.isAsset ? null :
                            <div className='mt-1 flex flex-col gap-2'>
                                {item.assets && item.assets?.length ? <div>
                                    <MDTypography variant="h5" fontWeight="medium">Assets</MDTypography>
                                    <IssuesAssets assets={item.assets} accountUUID={accountUUID} />
                                </div>
                            /*
                                item.assets && item.assets?.length ? item.assets?.map(a => {
                                return <IssuesAssets a={a} accountUUID={accountUUID} />
                            }) */ : null

                                }
                            </div>}
                    </MDBox>
                </Card>
            </Collapse>
        ),
    };

    // filter list

    tData = {
        nodes: tData?.nodes?.filter((item) => {
            // check tags
            // da fare tags con chiave valore
            if (tableTagsActive && tableTagsActive.length > 0) {
                let trueCount = tableTagsActive.length;
                /*
                const scoreValue = item.asset_last_rating && a.generalasset_rating ? a.generalasset_rating : "N/A";
                let textValue = "Bad";
                if (scoreValue === "N/A") {
                    textValue = "N/A";
                } else if (scoreValue < 30) {
                    textValue = "Bad";
                } else if (scoreValue < 50) {
                    textValue = "Poor";
                } else if (scoreValue < 70) {
                    textValue = "Fair";
                } else if (scoreValue < 90) {
                    textValue = "Good";
                } else if (scoreValue < 100 || scoreValue == 100) {
                    textValue = "Excellent";
                }
                    */
                tableTagsActive.forEach(ta => {
                    let notFound = true;
                    // X:item.cysrTag s
                    if (ta && typeof ta === 'string' && ta.includes(":")) {
                        const tag = ta.toLowerCase().split(":");
                        const tagKey = tag[0];
                        const tagValue = tag[1].toString().trim();
                        COLUMNS.forEach(col => {
                            if (col.cysrTag && item[col.cysrTag] && col.label.toLowerCase() === tagKey && item[col.cysrTag.toLowerCase()] && item[col.cysrTag.toLowerCase()]) {
                                // check cysrTagDateFormat
                                if (col.cysrTagDateFormat) {
                                    const vd = new Date(item[col.cysrTag.toLowerCase()].toString()).toLocaleDateString(col.cysrTagDateFormat, {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                    })
                                    if (vd.toString().includes(tagValue) && notFound) {
                                        trueCount--;
                                        notFound = false;
                                    }
                                } else if (item[col.cysrTag.toLowerCase()].toString().toLowerCase().includes(tagValue)) {
                                    if (notFound) {
                                        trueCount--;
                                        notFound = false;
                                    }
                                }
                            }
                        })
                    }
                    // X:item.cysrTag e
                    /*
                    // compare (for freeSolo) s
                    if (ta && ta.title && ta.title === textValue) {
                        if (notFound) {
                            trueCount--;
                            notFound = false;
                        }
                    }
                        */
                    [
                        "higher_severity_descr",
                        "issuedescr_shortname"
                    ].forEach(property => {
                        if (ta && ta[property]) {
                            // a default option (with [property])
                            if (ta && ta[property] && item[property] && item[property].toLowerCase().includes(ta[property].toLowerCase())) {
                                if (notFound) {
                                    trueCount--;
                                    notFound = false;
                                }
                            }
                        } else {
                            if (ta && ta.title) {
                                // avoid checking again ta with title (rating Excellent, Good...)
                                /* } else if (ta === textValue) {
                                     // checking ta without title written by user ("Excellent", "Good"...)
                                     if (notFound) {
                                         trueCount--;
                                         notFound = false;
                                     }*/
                            } else {
                                // not a default option (with [property])
                                if (ta && item[property] && item[property].toLowerCase().includes(ta.toLowerCase())) {
                                    if (notFound) {
                                        trueCount--;
                                        notFound = false;
                                    }
                                }
                            }
                        }
                    });
                    // compare (for freeSolo) e
                });
                if (trueCount !== 0) {
                    return false;
                }
            }
            // check search at last
            // ..
            return true;
        }
        ),
    };

    const materialTheme = getTheme(DEFAULT_OPTIONS, {
        // isVirtualized: true
        isVirtualized: false
    });

    // to set row border bottom style:
    // border-bottom: 1px solid #${darkMode ? "4a4545" : "f0f2f5"};

    const customTheme = {
        Table: expandedTableStyle,
        BaseCell: `
      border: 0!important;
      padding: 4px;
    `,
    };
    let theme = useTheme([materialTheme, customTheme]);
    if (darkMode) {
        // provide custom theme
        theme = useTheme([materialTheme, {
            Table: expandedTableStyle,
            BaseCell: `
        color: #ffffff;
        background-color: #192328;
        border: 0!important;
        padding: 4px;
      `,
        }
        ]);
    }

    const pagination = usePagination(tData, {
        state: {
            page: 0,
            // size: 2,
            // size: tData.nodes.length,
            size: tableRowsPerPage[0],
        },
        // onChange: onPaginationChange,
    });

    /*
    function onPaginationChange(action, state) {
      // console.log(action, state);
    }
    */

    const sort = useSort(
        tData,
        {
            state: {
                sortKey: data.isVulnerabilities ? 'CVESCORE' : 'SEVERITY',
                reverse: true
            },
            // onChange: onSortChange,
        },
        {
            sortIcon: {
                iconDefault: null,
                iconUp: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                </svg>
                ,
                iconDown: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
                ,
            },
            sortFns: {
                NAME: (array) => array.sort((a, b) => a.issuedescr_shortname.localeCompare(b.issuedescr_shortname)),
                SEVERITY: (array) => array.sort((a, b) => a.higher_severity - b.higher_severity),
                CVESCORE: (array) => array.sort((a, b) => a.cve_score ? a.cve_score - b.cve_score : a.issuedescr_details?.cvss3_base - b.issuedescr_details?.cvss3_base),
                FINDINGS: (array) => array.sort((a, b) => a.assets?.length - b.assets?.length),

                STATUS: (array) => array.sort((a, b) => a.asset_status.localeCompare(b.asset_status)),
                TYPE: (array) => array.sort((a, b) => a.generalasset_type.localeCompare(b.generalasset_type)),
                ASSET: (array) => array.sort((a, b) => a.generalasset_val.localeCompare(b.generalasset_val)),
                /*
                RATING: (array) => array.sort((a, b) =>
                  (a.asset_last_rating && a.asset_last_rating.rating_val ? a.asset_last_rating.rating_val : 0) - (b.asset_last_rating && b.asset_last_rating.rating_val ? b.asset_last_rating.rating_val : 0)
                  ),
                */
                RATING: (array) => array.sort(function (a, b) {
                    let aR = a.asset_last_rating && a.asset_last_rating.rating_val ? a.asset_last_rating.rating_val : "N/A";
                    let bR = b.asset_last_rating && b.asset_last_rating.rating_val ? b.asset_last_rating.rating_val : "N/A";
                    if (!aR || aR === null || aR === undefined || aR === "" || aR === "N/A") {
                        aR = 101;
                    }
                    if (!bR || bR === null || bR === undefined || bR === "" || bR === "N/A") {
                        bR = 101;
                    }
                    return aR - bR
                }),
                // VALUE: (array) => array.sort((a, b) => a.ratingdetail_val.localeCompare(b.ratingdetail_val)),
                VALUE: (array) => array.sort(function (a, b) {
                    if (isNaN(a.ratingdetail_val) && isNaN(b.ratingdetail_val)) {
                        // both strings
                        return a.ratingdetail_val.localeCompare(b.ratingdetail_val);
                    } else if (isNaN(a.ratingdetail_val) === false && isNaN(b.ratingdetail_val) === false) {
                        // both numbers
                        return a.ratingdetail_val - b.ratingdetail_val;
                    } else if (isNaN(a.ratingdetail_val)) {
                        // a not number
                        return 0 - b.ratingdetail_val;
                    } else if (isNaN(b.ratingdetail_val)) {
                        // b not number
                        return a.ratingdetail_val - 0;
                    }
                }),
                WEIGHT: (array) => array.sort((a, b) => a.ratingdetail_rating - b.ratingdetail_rating),
                // CREATED: (array) => array.sort((a, b) => a.mu_created_at - b.mu_created_at),
                // UPDATED: (array) => array.sort((a, b) => a.generalasset_lastcheck_datetime - b.generalasset_lastcheck_datetime),
                // SOURCE: (array) => array.sort((a, b) => a.assetsrc_name.localeCompare(b.assetsrc_name)),
                // TYPE: (array) => array.sort((a, b) => a.type.localeCompare(b.type)),
                // COMPLETE: (array) => array.sort((a, b) => a.isComplete - b.isComplete),
                // TASKS: (array) => array.sort((a, b) => a.tasks || 0 - b.tasks || 0),
                // TASKS: (array) => array.sort((a, b) => (a.nodes || []).length - (b.nodes || []).length),
            },
        },
    );
    /*
      function onSortChange(action, state) {
        // console.log(action, state);
      }
      */

    const handleSetTableTags = (event, t) => {
        // console.log(t)
        setTableTagsActive(t);
        // replace url s
        let params = new URLSearchParams(window.location.search)
        params.delete('ti')
        if (t != "" && t.length > 0) {
            params.append("ti", Base64.encode(JSON.stringify(t)))
        }
        window.history.replaceState(null, '', '?' + params + window.location.hash)
        // replace url e
        // go to first page
        pagination.fns.onSetPage(0)
    };
    const handleSearch = (event) => {
        const s = event.target.value;
        setSearch(s);
        // replace url s
        let params = new URLSearchParams(window.location.search)
        params.delete('si')
        if (s != "") {
            params.append("si", s)
        }
        window.history.replaceState(null, '', '?' + params + window.location.hash)
        // replace url e
    };
    const handleSetHideCompleted = (event) => {
        const c = event.target.checked;
        setHideCompleted(c);
        // replace url s
        let params = new URLSearchParams(window.location.search)
        params.delete('hci')
        if (c) {
            params.append("hci", "true")
        }
        window.history.replaceState(null, '', '?' + params + window.location.hash)
        // replace url e
    };

    const VIRTUALIZED_OPTIONS = {
        rowHeight: (_item, _index) => {
            return 36
        }
    };

    return (
        <div className={`${darkMode ? "dark" : "light"}`}>
            <Stack spacing={10} mb={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <CysrTags tableTagsOptions={tableTagsOptions} tableTagsActive={tableTagsActive} handleSetTableTags={handleSetTableTags} darkMode={darkMode} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={darkMode ? "dark" : "light"}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button variant="outline" className="ml-auto text-black dark:text-white">
                                        Columns <KeyboardArrowDownSharp className="ml-2 h-4 w-4" />
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent style={{
                                    backgroundColor: darkMode ? "#50666b" : "#cddddf",
                                    color: darkMode ? "#fff" : "#000"
                                }} align="end">
                                    {COLUMNS
                                        .filter((column) => column.hide !== undefined)
                                        .map((column) => {
                                            return (
                                                <DropdownMenuCheckboxItem
                                                    key={column.label}
                                                    className="cursor-pointer hover:bg-turquoise-100 hover:text-turquoise-900"
                                                    checked={!(column.hide)}
                                                    onCheckedChange={() => {
                                                        toggleColumn(column.label)
                                                    }}
                                                >
                                                    {column.label}
                                                </DropdownMenuCheckboxItem>
                                            )
                                        })}
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    </Grid>
                </Grid>
            </Stack>
            {
                // virtualized
                // <Paper style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none', height: '720px' }}>
            }
            <Paper style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none', height: 'auto' }}>
                <CompactTable
                    columns={COLUMNS}
                    // passando le virtualizedOptions non funziona l'expand abilitato, penso perchè imposta l'height da solo
                    // virtualizedOptions={VIRTUALIZED_OPTIONS}
                    data={tData}
                    sort={sort}
                    theme={theme}
                    rowOptions={ROW_OPTIONS}
                    layout={{
                        isDiv: true,
                        fixedHeader: true
                    }}
                    pagination={pagination}
                    style={{ borderColor: 'transparent' }}
                // horizontalScroll={true}
                />
            </Paper>
            <Grid mt={0} container style={{
                alignItems: "center",
                borderTop: `1px solid #${darkMode ? "4a4545" : "f0f2f5"}`,
                justifyContent: "space-between"
            }} width="100%" spacing={1}>
                <Grid item>
                    <CysrTableButtonActions
                        options={
                            [
                                {
                                    label: "CSV",
                                    color: "info",
                                    value: "CSV",
                                    onClick: "download_csv",
                                    key: 0,
                                    icon: cysrConfig().report.icon.svg
                                }
                            ]
                        }
                        triggerAction={
                            function (fun) {
                                switch (fun) {
                                    case "download_csv":
                                        handleDownloadCsv()
                                        break;

                                    default:
                                        break;
                                }
                            }
                        } />
                </Grid>
                <Grid item ml={"auto"}>
                    <TablePagination
                        component="div"
                        style={{ color: darkMode ? "#ffffff" : "#000000", border: 0 }}
                        count={tData.nodes.length}
                        page={pagination.state.page}
                        rowsPerPage={pagination.state.size}
                        rowsPerPageOptions={tableRowsPerPage}
                        onRowsPerPageChange={(event) =>
                            pagination.fns.onSetSize(parseInt(event.target.value, 10))
                        }
                        onPageChange={(event, page) => pagination.fns.onSetPage(page)}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export function IssuesAssets({ assets, accountUUID, height = '200px', pagination = false }) {
    return <CysrTableAssets
        defaultData={assets}
        height={height}
        hasPagination={pagination}
    />
    return (<div key={a.asset_uuid} className='flex items-center gap-2 text-black dark:text-white bg-turquoise-100 dark:bg-turquoise-950 rounded py-2 px-3'>
        {
            a.generalasset_rating ?
                <ScoreItem scoreValue={a.generalasset_rating} circle={true} textual={true} item_style="small" /> : "N/A"
        }
        <MDTypography fontWeight="medium" style={{ lineHeight: "1", display: "block", marginBottom: "0", marginRight: "auto", marginTop: "0", fontSize: "12px" }}>
            {a.generalasset_type}:
            <span style={{ fontWeight: "bold", display: "inline-block", fontSize: "16px", marginLeft: "8px" }}>
                {a.generalasset_val}
            </span>
        </MDTypography>
        <Link to={`/company/${accountUUID}/${"asset"}#${a.asset_uuid}`}>
            <MDButton style={cysrConfig().button_style} size="medium">
                Go to asset
                <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
            </MDButton>
        </Link>
    </div>)
}
export default Component;
/* eslint-disable */