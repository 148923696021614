/* eslint-disable */
// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/waffle
import { ResponsiveWaffleCanvas } from '@nivo/waffle'

export default function App() {
    const data = [
        {
        "id": "men",
        "label": "men",
        "value": 27.81846502574229,
        "color": "#468df3"
        },
        {
        "id": "women",
        "label": "women",
        "value": 11.109781931874469,
        "color": "#ba72ff"
        },
        {
        "id": "children",
        "label": "children",
        "value": 3.1100072646911716,
        "color": "#a1cfff"
        }
    ];
    return (
        <div style={{background: "#ff0000", display: "block", textAlign: "center", position: "fixed", zIndex: "99999", left: "0", right: "0", top:"0", bottom:"0", padding:"0", overflow:"auto", height: "100%", width: "100%", minHeight: 650, minWidth: 650}}>
            <ResponsiveWaffleCanvas
                data={data}
                total={100}
                rows={18}
                columns={14}
                margin={{ top: 10, right: 10, bottom: 10, left: 120 }}
                colors={{ scheme: 'nivo' }}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.3
                        ]
                    ]
                }}
                animate={false}
                legends={[
                    {
                        anchor: 'top-left',
                        direction: 'column',
                        justify: false,
                        translateX: -100,
                        translateY: 0,
                        itemsSpacing: 4,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        itemTextColor: '#777',
                        symbolSize: 20,
                    }
                ]}
            />
        </div>
    );
  }