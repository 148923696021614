/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
import { Base64 } from 'js-base64';
import React, { useState, useMemo } from "react";
import Action from "layouts/cysr/components/Action";
// @mui material components
import Card from "@mui/material/Card";

import Box from '@mui/material/Box';

// Material Dashboard 2 PRO React components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import { useLocation } from "react-router-dom";

import { valueFormatter, inverseValueFormatter } from 'layouts/cysr/risk';
import InfoButton from "layouts/cysr/components/infoButton";

import { useMaterialUIController } from "context";

import cysrConfig from "layouts/cysr/config";
import Loader from "layouts/cysr/components/Loader";
import CysrDropdown from "layouts/cysr/components/CysrDropdown";

import { toast } from "sonner"

import MDInput from "components/MDInput";
import {
    // countries,
    countriesCodes
} from "layouts/cysr/components/CYSRCountries"
// ms
import { getCountryCode, getIndustry, getIndustryCode, getCountryName, getActivityType } from "layouts/cysr/companies/CompaniesTop"

import Collapse from "@mui/material/Collapse";
import CysrStepper from "layouts/cysr/components/CysrStepper";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
//
/* 

import Autocomplete from "@mui/material/Autocomplete";

import MDTypography from "components/MDTypography";

import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Action from "layouts/cysr/components/Action";
import { useSnackbar } from 'notistack';
import CysrDropdown from "layouts/cysr/components/CysrDropdown";
 */


export function SearchCompany({ companyToEditUUID, setAccountDataNationalID, setAccountVatAddress, setAccountVatCity, setAccountDataIndustry, setAccountDataEmployeesNumber, setAccountDataSalesEur, selectedCompany, setSelectedCompany, companyVatCode, setCompanyVatCode, companyName, setCompanyName, darkMode }) {
    // from CompaniesTop

    const foundCompaniesPerPage = 10;
    // company states
    const [foundCompaniesCurrentPage, setFoundCompaniesCurrentPage] = useState(1)

    function filterFoundCompanies(companies) {
        let filteredCompanies = [];

        companies.forEach((c, i) => {
            if (filteredCompanies.length < foundCompaniesPerPage) {
                if (
                    i < (foundCompaniesCurrentPage * foundCompaniesPerPage + 1)
                    &&
                    // i > (foundCompaniesCurrentPage * companies.length / foundCompaniesPerPage)
                    i > (foundCompaniesCurrentPage * foundCompaniesPerPage - foundCompaniesPerPage - 1)
                ) {
                    filteredCompanies.push(c);
                }
            }
        })
        return filteredCompanies;
    }


    const [foundCompanies, setFoundCompanies] = useState([])
    const [loadingCompaniesSearch, setLoadingCompaniesSearch] = useState(false)
    const [selectedCompanyFinderOpen, setSelectedCompanyFinderOpen] =
        useState(false);

    const [open, setOpen] = useState(false);
    const [tempData, setTempData] = useState(null);
    const [companyVat, setCompanyVat] = useState("");
    return (
        <div className='mb-6'>
            <MDButton
                aria-label="expand row"
                onClick={() => {
                    if (open === false) {
                        // qui 
                        setSelectedCompany(false)
                    }
                    setOpen(!open)
                }} style={cysrConfig().button_style} size="medium">
                {open ? <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} /> : <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />
                }
                Compile by searching for info from public registers
            </MDButton>
            <Collapse style={{ marginTop: ".5em" }} in={open} timeout="auto" unmountOnExit>
                <div className={`${darkMode ? "dark" : "light"}`}>
                    <div className="mt-2 bg-zinc-100 dark:bg-zinc-900 rounded-xl border-zinc-100 dark:border-zinc-700 border-b border-t">
                        {(selectedCompanyFinderOpen === false && loadingCompaniesSearch === false) ? (
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    if (companyVatCode && companyName) {
                                        setLoadingCompaniesSearch(true)
                                        // call hithorizons s
                                        const callUrl = `${cysrConfig().api_base_url}info/companysearch/?country=${companyVatCode}&searchtext=${companyName}`;
                                        fetch(callUrl, {
                                            headers: {
                                                Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                            },
                                        })
                                            .then((response) => {
                                                if (response && response.ok) {
                                                    return response.json();
                                                } else {
                                                    return response.json().then(err => {
                                                        throw JSON.stringify(err)
                                                    })
                                                }
                                            })
                                            .then(data => {
                                                if (data && data.Result && data.Result.Results) {
                                                    setFoundCompanies(data.Result.Results)
                                                    setFoundCompaniesCurrentPage(1)
                                                    setSelectedCompanyFinderOpen(true);
                                                } else {
                                                    console.error(data);
                                                }
                                                setLoadingCompaniesSearch(false)
                                            })
                                            .catch((err) => {
                                                console.error("err1:", err);
                                                toast.error(JSON.parse(err).ErrorMessage//, JSON.parse(err).ErrorDetail
                                                );
                                                setLoadingCompaniesSearch(false)
                                            })
                                        // call hithorizons e
                                    } else {
                                        toast.warning(
                                            // "Company not created",
                                            "Check that all the fields are compiled correctly and try search again"
                                        );
                                        setLoadingCompaniesSearch(false)
                                    }
                                }}
                            >
                                <CysrStepper
                                    // isLoading={companyCreationLoading}
                                    steps={[
                                        {
                                            label: "Select the country and enter the name or ID",
                                            value: `${companyName &&
                                                companyName != "" &&
                                                companyVatCode &&
                                                companyVatCode != "" &&
                                                companyVatCode.toLowerCase() != "other"
                                                ? companyVatCode && companyVatCode != ""
                                                    ? companyName.toUpperCase() + ", " + companyVatCode
                                                    : companyName.toUpperCase()
                                                : companyName && companyName != "" && companyName.toUpperCase()
                                                }`,
                                            content: (
                                                <div className="flex w-full gap-2 items-center">
                                                    <div className="w-full sm:w-6/12 lg:w-3/12">
                                                        <Autocomplete
                                                            // defaultValue={companyVatCode}
                                                            value={companyVatCode}
                                                            onChange={(e, x) => {
                                                                setCompanyVatCode(x);
                                                            }}
                                                            size="small"
                                                            // label="generalasset_type"
                                                            id="c_account_vat_code"
                                                            options={countriesCodes}
                                                            renderInput={(params) => <MDInput
                                                                // sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#232728' : '#ffffff' }}
                                                                sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? 'rgb(9 9 11)' : 'rgb(250 250 250)', borderColor: darkMode ? 'rgb(63 63 70)' : 'rgb(212 212 216)', borderRadius: "6px" }}
                                                                size="small" label="VAT Country" {...params} />}
                                                            renderOption={(props, option) =>
                                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                    {option && option.toLowerCase() != "xx" && option.toLowerCase() != "other" && <img
                                                                        loading="lazy"
                                                                        width="20"
                                                                        src={`https://flagcdn.com/w20/${option.toLowerCase()}.png`}
                                                                        srcSet={`https://flagcdn.com/w40/${option.toLowerCase()}.png 2x`}
                                                                        alt=""
                                                                    />}
                                                                    {getCountryName(option)}
                                                                </Box>
                                                            }
                                                        />
                                                    </div>
                                                    <div className="w-full sm:w-6/12">
                                                        <Action
                                                            placeholder="Company name or ID"
                                                            type="text"
                                                            // defaultValue={companyName}
                                                            value={companyName}
                                                            onChange={(e) => {
                                                                setCompanyName(e.target.value);
                                                            }}
                                                            id="c_account_name"
                                                        />
                                                    </div>
                                                </div>
                                            ),
                                            submitIcon: "expand",
                                            submitText: "Search company"
                                        },
                                    ]}
                                />
                            </form>
                        ) : (
                            <div className="my-2 px-2 flex flex-col gap-4 flex-wrap">
                                <div className="flex flex-col items-start gap-4">
                                    {loadingCompaniesSearch
                                        ?
                                        <Action
                                            loading={true}
                                            leftIcon="back"
                                            text="Loading results"
                                        />
                                        :
                                        <Action
                                            onClick={() => {
                                                setSelectedCompanyFinderOpen(false);
                                                setSelectedCompany(false);
                                            }}
                                            leftIcon="back"
                                            text="Change company details"
                                        />
                                    }
                                    {(selectedCompanyFinderOpen && companyVatCode && (companyVatCode.toLowerCase() === "other"))
                                        ?
                                        <span className="border-t border-zinc-200 dark:border-zinc-800 w-full pt-2 text-sm text-zinc-700 dark:text-zinc-300">
                                            You have set <strong>{companyName}</strong> as company name and selected <strong>Other</strong> as country
                                        </span>
                                        :
                                        <span className="border-t border-zinc-200 dark:border-zinc-800 w-full pt-2 text-sm text-zinc-700 dark:text-zinc-300">
                                            {
                                                loadingCompaniesSearch
                                                    ? <>
                                                        Loading results for
                                                        <strong className="text-cyan-600 dark:text-cyan-400 text-lg uppercase">
                                                            {companyName}, {companyVatCode}
                                                        </strong>
                                                        <div className="animate-pulse flex space-x-4 w-full">
                                                            <div className="flex flex-col gap-4 mb-4 w-full"><div className="p-3 bg-zinc-50 dark:bg-zinc-950 border-zinc-200 dark:border-zinc-800 border rounded-lg relative"><div className="flex items-center"><div className="inline"><h2 className="font-bold text-zinc-950 dark:text-zinc-50 inline">...</h2></div><div className="ml-auto flex items-center gap-2 my-0.5"><span className="text-sm uppercase text-zinc-600 dark:text-zinc-400"></span></div></div><div className="flex flex-wrap justify-between items-center"><div className="flex items-center gap-2 text-zinc-700 dark:text-zinc-300"><span className="font-bold text-sm"></span><span className="min-w-[200px] md:min-w-0 text-sm font-light"><strong className="font-bold text-sm"></strong> </span><span className="font-bold text-sm"></span></div><div className="absolute -bottom-1.5 right-2"></div></div></div></div>
                                                        </div>
                                                    </>
                                                    : <>
                                                        Viewing {foundCompaniesCurrentPage} of {Math.ceil(foundCompanies.length / foundCompaniesPerPage)} page{foundCompanies.length > foundCompaniesPerPage ? "s" : ""} from {foundCompanies.length} results for{" "}
                                                        <strong className="text-cyan-600 dark:text-cyan-400 text-lg uppercase">
                                                            {companyName}, {companyVatCode}
                                                        </strong>
                                                    </>
                                            }
                                        </span>
                                    }
                                </div>
                                {(selectedCompanyFinderOpen && companyVatCode && (companyVatCode.toLowerCase() === "other"))
                                    ?
                                    null
                                    :
                                    loadingCompaniesSearch === false && <>
                                        <div className="flex flex-col gap-4 mb-4 pl-3">
                                            {
                                                /*
                                                AddressStreetLine1
                                                : 
                                                "VIA EZIO ANDOLFATO 10"
                                                AddressStreetLine2
                                                : 
                                                ""
                                                City
                                                : 
                                                "MILANO"
                                                CompanyName
                                                : 
                                                "MUSCOPE CYBERSECURITY SRL"
                                                CompanyProfileURL
                                                : 
                                                "https://www.hithorizons.com/eu/companies/H-IT0962342428/muscope-cybersecurity-srl"
                                                CompanySecondaryName
                                                : 
                                                ""
                                                Country
                                                : 
                                                "ITALY"
                                                DUNSNumber
                                                : 
                                                "442261924"
                                                EmployeesNumber
                                                : 
                                                2
                                                EstablishmentOfOwnership
                                                : 
                                                2021
                                                HitHorizonsId
                                                : 
                                                "H-IT0962342428"
                                                Industry
                                                : 
                                                "I"
                                                LocalActivityCode
                                                : 
                                                "7372"
                                                LocalActivityCodeType
                                                : 
                                                "060"
                                                NationalId
                                                : 
                                                "IT12136410961"
                                                PostalCode
                                                : 
                                                "20126"
                                                SICCode
                                                : 
                                                "7372"
                                                SalesEUR
                                                : 
                                                42365
                                                StateProvince
                                                : 
                                                "MILANO"
                                                TaxId
                                                : 
                                                null
                                                */
                                            }
                                            {foundCompanies && filterFoundCompanies(foundCompanies).map((c) => (
                                                // ++ now hide if one is selected except the selected
                                                selectedCompany === false || selectedCompany === c.HitHorizonsId
                                                    ?
                                                    <div
                                                        key={c.HitHorizonsId}
                                                        className={
                                                            selectedCompany === c.HitHorizonsId
                                                                ? "p-3 ring-cyan-300 dark:ring-cyan-700 border-cyan-200 dark:border-cyan-800 border ring-2 bg-cyan-50 dark:bg-cyan-950 rounded-lg relative max-w-4xl cursor-pointer"
                                                                : "p-3 hover:ring-cyan-200 dark:hover:ring-cyan-800 bg-zinc-50 dark:bg-zinc-950 hover:border-cyan-200 dark:hover:border-cyan-800 hover:ring-2 border-zinc-200 dark:border-zinc-800 border rounded-lg relative max-w-4xl cursor-pointer"
                                                        }
                                                        onClick={() => {
                                                            const isAlreadySelected = selectedCompany === c.HitHorizonsId;
                                                            setSelectedCompany(
                                                                isAlreadySelected
                                                                    ? false
                                                                    : c.HitHorizonsId
                                                            );
                                                            //+
                                                            if (isAlreadySelected === false) {
                                                                let newTempData = {}
                                                                if (c.CompanyName) {
                                                                    // setAccountDataNationalID(c.NationalId)
                                                                    newTempData.CompanyName = (c.CompanyName)
                                                                }
                                                                if (c.NationalId) {
                                                                    // setAccountDataNationalID(c.NationalId)
                                                                    newTempData.NationalId = (c.NationalId)
                                                                }
                                                                if (c.AddressStreetLine1) {
                                                                    // setAccountVatAddress(c.AddressStreetLine1)
                                                                    newTempData.AddressStreetLine1 = (c.AddressStreetLine1)
                                                                }
                                                                if (c.City) {
                                                                    // setAccountVatCity(c.City)
                                                                    newTempData.City = (c.City)
                                                                }
                                                                if (c.Industry) {
                                                                    // setAccountDataIndustry(c.Industry)
                                                                    newTempData.Industry = (c.Industry)
                                                                }
                                                                if (c.EmployeesNumber) {
                                                                    // setAccountDataEmployeesNumber(c.EmployeesNumber)
                                                                    newTempData.EmployeesNumber = (c.EmployeesNumber)
                                                                }
                                                                if (c.EmployeesNumber) {
                                                                    // setAccountDataEmployeesNumber(c.EmployeesNumber)
                                                                    newTempData.EmployeesNumber = (c.EmployeesNumber)
                                                                }
                                                                if (c.SalesEUR) {
                                                                    // setAccountDataSalesEur(c.SalesEUR)
                                                                    newTempData.SalesEUR = (c.SalesEUR)
                                                                }
                                                                setTempData(newTempData)
                                                            }
                                                            //+
                                                        }}
                                                    >
                                                        <div className="flex items-center">
                                                            <div className="flex" style={{
                                                                display: "flex",
                                                                // flexWrap: "wrap",
                                                                wordBreak: "break-all",
                                                                paddingRight: "12px"
                                                            }}>
                                                                <h2 className="text-base font-bold text-zinc-950 dark:text-zinc-50 inline" style={{
                                                                    display: "inline",
                                                                    lineHeight: "1.1618"
                                                                }}>{c.CompanyName}

                                                                </h2>
                                                            </div>
                                                            <div className="ml-auto pl-2">
                                                                <img
                                                                    className="h-4"
                                                                    onError={() => this.style.visibility = 'hidden'}
                                                                    src={`https://flagicons.lipis.dev/flags/4x3/${getCountryCode(c.Country)}.svg`}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-wrap justify-between items-center">
                                                            <div className="flex items-center gap-1 text-zinc-700 dark:text-zinc-300">
                                                                {false && <span className="min-w-[200px] md:min-w-0 text-sm font-light">
                                                                    <strong className="font-bold text-sm">{getActivityType(c.LocalActivityCodeType)}</strong>
                                                                </span>}
                                                                <span className="text-sm">Id: </span>
                                                                <span className="font-bold text-sm">
                                                                    {c.NationalId}
                                                                </span>
                                                                <span className="text-sm"> - Industry: </span>
                                                                <span className="font-bold text-sm" style={{
                                                                    maxWidth: "120px",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "nowrap"
                                                                }}>
                                                                    {getIndustry(c.Industry)}
                                                                </span>
                                                                <span className="text-sm"> - Established: </span>
                                                                <span className="font-bold text-sm">
                                                                    {c.EstablishmentOfOwnership}
                                                                </span>
                                                            </div>
                                                            <div className="ml-auto">
                                                                <span className="text-xs uppercase text-zinc-600 dark:text-zinc-400">
                                                                    {c.AddressStreetLine1 &&
                                                                        `${c.AddressStreetLine1} - `}
                                                                    {c.City
                                                                        ? `${c.City}, ${c.Country}`
                                                                        : `${c.Country}`}
                                                                </span>
                                                            </div>
                                                            {false && <div className="flex flex-col md:flex-row gap-0.5 md:gap-x-4">
                                                                <div className="flex items-center md:gap-2">
                                                                    <span className="min-w-[200px] md:min-w-0 text-sm font-light text-zinc-700 dark:text-zinc-300">
                                                                        Establishment Of Ownership
                                                                    </span>
                                                                    <span className="font-bold text-sm">
                                                                        {c.establishment_of_ownership}
                                                                    </span>
                                                                </div>
                                                                <div className="flex items-center md:gap-2">
                                                                    <span className="min-w-[200px] md:min-w-0 text-sm font-light text-zinc-700 dark:text-zinc-300">
                                                                        Type
                                                                    </span>
                                                                    <span className="font-bold text-sm">
                                                                        {c.search_result_type}
                                                                    </span>
                                                                </div>
                                                            </div>}
                                                            {selectedCompany === c.HitHorizonsId ? (
                                                                <div className="absolute -left-2 -top-3">
                                                                    <CheckCircleRoundedIcon
                                                                        className="text-cyan-600 dark:text-cyan-400 ring-cyan-300 dark:ring-cyan-700 ring rounded-full bg-cyan-50"
                                                                        fontSize="8"
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div className="absolute -left-2 -top-3">
                                                                    <CircleRoundedIcon
                                                                        className="text-cyan-100 dark:text-cyan-900 ring-cyan-200 dark:ring-cyan-800 ring rounded-full bg-cyan-50 dark:bg-cyan-950"
                                                                        fontSize="8"
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    : null
                                            ))}
                                        </div>
                                        {// ++ now hide if one is selected
                                            selectedCompany === false
                                                ? <div className="flex gap-2">
                                                    {
                                                        Array.from(Array(
                                                            Math.ceil(foundCompanies.length / foundCompaniesPerPage)
                                                        ).keys()).map((n) => (
                                                            <div style={{ opacity: (n + 1) === foundCompaniesCurrentPage ? "0.5" : "1" }}>
                                                                <Action
                                                                    onClick={() => {
                                                                        setFoundCompaniesCurrentPage(n + 1);
                                                                    }}
                                                                    leftIcon={null}
                                                                    text={n + 1}
                                                                />
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                : null
                                        }
                                    </>
                                }
                            </div>
                        )}
                        {(selectedCompanyFinderOpen && companyVatCode && (companyVatCode.toLowerCase() === "other"))
                            ?
                            null
                            :
                            <>
                                {selectedCompanyFinderOpen && (
                                    <span className="my-2 px-4 flex items-center gap-1 text-sm text-zinc-600 dark:text-zinc-400">
                                        Company not listed?{" "}
                                        <button
                                            className="text-cyan-600 dark:text-cyan-400 underline"
                                            onClick={() => {
                                                setSelectedCompanyFinderOpen(false);
                                                setSelectedCompany(false);
                                            }}
                                        >
                                            Change company details
                                        </button>{" "}
                                        and select <strong>Other</strong> as country
                                    </span>
                                )}
                                {selectedCompany ? (
                                    <>
                                        <span className="my-2 px-4 flex items-center gap-1 text-xs text-emerald-600 dark:text-emerald-400">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                className="w-4 h-4"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M16.403 12.652a3 3 0 000-5.304 3 3 0 00-3.75-3.751 3 3 0 00-5.305 0 3 3 0 00-3.751 3.75 3 3 0 000 5.305 3 3 0 003.75 3.751 3 3 0 005.305 0 3 3 0 003.751-3.75zm-2.546-4.46a.75.75 0 00-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            You have selected company {companyName}.
                                        </span>
                                        <p className='mb-2 px-4 flex items-center gap-1 text-lg text-black dark:text-white'>Do you want to overwrite the info with the data of <strong className='text-base font-bold text-zinc-950 dark:text-zinc-50 inline'>{companyName}</strong>?
                                        </p>
                                        <div className='px-4 my-2 pb-2'>
                                            <MDButton style={cysrConfig().button_style} size="medium" onClick={() => {
                                                const editProfileUrl = `${cysrConfig().api_base_url}account/${companyToEditUUID}/update-data/`;
                                                const b = {
                                                    // hith here
                                                    account_data_vendor_id: selectedCompany ? selectedCompany : null,
                                                    account_data_national_id: tempData.NationalId,
                                                    account_name: tempData.CompanyName,
                                                    account_vat_address: tempData.AddressStreetLine1,
                                                    account_vat_city: tempData.City,
                                                    account_vat_country_code: companyVatCode,
                                                    account_data_employees_number: tempData.EmployeesNumber,
                                                    account_data_sales_eur: tempData.SalesEUR,
                                                    account_data_industry: tempData.Industry,
                                                }
                                                if (
                                                    (b.account_data_employees_number > 0 && b.account_data_sales_eur > 0)
                                                    || (b.account_data_employees_number == "" && b.account_data_sales_eur == "")
                                                ) {
                                                    // ok 

                                                } else {
                                                    // toast.warning("Provide both the company employees number and annual revenue in € (or empty both) to save")
                                                    // return
                                                    // overwrite both to null
                                                    b.account_data_employees_number = null
                                                    b.account_data_sales_eur = null
                                                }
                                                console.info(b)
                                                fetch(editProfileUrl, {
                                                    body: JSON.stringify(b),
                                                    headers: {
                                                        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                                        "content-type": "application/json",
                                                    },
                                                    method: "PATCH",
                                                })
                                                    .then((response) => {
                                                        let patchAfterOK = false;
                                                        if (response.status === 200) {
                                                            response.json()
                                                                .then((responseData) => {
                                                                    toast.success(responseData.detail ? responseData.detail : "Information refreshed, reloading page");
                                                                })
                                                                .catch(() => toast.success("Information refreshed, reloading page"))
                                                            // calling patch after refresh
                                                            // window.location.reload()
                                                            patchAfterOK = true;
                                                        } else if (response.ok || (response.status > 199 && response.status < 300)) {
                                                            response.json()
                                                            .then((responseData) => {
                                                                toast.success(responseData.detail ? responseData.detail : "Information refreshed, reloading page");
                                                            })
                                                            .catch(() => toast.success("Information refreshed, reloading page"))
                                                            // calling patch after refresh
                                                            // window.location.reload()
                                                            patchAfterOK = true;
                                                        } else {
                                                            return response.json().then(err => {
                                                                throw JSON.stringify(err)
                                                            })
                                                        }
                                                        if(patchAfterOK === true){
                                                            // call patch and refresh
                                                            fetch(`${cysrConfig().api_base_url}account/${companyToEditUUID}/refresh-data/`, {
                                                                headers: {
                                                                    Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                                                    "content-type": "application/json",
                                                                },
                                                                method: "PATCH",
                                                            })
                                                                .then((response) => {
                                                                    if (response.status === 200) {
                                                                        response.json()
                                                                            .then((responseData) => {
                                                                                toast.success(responseData.detail ? responseData.detail : "Information refreshed, reloading page");
                                                                            })
                                                                            .catch(() => toast.success("Information refreshed, reloading page"))
                                                                        window.location.reload()
                                                                    } else if (response.ok || (response.status > 199 && response.status < 300)) {
                                                                        response.json()
                                                                            .then((responseData) => {
                                                                                toast.success(responseData.detail ? responseData.detail : "Information refreshed, reloading page");
                                                                            })
                                                                            .catch(() => toast.success("Information refreshed, reloading page"))
                                                                        window.location.reload()
                                                                    } else {
                                                                        return response.json().then(err => {
                                                                            throw JSON.stringify(err)
                                                                        })
                                                                    }
                                                                })
                                                                .catch((err) => {
                                                                    console.error("err0:", err);
                                                                    toast.error(JSON.parse(err).detail);
                                                                });
                                                        }
                                                    })
                                                    .catch((err) => {
                                                        console.error("err1:", err);
                                                        toast.error(`${JSON.parse(err).ErrorDetail}: ${JSON.parse(err).ErrorMessage}`);
                                                    });
                                            }}>
                                                <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().update.icon.svg }} />
                                                Refresh with selected data info
                                            </MDButton>
                                            {
                                                /*
                                                // saving directly now
}
                                        <Action
                                            onClick={() => {
                                                setSelectedCompanyFinderOpen(false);
                                                if (tempData) {
                                                    if (tempData.CompanyName) {
                                                        setCompanyName(tempData.CompanyName)
                                                    }
                                                    if (tempData.NationalId) {
                                                        setAccountDataNationalID(tempData.NationalId)
                                                    }
                                                    if (tempData.AddressStreetLine1) {
                                                        setAccountVatAddress(tempData.AddressStreetLine1)
                                                    }
                                                    if (tempData.City) {
                                                        setAccountVatCity(tempData.City)
                                                    }
                                                    if (tempData.Industry) {
                                                        setAccountDataIndustry(tempData.Industry)
                                                    }
                                                    if (tempData.EmployeesNumber) {
                                                        setAccountDataEmployeesNumber(tempData.EmployeesNumber)
                                                    }
                                                    if (tempData.SalesEUR) {
                                                        setAccountDataSalesEur(tempData.SalesEUR)
                                                    }
                                                }
                                                toast.info("Form filled with the data, remember to save at the end of the form")
                                                setOpen(false);
                                                //+
                                            }}
                                            leftIcon="refresh"
                                            text="Overwrite form with selected data"
                                        />
                                        {
                                                */
                                            }
                                        </div>
                                    </>
                                ) : (
                                    selectedCompanyFinderOpen && (
                                        <span className="my-2 px-4 flex items-center gap-1 text-xs text-amber-600 dark:text-amber-400">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                className="w-4 h-4"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            Select a company to continue
                                        </span>
                                    )
                                )}
                            </>
                        }
                    </div>
                </div>
            </Collapse>
        </div>
    )
}
//
export default function Page() {
    const defaultStyle = false;
    const [controller] = useMaterialUIController();

    const { darkMode } = controller;

    const [isLoading, setLoading] = useState(true);

    const location = useLocation();

    let companyToEditUUID = 0;
    // extract id
    if (window.location.hash !== undefined) {
        companyToEditUUID = window.location.hash.replace("#", "");
    }

    const [views, setViews] = useState([]);

    const [accountWithID, setAccountWithID] = useState(false);
    const [hasVendorID, setHasVendorID] = useState(false);

    const [companyFetchedID, setCompanyFetchedID] = useState(false);

    const [accountName, setAccountName] = useState("");
    const [accountVatAddress, setAccountVatAddress] = useState("");
    const [accountVatCity, setAccountVatCity] = useState("");
    const [accountCountryCode, setAccountCountryCode] = useState("");
    const [accountDataNationalID, setAccountDataNationalID] = useState("");
    const [accountDataEmployeesNumber, setAccountDataEmployeesNumber] = useState("");
    const [accountDataSalesEur, setAccountDataSalesEur] = useState("");
    const [accountDataIndustry, setAccountDataIndustry] = useState("Y");

    if (companyToEditUUID) {
        useMemo(() => {
            setLoading(true);
            fetch(`${cysrConfig().api_base_url}dashboard/mainpage/${companyToEditUUID}/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                },
            })
                .then((myresponse) => myresponse.json())
                .then((resData) => {
                    setViews({
                        account_data: resData.company_profile
                    });
                    let vatCountryCode = resData.company_profile.account_vat_country_code;
                    if (vatCountryCode && (vatCountryCode.toLowerCase() === "other" || vatCountryCode.toLowerCase() === "xx")) {
                        vatCountryCode = "other"
                        setAccountWithID(false)
                    } else {
                        setAccountWithID(true)
                    }
                    setHasVendorID(resData.company_profile.account_data_vendor_id)

                    setAccountName(resData.company_profile.account_name)
                    setAccountVatAddress(resData.company_profile.account_vat_address)
                    setAccountVatCity(resData.company_profile.account_vat_city)
                    setAccountCountryCode(vatCountryCode)
                    setAccountDataNationalID(resData.company_profile.account_data_national_id)
                    setAccountDataEmployeesNumber(resData.company_profile.account_data_employees_number)
                    setAccountDataSalesEur(resData.company_profile.account_data_sales_eur)
                    // setAccountDataIndustry(resData.company_profile.account_data_industry)
                    setAccountDataIndustry(getIndustryCode(resData.company_profile.account_data_industry_descr))
                    setLoading(false);
                })
                .catch((ex) => {
                    console.error("errore qui:")
                    console.error(ex)
                    setViews({
                        account_data: null
                    })
                    setLoading(false);
                });
        }, [location]);
    }
    return (
        <DashboardLayout>
            <DashboardNavbar cysr_company={"edit_info"} />
            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <Card>
                        {false
                            && <InfoButton cysr_info={cysrConfig().i("subscription", "header", "help")} cysr_info_link={cysrConfig().i("subscription", "header", "help_link")} />
                        }
                        <MDBox py={5} px={3} fullWidth>

                            <MDTypography variant="h5">Edit information</MDTypography>

                            <MDTypography variant="button" color="text" fontWeight="regular">
                                Manage your company {accountName} information
                            </MDTypography>
                            {isLoading ?
                                <Loader />
                                : views && views.account_data ? <MDBox mt={2} pb={2} component="div">
                                    {accountWithID && hasVendorID ?
                                        <>
                                            <MDTypography style={{
                                                alignItems: "center",
                                                display: "flex",
                                                gap: "4px"
                                            }} variant="button" color="text" fontWeight="regular">If the information below is outdated, you can tap <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().update.icon.svg }} /> to update it automatically from public registers.</MDTypography>

                                            <MDButton style={cysrConfig().button_style} size="medium" onClick={() => {
                                                const editProfileUrl = `${cysrConfig().api_base_url}account/${companyToEditUUID}/refresh-data/`;
                                                fetch(editProfileUrl, {
                                                    headers: {
                                                        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                                        "content-type": "application/json",
                                                    },
                                                    method: "PATCH",
                                                })
                                                    .then((response) => {
                                                        if (response.status === 200) {
                                                            response.json()
                                                                .then((responseData) => {
                                                                    toast.success(responseData.detail ? responseData.detail : "Information refreshed, reloading page");
                                                                })
                                                                .catch(() => toast.success("Information refreshed, reloading page"))
                                                            window.location.reload()
                                                        } else if (response.ok || (response.status > 199 && response.status < 300)) {
                                                            response.json()
                                                                .then((responseData) => {
                                                                    toast.success(responseData.detail ? responseData.detail : "Information refreshed, reloading page");
                                                                })
                                                                .catch(() => toast.success("Information refreshed, reloading page"))
                                                            window.location.reload()
                                                        } else {
                                                            return response.json().then(err => {
                                                                throw JSON.stringify(err)
                                                            })
                                                        }
                                                    })
                                                    .catch((err) => {
                                                        console.error("err0:", err);
                                                        toast.error(JSON.parse(err).detail);
                                                    });
                                            }}>
                                                <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().update.icon.svg }} />
                                                Refresh information from public registers
                                            </MDButton>
                                            <MDTypography style={{
                                                alignItems: "center",
                                                display: "flex",
                                                marginTop: "20px",
                                                gap: "4px"
                                            }} variant="button" color="text" fontWeight="regular">Alternatively, you can fill in the fields below manually.</MDTypography>
                                        </>
                                        : <SearchCompany companyToEditUUID={companyToEditUUID} setAccountDataNationalID={setAccountDataNationalID} setAccountVatAddress={setAccountVatAddress} setAccountVatCity={setAccountVatCity} setAccountDataIndustry={setAccountDataIndustry} setAccountDataEmployeesNumber={setAccountDataEmployeesNumber} setAccountDataSalesEur={setAccountDataSalesEur} selectedCompany={companyFetchedID} setSelectedCompany={setCompanyFetchedID} companyVatCode={accountCountryCode} setCompanyVatCode={setAccountCountryCode} companyName={accountName} setCompanyName={setAccountName} darkMode={darkMode} />
                                    }

                                    <div className={`${darkMode ? "dark" : "light"} mt-4 flex flex-col gap-4 w-full max-w-lg`}>

                                        {accountWithID ? null :
                                            <>
                                                <Autocomplete
                                                    disabled={accountWithID}
                                                    id='account_vat_country_code'
                                                    // defaultValue={accountCountryCode}
                                                    value={accountCountryCode}
                                                    onChange={(e, x) => {
                                                        setAccountCountryCode(x);
                                                    }}
                                                    size="small"
                                                    options={countriesCodes}
                                                    renderInput={(params) => <MDInput
                                                        // classNames="disabled:cursor-not-allowed disabled:bg-zinc-300 disabled:dark:bg-zinc-800 disabled:text-zinc-50 disabled:dark:text-zinc-500"
                                                        // sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#232728' : '#ffffff' }}
                                                        sx={{
                                                            color:
                                                                darkMode
                                                                    ? accountWithID // disabled
                                                                        ? "rgb(113 113 122)!important"
                                                                        : 'rgba(255, 255, 255, 0.65)'
                                                                    : accountWithID
                                                                        ? "rgb(250 250 250)!important"
                                                                        : 'rgb(20, 20, 20)',
                                                            background:
                                                                darkMode
                                                                    ? accountWithID // disabled
                                                                        ? "rgb(39 39 42)!important"
                                                                        : 'rgb(9 9 11)'
                                                                    : accountWithID // disabled
                                                                        ? "rgb(212 212 216)!important"
                                                                        : 'rgb(250 250 250)',
                                                            borderColor: darkMode ? 'rgb(63 63 70)' : 'rgb(212 212 216)',
                                                            borderRadius: "6px",
                                                            cursor: accountWithID // disabled
                                                                ? "not-allowed"
                                                                : "unset"
                                                        }}
                                                        size="small" label="Country code" {...params} />
                                                    }
                                                    renderOption={(props, option) =>
                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                            {option && option.toLowerCase() != "xx" && option.toLowerCase() != "other" && <img
                                                                loading="lazy"
                                                                width="20"
                                                                src={`https://flagcdn.com/w20/${option.toLowerCase()}.png`}
                                                                srcSet={`https://flagcdn.com/w40/${option.toLowerCase()}.png 2x`}
                                                                alt=""
                                                            />}
                                                            {getCountryName(option)}
                                                        </Box>
                                                    }
                                                />
                                                {defaultStyle
                                                    ? <MDInput
                                                        disabled={accountWithID}
                                                        fullWidth
                                                        label="ID"
                                                        // defaultValue={accountDataNationalID}
                                                        value={accountDataNationalID}
                                                        onChange={(e) => setAccountDataNationalID(e.target.value)}
                                                        id="account_data_national_id"
                                                    />
                                                    : <Action
                                                        disabled={accountWithID}
                                                        text="ID"
                                                        type="text"
                                                        //onChange
                                                        // defaultValue={accountDataNationalID}
                                                        value={accountDataNationalID}
                                                        onChange={(e) => setAccountDataNationalID(e.target.value)}
                                                        // value
                                                        // title
                                                        placeholder="Company ID"
                                                        // params
                                                        id="account_data_national_id"
                                                    />
                                                }
                                            </>

                                        }

                                        {defaultStyle ?
                                            <MDInput
                                                fullWidth
                                                label="Name"
                                                id="account_name"
                                                // defaultValue={accountName}
                                                value={accountName}
                                                onChange={(e) => setAccountName(e.target.value)}
                                            />
                                            :
                                            <Action
                                                fullWidth
                                                text="Name"
                                                type="text"
                                                // defaultValue={accountName}
                                                value={accountName}
                                                onChange={(e) => setAccountName(e.target.value)}
                                                placeholder="Company Name"
                                                id="account_name"
                                            />
                                        }


                                        {defaultStyle ?
                                            <MDInput
                                                disabled={accountWithID}
                                                fullWidth
                                                label="Address"
                                                id="account_vat_address"
                                                // defaultValue={accountVatAddress}
                                                value={accountVatAddress}
                                                onChange={(e) => setAccountVatAddress(e.target.value)}
                                            />
                                            :
                                            <Action
                                                // disabled={accountWithID}
                                                text="Address"
                                                type="text"
                                                // defaultValue={accountVatAddress}
                                                value={accountVatAddress}
                                                onChange={(e) => setAccountVatAddress(e.target.value)}
                                                placeholder="Company Address"
                                                id="account_vat_address"
                                            />
                                        }
                                        {defaultStyle ?

                                            <MDInput
                                                fullWidth
                                                label="City"
                                                id="account_vat_city"
                                                // defaultValue={accountVatCity}
                                                value={accountVatCity}
                                                onChange={(e) => setAccountVatCity(e.target.value)}
                                            />
                                            :
                                            <Action
                                                text="City"
                                                type="text"
                                                // defaultValue={accountVatCity}
                                                value={accountVatCity}
                                                onChange={(e) => setAccountVatCity(e.target.value)}
                                                placeholder="Company City"
                                                id="account_vat_city"
                                            />}
                                        <div className='flex flex-col gap-1'>
                                            <span className='text-xs flex flex-col indent-2 text-zinc-600 dark:text-zinc-400'>Industry</span>
                                            <CysrDropdown
                                                // id="account_data_industry"
                                                data={
                                                    {
                                                        // isDisabled: accountWithID,
                                                        isLoading: false,
                                                        options:
                                                            ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "Y"].map((y) => ({
                                                                label: getIndustry(y),
                                                                color: "",
                                                                value: y,
                                                                key: y,
                                                                isDisabled: false
                                                            })
                                                            ),
                                                        value: {
                                                            label: getIndustry(accountDataIndustry),
                                                            color: "",
                                                            value: accountDataIndustry,
                                                            key: accountDataIndustry,
                                                            isDisabled: false
                                                        },
                                                        defaultValue: {
                                                            label: getIndustry(accountDataIndustry),
                                                            color: "",
                                                            value: accountDataIndustry,
                                                            key: accountDataIndustry,
                                                            isDisabled: false
                                                        },
                                                        onChange: (x) => setAccountDataIndustry(x)
                                                    }
                                                }
                                            />
                                        </div>
                                        {defaultStyle ?
                                            <MDInput
                                                fullWidth
                                                label="Employees number"
                                                id="account_data_employees_number"
                                                // defaultValue={accountDataEmployeesNumber}
                                                value={accountDataEmployeesNumber}
                                                onChange={(event) => {
                                                    let number = event.target.value;
                                                    if (number === undefined || number === "" || isNaN(number)) {
                                                        number = `${number}`
                                                    } else {
                                                        number = parseInt(number, 10)
                                                        if (number > 2147483640) {
                                                            toast.error("The provided employees number is out of this world.")
                                                            return false
                                                        }
                                                    }
                                                    toast.dismiss()
                                                    setAccountDataEmployeesNumber(number)
                                                }}
                                            /> :
                                            <Action
                                                text="Employees number"
                                                type="number"
                                                min={0}
                                                // defaultValue={accountDataEmployeesNumber}
                                                value={accountDataEmployeesNumber}
                                                onChange={(event) => {
                                                    let number = event.target.value;
                                                    if (number === undefined || number === "" || isNaN(number)) {
                                                        number = `${number}`
                                                    } else {
                                                        number = parseInt(number, 10)
                                                        if (number > 2147483640) {
                                                            toast.error("The provided employees number is out of this world.")
                                                            return false
                                                        }
                                                    }
                                                    toast.dismiss()
                                                    setAccountDataEmployeesNumber(number)
                                                }}
                                                placeholder="Company Employees number"
                                                id="account_data_employees_number"
                                            />
                                        }
                                        <>
                                            {defaultStyle ?
                                                <MDInput
                                                    fullWidth
                                                    label="Annual revenue in €"
                                                    id="account_data_sales_eur"
                                                    value={accountDataSalesEur}
                                                    // value={valueFormatter(accountDataSalesEur, 0, true)}
                                                    onChange={(event) => {
                                                        // console.log(accountDataSalesEur)
                                                        // console.log(event.target.value)
                                                        // setAccountDataSalesEur(inverseValueFormatter(event.target.value, true))
                                                        let number = event.target.value;
                                                        if (number === undefined || number === "" || isNaN(number)) {
                                                            number = `${number}`
                                                        } else {
                                                            number = parseFloat(number)
                                                            if (number > 1000000000000000) {
                                                                toast.error("The provided revenue is out of this world.")
                                                                return false
                                                            }
                                                        }
                                                        toast.dismiss()
                                                        setAccountDataSalesEur(number)
                                                    }}
                                                />
                                                :
                                                <Action
                                                    text="Annual revenue in €"
                                                    type="text"
                                                    value={accountDataSalesEur}
                                                    // value={valueFormatter(accountDataSalesEur, 0, true)}
                                                    onChange={(event) => {
                                                        // console.log(accountDataSalesEur)
                                                        // console.log(event.target.value)
                                                        // setAccountDataSalesEur(inverseValueFormatter(event.target.value, true))
                                                        let number = event.target.value;
                                                        if (number === undefined || number === "" || isNaN(number)) {
                                                            number = `${number}`
                                                        } else {
                                                            number = parseFloat(number)
                                                            if (number > 1000000000000000) {
                                                                toast.error("The provided revenue is out of this world.")
                                                                return false
                                                            }
                                                        }
                                                        toast.dismiss()
                                                        setAccountDataSalesEur(number)
                                                    }}
                                                    placeholder="Company Annual revenue in €"
                                                    id="account_data_sales_eur"
                                                />
                                            }
                                            <MDTypography style={{
                                                transform: "translateY(-20px)"
                                            }} variant="overline" color="text" fontWeight="regular">{accountDataSalesEur === undefined || accountDataSalesEur === null || accountDataSalesEur === "" ? "Revenue not provided." : <>{`${valueFormatter(accountDataSalesEur, 0, true)} ${accountDataSalesEur > 999 ? `(${valueFormatter(accountDataSalesEur, 2)})` : ""}`}</>}</MDTypography>
                                        </>
                                        <MDButton style={cysrConfig().button_style} size="medium" onClick={() => {
                                            const editProfileUrl = `${cysrConfig().api_base_url}account/${companyToEditUUID}/update-data/`;
                                            const b = accountWithID ? {
                                                // account_data_national_id: document.getElementById("account_data_national_id").value,
                                                account_name: document.getElementById("account_name").value,
                                                account_vat_address: document.getElementById("account_vat_address").value,
                                                account_vat_city: document.getElementById("account_vat_city").value,
                                                // account_vat_country_code: document.getElementById("account_vat_country_code").value,
                                                account_data_employees_number: document.getElementById("account_data_employees_number").value,
                                                account_data_sales_eur: document.getElementById("account_data_sales_eur").value,
                                                account_data_industry: accountDataIndustry,
                                            } : {
                                                // hith here
                                                account_data_vendor_id: companyFetchedID ? companyFetchedID : null,
                                                account_data_national_id: document.getElementById("account_data_national_id").value,
                                                account_name: document.getElementById("account_name").value,
                                                account_vat_address: document.getElementById("account_vat_address").value,
                                                account_vat_city: document.getElementById("account_vat_city").value,
                                                account_vat_country_code: (document.getElementById("account_data_national_id").value && document.getElementById("account_data_national_id").value != "other")
                                                    ? document.getElementById("account_vat_country_code").value
                                                    : "",
                                                account_data_employees_number: document.getElementById("account_data_employees_number").value,
                                                account_data_sales_eur: document.getElementById("account_data_sales_eur").value,
                                                account_data_industry: accountDataIndustry,
                                            }
                                            if (
                                                (b.account_data_employees_number > 0 && b.account_data_sales_eur > 0)
                                                || (b.account_data_employees_number == "" && b.account_data_sales_eur == "")
                                            ) {
                                                // ok 
                                            } else {
                                                toast.warning("Provide both the company employees number and annual revenue in € (or empty both) to save")
                                                return
                                            }
                                            console.warn(b)
                                            fetch(editProfileUrl, {
                                                body: JSON.stringify(b),
                                                headers: {
                                                    Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                                    "content-type": "application/json",
                                                },
                                                method: "PATCH",
                                            })
                                                .then((response) => {
                                                    if (response.status === 200) {
                                                        response.json()
                                                            .then((responseData) => {
                                                                toast.success(responseData.detail ? responseData.detail : "Information edited");
                                                            })
                                                    } else if (response.ok || (response.status > 199 && response.status < 300)) {
                                                        response.json()
                                                            .then((responseData) => {
                                                                toast.success(responseData.detail ? responseData.detail : "Information edited");
                                                            })
                                                    } else {
                                                        return response.json().then(err => {
                                                            throw JSON.stringify(err)
                                                        })
                                                    }
                                                })
                                                .catch((err) => {
                                                    console.error("err1:", err);
                                                    toast.error(`${JSON.parse(err).ErrorDetail}: ${JSON.parse(err).ErrorMessage}`);
                                                });
                                        }}>
                                            <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().edit.icon.svg }} />
                                            Save manually entered information
                                        </MDButton>
                                    </div>

                                    {/*
                                                aggiunto il campo della città
messo un vincolo che anche la country non si può cambiare se già c'è la partita iva


{
    "account_data_national_id": "IT02660450426",
  "account_name": "365 aaa",
  "account_vat_address": "via di qui",
  "account_vat_city": "Imola",
  "account_vat_country_code": "IT",
  "account_data_employees_number": 214743647,
  "account_data_sales_eur": 92233720368500,
  "account_data_industry": "A"
                                                nome, indirzzo, country, id, industry, [dipendenti,fatturato]
                                                {
                                                    `
                                                    "account_name",
                                                    "account_vat_address",
                                                    "account_vat_country_code",
                                                    "account_data_national_id",
                                                    "account_data_employees_number",
                                                    "account_data_sales_eur",
                                                    "account_data_industry",
                                                    industry_descriptions = {
                                                        "A": "Agriculture, Forestry, and Fishing",
                                                        "B": "Mining",
                                                        "C": "Construction",
                                                        "D": "Manufacturing",
                                                        "E": "Transportation, Communications, Electric, Gas, and Sanitary Services",
                                                        "F": "Wholesale Trade",
                                                        "G": "Retail Trade",
                                                        "H": "Finance, Insurance, and Real Estate",
                                                        "I": "Services",
                                                        "J": "Public Sector",
                                                        "Y": "Unknown industry",
                                                    }

                                                    `
                                                    */
                                    }



                                </MDBox> : "Company is not currently editable."
                            }
                        </MDBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout >
    );
};
/* eslint-disable */
