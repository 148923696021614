/* eslint-disable */
// prettier-ignore
import { Base64 } from 'js-base64';
import { useState, useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

import Flag from "react-flag-cdn"
import { CYSRCompanyRadar } from "layouts/cysr/components/radar";
import { getIndustry } from "layouts/cysr/companies/CompaniesTop";
import Slider from "@mui/material/Slider";
import { toast } from "sonner"
import { ImportantMessage } from "layouts/cysr/components/ImportantMessage";
import ScoreItem from "layouts/cysr/components/ScoreItem";
import { useMaterialUIController } from "context";
import {
    // AreaChart,
    // Badge,
    // Bold,
    // Card as TCard,
    // Col,
    // Divider as TDivider,
    Flex,
    // Grid as TGrid,
    // Icon,
    // List,
    // ListItem,
    Metric,
    Text,
    // ProgressBar,
    // Subtitle,
    // Tab,
    // TabGroup,
    // TabList,
    // TabPanel,
    // TabPanels,
    SparkAreaChart,
    // MarkerBar,
    AreaChart,
    // BarChart
} from "@tremor/react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";

// import Footer from "examples/Footer";
import Loader from "layouts/cysr/components/Loader";
import { valueFormatter } from "layouts/cysr/risk";

import Heatmap from "layouts/cysr/insight/insightHeatmap";
import { InsightBarList } from "layouts/cysr/insight/insightBarList";
import { Waffle } from "layouts/cysr/insight/insightWaffle";
import { ToggleGroup, ToggleGroupItem } from "layouts/cysr/insight/ToggleGroup";

import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import InfoButton from "layouts/cysr/components/infoButton";
import Divider from "@mui/material/Divider";
// import KeyboardArrowDownIcon from '@mui/icons-material/Add';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Card from "@mui/material/Card";
import CysrTableCompanies from "layouts/cysr/components/CysrTable/companies";
// import DataTable from "layouts/cysr/components/DataTable";
// import dashboardData from "layouts/cysr/dashboard/dashboardData";

// import { LineChart } from "@carbon/charts-react";
// import "@carbon/charts/styles-g90.css";

import cysrConfig from "layouts/cysr/config";

import { updateCompanies } from "db";

export const colorArray = [
    //{ name: "slate", code: "#64748b" },
    //{ name: "gray", code: "#6b7280" },
    //{ name: "zinc", code: "#71717a" },
    //{ name: "neutral", code: "#737373" },
    //{ name: "stone", code: "#78716c" },
    { name: "violet", code: "#8b5cf6" },
    { name: "cyan", code: "#06b6d4" },
    { name: "pink", code: "#ec4899" },
    { name: "green", code: "#22c55e" },
    { name: "indigo", code: "#6366f1" },
    { name: "lime", code: "#84cc16" },
    { name: "blue", code: "#3b82f6" },
    { name: "purple", code: "#a855f7" },
    { name: "amber", code: "#f59e0b" },
    { name: "teal", code: "#14b8a6" },
    { name: "emerald", code: "#10b981" },
    { name: "rose", code: "#f43f5e" },
    { name: "orange", code: "#f97316" },
    { name: "sky", code: "#0ea5e9" },
    { name: "fuchsia", code: "#d946ef" },
    { name: "red", code: "#ef4444" },
    { name: "yellow", code: "#eab308" },
];

const max_counter_query = 250;
// import CompaniesTop from "./CompaniesTop";
function transformDataForRadar(d) {
    const typeOrder = ["domain", "email", "dns", "website", "ip", "network", "as"];
    return Object.entries(d)
        .map(([type, rating]) => ({ type, rating }))
        .sort((a, b) => {
            const typeAIndex = typeOrder.indexOf(a.type);
            const typeBIndex = typeOrder.indexOf(b.type);

            if (typeAIndex === -1 && typeBIndex === -1) {
                // If both types are not in typeOrder, keep their order
                return 0;
            } else if (typeAIndex === -1) {
                // If only type A is not in typeOrder, put it at the end
                return 1;
            } else if (typeBIndex === -1) {
                // If only type B is not in typeOrder, put it at the end
                return -1;
            } else {
                // Otherwise, sort by their index in typeOrder
                return typeAIndex - typeBIndex;
            }
        });
}
function getInsightRatingColor(x) {
    /*
    BLU            <0.1    (meno di 1 in 10 anni)
AZZURRO        >=0.1    (>1 evento in 10 anni)
GIALLO        >=0.20    (>1 evento in 5 anni)
ARANCIO        >=0.3    (>1.2 eventi in 4 anni)
ROSSO        >=0.4    (1.2 eventi in 3 anni)
    */
    return `#${x > .3999
        ? "E7212E"
        : x > .2999
            ? "F05E25"
            : x > .1999
                ? "F6921F"
                : x > .0999
                    ? "57A6D9"
                    : x < .1
                        ? "2466B0" : "CCCCCC"}`;
}
function Page() {
    if (localStorage.getItem("cysr_useraccountrole_selected")) {
        localStorage.removeItem("cysr_useraccountrole_selected")
        window.location.reload()
    }
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    // filter test s
    const params = new URLSearchParams(window.location.search);
    let activeTags = [];
    try {
        activeTags = params.get('t') && (decodeURI(params.get('t'))) && Base64.decode(decodeURI(params.get('t'))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('t')))) : [];
    } catch (error) {
        console.error("Error in setting active tags:")
        console.error(error)
    }
    // filter test e

    // Our State.
    const [isLoading, setLoading] = useState(true);
    const [views, setViews] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    // const [queryCount, setQueryCount] = useState(0);

    const [allSubscriptions, setAllSubscriptions] = useState([]);

    const [filterMode, setFilterMode] = useState("OR");
    const [filters, setFilters] = useState({
        companies_sizes: null,
        companies_industries: null,
        companies_subscriptions: null,
        companies_countries: null,
        companies_ratings: null,
        companies_cti_ratings: null,
        companies_risk_lef: null,
        companies_risk_lm: null,
        companies_risk_risk: null,
        companies_tags: null,
    });

    const accountsRoleCallUrl = `${cysrConfig().api_base_url}user/mycompanies/`;
    useMemo(() => {
        setLoading(true);
        const pageTitle = "Dashboard";
        document.title = `${pageTitle} - ${document.title.split(" - ")[1] ? document.title.split(" - ")[1] : document.title}`;
        // if (localStorage.getItem("cysr_useraccountroles")) {
        if (localStorage.getItem("cysr_access")) {
            /*
            // call table of asset + call table of checks
            fetch(accountsRoleCallUrl, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                },
            })
                .then((response) => response.json())
                .then(async (responseAccountsRoleData) => {
            */
            fetch(`${cysrConfig().api_base_url}group-insight/myselectors/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                },
            })
                .then((r) => r.json())
                .then(async (rSelData) => {
                    let rSelectors = rSelData;
                    // rSelectors.companies_countries.sort((a, b) => b.value - a.value);
                    // rSelectors.companies_countries.sort((a, b) => a.name.localeCompare(b.name));
                    rSelectors.companies_countries.sort((a, b) => ((a === "XX" ? "Z" : a).localeCompare(b === "XX" ? "Z" : b)));
                    rSelectors.companies_countries[rSelectors.companies_countries.length - 1] = rSelectors.companies_countries[rSelectors.companies_countries.length - 1] === "XX" ? "other" : rSelectors.companies_countries[rSelectors.companies_countries.length - 1];

                    rSelectors.companies_industries.sort((a, b) => getIndustry(a).localeCompare(getIndustry(b)));

                    rSelectors.companies_sizes.sort((b, a) => ((a === "N/A" ? "a" : a).localeCompare(b === "N/A" ? "a" : b)));
                    // this was to convert N/A to other, but then passing other to API does not work
                    // rSelectors.companies_sizes[rSelectors.companies_sizes.length - 1] = rSelectors.companies_sizes[rSelectors.companies_sizes.length - 1] === "N/A" ? "other" : rSelectors.companies_sizes[rSelectors.companies_sizes.length - 1];
                    // just remove it why would someone filter for N/A that do not even work
                    rSelectors.companies_sizes.pop();

                    rSelectors.companies_tags.sort((a, b) => a.localeCompare(b));
                    // console.log('/user/selectors')
                    // console.log(rSelectors)
                    setAllSubscriptions(rSelectors.companies_subscriptions)
                    /*

                    console.log(responseAccountsRoleData)
                    if (responseAccountsRoleData.code && responseAccountsRoleData.code === "token_not_valid") {
                        // remove localstorage and go to login
                        const dMode = localStorage.getItem('cysr_dark_mode') ? localStorage.getItem('cysr_dark_mode') : "true";
                        localStorage.clear();
                        localStorage.setItem('cysr_dark_mode', dMode);
                        window.location.href = `/cysr/login`;
                        return
                    }
                    // up DB
                    // await updateCompanies({ data: responseAccountsRoleData })
                    // set as true to verify it has data
                    // localStorage.setItem("cysr_useraccountroles", true);

                    // do here s
                    const userAccounts = responseAccountsRoleData;
                    userAccounts.sort((a, b) =>
                        a.account_name
                            .toLowerCase()
                            .localeCompare(b.account_name.toLowerCase())
                    );
                    // timeline
                    const tData = [];
                    // change with actual data
                    const timeLineData = [
                        {
                            mu_created_at: "2020-12-10T21:00:00.000Z",
                            rating_val: 10
                        },
                        {
                            mu_created_at: "2020-12-10T21:00:00.000Z",
                            rating_val: 10
                        },
                        {
                            mu_created_at: "2020-12-10T21:00:00.000Z",
                            rating_val: 10
                        }
                    ];
                    timeLineData.forEach(t => {
                        // "date": "2020-12-10T21:00:00.000Z",
                        // "value": 10
                        tData.push({
                            date: t.mu_created_at,
                            value: parseInt(t.rating_val, 10)
                        });
                    });
                    // set values of boxes
                    const riskResponse = {
                        "RANSOMWARE": {
                            "CALCULATED": {
                                "riskData": {
                                    "lm": {
                                        "minValue": 24642,
                                        "averageValue": 195173,
                                        "maxValue": 655603
                                    },
                                    "badge": {
                                        "lef": {
                                            "minValue": "0.125121",
                                            "averageValue": "0.305659",
                                            "maxValue": "0.638925"
                                        },
                                        "risk": {
                                            "minValue": 3458,
                                            "averageValue": 59619,
                                            "maxValue": 315422
                                        }
                                    }
                                },
                                "report_body": {
                                    "fairTree": {
                                        "name": "Risk",
                                        "children": [
                                            {
                                                "name": "Loss Event Frequency",
                                                "children": [
                                                    {
                                                        "name": "Threat Event Frequency",
                                                        "children": [
                                                            {
                                                                "name": "Contact Frequency",
                                                                "maxValue": 0,
                                                                "minValue": 0,
                                                                "shortName": "C",
                                                                "averageValue": 0
                                                            },
                                                            {
                                                                "name": "Probability of Action",
                                                                "maxValue": 0,
                                                                "minValue": 0,
                                                                "shortName": "A",
                                                                "averageValue": 0
                                                            }
                                                        ],
                                                        "maxValue": 0.66,
                                                        "minValue": 0.125,
                                                        "shortName": "TEF",
                                                        "averageValue": 0.25
                                                    },
                                                    {
                                                        "name": "Vulnerability",
                                                        "children": [
                                                            {
                                                                "name": "Threat Capability",
                                                                "maxValue": 1.0,
                                                                "minValue": 0.2939278324148858,
                                                                "shortName": "TC",
                                                                "averageValue": 0.6790745833333334
                                                            },
                                                            {
                                                                "name": "Resistance Strength",
                                                                "maxValue": 0.36627670481927427,
                                                                "minValue": 0.2662767048192743,
                                                                "shortName": "CS",
                                                                "averageValue": 0.3162767048192743
                                                            }
                                                        ],
                                                        "maxValue": 1.0,
                                                        "minValue": 1.0,
                                                        "shortName": "V",
                                                        "averageValue": 1.0
                                                    }
                                                ],
                                                "maxValue": 0.6389247758868197,
                                                "minValue": 0.12512122920038088,
                                                "shortName": "LEF",
                                                "averageValue": 0.30565915668572935
                                            },
                                            {
                                                "name": "Loss Magnitude",
                                                "children": [
                                                    {
                                                        "name": "Primary Loss",
                                                        "maxValue": 720380.9332353598,
                                                        "minValue": 21808.72366748641,
                                                        "shortName": "PL",
                                                        "averageValue": 103223.69958396559
                                                    },
                                                    {
                                                        "name": "Secondary Risk",
                                                        "children": [
                                                            {
                                                                "name": "Secondary Loss Event Frequency",
                                                                "maxValue": 1.0,
                                                                "minValue": 0.99,
                                                                "shortName": "SLEF",
                                                                "averageValue": 1.0
                                                            },
                                                            {
                                                                "name": "Secondary Loss Magnitude",
                                                                "maxValue": 6300.082272,
                                                                "minValue": 1488.51714,
                                                                "shortName": "SLM",
                                                                "averageValue": 3150.041136
                                                            }
                                                        ],
                                                        "maxValue": 6047.07020904635,
                                                        "minValue": 1526.8021300843877,
                                                        "shortName": "SL",
                                                        "averageValue": 3401.9261961572215
                                                    }
                                                ],
                                                "maxValue": 655603.5570346522,
                                                "minValue": 24642.113840237635,
                                                "shortName": "LM",
                                                "averageValue": 195173.2374012178
                                            }
                                        ],
                                        "maxValue": 315422.7001267025,
                                        "minValue": 3458.4622875954037,
                                        "shortName": "R",
                                        "averageValue": 59619.73730022518
                                    },
                                    "lossCurve": [
                                        {
                                            "cost": 0,
                                            "percentage": 100.0
                                        },
                                        {
                                            "cost": 13142,
                                            "percentage": 93.85
                                        },
                                        {
                                            "cost": 26285,
                                            "percentage": 77.47
                                        },
                                        {
                                            "cost": 39427,
                                            "percentage": 60.88
                                        },
                                        {
                                            "cost": 52570,
                                            "percentage": 46.5
                                        },
                                        {
                                            "cost": 65713,
                                            "percentage": 34.82
                                        },
                                        {
                                            "cost": 78855,
                                            "percentage": 25.54
                                        },
                                        {
                                            "cost": 91998,
                                            "percentage": 18.43
                                        },
                                        {
                                            "cost": 105140,
                                            "percentage": 13.33
                                        },
                                        {
                                            "cost": 118283,
                                            "percentage": 9.92
                                        },
                                        {
                                            "cost": 131426,
                                            "percentage": 7.15
                                        },
                                        {
                                            "cost": 144568,
                                            "percentage": 5.05
                                        },
                                        {
                                            "cost": 157711,
                                            "percentage": 3.54
                                        },
                                        {
                                            "cost": 170853,
                                            "percentage": 2.51
                                        },
                                        {
                                            "cost": 183996,
                                            "percentage": 1.62
                                        },
                                        {
                                            "cost": 197139,
                                            "percentage": 1.18
                                        },
                                        {
                                            "cost": 210281,
                                            "percentage": 0.76
                                        },
                                        {
                                            "cost": 223424,
                                            "percentage": 0.53
                                        },
                                        {
                                            "cost": 236567,
                                            "percentage": 0.34
                                        },
                                        {
                                            "cost": 249709,
                                            "percentage": 0.21
                                        },
                                        {
                                            "cost": 262852,
                                            "percentage": 0.11
                                        },
                                        {
                                            "cost": 275994,
                                            "percentage": 0.06
                                        },
                                        {
                                            "cost": 302280,
                                            "percentage": 0.03
                                        },
                                        {
                                            "cost": 315422,
                                            "percentage": 0.01
                                        }
                                    ],
                                    "primaryCost": {
                                        "primaryCostRange": [
                                            {
                                                "title": "Minimum",
                                                "metric": 21808.72366748641
                                            },
                                            {
                                                "title": "Most likely",
                                                "metric": 103223.69958396559
                                            },
                                            {
                                                "title": "Maximum",
                                                "metric": 720380.9332353598
                                            }
                                        ],
                                        "primaryCostDetails": [
                                            {
                                                "name": "Recovery cost",
                                                "maxValue": 601562.5,
                                                "minValue": 4010.416666666667,
                                                "averageValue": 60156.25
                                            },
                                            {
                                                "name": "Business interruption",
                                                "maxValue": 36051.98323535973,
                                                "minValue": 1245.0170008197406,
                                                "averageValue": 9960.869583965585
                                            },
                                            {
                                                "name": "Ransom Payment",
                                                "maxValue": 82766.45000000001,
                                                "minValue": 16553.29,
                                                "averageValue": 33106.58
                                            }
                                        ]
                                    },
                                    "secondaryCost": {
                                        "secondaryCostRange": [
                                            {
                                                "title": "Minimum",
                                                "metric": 1488.51714
                                            },
                                            {
                                                "title": "Most likely",
                                                "metric": 3150.041136
                                            },
                                            {
                                                "title": "Maximum",
                                                "metric": 6300.082272
                                            }
                                        ],
                                        "secondaryCostDetails": [
                                            {
                                                "name": "Post Breach Security Improvements",
                                                "maxValue": 6300.082272,
                                                "minValue": 1488.51714,
                                                "description": "Security improvements undertaken voluntarily after a breach.",
                                                "averageValue": 3150.041136
                                            }
                                        ]
                                    },
                                    "riskOverRevenue": {
                                        "low": 0.002089289976551733,
                                        "high": 0.19054985451635445,
                                        "mode": 0.036016850608081644
                                    },
                                    "riskDistribution": [
                                        {
                                            "price": 0,
                                            "simulations": 6.15
                                        },
                                        {
                                            "price": 13142,
                                            "simulations": 16.38
                                        },
                                        {
                                            "price": 26285,
                                            "simulations": 16.59
                                        },
                                        {
                                            "price": 39427,
                                            "simulations": 14.38
                                        },
                                        {
                                            "price": 52570,
                                            "simulations": 11.68
                                        },
                                        {
                                            "price": 65713,
                                            "simulations": 9.28
                                        },
                                        {
                                            "price": 78855,
                                            "simulations": 7.11
                                        },
                                        {
                                            "price": 91998,
                                            "simulations": 5.1
                                        },
                                        {
                                            "price": 105140,
                                            "simulations": 3.41
                                        },
                                        {
                                            "price": 118283,
                                            "simulations": 2.77
                                        },
                                        {
                                            "price": 131426,
                                            "simulations": 2.1
                                        },
                                        {
                                            "price": 144568,
                                            "simulations": 1.51
                                        },
                                        {
                                            "price": 157711,
                                            "simulations": 1.03
                                        },
                                        {
                                            "price": 170853,
                                            "simulations": 0.89
                                        },
                                        {
                                            "price": 183996,
                                            "simulations": 0.44
                                        },
                                        {
                                            "price": 197139,
                                            "simulations": 0.42
                                        },
                                        {
                                            "price": 210281,
                                            "simulations": 0.23
                                        },
                                        {
                                            "price": 223424,
                                            "simulations": 0.19
                                        },
                                        {
                                            "price": 236567,
                                            "simulations": 0.13
                                        },
                                        {
                                            "price": 249709,
                                            "simulations": 0.1
                                        },
                                        {
                                            "price": 262852,
                                            "simulations": 0.05
                                        },
                                        {
                                            "price": 275994,
                                            "simulations": 0.03
                                        },
                                        {
                                            "price": 302280,
                                            "simulations": 0.02
                                        },
                                        {
                                            "price": 315422,
                                            "simulations": 0.01
                                        }
                                    ],
                                    "lossMagnitudeOverRevenue": {
                                        "low": 0.014886535450196085,
                                        "high": 0.39605634712776266,
                                        "mode": 0.11790600986342764
                                    }
                                },
                                "params_conf": {
                                    "MFA": 0.6089,
                                    "BACKUP": 0.3958,
                                    "RANSOMWARE_AWARENESS": 0.65,
                                    "RANSOMWARE_RECOVERY_COST": {
                                        "low": 4010.0,
                                        "high": 601562.0,
                                        "mode": 60156.0
                                    },
                                    "RANSOMWARE_RANSOM_PAYMENT": {
                                        "low": 16553.0,
                                        "high": 82766.0,
                                        "mode": 33107.0
                                    },
                                    "RANSOMWARE_BUSINESS_INTERRUPTION": {
                                        "low": 1245.0,
                                        "high": 36052.0,
                                        "mode": 9961.0
                                    },
                                    "RANSOMWARE_VOLUNTARY_IMPROVEMENT": {
                                        "low": 1489.0,
                                        "high": 6300.0,
                                        "mode": 3150.0
                                    }
                                }
                            },
                            "CUSTOM": {
                                "riskData": {
                                    "lm": {
                                        "minValue": 24642,
                                        "averageValue": 195173,
                                        "maxValue": 655603
                                    },
                                    "badge": {
                                        "lef": {
                                            "minValue": "0.125121",
                                            "averageValue": "0.305659",
                                            "maxValue": "0.638925"
                                        },
                                        "risk": {
                                            "minValue": 3458,
                                            "averageValue": 59619,
                                            "maxValue": 315422
                                        }
                                    }
                                },
                                "report_body": {
                                    "fairTree": {
                                        "name": "Risk",
                                        "children": [
                                            {
                                                "name": "Loss Event Frequency",
                                                "children": [
                                                    {
                                                        "name": "Threat Event Frequency",
                                                        "children": [
                                                            {
                                                                "name": "Contact Frequency",
                                                                "maxValue": 0,
                                                                "minValue": 0,
                                                                "shortName": "C",
                                                                "averageValue": 0
                                                            },
                                                            {
                                                                "name": "Probability of Action",
                                                                "maxValue": 0,
                                                                "minValue": 0,
                                                                "shortName": "A",
                                                                "averageValue": 0
                                                            }
                                                        ],
                                                        "maxValue": 0.66,
                                                        "minValue": 0.125,
                                                        "shortName": "TEF",
                                                        "averageValue": 0.25
                                                    },
                                                    {
                                                        "name": "Vulnerability",
                                                        "children": [
                                                            {
                                                                "name": "Threat Capability",
                                                                "maxValue": 1.0,
                                                                "minValue": 0.2939278324148858,
                                                                "shortName": "TC",
                                                                "averageValue": 0.6790745833333334
                                                            },
                                                            {
                                                                "name": "Resistance Strength",
                                                                "maxValue": 0.36627670481927427,
                                                                "minValue": 0.2662767048192743,
                                                                "shortName": "CS",
                                                                "averageValue": 0.3162767048192743
                                                            }
                                                        ],
                                                        "maxValue": 1.0,
                                                        "minValue": 1.0,
                                                        "shortName": "V",
                                                        "averageValue": 1.0
                                                    }
                                                ],
                                                "maxValue": 0.6389247758868197,
                                                "minValue": 0.12512122920038088,
                                                "shortName": "LEF",
                                                "averageValue": 0.30565915668572935
                                            },
                                            {
                                                "name": "Loss Magnitude",
                                                "children": [
                                                    {
                                                        "name": "Primary Loss",
                                                        "maxValue": 720380.9332353598,
                                                        "minValue": 21808.72366748641,
                                                        "shortName": "PL",
                                                        "averageValue": 103223.69958396559
                                                    },
                                                    {
                                                        "name": "Secondary Risk",
                                                        "children": [
                                                            {
                                                                "name": "Secondary Loss Event Frequency",
                                                                "maxValue": 1.0,
                                                                "minValue": 0.99,
                                                                "shortName": "SLEF",
                                                                "averageValue": 1.0
                                                            },
                                                            {
                                                                "name": "Secondary Loss Magnitude",
                                                                "maxValue": 6300.082272,
                                                                "minValue": 1488.51714,
                                                                "shortName": "SLM",
                                                                "averageValue": 3150.041136
                                                            }
                                                        ],
                                                        "maxValue": 6047.07020904635,
                                                        "minValue": 1526.8021300843877,
                                                        "shortName": "SL",
                                                        "averageValue": 3401.9261961572215
                                                    }
                                                ],
                                                "maxValue": 655603.5570346522,
                                                "minValue": 24642.113840237635,
                                                "shortName": "LM",
                                                "averageValue": 195173.2374012178
                                            }
                                        ],
                                        "maxValue": 315422.7001267025,
                                        "minValue": 3458.4622875954037,
                                        "shortName": "R",
                                        "averageValue": 59619.73730022518
                                    },
                                    "lossCurve": [
                                        {
                                            "cost": 0,
                                            "percentage": 100.0
                                        },
                                        {
                                            "cost": 13142,
                                            "percentage": 93.85
                                        },
                                        {
                                            "cost": 26285,
                                            "percentage": 77.47
                                        },
                                        {
                                            "cost": 39427,
                                            "percentage": 60.88
                                        },
                                        {
                                            "cost": 52570,
                                            "percentage": 46.5
                                        },
                                        {
                                            "cost": 65713,
                                            "percentage": 34.82
                                        },
                                        {
                                            "cost": 78855,
                                            "percentage": 25.54
                                        },
                                        {
                                            "cost": 91998,
                                            "percentage": 18.43
                                        },
                                        {
                                            "cost": 105140,
                                            "percentage": 13.33
                                        },
                                        {
                                            "cost": 118283,
                                            "percentage": 9.92
                                        },
                                        {
                                            "cost": 131426,
                                            "percentage": 7.15
                                        },
                                        {
                                            "cost": 144568,
                                            "percentage": 5.05
                                        },
                                        {
                                            "cost": 157711,
                                            "percentage": 3.54
                                        },
                                        {
                                            "cost": 170853,
                                            "percentage": 2.51
                                        },
                                        {
                                            "cost": 183996,
                                            "percentage": 1.62
                                        },
                                        {
                                            "cost": 197139,
                                            "percentage": 1.18
                                        },
                                        {
                                            "cost": 210281,
                                            "percentage": 0.76
                                        },
                                        {
                                            "cost": 223424,
                                            "percentage": 0.53
                                        },
                                        {
                                            "cost": 236567,
                                            "percentage": 0.34
                                        },
                                        {
                                            "cost": 249709,
                                            "percentage": 0.21
                                        },
                                        {
                                            "cost": 262852,
                                            "percentage": 0.11
                                        },
                                        {
                                            "cost": 275994,
                                            "percentage": 0.06
                                        },
                                        {
                                            "cost": 302280,
                                            "percentage": 0.03
                                        },
                                        {
                                            "cost": 315422,
                                            "percentage": 0.01
                                        }
                                    ],
                                    "primaryCost": {
                                        "primaryCostRange": [
                                            {
                                                "title": "Minimum",
                                                "metric": 21808.72366748641
                                            },
                                            {
                                                "title": "Most likely",
                                                "metric": 103223.69958396559
                                            },
                                            {
                                                "title": "Maximum",
                                                "metric": 720380.9332353598
                                            }
                                        ],
                                        "primaryCostDetails": [
                                            {
                                                "name": "Recovery cost",
                                                "maxValue": 601562.5,
                                                "minValue": 4010.416666666667,
                                                "averageValue": 60156.25
                                            },
                                            {
                                                "name": "Business interruption",
                                                "maxValue": 36051.98323535973,
                                                "minValue": 1245.0170008197406,
                                                "averageValue": 9960.869583965585
                                            },
                                            {
                                                "name": "Ransom Payment",
                                                "maxValue": 82766.45000000001,
                                                "minValue": 16553.29,
                                                "averageValue": 33106.58
                                            }
                                        ]
                                    },
                                    "secondaryCost": {
                                        "secondaryCostRange": [
                                            {
                                                "title": "Minimum",
                                                "metric": 1488.51714
                                            },
                                            {
                                                "title": "Most likely",
                                                "metric": 3150.041136
                                            },
                                            {
                                                "title": "Maximum",
                                                "metric": 6300.082272
                                            }
                                        ],
                                        "secondaryCostDetails": [
                                            {
                                                "name": "Post Breach Security Improvements",
                                                "maxValue": 6300.082272,
                                                "minValue": 1488.51714,
                                                "description": "Security improvements undertaken voluntarily after a breach.",
                                                "averageValue": 3150.041136
                                            }
                                        ]
                                    },
                                    "riskOverRevenue": {
                                        "low": 0.002089289976551733,
                                        "high": 0.19054985451635445,
                                        "mode": 0.036016850608081644
                                    },
                                    "riskDistribution": [
                                        {
                                            "price": 0,
                                            "simulations": 6.15
                                        },
                                        {
                                            "price": 13142,
                                            "simulations": 16.38
                                        },
                                        {
                                            "price": 26285,
                                            "simulations": 16.59
                                        },
                                        {
                                            "price": 39427,
                                            "simulations": 14.38
                                        },
                                        {
                                            "price": 52570,
                                            "simulations": 11.68
                                        },
                                        {
                                            "price": 65713,
                                            "simulations": 9.28
                                        },
                                        {
                                            "price": 78855,
                                            "simulations": 7.11
                                        },
                                        {
                                            "price": 91998,
                                            "simulations": 5.1
                                        },
                                        {
                                            "price": 105140,
                                            "simulations": 3.41
                                        },
                                        {
                                            "price": 118283,
                                            "simulations": 2.77
                                        },
                                        {
                                            "price": 131426,
                                            "simulations": 2.1
                                        },
                                        {
                                            "price": 144568,
                                            "simulations": 1.51
                                        },
                                        {
                                            "price": 157711,
                                            "simulations": 1.03
                                        },
                                        {
                                            "price": 170853,
                                            "simulations": 0.89
                                        },
                                        {
                                            "price": 183996,
                                            "simulations": 0.44
                                        },
                                        {
                                            "price": 197139,
                                            "simulations": 0.42
                                        },
                                        {
                                            "price": 210281,
                                            "simulations": 0.23
                                        },
                                        {
                                            "price": 223424,
                                            "simulations": 0.19
                                        },
                                        {
                                            "price": 236567,
                                            "simulations": 0.13
                                        },
                                        {
                                            "price": 249709,
                                            "simulations": 0.1
                                        },
                                        {
                                            "price": 262852,
                                            "simulations": 0.05
                                        },
                                        {
                                            "price": 275994,
                                            "simulations": 0.03
                                        },
                                        {
                                            "price": 302280,
                                            "simulations": 0.02
                                        },
                                        {
                                            "price": 315422,
                                            "simulations": 0.01
                                        }
                                    ],
                                    "lossMagnitudeOverRevenue": {
                                        "low": 0.014886535450196085,
                                        "high": 0.39605634712776266,
                                        "mode": 0.11790600986342764
                                    }
                                },
                                "params_conf": {
                                    "MFA": 0.6089,
                                    "BACKUP": 0.3958,
                                    "RANSOMWARE_AWARENESS": 0.65,
                                    "RANSOMWARE_RECOVERY_COST": {
                                        "low": 4010.0,
                                        "high": 601562.0,
                                        "mode": 60156.0
                                    },
                                    "RANSOMWARE_RANSOM_PAYMENT": {
                                        "low": 16553.0,
                                        "high": 82766.0,
                                        "mode": 33107.0
                                    },
                                    "RANSOMWARE_BUSINESS_INTERRUPTION": {
                                        "low": 1245.0,
                                        "high": 36052.0,
                                        "mode": 9961.0
                                    },
                                    "RANSOMWARE_VOLUNTARY_IMPROVEMENT": {
                                        "low": 1489.0,
                                        "high": 6300.0,
                                        "mode": 3150.0
                                    }
                                }
                            }
                        }
                    };
                    let dataFromRiskResponse = [];
                    for (const key in riskResponse) {
                        if (Object.hasOwnProperty.call(riskResponse, key)) {
                            const element = riskResponse[key].CUSTOM;


                            dataFromRiskResponse.push(
                                {
                                    "riskData": {
                                        "date": "2024-02-09",
                                        "name": key ? key : element.riskData?.name,
                                        "risk": {
                                            "minValue": element.riskData?.badge?.risk?.minValue,
                                            "averageValue": element.riskData?.badge?.risk?.averageValue,
                                            "maxValue": element.riskData?.badge?.risk?.maxValue
                                        },
                                        "badge": {
                                            "lef": {
                                                "minValue": element.riskData?.badge?.lef?.minValue,
                                                "averageValue": element.riskData?.badge?.lef?.averageValue,
                                                "maxValue": element.riskData?.badge?.lef?.maxValue
                                            },
                                            "lm": {
                                                "minValue": element.riskData?.lm?.minValue,
                                                "averageValue": element.riskData?.lm?.averageValue,
                                                "maxValue": element.riskData?.lm?.maxValue
                                            }
                                        }
                                    },
                                    "fairTree": element.report_body.fairTree,
                                    "lossCurve": element.report_body.lossCurve,
                                    "primaryCost": element.report_body.primaryCost,
                                    "secondaryCost": element.report_body.secondaryCost,
                                    "riskDistribution": element.report_body.riskDistribution,
                                    /*,
                                    "primaryCost": {
                                        "primaryCostRange": [
                                            {
                                                "title": "Minimum",
                                                "metric": 100000
                                            },
                                            {
                                                "title": "Most likely",
                                                "metric": 2000000
                                            },
                                            {
                                                "title": "Maximum",
                                                "metric": 3000000
                                            }
                                        ],
                                        "primaryCostDetails": [
                                            {
                                                "name": "Recovery cost",
                                                "minValue": 4000000,
                                                "averageValue": 5000000,
                                                "maxValue": 19000000
                                            },
                                            {
                                                "name": "Business interruption",
                                                "minValue": 4000000,
                                                "averageValue": 5000000,
                                                "maxValue": 19000000
                                            },
                                            {
                                                "name": "Cyber extortion",
                                                "minValue": 4000000,
                                                "averageValue": 5000000,
                                                "maxValue": 19000000
                                            }
                                        ]
                                    },
                                    "secondaryCost": {
                                        "secondaryCostRange": [
                                            {
                                                "title": "Minimum",
                                                "metric": 100000
                                            },
                                            {
                                                "title": "Most likely",
                                                "metric": 2000000
                                            },
                                            {
                                                "title": "Maximum",
                                                "metric": 3000000
                                            }
                                        ],
                                        "secondaryCostDetails": [
                                            {
                                                "name": "Post Breach Security Improvements",
                                                "description": "Security improvements undertaken voluntarily after a breach.",
                                                "minValue": 4000000,
                                                "averageValue": 9000000,
                                                "maxValue": 10000000
                                            }
                                        ]
                                    },
                                    "riskDistribution": [
                                        {
                                            "price": 0,
                                            "simulations": 0
                                        },
                                        {
                                            "price": 10000,
                                            "simulations": 10
                                        },
                                        {
                                            "price": 15000,
                                            "simulations": 20
                                        },
                                        {
                                            "price": 16000,
                                            "simulations": 15
                                        },
                                        {
                                            "price": 30000,
                                            "simulations": 50
                                        },
                                        {
                                            "price": 45000,
                                            "simulations": 60
                                        },
                                        {
                                            "price": 60000,
                                            "simulations": 30
                                        },
                                        {
                                            "price": 75000,
                                            "simulations": 20
                                        },
                                        {
                                            "price": 90000,
                                            "simulations": 2
                                        }
                                    ],
                                    "lossCurve": [
                                        [
                                            {
                                                "cost": 0,
                                                "percentage": 100
                                            },
                                            {
                                                "cost": 50000,
                                                "percentage": 95
                                            },
                                            {
                                                "cost": 100000,
                                                "percentage": 60
                                            },
                                            {
                                                "cost": 150000,
                                                "percentage": 10
                                            },
                                            {
                                                "cost": 200000,
                                                "percentage": 0
                                            },
                                            {
                                                "cost": 250000,
                                                "percentage": 0
                                            }
                                        ]
                                    ],
                                    "fairTree": {
                                        "name": "Risk",
                                        "shortName": "R",
                                        "minValue": 8000000,
                                        "averageValue": 14000000,
                                        "maxValue": 29000000,
                                        "children": [
                                            {
                                                "name": "Loss Event Frequency",
                                                "shortName": "LEF",
                                                "minValue": 4000000,
                                                "averageValue": 9000000,
                                                "maxValue": 19000000,
                                                "children": [
                                                    {
                                                        "name": "Threat Event Frequency",
                                                        "shortName": "TEF",
                                                        "minValue": 0.5,
                                                        "averageValue": 0.5,
                                                        "maxValue": 1,
                                                        "children": [
                                                            {
                                                                "name": "Contact Frequency",
                                                                "shortName": "C",
                                                                "minValue": 0,
                                                                "averageValue": 0,
                                                                "maxValue": 0
                                                            },
                                                            {
                                                                "name": "Probability of Action",
                                                                "shortName": "A",
                                                                "minValue": 0,
                                                                "averageValue": 0,
                                                                "maxValue": 0
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "name": "Vulnerability",
                                                        "shortName": "V",
                                                        "minValue": 0.1,
                                                        "averageValue": 0.2,
                                                        "maxValue": 0.5,
                                                        "children": [
                                                            {
                                                                "name": "Threat Capability",
                                                                "shortName": "TC",
                                                                "minValue": 0.1,
                                                                "averageValue": 0.62,
                                                                "maxValue": 1
                                                            },
                                                            {
                                                                "name": "Resistance Strength",
                                                                "shortName": "CS",
                                                                "minValue": 0.35,
                                                                "averageValue": 0.4,
                                                                "maxValue": 0.45
                                                            }
                                                        ]
                                                    }
                                                ]
                                            },
                                            {
                                                "name": "Loss Magnitude",
                                                "shortName": "LM",
                                                "minValue": 4000000,
                                                "averageValue": 5000000,
                                                "maxValue": 10000000,
                                                "children": [
                                                    {
                                                        "name": "Primary Loss",
                                                        "shortName": "PL",
                                                        "minValue": 4000000,
                                                        "averageValue": 5000000,
                                                        "maxValue": 10000000
                                                    },
                                                    {
                                                        "name": "Secondary Risk",
                                                        "shortName": "SL",
                                                        "minValue": 4000000,
                                                        "averageValue": 5000000,
                                                        "maxValue": 10000000,
                                                        "children": [
                                                            {
                                                                "name": "Secondary Loss Event Frequency",
                                                                "shortName": "SLEF",
                                                                "minValue": 1,
                                                                "averageValue": 1,
                                                                "maxValue": 1
                                                            },
                                                            {
                                                                "name": "Secondary Loss Magnitude",
                                                                "shortName": "SLM",
                                                                "minValue": 4000000,
                                                                "averageValue": 5000000,
                                                                "maxValue": 10000000
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                    
                                });
                        }
                    }
                    */
                    setViews({
                        selectors: rSelectors,
                        /*
                        // risk: dataFromRiskResponse,
                        // accounts_role: userAccounts,
                        timeline: {
                            data: {
                                "labels": [
                                    "Rating score"
                                ],
                                "datasets": [
                                    {
                                        "label": "Rating",
                                        "data": tData
                                    }
                                ]
                            },
                            options: {
                                // "title": "Line (time series) - Time interval hourly with custom ticks formats ('MMM d, HH:mm' and 'HH:mm')",
                                "title": false,
                                "axes": {
                                    "left": {},
                                    "bottom": {
                                        "scaleType": "time"
                                    }
                                },
                                "timeScale": {
                                    "timeIntervalFormats": {
                                        "hourly": {
                                            "primary": "MMM d, HH:mm",
                                            "secondary": "HH:mm"
                                        }
                                    }
                                },
                                "legend": {
                                    "enabled": false,
                                },
                                "height": "400px",
                                "toolbar": { "enabled": false },
                            }
                        },
                        */
                        cysr_tags_active: activeTags
                    });
                    // set at 0 so to show the alert importantmessage
                    setLoading(0);
                    // do here e
                })
                .catch((e) => {
                    console.error(e)
                    setLoading(false);
                })
            /*
                })
                .catch((err) => {
                    console.error(err)
                    setLoading(false);
                });
            */
        } else {
            setViews({});
            setLoading(false);
        }
    }, []);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className={`${darkMode ? "dark" : "light"}`}>
                <Grid container spacing={3} className="cysr-insight" alignItems={"start"}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox pt={3} pb={2} px={3} fullWidth>
                                <MDBox mb={1} fullWidth>
                                    <MDBox>
                                        <MDTypography variant="h5">Group Insight</MDTypography>
                                        <MDTypography variant="button" color="text" fontWeight="regular">
                                            Filter your companies to get aggregated results
                                        </MDTypography>
                                        <div className="flex flex-col md:flex-row pt-2">
                                            {views && views.selectors ? <>
                                                <div className='w-full flex flex-col gap-6'>
                                                    {[{
                                                        label: "Sizes",
                                                        field: "companies_sizes"
                                                    }, {
                                                        label: "Industries",
                                                        field: "companies_industries",
                                                    }, {
                                                        label: "Subscriptions",
                                                        field: "companies_subscriptions",
                                                    }, {
                                                        label: "Countries",
                                                        field: "companies_countries",
                                                    }/*, {
                                                        label: "Tags",
                                                        field: "companies_tags",

                                                    }*/
                                                    ].map((sel) => {
                                                        const s = sel.field === "companies_subscriptions"
                                                            ? Object.values(views.selectors[sel.field])
                                                            : views.selectors[sel.field]
                                                        return <div>
                                                            <Text>{sel.label}</Text>
                                                            {s ?
                                                                <ToggleGroup className="justify-start mt-2" type="multiple" onValueChange={(v) => {
                                                                    const currentFilters = filters;
                                                                    let newFilters = {};
                                                                    for (const key in currentFilters) {
                                                                        if (Object.hasOwnProperty.call(currentFilters, key)) {
                                                                            const value = currentFilters[key];
                                                                            newFilters[key] = key === sel.field ? v : value
                                                                        }
                                                                    }
                                                                    setFilters(newFilters)
                                                                }
                                                                }>
                                                                    {s.length > 0 ? (
                                                                        // if sub sort here, other ones already sorted
                                                                        sel.field === "companies_subscriptions"
                                                                            ? s.sort((a, b) => a.localeCompare(b))
                                                                            : s
                                                                    ).map((x) => x ? <ToggleGroupItem className="inline-flex gap-2 items-center" value={x}>
                                                                        {sel.field === "companies_countries"
                                                                            ? (
                                                                                x.toUpperCase() !== "XX"
                                                                                    && x.toUpperCase() !== "OTHER" ?
                                                                                    <Flag
                                                                                        country={x}
                                                                                        // className="mr-2"
                                                                                        // variant="circle" //Only works with rasterized images (jpg or png)
                                                                                        type="svg"
                                                                                        width="20"

                                                                                    // className="mr-2.5 fill-blue-500"
                                                                                    // viewBox="0 0 24 24"
                                                                                    // height="20"
                                                                                    /> : null
                                                                            ) : null}
                                                                        <span className='text-tremor-default text-white text-xs'>
                                                                            {sel.field === "companies_industries" ? getIndustry(x) : x}
                                                                        </span>
                                                                    </ToggleGroupItem> : null
                                                                    ) : null}
                                                                </ToggleGroup>
                                                                : null}
                                                            {sel.field === "companies_tags"
                                                                ?
                                                                <div className='flex gap-2 items-center'>
                                                                    <Text className='text-xs'>Only companies matching all the selected tags</Text>
                                                                    <Switch checked={filterMode === "AND"} onChange={() => setFilterMode(filterMode === "OR" ? "AND" : "OR")} />
                                                                </div> : null}

                                                        </div>
                                                    })}
                                                </div>
                                                <div className='w-full flex flex-col gap-2 px-6'>
                                                    {
                                                        [
                                                            {
                                                                label: "Ratings",
                                                                field: "companies_ratings",
                                                                type: "0-100"
                                                            },
                                                            {
                                                                label: "CTI Ratings",
                                                                field: "companies_cti_ratings",
                                                                type: "0-100"
                                                            },
                                                            /*
                                                            {
                                                                label: "LEF Risk",
                                                                field: "companies_risk_lef",
                                                                type: "0-?",
                                                                percentage: true
                                                            },
                                                            {
                                                                label: "LM Risk",
                                                                field: "companies_risk_lm",
                                                                type: "0-?"
                                                            },
                                                            */
                                                            {
                                                                label: "Risk",
                                                                field: "companies_risk_risk",
                                                                type: "0-?"
                                                            }
                                                        ].map((sel) => {
                                                            const s = views.selectors[sel.field]
                                                            // percentage s
                                                            if (sel.percentage) {
                                                                if (s.max < 1.1) {
                                                                    s.max = (s.max * 100).toFixed(2);
                                                                }
                                                                if (s.min < 1.1) {
                                                                    s.min = (s.min * 100).toFixed(2);
                                                                }
                                                            }
                                                            // percentage e
                                                            return <>
                                                                <Text>{sel.label}</Text>
                                                                <Slider
                                                                    defaultValue={[s.min, s.max]}
                                                                    //value={value}
                                                                    valueLabelDisplay="auto"
                                                                    // shiftStep={10}
                                                                    step={1}
                                                                    marks={[
                                                                        {
                                                                            value: s.min,
                                                                            label: sel.type === "0-100" ? `${s.min}` : sel.type === "0-?" ? sel.percentage ? `${s.min}%` : `${valueFormatter(s.min)}` : `${s.min}`,
                                                                        },
                                                                        {
                                                                            value: s.max,
                                                                            label: sel.type === "0-100" ? `${s.max}` : sel.type === "0-?" ? sel.percentage ? `${s.max}%` : `${valueFormatter(s.max)}` : `${s.max}`
                                                                        },
                                                                    ]}
                                                                    onChange={(e, v) => {
                                                                        const currentFilters = filters;
                                                                        let newFilters = {};
                                                                        for (const key in currentFilters) {
                                                                            if (Object.hasOwnProperty.call(currentFilters, key)) {
                                                                                const value = currentFilters[key];
                                                                                newFilters[key] = key === sel.field ? v : value
                                                                            }
                                                                        }
                                                                        setFilters(newFilters)
                                                                    }}
                                                                    min={0}
                                                                    max={sel.type === "0-100" ? 100 : s.max}
                                                                    valueLabelFormat={(value) => {
                                                                        return sel.type === "0-100" ? `${value}` : sel.type === "0-?" ? sel.percentage ? `${value}%` : `${valueFormatter(value)}` : `${value}`;
                                                                    }}
                                                                /></>
                                                        }
                                                        )
                                                    }
                                                    <div className='mt-4'>
                                                        {
                                                            //tags dopo risks s
                                                            [{
                                                                label: "Tags",
                                                                field: "companies_tags",

                                                            }
                                                            ].map((sel) => {
                                                                const s = sel.field === "companies_subscriptions"
                                                                    ? Object.values(views.selectors[sel.field])
                                                                    : views.selectors[sel.field]
                                                                return <div>
                                                                    <Text>{sel.label}</Text>
                                                                    {s ?
                                                                        <ToggleGroup className="justify-start mt-2" type="multiple" onValueChange={(v) => {
                                                                            const currentFilters = filters;
                                                                            let newFilters = {};
                                                                            for (const key in currentFilters) {
                                                                                if (Object.hasOwnProperty.call(currentFilters, key)) {
                                                                                    const value = currentFilters[key];
                                                                                    newFilters[key] = key === sel.field ? v : value
                                                                                }
                                                                            }
                                                                            setFilters(newFilters)
                                                                        }
                                                                        }>
                                                                            {s.length > 0 ? (
                                                                                // if sub sort here, other ones already sorted
                                                                                sel.field === "companies_subscriptions"
                                                                                    ? s.sort((a, b) => a.localeCompare(b))
                                                                                    : s
                                                                            ).map((x) => x ? <ToggleGroupItem className="inline-flex gap-2 items-center" value={x}>
                                                                                {sel.field === "companies_countries"
                                                                                    ? (
                                                                                        x.toUpperCase() !== "XX"
                                                                                            && x.toUpperCase() !== "OTHER" ?
                                                                                            <Flag
                                                                                                country={x}
                                                                                                // className="mr-2"
                                                                                                // variant="circle" //Only works with rasterized images (jpg or png)
                                                                                                type="svg"
                                                                                                width="20"

                                                                                            // className="mr-2.5 fill-blue-500"
                                                                                            // viewBox="0 0 24 24"
                                                                                            // height="20"
                                                                                            /> : null
                                                                                    ) : null}
                                                                                <span className='text-tremor-default text-white text-xs'>
                                                                                    {sel.field === "companies_industries" ? getIndustry(x) : x}
                                                                                </span>
                                                                            </ToggleGroupItem> : null
                                                                            ) : null}
                                                                        </ToggleGroup>
                                                                        : null}
                                                                    {sel.field === "companies_tags"
                                                                        ?
                                                                        <div className='flex gap-2 items-center'>
                                                                            <Text className='text-xs'>Only companies matching all the selected tags</Text>
                                                                            <Switch checked={filterMode === "AND"} onChange={() => setFilterMode(filterMode === "OR" ? "AND" : "OR")} />
                                                                        </div> : null}

                                                                </div>
                                                            })
                                                            //tags dopo risks e
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                                : null
                                            }
                                        </div>
                                        <div class="mt-4 flex justify-between items-end">
                                            <MDTypography style={{
                                                alignItems: "center",
                                                display: "flex",
                                                gap: "4px"
                                            }} variant="button" color="text" fontWeight="regular">Once you are satisfied with the filters, tap <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().update.icon.svg }} /> to get the summary of the filtered companies.</MDTypography>
                                            <MDButton onClick={() => {
                                                if (isLoading) {
                                                    return null
                                                }
                                                // 1 for importantmessage
                                                setLoading(1)
                                                let queryString = "?";
                                                //tagsmode:  | AND | OR
                                                queryString += filterMode ? `tags_mode=${filterMode.toLowerCase()}` : "";
                                                for (const key in filters) {
                                                    if (Object.hasOwnProperty.call(filters, key)) {
                                                        const v = filters[key];
                                                        if (v && v.length > 0) {
                                                            if (key === "companies_subscriptions") {
                                                                const allS = Object.entries(allSubscriptions)
                                                                v.forEach(sub => {
                                                                    // console.log(allS.find((z) => z[1] === sub)[0])
                                                                    queryString += `&${key}=${allS.find((z) => z[1] === sub)[0]}`;
                                                                });
                                                            } else if (key === "companies_countries" && v === "other") {
                                                                queryString += `&${key}=XX`;
                                                            } else if (key === "companies_sizes" && v === "unknown") {
                                                                // questo non succede, che poi companies_sizes=N/A non è riconosciuto dalla API
                                                                queryString += `&${key}=N/A`;
                                                            } else {
                                                                queryString += `&${key}=${v}`;
                                                            }
                                                        }
                                                    }
                                                }
                                                // fetch(`${cysrConfig().api_base_url}group-insight/overview/${queryString}`, {
                                                fetch(`${cysrConfig().api_base_url}group-insight/counter/${queryString}`, {
                                                    headers: {
                                                        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                                    },
                                                })
                                                    .then((re) => re.json())
                                                    .then((counter) => {
                                                        console.info("counter:", counter)
                                                        // setQueryCount(counter)
                                                        if (counter && !(isNaN(counter)) && parseInt(counter, 10) < max_counter_query) {

                                                            fetch(`${cysrConfig().api_base_url}group-insight/overview/${queryString}`, {
                                                                headers: {
                                                                    Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                                                },
                                                            })
                                                                .then((r) => r.json())
                                                                .then(async (overview) => {
                                                                    // console.log(`group-insight/overview/${queryString}`)
                                                                    // console.log(overview)
                                                                    // console.log(JSON.stringify(queryString))
                                                                    // update data s
                                                                    const userAccounts = overview.account_list ? overview.account_list : overview.company_list;
                                                                    userAccounts.sort((a, b) =>
                                                                        a.account_name
                                                                            .toLowerCase()
                                                                            .localeCompare(b.account_name.toLowerCase())
                                                                    );
                                                                    let currentViews = { ...views };
                                                                    currentViews.accounts_role = userAccounts;
                                                                    currentViews.account_count = overview.account_count;
                                                                    currentViews.attack_perimeter_counters = overview.attack_perimeter_counters;
                                                                    currentViews.radar = overview.radar;
                                                                    currentViews.risks = overview.risks;
                                                                    setViews(currentViews)
                                                                    setLoading(false)
                                                                    // update data e
                                                                })
                                                                .catch((er) => {
                                                                    console.error(er)
                                                                    setLoading(false)
                                                                })
                                                        } else {
                                                            toast.warning(`The query can handle up to ${max_counter_query} companies, it found ${counter} for this search. Use filters to reduce the number below ${max_counter_query} and try again.`)
                                                            setLoading(0)
                                                        }
                                                    })
                                                    .catch((err) => {
                                                        console.error(err)
                                                        setLoading(0)
                                                    })
                                            }} style={cysrConfig().button_style} size="medium">
                                                {isLoading
                                                    ?
                                                    <>
                                                        <span style={cysrConfig().button_icon_left_style} className="spin" dangerouslySetInnerHTML={{ __html: cysrConfig().loading.icon.svg }} />
                                                        Loading insight
                                                    </>
                                                    :
                                                    <>
                                                        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().update.icon.svg }} />
                                                        Get insight
                                                    </>
                                                }</MDButton>
                                        </div>

                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                    {isLoading === 0 || isLoading === 1 ? (
                        <Grid item xs={12}>
                            <ImportantMessage
                                // button={"Go to CTI module"}
                                className={`text-black dark:text-white text-left w-full border pl-11 pr-12 py-3 rounded border-cyan-500/50 bg-cyan-500/5`}
                                title={
                                    "Insight of your companies"
                                }
                                description="The insight module provides a comprehensive summary of the companies you select. The loading time increases as more companies are selected."
                            />
                        </Grid>
                    )
                        : isLoading ? (
                            <Loader />
                        ) : views && views.account_count && views.account_count.count_total === 0
                            ? <Grid item xs={12}>
                                <ImportantMessage
                                    className={`text-black dark:text-white text-left w-full border pl-11 pr-12 py-3 rounded border-red-500/50 bg-red-500/5`}
                                    title={
                                        "No results"
                                    }
                                    description="There are zero results matching this request, adjust the filters."
                                />
                            </Grid>
                            : (
                                <>
                                    <Grid item container xs={12} lg={6} spacing={3}>
                                        <Grid item xs={12}>
                                            <Card>
                                                <MDBox py={3} px={3} fullWidth>
                                                    <MDTypography variant="h5">
                                                        Summary of filtered companies
                                                    </MDTypography>
                                                    <MDTypography variant="button" color="text" fontWeight="regular">
                                                        Companies count
                                                    </MDTypography>
                                                    <MDBox display="flex" flexDirection="row" alignItems="center">
                                                        <MDBox display="flex" alignItems="center">
                                                            <MDTypography mr={1} style={{ fontSize: "1.5rem", fontFamily: "Ubuntu" }} variant="h6">{views && views.account_count && views.account_count.count_total ? views.account_count.count_total : views.accounts_role ? views.accounts_role.length : "0"}</MDTypography>
                                                        </MDBox>
                                                    </MDBox>
                                                    <Divider />
                                                    {views && views.radar ? <CYSRCompanyRadar labels={["Rating by asset type"
                                                        //, "Average rating by asset type"
                                                    ]} hideDefaults={[false
                                                        //, true
                                                    ]} data={views.radar ? transformDataForRadar(views.radar.min) : null}
                                                        //radarIndustryAverage={views.radar ? transformDataForRadar(views.radar.avg) : null} 
                                                        darkMode={darkMode} /> : null}
                                                    <MDTypography variant="button" color="text" fontWeight="regular">
                                                        Assets count
                                                    </MDTypography>
                                                    <MDBox display="flex" flexDirection="row" alignItems="center">
                                                        <MDBox display="flex" alignItems="center">
                                                            <MDTypography mr={1} style={{ fontSize: "1.5rem", fontFamily: "Ubuntu" }} variant="h6">{views && views.attack_perimeter_counters && views.attack_perimeter_counters.asset_number_total ? views.attack_perimeter_counters.asset_number_total : "0"}</MDTypography>
                                                        </MDBox>
                                                        {false && <div>
                                                            <MDTypography variant="overline" style={{ fontSize: "1.125rem" }} color="text">(</MDTypography>
                                                            <MDTypography variant="overline" style={{ fontSize: "1.125rem" }} color="info"><strong>{`${String(views.ratingCompletionRateTotal || 0)}%`}</strong></MDTypography>
                                                            <MDTypography variant="overline" style={{ fontSize: "1.125rem" }} color="text">&nbsp;analysed)</MDTypography>
                                                        </div>}
                                                    </MDBox>
                                                </MDBox>
                                            </Card>
                                        </Grid>

                                        <Grid item container xs={12} spacing={3}>
                                            {
                                                views.attack_perimeter_counters && views.attack_perimeter_counters.assets_number_by_asset_type
                                                    ? Object.entries(views.attack_perimeter_counters.assets_number_by_asset_type).map((a) => <Grid item key={a[0]} xs={12} md={6}>
                                                        <div style={{ position: "relative" }}>
                                                            <InfoButton cysr_info={cysrConfig().i("overview", a[0], "help")} cysr_info_link={cysrConfig().i("overview", a[0], "help_link")} />
                                                        </div>
                                                        <Card style={{ height: "100%", overflow: "hidden" }}>
                                                            <MDBox py={3} px={3} fullWidth>
                                                                {cysrConfig().i("overview", a[0], "title") && (
                                                                    <MDTypography variant="h5">{cysrConfig().i("overview", a[0], "title")}</MDTypography>
                                                                )}
                                                                {cysrConfig().i("overview", a[0], "sub_title") && (
                                                                    <MDTypography variant="button" color="text" fontWeight="regular">
                                                                        {cysrConfig().i("overview", a[0], "sub_title")}
                                                                    </MDTypography>
                                                                )}
                                                                {views.attack_perimeter_counters.assets_number_by_asset_type_and_cat && views.attack_perimeter_counters.assets_number_by_asset_type_and_cat[a[0]] ?
                                                                    // asset card with 250 px / 40 px image at bottom
                                                                    <Grid container>
                                                                        <Grid item xs={12}>
                                                                            <MDBox lineHeight={1} style={{
                                                                                display: "flex",
                                                                                // fix ruler smashed above s
                                                                                paddingBottom: "1dvw",
                                                                                // fix ruler smashed above e
                                                                                flexWrap: "wrap", gap: "8px", alignItems: "end", marginBottom: 8
                                                                            }}>
                                                                                <MDTypography variant="h5" fontWeight="bold">
                                                                                    {a[1]}
                                                                                </MDTypography>
                                                                                {false &&
                                                                                    <span style={{ fontSize: "12px" }}>
                                                                                        (
                                                                                        <MDTypography variant="button" fontWeight="bold" color={"info"}>
                                                                                            {"100%"}&nbsp;
                                                                                            <MDTypography
                                                                                                variant="button"
                                                                                                fontWeight="regular"
                                                                                                color={darkMode ? "text" : "secondary"}
                                                                                            >
                                                                                                {"somethiing here?"}
                                                                                            </MDTypography>
                                                                                        </MDTypography>
                                                                                        )
                                                                                    </span>
                                                                                }
                                                                                <img style={{
                                                                                    bottom: "0",
                                                                                    height: "auto",
                                                                                    left: 0,
                                                                                    position: "absolute",
                                                                                    right: 0,
                                                                                    width: "100%",
                                                                                    maxHeight: "30px",
                                                                                    objectPosition: "100% 100%",
                                                                                    objectFit: "auto"
                                                                                }}
                                                                                    // src="https://placehold.co/250x40"
                                                                                    src={`https://cysr.com/static/ruler/?red=${views.attack_perimeter_counters.assets_number_by_asset_type_and_cat[a[0]]["Bad"]}&orange=${views.attack_perimeter_counters.assets_number_by_asset_type_and_cat[a[0]]["Poor"]}&yellow=${views.attack_perimeter_counters.assets_number_by_asset_type_and_cat[a[0]]["Fair"]}&green=${views.attack_perimeter_counters.assets_number_by_asset_type_and_cat[a[0]]["Good"]}&blue=${views.attack_perimeter_counters.assets_number_by_asset_type_and_cat[a[0]]["Excellent"]}&size=${360}`} />
                                                                            </MDBox>
                                                                        </Grid>
                                                                    </Grid>
                                                                    :
                                                                    <Grid container>
                                                                        <Grid item xs={12}>

                                                                            <MDBox lineHeight={1}>
                                                                                <MDTypography variant="h5" fontWeight="bold">
                                                                                    {a[1]}
                                                                                </MDTypography>
                                                                            </MDBox>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            {false &&
                                                                                <MDTypography variant="button" fontWeight="bold" color={"info"}>
                                                                                    {"100%"}&nbsp;
                                                                                    <MDTypography
                                                                                        variant="button"
                                                                                        fontWeight="regular"
                                                                                        color={darkMode ? "text" : "secondary"}
                                                                                    >
                                                                                        {"somethiiing?"}
                                                                                    </MDTypography>
                                                                                </MDTypography>
                                                                            }
                                                                            {false && (
                                                                                <Grid item xs={12}>
                                                                                    <MDTypography variant="caption" fontWeight="bold" color="warning">
                                                                                        <Icon>warning</Icon>&nbsp;{"warning here?"} <ScoreItem scoreValue={0} textual={true} item_style={"mini"} /><span style={{ fontSize: 12 }}> assets</span>
                                                                                    </MDTypography>
                                                                                </Grid>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                            </MDBox>
                                                        </Card>

                                                    </Grid>)
                                                    : null
                                            }

                                        </Grid>

                                    </Grid>
                                    <Grid item container sm={12} lg={6} spacing={3}>
                                        {[
                                            {
                                                data: views.account_count && views.account_count.count_by_country
                                                    ? Object.entries(views.account_count.count_by_country).map((ac) => (
                                                        {
                                                            "id": ac[0],
                                                            "label": ac[0],
                                                            "value": ac[1],
                                                            "increment": 1.3,
                                                        })
                                                    )
                                                    : []
                                                ,
                                                title: "Countries",
                                                sub_title: "Aggregate by countries",
                                                type: "Country"
                                            },
                                            {
                                                data: views.account_count && views.account_count.count_by_size
                                                    ? Object.entries(views.account_count.count_by_size).map((ac) => (
                                                        {
                                                            "id": ac[0],
                                                            "label": ac[0],
                                                            "value": ac[1],
                                                            "increment": -1.3,
                                                        })
                                                    )
                                                    : []
                                                ,
                                                title: "Sizes",
                                                sub_title: "Aggregate by sizes",
                                                type: "Size"
                                            },
                                            {
                                                data: views.account_count && views.account_count.count_by_industry
                                                    ? Object.entries(views.account_count.count_by_industry).map((ac) => (
                                                        {
                                                            "id": ac[0],
                                                            "label": ac[0],
                                                            "value": ac[1],
                                                            "increment": -1.3,
                                                        })
                                                    )
                                                    : []
                                                ,
                                                title: "Industries",
                                                sub_title: "Aggregate by industries",
                                                type: "Industry"
                                            }
                                        ].map((t) => {
                                            return (
                                                <Grid item key={t.label} xs={12} // md={4}
                                                >
                                                    <Card>
                                                        <MDBox pt={3} pb={2} px={3} fullWidth>
                                                            <div className='flex gap-2 justify-between relative'>
                                                                <div>
                                                                    <MDTypography variant="h5">
                                                                        {t.title}
                                                                    </MDTypography>
                                                                    <MDTypography variant="button" color="text" fontWeight="regular">
                                                                        {t.sub_title}
                                                                    </MDTypography>
                                                                </div>
                                                                <Waffle className="absolute top-0 right-0" data={t.data} />
                                                            </div>
                                                            <div className='flex gap-2 mt-2' style={{
                                                                minHeight: 172
                                                            }}>

                                                                <div className='flex flex-col gap-2' style={{
                                                                    width: "calc(100% - 224px)"
                                                                }}>

                                                                    <InsightBarList labels={[t.type, "Count"]} data={(t.data).map((z, ind) => (

                                                                        {
                                                                            name: t.type === "Industry" ? getIndustry(z.label) : z.label,
                                                                            // value: valueFormatter(z.value),
                                                                            value: z.value,
                                                                            // href: 'muscope.com',

                                                                            /*
                                                                            }
                                                                            <img
                                                                                loading="lazy"
                                                                                width="20"
                                                                                // src={`https://flagcdn.com/${z.value.toLowerCase()}.svg`}
                                                                                src={`https://flagcdn.com/w20/${z.value.toLowerCase()}.png`}
                                                                                srcSet={`https://flagcdn.com/w40/${z.value.toLowerCase()}.png 2x`}
                                                                                alt=""
                                                                            />
                                                                            
                                                                            */

                                                                            icon: t.type === "Country"
                                                                                ? () => (
                                                                                    z.label.toUpperCase() !== "XX"
                                                                                        && z.label.toUpperCase() !== "OTHER" ?
                                                                                        <Flag
                                                                                            country={z.label}
                                                                                            className="mr-2"
                                                                                            // variant="circle" //Only works with rasterized images (jpg or png)
                                                                                            type="svg"
                                                                                            width="20"

                                                                                        // className="mr-2.5 fill-blue-500"
                                                                                        // viewBox="0 0 24 24"
                                                                                        // height="20"
                                                                                        /> : null
                                                                                )
                                                                                : null
                                                                        }

                                                                    ))} />
                                                                </div>
                                                            </div>
                                                        </MDBox>
                                                    </Card>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>

                                    {views.risks ? Object.entries(views.risks).map((r => {
                                        const rLabel = r[0];
                                        const rData = r[1];

                                        return rData === undefined
                                            || rData === null
                                            || rData.total === undefined
                                            || rData.total === null
                                            || rData.total.risk === undefined
                                            || rData.total.risk === null
                                            || rData.total.risk.min === 0 && rData.total.risk.avg === 0
                                            ? null
                                            : (
                                                <Grid item container xs={12} spacing={3}>
                                                    <Grid item xs={12} lg={12}>
                                                        <Card>
                                                            <MDBox pt={3} pb={2} px={3} fullWidth>
                                                                <MDBox mb={1} fullWidth>
                                                                    <MDBox>
                                                                        <MDTypography variant="h5">Annualized risk expectancy to <span className="font-medium text-lg uppercase" style={{
                                                                            fontSize: "inherit",
                                                                            color: getInsightRatingColor(rData.total.risk.avg)
                                                                        }}>
                                                                            {rLabel}
                                                                        </span></MDTypography>
                                                                        <MDTypography variant="button" color="text" fontWeight="regular">
                                                                            Risk distribution
                                                                        </MDTypography>
                                                                        {
                                                                            // <Grid item container xs={12} spacing={3}>
                                                                        }
                                                                        <Grid item xs={12} // lg={4}
                                                                        >

                                                                            <div>
                                                                                <AreaChart
                                                                                    className="mt-4 h-80"
                                                                                    style={{
                                                                                        transform: "translateX(-18px)"
                                                                                    }}
                                                                                    /*
                                                                                    data={
                                                                                        rData.total.risk_distribution
                                                                                        // getCurveData(rData.total.risk)
                                                                                    }
                                                                                    */
                                                                                    data={
                                                                                        getAggregatedCurvesData(
                                                                                            [
                                                                                                {
                                                                                                    label: rLabel,
                                                                                                    values: {
                                                                                                        risk_distribution: rData.total.risk_distribution
                                                                                                    }
                                                                                                }
                                                                                            ]
                                                                                        )
                                                                                    }
                                                                                    categories={[rLabel]}
                                                                                    curveType={"monotone"}
                                                                                    index="price"
                                                                                    showLegend={false}
                                                                                    colors={["teal"]}
                                                                                    // yAxisWidth={60}
                                                                                    valueFormatter={(x) => x.toFixed(0) + "%"}
                                                                                    // valueFormatter={(x) => ""}
                                                                                    intervalType="preserveStartEnd"
                                                                                    showGradient={false}
                                                                                />
                                                                                <Text className="mt-4">This curve represents the probability of experiencing a specific loss. The X-axis indicates the expected loss, while the Y-axis represents the probability of occurrence for that loss.</Text>
                                                                            </div>
                                                                            <Flex className="max-w-3xl my-4 flex-col sm:flex-row items-start">
                                                                                <div className="text-center">
                                                                                    <Text>Minimum</Text>
                                                                                    <Metric>
                                                                                        {valueFormatter(rData.total.risk.min)}
                                                                                    </Metric>
                                                                                </div>
                                                                                <div className="text-center">
                                                                                    <Text className='text-turquoise-500 dark:text-turquoise-400'>Expected</Text>
                                                                                    <Metric>
                                                                                        <span style={{ color: views && views.risk ? getInsightRatingColor(rData.total.risk.avg) : null }}>
                                                                                            {valueFormatter(rData.total.risk.avg)}
                                                                                        </span>
                                                                                    </Metric>
                                                                                </div>
                                                                                <div className="text-center">
                                                                                    <Text>Maximum</Text>
                                                                                    <Metric>
                                                                                        {valueFormatter(rData.total.risk.max)}
                                                                                    </Metric>
                                                                                </div>
                                                                            </Flex>
                                                                            <Text className="mt-4">This curve shows the probability that losses exceed certain values. The X-axis displays the expected loss, while the Y-axis displays the probabilities of exceeding these losses.</Text>

                                                                        </Grid>
                                                                        {
                                                                            /*
                                                                        }
                                                                        <Grid item xs={12} lg={8}>
                                                                            <Heatmap data={[
                                                                                {
                                                                                    "id": "Japan",
                                                                                    "data": [
                                                                                        {
                                                                                            "x": "Train",
                                                                                            "y": 55600
                                                                                        },
                                                                                        {
                                                                                            "x": "Subway",
                                                                                            "y": 27308
                                                                                        },
                                                                                        {
                                                                                            "x": "Bus",
                                                                                            "y": 53015
                                                                                        },
                                                                                        {
                                                                                            "x": "Car",
                                                                                            "y": -48964
                                                                                        },
                                                                                        {
                                                                                            "x": "Boat",
                                                                                            "y": 47496
                                                                                        },
                                                                                        {
                                                                                            "x": "Moto",
                                                                                            "y": -88561
                                                                                        },
                                                                                        {
                                                                                            "x": "Moped",
                                                                                            "y": -18193
                                                                                        },
                                                                                        {
                                                                                            "x": "Bicycle",
                                                                                            "y": 98627
                                                                                        },
                                                                                        {
                                                                                            "x": "Others",
                                                                                            "y": -91852
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "id": "France",
                                                                                    "data": [
                                                                                        {
                                                                                            "x": "Train",
                                                                                            "y": -36570
                                                                                        },
                                                                                        {
                                                                                            "x": "Subway",
                                                                                            "y": 77502
                                                                                        },
                                                                                        {
                                                                                            "x": "Bus",
                                                                                            "y": 28871
                                                                                        },
                                                                                        {
                                                                                            "x": "Car",
                                                                                            "y": 89091
                                                                                        },
                                                                                        {
                                                                                            "x": "Boat",
                                                                                            "y": -65790
                                                                                        },
                                                                                        {
                                                                                            "x": "Moto",
                                                                                            "y": -21477
                                                                                        },
                                                                                        {
                                                                                            "x": "Moped",
                                                                                            "y": -82255
                                                                                        },
                                                                                        {
                                                                                            "x": "Bicycle",
                                                                                            "y": -37553
                                                                                        },
                                                                                        {
                                                                                            "x": "Others",
                                                                                            "y": 27176
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "id": "US",
                                                                                    "data": [
                                                                                        {
                                                                                            "x": "Train",
                                                                                            "y": 79180
                                                                                        },
                                                                                        {
                                                                                            "x": "Subway",
                                                                                            "y": 28593
                                                                                        },
                                                                                        {
                                                                                            "x": "Bus",
                                                                                            "y": 21942
                                                                                        },
                                                                                        {
                                                                                            "x": "Car",
                                                                                            "y": 43071
                                                                                        },
                                                                                        {
                                                                                            "x": "Boat",
                                                                                            "y": 48261
                                                                                        },
                                                                                        {
                                                                                            "x": "Moto",
                                                                                            "y": 15453
                                                                                        },
                                                                                        {
                                                                                            "x": "Moped",
                                                                                            "y": 77383
                                                                                        },
                                                                                        {
                                                                                            "x": "Bicycle",
                                                                                            "y": 36527
                                                                                        },
                                                                                        {
                                                                                            "x": "Others",
                                                                                            "y": -53322
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "id": "Germany",
                                                                                    "data": [
                                                                                        {
                                                                                            "x": "Train",
                                                                                            "y": 11631
                                                                                        },
                                                                                        {
                                                                                            "x": "Subway",
                                                                                            "y": -80245
                                                                                        },
                                                                                        {
                                                                                            "x": "Bus",
                                                                                            "y": -29644
                                                                                        },
                                                                                        {
                                                                                            "x": "Car",
                                                                                            "y": 74070
                                                                                        },
                                                                                        {
                                                                                            "x": "Boat",
                                                                                            "y": -36916
                                                                                        },
                                                                                        {
                                                                                            "x": "Moto",
                                                                                            "y": -78329
                                                                                        },
                                                                                        {
                                                                                            "x": "Moped",
                                                                                            "y": -91478
                                                                                        },
                                                                                        {
                                                                                            "x": "Bicycle",
                                                                                            "y": 41230
                                                                                        },
                                                                                        {
                                                                                            "x": "Others",
                                                                                            "y": 25055
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "id": "Norway",
                                                                                    "data": [
                                                                                        {
                                                                                            "x": "Train",
                                                                                            "y": -71741
                                                                                        },
                                                                                        {
                                                                                            "x": "Subway",
                                                                                            "y": -74597
                                                                                        },
                                                                                        {
                                                                                            "x": "Bus",
                                                                                            "y": -67316
                                                                                        },
                                                                                        {
                                                                                            "x": "Car",
                                                                                            "y": -23518
                                                                                        },
                                                                                        {
                                                                                            "x": "Boat",
                                                                                            "y": -62307
                                                                                        },
                                                                                        {
                                                                                            "x": "Moto",
                                                                                            "y": 24945
                                                                                        },
                                                                                        {
                                                                                            "x": "Moped",
                                                                                            "y": -6312
                                                                                        },
                                                                                        {
                                                                                            "x": "Bicycle",
                                                                                            "y": -89630
                                                                                        },
                                                                                        {
                                                                                            "x": "Others",
                                                                                            "y": -43000
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "id": "Iceland",
                                                                                    "data": [
                                                                                        {
                                                                                            "x": "Train",
                                                                                            "y": 93421
                                                                                        },
                                                                                        {
                                                                                            "x": "Subway",
                                                                                            "y": -61340
                                                                                        },
                                                                                        {
                                                                                            "x": "Bus",
                                                                                            "y": 98352
                                                                                        },
                                                                                        {
                                                                                            "x": "Car",
                                                                                            "y": -55598
                                                                                        },
                                                                                        {
                                                                                            "x": "Boat",
                                                                                            "y": 67184
                                                                                        },
                                                                                        {
                                                                                            "x": "Moto",
                                                                                            "y": -91288
                                                                                        },
                                                                                        {
                                                                                            "x": "Moped",
                                                                                            "y": -65341
                                                                                        },
                                                                                        {
                                                                                            "x": "Bicycle",
                                                                                            "y": 9810
                                                                                        },
                                                                                        {
                                                                                            "x": "Others",
                                                                                            "y": 40777
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "id": "UK",
                                                                                    "data": [
                                                                                        {
                                                                                            "x": "Train",
                                                                                            "y": -63480
                                                                                        },
                                                                                        {
                                                                                            "x": "Subway",
                                                                                            "y": -53825
                                                                                        },
                                                                                        {
                                                                                            "x": "Bus",
                                                                                            "y": -81350
                                                                                        },
                                                                                        {
                                                                                            "x": "Car",
                                                                                            "y": 95009
                                                                                        },
                                                                                        {
                                                                                            "x": "Boat",
                                                                                            "y": 56964
                                                                                        },
                                                                                        {
                                                                                            "x": "Moto",
                                                                                            "y": -93084
                                                                                        },
                                                                                        {
                                                                                            "x": "Moped",
                                                                                            "y": -48698
                                                                                        },
                                                                                        {
                                                                                            "x": "Bicycle",
                                                                                            "y": 28433
                                                                                        },
                                                                                        {
                                                                                            "x": "Others",
                                                                                            "y": -49102
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "id": "Vietnam",
                                                                                    "data": [
                                                                                        {
                                                                                            "x": "Train",
                                                                                            "y": -82541
                                                                                        },
                                                                                        {
                                                                                            "x": "Subway",
                                                                                            "y": -64608
                                                                                        },
                                                                                        {
                                                                                            "x": "Bus",
                                                                                            "y": 8289
                                                                                        },
                                                                                        {
                                                                                            "x": "Car",
                                                                                            "y": -44942
                                                                                        },
                                                                                        {
                                                                                            "x": "Boat",
                                                                                            "y": 30622
                                                                                        },
                                                                                        {
                                                                                            "x": "Moto",
                                                                                            "y": 39118
                                                                                        },
                                                                                        {
                                                                                            "x": "Moped",
                                                                                            "y": 98929
                                                                                        },
                                                                                        {
                                                                                            "x": "Bicycle",
                                                                                            "y": 28994
                                                                                        },
                                                                                        {
                                                                                            "x": "Others",
                                                                                            "y": -8513
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]} />
                                                                            <Text className="max-w-3xl mt-4">This heatmap shows the probability that losses exceed certain values. The X-axis displays the expected loss, while the Y-axis displays the probabilities of exceeding these losses.</Text>
            
                                                                        </Grid>
                                                                    </Grid>
                                                                        {
                                                                            */
                                                                        }
                                                                    </MDBox>
                                                                </MDBox>
                                                            </MDBox>
                                                        </Card>
                                                    </Grid>

                                                    <Grid item container sm={12} lg={12} spacing={3}>
                                                        {[
                                                            {
                                                                data: rData.by_country
                                                                    ? Object.entries(rData.by_country).map((rB) => (
                                                                        {
                                                                            "id": rB[0],
                                                                            "label": rB[0],
                                                                            "values": rB[1],
                                                                            "increment": 1.3,
                                                                        })
                                                                    )
                                                                    : []
                                                                ,
                                                                title: "Countries risk",
                                                                sub_title: "Aggregate of risks by countries",
                                                                type: "Country"
                                                            },
                                                            {
                                                                data: rData.by_size
                                                                    ? Object.entries(rData.by_size).map((rB) => (
                                                                        {
                                                                            "id": rB[0],
                                                                            "label": rB[0],
                                                                            "values": rB[1],
                                                                            "increment": 1.3,
                                                                        })
                                                                    )
                                                                    : []
                                                                ,
                                                                title: "Sizes risk",
                                                                sub_title: "Aggregate of risks by sizes",
                                                                type: "Size"
                                                            },
                                                            {
                                                                data: rData.by_industry
                                                                    ? Object.entries(rData.by_industry).map((rB) => (
                                                                        {
                                                                            "id": rB[0],
                                                                            "label": rB[0],
                                                                            "values": rB[1],
                                                                            "increment": 1.3,
                                                                        })
                                                                    )
                                                                    : []
                                                                ,
                                                                title: "Industries risk",
                                                                sub_title: "Aggregate of risks by industries",
                                                                type: "Industry"
                                                            }
                                                        ].map((t) => {
                                                            return (
                                                                <Grid item key={t.label} xs={12} // md={4}
                                                                >
                                                                    <Card>
                                                                        <MDBox pt={3} pb={2} px={3} fullWidth>
                                                                            <div className='flex gap-2 justify-between relative'>
                                                                                <div>
                                                                                    <MDTypography variant="h5">
                                                                                        {t.title}
                                                                                    </MDTypography>
                                                                                    <MDTypography variant="button" color="text" fontWeight="regular">
                                                                                        {t.sub_title}
                                                                                    </MDTypography>
                                                                                </div>
                                                                                <Waffle className="absolute top-0 right-0" data={t.data} avgValProperty={"risk"} />
                                                                            </div>
                                                                            <div className='flex gap-2 mt-2' style={{
                                                                                minHeight: 172
                                                                            }}>

                                                                                <div className='flex gap-2 mt-2' style={{
                                                                                    minHeight: 144
                                                                                }}>
                                                                                    <div className='flex flex-col gap-2 mt-2' style={{
                                                                                        width: "200px"
                                                                                    }}>
                                                                                        <p className="mb-2 text-tremor-default flex items-center justify-between text-tremor-content dark:text-dark-tremor-content">
                                                                                            <span>Expected</span>
                                                                                            <span>Distribution</span>
                                                                                        </p>
                                                                                        {(t.data).sort((a, b) => b.values.risk.avg - a.values.risk.avg).map((z, ind) => (
                                                                                            <>
                                                                                                <div style={{
                                                                                                    padding: "2px"
                                                                                                }} className="flex flex-row items-center justify-between gap-2 w-full dark:bg-turquoise-950/80 bg-turquoise-50 rounded-lg">
                                                                                                    <div className='w-full flex gap-2 items-center'>
                                                                                                        <div className='ml-2 h-3 w-3 border' style={
                                                                                                            {
                                                                                                                background: z.color ? z.color : colorArray[ind].code
                                                                                                            }
                                                                                                        }></div>
                                                                                                        <div className="flex items-center gap-2">
                                                                                                            <p className="text-sm text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis font-medium">{z.label}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='w-full flex justify-end items-center'>
                                                                                                        <div className="flex items-center gap-2">
                                                                                                            <span className="font-medium text-base text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis">{valueFormatter(z.values.risk.avg)}</span>
                                                                                                            <SparkAreaChart

                                                                                                                data={
                                                                                                                    // getCurveData(z.values.risk, false)
                                                                                                                    z.values.risk_distribution
                                                                                                                }
                                                                                                                categories={["simulations"]}
                                                                                                                curveType={"monotone"}
                                                                                                                index="price"
                                                                                                                showLegend={false}
                                                                                                                colors={[z.color ? z.color : colorArray[ind].name]}
                                                                                                                yAxisWidth={60}
                                                                                                                // valueFormatter={(x) => x.toFixed(0) + "%"}
                                                                                                                intervalType="preserveStartEnd"
                                                                                                                showGradient={false}

                                                                                                                // index="date"
                                                                                                                // categories={['Performance', 'Benchmark']}
                                                                                                                // colors={['blue', 'cyan']}
                                                                                                                className="h-8 w-20"
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div></>)
                                                                                        )}</div>
                                                                                </div>

                                                                                <div className='flex flex-col gap-2' style={{
                                                                                    width: "calc(100% - 224px - 200px)"
                                                                                }}>

                                                                                    <InsightBarList labels={[t.type, "Count"]}
                                                                                        valueFormatter={valueFormatter} data={(t.data).map((z, ind) => (

                                                                                            {
                                                                                                name: t.type === "Industry" ? getIndustry(z.label) : z.label,
                                                                                                // value: valueFormatter(z.value),
                                                                                                value: z.values.risk.avg,
                                                                                                // href: 'muscope.com',
                                                                                                icon: t.type === "Country"
                                                                                                    ? () => (
                                                                                                        z.label.toUpperCase() !== "XX"
                                                                                                            && z.label.toUpperCase() !== "OTHER" ?
                                                                                                            <Flag
                                                                                                                country={z.label}
                                                                                                                className="mr-2"
                                                                                                                // variant="circle" //Only works with rasterized images (jpg or png)
                                                                                                                type="svg"
                                                                                                                width="20"

                                                                                                            // className="mr-2.5 fill-blue-500"
                                                                                                            // viewBox="0 0 24 24"
                                                                                                            // height="20"
                                                                                                            /> : null
                                                                                                    )
                                                                                                    : null
                                                                                            }

                                                                                        ))} />
                                                                                </div>
                                                                            </div>

                                                                            {
                                                                                <>
                                                                                    <div>
                                                                                        <AggregatedAreaChart data={t.data} />
                                                                                        <Text className="mt-4">This curve represents the probability of experiencing a specific loss. The X-axis indicates the expected loss, while the Y-axis represents the probability of occurrence for that loss.</Text>
                                                                                    </div>
                                                                                    {false && <>
                                                                                        <Flex className="max-w-3xl my-4 flex-col sm:flex-row items-start">
                                                                                            <div className="text-center">
                                                                                                <Text>Minimum</Text>
                                                                                                <Metric>
                                                                                                    {valueFormatter(z.values.risk.min)}
                                                                                                </Metric>
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <Text className='text-turquoise-500 dark:text-turquoise-400'>Expected</Text>
                                                                                                <Metric>
                                                                                                    <span style={{ color: views && views.risk ? getInsightRatingColor(z.values.risk.avg) : null }}>
                                                                                                        {valueFormatter(z.values.risk.avg)}
                                                                                                    </span>
                                                                                                </Metric>
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <Text>Maximum</Text>
                                                                                                <Metric>
                                                                                                    {valueFormatter(z.values.risk.max)}
                                                                                                </Metric>
                                                                                            </div>
                                                                                        </Flex>
                                                                                        <Text className="mt-4">This curve shows the probability that losses exceed certain values. The X-axis displays the expected loss, while the Y-axis displays the probabilities of exceeding these losses.</Text>
                                                                                    </>}
                                                                                </>
                                                                            }
                                                                        </MDBox>
                                                                    </Card>
                                                                </Grid>
                                                            )
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            )
                                    }

                                    )) : null}

                                    <Grid item xs={12}>
                                        <Card>
                                            <div style={{ position: "relative" }}>
                                                {isLoading ? (
                                                    <Loader />
                                                ) : (
                                                    <>
                                                        <MDBox pt={3} pb={2} px={3} fullWidth>
                                                            <CysrTableCompanies data={
                                                                {
                                                                    insight: true,
                                                                    search: params.get('s') ? params.get('s') : "",
                                                                    hideCompleted: params.get('hc') ? true : false,
                                                                    tableTagsOptions: [],
                                                                    nodes: views.accounts_role,
                                                                    tableTagsActive: views.cysr_tags_active,
                                                                }
                                                            }
                                                            />
                                                        </MDBox>
                                                        {
                                                            // <DataTable  entriesPerPage={{ defaultValue: 50, entries: [25, 50, 100, 200, 400] }} table={dashboardData(views)} canSearch />
                                                        }
                                                    </>
                                                )}
                                            </div>
                                        </Card >
                                    </Grid >
                                </>
                            )
                    }
                </Grid >
            </div>
        </DashboardLayout >
    );
}

export function OLDgetCurveData(x, format = true) {
    // step = (max - min)/100
    const divideByThis = 1
    var min = x.min / divideByThis,
        avg = x.avg / divideByThis,
        max = x.max / divideByThis;
    var a = (min + 4 * avg + max) / 6,
        b = (max - min) / 6;
    let data = [];
    for (var i = min; i <= max; i += (max - min) / 100) {
        data.push(
            /*
                {
                    "price": 26285,
                    "simulations": 16.59
                },
            */
            {
                simulations: //valueFormatter(
                    Math.pow(Math.E, -Math.pow((i - a) / b, 2)) /
                    (b * Math.sqrt(Math.PI * 2))
                //)
                ,
                // price: parseFloat(i).toFixed(1)//0.1
                price: format ? valueFormatter(i) : i
            }
        );
    }
    return data
}
function OLDgetCurvesData(x) {
    /*
    let arr = []
    x.forEach((z, ind) => {
        const cData = getCurveData(z.values.risk)[0]
        const newData = {
            price: cData.price
        }
        newData[z.label] = cData.simulations
        arr.push(newData)
    });
    
*/
    let resultMap = new Map();

    x.forEach((z, ind) => {
        const curveData = OLDgetCurveData(z.values.risk, false);
        curveData.forEach(cData => {
            const price = cData.price;
            if (!resultMap.has(price)) {
                resultMap.set(price, {});
            }
            if (!resultMap.get(price)[z.label]) {
                resultMap.get(price)[z.label] = cData.simulations;
            } else {
                resultMap.get(price)[z.label] += cData.simulations;
            }
        });
    });

    // Add missing prices with null values
    const allLabels = x.map(z => z.label);
    resultMap.forEach((simulations, price) => {
        allLabels.forEach(label => {
            if (!simulations[label]) {
                simulations[label] = null;
            }
        });
    });

    let arr = Array.from(resultMap.entries()).map(([price, simulations]) => {
        return { price: price, ...simulations };
    }).sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    // Sort the array by price from minimum to highest

    arr.forEach((x, i) => {
        arr[i].price = valueFormatter(arr[i].price)
    });
    return arr
}

function getAggregatedCurvesData(x) {
    let resultMap = new Map();

    x.forEach((z, ind) => {
        const curveData = z.values.risk_distribution;
        curveData.forEach(cData => {
            const price = cData.price;
            if (!resultMap.has(price)) {
                resultMap.set(price, {});
            }
            if (!resultMap.get(price)[z.label]) {
                resultMap.get(price)[z.label] = cData.simulations;
            } else {
                resultMap.get(price)[z.label] += cData.simulations;
            }
        });
    });

    // Add missing prices with null values
    const allLabels = x.map(z => z.label);
    resultMap.forEach((simulations, price) => {
        allLabels.forEach(label => {
            if (!simulations[label]) {
                simulations[label] = null;
            }
        });
    });

    let arr = Array.from(resultMap.entries()).map(([price, simulations]) => {
        return { price: price, ...simulations };
    }).sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    // Sort the array by price from minimum to highest

    arr.forEach((x, i) => {
        arr[i].price = valueFormatter(arr[i].price)
    });
    return arr
}

function AggregatedAreaChart(x) {
    const categories = x.data.map((z) => z.label)
    const data = getAggregatedCurvesData(x.data)
    // const data = getCurvesData(x.data)
    /*
    const chartdata = [
  {
    date: 'Jan 22',
    SemiAnalysis: 2890,
    'The Pragmatic Engineer': 2338,
  },
  {
    date: 'Feb 22',
    SemiAnalysis: 2756,
    'The Pragmatic Engineer': 2103,
  },

    */
    return <AreaChart
        className="mt-4 h-80"
        style={{
            transform: "translateX(-18px)"
        }}
        data={data}
        categories={categories}
        // "linear" | "natural" | "monotone" | "step"
        curveType={"monotone"}
        showLegend={true}
        connectNulls={true}
        minValue={0}
        index="price"
        colors={x.data.map((z, ind) => z.color ? z.color : colorArray[ind].name)}
        yAxisWidth={60}
        valueFormatter={(x) => x.toFixed(0) + "%"}
        // valueFormatter={(x) => ""}
        intervalType="preserveStartEnd"
        showGradient={false}
    />
}
export default Page;

/* eslint-disable */
// prettier-ignore
