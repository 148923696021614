/* eslint-disable */
// prettier-ignore
import { InfoSharp } from '@mui/icons-material';
import { Button, Dialog, DialogPanel, Icon } from '@tremor/react';
import { useState } from 'react';

export function CtrlInfoDialog({ darkMode, a, type, className = "",children = null }) {
  const [isOpen, setIsOpen] = useState(false);
  const shortDescription = type === "target" ? (a?.target_answer ? a?.target_answer : a?.target_control_answer) : a?.answer
  const description = type === "target" ? a?.target_answer_description : a?.answer_description;
  return (
    <div className="flex justify-center">
      {children ?
      <button type='button' className={className} onClick={() => setIsOpen(true)}>
        {children}
      </button>        
        :
        <button
          style={{ maxHeight: 20 }}
          type='button'
          className="rounded-tremor-small p-0 text-tremor-default font-medium"
          onClick={() => setIsOpen(true)}
        >
          <Icon
            icon={InfoSharp}
            className='p-0'
            variant="simple"
          // tooltip={`Press to read more info about ${shortDescription}`}
          />
        </button>
      }
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        static={true}
        className={`z-[999999] ${darkMode ? "dark" : "light"}`}
      >
        <DialogPanel className="max-w-lg">
          <div className='flex justify-between'>
            <p className='text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>{type === "target" ? "Target" : null} Maturity Level</p>
            <Button
              className="ml-auto my-0 py-1 flex text-black dark:text-white items-center"
              onClick={() => setIsOpen(false)}
            >
              Close
            </Button>
          </div>
          <h3 className="text-lg font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">{shortDescription}</h3>
          <p className="mt-2 leading-6 text-tremor-default text-tremor-content dark:text-dark-tremor-content whitespace-pre-line">
            {description}
          </p>
        </DialogPanel>
      </Dialog>
    </div>
  );
}