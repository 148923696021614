/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
// import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components

import { useLocation } from "react-router-dom";

import ChartSolar from "layouts/cysr/components/ChartSolar/solar";

import cysrConfig from "layouts/cysr/config";
import './App.css';

// Material Dashboard 2 React Examples
/* eslint-disable */
function ClusteredChart() {
  const location = useLocation();
  // Our State.
  const [views, setViews] = useState([]);
  const accountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/company/") + "/company/".length,
    window.location.pathname.lastIndexOf("/")
  );
  const dashboardCallUrl = `${cysrConfig().api_base_url}dashboard/mainpage/${accountUUID}/`;
  useEffect(() => {
    //setLoading(true);
    fetch(dashboardCallUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      },
    })
      .then((response) => response.json())
      .then((responseDashboardAccountData) => {
        setViews({
          general: responseDashboardAccountData.assets.graphs.general,
        });
        //setLoading(false);
      })
      .catch((err) => {
        console.error(err)
        //setLoading(false);
      });
    // very similar to handleDownloadPdf e
  }, [location]);
  return (
    <div style={{ background: "#ffffff", display: "block", textAlign: "center", position: "fixed", zIndex: "99999", left: "0", right: "0", top: "0", bottom: "0", padding: "0", overflow: "auto", height: "100%", width: "100%" }}>
      {views.general && views.general.nodes && views.general.nodes.length > 0 && (
        <ChartSolar full dataGraphRaw={views.general} darkMode={localStorage.getItem("cysr_dark_mode") != null ? localStorage.getItem("cysr_dark_mode") === "true" : true} />

      )}
    </div>
  );
}
/* eslint-disable */
export default ClusteredChart;
