/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/cysr/login/BasicLayout";
import { useSnackbar } from 'notistack';

import { Checkbox, FormControlLabel } from '@mui/material';

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import cysrConfig from "layouts/cysr/config";

function Basic() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // add action to an individual snackbar
  const action = snackbarId => (
    <>
      <MDButton onClick={() => { closeSnackbar(snackbarId) }} style={cysrConfig().button_style} size="medium">
        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().close.icon.svg }} />
        Dismiss
      </MDButton>
    </>
  );
  const [revocationEmail, setRevocationEmail] = useState("");
  const [areConfirmedTerms, setAreConfirmedTerms] = useState(false);
  const [revocationExistingUser, setRevocationExistingUser] = useState(false);
  const [revocationMessage, setRevocationMessage] = useState(<>

    <MDTypography variant="h4" fontWeight="medium" mb={2} align="center">
      Warning: You are about to revoke the authorization for the processing of personal data
    </MDTypography>
    <MDTypography variant="body2" mb={2}>If you revoke this authorization, the data will be removed from the platform, and only a subset of the data will be visible, with passwords and most of the email addresses redacted (Note: names before the @ in email addresses will be replaced with a series of asterisks). To allow us to manage and display cybersecurity threat data again, you will need to proceed with the authorization process by the DPO.</MDTypography>
  </>);
  const isDPO = window.location.href.indexOf("dpo") > - 1;
  const revocationId = window.location.href.split('/revocation#')[1];
  console.warn("revocationId")
  console.warn(revocationId)
  const [readyToRegister, setReadyToRegister] = useState(true);

  const revocationRequest = () => {


    const joinUrl = `${cysrConfig().api_base_url}${isDPO ? "dpo" : "cti"}/revoke/${revocationId}/`;

    fetch(joinUrl, {
      /*
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      },
      */
      method: "DELETE"
    })
      .then((response) => {
        console.warn("rresp")
        console.warn(response)
        return response.json()
      })
      .then((responseData) => {
        console.log("responseData")
        console.log(responseData)
        if (responseData.invited) {
          setRevocationEmail(responseData.invited)
        }
        setRevocationExistingUser(false)
        if (responseData.message && responseData.message === "Unable to add. Non existent user") {
          setRevocationMessage(
            <>
              <MDTypography variant="body2" mb={2}>
                To accept your revocation, you must first register on the {platformName} platform.
                Please read the <a href="/terms-and-conditions/" target="_blank">terms of service</a> and fill in the following form:
              </MDTypography>
            </>
          )
          setReadyToRegister(true)
        } else if (responseData.ErrorDetail || responseData.ErrorMessage) {
          setRevocationMessage(
            <>
              <MDTypography mb={2} variant="body2">{responseData.ErrorMessage}</MDTypography>
              <MDTypography variant="body2" color="warning" mb={2}>{responseData.ErrorDetail}</MDTypography>
            </>
          )
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column" }}>
              <MDTypography variant="caption" fontWeight="bold">{responseData.ErrorMessage}</MDTypography>
              <MDTypography variant="caption">{responseData.ErrorDetail}</MDTypography>
            </div>, {
            action,
            variant: 'warning',
            persist: true
          })
        } else {
          setRevocationMessage(
            <>
              <MDTypography variant="h4" fontWeight="medium" mb={2} align="center">
                Authorization Revoked
              </MDTypography>
              <MDTypography variant="body2" mb={2}>You have successfully revoked the authorization for the processing of personal data. To regain access to the full cybersecurity threat data, please initiate a new authorization process through the DPO.</MDTypography>
              <MDTypography variant="body2" mb={2}>You may now log in using your credentials</MDTypography>
            </>
          )
          setRevocationExistingUser(true)
        }
      })
      .catch((err) => {
        let snackErrorTitle = "Revocation error";
        let snackErrorDetail = "Invite could not be accepted at this time";
        if (err && err.ErrorMessage) {
          snackErrorTitle = err.ErrorMessage;
          snackErrorDetail = err.ErrorDetail;
        } else if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
          snackErrorTitle = JSON.parse(err).ErrorMessage;
          snackErrorDetail = JSON.parse(err).ErrorDetail;
        } else if (JSON.parse(err) && JSON.parse(err).error) {
          // snackErrorTitle = "Error";
          snackErrorDetail = JSON.parse(err).error;
        }
        enqueueSnackbar(
          <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <MDTypography variant="caption" style={{ fontWeight: "600" }}>{snackErrorTitle}</MDTypography>
            <MDTypography variant="caption">{snackErrorDetail}</MDTypography>
          </div>, {
          action,
          variant: 'error',
          persist: true
        })
        console.log("err...");
        console.log(err);
      });
    /*
    const dataLogin = {
      email: revocationEmail,
      password: document.getElementById("cysr_pass").value,
    };
    console.log(dataLogin)
    fetch(revocationRegisterUrl, {
      body: JSON.stringify(dataLogin),
      headers: {
        "content-type": "application/json",
      },
      method: "POST",
    })
      .then((response) => {
        if (response.ok) {
          // window.location.reload();
          return response.json();
        } else {
          console.error(response);
          return response.json().then(err => {
            throw JSON.stringify(err)
          })
        }
      })
      .then((loginData) => {
        console.warn(loginData)
        setRevocationMessage(
          <>
            <MDTypography variant="body2" mb={2}>You succesfully signed up</MDTypography>
            <MDTypography variant="body2" mb={2}>Access the platform using your credentials</MDTypography>
          </>
        )
        setRevocationExistingUser(true)
      })
      .catch((err) => {
        console.log(err);
        console.log("err3:", err);
        if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
          // toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <MDTypography variant="caption" fontWeight="bold">{JSON.parse(err).ErrorMessage}</MDTypography>
              <MDTypography variant="caption">
                <div
                  dangerouslySetInnerHTML={{ __html: JSON.parse(err).ErrorDetail }}
                ></div>
              </MDTypography>
            </div>, {
            action,
            variant: 'error',
            persist: true
          })
        } else if (JSON.parse(err) && JSON.parse(err).error) {
          // toggleSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <MDTypography variant="caption" fontWeight="bold">Error</MDTypography>
              <MDTypography variant="caption">{JSON.parse(err).error}</MDTypography>
            </div>, {
            action,
            variant: 'error',
            persist: true
          })
        } else {
          // toggleSnackbar("Error", "Some error happened", "error", "error", "Error");
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <MDTypography variant="caption" fontWeight="bold">Error</MDTypography>
              <MDTypography variant="caption">Some error happened</MDTypography>
            </div>, {
            action,
            variant: 'error',
            persist: true
          })
        }
      })
      */

  };


  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox pt={2} pb={3} px={3}>
          <>
            <MDTypography variant="h4" fontWeight="medium" align="center">
              {platformName}
            </MDTypography>
            {revocationMessage}
            <MDBox component="form" role="form" autoComplete="off">
              <MDBox mb={1}>
                {revocationExistingUser ?
                  <MDButton fullWidth onClick={() => { window.location.href = "/cysr/login" }} style={cysrConfig().button_style} size="medium">
                    <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().login.icon.svg }} />
                    Go to Login
                  </MDButton>
                  :
                  <>
                    {readyToRegister ?
                      <>
                        <MDBox mb={1}>
                          <FormControlLabel
                            control={
                              <Checkbox defaultChecked={areConfirmedTerms} onChange={() => setAreConfirmedTerms(!areConfirmedTerms)} />
                            }
                            // label="I have read and accept the terms of service"
                            label={<span>I have read and agree</span>}
                          />
                        </MDBox>
                        {areConfirmedTerms ? <MDButton fullWidth onClick={() => revocationRequest()} style={cysrConfig().button_style} size="medium">
                          <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().login.icon.svg }} />
                          Revoke authorization
                        </MDButton> :
                          <MDButton fullWidth style={cysrConfig().button_style} size="medium" disabled>
                            <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().login.icon.svg }} />
                            Revoke authorization
                          </MDButton>
                        }
                      </>
                      :
                      <MDButton fullWidth onClick={() => { window.location.href = "/cysr/login" }} style={cysrConfig().button_style} size="medium">
                        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().login.icon.svg }} />
                        Go to Login
                      </MDButton>
                    }
                  </>
                }
              </MDBox>
            </MDBox>
          </>

        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
/* eslint-disable */