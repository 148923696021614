/* eslint-disable */
// prettier-ignore
"use client";

import * as React from "react";
import { format, startOfToday, sub } from "date-fns";

import { cn } from "lib/utils"
import { Button } from "layouts/cysr/components/ui/button";
import { Calendar } from "layouts/cysr/components/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "layouts/cysr/components/ui/popover";
import { DateFormatter } from "react-day-picker";
import { process, getColor } from "layouts/cysr/config";

export function TimeshiftCalendarDatePicker({
    timeshiftData = [],
    setTimeshift,
    callback = null,
}) {
    const calendarNumberOfMonths = 3;
    const [date, setDate] = React.useState(startOfToday);

    const selectTimeshift = function (selectedDate) {
        const formattedSelectedDate = selectedDate ? format(
            selectedDate,
            process.env.NEXT_PUBLIC_DATE_FORMAT_TIMESHIFT
        ) : null;
        if (timeshiftData.find((td) => td.report_date === formattedSelectedDate)) {
            let timeshift = timeshiftData.find((td) => td.report_date === formattedSelectedDate)
            // imposta data selezionata al calendario:
            setDate(selectedDate);
            // imposta timeshift con i valori:
            setTimeshift(timeshift);
            if (callback) {
                callback(timeshift);
            }
            // alert(`date ${formattedSelectedDate} IS available for timeshift`);
        } else if (
            selectedDate &&
            format(selectedDate, process.env.NEXT_PUBLIC_DATE_FORMAT_TIMESHIFT) ===
            format(startOfToday(), process.env.NEXT_PUBLIC_DATE_FORMAT_TIMESHIFT)
        ) {
            // imposta data selezionata al calendario:
            setDate(selectedDate);
            // imposta timeshift a null, torna a oggi:
            setTimeshift(null);
            if (callback) {
                callback(null);
            }
        } else {
            // nope s
            // !!maybe add here to do forin e seleziona il timeshift prima di questa data
            // TODO
            // alert(`date ${formattedSelectedDate} not available for timeshift`);
            // nope e
            // in questo caso non si fa nulla e non si può nemmeno impostare la data selezionata al calendario
        }
    };

    // https://react-day-picker.js.org/guides/formatters
    // formatDay	DateFormatter	Format the day in the day cell.
    const formatDay
        // : DateFormatter 
        = (d, options) => {
            let itemFound = false;
            timeshiftData.forEach(element => {
                if (element.report_date === format(d, process.env.NEXT_PUBLIC_DATE_FORMAT_TIMESHIFT)) {
                    itemFound = element;
                }
            });
            if (itemFound === false) {
                // check if same day of today to not reduce opacity
                const isItemToday =
                    format(d, process.env.NEXT_PUBLIC_DATE_FORMAT_TIMESHIFT) ===
                    format(startOfToday(), process.env.NEXT_PUBLIC_DATE_FORMAT_TIMESHIFT);
                return (
                    <span
                        aria-label={d.toDateString()}
                        className={cn(
                            "pb-1 h-[34px] w-[34px] items-center inline-flex justify-center",
                            isItemToday
                                ? (
                                    date.toDateString() === d.toDateString()
                                        ? "ring-2 rounded-md font-bold relative ring-black dark:ring-white"
                                        : "font-normal"
                                )
                                : "opacity-20 cursor-not-allowed")}
                    >
                        {format(d, process.env.NEXT_PUBLIC_DATE_FORMAT_TIMESHIFT_DAY)}
                        {date.toDateString() === d.toDateString() ? <svg className="absolute bottom-1 left-0 right-0 w-1.5 h-1.5 mx-auto" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CircleSharpIcon" fill="currentColor"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"></path></svg> : null}
                    </span>
                );
            } else {
                return (
                    <>
                        <span
                            className={cn(
                                "pb-1 ring-2 h-[34px] w-[34px] items-center inline-flex justify-center rounded-md text-white",
                                date.toDateString() === d.toDateString()
                                    ? "font-bold ring-black dark:ring-white hover:ring-black/75 dark:hover:ring-white/75 relative"
                                    : "font-normal ring-black/25 hover:ring-black/80 dark:ring-white/25 hover:dark:ring-white/80"
                            )}
                            style={{
                                background: getColor(itemFound.rating_val),
                                // color: getColor(itemFound.companies[0].rating),
                            }}
                            aria-label={d.toDateString()}
                        >
                            {format(d, process.env.NEXT_PUBLIC_DATE_FORMAT_TIMESHIFT_DAY)}
                            {date.toDateString() === d.toDateString() ? <svg className="absolute bottom-1 left-0 right-0 w-1.5 h-1.5 mx-auto" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CircleSharpIcon" fill="currentColor"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"></path></svg> : null}
                        </span>
                    </>
                );
            }
        };

    {
        /*
    }
    <Popover>
        <PopoverTrigger asChild>
            <Button
                variant={"outline"}
                className={cn(
                    "w-[240px] flex items-center justify-start text-left font-normal",
                    `${format(date, process.env.NEXT_PUBLIC_DATE_FORMAT_TIMESHIFT) !==
                        format(
                            startOfToday(),
                            process.env.NEXT_PUBLIC_DATE_FORMAT_TIMESHIFT
                        )
                        ? "focus-visible:ring-teal-400  dark:ring-offset-teal-950 dark:focus-visible:ring-teal-800 border-teal-200 bg-teal-50 hover:bg-teal-100 hover:text-teal-900 dark:border-teal-800 dark:bg-teal-950 dark:hover:bg-teal-800 dark:hover:text-teal-50"
                        : null
                    }`,
                    !date && "text-muted-foreground"
                )}
            >
                <svg
                    className="mr-2 h-4 w-4"
                    width="16"
                    height="16"
                    fill="currentColor"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="CalendarMonthSharpIcon"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM9 14H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm-8 4H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z"></path>
                </svg>
                {date ? format(date, "PPP") : <span>Pick a date</span>}
            </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
        */
    }
    return (
        <Calendar
            mode="single"
            selected={date}
            formatters={{ formatDay }}
            fixedWeeks
            // onSelect={setDate}
            onSelect={(selectedDate) => {
                selectTimeshift(selectedDate);
            }}
            initialFocus
            // show quarters
            numberOfMonths={calendarNumberOfMonths}
            pagedNavigation={true}
            // display previous numberOfMonths / 2

            // blocks navigation:
            // month={sub(startOfToday(), { months: calendarNumberOfMonths > 1 ? calendarNumberOfMonths - 1 : calendarNumberOfMonths })}
            
            showOutsideDays={false}
            // toMonth={new Date(2018, 11)}
            toMonth={startOfToday()}
        />
    );
    {
        /*
    }
    </PopoverContent>
    </Popover>
    {
    */
    }
}
