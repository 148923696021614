/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
// prettier-ignore
import { Base64 } from 'js-base64';
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
import InfoButton from "layouts/cysr/components/infoButton";
// import Footer from "examples/Footer";
import DashboardCompanyInfo from "layouts/cysr/components/DashboardCompanyInfo";
import Loader from "layouts/cysr/components/Loader";
import Autocomplete from "@mui/material/Autocomplete";

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";

import Collapse from "@mui/material/Collapse";

// import KeyboardArrowDownIcon from '@mui/icons-material/Add';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Card from "@mui/material/Card";
import CysrTableNotifications from "layouts/cysr/components/CysrTable/notifications";

import Select, { components } from 'react-select';

import { useMaterialUIController } from "context";
// import DataTable from "layouts/cysr/components/DataTable";
// import dashboardData from "layouts/cysr/dashboard/dashboardData";

// import { LineChart } from "@carbon/charts-react";
// import "@carbon/charts/styles-g90.css";


import cysrConfig from "layouts/cysr/config";
import { Checkbox, FormControlLabel } from '@mui/material';

import RenderMarkdown from "layouts/cysr/components/RenderMarkdown"
import './style.css';

import { add, format, formatDistanceToNow, subDays } from 'date-fns';

function Sales() {
  if (localStorage.getItem("cysr_useraccountrole_selected")) {
    localStorage.removeItem("cysr_useraccountrole_selected")
    window.location.reload()
  }

  const [notificationsDaysRange, setNotificationsDaysRange] = useState(0);
  const [notificationsShowArchived, setNotificationsShowArchived] = useState(false);

  const [ids, setIds] = useState([]);
  const actionsPixelWidth = 80;
  const handleExpand = (item) => {
    if (ids.includes(item.timestamp)) {
      setIds(ids.filter((id) => id !== item.timestamp));
    } else {
      setIds(ids.concat(item.timestamp));
      // set read as true (!false) on view expand
      if (item.read === false) {
        toggleRead(item.timestamp, item.read)
      }
    }
  };




  function filterByUnreadToggle() {
    const actualShowUnread = showUnread;
    const viewAll = actualShowUnread;
    let newNodes = [];
    setShowUnread(!actualShowUnread);
    if (viewAll) {
      fetch(`${cysrConfig().api_base_url}notification/myfeed/?archived=${notificationsShowArchived}&start_date=${format(subDays(new Date(), notificationsDaysRange ? notificationsDaysRange : 0), "yyyy-MM-dd'T'hh:mm:ss.SSS")}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
          "content-type": "application/json",
        },
        method: "GET",
      })
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((responseData) => {
                setTData({
                  nodes: responseData.events.filter(filterNotifications(tableTagsActive, COLUMNS, notificationsShowArchived, !actualShowUnread))
                });
              })
          } else {
            return response.json().then(err => {
              // restore previous
              setShowUnread(actualShowUnread);
              throw JSON.stringify(err)
            })
          }
        })
        .catch((err) => {
          // restore previous
          setShowUnread(actualShowUnread);
          console.error("enabled err:", err);
          console.error(err);
        });
    } else {
      tData && tData.nodes && tData.nodes.forEach(nd => {
        let newNode = nd;
        if (nd.read === false) {
          newNodes.push(newNode);
        }
      });
      setTData({ nodes: newNodes.filter(filterNotifications(tableTagsActive, COLUMNS)) });
    }
  };
  let COLUMNS = [
    {
      label: 'Severity',
      renderCell: (item) =>
        <MDBox
          fullWidth
          color={
            item.severity
              ?
              cysrConfig().severityWordArray.filter((s) => s.value == item.severity)
                ? cysrConfig().severityWordArray.filter((s) => s.value == item.severity)[0].color
                : "text"
              : "text"
          }
          style={{ fontWeight: item.read ? "400" : "600", textAlign: "left" }}
        >{item.severity ? cysrConfig().severityWordArray.filter((s) => s.value == item.severity)[0].label : "N/A"}
        </MDBox>
      ,
      sort: { sortKey: 'SEVERITY' },
      resize: true,
      // cysrTag: "severity"
    },
    {
      label: 'Timestamp',
      renderCell: (item) => <span style={
        {
          letterSpacing: item.read ? "normal" : ".08em",
          fontWeight: item.read ? "400" : "600"
        }
      }>
        {
          item.timestamp ? formatDistanceToNow(new Date(item.timestamp.indexOf("Z") > -1 ? item.timestamp : item.timestamp + "Z"), {
            addSuffix: true
          }) : ""
        }
      </span>
      ,
      sort: { sortKey: 'LAST_CHECK' },
      resize: true,
      cysrTag: "timestamp",
      cysrTagDateFormat: 'it-IT'
    },
    {
      label: 'Title',
      renderCell: (item) => <span style={
        {
          fontWeight: item.read ? "400" : "600",
          letterSpacing: item.read ? "normal" : ".08em"
        }
      }>
        <RenderMarkdown>
          {item.title}
        </RenderMarkdown>
      </span>,
      sort: { sortKey: 'TITLE' },
      resize: true,
      cysrTag: "title"
    },
    {
      label: 'Actions',
      renderCell: (item) => (
        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '8px', width: `${actionsPixelWidth}px` }}>

          <>
            {false &&
              <MDButton onClick={() => {
                // if available set the current account uuuid as selected
                if (item.timestamp) {
                  localStorage.setItem("cysr_useraccountrole_selected", item.timestamp)
                  window.location.href = `/company/${item.timestamp}/overview`
                }
                // if available set the current account uuuid as selected e
              }} style={cysrConfig().button_style} size="medium">
                Go to company overview
                <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
              </MDButton>
            }
            <MDButton onClick={() => handleExpand(item)} style={cysrConfig().button_style} size="medium" sx={{
              opacity: item.read ? "0.85" : "1"
            }}>
              {ids && ids.includes(item.timestamp) ? <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} /> : <span style={cysrConfig().button_icon_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />}
            </MDButton>
          </>
        </div>
      ),
      pinRight: true
      // sort: { sortKey: 'SEVERITY' }
    },
  ];
  // let tData = { nodes: data.nodes };
  const [tData, setTData] = useState(
    {
      nodes: []
    }
  );


  const toggleArchive = (timestamp, archived) => {
    // da:
    // 2023-05-16T14:38:40.521
    // a:
    // 20230516-143840-521
    const uidTimestamp = timestamp;
    timestamp = timestamp.split(":").join("");
    timestamp = timestamp.split("-").join("");
    timestamp = timestamp.split("T").join("-");
    timestamp = timestamp.split(".").join("-");
    const toggleArchiveUrl = `${cysrConfig().api_base_url}notification/myfeed/${timestamp}/`;
    fetch(toggleArchiveUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      body: JSON.stringify(
        {
          archive: !archived
        }
      ),
      method: "PATCH",
    })
      .then((response) => {
        if (response.ok) {
          // window.location.reload();
          let newNodes = [];
          tData && tData.nodes && tData.nodes.forEach(nd => {
            let newNode = nd;
            if (nd.timestamp === uidTimestamp) {
              newNode.archived = !archived;
            }
            newNodes.push(newNode);
          });
          setTData({ nodes: newNodes.filter(filterNotifications(tableTagsActive, COLUMNS)) })
          return response.json();
        }
        throw new Error(response);
      })
      .then((toggleArchiveData) => {
        // no-console-ignore
        console.log(toggleArchiveData);
      })
      .catch((err) => {
        // no-console-ignore
        console.log(err);
      });
  };

  const toggleRead = (timestamp, read, withoutUpdate) => {
    // da:
    // 2023-05-16T14:38:40.521
    // a:
    // 20230516-143840-521
    const uidTimestamp = timestamp;
    timestamp = timestamp.split(":").join("");
    timestamp = timestamp.split("-").join("");
    timestamp = timestamp.split("T").join("-");
    timestamp = timestamp.split(".").join("-");
    const toggleReadUrl = `${cysrConfig().api_base_url}notification/myfeed/${timestamp}/`;
    fetch(toggleReadUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      body: JSON.stringify(
        {
          read: !read
        }
      ),
      method: "PATCH",
    })
      .then((response) => {
        if (response.ok) {
          // window.location.reload();
          if (withoutUpdate && withoutUpdate === true) {
            return response.json()
          }
          let newNodes = [];
          tData && tData.nodes && tData.nodes.forEach(nd => {
            let newNode = nd;
            if (nd.timestamp === uidTimestamp) {
              newNode.read = !read;
            }
            newNodes.push(newNode);
          });
          setTData({ nodes: newNodes.filter(filterNotifications(tableTagsActive, COLUMNS)) })
          return response.json();
        }
        throw new Error(response);
      })
      .then((toggleReadData) => {
        // no-console-ignore
        console.log(toggleReadData);
      })
      .catch((err) => {
        // no-console-ignore
        console.log(err);
      });
  };
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  // filter test s
  const params = new URLSearchParams(window.location.search);
  let activeTags = [];
  try {
    activeTags = params.get('t') && (decodeURI(params.get('t'))) && Base64.decode(decodeURI(params.get('t'))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('t')))) : [];
  } catch (error) {
    console.log("Error in setting active tags:")
    console.log(error)
  }
  const [tableTagsActive, setTableTagsActive] = useState(activeTags);
  const [showUnread, setShowUnread] = useState(false);


  function filterNotifications(tableTagsActive, COLUMNS, isArchived = notificationsShowArchived, showingUnread = showUnread) {
    return (item) => {
      if (showingUnread && showingUnread === true && item.read) {
        return false;
      }
      // check also here if archived is different than the visibile or not s
      if (isArchived === undefined || isArchived === notificationsShowArchived) {
        if (item.archived != notificationsShowArchived) {
          return false;
        }
      }
      // check also here if archived is visibile or not e
      /*
      // check filter first
      if(hideCompleted && item.isComplete){
        return false
      }
      */
      // check tags
      // da fare tags con chiave valore
      if (tableTagsActive && tableTagsActive.length > 0) {
        let trueCount = tableTagsActive.length;
        tableTagsActive.forEach(ta => {
          let notFound = true;
          // X:item.cysrTag s
          if (ta && typeof ta === 'string' && ta.includes(":")) {
            const tag = ta.toLowerCase().split(":");
            const tagKey = tag[0].toLowerCase();
            const tagValue = tag[1].toString().trim();
            //tag: s
            if (tagKey === "tag" && item.tags && item.tags.indexOf(tagValue) > -1) {
              if (notFound) {
                trueCount--;
                notFound = false;
              }
            }
            //tag: e
            COLUMNS.forEach(col => {
              if (Array.isArray(col.cysrTag) === false) {
                if (col.cysrTag && item[col.cysrTag] && col.label.toLowerCase() === tagKey && item[col.cysrTag.toLowerCase()] && item[col.cysrTag.toLowerCase()]) {
                  // check cysrTagDateFormat
                  if (col.cysrTagDateFormat) {
                    const vd = new Date(item[col.cysrTag.toLowerCase()].toString()).toLocaleDateString(col.cysrTagDateFormat, {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    });
                    if (vd.toString().includes(tagValue) && notFound) {
                      trueCount--;
                      notFound = false;
                    }
                  } else if (item[col.cysrTag.toLowerCase()].toString().toLowerCase().includes(tagValue)) {
                    if (notFound) {
                      trueCount--;
                      notFound = false;
                    }
                  }
                }
              } else {
                col.cysrTag.forEach(cT => {
                  if (cT && item[cT] && col.label.toLowerCase() === tagKey && item[cT.toLowerCase()]) {
                    // check cysrTagDateFormat
                    if (col.cysrTagDateFormat) {
                      const vd = new Date(item[cT.toLowerCase()].toString()).toLocaleDateString(col.cysrTagDateFormat, {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      });
                      if (vd.toString().includes(tagValue) && notFound) {
                        trueCount--;
                        notFound = false;
                      }
                    } else if (item[cT.toLowerCase()].toString().toLowerCase().includes(tagValue)) {
                      if (notFound) {
                        trueCount--;
                        notFound = false;
                      }
                    } else if (
                      // item.account_vat_country_code === "XX" ? "other"
                      cT === "account_vat_country_code"
                      && item[cT.toLowerCase()].toString().toLowerCase() === "xx"
                      && "other".includes(tagValue)) {
                      if (notFound) {
                        trueCount--;
                        notFound = false;
                      }
                    } else if (
                      // item.account_vat_country_code === "XX" ? "other"
                      cT === "account_data_national_id"
                      && item[cT.toLowerCase()].toString().toLowerCase().includes(tagValue.toLowerCase().replace(item.account_vat_country_code.toLowerCase(), ""))) {
                      if (notFound) {
                        trueCount--;
                        notFound = false;
                      }
                    }
                  }
                });
              }
            });
          }
          // X:item.cysrTag e
          // compare (for freeSolo) s
          [
            // "severity",
            "topic_descr",
            // "archived",
            "source",
            "summary",
            "timestamp",
            "title",
            "topic",
          ].forEach(property => {
            if (ta && ta[property]) {
              // a default option (with [property])
              if (ta && ta[property] && item[property] && item[property].toLowerCase().includes(ta[property].toLowerCase())) {
                if (notFound) {
                  trueCount--;
                  notFound = false;
                }
              }
            } else {
              // not a default option (with [property])
              if (ta && item[property] && item[property].toLowerCase().includes(ta.toLowerCase())) {
                if (notFound) {
                  trueCount--;
                  notFound = false;
                }
              } else if (
                // item.account_vat_country_code === "XX" ? "other"
                property === "account_vat_country_code" && ta && item[property] && item[property].toLowerCase() && item[property].toLowerCase() === "xx" && "other".includes(ta.toLowerCase())) {
                if (notFound) {
                  trueCount--;
                  notFound = false;
                }
              }
            }
          });
          // compare (for freeSolo) e
        });
        if (trueCount !== 0) {
          return false;
        }
      }
      // check search at last
      // ..
      return true;
    };
  }
  // filter test e

  // Our State.
  const [isLoading, setLoading] = useState(true);
  const [views, setViews] = useState([]);
  const [createOpen, setOpen] = useState(false);
  // company creation states
  const [companyVatCode, setCompanyVatCode] = useState("IT");
  const [companyVat, setCompanyVat] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyMainAsset, setCompanyMainAsset] = useState("");
  const [companyCreationLoading, setCompanyCreationLoading] = useState(false);

  const [selectStatusLoading, setSelectStatusLoading] = useState(false);

  // Tutti gli asset di un account (/asset/) senza UUID?
  if (localStorage.getItem("cysr_useraccountroles") === null) {
    window.location.href = "/cysr/login";
  }
  const [show, setShow] = useState(false);

  const [errorTitle, setErrorTitle] = useState("Company not created");
  const [errorMsg, setErrorMsg] = useState("Check that all the fields are compiled correctly and try again");

  const defaultToggleSnackbar = () => {
    setShow(!show);
  }
  const toggleSnackbar = (title = errorTitle, msg = errorMsg) => {
    setErrorTitle(title);
    setErrorMsg(msg);
    setShow(true);
  }
  // add company creation load
  const btnCreateCompanyDefaultString = "Create company";
  const [btnCreateCompanyString, setStringBtnCreateCompany] = useState(btnCreateCompanyDefaultString);

  // custom select
  const colourStyles = {
    placeholder: (styles) => ({
      ...styles,
      fontSize: '16px',
      color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white"
    }),
    multiValueRemove: (base) => ({
      ...base,
      backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#f8f9fa" : "#23272E",
      borderRadius: 0
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
      backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#f8f9fa" : "#23272E",
      height: "18px",
      minHeight: "18px",
      lineHeight: "1",
      fontSize: '12px',
      borderRadius: 0
    }),
    valueContainer: (base) => ({
      ...base,
      width: '100%',
      minWidth: '160px',
      display: "flex",
    }),
    indicatorsContainer: (styles) => ({ ...styles, height: "100%" }),
    indicatorSeparator: (styles) => ({ ...styles, border: "0", backgroundColor: "transparent" }),
    control: (styles, { isDisabled }) => ({
      ...styles, border: "0", color: isDisabled
        ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#bdc7d0" : "#666666") : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: isDisabled
          ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#EEF0F1" : "#17191c") : (localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b"), height: "32px", minHeight: "32px", minWidth: "160px"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? undefined
              : undefined,
        color: isDisabled
          ? '#ccc'
          : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
            && data.color
            : undefined,
        },
      };
    },
    menuList: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b" }),
    input: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };

  const loadingStyles = {
    valueContainer: (base) => ({
      ...base,
      width: '100%',
      display: "flex",
    }),
    indicatorsContainer: (styles) => ({ ...styles, height: "100%" }),
    indicatorSeparator: (styles) => ({ ...styles, border: "0", backgroundColor: "transparent" }),
    control: (styles, { isDisabled }) => ({
      ...styles, border: "0", color: isDisabled
        ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#bdc7d0" : "#666666") : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: isDisabled
          ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#EEF0F1" : "#17191c") : (localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b"), height: "32px", minHeight: "32px"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? undefined
              : undefined,
        color: isDisabled
          ? '#ccc'
          : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
            && data.color
            : undefined,
        },
      };
    },
    menuList: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b" }),
    input: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
    placeholder: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };
  const { Option } = components
  const CustomSelectOption = props => (
    <Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ display: "inline-flex", alignItems: "center", transform: "scale(0.75)", marginRight: "12px" }} dangerouslySetInnerHTML={{ __html: props.data.icon }} />
        <span style={{ fontSize: "14px", lineHeight: "1" }}>{props.data.label}</span>
      </div>
    </Option>
  )

  const CustomSelectValue = props => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: props.data.icon }} />
      <span style={{ fontSize: "16px", lineHeight: "1" }}>{props.data.label}</span>
    </div>
  )
  const CustomSelectValueLoading = props => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={cysrConfig().button_icon_left_style} className="spin" dangerouslySetInnerHTML={{ __html: cysrConfig().loading.icon.svg }} />
      <span style={{ fontSize: "16px", lineHeight: "1" }}>{props.data.label}</span>
    </div>
  )

  const Svg = (p) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      focusable="false"
      role="presentation"
      {...p}
    />
  );
  const DropdownIndicator = () => (
    <div style={{ color: "#0D918F", height: 20, width: 20, display: "flex", alignItems: "center" }}>
      <Svg>
        <path d="M10 13.75L3.75 7.5L4.625 6.625L10 12L15.375 6.625L16.25 7.5L10 13.75Z" fill="currentColor" />
      </Svg>
    </div>
  );

  const accountsRoleCallUrl = `${cysrConfig().api_base_url}notification/myfeed/?archived=${notificationsShowArchived}&start_date=${format(subDays(new Date(), notificationsDaysRange ? notificationsDaysRange : 8), "yyyy-MM-dd'T'hh:mm:ss.SSS")}`;
  useEffect(() => {
    setLoading(true);
    const pageTitle = "Notifications";
    document.title = `${pageTitle} - ${document.title.split(" - ")[1] ? document.title.split(" - ")[1] : document.title}`;

    if (localStorage.getItem("cysr_useraccountroles")) {
      // call table of asset + call table of checks
      fetch(accountsRoleCallUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
      })
        .then((response) => response.json())
        .then((responseAccountsRoleData) => {
          console.log(responseAccountsRoleData);
          if (responseAccountsRoleData.code && responseAccountsRoleData.code === "token_not_valid") {
            // remove localstorage and go to login
            const dMode = localStorage.getItem('cysr_dark_mode') ? localStorage.getItem('cysr_dark_mode') : "true";
            localStorage.clear();
            localStorage.setItem('cysr_dark_mode', dMode);
            window.location.href = `/cysr/login`;
            return
          }
          const userAccounts = responseAccountsRoleData.events;
          userAccounts.sort((a, b) =>
            a.title
              .toLowerCase()
              .localeCompare(b.title.toLowerCase())
          );
          // set values of boxes
          setViews({
            accounts_role: userAccounts
          });
          setTData(
            {
              nodes: userAccounts.filter(filterNotifications(tableTagsActive, COLUMNS)
              )
            }
          );
          setLoading(false);
        })
        .catch((err) => {
          console.log(err)
          setLoading(false);
        });
    } else {
      setViews({});
      setLoading(false);
    }
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <InfoButton cysr_info={cysrConfig().i("dashboard", "notifications", "help")} cysr_info_link={cysrConfig().i("dashboard", "notifications", "help_link")} />
            <MDBox py={5} px={3} fullWidth>
              <MDBox mb={1} fullWidth>
                <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  <div>
                    {cysrConfig().i("dashboard", "notifications", "title") && (
                      <MDTypography variant="h5">{cysrConfig().i("dashboard", "notifications", "title")}</MDTypography>
                    )}
                    {cysrConfig().i("dashboard", "notifications", "sub_title") && (
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        {cysrConfig().i("dashboard", "notifications", "sub_title")}
                      </MDTypography>
                    )}
                  </div>
                </MDBox>

              </MDBox>
            </MDBox>
            <div style={{ position: "relative" }}>
              {false && <InfoButton cysr_info={cysrConfig().i("dashboard", "companies_list", "help")} cysr_info_link={cysrConfig().i("dashboard", "companies_list", "help_link")} />}
              {false && <MDBox pb={5} px={3} fullWidth>
                <MDBox mb={1} fullWidth>
                  <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <div>
                      {cysrConfig().i("dashboard", "companies_list", "title") && (
                        <MDTypography variant="h5">{cysrConfig().i("dashboard", "companies_list", "title")}</MDTypography>
                      )}
                      {cysrConfig().i("dashboard", "companies_list", "sub_title") && (
                        <MDTypography variant="button" color="text" fontWeight="regular">
                          {cysrConfig().i("dashboard", "companies_list", "sub_title")}
                        </MDTypography>
                      )}
                    </div>
                  </MDBox>
                </MDBox>
              </MDBox>}
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <MDBox pb={5} pt={0} px={3} fullWidth>
                    <CysrTableNotifications
                      data={
                        {
                          search: params.get('s') ? params.get('s') : "",
                          hideCompleted: params.get('hc') ? true : false,
                          tableTagsOptions: [],
                          nodes: views.accounts_role,
                        }
                      }
                      tableTagsActive={tableTagsActive}
                      setTableTagsActive={setTableTagsActive}
                      showUnread={showUnread}
                      setShowUnread={setShowUnread}
                      tData={tData}
                      setTData={setTData}
                      COLUMNS={COLUMNS}
                      filterNotifications={filterNotifications}
                      ids={ids}
                      setIds={setIds}
                      toggleArchive={toggleArchive}
                      toggleRead={toggleRead}
                      notificationsDaysRange={notificationsDaysRange ? notificationsDaysRange : 8}
                      notificationsShowArchived={notificationsShowArchived}
                    />
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "8px", width: "100%", alignItems: "center" }}>
                      <MDButton onClick={
                        () => {
                          let timestampsToUpdate = []
                          // set all unread as read
                          tData && tData.nodes && tData.nodes.forEach((item, i) => {
                            if (item.read === false) {
                              toggleRead(item.timestamp, item.read, false)
                              timestampsToUpdate.push(item.timestamp)
                            }
                          })
                          // update data nodes s
                          let newNodes = [];
                          tData && tData.nodes && tData.nodes.forEach(nd => {
                            let newNode = nd;
                            if (timestampsToUpdate.indexOf(nd.timestamp) > -1) {
                              newNode.read = true;
                            }
                            newNodes.push(newNode);
                          });
                          setTData({ nodes: newNodes.filter(filterNotifications(tableTagsActive, COLUMNS)) })
                          // update data nodes e
                        }
                      } style={cysrConfig().button_style} size="medium">
                        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().checks.icon.svg }} />
                        Set All as Read
                      </MDButton>
                      <Select isSearchable={false} options={[
                        {
                          label: "Unarchived",
                          value: false
                        },
                        {
                          label: "Archived",
                          value: true
                        }
                      ]} defaultValue={{
                        label: "Unarchived",
                        value: false
                      }} styles={selectStatusLoading ? loadingStyles : colourStyles} onChange={
                        (newValue) => {
                          const displayArchived = newValue.value;
                          fetch(`${cysrConfig().api_base_url}notification/myfeed/?archived=${displayArchived}&start_date=${format(subDays(new Date(), notificationsDaysRange ? notificationsDaysRange : 0), "yyyy-MM-dd'T'hh:mm:ss.SSS")}`, {
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                              "content-type": "application/json",
                            },
                            method: "GET",
                          })
                            .then((response) => {
                              if (response.ok) {
                                response.json()
                                  .then((responseData) => {
                                    const newDisplayArchivedValue = displayArchived;
                                    setNotificationsShowArchived(newDisplayArchivedValue)
                                    setViews({
                                      accounts_role: responseData.events,
                                    });
                                    setTData(
                                      {
                                        nodes: responseData.events.filter(filterNotifications(tableTagsActive, COLUMNS, displayArchived)
                                        )
                                      }
                                    );
                                  })
                              } else {
                                return response.json().then(err => {
                                  throw JSON.stringify(err)
                                })
                              }
                            })
                            .catch((err) => {
                              console.error("enabled err:", err);
                              console.error(err);
                            });

                        }}
                        components={{ DropdownIndicator, Option: CustomSelectOption, SingleValue: selectStatusLoading ? CustomSelectValueLoading : CustomSelectValue }}
                      />
                      <Select isSearchable={false} options={[
                        {
                          date: subDays(new Date(), 8),
                          label: "7 days",
                          value: 8
                        },
                        {
                          date: subDays(new Date(), 31),
                          label: "30 days",
                          value: 31
                        },
                        {
                          date: subDays(new Date(), 91),
                          label: "90 days",
                          value: 91
                        }
                      ]} defaultValue={{
                        date: subDays(new Date(), 8),
                        label: "7 days",
                        value: 8
                      }} styles={selectStatusLoading ? loadingStyles : colourStyles} onChange={
                        (newValue) => {
                          let currentRangeValue = notificationsDaysRange ? notificationsDaysRange : 0;
                          currentRangeValue = newValue.value;
                          // 230415-054155-658
                          // {format(newValue.date, "yyyyMMdd-hhmmss-SSS")}
                          // "2023-05-09T15:09:19.504"
                          fetch(`${cysrConfig().api_base_url}notification/myfeed/?archived=${notificationsShowArchived}&start_date=${format(newValue.date, "yyyy-MM-dd'T'hh:mm:ss.SSS")}`, {
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                              "content-type": "application/json",
                            },
                            method: "GET",
                          })
                            .then((response) => {
                              if (response.ok) {
                                response.json()
                                  .then((responseData) => {
                                    const newRangeValue = currentRangeValue;
                                    setNotificationsDaysRange(newRangeValue)
                                    setViews({
                                      accounts_role: responseData.events,
                                    });
                                    setTData(
                                      {
                                        nodes: responseData.events.filter(filterNotifications(tableTagsActive, COLUMNS)
                                        )
                                      }
                                    );
                                  })
                              } else {
                                return response.json().then(err => {
                                  throw JSON.stringify(err)
                                })
                              }
                            })
                            .catch((err) => {
                              console.error("enabled err:", err);
                              console.error(err);
                            });

                        }}
                        components={{ DropdownIndicator, Option: CustomSelectOption, SingleValue: selectStatusLoading ? CustomSelectValueLoading : CustomSelectValue }}
                      />
                      <FormControlLabel sx={{ marginLeft: 0 }}
                        control={
                          <Checkbox checked={showUnread} onChange={filterByUnreadToggle} />
                        }
                        label="Show only Unread"
                      />

                    </div>
                  </MDBox>
                </>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>

      {
        /*
      }
        <MDBox py={3}>
          {false && views && views.timeline && (
            <LineChart
              data={views.timeline.data}
              options={views.timeline.options}
            />
          )}
        </MDBox>
        <MDBox py={3} fullWidth>
          <MDBox mb={3} fullWidth>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MDBox pb={3} lineHeight={1} fullWidth>
                  <MDBox mb={1} fullWidth>
                    <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                      <MDTypography variant="h5">Companies list</MDTypography>
                      <MDButton
                        aria-label="expand row"
                        color="dark"
                        size="medium"
                        onClick={() => setOpen(!createOpen)}
                        >
                        Add a company&nbsp;{createOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </MDButton>
                    </MDBox>
                    <Collapse in={createOpen} style={{width:"100%"}} timeout="auto" unmountOnExit>        
                      <form onSubmit={createAccount} py={3} style={{ display:"block", padding: ".5em 0"}}>
                        <Grid container spacing={1}>
                          <Grid item xs={3} lg={2}>
                            <Autocomplete
                              defaultValue="IT"
                              // label="generalasset_type"
                              id="c_account_vat_code"
                              size="small"
                              renderInput={(params) => <MDInput style={{height:"100%"}} label="Company VAT Code" {...params} />}
                              style={{height:"100%", display:"block"}}
                            />
                          </Grid>
                          <Grid item xs={9} lg={4}>
                            <MDInput
                              size="small"
                              label="Company VAT"
                              id="c_account_data_national_id"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <MDInput
                              fullWidth
                              size="small"
                              label="Company name"
                              id="c_account_name"
                            />
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <MDButton fullWidth size="small" color="dark" type="submit" style={{height:"100%"}}>
                              Create Company
                            </MDButton>
                          </Grid>
                        </Grid>
                      </form>
                      <MDSnackbar
                        color="error"
                        icon="error"
                        title="Company not created"
                        content="Check that all the fields are compiled correctly and try again"
                        dateTime="Error"
                        open={show}
                        close={toggleSnackbar}
                      />
                    </Collapse>
                    
                  </MDBox>
                  <MDTypography variant="button" color="text" fontWeight="regular">
                    The list of companies this account has access to.
                  </MDTypography>
                </MDBox>
                {views && views.accounts_role && views.accounts_role.length > 0 ? (
                  <MDBox
                    // display="flex"
                    // justifyContent="space-between"
                    // alignItems="center"
                    width={{ xs: "max-content", sm: "100%" }}
                  >
                    {views.accounts_role.map(
                      (role) =>
                        role ? (
                          <div key={role.account_uuid}>
                            <Grid container spacing={3}>
                              {role.account && role.rating && role.rating[0] && role.rating[0].rating_val ? (
                              <Grid item xs={3} md={2}>
                                <img
                                  alt="Rating"
                                  src={`${cysrConfig().image_rating_base_url}${role.rating[0].rating_val}.svg`}
                                  width="100%"
                                />
                              </Grid>
                              ) : (
                                <Grid item xs={3} md={2}>
                                  <p>No rating</p>
                                </Grid>
                              )}
                              <Grid item xs={9} md={10}>
                                <DashboardCompanyInfo useraccountrole={role} />
                              </Grid>
                            </Grid>
                            <Divider />
                          </div>
                        ) : <p>No role</p>
                    )}
                  </MDBox>
                ) : (
                  <MDTypography variant="caption" color="text">
                    This account does not have any company
                  </MDTypography>
                )}
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        {
          */
      }

    </DashboardLayout>
  );
}

export default Sales;

/* eslint-disable */
// prettier-ignore
