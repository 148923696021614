/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Base64 } from 'js-base64';
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
import MDButton from "components/MDButton";
import RenderMarkdown from "layouts/cysr/components/RenderMarkdown"

import ScoreItem from "layouts/cysr/components/ScoreItem";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import DataTable from "layouts/cysr/components/DataTable";
import Loader from "layouts/cysr/components/Loader";
// Data
// import checksData from "layouts/cysr/components/checksData";

import CysrTableIssues from "layouts/cysr/components/CysrTable/issues";
import { useLocation } from "react-router-dom";
import { useMaterialUIController } from "context";

import cysrConfig from "layouts/cysr/config";
import { IssueBadge, IssuesAssets } from "../components/CysrTable/issues";
// ms
const App = function () {
    // get hashtag
    let issueUUID = 0;
    // extract id
    if (window.location.hash !== undefined) {
        issueUUID = window.location.hash.replace("#", "");
    }
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const [isLoading, setLoading] = useState(true);
    const [referencesExpanded, setReferencesExpanded] = useState(false);
    const maxReferences = 5;
    const location = useLocation();
    // Our State.
    const [views, setViews] = useState([]);
    // const accountUUID = "8c75a44b-0faa-4e83-9bef-4ee47d21883a";
    // const accountUUID = window.location.pathname.substring(window.location.pathname.indexOf(":") + 1);
    const accountUUID = window.location.pathname.substring(
        window.location.pathname.indexOf("/company/") + "/company/".length,
        window.location.pathname.lastIndexOf("/")
    );
    // all checks:
    // const callUrl = `${cysrConfig().api_base_url}account/${accountUUID}/lastcheck/`;
    // fare loop su questo
    // const callUrl = `${cysrConfig().api_base_url}dashboard/account/${accountUUID}/`;
    // /{version}/dashboard/account/{account_req_uuid}/checks/
    const callUrl = `${cysrConfig().api_base_url}dashboard/account/${accountUUID}/issue/${issueUUID}/`;
    useEffect(() => {
        setLoading(true);
        fetch(callUrl, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log('issue')
                // console.log(data)
                setViews({
                    // checks: data,
                    issue: data,// ? Object.values(data.issues) : [],
                    useraccountrole_account: {
                        account_uuid: accountUUID,
                    }
                });
                setLoading(false);
            })
            .catch((err) => {
                console.error(err)
                setLoading(false);
            });
    }, [location]);
    return (
        <DashboardLayout>
            <DashboardNavbar cysr_company />
            <MDBox pt={6} pb={3}>
                <Card>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            {views && views.issue &&
                                <>
                                    <MDBox pl={3} pt={3} lineHeight={1}>
                                        <MDTypography variant="h5" fontWeight="medium">
                                            {views && views?.issue?.issuedescr_source && views?.issue?.issuedescr_source === "CVE" ? "Vulnerability" : "Issue"}
                                        </MDTypography>
                                    </MDBox>
                                    <div className={`${darkMode ? "dark" : "light"}`}>
                                        <MDBox py={5} px={3} fullWidth>

                                            <div className="flex flex-col gap-2 text-black dark:text-white text-base">
                                                <div className='mb-1 flex gap-4'>
                                                    {views.issue?.categories?.map(c => {
                                                        return <div className='flex gap-2 items-center text-black dark:text-white'>
                                                            <span className='text-sm'>{c.category_longname ?? c.category}</span><IssueBadge text={c.severity_descr} /></div>
                                                    })}
                                                </div>
                                                <MDTypography variant="h3" fontWeight="medium">{views.issue?.issuedescr_shortname ?? views.issue?.issuedescr_key}</MDTypography>
                                                {
                                                    // <p className="text-xs">{views.issue?.issuedescr_source}</p>
                                                }
                                                <div className="grid md:grid-cols-2 gap-6">
                                                    <div className="flex flex-col gap-2">
                                                        <p className="max-w-3xl"><RenderMarkdown>{views.issue?.issuedescr_description}</RenderMarkdown></p>
                                                        {
                                                            views.issue?.issuedescr_remediation ?
                                                                <div className="my-4">

                                                                    <MDTypography variant="h5" fontWeight="medium">Remediation</MDTypography>
                                                                    <p className="max-w-3xl"><RenderMarkdown>{views.issue?.issuedescr_remediation}</RenderMarkdown></p>
                                                                </div>
                                                                : null
                                                        }
                                                        {
                                                            views.issue?.issuedescr_references &&
                                                                views.issue?.issuedescr_references?.length > 0 ?
                                                                <div className="my-4">
                                                                    <MDTypography variant="h5" fontWeight="medium">References</MDTypography>
                                                                    <div className="max-w-3xl mt-2 p-3 flex flex-col gap-2 rounded-lg border border-zinc-300 dark:border-zinc-700">
                                                                        <ul className="flex flex-col gap-1 list-disc list-inside marker:text-teal-500">
                                                                            {views.issue?.issuedescr_references.map((r, i) => !referencesExpanded && i > maxReferences ? null :
                                                                                <li className="inline-block" key={r}>
                                                                                    <a style={{
                                                                                        wordBreak: "break-all",
                                                                                        overflow: "hidden",
                                                                                        maxWidth: " calc(100% - 36px)",
                                                                                        textOverflow: "ellipsis"
                                                                                    }} className="inline-block whitespace-nowrap" href={r} target="_blank">{r}</a>
                                                                                </li>
                                                                            )}
                                                                        </ul>
                                                                        {views.issue?.issuedescr_references?.length > maxReferences ?
                                                                            referencesExpanded
                                                                                ? <MDButton onClick={() => setReferencesExpanded(false)} style={cysrConfig().button_style} size="medium">
                                                                                    View less
                                                                                    <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} />
                                                                                </MDButton>
                                                                                : <MDButton onClick={() => setReferencesExpanded(true)} style={cysrConfig().button_style} size="medium">
                                                                                    View more
                                                                                    <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />
                                                                                </MDButton>
                                                                        :null}
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                    <div>
                                                        {views.issue?.issuedescr_details?.cvss3_base ? <>
                                                            <MDTypography variant="h5" fontWeight="medium">CVSS3</MDTypography>
                                                            <ul className="my-3 flex flex-col">
                                                                <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm border text-zinc-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg border-zinc-300 dark:border-zinc-700 dark:text-zinc-200">
                                                                    <div className="flex items-center justify-between w-full">
                                                                        <span>Base</span>
                                                                        <span>{views.issue?.issuedescr_details?.cvss3_base}</span>
                                                                    </div>
                                                                </li>
                                                                <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm border text-zinc-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg border-zinc-300 dark:border-zinc-700 dark:text-zinc-200">
                                                                    <div className="flex items-center justify-between w-full">
                                                                        <span>Impact</span>
                                                                        <span>{views.issue?.issuedescr_details?.cvss3_impact}</span>
                                                                    </div>
                                                                </li>
                                                                <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium border text-zinc-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg border-zinc-300 dark:border-zinc-700 dark:text-zinc-200">
                                                                    <div className="flex items-center justify-between w-full">
                                                                        <span>Exploitability</span>
                                                                        <span>{views.issue?.issuedescr_details?.cvss3_exploitability}</span>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <MDTypography variant="h5" fontWeight="medium">CVSS3 Vector</MDTypography>
                                                            <p>{views.issue?.issuedescr_details?.cvss3_vector}</p>
                                                        </>
                                                            : null}
                                                        {views.issue?.issuedescr_details?.cvss_base ?
                                                            <>
                                                                <MDTypography variant="h5" fontWeight="medium">CVSS</MDTypography>
                                                                <ul className="my-3 flex flex-col">
                                                                    <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm border text-zinc-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg border-zinc-300 dark:border-zinc-700 dark:text-zinc-200">
                                                                        <div className="flex items-center justify-between w-full">
                                                                            <span>Base</span>
                                                                            <span>{views.issue?.issuedescr_details?.cvss_base}</span>
                                                                        </div>
                                                                    </li>
                                                                    <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm border text-zinc-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg border-zinc-300 dark:border-zinc-700 dark:text-zinc-200">
                                                                        <div className="flex items-center justify-between w-full">
                                                                            <span>Impact</span>
                                                                            <span>{views.issue?.issuedescr_details?.cvss_impact}</span>
                                                                        </div>
                                                                    </li>
                                                                    <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium border text-zinc-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg border-zinc-300 dark:border-zinc-700 dark:text-zinc-200">
                                                                        <div className="flex items-center justify-between w-full">
                                                                            <span>Exploitability</span>
                                                                            <span>{views.issue?.issuedescr_details?.cvss_exploitability}</span>
                                                                        </div>
                                                                    </li>
                                                                </ul>

                                                                <MDTypography variant="h5" fontWeight="medium">CVSS Vector</MDTypography>
                                                                <p>{views.issue?.issuedescr_details?.cvss_vector}</p>
                                                            </>
                                                            : null}
                                                        {views.issue?.issuedescr_details?.cwe ? <div className="mt-3 flex flex-col">
                                                            <MDTypography variant="h5" fontWeight="medium">CWE</MDTypography>
                                                            <p>{views.issue?.issuedescr_details?.cwe}</p></div> : null}
                                                        {views.issue?.issuedescr_details?.products ? <div className="mt-3 flex flex-col">
                                                            <MDTypography variant="h5" fontWeight="medium">Products</MDTypography>
                                                            <div className="mt-2 flex flex-wrap gap-2">
                                                                {views.issue?.issuedescr_details?.products?.map(x =>
                                                                    <span className="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium border border-zinc-800 text-zinc-800 dark:border-white dark:text-white">
                                                                        {x}
                                                                    </span>
                                                                )}</div>
                                                        </div> : null}

                                                        {views.issue?.issuedescr_details?.vendors ? <div className="mt-3 flex flex-col">
                                                            <MDTypography variant="h5" fontWeight="medium">Vendors</MDTypography>
                                                            <div className="mt-2 flex flex-wrap gap-2"> {views.issue?.issuedescr_details?.vendors?.map(x =>
                                                                <span className="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium border border-zinc-800 text-zinc-800 dark:border-white dark:text-white">
                                                                    {x}
                                                                </span>
                                                            )}
                                                            </div>
                                                        </div> : null}

                                                        {views.issue?.issuedescr_details?.published ? <div className="mt-3 flex flex-col">
                                                            <MDTypography variant="h5" fontWeight="medium">Published</MDTypography>
                                                            <p>{new Date(views.issue?.issuedescr_details?.published)?.toLocaleDateString('it-IT', {
                                                                year: 'numeric',
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                            })}</p></div> : null}

                                                        {views.issue?.issuedescr_details?.modified ? <div className="mt-3 flex flex-col">
                                                            <MDTypography variant="h5" fontWeight="medium">Modified</MDTypography>
                                                            <p>{new Date(views.issue?.issuedescr_details?.modified)?.toLocaleDateString('it-IT', {
                                                                year: 'numeric',
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                            })}</p></div> : null}
                                                    </div>
                                                </div>
                                                <div className='mt-1 flex flex-col gap-2'>
                                                    {
                                                        views.issue?.assets
                                                            ? <div>
                                                                <MDTypography variant="h5" fontWeight="medium">Assets</MDTypography>
                                                                <IssuesAssets assets={Object.values(views.issue.assets)} accountUUID={accountUUID} height={'720px'} pagination={true} />
                                                            </div>
                                                            : <span className="text-white">No assets.</span>
                                                    }
                                                </div>
                                            </div>
                                        </MDBox>
                                    </div>
                                </>
                            }
                        </>
                    )}
                </Card>
            </MDBox>
        </DashboardLayout>
    );
};
export default App;
/* eslint-disable */