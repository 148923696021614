/* eslint-disable */
import VirtualizedCheckbox from 'react-virtualized-checkbox'
import { useState, useEffect } from "react";
import styles from './VirtualizedCheckboxes.module.css';

import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

export default function VirtualizedCheckboxes({
    items = [],
    /*
        { label: "One", value: 1, checked: true },
        { label: "Two", value: 2, checked: true },
        { label: "Three", value: 3, checked: true }
    ],
    */
    onChange,
    label,
    darkMode
}) {
    const initialCheckedItems = items.filter((i) => i.checked);
    const [checkedText, setCheckedText] = useState(initialCheckedItems && initialCheckedItems.length > 0
        ? initialCheckedItems.map(
            (c, i) => <span key={`${c.label}-c`}>{i > 0 ? ", " : ""}{c.label}</span>
        )
        : "No option selected"
    )
    const [checkboxesChecked, setCheckboxesChecked] = useState(initialCheckedItems)
    // const [visibleItems, setVisibleItems] = useState(items.filter((i) => i.checked))
    return (
        <div className={`${darkMode ? "dark" : "light"}`}>
            <div style={{ paddintTop: "8px", pointerEvents: "none", position: "relative", zIndex: "1" }}>
                <MDInput
                    value={" "}
                    sx={{ width: "100%", zIndex: "-1", position: "relative", color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? 'rgb(9 9 11)' : 'rgb(250 250 250)', borderColor: darkMode ? 'rgb(63 63 70)' : 'rgb(212 212 216)', borderRadius: "6px" }}
                    size="small" label={label} />
            </div>
            <div
                className={styles.virtualized_checkbox_container}
                style={{
                    height: 150,
                    marginTop: "-30px",
                    zIndex: 5,
                    position: "relative"
                }}
            >
                <VirtualizedCheckbox
                    hasCancelButton={false}
                    hasFilterBox={true}
                    hasOkButton={false}
                    height={150}
                    items={items}
                    onChange={item => {
                        let currentCheckboxesChecked = checkboxesChecked;
                        if (item.length > 1) {
                            if (item[0] && item[0].checked) {
                                currentCheckboxesChecked = (item.filter((i) => i.checked));
                                setCheckboxesChecked(currentCheckboxesChecked);
                                onChange(item.filter((i) => i.checked))
                            } else {
                                currentCheckboxesChecked = []
                                setCheckboxesChecked(currentCheckboxesChecked)
                                onChange([])
                            }
                        } else {
                            let elementFoundAtIndex = false;
                            console.warn(currentCheckboxesChecked)
                            currentCheckboxesChecked.forEach((c, i) => {
                                if (c.value === item.value) {
                                    elementFoundAtIndex = i;
                                }
                            });
                            if (item.checked) {
                                // add it
                                currentCheckboxesChecked.push(item)
                            } else if (elementFoundAtIndex !== false) {
                                currentCheckboxesChecked.splice(elementFoundAtIndex, 1)
                            }
                            setCheckboxesChecked(currentCheckboxesChecked)
                            onChange(currentCheckboxesChecked)
                            console.log(currentCheckboxesChecked)
                        }
                        setCheckedText(currentCheckboxesChecked && currentCheckboxesChecked.length > 0
                            ? currentCheckboxesChecked.map(
                                (c, i) => <span key={`${c.label}-c`}>{i > 0 ? ", " : ""}{c.label}</span>
                            )
                            : "No option selected"
                        )
                    }}
                    rowHeight={30}
                />
            </div>
            <MDTypography variant="caption" color="text" sx={{
                display: "block",
                overflow: "hidden",
                marginTop: "8px",
                paddingTop: "8px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                borderTop: "1px solid rgba(0, 0, 0, .15)"
            }}>
                {checkboxesChecked && checkboxesChecked.length > 0 && <strong style={
                    {
                        marginRight: "4px",
                        fontFamily: "Ubuntu Mono, monospace"
                    }
                }>{checkboxesChecked.length + ":"}</strong>}
                {checkedText}
            </MDTypography>
        </div>
    )
}
/* eslint-disable */