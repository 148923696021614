/* eslint-disable */
import { Base64 } from 'js-base64';
import { useState } from "react";
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/material-ui';
import { Collapse, Paper, Stack, TablePagination } from '@mui/material';

import { usePagination } from '@table-library/react-table-library/pagination';
import { useSort } from '@table-library/react-table-library/sort';

// custom stuffs s
import cysrConfig, { process } from "layouts/cysr/config";
import { format } from 'date-fns'

import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ScoreItem from "layouts/cysr/components/ScoreItem";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import StatusItem from "layouts/cysr/components/StatusItem";
import CysrTags from "layouts/cysr/components/CysrTags";
// import FormField from "layouts/pages/account/components/FormField";

import { useMaterialUIController } from "context";
// custom stuffs e

const Component = ({ data }) => {
  // const actionsPixelWidth = 148;
  const tableRowsPerPage = [25, 50, 100, 200, 400];
  const [tableTagsActive, setTableTagsActive] = useState(data.tableTagsActive);
  const [tableTagsOptions, setTableTagsOptions] = useState(data.tableTagsOptions);
  const [hideCompleted, setHideCompleted] = useState(data.hideCompleted);
  const [search, setSearch] = useState(data.search);
  // data.nodes
  /*
  {
    "columns": [
        {
            "align": null,
            "Header": "Name",
            "accessor": "name"
        },
        {
            "align": null,
            "Header": "Version",
            "accessor": "version"
        },
        {
            "align": null,
            "Header": "Category",
            "accessor": "category"
        },
        {
            "align": null,
            "Header": "Total CVEs",
            "accessor": "total_cves"
        }
    ],
    "isSorted": [
        {
            "name": "desc"
        }
    ],
    "rows": [
        {
            "name": "Google Hosted Libraries",
            "version": "-",
            "category": "CDN",
            "total_cves": 0
        }
    ]
  */
  let formattedDates = [];
  data.rows.forEach(td => {
    let newObject = {};
    for (const key in td) {
      if (Object.hasOwnProperty.call(td, key)) {
        const val = td[key];
        if (key === "date") {
          newObject[key] = format(new Date(val), process.env.NEXT_PUBLIC_DATE_FORMAT_MINUTES);
        } else {
          newObject[key] = (val === true) ? "true" : ((val === false) ? "false" : val);
        }
      }
    }
    formattedDates.push(newObject)
  });
  // let tData = { nodes: data.rows };
  let tData = { nodes: formattedDates };
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  let accountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/company/") + "/company/".length,
    window.location.pathname.lastIndexOf("/")
  );
  if (localStorage.getItem("cysr_useraccountrole_selected")) {
    accountUUID = localStorage.getItem("cysr_useraccountrole_selected");
  }

  let COLUMNS = [];
  let filterableColumnsValues = [];

  data.columns.forEach(c => {
    COLUMNS.push(
      {
        /*
              "align": null,
              "Header": "Category",
              "accessor": "category"
        */
        label: c.Header,
        renderCell: (item) => item[c.accessor].toString(),
        sort: { sortKey: c.accessor.toUpperCase() },
        resize: true
      })
    filterableColumnsValues.push(c.accessor)
  });

  // expandable
  const [ids, setIds] = useState([]);
  let autoString = "";
  let firstAuto = 0;
  COLUMNS.forEach(c => {
    if (firstAuto === 0) {
      autoString += "auto ";
    } else {
      firstAuto--;
    }
  });
  const expandedTableStyle = `
    --data-table-library_grid-template-columns: ${autoString}!important;
    .animate {
      grid-column: 1 / -1;
      display: flex;
    }
    .animate {
        visibility: visible;
        border-bottom: 1px solid #${darkMode ? "4a4545" : "f0f2f5"};
    }
    .animate > div {
      flex: 1;
      display: flex;
    }
    
    @media (min-width: 768px){
      .pin-right {
        right: 0;
      }
    }
  `;

  const handleExpand = (item) => {
    if (ids.includes(item.id)) {
      setIds(ids.filter((id) => id !== item.id));
    } else {
      setIds(ids.concat(item.id));
    }
  };

  const ROW_OPTIONS = {
    renderAfterRow: (item) => (
      <Collapse className="animate" in={ids.includes(item.id)}>
        <div style={{ flex: '1', display: 'flex' }}>
          <div style={{ flex: '1', backgroundColor: darkMode ? "#121619" : "#edf0f2" }}>
            <MDBox style={{ position: "sticky", left: 0, right: 0 }} maxWidth={{ xs: "calc(100vw - (48px + 64px))", xl: "calc(100vw - (282px + 48px + 58px))" }}>
              <Card style={{ backgroundColor: darkMode ? "#121619" : "#edf0f2", color: darkMode ? "#7B809A" : "#7B809A", borderRadius: 0, boxShadow: "none", padding: "1em", margin: 0 }}>
                <MDBox width="100%">
                  {/*}
                    {tableDetailsRow(item.id, "Name", item.name)}
                    <Divider />
                    {tableDetailsRow(item.id, "Deadline", item.deadline, "date")}
                    <Divider />
                    {tableDetailsRow(item.id, "Type", item.generalasset_type)}
                    <Divider />
                    {tableDetailsRow(item.id, "Completed", item.isComplete.toString())}
                    {*/}
                </MDBox>
              </Card>
            </MDBox>
          </div>
        </div>
      </Collapse>
    ),
  };

  // filter list
  tData = {
    nodes: tData.nodes && tData.nodes.filter((item) => {
      /*
      // check filter first
      if(hideCompleted && item.isComplete){
        return false
      }
      */
      // check tags
      // da fare tags con chiave valore
      if (tableTagsActive && tableTagsActive.length > 0) {
        let trueCount = tableTagsActive.length;
        tableTagsActive.forEach(ta => {
          let notFound = true;
          // X:item.cysrTag s
          if (ta && typeof ta === 'string' && ta.includes(":")) {
            const tag = ta.toLowerCase().split(":");
            const tagKey = tag[0].toLowerCase();
            const tagValue = tag[1].toString().trim();
            COLUMNS.forEach(col => {
              if (col.cysrTag && item[col.cysrTag] && col.label.toLowerCase() === tagKey && item[col.cysrTag.toLowerCase()] && item[col.cysrTag.toLowerCase()]) {
                // check cysrTagDateFormat
                if (col.cysrTagDateFormat) {
                  const vd = new Date(item[col.cysrTag.toLowerCase()].toString()).toLocaleDateString(col.cysrTagDateFormat, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })
                  if (vd.toString().includes(tagValue) && notFound) {
                    trueCount--;
                    notFound = false;
                  }
                } else if (item[col.cysrTag.toLowerCase()].toString().toLowerCase().includes(tagValue)) {
                  if (notFound) {
                    trueCount--;
                    notFound = false;
                  }
                }
              }
            })
          }
          // X:item.cysrTag e
          // compare (for freeSolo) s
          filterableColumnsValues.forEach(property => {
            if (ta && ta[property]) {
              // a default option (with [property])
              if (ta && ta[property] && item[property] && item[property].toLowerCase().includes(ta[property].toLowerCase())) {
                if (notFound) {
                  trueCount--;
                  notFound = false;
                }
              }
            } else {
              if (ta && ta.rating) {
                // avoid checking again ta with title (rating Excellent, Good...)
              } else {
                // not a default option (with [property])
                if (ta && item[property] && item[property].toLowerCase().includes(ta.toLowerCase())) {
                  if (notFound) {
                    trueCount--;
                    notFound = false;
                  }
                }
              }
            }
          });
          // compare (for freeSolo) e
        });
        if (trueCount !== 0) {
          return false;
        }
      }
      // check search at last
      // ..
      return true;
    }
    ),
  };

  const materialTheme = getTheme(DEFAULT_OPTIONS, {
    isVirtualized: true
  });

  // to set row border bottom style:
  // border-bottom: 1px solid #${darkMode ? "4a4545" : "f0f2f5"};

  const customTheme = {
    Table: expandedTableStyle,
    BaseCell: `
      border: 0!important;
      padding: 4px;
    `,
  };
  let theme = useTheme([materialTheme, customTheme]);
  if (darkMode) {
    // provide custom theme
    theme = useTheme([materialTheme, {
      Table: expandedTableStyle,
      BaseCell: `
        color: #ffffff;
        background-color: #192328;
        border: 0!important;
        padding: 4px;
      `,
    }
    ]);
  }

  const pagination = usePagination(tData, {
    state: {
      page: 0,
      // size: 2,
      // size: tData.nodes.length,
      size: tableRowsPerPage[0],
    },
    onChange: onPaginationChange,
  });

  function onPaginationChange(action, state) {
    console.log(action, state);
  }

  // add sorting functions s
  let sortFnsCustom = {};
  // VALUE: (array) => array.sort((a, b) => a.cve ? a.cve - b.cve ? b.cve : 0 : 0),
  filterableColumnsValues.forEach(property => {
    sortFnsCustom[property.toUpperCase()] = (array) => array.sort(function (a, b) {
      if (isNaN(a[property.toLowerCase()]) && isNaN(b[property.toLowerCase()])) {
        // both strings
        return a[property.toLowerCase()].localeCompare(b[property.toLowerCase()]);
      } else if (isNaN(a[property.toLowerCase()]) === false && isNaN(b[property.toLowerCase()]) === false) {
        // both numbers
        return a[property.toLowerCase()] - b[property.toLowerCase()];
      } else if (isNaN(a[property.toLowerCase()])) {
        // a not number
        return 0 - b[property.toLowerCase()];
      } else if (isNaN(b[property.toLowerCase()])) {
        // b not number
        return a[property.toLowerCase()] - 0;
      }
    })

  })
  // add sorting functions e

  const sort = useSort(
    tData,
    {
      state: {
        sortKey: filterableColumnsValues[0].toUpperCase(),
        reverse: true
      },
      onChange: onSortChange,
    },
    {
      sortIcon: {
        iconDefault: null,
        iconUp: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
        </svg>
        ,
        iconDown: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
        ,
      },
      sortFns: sortFnsCustom,
      /*
      sortFns: {
        VALUE: (array) => array.sort((a, b) => a.cve ? a.cve - b.cve ? b.cve : 0 : 0),
        CREATED: (array) => array.sort((a, b) => a.mu_created_at - b.mu_created_at),
        UPDATED: (array) => array.sort((a, b) => a.generalasset_lastcheck_datetime - b.generalasset_lastcheck_datetime),
        SOURCE: (array) => array.sort((a, b) => a.assetsrc_name.localeCompare(b.assetsrc_name)),
      },
      */
    },
  );

  function onSortChange(action, state) {
    console.log(action, state);
  }

  const handleSetTableTags = (event, t) => {
    // console.log(t)
    setTableTagsActive(t);
    // replace url s
    let params = new URLSearchParams(window.location.search)
    params.delete('t')
    if (t != "" && t.length > 0) {
      params.append("t", Base64.encode(JSON.stringify(t)))
    }
    window.history.replaceState(null, '', '?' + params + window.location.hash)
    // replace url e
    // go to first page
    pagination.fns.onSetPage(0)
  };

  const VIRTUALIZED_OPTIONS = {
    rowHeight: (_item, _index) => {
      return 54
    }
  };

  return (
    <>
      <Stack spacing={10} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CysrTags tableTagsOptions={tableTagsOptions} tableTagsActive={tableTagsActive} handleSetTableTags={handleSetTableTags} darkMode={darkMode} />
          </Grid>
        </Grid>
      </Stack>
      <Paper style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none', height: 'auto' }}>
        {
          // <Paper style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none', height: '360px' }}>
        }
        <CompactTable
          columns={COLUMNS}
          // passando le virtualizedOptions non funziona l'expand abilitato, penso perchè imposta l'height da solo
          // virtualizedOptions={VIRTUALIZED_OPTIONS}
          data={tData}
          sort={sort}
          theme={theme}
          rowOptions={ROW_OPTIONS}
          layout={{
            isDiv: true,
            fixedHeader: true
          }}
          pagination={pagination}
          style={{ borderColor: 'transparent' }}
        // horizontalScroll={true}
        />
      </Paper>
      <Stack spacing={10}>
        <TablePagination
          component="div"
          style={{ color: darkMode ? "#ffffff" : "#000000", border: 0 }}
          count={tData.nodes.length}
          page={pagination.state.page}
          rowsPerPage={pagination.state.size}
          rowsPerPageOptions={tableRowsPerPage}
          onRowsPerPageChange={(event) =>
            pagination.fns.onSetSize(parseInt(event.target.value, 10))
          }
          onPageChange={(event, page) => pagination.fns.onSetPage(page)}
        />
      </Stack>
    </>
  );
};
export default Component;
/* eslint-disable */