/* eslint-disable */
import { Component, FunctionComponent, ReactNode } from 'react';
import MDButton from "components/MDButton";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Select, { components } from 'react-select';
import { defaultTheme } from 'react-select';
import cysrConfig from "layouts/cysr/config";

const { colors } = defaultTheme;
const accountUUID = window.location.pathname.substring(
  window.location.pathname.indexOf("/company/") + "/company/".length,
  window.location.pathname.lastIndexOf("/")
);

const { Option } = components
const CustomSelectOption = props => (
  <Option {...props}>
    <div style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
      <span style={{ display: "inline-flex", alignItems: "center", transform: "scale(0.75)", marginRight: "12px" }} dangerouslySetInnerHTML={{ __html: props.data.icon }} />
      <span style={{ fontSize: "14px", lineHeight: "1" }}>{props.data.label}</span>
    </div>
  </Option>
)

const selectStyles = {

  valueContainer: (base) => ({
    ...base,
    width: '100%',
    display: "flex"
  }),
  indicatorSeparator: (styles) => ({ ...styles, border: "0", backgroundColor: "transparent" }),
  control: (styles) => ({ ...styles, display: "none" }),
  /*
      control: (provided) => ({
          ...provided,
          minWidth: 240,
          margin: 8,
      }),
      */
  // menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused
            ? undefined
            : undefined,
      color: isDisabled
        ? '#aaa'
        : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
          && data.color
          : undefined,
      },
    };
  },
  menuList: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b" }),
  input: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
  placeholder: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
  singleValue: (styles, { data }) => ({ ...styles }),
};
export default class ButtonActions extends Component {
  state = { isOpen: false, value: undefined };
  toggleOpen = () => {
    this.setState((state) => ({ isOpen: !state.isOpen }));
  };
  onSelectChange = (value) => {
    // console.log(value)
    this.props.triggerAction(value.onClick);
    this.toggleOpen();
    this.setState({ value });
  };
  render() {
    const { isOpen, value } = this.state;
    return (
      <Dropdown
        isSearchable={false}
        searchable={false}
        isOpen={isOpen}
        onClose={this.toggleOpen}
        target={
          <ClickAwayListener onClickAway={() => {
            isOpen && this.toggleOpen()
          }}>
            <MDButton
              // onFocusVisible={isOpen}
              style={cysrConfig().button_style}
              size="medium"
              onClick={this.toggleOpen}
            >
              <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().more_actions.icon.svg }} />
              Export
              <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().more_actions.expand_icon.svg }} />
            </MDButton>
          </ClickAwayListener>
        }
      >
        <Select
          searchable={false}
          isSearchable={false}
          autoFocus
          backspaceRemovesValue={false}
          components={{ DropdownIndicator, IndicatorSeparator: null, Option: CustomSelectOption }}

          // controlShouldRenderValue={false}

          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          onChange={this.onSelectChange}
          options={this.props.options}
          styles={selectStyles}
          tabSelectsValue={false}
          value={value}
        />
      </Dropdown>
    );
  }
}

// styled components

const Menu = (props) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      css={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
      }}
      {...props}
    />
  );
};
const Blanket = (props) => (
  <div
    css={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({
  children,
  isOpen,
  target,
  onClose,
}) => (
  <div css={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div css={{ color: colors.neutral20, height: 20, width: 20 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);
/* eslint-disable */