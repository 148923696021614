/* eslint-disable */
import { Component, FunctionComponent, ReactNode } from 'react';
import MDButton from "components/MDButton";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useEffect, useState } from 'react';
import Select, { components, defaultTheme } from 'react-select';
import cysrConfig from "layouts/cysr/config";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';

const { colors } = defaultTheme;
let accountUUID = 0;
if (window.location.pathname.indexOf("/company/") > -1) {
  accountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/company/") + "/company/".length,
    window.location.pathname.lastIndexOf("/")
  );
} else {
  accountUUID = localStorage.getItem("cysr_useraccountrole_selected");
}

const colourStyles = {
  placeholder: (styles) => ({
    ...styles,
    fontSize: '16px',
    color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white"
  }),
  multiValueRemove: (base) => ({
    ...base,
    backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#f8f9fa" : "#23272E",
    borderRadius: 0
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
    backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#f8f9fa" : "#23272E",
    height: "18px",
    minHeight: "18px",
    lineHeight: "1",
    fontSize: '12px',
    borderRadius: 0
  }),
  valueContainer: (base) => ({
    ...base,
    width: '100%',
    minWidth: '160px',
    display: "flex",
  }),
  indicatorsContainer: (styles) => ({ ...styles, height: "100%" }),
  indicatorSeparator: (styles) => ({ ...styles, border: "0", backgroundColor: "transparent" }),
  control: (styles, { isDisabled }) => ({
    ...styles, border: "0", color: isDisabled
      ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#bdc7d0" : "#666666") : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: isDisabled
        ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#EEF0F1" : "#17191c") : (localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b"), height: "32px", minHeight: "32px", minWidth: "160px"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused
            ? undefined
            : undefined,
      color: isDisabled
        ? '#ccc'
        : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
          && data.color
          : undefined,
      },
    };
  },
  menuList: (styles) => ({ ...styles, position: "fixed", color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b" }),
  input: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
  singleValue: (styles, { data }) => ({ ...styles }),
};


const CustomSelectValue = props => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: props.data.icon }} />
    <span style={{ fontSize: "16px", lineHeight: "1" }}>{props.data.label}</span>
  </div>
)

export const triggerAction = function (fun, timeshift = false, parameters = null) {
  switch (fun) {
    case "toggle_archive":
      cysrConfig().more_actions.functions.toggle_archive(accountUUID)
      break;

    case "do_reset":
      cysrConfig().more_actions.functions.do_reset(accountUUID)
      break;

    case "go_reports":
      cysrConfig().more_actions.functions.go_reports(accountUUID)
      break;

    case "go_to_report_full":
      cysrConfig().more_actions.functions.go_to_report_full(accountUUID, timeshift)
      break;

    case "go_to_report_full_it":
      cysrConfig().more_actions.functions.go_to_report_full_it(accountUUID, timeshift)
      break;

    case "go_to_report_full_de":
      cysrConfig().more_actions.functions.go_to_report_full_de(accountUUID, timeshift)
      break;

    case "go_to_report_full_es":
      cysrConfig().more_actions.functions.go_to_report_full_es(accountUUID, timeshift)
      break;

    case "go_to_report_full_fr":
      cysrConfig().more_actions.functions.go_to_report_full_fr(accountUUID, timeshift)
      break;


    case "go_to_report_excel":
      cysrConfig().more_actions.functions.go_to_report_excel(accountUUID, timeshift)
      break;

    case "go_to_report":
      cysrConfig().more_actions.functions.go_to_report(accountUUID, timeshift)
      break;

    case "go_to_report_it":
      cysrConfig().more_actions.functions.go_to_report_it(accountUUID, timeshift)
      break;

    case "go_to_report_de":
      cysrConfig().more_actions.functions.go_to_report_de(accountUUID, timeshift)
      break;

    case "go_to_report_es":
      cysrConfig().more_actions.functions.go_to_report_es(accountUUID, timeshift)
      break;

    case "go_to_report_fr":
      cysrConfig().more_actions.functions.go_to_report_fr(accountUUID, timeshift)
      break;

    case "go_to_report_comply":
      cysrConfig().more_actions.functions.go_to_report_comply(accountUUID, timeshift)
      break;

    case "go_to_report_comply_it":
      cysrConfig().more_actions.functions.go_to_report_comply_it(accountUUID, timeshift)
      break;

    case "go_to_report_comply_de":
      cysrConfig().more_actions.functions.go_to_report_comply_de(accountUUID, timeshift)
      break;

    case "go_to_report_comply_es":
      cysrConfig().more_actions.functions.go_to_report_comply_es(accountUUID, timeshift)
      break;

    case "go_to_report_comply_fr":
      cysrConfig().more_actions.functions.go_to_report_comply_fr(accountUUID, timeshift)
      break;

    case "go_to_report_by_tags":
      cysrConfig().more_actions.functions.go_to_report_by_tags(accountUUID, timeshift, parameters)
      break;

    case "go_to_report_by_tags_it":
      cysrConfig().more_actions.functions.go_to_report_by_tags_it(accountUUID, timeshift, parameters)
      break;

    case "go_to_report_by_tags_de":
      cysrConfig().more_actions.functions.go_to_report_by_tags_de(accountUUID, timeshift, parameters)
      break;

    case "go_to_report_by_tags_es":
      cysrConfig().more_actions.functions.go_to_report_by_tags_es(accountUUID, timeshift, parameters)
      break;

    case "go_to_report_by_tags_fr":
      cysrConfig().more_actions.functions.go_to_report_by_tags_fr(accountUUID, timeshift, parameters)
      break;


    case "go_to_report_ARCHIVED":
      cysrConfig().more_actions.functions.go_to_report_ARCHIVED(accountUUID, timeshift)
      break;

    case "go_to_report_it_ARCHIVED":
      cysrConfig().more_actions.functions.go_to_report_it_ARCHIVED(accountUUID, timeshift)
      break;

    case "go_to_report_de_ARCHIVED":
      cysrConfig().more_actions.functions.go_to_report_de_ARCHIVED(accountUUID, timeshift)
      break;

    case "go_to_report_es_ARCHIVED":
      cysrConfig().more_actions.functions.go_to_report_es_ARCHIVED(accountUUID, timeshift)
      break;

    case "go_to_report_fr_ARCHIVED":
      cysrConfig().more_actions.functions.go_to_report_fr_ARCHIVED(accountUUID, timeshift)
      break;

    case "go_to_report_cti":
      cysrConfig().more_actions.functions.go_to_report_cti(accountUUID, timeshift)
      break;

    case "go_to_report_cti_it":
      cysrConfig().more_actions.functions.go_to_report_cti_it(accountUUID, timeshift)
      break;

    case "go_to_report_cti_de":
      cysrConfig().more_actions.functions.go_to_report_cti_de(accountUUID, timeshift)
      break;

    case "go_to_report_cti_es":
      cysrConfig().more_actions.functions.go_to_report_cti_es(accountUUID, timeshift)
      break;

    case "go_to_report_cti_fr":
      cysrConfig().more_actions.functions.go_to_report_cti_fr(accountUUID, timeshift)
      break;

    case "go_to_report_risk":
      cysrConfig().more_actions.functions.go_to_report_risk(accountUUID, timeshift)
      break;

    case "go_to_report_risk_it":
      cysrConfig().more_actions.functions.go_to_report_risk_it(accountUUID, timeshift)
      break;

    case "go_to_report_risk_de":
      cysrConfig().more_actions.functions.go_to_report_risk_de(accountUUID, timeshift)
      break;

    case "go_to_report_risk_es":
      cysrConfig().more_actions.functions.go_to_report_risk_es(accountUUID, timeshift)
      break;

    case "go_to_report_risk_fr":
      cysrConfig().more_actions.functions.go_to_report_risk_fr(accountUUID, timeshift)
      break;

    case "go_to_third_parties":
      cysrConfig().more_actions.functions.go_to_third_parties(accountUUID)
      break;

    case "go_to_subscription":
      cysrConfig().more_actions.functions.go_to_subscription(accountUUID)
      break;

    case "edit_company_info":
      cysrConfig().more_actions.functions.go_to_edit_company_info(accountUUID)
      break;

    default:
      break;
  }
}

const { Option } = components
const CustomSelectOption = props => (
  <Option {...props}>
    <div style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
      <span style={{ display: "inline-flex", alignItems: "center", transform: "scale(0.75)", marginRight: "12px" }} dangerouslySetInnerHTML={{ __html: props.data.icon }} />
      <span style={{ fontSize: "14px", lineHeight: "1" }}>{props.data.label}</span>
    </div>
  </Option>
)

const selectStyles = {

  valueContainer: (base) => ({
    ...base,
    width: '100%',
    display: "flex"
  }),
  indicatorSeparator: (styles) => ({ ...styles, border: "0", backgroundColor: "transparent" }),
  control: (styles) => ({ ...styles, display: "none" }),
  /*
      control: (provided) => ({
          ...provided,
          minWidth: 240,
          margin: 8,
      }),
      */
  // menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused
            ? undefined
            : undefined,
      color: isDisabled
        ? '#aaa'
        : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
          && data.color
          : undefined,
      },
    };
  },
  menuList: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b" }),
  input: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
  placeholder: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
  singleValue: (styles, { data }) => ({ ...styles }),
};
function NotificationsModal({ closeState }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(true);
  let accounts = [];
  const [notificationModalData, setNotificationModalData] = useState(false);
  const handleClose = () => {
    setDialogOpen(false);
    closeState()
  };
  useEffect(() => {
    // get topics
    const topicsUrl = `${cysrConfig().api_base_url}notification/topic/`;
    fetch(topicsUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          response.json()
            .then(async (responseData) => {
              responseData.forEach(ch => {
                // sv[ch.usernotificationtopic_uuid] = ch.usernotificationtopic_severity;
                if (ch.subscription_tech_accounts && ch.subscription_tech_accounts.length > 0) {
                  ch.subscription_tech_accounts.forEach(sch => {
                    if (sch.usernotificationtopic_topic == `account=${accountUUID}`) {
                      accounts.push(sch)
                    }
                  });
                }
              });
              // if empty use POST to create notification entry for this company
              if (accounts.length === 0) {
                /*
                // auto POST
                // notification/topic/
                const topicPostUrl = `${cysrConfig().api_base_url}notification/topic/`;
                await fetch(topicPostUrl, {
                  body: JSON.stringify({
                    usernotificationtopic_topic: `account=${accountUUID}`,
                    usernotificationtopic_enabled: true,
                    usernotificationtopic_severity: cysrConfig().severityArray[0].value
                  }),
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                    "content-type": "application/json",
                  },
                  method: "POST",
                })
                .then((response) => {
                  if (response.ok) {
                    response.json()
                      .then((responseData) => {
                        console.warn(responseData);
                        accounts.push(responseData)
                        setNotificationModalData(accounts[0])
                      })
                  } else {
                    return response.json().then(err => {
                      throw JSON.stringify(err)
                    })
                  }
                })
                .catch((err) => {
                  console.error("enabled err:", err);
                  console.error(err);
                });
                */
                setNotificationModalData(1);
              } else {
                setNotificationModalData(accounts[0])
              }
            })
        } else {
          return response.json().then(err => {
            throw JSON.stringify(err)
          })
        }
      })
      .catch((err) => {
        console.error("err1:", err);
        console.error(err);
        enqueueSnackbar(
          <div style={{ display: "flex", flexDirection: "column" }}>
            <MDTypography variant="caption" style={{ fontWeight: "600" }}>Reading failed</MDTypography>
            <MDTypography variant="caption">Notifications for this company couldn't be read</MDTypography>
          </div>, {
          variant: 'error',
          persist: false
        })
      });
  }, [])
  return (notificationModalData && notificationModalData !== false) ?
    <Dialog
      fullScreen={false}
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="remove-user-dialog-title-notifications"
      style={{
        background: darkMode ? 'rgba(0,0,0,0.25)' : 'rgba(255, 255, 255, 0.25)',
        backdropFilter: "blur(12px)",
        zIndex: 999999
      }}>
      <div style={{ background: darkMode ? '#192328' : '#ffffff' }}>
        <DialogTitle id="remove-user-dialog-title-notifications">
          {"Company Notifications"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {notificationModalData === 1 ?
              <MDTypography variant="body2">
                Do you want to activate a special configuration?
              </MDTypography>
              :
              <>
                <MDTypography variant="body2">
                  Special configuration for {notificationModalData.usernotificationtopic_descr}
                </MDTypography>
                <MDTypography variant="caption" color="text">
                  select a level, saved on change
                </MDTypography>
              </>
            }
            <div style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              marginTop: 4,
            }}>
              <div key={notificationModalData.usernotificationtopic_uuid} style={{ display: "flex", flexDirection: "column", gap: 12 }}>
                {notificationModalData === 1 ?
                  <div style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                  }}
                  >
                    <MDButton style={cysrConfig().button_style} size="medium" onClick={handleClose}
                    >
                      <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().close.icon.svg }} />
                      Cancel and close
                    </MDButton>
                    <MDButton style={cysrConfig().button_style} size="medium" onClick={async () => {
                      // notification/topic/
                      const topicPostUrl = `${cysrConfig().api_base_url}notification/topic/`;
                      await fetch(topicPostUrl, {
                        body: JSON.stringify({
                          usernotificationtopic_topic: `account=${accountUUID}`,
                          usernotificationtopic_enabled: true,
                          usernotificationtopic_severity: cysrConfig().severityArray[0].value
                        }),
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                          "content-type": "application/json",
                        },
                        method: "POST",
                      })
                        .then((response) => {
                          if (response.ok) {
                            response.json()
                              .then((responseData) => {
                                setNotificationModalData(responseData)
                              })
                          } else {
                            return response.json().then(err => {
                              throw JSON.stringify(err)
                            })
                          }
                        })
                        .catch((err) => {
                          console.error("enabled err:", err);
                          console.error(err);
                          enqueueSnackbar(
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <MDTypography variant="caption" style={{ fontWeight: "600" }}>Activation failed</MDTypography>
                              <MDTypography variant="caption">Notifications for this company couldn't be activated</MDTypography>
                            </div>, {
                            variant: 'error',
                            persist: false
                          })
                        });
                    }}>
                      <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
                      Activate and set notifications
                    </MDButton>
                  </div>
                  :
                  <>
                    <Select isDisabled={notificationModalData === 1} isSearchable={false} options={cysrConfig().severityArray} defaultValue={notificationModalData === 1 ? cysrConfig().severityArray[0] : cysrConfig().severityArray.filter((s) => s.value == notificationModalData.usernotificationtopic_severity)} styles={colourStyles} onChange={(newValue) => {
                      const topicsPatchUrl = `${cysrConfig().api_base_url}notification/topic/${notificationModalData.usernotificationtopic_topic}/`;
                      fetch(topicsPatchUrl, {
                        body: JSON.stringify({
                          usernotificationtopic_severity: newValue.value,
                        }),
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                          "content-type": "application/json",
                        },
                        method: "PATCH",
                      })
                        .then((response) => {
                          if (response.ok) {
                            response.json()
                              .then((responseData) => {
                                console.log("responseData")
                                console.log(responseData)
                              })
                          } else {
                            return response.json().then(err => {
                              throw JSON.stringify(err)
                            })
                          }
                        })
                        .catch((err) => {
                          console.error("enabled err:", err);
                          console.error(err);
                        });
                    }}
                      components={{ ChoiceDropdownIndicator, Option: CustomSelectOption, SingleValue: CustomSelectValue }}
                    />
                    <div style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "8px",
                    }}
                    >
                      <MDButton style={cysrConfig().button_style} size="medium" onClick={
                        () => {
                          const topicsPatchUrl = `${cysrConfig().api_base_url}notification/topic/${notificationModalData.usernotificationtopic_topic}/`;
                          fetch(topicsPatchUrl, {
                            /*
                            body: JSON.stringify({
                              usernotificationtopic_enabled: notificationModalDataReset.usernotificationtopic_enabled,
                              usernotificationtopic_severity: notificationModalDataReset.usernotificationtopic_severity
                            }),
                            */
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                              "content-type": "application/json",
                            },
                            method: "DELETE",
                          })
                            .then((response) => {
                              if (response.ok) {
                                response.json()
                                  .then((responseData) => {
                                    enqueueSnackbar(
                                      <div style={{ display: "flex", flexDirection: "column" }}>
                                        <MDTypography variant="caption" style={{ fontWeight: "600" }}>Reset successfully</MDTypography>
                                        <MDTypography variant="caption">Reloading shortly..</MDTypography>
                                      </div>, {
                                      variant: 'success',
                                      persist: false
                                    })
                                    window.location.reload()
                                  })
                              } else {
                                return response.json().then(err => {
                                  throw JSON.stringify(err)
                                })
                              }
                            })
                            .catch((err) => {
                              console.error("enabled err:", err);
                              console.error(err);
                              enqueueSnackbar(
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                  <MDTypography variant="caption" style={{ fontWeight: "600" }}>Updated failed</MDTypography>
                                  <MDTypography variant="caption">Notifications for this company couldn't be updated</MDTypography>
                                </div>, {
                                variant: 'error',
                                persist: false
                              })
                            });
                        }
                      }
                      >
                        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().exit.icon.svg }} />
                        Reset to default
                      </MDButton>
                      <MDButton style={cysrConfig().button_style} size="medium" onClick={handleClose}
                      >
                        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
                        Confirm and close
                      </MDButton>
                    </div>
                  </>
                }
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </div>
    </Dialog> : null
}
export default class ButtonActions extends Component {
  state = { isOpen: false, value: undefined, showNotificationModal: false };
  closeNotificationModal = () => {
    this.setState((state) => ({ showNotificationModal: false }));
  };
  toggleOpen = () => {
    this.setState((state) => ({ isOpen: !state.isOpen }));
  };
  onSelectChange = (value) => {
    // console.log(value)
    if (value.onClick === "manage_notifications") {
      // notification modal s
      this.setState((state) => ({ showNotificationModal: !state.showNotificationModal }));
      // notification modal e
    } else {
      triggerAction(value.onClick);
    }
    this.toggleOpen();
    this.setState({ value });
  };
  render() {
    const { features } = this.props;
    let stateOptions = []
    /*
    there is new page for reports now
    if (cysrConfig().feature_available("company_report_en", this.props.features)) {
      stateOptions.push(
        {
          label: "Generate report",
          color: "info",
          value: "Generate report",
          onClick: "go_to_report",
          key: 0,
          icon: cysrConfig().report.icon.svg
        }
      );
    }
    if (cysrConfig().feature_available("company_report_it", this.props.features)) {
      stateOptions.push(
        {
          label: "Generate report (IT)",
          color: "info",
          value: "Generate report (IT)",
          onClick: "go_to_report_it",
          key: 20,
          icon: cysrConfig().report.icon.svg
        }
      );
    }
    if (cysrConfig().feature_available("company_report_de", this.props.features)) {
      stateOptions.push(
        {
          label: "Generate report (DE)",
          color: "info",
          value: "Generate report (DE)",
          onClick: "go_to_report_de",
          key: 21,
          icon: cysrConfig().report.icon.svg
        }
      );
    }
    if (cysrConfig().feature_available("company_report_fr", this.props.features)) {
      stateOptions.push(
        {
          label: "Generate report (FR)",
          color: "info",
          value: "Generate report (FR)",
          onClick: "go_to_report_fr",
          key: 22,
          icon: cysrConfig().report.icon.svg
        }
      );
    }
    if (cysrConfig().feature_available("company_cti_report", this.props.features)) {
      stateOptions.push(
        {
          label: "Generate CTI report",
          color: "info",
          value: "Generate CTI report",
          onClick: "go_to_report_cti",
          key: 23,
          icon: cysrConfig().report.icon.svg
        }
      );
    }
    */
    if (features["CYSR/THIRDPARTY"] && features["CYSR/THIRDPARTY"] != "false") {
      stateOptions.push(
        {
          label: "View third parties",
          color: "info",
          value: "View third parties",
          onClick: "go_to_third_parties",
          key: 10,
          icon: cysrConfig().go.icon.svg
        }
      )
    }
    // go_reports s
    stateOptions.push(
      {
        label: "Go to Reports",
        color: "info",
        value: "Go to Reports",
        onClick: "go_reports",
        key: 22,
        icon: cysrConfig().go.icon.svg
      }
    );
    // go_reports e
    // if (cysrConfig().feature_available("company_archive", this.props.features)) {
    if (features["company_archive"] && features["company_archive"] != "false") {
      stateOptions.push(
        {
          label: "Archive company",
          color: "info",
          value: "Archive",
          onClick: "toggle_archive",
          key: 30,
          icon: cysrConfig().archive.icon.svg
        }
      )
    }
    // if (cysrConfig().feature_available("company_assets_clean", this.props.features)) {
    if (features["company_assets_clean"] && features["company_assets_clean"] != "false") {
      stateOptions.push(
        {
          label: "Company clean",
          color: "info",
          value: "Clean",
          onClick: "do_clean",
          key: 40,
          icon: cysrConfig().remove.icon.svg
        }
      )
    }
    //if (cysrConfig().feature_available("company_assets_reset", this.props.features)) {
    if (features["company_assets_reset"] && features["company_assets_reset"] != "false") {
      stateOptions.push(
        {
          label: "Company reset",
          color: "info",
          value: "Reset",
          onClick: "do_reset",
          key: 50,
          icon: cysrConfig().remove.icon.svg
        }
      )
    }
    // company subscription s
    if (localStorage.getItem("cysr_mydata") &&
      JSON.parse(localStorage.getItem("cysr_mydata")) &&
      JSON.parse(localStorage.getItem("cysr_mydata")).subscriptions &&
      JSON.parse(localStorage.getItem("cysr_mydata")).subscriptions &&
      JSON.parse(localStorage.getItem("cysr_mydata")).subscriptions) {
      // controllo permessi manage subscriptions come in profile
      if (JSON.parse(localStorage.getItem("cysr_mydata")).contractors && (Object.keys(JSON.parse(localStorage.getItem("cysr_mydata")).contractors).length > 0 || (
        JSON.parse(localStorage.getItem("cysr_mydata")).contractors.length && JSON.parse(localStorage.getItem("cysr_mydata")).contractors.length > 0
      ))) {
        stateOptions.push({
          label: "Manage Company Subscription",
          color: "info",
          value: "Subscription",
          onClick: "go_to_subscription",
          key: 60,
          icon: cysrConfig().go.icon.svg
        }
        )
      }
    }
    // company subscription e
    if (cysrConfig().feature_available("company_manage_notifications", this.props.features)) {
      // if (features["company_manage_notifications"]) {
      stateOptions.push(
        {
          label: "Edit Notifications",
          color: "info",
          value: "Edit Notifications",
          onClick: "manage_notifications",
          key: 70,
          icon: cysrConfig().edit.icon.svg
        }
      )
    }
    // edit company s
    stateOptions.push(
      {
        label: "Edit Company info",
        color: "info",
        value: "Edit Company info",
        onClick: "edit_company_info",
        key: 80,
        icon: cysrConfig().edit.icon.svg
      }
    )
    // edit company e
    const { isOpen, value } = this.state;
    return (
      <>
        <Dropdown
          isSearchable={false}
          searchable={false}
          isOpen={isOpen}
          onClose={this.toggleOpen}
          target={
            <ClickAwayListener onClickAway={() => {
              isOpen && this.toggleOpen()
            }}>
              <MDButton
                // onFocusVisible={isOpen}
                style={cysrConfig().button_style}
                sx={{ marginRight: 14 }}
                size="medium"
                onClick={this.toggleOpen}
              >
                <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().more_actions.icon.svg }} />
                Actions
                <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().more_actions.expand_icon.svg }} />
              </MDButton>
            </ClickAwayListener>
          }
        >
          <Select
            searchable={false}
            isSearchable={false}
            autoFocus
            backspaceRemovesValue={false}
            components={{ DropdownIndicator, IndicatorSeparator: null, Option: CustomSelectOption }}
            // controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            menuIsOpen
            onChange={this.onSelectChange}
            options={stateOptions}
            styles={selectStyles}
            tabSelectsValue={false}
            value={value}
          />
        </Dropdown>
        {this.state.showNotificationModal && <NotificationsModal closeState={this.closeNotificationModal} />}
      </>
    );
  }
}

// styled components

const Menu = (props) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      css={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
      }}
      {...props}
    />
  );
};
const Blanket = (props) => (
  <div
    css={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({
  children,
  isOpen,
  target,
  onClose,
}) => (
  <div css={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div css={{ color: colors.neutral20, height: 20, width: 20 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);

const ChoiceDropdownIndicator = () => (
  <div style={{ color: "#0D918F", height: 20, width: 20, display: "flex", alignItems: "center" }}>
    <Svg>
      <path d="M10 13.75L3.75 7.5L4.625 6.625L10 12L15.375 6.625L16.25 7.5L10 13.75Z" fill="currentColor" />
    </Svg>
  </div>
);
/* eslint-disable */