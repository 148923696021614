/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
import { Base64 } from 'js-base64';
import React, { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Collapse from "@mui/material/Collapse";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CysrStepper from "layouts/cysr/components/CysrStepper";


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import DataTable from "layouts/cysr/components/DataTable";
import { useLocation } from "react-router-dom";

import { format } from 'date-fns';
import InfoButton from "layouts/cysr/components/infoButton";

import CysrTableCompanies from "layouts/cysr/components/CysrTable/companies";
import CysrTableUsers from "layouts/cysr/components/CysrTable/users";
import { useMaterialUIController } from "context";
import { useSnackbar } from 'notistack';

// import checksData from "layouts/cysr/components/assetChecksDataTableData";
// import checksData from "layouts/cysr/components/lastchecksDataTableData";
// new
// import companiesData from "layouts/cysr/contract/companiesData";
// import usersData from "layouts/cysr/contract/usersData";
// import BubbleChartLabel from "layouts/cysr/asset/BubbleChartLabel";
// import BubbleChartValue from "layouts/cysr/asset/BubbleChartValue";
import Subscriptions from "layouts/cysr/profile/components/Subscriptions";
import CysrTableSubscriptions from "layouts/cysr/components/CysrTable/subscriptions";

import cysrConfig from "layouts/cysr/config";
import Loader from "layouts/cysr/components/Loader";


import MDInput from "components/MDInput";
import {
  // countries,
  countriesCodes
} from "layouts/cysr/components/CYSRCountries"
// const selectedCompanyID = localStorage.getItem("cysr_useraccountrole_selected");
let accountUUID = 0;
/*
if(window.location.pathname.indexOf("/account/") > -1){
  accountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/account/") + "/account/".length,
    window.location.pathname.lastIndexOf("/")
  );
}
*/
if (window.location.pathname.indexOf("/company-subscription") > -1) {
  if (window.location.hash !== undefined) {
    accountUUID = window.location.hash.replace("#", "");
  }
}
// ms
const App = function app() {
  const [controller] = useMaterialUIController();

  function boxSubscriptionHolders(subscriptionHolder, subscriptionBillingContact = false) {
    return <Grid item xs={12} container spacing={1}>
      {(
        (subscriptionBillingContact && subscriptionBillingContact.contractor_name)
        ||
        (subscriptionHolder && subscriptionHolder.contractor_name)
      ) &&
        <MDTypography mt={3} ml={1} color="text" variant="body2" style={{ fontSize: "14px", width: "100%" }}>
          Subscriber details of the subscription:
        </MDTypography>}
      {subscriptionBillingContact && subscriptionBillingContact.contractor_name &&
        <Grid item xs={6}>
          <div
            style={{
              border: "1px solid rgb(91 148 159)",
              padding: "12px",
              background: `${darkMode ? "#131516" : "#e6f6f9"}`,
              borderRadius: "5px",
              height: "100%",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <MDTypography variant="caption" mb={1} fontWeight="bold" color="info">
              Subscriber
            </MDTypography>
            <MDTypography variant="button">
              {subscriptionBillingContact.contractor_name}
            </MDTypography>
            <MDTypography variant="text">
              {subscriptionBillingContact.contractor_vat_name}
            </MDTypography>
            {subscriptionBillingContact.contractor_vat_country_code ?
              <MDTypography variant="button">
                {`${subscriptionBillingContact.contractor_vat_country_code} ${subscriptionBillingContact.contractor_vat ? subscriptionBillingContact.contractor_vat : subscriptionBillingContact.contractor_vat_number}`}
              </MDTypography>
              :
              <MDTypography variant="button">
                {subscriptionBillingContact.contractor_vat_country}
              </MDTypography>
            }
            <MDTypography variant="button">
              {subscriptionBillingContact.contractor_vat_address}
            </MDTypography>
            <MDTypography variant="button">
              {subscriptionBillingContact.contractor_vat_city}
            </MDTypography>
          </div>
        </Grid>}
      {subscriptionHolder && subscriptionHolder.contractor_name &&
        <Grid item xs={6}>
          <div
            style={{
              border: "1px solid rgb(91 148 159)",
              padding: "12px",
              background: `${darkMode ? "#131516" : "#e6f6f9"}`,
              borderRadius: "5px",
              height: "100%",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <MDTypography variant="caption" mb={1} fontWeight="bold" color="info">
              Holder/Beneficiary
            </MDTypography>
            <MDTypography variant="button">
              Name: <strong>{subscriptionHolder.contractor_name}</strong>
            </MDTypography>
            {subscriptionHolder.contractor_vat_country_code && subscriptionHolder.contractor_vat_country_code != "XX" && subscriptionHolder.contractor_vat_name &&
              <MDTypography variant="button">VAT Name: <MDTypography variant="text">
                <strong>{subscriptionHolder.contractor_vat_name}</strong>
              </MDTypography>
              </MDTypography>
            }
            {subscriptionHolder.contractor_vat_country_code && subscriptionHolder.contractor_vat_country_code != "XX" ?
              <MDTypography variant="button">
                VAT Number: <strong>{`${subscriptionHolder.contractor_vat_country_code} ${subscriptionHolder.contractor_vat}`}</strong>
              </MDTypography>
              :
              <MDTypography variant="button">
                {subscriptionHolder.contractor_vat_country}
              </MDTypography>
            }
            {subscriptionHolder.contractor_vat_country_code && subscriptionHolder.contractor_vat_country_code != "XX" &&
              [
                <MDTypography variant="button">
                  {subscriptionHolder.contractor_vat_address}
                </MDTypography>,
                <MDTypography variant="button">
                  {subscriptionHolder.contractor_vat_city}
                </MDTypography>
              ]
            }
          </div>
        </Grid>}
    </Grid>;
  }
  function boxSubscriptionModules(subscriptionModulesObject) {
    /*
    {
    "CYSR": {
        "subscription_name": "NFR",
        "subscription_type": "TIME",
        "company_max": "100000",
        "recheck_period_days": "30",
        "asset_per_company_max": "500",
        "manual_asset_per_company_max": "10",

        "leak_domain_max": "5",
        "botnet_domain_max": "5",
    }
    }
    */
    let modules = [];
    for (const key in subscriptionModulesObject) {
      if (Object.hasOwnProperty.call(subscriptionModulesObject, key)) {
        const element = subscriptionModulesObject[key];
        element.module_name = key;
        modules.push(element)
      }
    }
    console.warn(modules)
    return <Grid item xs={12} container spacing={1}>
      <MDTypography mt={3} ml={1} variant="body2" style={{ fontSize: "14px", width: "100%" }}>
        Modules included in the subscription:
      </MDTypography>
      {modules && modules.map((mod, x) =>
        /*
          {
              "module_name": "CYSR_TIME_SME",
              "module_description": "CYSR Module for Small&Medium Enterprises",
              "module_group": "CYSR",
              "module_order": 1,
              "module_configuration": {
                  "company_max": "1",
                  "recheck_period_days": "15",
                  "asset_per_company_max": "200",
                  "manual_asset_per_company_max": "2"
              }
          }
          ,
          {
              "module_name": "CTI_SME",
              "module_description": "CTI_SME",
              "module_group": "CTI",
              "module_order": 1,
              "module_configuration": {
                  "leak_domain_max": "5",
                  "botnet_domain_max": "5",
                  "recheck_period_days": "7"
              }
          }..
        */
        <Grid key={mod && mod.module_name ? mod.module_name : x} item xs={6}>
          <div
            cysr_module_name={mod.module_name}
            style={{
              border: "1px solid rgb(91 148 159)",
              padding: "12px",
              background: `${darkMode ? "#131516" : "#e6f6f9"}`,
              borderRadius: "5px",
              height: "100%",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <MDTypography variant="caption" mb={1} fontWeight="bold" color="info">
              {mod.module_name}
            </MDTypography>
            <MDTypography variant="text">
              {mod.module_description}
            </MDTypography>
            {
              // CYSR:
            }
            {mod && mod.company_max &&
              <MDTypography variant="button">
                Max companies: <strong>{mod.company_max}</strong>
              </MDTypography>}
            {mod && mod.thirdparty_max &&
              <MDTypography variant="button">
                Max third parties: <strong>{mod.thirdparty_max}</strong>
              </MDTypography>}
            {mod && mod.recheck_period_days &&
              <MDTypography variant="button">
                Recheck period (days): <strong>{mod.recheck_period_days}</strong>
              </MDTypography>}
            {mod && mod.asset_per_company_max &&
              <MDTypography variant="button">
                Max assets per company: <strong>{mod.asset_per_company_max}</strong>
              </MDTypography>}
            {mod && mod.manual_asset_per_company_max &&
              <MDTypography variant="button">
                Max manual asset per company: <strong>{mod.manual_asset_per_company_max}</strong>
              </MDTypography>}
            {
              // CTI:
            }
            {mod && mod.leak_domain_max &&
              <MDTypography variant="button">
                Max leak domain: <strong>{mod.leak_domain_max}</strong>
              </MDTypography>}
            {mod && mod.botnet_domain_max &&
              <MDTypography variant="button">
                Max botnet domain: <strong>{mod.botnet_domain_max}</strong>
              </MDTypography>}
          </div>
        </Grid>
      )}
    </Grid>;
  }
  //snackBar s
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // add action to an individual snackbar
  const action = snackbarId => (
    <>
      <MDButton onClick={() => { closeSnackbar(snackbarId) }} style={cysrConfig().button_style} size="medium">
        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().close.icon.svg }} />
        Dismiss
      </MDButton>
    </>
  );
  //snackBar e
  const { darkMode } = controller;

  const [isLoading, setLoading] = useState(true);
  // filter test s
  const params = new URLSearchParams(window.location.search);
  let activeTags = [];
  let userActiveTags = [];
  // user filtered:
  try {
    activeTags = params.get('t') && (decodeURI(params.get('t'))) && Base64.decode(decodeURI(params.get('t'))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('t')))) : [];
  } catch (error) {
    console.log("Error in setting active tags:")
    console.log(error)
  }
  try {
    userActiveTags = params.get('u') && (decodeURI(params.get('u'))) && Base64.decode(decodeURI(params.get('u'))) && JSON.parse(Base64.decode(decodeURI(params.get('u')))) && JSON.parse(Base64.decode(decodeURI(params.get('u')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('u')))) : [];
  } catch (error) {
    console.log("Error in setting active tags:")
    console.log(error)
  }
  // filter test e
  const location = useLocation();

  let subscriptionUUID = 0;
  // extract id
  if (window.location.hash !== undefined) {
    subscriptionUUID = window.location.hash.replace("#", "");
  }

  const [defaultSubscriptionID, setDefaultSubscriptionID] = useState(subscriptionUUID);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedItemForDialog, setSelectedItemForDialog] = useState(null);


  const removeSubscription = () => {
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  const [expandedTable, setExpandedTableOpen] = useState(false);
  const [views, setViews] = useState([]);

  const [createOpen, setOpen] = useState(false);
  // asset creation states
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userTech, setUserTech] = useState(false);
  const [userAdmin, setUserAdmin] = useState(false);
  const [userCreationLoading, setUserCreationLoading] = useState(false);

  // add asset creation load
  const btnCreateUserDefaultString = "Send the invitation email";
  const [btnCreateUserString, setStringBtnCreateUser] = useState(btnCreateUserDefaultString);

  function deleteSubscription() {
    // /subscription/<uuid>
    // subscription/id_sub
    const callUrl = `${cysrConfig().api_base_url}subscription/${subscriptionUUID}/`;

    fetch(callUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      },
      method: "DELETE"
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("subscription");
        console.log(data);
        if (data.ErrorMessage) {
          throw ({
            ErrorMessage: data.ErrorMessage,
            ErrorDetail: data.ErrorDetail
          });
        }
      })
      .catch((aerr) => {
        console.error(aerr)
        const err = aerr;
        let snackErrorTitle = "Error";
        let snackErrorDetail = "Some error happened";
        if (err && err.ErrorMessage) {
          snackErrorTitle = err.ErrorMessage;
          snackErrorDetail = err.ErrorDetail;
        } else if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
          snackErrorTitle = JSON.parse(err).ErrorMessage;
          snackErrorDetail = JSON.parse(err).ErrorDetail;
        } else if (JSON.parse(err) && JSON.parse(err).error) {
          // snackErrorTitle = "Error";
          snackErrorDetail = JSON.parse(err).error;
        }
        enqueueSnackbar(
          <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <MDTypography variant="caption" style={{ fontWeight: "600" }}>{snackErrorTitle}</MDTypography>
            <MDTypography variant="caption">{snackErrorDetail}</MDTypography>
          </div>, {
          action,
          variant: 'error',
          persist: true
        })
      });
  }

  const removeSubscriptionConfirmed = () => {
    deleteSubscription();
  }

  function inviteUser(event) {
    event.preventDefault();
    setStringBtnCreateUser('Sending invite to tech user...')
    console.log(userAdmin) // false/true
    console.log(userTech) // false/true
    // if (userEmail && userEmail != "" && userName && userName != "" && userLastName && userLastName != "") {
    if (userEmail && userEmail != "") {
      // invite
      // /subscription/<uuid>/user/ask/ POST <email> 
      // const inviteUserUrl = `${cysrConfig().api_base_url}user/newsubuser/`;
      const inviteUserUrl = `${cysrConfig().api_base_url}subscription/${subscriptionUUID}/user/ask/`;
      const datainviteUser = {
        invited_email: userEmail,
      };
      console.log(datainviteUser);
      fetch(inviteUserUrl, {
        body: JSON.stringify(datainviteUser),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
          "content-type": "application/json",
        },
        method: "POST",
      })
        .then((response) => {
          console.log(response);
          if (response.status === 500) {
            enqueueSnackbar(
              <div style={{ display: "flex", flexDirection: "column" }}>
                <MDTypography variant="caption" style={{ fontWeight: "600" }}>Error</MDTypography>
                <MDTypography variant="caption">{response.statusText}</MDTypography>
              </div>, {
              action,
              variant: 'error',
              persist: true
            })
            setStringBtnCreateUser(btnCreateUserDefaultString)
            return false
          } else if (response.ErrorMessage) {
            throw ({
              ErrorMessage: response.ErrorMessage,
              ErrorDetail: response.ErrorDetail
            });
          } else {
            return response.json();
          }
        })
        .then((r) => {
          console.log(r);
          // refresh accounts s
          if (r && r.ResponseMetadata && r.ResponseMetadata.HTTPStatusCode && r.ResponseMetadata.HTTPStatusCode === 200) {
            enqueueSnackbar(
              <div style={{ display: "flex", flexDirection: "column" }}>
                <MDTypography variant="caption" style={{ fontWeight: "600" }}>Sent successfully</MDTypography>
                <MDTypography variant="caption">Invitation has been sent</MDTypography>
              </div>, {
              // action,
              variant: 'success',
              persist: false
            })
            let viewsWithNewUser = views;
            viewsWithNewUser.users.push(
              {
                "email": userEmail,
                "first_name": "",
                "last_name": "",
                "invitation_date": "now"
              }
            )
            setViews(viewsWithNewUser);
          } else if (r.ErrorMessage) {
            throw ({
              ErrorMessage: r.ErrorMessage,
              ErrorDetail: r.ErrorDetail
            });
          } else {
            enqueueSnackbar(
              <div style={{ display: "flex", flexDirection: "column" }}>
                <MDTypography variant="caption" style={{ fontWeight: "600" }}>{r.Message}</MDTypography>
                <MDTypography variant="caption">{r.Detail}</MDTypography>
              </div>, {
              // action,
              variant: 'warning',
              persist: false
            })
          }
          setStringBtnCreateUser(btnCreateUserDefaultString)
        })
        .catch((err) => {
          setStringBtnCreateUser(btnCreateUserDefaultString)
          let snackErrorTitle = "Invite error";
          let snackErrorDetail = "Invite could not be sent at this time";
          if (err && err.ErrorMessage) {
            snackErrorTitle = err.ErrorMessage;
            snackErrorDetail = err.ErrorDetail;
          } else if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
            snackErrorTitle = JSON.parse(err).ErrorMessage;
            snackErrorDetail = JSON.parse(err).ErrorDetail;
          } else if (JSON.parse(err) && JSON.parse(err).error) {
            // snackErrorTitle = "Error";
            snackErrorDetail = JSON.parse(err).error;
          }
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>{snackErrorTitle}</MDTypography>
              <MDTypography variant="caption">{snackErrorDetail}</MDTypography>
            </div>, {
            action,
            variant: 'error',
            persist: true
          })
          console.log("err...");
          console.log(err);
        });
    } else {
      // ask to fill fields
      setStringBtnCreateUser(btnCreateUserDefaultString)
      let snackErrorTitle = "Invite error";
      let snackErrorDetail = "Email missing";
      if (err && err.ErrorMessage) {
        snackErrorTitle = err.ErrorMessage;
        snackErrorDetail = err.ErrorDetail;
      } else if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
        snackErrorTitle = JSON.parse(err).ErrorMessage;
        snackErrorDetail = JSON.parse(err).ErrorDetail;
      } else if (JSON.parse(err) && JSON.parse(err).error) {
        // snackErrorTitle = "Error";
        snackErrorDetail = JSON.parse(err).error;
      }
      enqueueSnackbar(
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <MDTypography variant="caption" style={{ fontWeight: "600" }}>{snackErrorTitle}</MDTypography>
          <MDTypography variant="caption">{snackErrorDetail}</MDTypography>
        </div>, {
        action,
        variant: 'error',
        persist: true
      })
    }
  }
  function changingSubscription(sid) {
    if (defaultSubscriptionID !== sid) {
      // /{version}/account/{account_req_uuid}/subscription/change/
      const changeSubUrl = `${cysrConfig().api_base_url}account/${accountUUID}/subscription/change/`;
      const changeSubJSON = JSON.stringify({
        subscription_uuid_from: defaultSubscriptionID,
        subscription_uuid_to: sid
      });
      console.warn("changeSubJSON")
      console.warn(changeSubJSON)
      fetch(changeSubUrl, {
        body: changeSubJSON,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
          "content-type": "application/json",
        },
        method: "PATCH",
      })
        .then((response) => {
          if (response.ok) {
            // window.location.reload();
            return response.json();
          } else {
            console.error(response);
            return response.json().then(err => {
              throw JSON.stringify(err);
            });
            // setSelectAssetSrcLoading(false)
            // return response.json()
          }
        })
        .then((responseData) => {
          if (responseData.ErrorMessage) {

          } else {
            enqueueSnackbar(
              <div style={{ display: "flex", flexDirection: "column" }}>
                <MDTypography variant="caption" style={{ fontWeight: "600" }}>{responseData.Message ? responseData.Message : "Subscription changed"}</MDTypography>
                <MDTypography variant="caption">{responseData.Detail ? responseData.Detail : "Subscription for the company was succesfully changed"}</MDTypography>
              </div>, {
              // action,
              variant: 'success',
              persist: false
            })
            setDefaultSubscriptionID(sid);
            enqueueSnackbar(
              <div style={{ display: "flex", flexDirection: "column" }}>
                <MDTypography variant="caption" style={{ fontWeight: "600" }}>Reloading..</MDTypography>
                <MDTypography variant="caption">The page will reload shortly</MDTypography>
              </div>, {
              // action,
              variant: 'info'
            }
            )
            window.location.reload()
          }
        })
        .catch((err) => {
          console.log("err1:", err);
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>{JSON.parse(err).ErrorMessage}</MDTypography>
              <MDTypography variant="caption">{JSON.parse(err).ErrorDetail}</MDTypography>
            </div>, {
            action,
            variant: 'error',
            persist: true
          });
          console.log(err);
        });
    } else {
      enqueueSnackbar(
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <MDTypography variant="caption" style={{ fontWeight: "600" }}>Already active</MDTypography>
          <MDTypography variant="caption">This subscription is already the selected one</MDTypography>
        </div>, {
        action,
        variant: 'warning',
        persist: true
      })
    }
  }
  if (accountUUID) {
    // get subscriptions options to use in radio
    useEffect(() => {
      setLoading(true);
      fetch(`${cysrConfig().api_base_url}account/${accountUUID}/subscription/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
      })
        .then((myresponse) => myresponse.json())
        .then((subData) => {

          // console.log("heeree subData")
          // console.log(subData)

          setDefaultSubscriptionID(subData.current_subscription.subscription_uuid);

          let subUsers = subData.current_subscription.users;
          if (subData.current_subscription.pending_users) {
            subData.current_subscription.pending_users.forEach(ele => {
              subUsers.push(ele)
            });
          }
          let subAvailableSubs = {};
          subData.available_subscriptions.forEach(subEl => {
            subEl["subscription_billingcontact"] = subEl["subscription_billingcontact"]["contractor_name"];
            subEl["subscription_holder"] = subEl["subscription_holder"]["contractor_name"];
            subAvailableSubs[subEl.subscription_uuid] = subEl;
          });
          console.warn(subAvailableSubs);
          setViews({
            // accounts_role: JSON.parse('[{"account_uuid":"f0872167-8c11-49f2-a8cb-005abe02720e","account_name":"test1","contractor_subscription":"Engineering Spa - MSSP","account_data_national_id":"111111111","account_vat_country_code":"XX","account_vat_address":null,"account_vat_city":null,"account_vat_name":null,"account_oldest_check_time_on_manual_asset":null,"account_last_ratingACC_datetime":null,"account_last_rating_val":null}]'),
            accounts_role: subData.current_subscription.companies,
            subscription: subData.current_subscription,
            subscriptions_options: subAvailableSubs,
            subscriptions_available_options: subData.available_subscriptions,
            // used for pageheader
            account_subscription: subData.account,
            // users: data.users
            users: subUsers
          });
          setLoading(false);
        })
        .catch((ex) => {
          console.error("errore qui:")
          console.error(ex)
          setViews({
            accounts_role: null,
            subscription: null,
            subscriptions_options: null,
            subscriptions_available_options: [],
            users: null
          })
          setLoading(false);
        });
    }, [location]);
  } else {
    // subscription/id_sub
    const callUrl = `${cysrConfig().api_base_url}subscription/${subscriptionUUID}/`;
    useEffect(() => {
      setLoading(true);
      fetch(callUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            // window.location.reload();
            return response.json();
          } else {
            console.error(response);
            return response.json().then(err => {
              throw JSON.stringify(err);
            });
          }
        })
        .then((data) => {
          console.log("subscription");
          console.log(data);
          let subUsers = data.users;
          if (data.pending_users) {
            data.pending_users.forEach(ele => {
              subUsers.push(ele)
            });
          }
          setViews({
            // accounts_role: JSON.parse('[{"account_uuid":"f0872167-8c11-49f2-a8cb-005abe02720e","account_name":"test1","contractor_subscription":"Engineering Spa - MSSP","account_data_national_id":"111111111","account_vat_country_code":"XX","account_vat_address":null,"account_vat_city":null,"account_vat_name":null,"account_oldest_check_time_on_manual_asset":null,"account_last_ratingACC_datetime":null,"account_last_rating_val":null}]'),
            accounts_role: data.companies,
            subscription: data,
            // users: data.users
            users: subUsers
          });
          setLoading(false);
        })
        .catch((aerr) => {
          console.log(aerr)
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>{JSON.parse(aerr).ErrorMessage}</MDTypography>
              <MDTypography variant="caption">{JSON.parse(aerr).ErrorDetail}</MDTypography>
            </div>, {
            action,
            variant: 'error',
            persist: true
          });
          setViews({
            accounts_role: null,
            subscription: null,
            users: null
          })
          setLoading(false);
        });
    }, [location]);
  }
  return (
    <DashboardLayout>
      <DashboardNavbar cysr_company={(accountUUID) ? views.account_subscription : false} />
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <Card>
            <InfoButton cysr_info={cysrConfig().i("subscription", "header", "help")} cysr_info_link={cysrConfig().i("subscription", "header", "help_link")} />
            <MDBox py={5} px={3} fullWidth>
              {cysrConfig().i("subscription", "header", "title") && (
                <MDTypography variant="h5">{cysrConfig().i("subscription", "header", "title")}</MDTypography>
              )}
              {cysrConfig().i("subscription", "header", "sub_title") && (
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {cysrConfig().i("subscription", "header", "sub_title")}
                </MDTypography>
              )}
              {isLoading ?
                <Loader />
                : <MDBox pt={1} pb={2}>
                  {views && views.subscription &&
                    <Grid container flexDirection="column">
                      {
                        [
                          /*
                          // ora ci sono box s
                          {
                            title: "Holder",
                            text: `${(views.subscription.subscription_holder && views.subscription.subscription_holder.subscription_holder) ? views.subscription.subscription_holder.contractor_name : ""} ${(views.subscription.subscription_holder && views.subscription.subscription_holder.contractor_vat_name) ? views.subscription.subscription_holder.contractor_vat_name : ""}`
                          },
                          {
                            title: "Type",
                            text: (views.subscription && views.subscription.subscription_subscriptiontype && views.subscription.subscription_subscriptiontype.subscriptiontype_name) ? views.subscription.subscription_subscriptiontype.subscriptiontype_name : ""
                          },
                          // ora ci sono box e
                          */
                          {
                            title: "Status",
                            text: (views.subscription && views.subscription.subscription_status) ? views.subscription.subscription_status : ""
                          },
                          {
                            title: "Date start",
                            text: (views.subscription && views.subscription.subscription_start_datetime) ? format(new Date(views.subscription.subscription_start_datetime), 'dd/MM/yyyy') : ""
                          },
                          {
                            title: "Date end",
                            text: (views.subscription && views.subscription.subscription_end_datetime) ? format(new Date(views.subscription.subscription_end_datetime), 'dd/MM/yyyy') : ""
                          }
                        ].map(
                          (i) => (
                            <Grid item key={i} style={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              gap: "8px"
                            }}>
                              <MDTypography style={{ fontSize: ".9rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} variant="body2" color="text">
                                {i.title}:
                              </MDTypography>
                              {i.text &&
                                <MDTypography variant="button" color="text" fontWeight="bold">
                                  {i.text}
                                </MDTypography>
                              }
                            </Grid>
                          )
                        )
                      }
                      {views.subscription && views.subscription.subscription_holder && boxSubscriptionHolders(views.subscription.subscription_holder)}
                      {views.subscription && views.subscription.subscription_modules_detail && boxSubscriptionModules(views.subscription.subscription_modules_detail)}
                      {accountUUID
                        ? null
                        :
                        <Grid item mt={2} xs={12}>
                          <MDButton onClick={() => removeSubscription()} style={cysrConfig().button_style} size="medium">
                            <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().remove.icon.svg }} />
                            Remove subscription
                          </MDButton>

                          <Dialog
                            fullScreen={false}
                            open={dialogOpen}
                            onClose={handleClose}
                            aria-labelledby="remove-user-dialog-title"
                            style={{
                              background: darkMode ? 'rgba(0,0,0,0.25)' : 'rgba(255, 255, 255, 0.25)',
                              backdropFilter: "blur(12px)"
                            }}>
                            <div style={{ background: darkMode ? '#192328' : '#ffffff' }}>
                              <DialogTitle id="remove-user-dialog-title">
                                {"Confirm user removal?"}
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText>
                                  <MDTypography variant="body2">
                                    You are about to remove the subscription, sounds good?
                                  </MDTypography>
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <MDButton style={cysrConfig().button_style} size="medium" autoFocus onClick={handleClose}>
                                  Disagree
                                </MDButton>
                                <MDButton style={cysrConfig().button_style} size="medium" onClick={removeSubscriptionConfirmed} autoFocus>
                                  Agree
                                </MDButton>
                              </DialogActions>
                            </div>
                          </Dialog>
                        </Grid>
                      }
                    </Grid>
                  }
                  {accountUUID ?
                    null
                    :
                    <Grid container spacing={3}>
                      {false && <Grid item>
                        <MDButton onClick={() => alert("edit functionality will be added in future")} style={cysrConfig().button_style} size="medium">
                          <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().edit.icon.svg }} /> Edit details
                        </MDButton>
                      </Grid>
                      }
                      {false && <Grid item>
                        <MDButton onClick={() => alert("set default functionality will be added in future")} style={cysrConfig().button_style} size="medium">
                          <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().contract_default.icon.svg }} /> Set as default
                        </MDButton>
                      </Grid>
                      }
                    </Grid>
                  }
                </MDBox>
              }
            </MDBox>
          </Card>
        </Grid>
        {isLoading ?
          <Loader />
          : (accountUUID ?
            <Grid item xs={12}>
              {views.subscriptions_available_options &&
                <Card>
                  <InfoButton cysr_info={cysrConfig().i("subscription-switch", "header", "help")} cysr_info_link={cysrConfig().i("subscription", "header", "help_link")} />
                  <MDBox pt={5} px={3} fullWidth>
                    {cysrConfig().i("subscription-switch", "header", "title") && (
                      <MDTypography variant="h5">{cysrConfig().i("subscription-switch", "header", "title")}</MDTypography>
                    )}
                    {cysrConfig().i("subscription-switch", "header", "sub_title") && (
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        {cysrConfig().i("subscription-switch", "header", "sub_title")}
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox pt={3} pb={5} px={3} fullWidth>
                    <CysrTableSubscriptions data={
                      {
                        // search: params.get('s') ? params.get('s') : "",
                        hideCompleted: false,
                        tableTagsOptions: [],
                        nodes: views.subscriptions_available_options,
                        tableTagsActive: [],
                        isOptions: true,
                      }
                    }
                      radioChangeCallback={changingSubscription}
                      setDefaultSubscriptionID={setDefaultSubscriptionID}
                      defaultSubscriptionID={defaultSubscriptionID}
                    />
                  </MDBox>
                </Card>
              }
              {false &&
                <Subscriptions radioChangeCallback={changingSubscription} setDefaultSubscriptionID={setDefaultSubscriptionID} defaultSubscriptionID={defaultSubscriptionID} subscriptions={views.subscriptions_options ? views.subscriptions_options : []} textDescription="Change the company subscription" />
              }
            </Grid>
            :
            <>
              {views && views.accounts_role &&
                <Grid item xs={12}>
                  <Card>
                    <InfoButton cysr_info={cysrConfig().i("subscription", "companies_list", "help")} cysr_info_link={cysrConfig().i("subscription", "companies_list", "help_link")} />
                    <MDBox pt={5} px={3} fullWidth>
                      <div>
                        {cysrConfig().i("subscription", "companies_list", "title") && (
                          <MDTypography variant="h5">{cysrConfig().i("subscription", "companies_list", "title")}</MDTypography>
                        )}
                        {cysrConfig().i("subscription", "companies_list", "sub_title") && (
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {cysrConfig().i("subscription", "companies_list", "sub_title")}
                          </MDTypography>
                        )}
                      </div>
                    </MDBox>
                    {views.accounts_role && <MDBox py={5} px={3} fullWidth>
                      <CysrTableCompanies data={
                        {
                          isSubscription: subscriptionUUID,
                          search: params.get('s') ? params.get('s') : "",
                          hideCompleted: params.get('hc') ? true : false,
                          tableTagsOptions: [],
                          nodes: views.accounts_role,
                          tableTagsActive: activeTags,
                        }
                      }
                      />
                    </MDBox>
                    }
                    {false &&
                      <MDBox py={5} px={3} fullWidth>
                        {// views && views.users && views.users.length > 0 ? (
                          views ? (
                            <DataTable entriesPerPage={{ defaultValue: 50, entries: [25, 50, 100, 200, 400] }} table={companiesData(views)} canSearch />
                          ) : (
                            <MDTypography variant="button" color="text">
                              There are no companies related to this subscription yet.
                            </MDTypography>
                          )}
                      </MDBox>
                    }
                  </Card>
                </Grid>
              }
              <Grid item xs={12}>
                <Card>
                  <InfoButton cysr_info={cysrConfig().i("subscription", "add_a_user", "help")} cysr_info_link={cysrConfig().i("subscription", "add_a_user", "help_link")} />
                  <MDBox pt={5} px={3} fullWidth>
                    <MDBox mb={1} fullWidth>
                      <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <div>
                          {cysrConfig().i("subscription", "add_a_user", "title") && (
                            <MDTypography variant="h5">{cysrConfig().i("subscription", "add_a_user", "title")}</MDTypography>
                          )}
                          {cysrConfig().i("subscription", "add_a_user", "sub_title") && (
                            <MDTypography variant="button" color="text" fontWeight="regular">
                              {cysrConfig().i("subscription", "add_a_user", "sub_title")}
                            </MDTypography>
                          )}
                        </div>
                        <MDButton
                          aria-label="expand row"
                          onClick={() => setOpen(!createOpen)} style={cysrConfig().button_style} size="medium">
                          {createOpen ? <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} /> : <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />
                          }
                          Add a tech user
                        </MDButton>
                      </MDBox>
                      <Collapse in={createOpen} style={{ marginTop: ".5em" }} timeout="auto" unmountOnExit>
                        <div className={`${darkMode ? "dark" : "light"}`}>
                          <div className="mt-2 bg-zinc-100 dark:bg-zinc-900 rounded-xl border-zinc-100 dark:border-zinc-700 border-b border-t">
                            <form onSubmit={inviteUser}>
                              <CysrStepper darkMode isLoading={userCreationLoading} steps={
                                [
                                  {
                                    // label: 'Email address of the user',
                                    label: '',
                                    value: userEmail,
                                    content:
                                      <Grid container spacing={1}>
                                        <Grid item xs={6} lg={6}>
                                          <MDInput
                                            fullWidth
                                            defaultValue={userEmail}
                                            sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                                            onChange={(e) => {
                                              setTimeout(() => {
                                                if (userEmail != e.target.value) {
                                                  setUserEmail(e.target.value);
                                                }
                                              }, 200);
                                            }}
                                            size="small"
                                            type="email"
                                            label="Email address of the new tech user"
                                            id="c_email_val"
                                          />
                                        </Grid>
                                      </Grid>,
                                    /*
                                      },
                                      {
                                        label: 'Name of the user',
                                        value: `${userName} ${userLastName}`,
                                        content:
                                        <Grid container spacing={3}>
                                          <Grid item xs={12} sm={6}>
                                            <MDInput
                                              fullWidth
                                              label="First Name"
                                              defaultValue={userName}
                                              sx={{color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                                              onChange={(e) => {
                                                setTimeout(() => {
                                                  if(userName != e.target.value){
                                                    setUserName(e.target.value);
                                                  }
                                                }, 200);
                                              }}
                                              size="small"
                                              id="c_name_val"
                                            />
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <MDInput
                                              fullWidth
                                              label="Last Name"
                                              defaultValue={userLastName}
                                              sx={{color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                                              onChange={(e) => {
                                                setTimeout(() => {
                                                  if(userLastName != e.target.value){
                                                    setUserLastName(e.target.value);
                                                  }
                                                }, 200);
                                              }}
                                              size="small"
                                              id="c_lastname_val"
                                            />
                                          </Grid>
                                        </Grid>
                                      },
                                      {
                                        label: 'Roles of the user',
                                        value: userTech && userAdmin ? "Tech + Admin" : userTech && !userAdmin ? "Tech" : userAdmin ? "Admin" : "No permission",
                                        content:
                                        <Grid container spacing={1}>
                                          <Grid item>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={userTech}
                                                  onChange={()=>{
                                                    setUserTech(!userTech)}
                                                  }
                                                  value={userTech}
                                                  name="user_permissions"
                                                />
                                              }
                                              label="Tech"
                                            />
                                          </Grid>
                                          <Grid item>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={userAdmin}
                                                  onChange={()=>{
                                                    setUserAdmin(!userAdmin)}
                                                  }
                                                  value={userAdmin}
                                                  name="user_permissions"
                                                />
                                              }
                                              label="Admin"
                                            />
                                          </Grid>
                                        </Grid>,
                                    */
                                    submitIcon: "add",
                                    submitText: btnCreateUserString
                                  }
                                ]
                              } />
                            </form>
                          </div>
                        </div>
                      </Collapse>
                      {false && <Collapse in={createOpen} style={cysrConfig().collapse_style} timeout="auto" unmountOnExit>
                        <form onSubmit={inviteUser} py={3} style={{ display: "block", padding: ".5em 0" }}>
                          <Grid container spacing={1}>
                            <Grid item xs={3} lg={2}>
                              <Autocomplete
                                defaultValue="IT"
                                size="small"
                                // label="generalasset_type"
                                id="c_account_vat_code"
                                options={countriesCodes}
                                renderInput={(params) => <MDInput
                                  size="small" label="VAT Country" {...params} />}
                              />
                            </Grid>
                            <Grid item xs={9} lg={4}>
                              <MDInput
                                label="Subscription VAT"
                                id="c_account_data_national_id"
                                size="small"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                              <MDInput
                                fullWidth
                                size="small"
                                label="Subscription name"
                                id="c_account_name"
                              />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                              {
                                /*
                              }
                              <MDButton fullWidth size="small" color="dark" type="submit" style={{height:"100%"}}>
                                Create Contract
                              </MDButton>
                              {
                                */
                              }

                              <MDButton type="submit" {...(btnCreateUserString != btnCreateUserDefaultString && { disabled: true })} style={cysrConfig().button_style} size="medium">
                                {btnCreateUserString != btnCreateUserDefaultString ? (
                                  /*
                                  <div style={{height: "20px", width: "20px", marginRight: "8px"}}><Loader /></div>
                                  */
                                  <span style={cysrConfig().button_icon_left_style} className="spin" dangerouslySetInnerHTML={{ __html: cysrConfig().loading.icon.svg }} />
                                ) : (
                                  <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().add.icon.svg }} />
                                )}{btnCreateUserString}</MDButton>

                            </Grid>
                          </Grid>
                        </form>
                      </Collapse>
                      }

                    </MDBox>
                  </MDBox>
                  <InfoButton cysr_info={cysrConfig().i("subscription", "users", "help")} cysr_info_link={cysrConfig().i("subscription", "users", "help_link")} />
                  <MDBox pb={5} px={3} fullWidth>
                    {false &&
                      // views && views.users && views.users.length > 0 ? (
                      views && (
                        <DataTable entriesPerPage={{ defaultValue: 50, entries: [25, 50, 100, 200, 400] }} table={usersData(views)} canSearch />
                      )}
                    {views && views.users ?
                      <MDBox pt={5} px={0} fullWidth>
                        <CysrTableUsers data={
                          {
                            subscription: views.subscription,
                            withStatus: true,
                            search: params.get('s') ? params.get('s') : "",
                            hideCompleted: params.get('hc') ? true : false,
                            tableTagsOptions: [],
                            nodes: views.users,
                            tableTagsActive: userActiveTags,
                          }
                        }
                        />
                      </MDBox>
                      : (
                        <MDTypography variant="button" color="text">
                          There are no users with access to this subscription yet.
                        </MDTypography>
                      )}
                  </MDBox>
                </Card>
              </Grid>
            </>
          )}
      </Grid>
    </DashboardLayout>
  );
};
export default App;
/* eslint-disable */
