/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import Sidenav from "layouts/cysr/profile/components/Sidenav";
import Header from "layouts/cysr/profile/components/Header";
import BasicInfo from "layouts/cysr/profile/components/BasicInfo";
import ChangePassword from "layouts/cysr/profile/components/ChangePassword";
import Authentication from "layouts/cysr/profile/components/Authentication";
import Subscriptions from "layouts/cysr/profile/components/Subscriptions";
import Channels from "layouts/cysr/profile/components/Channels";
import Topics from "layouts/cysr/profile/components/Topics";
import Notifications from "layouts/cysr/profile/components/Notifications";
import Sessions from "layouts/cysr/profile/components/Sessions";
import DeleteAccount from "layouts/cysr/profile/components/DeleteAccount";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
import Loader from "layouts/cysr/components/Loader";
import MDSnackbar from "layouts/cysr/components/MDSnackbar";
import { useState, useEffect } from "react";
import cysrConfig from "layouts/cysr/config";

function Settings() {
  const pageTitle = "Profile";
  document.title = `${pageTitle} - ${document.title.split(" - ")[1] ? document.title.split(" - ")[1] : document.title}`;

  const [profileData, setProfileData] = useState({});
  const [isLoading, setLoading] = useState(true);
  // snackbar s
  const [sshow, setSShow] = useState(false);
  const [msgDateTime, setMsgDateTime] = useState("Error");
  const [msgIcon, setMsgIcon] = useState("error");
  const [msgTheme, setMsgTheme] = useState("error");
  const [msgTitle, setMsgTitle] = useState("Profile not saved");
  // reacthtml msgContent:
  const [msgContent, setMsgContent] = useState(
    <></>
  )

  const defaultToggleSnackbar = () => {
    setSShow(!sshow);
  }
  const toggleSnackbar = (title = "Error", msg = "Some error happened", theme = "error", icon = "error", dateTime = "") => {
    setMsgDateTime(dateTime);
    setMsgIcon(icon);
    setMsgTheme(theme);

    setMsgTitle(title);
    setMsgContent(msg);
    // setSShow(!sshow);
    setSShow(true);
  }
  // snackbar e

  const [defaultSubscriptionID, setDefaultSubscriptionID] = useState("");
  // set useEffect this way to run only once
  useEffect(() => {
    if (localStorage.getItem("cysr_useraccountroles")) {
      // call table of asset + call table of checks
      fetch(`${cysrConfig().api_base_url}user/mydata/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
      })
        .then((response) => response.json())
        .then((responseProfile) => {
          console.log(responseProfile);
          if (responseProfile.code && responseProfile.code === "token_not_valid") {
            // remove localstorage and go to login
            const dMode = localStorage.getItem('cysr_dark_mode') ? localStorage.getItem('cysr_dark_mode') : "true";
            localStorage.clear();
            localStorage.setItem('cysr_dark_mode', dMode);
            window.location.href = `/cysr/login`;
            return
          }
          // check if subscription_status === "ENABLED" s
          if (responseProfile.user && responseProfile.user.subscription_default) {
            setDefaultSubscriptionID(responseProfile.user.subscription_default)
          }
          /*
          for (const skey in responseProfile.subscriptions) {
            if (Object.hasOwnProperty.call(responseProfile.subscriptions, skey)) {
              const ele = responseProfile.subscriptions[skey];
              if(ele.subscription_status === "ACTIVE"){
                // responseProfile.defaultSubscriptionID = skey;
                setDefaultSubscriptionID(skey);
                break;
              }
            }
          }
          */
          // check if subscription_status === "ENABLED" e
          setProfileData(responseProfile)
          setLoading(false);
        })
        .catch((err) => {
          console.log(err)
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);
  /*
  useEffect(() => {
    // this runs whenever profileData is updated.
    console.log(profileData)
  }, [profileData]);
  */

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav profileData={profileData} />
          </Grid>
          <Grid item xs={12} lg={9}>
            {isLoading ?
              <Loader />
              :
              <MDBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Header firstName={profileData.user.user_name} lastName={profileData.user.user_lastname} email={profileData.user.user_email} />
                  </Grid>
                  <Grid item xs={12}>
                    <BasicInfo firstName={profileData.user.user_name} lastName={profileData.user.user_lastname} toggleSnackbar={toggleSnackbar} />
                  </Grid>
                  <Grid item xs={12}>
                    <ChangePassword toggleSnackbar={toggleSnackbar} />
                  </Grid>
                  <Grid item xs={12}>
                    <Authentication toggleSnackbar={toggleSnackbar} />
                  </Grid>
                  <Grid item xs={12}>
                    {defaultSubscriptionID != "" &&
                      <Subscriptions setDefaultSubscriptionID={setDefaultSubscriptionID} defaultSubscriptionID={defaultSubscriptionID} subscriptions={profileData.subscriptions} toggleSnackbar={toggleSnackbar} />
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Channels />
                  </Grid>
                  <Grid item xs={12}>
                    <Topics />
                  </Grid>
                  {false &&
                    <>
                      <Grid item xs={12}>
                        <Notifications />
                      </Grid>
                      <Grid item xs={12}>
                        <Sessions />
                      </Grid>
                      <Grid item xs={12}>
                        <DeleteAccount />
                      </Grid>
                    </>
                  }
                </Grid>
              </MDBox>
            }
          </Grid>
        </Grid>
      </MDBox>
      <MDSnackbar
        color={msgTheme}
        icon={msgIcon}
        title={msgTitle}
        content={msgContent}
        dateTime={msgDateTime}
        open={sshow}
        close={defaultToggleSnackbar}
      />

    </DashboardLayout>
  );
}

export default Settings;
/* eslint-disable */