/* eslint-disable */
// prettier-ignore
// https://blog.logrocket.com/using-d3-js-v6-with-react/
import React, { lazy, Suspense } from "react";

// Dynamically import the ChartSolar component
const AsyncInnerComponent = lazy(() => import('./solar'));

export default function ChartSolarLoad(props) {
  return (
    <Suspense fallback={<div className="text-sm px-4">Loading Chart...</div>}>
      <AsyncInnerComponent {...props} />
    </Suspense>
  );
}