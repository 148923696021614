/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import { useLocation } from "react-router-dom";
import { RadarChart } from "@carbon/charts-react";

import(`@carbon/charts/styles-${localStorage.getItem("cysr_dark_mode") && localStorage.getItem("cysr_dark_mode") === "false" ? "g10" : "g90"}.css`);

import cysrConfig from "layouts/cysr/config";

// https://www.robinwieruch.de/react-component-to-pdf/
// import html2canvas from 'html2canvas';
// import { jsPDF } from 'jspdf';

/*
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});
*/

// Material Dashboard 2 React Examples
/* eslint-disable */
function Radar() {
  const location = useLocation();
  // Our State.
  const [views, setViews] = useState([]);
  const accountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/company/") + "/company/".length,
    window.location.pathname.lastIndexOf("/")
  );
  const dashboardCallUrl = `${cysrConfig().api_base_url}dashboard/mainpage/${accountUUID}/`;
  useEffect(() => {
    //setLoading(true);
    fetch(dashboardCallUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      },
    })
      .then((response) => response.json())
      .then((responseDashboardAccountData) => {
        setViews({
          radar: responseDashboardAccountData.assets.graphs.radar,
        });
        //setLoading(false);
      })
      .catch((err) => {
        console.log(err)
        //setLoading(false);
      });
    // very similar to handleDownloadPdf e
  }, [location]);
  return (
    <div style={{ background: "#ffffff", display: "block", textAlign: "center", position: "fixed", zIndex: "99999", left: "0", right: "0", top: "0", bottom: "0", padding: "2em", overflow: "auto" }}>
      <Grid item container spacing={3}>
        <Grid item container>
          <Grid item>
            {views && views.radar && views.radar.length > 0 && (
              <MDBox my={1}>
                <RadarChart
                  data={views.radar}
                  options={{
                    "title": false,
                    "radar": {
                      "axes": {
                        "angle": "type",
                        "value": "rating"
                      },
                      "alignment": "center"
                    },
                    "data": {
                      "groupMapsTo": "label"
                    },
                    "legend": {
                      "enabled": false,
                    },
                    // "height": "400px",
                    "height": "500px",
                    "width": "500px",
                    "toolbar": { "enabled": false },
                  }}
                />
              </MDBox>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
/* eslint-disable */
export default Radar;
