/* eslint-disable */
// prettier-ignore
import { Alert, AlertDescription, AlertTitle } from "layouts/cysr/components/ui/alert";
import cysrConfig, { process } from "layouts/cysr/config";
import MDButton from "components/MDButton";

export function ImportantMessage({ className, description, title, button = null }) {
    return (
        <Alert className={`pl-7 ${className}`}>
            <svg className="h-4 w-4 absolute left-4 top-4 translate-y-[-3px]" fill="currentColor" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="InfoSharp">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
            </svg>
            <AlertTitle>{title}</AlertTitle>
            {(description && description != "") &&
                <AlertDescription style={{
                    maxWidth: "calc(100% - 80px)"
                }}>{description}</AlertDescription>
            }
            {
                button && <div className="absolute right-4 top-0 bottom-0 my-auto flex items-center">
                    <MDButton style={cysrConfig().button_style} size="medium" type="button">
                        {button}
                    </MDButton>
                </div>
            }
        </Alert>
    );
}
