/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
// prettier-ignore
import { Base64 } from 'js-base64';
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
import InfoButton from "layouts/cysr/components/infoButton";
// import Footer from "examples/Footer";
import Loader from "layouts/cysr/components/Loader";
import Autocomplete from "@mui/material/Autocomplete";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import KeyboardArrowDownIcon from '@mui/icons-material/Add';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Card from "@mui/material/Card";


import { useMaterialUIController } from "context";
import { useSnackbar } from 'notistack';
// import DataTable from "layouts/cysr/components/DataTable";
// import subscriptionsData from "layouts/cysr/subscriptions/subscriptionsData";

// import { LineChart } from "@carbon/charts-react";
// import "@carbon/charts/styles-g100.css";


import cysrConfig from "layouts/cysr/config";
import CysrAutocomplete from "layouts/cysr/components/CysrAutocomplete";
import CysrStepper from "layouts/cysr/components/CysrStepper";

import CysrTableContractors from "layouts/cysr/components/CysrTable/contractors";
import {
  // countries,
  countriesCodes
} from "layouts/cysr/components/CYSRCountries"
function Subscribers() {
  if (localStorage.getItem("cysr_useraccountrole_selected")) {
    localStorage.removeItem("cysr_useraccountrole_selected")
    window.location.reload()
  }
  const [controller] = useMaterialUIController();
  //snackBar s
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // add action to an individual snackbar
  const action = snackbarId => (
    <>
      <MDButton onClick={() => { closeSnackbar(snackbarId) }} style={cysrConfig().button_style} size="medium">
        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().close.icon.svg }} />
        Dismiss
      </MDButton>
    </>
  );
  //snackBar e
  const { darkMode } = controller;

  const [subscriptionBillingContact, setSubscriptionBillingContact] = useState("");
  const [subscriptionHolderAsBillingContact, setSubscriptionHolderAsBillingContact] = useState(true);
  const [subscriptionHolder, setSubscriptionHolder] = useState("");
  const [subscriptionContractorsOptions, setSubscriptionContractorsOptions] = useState([]);
  const [subscriptionStartingDate, setSubscriptionStartingDate] = useState(new Date().toJSON().slice(0, 10));
  const [subscriptionCreationLoading, setSubscriptionCreationLoading] = useState(false);

  // Snackbars?
  const [errorTitle, setErrorTitle] = useState("Asset not created");
  const [errorMsg, setErrorMsg] = useState("Check that all the fields are compiled correctly and try again");
  // creation:
  const [contractorName, setContractorName] = useState("");
  const [contractorVatCountry, setContractorVatCountry] = useState("");
  const [contractorVatNumber, setContractorVatNumber] = useState("");
  const [contractorEmail, setContractorEmail] = useState("");

  /*
  const [userLastName, setUserLastName] = useState("");
  const [userTech, setUserTech] = useState(false);
  const [userAdmin, setUserAdmin] = useState(false);
  */
  const [userCreationLoading, setUserCreationLoading] = useState(false);
  const btnCreateUserDefaultString = "Create subscriber";
  const [btnCreateContractorstring, setStringBtnCreateContractor] = useState(btnCreateUserDefaultString);

  // filter test s
  const params = new URLSearchParams(window.location.search);
  let activeTags = [];
  try {
    activeTags = params.get('t') && (decodeURI(params.get('t'))) && Base64.decode(decodeURI(params.get('t'))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('t')))) : [];
  } catch (error) {
    console.log("Error in setting active tags:")
    console.log(error)
  }
  // filter test e

  // Our State.
  const [views, setViews] = useState([]);
  const [createOpen, setOpen] = useState(false);

  // Tutti gli subscription di un account (/subscription/) senza UUID?
  if (localStorage.getItem("cysr_useraccountroles") === null) {
    window.location.href = "/cysr/login";
  }

  function createContractor(event) {
    event.preventDefault();
    setStringBtnCreateContractor('Creating subscriber...')

    if (contractorName && contractorName != "" && contractorVatCountry && contractorVatCountry != "" && (contractorVatCountry === "other" || contractorVatNumber && contractorVatNumber != "")) {
      // create
      const createContractorUrl = `${cysrConfig().api_base_url}user/newcontractor/`;
      let cVCountry = contractorVatCountry;
      let cVNumber = contractorVatNumber;
      if (cVCountry === "other") {
        cVCountry = "XX";
        cVNumber = "N/A";
      }
      const datacreateContractor = {
        contractor_name: contractorName,
        contractor_vat_country: cVCountry,
        contractor_vat_number: cVNumber,
      };
      console.log("datacreateSubscriber");
      console.log(datacreateContractor);
      fetch(createContractorUrl, {
        body: JSON.stringify(datacreateContractor),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
          "content-type": "application/json",
        },
        method: "POST",
      })
        .then((response) => {
          if (response.ok) {
            response.json();
          } else {
            return response.json().then(err => {
              throw JSON.stringify(err)
            })
          }
        })
        .then((r) => {
          console.log(r);

          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>Subscriber Created</MDTypography>
              <MDTypography variant="caption">Subscriber succesfully created</MDTypography>
            </div>, {
            // action,
            variant: 'success',
            persist: false
          }
          )
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>Reloading..</MDTypography>
              <MDTypography variant="caption">The page will reload shortly</MDTypography>
            </div>, {
            // action,
            variant: 'info'
          }
          )
          window.location.reload()
        })
        .catch((err) => {
          setStringBtnCreateContractor(btnCreateUserDefaultString)

          let snackErrorTitle = "Error";
          let snackErrorDetail = "Some error happened";
          if (err && err.ErrorMessage) {
            snackErrorTitle = err.ErrorMessage;
            snackErrorDetail = err.ErrorDetail;
          } else if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
            snackErrorTitle = JSON.parse(err).ErrorMessage;
            snackErrorDetail = JSON.parse(err).ErrorDetail;
          } else if (JSON.parse(err) && JSON.parse(err).error) {
            // snackErrorTitle = "Error";
            snackErrorDetail = JSON.parse(err).error;
          }
          enqueueSnackbar(
            <div style={{ display: "flex", flexDirection: "column" }}>
              <MDTypography variant="caption" style={{ fontWeight: "600" }}>{snackErrorTitle}</MDTypography>
              <MDTypography variant="caption">{snackErrorDetail}</MDTypography>
            </div>, {
            action,
            variant: 'error',
            persist: true
          })
          console.log(err);
        });
    } else {
      // ask to fill fields
      setStringBtnCreateContractor(btnCreateUserDefaultString)

      let snackErrorTitle = "Error";
      let snackErrorDetail = "Check for incomplete fields";
      enqueueSnackbar(
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <MDTypography variant="caption" style={{ fontWeight: "600" }}>{snackErrorTitle}</MDTypography>
          <MDTypography variant="caption">{snackErrorDetail}</MDTypography>
        </div>, {
        action,
        variant: 'error',
        persist: true
      })
    }
  }
  // const accountsRoleCallUrl = `${cysrConfig().api_base_url}user/accountrole/`;
  const subscriptionsCallUrl = `${cysrConfig().api_base_url}user/mydata/`;
  useEffect(() => {
    const pageTitle = "Subscribers";
    document.title = `${pageTitle} - ${document.title.split(" - ")[1] ? document.title.split(" - ")[1] : document.title}`;
    if (localStorage.getItem("cysr_useraccountroles")) {
      // call table of subscription + call table of checks
      fetch(subscriptionsCallUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
      })
        .then((response) => response.json())
        .then((responseAccountsRoleData) => {
          console.log("subscription_list");
          console.log(responseAccountsRoleData);
          // set values of boxes
          let subs = [];
          if (responseAccountsRoleData.subscriptions) {
            for (const key in responseAccountsRoleData.subscriptions) {
              if (Object.hasOwnProperty.call(responseAccountsRoleData.subscriptions, key)) {
                const s = responseAccountsRoleData.subscriptions[key];
                subs.push(
                  {
                    subscription_id: key,
                    type: s.subscription_contracttype,
                    status: s.subscription_status,
                    start: s.subscription_start_datetime,
                    end: s.subscription_end_datetime,
                    companies_count: s.companies_active_count
                  },
                );
              }
            }
          }
          var contractorsList = [];
          /*
          {
            "397c6969-747f-4021-ab4f-3d78d75e67ae": {
              "contractor_name": "kitsune",
              "contractor_vat_name": "kitsune/mAiLBi Partners ltd"
            }
          }
          */
          for (const key in responseAccountsRoleData.contractors) {
            if (Object.hasOwnProperty.call(responseAccountsRoleData.contractors, key)) {
              const element = responseAccountsRoleData.contractors[key];
              let contractorObject = {};
              /*
              contractor_holder_name: undefined
              contractor_holder_uuid: undefined
              contractor_holder_vat_country: undefined
              contractor_holder_vat_number: undefined
              */
              contractorObject.id = key;
              contractorObject.name = element.contractor_name;
              contractorObject.vat_name = element.contractor_vat_name;
              contractorObject.vat_number = element.contractor_vat;
              contractorObject.vat_country_code = element.contractor_vat_country_code;
              contractorObject.contractor_vat_address = element.contractor_vat_address;
              contractorObject.contractor_vat_city = element.contractor_vat_city;
              contractorObject.contractor_vat_zip_code = element.contractor_vat_zip_code;
              contractorObject.contractor_evat_sdi_number = element.contractor_evat_sdi_number;
              contractorObject.contractor_is_payer = element.contractor_is_payer;
              contractorsList.push(contractorObject);
            }
          }
          setSubscriptionContractorsOptions(contractorsList)
          setViews({
            contractors: contractorsList,
            user_cysr_tags_active: activeTags
          });
        })
        .catch((err) => console.log(err));
    } else {
      setViews({});
    }
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <Card>
            <InfoButton cysr_info={cysrConfig().i("manage_contractors", "add_a_contractor", "help")} cysr_info_link={cysrConfig().i("manage_contractors", "add_a_contractor", "help_link")} />
            <MDBox pt={5} px={3} fullWidth>
              <MDBox mb={1} fullWidth>
                <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  <div>
                    {cysrConfig().i("manage_contractors", "add_a_contractor", "title") && (
                      <MDTypography variant="h5">{cysrConfig().i("manage_contractors", "add_a_contractor", "title")}</MDTypography>
                    )}
                    {cysrConfig().i("manage_contractors", "add_a_contractor", "sub_title") && (
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        {cysrConfig().i("manage_contractors", "add_a_contractor", "sub_title")}
                      </MDTypography>
                    )}
                  </div>
                  <MDButton
                    aria-label="expand row"
                    onClick={() => setOpen(!createOpen)} style={cysrConfig().button_style} size="medium">
                    {createOpen ? <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} /> : <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />
                    }
                    Add a subscriber
                  </MDButton>
                </MDBox>

                {
                  // const [userEmail, setUserEmail] = useState("");
                  // const [userName, setUserName] = useState("");
                  // const [userTech, setUserTech] = useState("");
                  // const [userAdmin, setUserAdmin] = useState("");
                }
                <Collapse in={createOpen} style={{ marginTop: ".5em" }} timeout="auto" unmountOnExit>
                  <div className={`${darkMode ? "dark" : "light"}`}>
                    <div className="mt-2 bg-zinc-100 dark:bg-zinc-900 rounded-xl border-zinc-100 dark:border-zinc-700 border-b border-t">
                      <form onSubmit={createContractor}>
                        <CysrStepper darkMode isLoading={userCreationLoading} steps={
                          [
                            {
                              label: 'Name of the Subscriber',
                              value: contractorName,
                              content:
                                <Grid container spacing={1}>
                                  <Grid item xs={6} lg={6}>
                                    <MDInput
                                      fullWidth
                                      defaultValue={contractorName}
                                      sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                                      onChange={(e) => {
                                        setTimeout(() => {
                                          if (contractorName != e.target.value) {
                                            setContractorName(e.target.value);
                                          }
                                        }, 200);
                                      }}
                                      size="small"
                                      type="text"
                                      label="Value"
                                    // id="c_generalsubscription_startingdate"
                                    />
                                  </Grid>
                                  {
                                    /*
                                    <Grid item xs={6} lg={6}>
                                      <MDInput
                                        fullWidth
                                        defaultValue={subscriptionValue}
                                        sx={{color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                                        onChange={(e) => {
                                          setSubscriptionValue(e.target.value);
                                        }}
                                        size="small"
                                        label="Value"
                                        id="c_generalsubscription_val"
                                      />
                                    </Grid>
                                    */
                                  }
                                </Grid>,
                            },
                            {
                              label: 'VAT of the Subscriber',
                              value: `${contractorVatCountry} ${contractorVatNumber}`,
                              content:
                                <Grid container spacing={1}>
                                  <Grid item xs={6} lg={3}>
                                    <Autocomplete
                                      defaultValue={contractorVatCountry}
                                      sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                                      onChange={(e, x) => {
                                        setTimeout(() => {
                                          if (contractorVatCountry != x) {
                                            setContractorVatCountry(x);
                                          }
                                        }, 200);
                                      }}
                                      size="small"
                                      options={countriesCodes}
                                      renderInput={(params) => <MDInput
                                        sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                                        size="small" label="VAT Country" {...params} />}
                                    />
                                  </Grid>
                                  <Grid item xs={6} lg={6}>
                                    <MDInput
                                      fullWidth
                                      defaultValue={contractorVatNumber}
                                      sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                                      onChange={(e) => {
                                        setTimeout(() => {
                                          if (contractorVatNumber != e.target.value) {
                                            setContractorVatNumber(e.target.value);
                                          }
                                        }, 200);
                                      }}
                                      size="small"
                                      type="text"
                                      label="VAT Number"
                                    // id="c_generalsubscription_startingdate"
                                    />
                                  </Grid>
                                  {
                                    /*
                                    <Grid item xs={6} lg={6}>
                                      <MDInput
                                        fullWidth
                                        defaultValue={subscriptionValue}
                                        sx={{color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#192328' : '#ffffff' }}
                                        onChange={(e) => {
                                          setSubscriptionValue(e.target.value);
                                        }}
                                        size="small"
                                        label="Value"
                                        id="c_generalsubscription_val"
                                      />
                                    </Grid>
                                    */
                                  }
                                </Grid>,
                              submitIcon: "add",
                              submitText: btnCreateContractorstring
                            }
                          ]
                        } />
                      </form>
                    </div>
                  </div>
                </Collapse>

              </MDBox>
            </MDBox>
            <InfoButton cysr_info={cysrConfig().i("subscription", "contractors", "help")} cysr_info_link={cysrConfig().i("subscription", "contractors", "help_link")} />
            <MDBox pb={5} px={3} fullWidth>
              {false &&
                // views && views.contractors && views.contractors.length > 0 ? (
                views && (
                  <DataTable entriesPerPage={{ defaultValue: 50, entries: [25, 50, 100, 200, 400] }} table={contractorsData(views)} canSearch />
                )}
              {views && views.contractors ?
                <MDBox pt={5} px={0} fullWidth>
                  <CysrTableContractors data={
                    {
                      search: params.get('s') ? params.get('s') : "",
                      hideCompleted: params.get('hc') ? true : false,
                      tableTagsOptions: [],
                      nodes: views.contractors,
                      tableTagsActive: views.user_cysr_tags_active,
                    }
                  }
                  />
                </MDBox>
                : (
                  <MDTypography variant="button" color="text">
                    There are no subscribers yet.
                  </MDTypography>
                )}
            </MDBox>
          </Card>
        </Grid>
      </Grid>

      {
        /*
      }
        <MDBox py={3}>
          {false && views && views.timeline && (
            <LineChart
              data={views.timeline.data}
              options={views.timeline.options}
            />
          )}
        </MDBox>
        <MDBox py={3} fullWidth>
          <MDBox mb={3} fullWidth>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MDBox pb={3} lineHeight={1} fullWidth>
                  <MDBox mb={1} fullWidth>
                    <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                      <MDTypography variant="h5">Companies list</MDTypography>
                      <MDButton
                        aria-label="expand row"
                        color="dark"
                        size="medium"
                        onClick={() => setOpen(!createOpen)}
                        >
                        Add a subscription&nbsp;{createOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </MDButton>
                    </MDBox>
                    <Collapse in={createOpen} style={{width:"100%"}} timeout="auto" unmountOnExit>        
                      <form onSubmit={createSubscription} py={3} style={{ display:"block", padding: ".5em 0"}}>
                        <Grid container spacing={1}>
                          <Grid item xs={3} lg={2}>
                            <Autocomplete
                              defaultValue="IT"
                              // label="generalsubscription_type"
                              id="c_account_vat_code"
                              options={countriesCodes}
                              size="small"
                              renderInput={(params) => <MDInput style={{height:"100%"}} label="Subscription VAT Code" {...params} />}
                              style={{height:"100%", display:"block"}}
                            />
                          </Grid>
                          <Grid item xs={9} lg={4}>
                            <MDInput
                              size="small"
                              label="Subscription VAT"
                              id="c_account_data_national_id"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <MDInput
                              fullWidth
                              size="small"
                              label="Subscription name"
                              id="c_account_name"
                            />
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <MDButton fullWidth size="small" color="dark" type="submit" style={{height:"100%"}}>
                              Create Subscription
                            </MDButton>
                          </Grid>
                        </Grid>
                      </form>
                      <MDSnackbar
                        color="error"
                        icon="error"
                        title="Subscription not created"
                        content="Check that all the fields are compiled correctly and try again"
                        dateTime="Error"
                        open={show}
                        close={toggleSnackbar}
                      />
                    </Collapse>
                    
                  </MDBox>
                  <MDTypography variant="button" color="text" fontWeight="regular">
                    The list of companies this account has access to.
                  </MDTypography>
                </MDBox>
                {views && views.accounts_role && views.accounts_role.length > 0 ? (
                  <MDBox
                    // display="flex"
                    // justifyContent="space-between"
                    // alignItems="center"
                    width={{ xs: "max-content", sm: "100%" }}
                  >
                    {views.accounts_role.map(
                      (role) =>
                        role ? (
                          <div key={role.account.account_uuid}>
                            <Grid container spacing={3}>
                              {role.account && role.account.rating && role.account.rating[0] && role.account.rating[0].rating_val ? (
                              <Grid item xs={3} md={2}>
                                <img
                                  alt="Rating"
                                  src={`${cysrConfig().image_rating_base_url}${role.account.rating[0].rating_val}.svg`}
                                  width="100%"
                                />
                              </Grid>
                              ) : (
                                <Grid item xs={3} md={2}>
                                  <p>No rating</p>
                                </Grid>
                              )}
                              <Grid item xs={9} md={10}>
                                <DashboardSubscriptionInfo useraccountrole={role} />
                              </Grid>
                            </Grid>
                            <Divider />
                          </div>
                        ) : <p>No role</p>
                    )}
                  </MDBox>
                ) : (
                  <MDTypography variant="caption" color="text">
                    This account does not have any subscription
                  </MDTypography>
                )}
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        {
          */
      }

    </DashboardLayout>
  );
}

export default Subscribers;

/* eslint-disable */
// prettier-ignore
