/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import cysrConfig from "layouts/cysr/config";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

function BasicInfo({firstName, lastName, toggleSnackbar}) {
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Basic Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDInput
              fullWidth
              label="First Name"
              id="profile_first_name"
              defaultValue={firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              fullWidth
              label="Last Name"
              defaultValue={lastName}
              id="profile_last_name" 
            />
          </Grid>
          {false &&
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  defaultValue="Male"
                  options={selectData.gender}
                  renderInput={(params) => (
                    <FormField {...params} label="I'm" InputLabelProps={{ shrink: true }} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={5}>
                    <Autocomplete
                      defaultValue="February"
                      options={selectData.birthDate}
                      renderInput={(params) => (
                        <FormField
                          {...params}
                          label="Birth Date"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      defaultValue="1"
                      options={selectData.days}
                      renderInput={(params) => (
                        <FormField {...params} InputLabelProps={{ shrink: true }} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      defaultValue="2021"
                      options={selectData.years}
                      renderInput={(params) => (
                        <FormField {...params} InputLabelProps={{ shrink: true }} />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          }
          {false &&
          <>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Email"
                placeholder={email}
                inputProps={{ type: "email" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="confirmation email"
                placeholder="example@email.com"
                inputProps={{ type: "email" }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField label="your location" placeholder="Sydney, A" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Phone Number"
                placeholder="+40 735 631 620"
                inputProps={{ type: "number" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField label="Language" placeholder="English" />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                defaultValue={["react", "angular"]}
                options={selectData.skills}
                renderInput={(params) => <FormField {...params} InputLabelProps={{ shrink: true }} />}
              />
            </Grid>
          </>
          }
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="flex-end" width="100%">
              <MDButton style={cysrConfig().button_style} size="medium" onClick={()=>{
            const editProfileUrl = `${cysrConfig().api_base_url}user/myprofile/`;
            fetch(editProfileUrl, {
              body: JSON.stringify({
                user_first_name: document.getElementById("profile_first_name").value,
                user_last_name: document.getElementById("profile_last_name").value,
              }),
              headers: {
                Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                "content-type": "application/json",
              },
              method: "PATCH",
            })
              .then((response) => {
                if (response.status === 200) {
                  response.json()
                    .then((responseData) => {
                      toggleSnackbar(responseData.Message, responseData.Detail, "info", "info", "Info");
                    })
                } else if (response.ok || (response.status > 199 && response.status < 300)) {
                  response.json()
                    .then((responseData) => {
                      toggleSnackbar(responseData.Message, responseData.Detail, "success", "checkmark", "Saved");                
                    })
                } else {
                  return response.json().then(err => {
                    throw JSON.stringify(err)
                  })
                }
              })
              .catch((err) => {
                console.log("err1:", err);
                toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
                console.log(err);
              });
          }}>
              <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{__html: cysrConfig().update.icon.svg }} />
                Update Basic Info
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props
BasicInfo.defaultProps = {
  firstName: "",
  lastName: "",
  // email: "",
};

// Typechecking props
BasicInfo.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  // email: PropTypes.string,
};

export default BasicInfo;
/* eslint-disable */