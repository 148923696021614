/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Pmu_created_at: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import MDSnackbar from "components/MDSnackbar";
import cysrConfig from "layouts/cysr/config";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import breakpoints from "assets/theme/base/breakpoints";

import Dialog from '@mui/material/Dialog';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { assetAdditionalInfo, assetTags, assetCustomTags } from "layouts/cysr/components/assetAdditionalInfo";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from "@mui/material/Chip";
import OptionDeleteIcon from '@mui/icons-material/Close';

import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Card from "@mui/material/Card";
import {
  Dialog as ScreenshotDialog,
  DialogContent,
  DialogTrigger,
} from "layouts/cysr/components/ui/dialog";
import { CloseFullscreenSharp, FullscreenExitSharp, FullscreenSharp, OpenInFullSharp } from "@mui/icons-material";

import CysrDropdown from "layouts/cysr/components/CysrDropdown";

// select
import Select, { components } from 'react-select'
import { toast } from "sonner";

export default function data({ views, features = null }) {
  if (views && views.views) {
    views = views.views;
  }
  // old one (rating)
  const [show, setShow] = useState(false);

  const screenshotHighlight = views && views.asset && views.asset.highlights ? views.asset.highlights.find(
    (h) => h.highlightGA_content?.type === "SCREENSHOT"
  ) : null;
  const darkMode = localStorage.getItem('cysr_dark_mode');

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;


  // new one (source/status)
  const [sshow, setSShow] = useState(false);

  const [editedTags, setEditedTags] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [accountTags, setAccountTags] = useState(null);
  const [editingAssetTags, setEditingAssetTags] = useState(null);
  useMemo(() => {
    const defaultAssetTags = (views && views.asset && views.asset.asset_usertags) ? views.asset.asset_usertags : null
    if (defaultAssetTags) {
      setAccountTags(defaultAssetTags);
      setEditingAssetTags(defaultAssetTags);
    }
  }, [views])


  const [msgDateTime, setMsgDateTime] = useState("Information");
  const [msgIcon, setMsgIcon] = useState("info");
  const [msgTheme, setMsgTheme] = useState("light");
  const [msgTitle, setMsgTitle] = useState("Recheck not executed");
  // reacthtml msgContent:
  const [msgContent, setMsgContent] = useState(
    <></>
  )

  const defaultToggleSnackbar = () => {
    setSShow(!sshow);
  }
  const toggleSnackbar = (title = errorTitle, msg = errorMsg, theme = "error", icon = "error", dateTime = "Error") => {
    setMsgDateTime(dateTime);
    setMsgIcon(icon);
    setMsgTheme(theme);

    setMsgTitle(title);
    setMsgContent(msg);
    setSShow(!sshow);
  }

  const [selectStatusLoading, setSelectStatusLoading] = useState(false);
  const [selectAssetSrcLoading, setSelectAssetSrcLoading] = useState(false);
  // custom select s

  const { Option } = components
  const CustomSelectOption = props => (
    <Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ display: "inline-flex", alignItems: "center", transform: "scale(0.75)", marginRight: "12px" }} dangerouslySetInnerHTML={{ __html: props.data.icon }} />
        <span style={{ fontSize: "14px", lineHeight: "1" }}>{props.data.label}</span>
      </div>
    </Option>
  )

  const CustomSelectValue = props => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: props.data.icon }} />
      <span style={{ fontSize: "16px", lineHeight: "1" }}>{props.data.label}</span>
    </div>
  )
  const CustomSelectValueLoading = props => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={cysrConfig().button_icon_left_style} className="spin" dangerouslySetInnerHTML={{ __html: cysrConfig().loading.icon.svg }} />
      <span style={{ fontSize: "16px", lineHeight: "1" }}>{props.data.label}</span>
    </div>
  )

  const Svg = (p) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      focusable="false"
      role="presentation"
      {...p}
    />
  );
  const DropdownIndicator = () => (
    <div style={{ color: "#0D918F", height: 20, width: 20, display: "flex", alignItems: "center" }}>
      <Svg>
        <path d="M10 13.75L3.75 7.5L4.625 6.625L10 12L15.375 6.625L16.25 7.5L10 13.75Z" fill="currentColor" />
      </Svg>
    </div>
  );

  const colourStyles = {
    valueContainer: (base) => ({
      ...base,
      width: '100%',
      minWidth: '160px',
      display: "flex",
    }),
    indicatorsContainer: (styles) => ({ ...styles, height: "100%" }),
    indicatorSeparator: (styles) => ({ ...styles, border: "0", backgroundColor: "transparent" }),
    control: (styles, { isDisabled }) => ({
      ...styles, border: "0", color: isDisabled
        ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#bdc7d0" : "#666666") : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: isDisabled
          ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#EEF0F1" : "#17191c") : (localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b"), height: "32px", minHeight: "32px", minWidth: "160px"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? undefined
              : undefined,
        color: isDisabled
          ? '#ccc'
          : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
            && data.color
            : undefined,
        },
      };
    },
    menuList: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b" }),
    input: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
    placeholder: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };
  const loadingStyles = {
    valueContainer: (base) => ({
      ...base,
      width: '100%',
      display: "flex",
    }),
    indicatorsContainer: (styles) => ({ ...styles, height: "100%" }),
    indicatorSeparator: (styles) => ({ ...styles, border: "0", backgroundColor: "transparent" }),
    control: (styles, { isDisabled }) => ({
      ...styles, border: "0", color: isDisabled
        ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#bdc7d0" : "#666666") : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: isDisabled
          ? (localStorage.getItem("cysr_dark_mode") === "false" ? "#EEF0F1" : "#17191c") : (localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b"), height: "32px", minHeight: "32px"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? undefined
              : undefined,
        color: isDisabled
          ? '#ccc'
          : localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white",
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
            && data.color
            : undefined,
        },
      };
    },
    menuList: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white", backgroundColor: localStorage.getItem("cysr_dark_mode") === "false" ? "#cddddf" : "#50666b" }),
    input: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
    placeholder: (styles) => ({ ...styles, color: localStorage.getItem("cysr_dark_mode") === "false" ? "black" : "white" }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };

  // custom select e

  /*
  to loop on localStorage.getItem("cysr_useraccountrole")
  and set isAdmin true se è admin [check localStorage.getItem("cysr_useraccountrole_selected")]

  while (userAccountsCount > 0) {
    userAccountsCount -= 1;
    const a = userAccounts[userAccountsCount];
    // here adding a check on cysr_useraccountrole_selected s
    if(localStorage.getItem("cysr_useraccountrole_selected")){
      if(a.account_uuid !== localStorage.getItem("cysr_useraccountrole_selected")){
  */

  const assetToChecker = (currentItem) => {
    const assetUUID = currentItem.asset_uuid;
    const dataAsset = {
      source: "checker"
    };
    // /{version}/asset/{asset_req_uuid}/recheck/

    const assetToManualUrl = `${cysrConfig().api_base_url}asset/${assetUUID}/source/`;
    fetch(assetToManualUrl, {
      body: JSON.stringify(dataAsset),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      method: "PATCH",
    })
      .then((response) => {
        if (response.ok) {
          window.location.reload();
          return response.json();
        } else {
          console.error(response);
          return response.json().then(err => {
            throw JSON.stringify(err)
          })
          // setSelectAssetSrcLoading(false)
          // return response.json()
        }
      })
      .then((recheckData) => {
        if (recheckData && recheckData.CheckDetail && recheckData.CheckDetail !== "OK") {
          alert(recheckData.CheckDetail)
          console.error(recheckData.CheckDetail);
        }
      })
      .catch((err) => {
        console.error("err1:", err);
        toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail);
        setSelectAssetSrcLoading(false)
        console.error(err);
      });
  };
  const assetToManual = (currentItem) => {
    const assetUUID = currentItem.asset_uuid;
    const dataAsset = {
      source: "manual"
    };
    // /{version}/asset/{asset_req_uuid}/recheck/

    const assetToManualUrl = `${cysrConfig().api_base_url}asset/${assetUUID}/source/`;
    fetch(assetToManualUrl, {
      body: JSON.stringify(dataAsset),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      method: "PATCH",
    })
      .then((response) => {
        if (response.ok) {
          window.location.reload();
          return response.json();
        } else {
          console.error(response);
          return response.json().then(err => {
            throw JSON.stringify(err)
          })
          setSelectAssetSrcLoading(false)
          return response.json()
        }
      })
      .then((recheckData) => {
        if (recheckData && recheckData.CheckDetail && recheckData.CheckDetail !== "OK") {
          alert(recheckData.CheckDetail)
          console.warn(recheckData.CheckDetail);
        }
      })
      .catch((err) => {
        console.log("err2:", err);
        toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail);
        setSelectAssetSrcLoading(false)
        console.log(err);
      });
  };
  const recheckAsset = (currentItem, action) => {
    const assetUUID = currentItem.asset_uuid;
    const dataRecheckAsset = {
      action: action
    };
    // /{version}/asset/{asset_req_uuid}/recheck/

    const recheckAssetUrl = `${cysrConfig().api_base_url}asset/${assetUUID}/recheck/`;
    fetch(recheckAssetUrl, {
      body: JSON.stringify(dataRecheckAsset),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        "content-type": "application/json",
      },
      method: "PATCH",
    })
      .then((response) => {
        if (response.ok) {
          // window.location.reload();
          return response.json();
        } else {
          console.error(response);
          return response.json().then(err => {
            throw JSON.stringify(err)
          })
          return response.json()
        }
      })
      .then((recheckData) => {
        // console.log(recheckData);
        toggleSnackbar(recheckData.CheckStatus, recheckData.CheckDetail, "success", "checkmark", "");
      })
      .catch((err) => {
        console.log(err);
        console.log("err3:", err);
        if (err && err.CheckDetail) {
          toggleSnackbar("Error Message", err.CheckDetail);
        } else if (err) {
          toggleSnackbar("Error", err);
        } else {
          toggleSnackbar("Error", "An error happened");
        }
      });
  };

  function handleRecheckToggle() {
    toggleSnackbar(
      "Confirm",
      <>
        <Grid container spacing={1} my={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <MDTypography variant="text" fontWeight="regular" style={{ color: localStorage.getItem("cysr_dark_mode") === "false" ? "#354767" : "#7B809A" }}>
                  Recheck asset?
                </MDTypography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <MDButton onClick={() => recheckAsset(views.asset, "recheck_only")} style={cysrConfig().button_style} size="small" color="info"><span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().recheck.icon.svg }} />Recheck</MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>, "light", "info", "Information");
  }
  function FullScreenDialog() {
    const [open, setOpen] = useState(false);

    /*
    // non più usato
    const handleClickOpen = () => {
      setOpen(true);
    };
    */

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div>
        <MDButton
          style={cysrConfig().button_style}
          onClick={handleRecheckToggle}>
          <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().recheck.icon.svg }} />
          Recheck
        </MDButton>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <MDTypography style={{ color: "#000000" }} sx={{ ml: 2, flex: 1 }} variant="h4" component="div"></MDTypography>
              <IconButton
                edge="start"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <List style={{ color: "#000000" }}>
            <ListItem>
              <MDBox ml={5}>
                <MDTypography style={{ color: "#555555" }} variant="h5">Recheck</MDTypography>
                <p mt={1} mb={2} style={{ color: "#555555", fontSize: "14px", lineHeight: "1.4" }}>Use <strong>Quick Recheck</strong> for checking only how this asset scores, discovery takes more time.</p>
              </MDBox>
            </ListItem>
            <Divider />
            <ListItem>
              <Grid container spacing={3} ml={4}>
                <Grid item xs={8} md={4}>
                  <Grid container spacing={1} px={1} pb={2} style={{ background: "#eeeeee" }}>
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <MDBadge
                            color="success"
                            size="sm"
                            badgeContent="quick"
                            container
                          />
                        </Grid>
                        <Grid item>
                          <MDBadge
                            color="warning"
                            size="sm"
                            badgeContent="without discovery"
                            container
                          />
                        </Grid>
                      </Grid>
                      <MDBox display="flex" flexDirection="column">
                        <MDTypography mt={1} style={{ color: "#111111" }} variant="h6">
                          Quick Recheck
                        </MDTypography>
                        <MDTypography mb={1} style={{ color: "#111111", lineHeight: "1.6" }} variant="overline">Do a recheck without discovery</MDTypography>
                        <MDButton color="primary" onClick={() => recheckAsset(views.asset, "recheck_only")}>
                          recheck without discovery
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={8} md={4}>
                  <Grid container spacing={1} px={1} pb={2} style={{ background: "#eeeeee" }}>
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <MDBadge
                            color="warning"
                            size="sm"
                            badgeContent="slower"
                            container
                          />
                        </Grid>
                        <Grid item>
                          <MDBadge
                            color="info"
                            size="sm"
                            badgeContent="full discovery"
                            container
                          />
                        </Grid>
                      </Grid>
                      <MDBox display="flex" flexDirection="column">
                        <MDTypography mt={1} style={{ color: "#111111" }} variant="h6">
                          Recheck and discovery
                        </MDTypography>
                        <MDTypography mb={1} style={{ color: "#111111", lineHeight: "1.6" }} variant="overline">Do a recheck with a full discovery</MDTypography>
                        <MDButton color="primary" onClick={() => recheckAsset(views.asset, "recheck_and_discovery")}>
                          recheck and discovery
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Dialog>
      </div>
    );
  }

  //asset_status s
  let statusArray = [
    {
      label: "Enabled",
      color: "success",
      value: "ENABLED",
      key: 0,
      icon: cysrConfig().get_status("ENABLED").svg
    }
  ];
  if (
    // check it is domain
    views && views.asset && views.asset.generalasset && views.asset.generalasset.generalasset_type === "domain"
    // also check it is manual
    && views.asset.assetsrc && views.asset.assetsrc.assetsrc_longname && views.asset.assetsrc.assetsrc_longname === "manual"
  ) {
    // do not add disabled
  } else {
    statusArray.push(
      {
        label: "Disabled",
        color: "error",
        value: "DISABLED",
        key: 1,
        icon: cysrConfig().get_status("DISABLED").svg
      }
    )
  };
  statusArray.push(
    {
      label: "Acknowledged",
      color: "warning",
      value: "ACKNOWLEDGED",
      key: 2,
      icon: cysrConfig().get_status("ACKNOWLEDGED").svg
    }
  );
  const assetSrcArray = [
    {
      label: "Auto-discovery",
      color: "info",
      // value: "checker",
      value: "auto",
      key: 0,
      icon: cysrConfig().origin.auto.svg
    },
    {
      label: "Manual",
      color: "info",
      value: "manual",
      key: 1,
      icon: cysrConfig().origin.manual.svg
    }
  ];
  /*
  function getCurrentStatusIndex() {    
    if(views && views.asset && views.asset.asset_status){
      statusArray.forEach((s) => {
        if(s.value === views.asset.asset_status) {
          return s.key;
        }
      });
    } else {
      console.error(views);
      return false;
    }
  }
  */
  // en/dis/ack : tendina, per CX meglio sempre visibili le opzioni se non troppe

  // const [tabValue, setRadioValue] = useState(getCurrentStatusIndex());
  // const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  /*
    useEffect(() => {
      // A function that sets the orientation state of the tabs.
      function handleTabsOrientation() {
        return window.innerWidth < breakpoints.values.sm
          ? setTabsOrientation("vertical")
          : setTabsOrientation("horizontal");
      }
      // window.addEventListener("resize", handleTabsOrientation);

      // Call the handleTabsOrientation function to set the state with the initial value.
      // handleTabsOrientation();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    const handleChangeSrc = (va, newValue) => {
      setSelectAssetSrcLoading(true)
      if(newValue === "auto" || newValue === "checker"){
        assetToChecker(va)
      } else if(newValue === "manual"){
        assetToManual(va)
      }    
    }
    const handleChangeStatus = (event, newValue) => {
      setSelectStatusLoading(true)
      const changeStatusAssetUrl = `${cysrConfig().api_base_url}asset/${views.asset.asset_uuid}/check/status/`;
      fetch(changeStatusAssetUrl, {
        body: JSON.stringify({"asset_status": newValue}),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
          "content-type": "application/json",
        },
        method: "PATCH",
      })
        .then((response) => {
          if (response.ok) {
            setSelectStatusLoading(false)
            // setRadioValue(newValue);

          } else {
            setSelectStatusLoading(false)
            return response.json().then(err => {
              throw JSON.stringify(err)
            })
          }
        })
        .then((statusData) => {
          console.log(statusData);
          setSelectStatusLoading(false)
          // window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          console.log("err4:", err);
          toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail);
          setSelectStatusLoading(false)
        });
    }
    //asset_status e
  */
  if (views && views.ratingCurrent) {
    const accountUUID = window.location.pathname.substring(
      window.location.pathname.indexOf("/company/") + "/company/".length,
      window.location.pathname.lastIndexOf("/")
    );
    const toggleSnackbar = () => setShow(!show);
    const removeAssetToggle = () => {
      toggleSnackbar();
    };
    const removeAsset = () => {
      // /{version}/asset/{asset_uuid}/
      const removeAssetUrl = `${cysrConfig().api_base_url}asset/${views.asset.asset_uuid}/check/status/`;
      fetch(removeAssetUrl, {
        body: JSON.stringify({ "asset_status": "NOT_MINE" }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
          "content-type": "application/json",
        },
        method: "PATCH",
      })
        .then((response) => {
          if (response.ok) {
            window.location.href = `/company/${accountUUID}/assets`;
          } else {
            return response.json().then(err => {
              throw JSON.stringify(err)
            })
          }
        })
        .then((removeData) => {
          // console.log(removeData);
          // window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          console.log("err5:", err);
          toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail);
        });
    };
    let ratingVal = 0;
    if (
      views &&
      views.ratingCurrent &&
      views.ratingCurrent[0] &&
      views.ratingCurrent[0].rating_val
    ) {
      ratingVal = views.ratingCurrent[0].rating_val;
    }
    let muCreatedAt = "";
    if (
      views &&
      views.asset &&
      views.asset.generalasset &&
      views.asset.generalasset.mu_created_at
    ) {
      muCreatedAt = new Date(views.asset.generalasset.mu_created_at).toLocaleDateString("it-IT");
    }
    let muUpdatedAt = "";
    if (
      views &&
      views.ratingCurrent &&
      views.ratingCurrent[0] &&
      views.ratingCurrent[0].mu_updated_at
    ) {
      muUpdatedAt = new Date(views.ratingCurrent[0].mu_updated_at).toLocaleDateString("it-IT");
    } else {
      muUpdatedAt = muCreatedAt;
    }
    function selectStatusValue(id, status) {
      // console.log('sVal')
      // console.log(id)
      return statusArray.find((opt) => opt.value === status);
    }
    function srcValue(id, src) {
      // console.log('srcV')
      // console.log(id)
      return assetSrcArray.find((opt) => {
        let v = src;
        if (src === "checker") {
          v = "auto";
        }
        return opt.value === v;
      })
    };
    return (
      <>
        <Grid container spacing={3} alignItems="center" item xs={12}>
          <Grid item xs={9}>
            {
              // <MDTypography fontWeight="medium" style={{ lineHeight: "1", display: "block", marginBottom: "0", marginTop: "0", fontSize: "24px", color: localStorage.getItem("cysr_dark_mode") === "false" ? "#354767" : "#AEC2E5" }}>
            }
            <MDTypography fontWeight="medium" style={{ zIndex: "1", position: "relative", lineHeight: "1", display: "block", marginBottom: "0", marginTop: "0", fontSize: "24px", color: localStorage.getItem("cysr_dark_mode") === "false" ? "#000000" : "#FFFFFF" }}>
              {views.asset.generalasset.generalasset_type}:
              {
                // <span style={{ fontWeight: "bold", display: "inline-block", fontSize: "32px", marginLeft: "8px", color: localStorage.getItem("cysr_dark_mode") === "false" ? "#354767" : "#AEC2E5" }}>
              }
              <span style={{ fontWeight: "bold", display: "inline-block", fontSize: "32px", marginLeft: "8px", color: localStorage.getItem("cysr_dark_mode") === "false" ? "#000000" : "#FFFFFF" }}>
                {views.asset.generalasset.generalasset_val}
              </span>
            </MDTypography>
          </Grid>
          <Grid item xs={3}>
            <div className="flex w-full" style={
              {
                justifyContent: "end"
              }
            }>
              {assetAdditionalInfo(views.asset.generalasset.generalasset_additional_info)}
            </div>
          </Grid>

          <Grid container spacing={3} alignItems="center" item xs={12}>
            <Grid item xs={views && views.asset && screenshotHighlight && screenshotHighlight.highlightGA_content ? 9 : 12}>
              {
                // user tags
              }
              <div className="gap-2" style={{ display: "flex", alignItems: "flex-start", flexWrap: "wrap" }}>
                <MDTypography variant="caption" color="text">Highlights:&nbsp;</MDTypography>
                {assetTags(views.asset.generalasset.generalasset_tags, true)}
              </div>
              {accountTags &&
                <div className="gap-2" style={{ display: "flex", alignItems: "flex-start", flexWrap: "wrap", marginTop: "8px" }}>
                  <MDTypography variant="caption" color="text">Asset tags:&nbsp;</MDTypography>
                  {editMode ?
                    <div style={{
                      display: "flex",
                      width: "100%",
                      maxWidth: '480px',
                      flexDirection: "column",
                      gap: "4px"
                    }}>
                      <MDTypography variant="body" color="text" style={{ fontSize: "13px", lineHeight: "1.2" }}>Add tags by typing. Use comma or press enter to insert. Tap <span style={{
                        ...cysrConfig().button_icon_style,
                        transform: "translateY(5px)"
                      }} dangerouslySetInnerHTML={{ __html: cysrConfig().update.icon.svg }} /> to update them</MDTypography>
                      <Autocomplete
                        color="info"
                        multiple
                        limitTags={5}
                        freeSolo
                        size="small"
                        id="multiple-asset-tags"
                        disableCloseOnSelect
                        onChange={(e, v) => {
                          setEditingAssetTags(v)
                          // just to update source s
                          // setEditedTags(true)
                          // just to update source e
                        }}
                        options={accountTags}
                        getOptionLabel={(option) => option}
                        // disable dropdown
                        PopperComponent={() => (
                          null
                        )}
                        renderOption={(props, option, { selected }) => (
                          <li {...props} style={{ marginBottom: "4px", zIndex: 9999999 }}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        // defaultValue={editedTags ? accountTags : views && views.asset ? views.asset.asset_usertags : accountTags}
                        // https://stackoverflow.com/a/59038435
                        // value={editedTags ? editingAssetTags : views && views.asset ? views.asset.asset_usertags : accountTags}
                        value={editingAssetTags}
                        clearOnBlur={true}
                        renderTags={(value, getTagProps) => value.map(function (option, index) {
                          let label = option.rating ? option.rating : option;
                          let descriptiveLabel = false;
                          // let chipColor = darkMode ? 'rgba(0,0,0,.85)' : 'rgb(250, 250, 250)';
                          // let color = "secondary";
                          let icon = null;
                          // "outlined", "filled";
                          let variant = "filled";
                          // color = "default";
                          // chipColor = darkMode ? 'hsl(205deg 73% 6%)' : 'hsl(214deg 82% 94%)';
                          return (
                            <Chip
                              // sx={{ color: `${darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgba(0, 0, 0, 0.85)'}!important`, border: `${darkMode ? '1px solid rgb(48, 48, 48)' : '1px solid rgb(232, 232, 232)'}!important`, background: `${chipColor}!important`, borderRadius: 0, fontFamily: "Ubuntu Mono, monospace" }}
                              sx={{ color: `${darkMode ? '#095bc6' : '#095bc6'}!important`, border: `${darkMode ? '1px solid rgb(48, 48, 48)' : '1px solid rgb(232, 232, 232)'}!important`, background: `#aecef7!important`, borderRadius: 0, fontFamily: "Ubuntu Mono, monospace" }}
                              icon={icon}
                              // color={color}
                              variant={variant}
                              label={label}
                              size="small"
                              deleteIcon={<OptionDeleteIcon sx={{ color: `${darkMode ? '#095bc6' : '#095bc6'}!important` }} />}
                              {...getTagProps({ index })} />
                          );
                        })}
                        renderInput={(params) => {
                          params.inputProps.onKeyDown = event => {
                            switch (event.key) {
                              case "Enter":
                              case ".":
                              case ",":
                              case ";": {
                                event.preventDefault();
                                event.stopPropagation();
                                if (event.target.value.length > 0) {
                                  if (editingAssetTags.length < 5) {
                                    setEditingAssetTags([...editingAssetTags, event.target.value]);
                                  } else {
                                    toast.warning("No more than 5 tags can be added.")
                                  }
                                }
                                break;
                              }
                              default:
                            }
                          };
                          return (

                            <TextField
                              {...params}
                              // variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              size="small"
                              // sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#121619' : '#f3f4f7' }}
                              sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? 'rgb(18 22 25)' : '#f3f4f7' }}
                              placeholder="Tags"
                            />
                          )
                        }}
                        sx={{ width: "100%", maxWidth: '480px' }}
                      />
                    </div>
                    :
                    // assetCustomTags(editedTags ? accountTags : views && views.asset ? views.asset.asset_usertags : accountTags)
                    assetCustomTags(accountTags)
                  }
                  {editMode ?
                    <div style={{ display: "inline-flex", alignItems: "end", height: "44px", flexWrap: "wrap", marginTop: "auto", marginLeft: "8px", gap: "8px", paddingBottom: "1px" }}>
                      <MDButton style={cysrConfig().button_style} size="medium" onClick={
                        () => {
                          const updateTagsUrl = `${cysrConfig().api_base_url}asset/${views.asset.asset_uuid}/tags/`;


                          fetch(updateTagsUrl, {
                            body: JSON.stringify({ tags: editingAssetTags }),
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                              "content-type": "application/json",
                            },
                            method: "PUT",
                          })
                            .then((response) => {
                              if (response.ok) {
                                // window.location.reload();
                                return response.json();
                              } else {
                                console.error(response);
                                return response.json().then(err => {
                                  throw JSON.stringify(err)
                                })
                                return response.json()
                              }
                            })
                            .then((updateTagsData) => {
                              // console.log(updateTagsData);
                              setAccountTags(editingAssetTags)
                              setEditMode(false)

                              if (updateTagsData && updateTagsData.CheckDetail) {
                                toast(updateTagsData.CheckDetail)
                              } else if (updateTagsData && updateTagsData[0] && updateTagsData[0].CheckDetail) {
                                toast(updateTagsData[0].CheckDetail)
                              } else {
                                toast.success("Asset tags updated")
                              }
                            })
                            .catch((err) => {
                              // console.log(err);
                              // console.log("errQ:", err);

                              if (err && err.CheckDetail) {
                                if (err && err.CheckStatus) {
                                  executeError(err.CheckStatus, err.CheckDetail);
                                } else {
                                  executeError("Error Message", err.CheckDetail);
                                }
                              } else if (err) {
                                if (JSON.parse(err) && JSON.parse(err).CheckDetail) {
                                  if (JSON.parse(err) && JSON.parse(err).CheckStatus) {
                                    executeError(JSON.parse(err).CheckStatus, JSON.parse(err).CheckDetail)
                                  } else {
                                    executeError("Error Message", JSON.parse(err).CheckDetail)
                                  }
                                } else {
                                  executeError("Error", err);
                                }
                              } else {
                                executeError("Error", "An error happened");
                              }
                            });
                        }
                      }>
                        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().update.icon.svg }} />
                        Update tags
                      </MDButton>
                      <MDButton style={cysrConfig().button_style} size="medium" onClick={
                        () => { setEditMode(!editMode) }
                      }>
                        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().close.icon.svg }} />
                        Cancel
                      </MDButton>
                    </div>
                    :
                    <MDButton
                      onClick={() => {
                        setEditMode(!editMode)
                      }} style={{
                        background: "transparent",
                        color: darkMode ? "#5c959f" : "#0f6066",
                        height: "20px",
                        padding: "0",
                        fontSize: "16px",
                        fontWeight: 400,
                        textTransform: "none",
                        minHeight: "unset",
                        minWidth: "unset"
                      }} size="medium">
                      <span style={{
                        color: darkMode ? "#5c959f" : "#0f6066",
                        display: "inline-flex",
                        alignItems: "center",
                        transform: "scale(0.6)"
                      }} dangerouslySetInnerHTML={{ __html: cysrConfig().edit.icon.svg }} />
                    </MDButton>
                  }
                </div>
              }

            </Grid>
            {
              // shot highlight s
            }
            {views && views.asset && screenshotHighlight && screenshotHighlight.highlightGA_content
              ? (
                <Grid style={{
                  position: "relative",
                }} item xs={3}>
                  <Card style={{
                    position: "absolute",
                    top: -20,
                    right: 0
                  }}>
                    {screenshotHighlight.highlightGA_content.help && (
                      <InfoButton cysr_info={screenshotHighlight.highlightGA_content.help ? screenshotHighlight.highlightGA_content.help : ""} cysr_info_link={screenshotHighlight.highlightGA_content.help_link ? screenshotHighlight.highlightGA_content.help_link : ""} />
                    )}
                    <MDBox p={
                      1.5
                    }>
                      <MDBox mx={0} my={0}>
                        <ScreenshotDialog>
                          <DialogTrigger asChild>
                            <div className="flex gap-4" style={{
                              alignItems: "center",
                              cursor: "pointer",
                            }}>
                              <div className="flex flex-col gap-2">
                                {
                                  /*
                                }
                                <MDTypography style={{ fontSize: "16px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} variant="h2" color="text">
                                  {screenshotHighlight.highlightGA_content.title ? screenshotHighlight.highlightGA_content.title : "Screenshot"}
                                </MDTypography>
                                <MDTypography style={{ fontSize: "12px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} variant="body2" color="text">
                                  {screenshotHighlight.highlightGA_content.help ? screenshotHighlight.highlightGA_content.help : "Tap to see the preview in fullscreen"}
                                </MDTypography>
                                {
                                  */
                                }
                                <OpenInFullSharp style={cysrConfig().button_icon_style} />
                              </div>
                              <div style={{
                                height: 90,//64,
                                width: 160//64
                              }} className="relative bg-zinc-300 dark:bg-zinc-700 cursor-pointer rounded-md">
                                <img
                                  style={{
                                    borderRadius: 5,
                                    objectFit: 'contain',
                                    height: '100%',
                                    width: '100%',
                                  }}
                                  alt={`Screenshot for ${screenshotHighlight.highlightGA_content.title}`}
                                  src={screenshotHighlight.highlightGA_content.url_thumbnail ? screenshotHighlight.highlightGA_content.url_thumbnail : screenshotHighlight.highlightGA_content.url}
                                />
                                {false &&
                                  <div className="absolute bottom-0 right-0 p-2 inline-flex items-center justify-center">
                                    <div style={{
                                      backgroundColor: darkMode ? "#1C7170" : "#5D9A99"
                                    }} className="p-2 rounded-full inline-flex items-center justify-center">
                                      <OpenInFullSharp className="h-10 w-10 text-white" />
                                    </div>
                                  </div>}
                              </div>
                            </div>
                          </DialogTrigger>
                          <DialogContent style={
                            {
                              background: `url(${screenshotHighlight.highlightGA_content.url ? screenshotHighlight.highlightGA_content.url : screenshotHighlight.highlightGA_content.url_thumbnail ? screenshotHighlight.highlightGA_content.url_thumbnail : ""})`,
                              backgroundPosition: '50% 50%',
                              backgroundRepeat: "no-repeat",
                              backgroundSize: 'contain',
                              height: '100%',
                              width: 'auto',
                              aspectRatio: '16/9',
                              border: 0,
                              maxWidth: '90vw',
                              maxHeight: '90vh',
                              zIndex: 9999999
                            }
                          } />
                        </ScreenshotDialog>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
              ) : null
            }
            {
              // shot highlight e
            }
          </Grid>

          <MDSnackbar
            color={msgTheme}
            icon={msgIcon}
            title={msgTitle}
            content={msgContent}
            dateTime={msgDateTime}
            open={sshow}
            close={defaultToggleSnackbar}
          />
          <Grid item xs={12} container style={{
            alignItems: "flex-end",
          }} width="100%" spacing={3}>
            <Grid item>
              {views && views.asset && views.asset.asset_status ? (
                <>
                  {
                    /*
                  }
                    <Tabs orientation={tabsOrientation} value={tabValue || views.asset.asset_status} onChange={handleChangeStatus}>
                      {statusArray.map((el) => (
                        <Tab color={el.color} label={el.label} key={el.key} value={el.value} />
                      ))}
                    </Tabs>
                  {
                    */
                  }
                  {
                    // cysrConfig().feature_available("asset_change_status") ? (
                  }
                  {(features && features["asset_change_status"] && features["asset_change_status"] != "false") ? (
                    <>
                      <CysrDropdown data={
                        {
                          asset: views.asset,
                          isDisabled: false,
                          isLoading: selectStatusLoading,
                          options: statusArray,
                          value: selectStatusValue(views.asset.asset_uuid, views.asset.asset_status),
                          defaultValue: selectStatusValue(views.asset.asset_uuid, views.asset.asset_status),
                          onChange: 'status'
                        }
                      }
                      />
                      {
                        /*
                        <Select isSearchable={false} options={statusArray} defaultValue={selectStatusValue(views.asset.asset_uuid, views.asset.asset_status)} styles={selectStatusLoading ? loadingStyles : colourStyles} onChange={(newValue) =>{
                          handleChangeStatus(null, newValue.value)
                        }}
                        components={{ DropdownIndicator, Option: CustomSelectOption, SingleValue: selectStatusLoading ? CustomSelectValueLoading : CustomSelectValue }} />
                        */
                      }
                    </>
                  ) :
                    <MDTypography variant="button" color="text" style={{ display: "block", lineHeight: "32px" }}>
                      Status is <strong>{selectStatusValue(views.asset.asset_uuid, views.asset.asset_status).value.toLowerCase()}</strong>
                    </MDTypography>
                  }
                </>
              ) : (
                <Tabs></Tabs>
              )}
            </Grid>
            <Grid item>
              {views && views.asset && views.asset.assetsrc && views.asset.assetsrc.assetsrc_longname && (
                <>
                  {
                    /*
                  }
                    <Tabs orientation={tabsOrientation} value={tabValue || views.asset.asset_status} onChange={handleChangeStatus}>
                      {statusArray.map((el) => (
                        <Tab color={el.color} label={el.label} key={el.key} value={el.value} />
                      ))}
                    </Tabs>
                  {
                    */
                  }
                  {
                    // cysrConfig().feature_available("asset_change_source") ? (
                  }
                  {(features && features["asset_change_source"] && features["asset_change_source"] != "false") ? (
                    <>
                      {srcValue(views.asset.asset_uuid, views.asset.assetsrc.assetsrc_longname) && srcValue(views.asset.asset_uuid, views.asset.assetsrc.assetsrc_longname).value && srcValue(views.asset.asset_uuid, views.asset.assetsrc.assetsrc_longname).value === "manual" ? (
                        <>
                          <CysrDropdown data={
                            {
                              asset: views.asset,
                              isDisabled: true,
                              isLoading: selectAssetSrcLoading,
                              options: assetSrcArray,
                              value: srcValue(views.asset.asset_uuid, views.asset.assetsrc.assetsrc_longname),
                              defaultValue: srcValue(views.asset.asset_uuid, views.asset.assetsrc.assetsrc_longname)
                            }
                          }
                          />
                          {
                            /*
                          }
                          <Select isSearchable={false} options={assetSrcArray} defaultValue={srcValue(views.asset.asset_uuid, views.asset.assetsrc.assetsrc_longname)} styles={colourStyles} isDisabled components={{ DropdownIndicator, Option: CustomSelectOption, SingleValue: CustomSelectValue }} />
                          {
                            */
                          }
                        </>
                      ) : (
                        <>
                          <CysrDropdown data={
                            {
                              asset: views.asset,
                              isDisabled: false,
                              isLoading: selectAssetSrcLoading,
                              options: assetSrcArray,
                              value: srcValue(views.asset.asset_uuid, views.asset.assetsrc.assetsrc_longname),
                              defaultValue: srcValue(views.asset.asset_uuid, views.asset.assetsrc.assetsrc_longname),
                              onChange: 'source'
                            }
                          }
                          />
                          {
                            /*
                          }
                          <Select isSearchable={false} options={assetSrcArray} defaultValue={srcValue(views.asset.asset_uuid, views.asset.assetsrc.assetsrc_longname)} styles={selectAssetSrcLoading ? loadingStyles : colourStyles} onChange={(newValue) =>{
                            handleChangeSrc(views.asset, newValue.value)
                          }} components={{ DropdownIndicator, Option: CustomSelectOption, SingleValue: selectAssetSrcLoading ? CustomSelectValueLoading : CustomSelectValue }} />
                          {
                            */
                          }
                        </>
                      )}
                    </>
                  ) :
                    <MDTypography variant="button" color="text" style={{ display: "block", lineHeight: "32px" }}>
                      Source is <strong>{srcValue(views.asset.asset_uuid, views.asset.assetsrc.assetsrc_longname).value.toLowerCase()}</strong>
                    </MDTypography>
                  }
                </>
              )}
            </Grid>
            {
              /*
                }
                <Grid item>
                  <Link
                    to={`/company/${accountUUID}/${"assets"}#tag=${
                      views.asset.assetsrc.assetsrc_longname
                    }`}
                  >
                    <MDBadge
                      variant="contained"
                      color="info"
                      size="xs"
                      badgeContent={views.asset.assetsrc.assetsrc_longname}
                      container
                    />
                  </Link>
                  {views && views.asset && views.asset.assetsrc && views.asset.assetsrc.assetsrc_longname && views.asset.assetsrc.assetsrc_longname != "manual" && (
                    <MDButton style={{marginLeft:"8px"}} color="info" onClick={() => assetToManual(views.asset)}>
                      convert to manual
                    </MDButton>
                  )}
                </Grid>
                {
              */
            }
            <Grid item>
              {
                // cysrConfig().feature_available("asset_recheck") && (
              }
              {(features && features["asset_recheck"] && features["asset_recheck"] != "false") ? (
                <FullScreenDialog />
              ) : null}
            </Grid>
            {
              // cysrConfig().feature_available("asset_set_not_mine") && (
            }
            {(features && features["asset_set_not_mine"] && features["asset_set_not_mine"] != "false") ? (
              <Grid item>
                <MDButton
                  style={cysrConfig().button_style}

                  onClick={removeAssetToggle}
                >
                  <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().not_mine.icon.svg }} />
                  Not mine
                </MDButton>
                <MDSnackbar
                  bgWhite
                  color="warning"
                  icon="warning"
                  title="Action confirmation"
                  content={
                    <Grid container spacing={1}>
                      <Grid item xs={12} textAlign="left">
                        Do you confirm the removal of this asset?
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item>
                            <MDButton
                              variant="gradient"
                              color="info"

                              onClick={removeAssetToggle}
                            >
                              Cancel
                            </MDButton>
                          </Grid>
                          <Grid item>
                            <MDButton variant="gradient" color="error" onClick={removeAsset}>
                              Not mine
                            </MDButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                  dateTime=""
                  open={show}
                  close={toggleSnackbar}
                />
              </Grid>
            ) : null}

          </Grid>
        </Grid>
      </>
    );
  }
  return "";
}
/* eslint-disable */
