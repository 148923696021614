/* eslint-disable */
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import OptionIcon from '@mui/icons-material/FilterAlt';
import OptionDeleteIcon from '@mui/icons-material/Close';
import MDTypography from "components/MDTypography";

export default function CysrTags({ tableTagsOptions, tableTagsActive, handleSetTableTags, darkMode }) {
  return <Autocomplete
    freeSolo
    multiple
    size="small"
    options={tableTagsOptions}
    getOptionLabel={(option) => {
      return option.rating ? option.rating : option;
    }}
    defaultValue={tableTagsActive}
    value={tableTagsActive}
    onChange={handleSetTableTags}
    renderTags={(value, getTagProps) => value.map(function (option, index) {
      let label = option.rating ? option.rating : option;
      let descriptiveLabel = false;
      let chipColor = darkMode ? 'rgba(0,0,0,.85)' : 'rgb(250, 250, 250)';
      // let color = "secondary";
      let icon = null;
      // "outlined", "filled";
      let variant = "filled";
      if (typeof option === 'string' && option.includes(":")) {
        const tag = option.toLowerCase().split(":");
        label = tag[1];
        descriptiveLabel = tag[0];
        descriptiveLabel = descriptiveLabel[0].toUpperCase() + descriptiveLabel.substring(1);
        if (descriptiveLabel === "Rating") {
          label = label[0].toUpperCase() + label.substring(1);
        }
        // color = "default";
        icon = <OptionIcon sx={{ color: `${darkMode ? 'rgb(69, 159, 226)' : 'rgb(9, 181, 168)'}!important` }} />;
        chipColor = darkMode ? 'hsl(205deg 73% 6%)' : 'hsl(214deg 82% 94%)';
      } else if (option.rating) {
        descriptiveLabel = "Rating";
        // color = "default";
        icon = <OptionIcon sx={{ color: `${darkMode ? 'rgb(69, 159, 226)' : 'rgb(9, 181, 168)'}!important` }} />;
        chipColor = darkMode ? 'hsl(205deg 73% 6%)' : 'hsl(214deg 82% 94%)';
      }
      return (
        <Chip
          sx={{ color: `${darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgba(0, 0, 0, 0.85)'}!important`, border: `${darkMode ? '1px solid rgb(48, 48, 48)' : '1px solid rgb(232, 232, 232)'}!important`, background: `${chipColor}!important`, borderRadius: 0, fontFamily: "Ubuntu Mono, monospace" }}
          icon={icon}
          // color={color}
          variant={variant}
          label={descriptiveLabel ? <div style={{ alignItems: "center", gap: "4px", display: "flex" }}><MDTypography sx={{ fontSize: "11px", color: `${darkMode ? 'rgb(69, 159, 226)' : 'rgb(9, 181, 168)'}!important`, opacity: .85, transform: "translateX(-2px)" }}><strong style={{ fontWeight: 500 }}>{descriptiveLabel}</strong>:</MDTypography>{label}</div> : label}
          size="small"
          deleteIcon={<OptionDeleteIcon sx={{ color: `${darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgba(0, 0, 0, 0.85)'}!important` }} />}
          {...getTagProps({ index })} />
      );
    })}
    renderInput={(params) => (
      <TextField
        {...params}
        // variant="outlined"
        InputLabelProps={{ shrink: true }}
        label="Type and Press ENTER to filter, deep search with y:x"
        size="medium"
        sx={{ color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#121619' : '#f3f4f7' }}
        placeholder="Tags and search" />
    )} />;
}

/*


        <Autocomplete
        freeSolo
        multiple
        size="small"
        options={tableTagsOptions}
        getOptionLabel={(option) => {
            return option.title ? option.title : option
        }}
        defaultValue={tableTagsActive}
        onChange={handleSetTableTags}
        renderTags={(value, getTagProps) =>
            value.map(function(option, index) {
              let label = option.title ? option.title : option;
              let hexColor = darkMode ? 'rgba(0,0,0,.85)' : 'rgb(250, 250, 250)';
              // let color = "secondary";
              let icon = null;
              // "outlined", "filled";
              let variant = "filled";
              if(typeof option === 'string' && option.includes(":")){
                const tag = option.toLowerCase().split(":");
                label = tag[1];
                // color = "default";
                icon = <OptionIcon sx={{color: `${darkMode ? 'rgb(69, 159, 226)' : 'rgb(9, 181, 168)'}!important`}} />
              }
              return (
                <Chip
                    sx={{color: `${darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgba(0, 0, 0, 0.85)'}!important`, border: `${darkMode ? '1px solid rgb(48, 48, 48)' : '1px solid rgb(232, 232, 232)'}!important`,background:`${hexColor}!important`,borderRadius: 0, fontFamily: "Ubuntu Mono, monospace"}}
                    icon={icon}
                    // color={color}
                    variant={variant}
                    label={label}
                    size="small"
                    deleteIcon={<OptionDeleteIcon sx={{color: `${darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgba(0, 0, 0, 0.85)'}!important`}} />}
                    {...getTagProps({ index })}
                />
              )
            })
        }
        renderInput={(params) => (
            <TextField
            {...params}
            variant="outlined"
            label="Type and Press ENTER to filter"
            sx={{color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgb(20, 20, 20)', background: darkMode ? '#121619' : '#f3f4f7'}}
            placeholder="Tags and search"
            />
        )}
        />

        */
/* eslint-disable */