/* eslint-disable */
// prettier-ignore
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// import Footer from "examples/Footer";
import { useLocation } from "react-router-dom";
import loadingUrl from "layouts/cysr/images/loading.svg";
import DashboardCompanyInfo from "layouts/cysr/components/DashboardCompanyInfo";

import Loader from "layouts/cysr/components/Loader";
import cysrConfig, { process } from "layouts/cysr/config";
import { useMaterialUIController } from "context";

import ScoreItem from "layouts/cysr/components/ScoreItem";
// +
import { CompanyDPO } from "layouts/cysr/components/CompanyDPO";

import { Separator } from "layouts/cysr/components/ui/separator";
import { Timeline } from "layouts/cysr/components/timeline";
import {
  Card as CardIn,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "layouts/cysr/components/ui/card";

import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "layouts/cysr/components/ui/accordion";
import { format } from "date-fns";
import { AccordionSummary } from "@mui/material";
import { ImportantMessage } from "../../../ImportantMessage";

import {
  Flex
} from "@tremor/react";

import {
  badgeNumberMaxLengthForDecimal,
  valueFormatter
} from "layouts/cysr/risk"

// -
export default function SelectedCompany({ cysr_company, cti = null, dpo = null, timeshift = null, features = null, setFeatures = null, risk_actual = null }) {
  const location = useLocation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  // cti timeline s
  let ctiHistoryState = null;
  if (cti && cti.rating && cti.rating.rating_history) {
    const ctiData = [];
    Object.entries(cti.rating.rating_history).forEach(t => {
      ctiData.push({
        date: format(new Date(t[0]), "yyyy-MM-dd"),
        value: parseInt(t[1], 10)
      });
    });
    ctiHistoryState = {
      data: {
        "labels": [
          "CTI Rating score"
        ],
        "datasets": [
          {
            "label": "CTI Rating",
            "data": ctiData
          }
        ]
      },
      options: {
        // "title": "Line (time series) - Time interval hourly with custom ticks formats ('MMM d, HH:mm' and 'HH:mm')",
        "title": false,
        "axes": {
          "left": {},
          "bottom": {
            "scaleType": "time"
          }
        },
        "timeScale": {
          "timeIntervalFormats": {
            "hourly": {
              "primary": "MMM d, HH:mm",
              "secondary": "HH:mm"
            }
          }
        },
        "legend": {
          "enabled": false
        },
        "height": "100%",
        "toolbar": { "enabled": false },
      }
    }
  }
  // cti timeline e
  // let coloredBg = "#cddddf";
  // let coloredBg = "#dfebfb";
  // let coloredBg = "#e5e9e9";
  let coloredBg = "#ffffff";
  if (darkMode) {
    // coloredBg = "#153b65";
    // coloredBg = "#03353a";
    // coloredBg = "#192328";
    coloredBg = "#192328";
  }
  const [isLoading, setLoading] = useState(true);
  const [views, setViews] = useState([]);
  // const selectedCompanyID = localStorage.getItem("cysr_useraccountrole_selected");
  // fix multi tabs s
  let selectedCompanyID = localStorage.getItem("cysr_useraccountrole_selected");
  if (window.location.pathname.indexOf("/company/") > -1) {
    const fixAccountUUID = window.location.pathname.substring(
      window.location.pathname.indexOf("/company/") + "/company/".length,
      window.location.pathname.lastIndexOf("/")
    );
    if (fixAccountUUID) {
      if (selectedCompanyID !== fixAccountUUID) {
        selectedCompanyID = fixAccountUUID;
        localStorage.setItem("cysr_useraccountrole_selected", fixAccountUUID)
      }
    }
  }
  // fix multi tabs e
  let ratingVal = 0;
  let ratingImage = loadingUrl;
  if (selectedCompanyID) {
    const accountUUID = selectedCompanyID;
    // const dashboardCallUrl = `${cysrConfig().api_base_url}dashboard/account/${accountUUID}/`;
    // const dashboardCallUrl = `${cysrConfig().api_base_url}dashboard/mainpage/${accountUUID}/`;
    const dashboardCallUrl = `${cysrConfig().api_base_url}dashboard/pageheader/${accountUUID}/`;
    useEffect(() => {
      setLoading(true);
      fetch(dashboardCallUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
      })
        .then((response) => {
          if (response.status === 401) {
            // remove localstorage and go to login
            const dMode = localStorage.getItem('cysr_dark_mode') ? localStorage.getItem('cysr_dark_mode') : "true";
            localStorage.clear();
            localStorage.setItem('cysr_dark_mode', dMode);
            window.location.href = `/cysr/login`;
            return
          }
          return response.json()
        })
        .then((responseDashboardAccountData) => {

          if (responseDashboardAccountData.code && responseDashboardAccountData.code === "token_not_valid") {
            // remove localstorage and go to login
            const dMode = localStorage.getItem('cysr_dark_mode') ? localStorage.getItem('cysr_dark_mode') : "true";
            localStorage.clear();
            localStorage.setItem('cysr_dark_mode', dMode);
            window.location.href = `/cysr/login`;
            return
          }
          if (responseDashboardAccountData && responseDashboardAccountData.ErrorMessage && responseDashboardAccountData.ErrorMessage === "Unathorized") {
            if (cysr_company) {
              console.log("Unathorized cysr_company")
              console.log(cysr_company)
              localStorage.setItem("cysr_features", "{}")

              const tData = [];
              if (Array.isArray(timeLineData) === false) {
                timeLineData = [];
              }
              timeLineData.forEach(t => {
                // "date": "2020-12-10T21:00:00.000Z",
                // "value": 10
                tData.push({
                  date: t.mu_created_at,
                  value: parseInt(t.rating_val, 10)
                });
              });
              historyState = {
                data: {
                  "labels": [
                    "Rating score"
                  ],
                  "datasets": [
                    {
                      "label": "Rating",
                      "data": tData
                    }
                  ]
                },
                options: {
                  // "title": "Line (time series) - Time interval hourly with custom ticks formats ('MMM d, HH:mm' and 'HH:mm')",
                  "title": false,
                  "axes": {
                    "left": {},
                    "bottom": {
                      "scaleType": "time"
                    }
                  },
                  "timeScale": {
                    "timeIntervalFormats": {
                      "hourly": {
                        "primary": "MMM d, HH:mm",
                        "secondary": "HH:mm"
                      }
                    }
                  },
                  "legend": {
                    "enabled": false
                  },
                  "height": "100%",
                  "toolbar": { "enabled": false },
                }
              };
              setViews({
                accounts_role: cysr_company,
                unathorized: true,
                third_party: cysr_company === "third-party" ? true : false,
                timeline: historyState,
                isSubscriptionPreview: (cysr_company && cysr_company !== "edit_info" && cysr_company !== true) ? true : false,
              })
              setLoading(false);
              return
            }
          }
          // set values of boxes
          if (
            responseDashboardAccountData &&
            responseDashboardAccountData.rating_actual &&
            responseDashboardAccountData.rating_actual.rating_val &&
            responseDashboardAccountData.rating_actual.rating_val != null
          ) {
            ratingVal = responseDashboardAccountData.rating_actual.rating_val;
            ratingImage = `${cysrConfig().image_rating_base_url}${ratingVal}.svg`;
          } else {
            ratingImage = `${cysrConfig().image_rating_base_url}no-rating.svg`;
          }
          if (responseDashboardAccountData.features) {
            if (setFeatures != null) {
              setFeatures(responseDashboardAccountData.features)
            }
            localStorage.setItem("cysr_features", JSON.stringify(responseDashboardAccountData.features))
          }
          /*
          const nAssetsNumber = responseDashboardAccountData.assets.summarized.asset_count_total || 0;
          const nDomainsNumber = responseDashboardAccountData.assets.summarized.asset_count_by_type.domain || 0;
          const nIpsNumber = responseDashboardAccountData.assets.summarized.asset_count_by_type.ip || 0;
          const nEmailsNumber = responseDashboardAccountData.assets.summarized.asset_count_by_type.email || 0;
          const nApplicationsNumber = responseDashboardAccountData.assets.summarized.asset_count_by_type.app || 0;
          const nHostsNumber = responseDashboardAccountData.assets.summarized.asset_count_by_type.host || 0;
          const nOthersNumber = responseDashboardAccountData.assets.summarized.asset_count_by_type.others || 0;
          const nAssetsManualNumber = responseDashboardAccountData.assets.summarized.asset_count_by_source.manual || 0;
          const nAssetsCheckerNumber = responseDashboardAccountData.assets.summarized.asset_count_by_source.checker || 0;
          const nAssetsAutoNumber = responseDashboardAccountData.assets.summarized.asset_count_by_source.auto || 0;
          */
          // console.log(alluvialData);
          setViews({
            accounts_role: responseDashboardAccountData,
            // assets: responseDashboardAccountData.assets,
            rating: ratingVal,
            ratingImageSrc: ratingImage,
            isDemo: responseDashboardAccountData.subscription && responseDashboardAccountData.subscription.is_demo ? responseDashboardAccountData.subscription.is_demo : false,
            third_party: cysr_company === "third-party" ? true : false,
            isSubscriptionPreview: (cysr_company && cysr_company !== "edit_info" && cysr_company !== true) ? true : false,
            subscriptionPreviewLabel: responseDashboardAccountData.subscription && responseDashboardAccountData.subscription.type_longname ? responseDashboardAccountData.subscription.type_longname : null,
          })
          setLoading(false);
        })
        .catch((err) => {
          console.error('err')
          setLoading(false);
        });
    }, [location]);
  }
  return (
    selectedCompanyID ? <div>
      <MDBox mb={1} p={3} style={{ background: coloredBg, position: "relative" }}>
        <div style={{
          position: "absolute",
          right: "-80vw",
          background: "inherit",
          display: "block",
          left: "-80vw",
          bottom: 0,
          top: 0,
          zIndex: "-1",
        }} />
        {isLoading ? (
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={2}>
                <Loader />
              </Grid>
              <Grid item container spacing={3} xs={12} md={8} lg={10}>
                <Grid item container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Loader />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Loader />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Loader />
                    <Grid item container spacing={3} mt={1}>
                      <Grid item xs={6}>
                        <Loader />
                      </Grid>
                      <Grid item xs={6}>
                        <Loader />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container spacing={3}>
                  <Grid item xs={12} sm={4} md={3} lg={2}>
                    <Loader />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={2}>
                    <Loader />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={2}>
                    <Loader />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={2}>
                    <Loader />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={2}>
                    <Loader />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
        ) : (
          <>
            <MDTypography style={{
              cursor: "pointer", width: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis",
              maxWidth: "calc(100vw - 500px)", minWidth: "86%"
            }} onClick={() => {
              if (views.unathorized) {
                // does not have link
              } else {
                // if available set the current account uuuid as selected
                if (views.accounts_role.account_uuid) {
                  localStorage.setItem("cysr_useraccountrole_selected", views.accounts_role.account_uuid)
                  if (cysr_company === "third-party") {
                    window.location.href = `/third-party/${views.accounts_role.account_uuid}/overview`
                  } else {
                    window.location.href = `/company/${views.accounts_role.account_uuid}/overview`
                  }
                }
                // if available set the current account uuuid as selected e
              }
            }} variant="h5">
              {
                // views.accounts_role.company_profile && views.accounts_role.company_profile.account_name ? views.accounts_role.company_profile.account_name : ""} {views.accounts_role && views.accounts_role.company_profile && views.accounts_role.company_profile.account_vat_name && `(${views.accounts_role.company_profile.account_vat_name})`
              }
              {
                views.accounts_role.company_profile && views.accounts_role.company_profile.account_name ?
                  views.accounts_role.company_profile.account_name : ""}
              {views && views.accounts_role && views.accounts_role.company_profile && views.accounts_role.company_profile.account_autodiscovery_enabled === false
                &&
                <span
                  style={{
                    marginLeft: "4px",
                    display: "inline-block",
                    transform: "translateY(-4px)",
                    borderRadius: 0,
                    background: "#121827",
                    color: "#ffffff",
                    fontWeight: 700,
                    fontSize: "11px",
                    height: "auto",
                    lineHeight: 1,
                    padding: "3px 6px",
                    textAlign: "center",
                    textTransform: "uppercase",
                    verticalAlign: "baseline",
                    whiteSpace: "nowrap"
                  }}>Auto discovery disabled</span>
              }
              {cysr_company === "third-party"
                ? <span
                  style={{
                    marginLeft: "4px",
                    display: "inline-block",
                    transform: "translateY(-4px)",
                    borderRadius: 0,
                    background: "#121827",
                    color: "#ffffff",
                    fontWeight: 700,
                    fontSize: "11px",
                    height: "auto",
                    lineHeight: 1,
                    padding: "3px 6px",
                    textAlign: "center",
                    textTransform: "uppercase",
                    verticalAlign: "baseline",
                    whiteSpace: "nowrap"
                  }}>Third Party</span>
                : cysr_company !== false && cysr_company !== "edit_info" && cysr_company !== true && <span
                  style={{
                    marginLeft: "4px",
                    display: "inline-block",
                    transform: "translateY(-4px)",
                    borderRadius: 0,
                    background: "#121827",
                    color: "#ffffff",
                    fontWeight: 700,
                    fontSize: "11px",
                    height: "auto",
                    lineHeight: 1,
                    padding: "3px 6px",
                    textAlign: "center",
                    textTransform: "uppercase",
                    verticalAlign: "baseline",
                    whiteSpace: "nowrap"
                  }}>{views && views.subscriptionPreviewLabel ? views.subscriptionPreviewLabel : cysr_company}</span>
              }
            </MDTypography>
            <Grid container mt={0} spacing={3} alignItems="center">
              <Grid item style={{
                flex: 1
              }}>
                {
                  // <Grid item xs={7} md={8} lg={10}>
                }
                <MDBox m={0} p={0}>
                  {views && views.accounts_role ? <DashboardCompanyInfo useraccountrole={views} /> : null}
                </MDBox>
              </Grid>
              {views.unathorized ? null :
                <Grid item style={{ padding: 0, marginLeft: "auto" }}>
                  <MDBox style={{
                    position: "relative"
                  }} marginTop={{ xs: "16px", md: "-27.5px" }} height={{ xs: "112px", md: "160px" }}>
                    {views.ratingImageSrc ? (
                      <img alt="Rating" src={views.ratingImageSrc || ratingImage} height="100%" />
                    ) : (
                      <img alt="Rating" src={ratingImage} height="100%" />
                    )}
                    <div
                      className={
                        `${darkMode ? "dark" : "light"} gap-4 flex absolute bottom-0 top-0 right-0`
                      }
                      style={
                        {
                          // ff visual bug
                          paddingRight: "calc(100% * 98 / 160 + 1.5rem)"
                        }
                      }
                    >
                      {
                        // </div><div className="mr-6 aaaspect-[98.88/160] w-auto flex flex-col">
                      }
                      <div className="mr-6 w-auto flex flex-col">
                        {[
                          {
                            background: process.env.NEXT_PUBLIC_RATING_EXCELLENT_COLOR,
                            label: process.env.NEXT_PUBLIC_RATING_EXCELLENT_LABEL,
                            from: process.env.NEXT_PUBLIC_RATING_EXCELLENT_FROM,
                            to: process.env.NEXT_PUBLIC_RATING_EXCELLENT_TO,
                          },
                          {
                            background: process.env.NEXT_PUBLIC_RATING_GOOD_COLOR,
                            label: process.env.NEXT_PUBLIC_RATING_GOOD_LABEL,
                            from: process.env.NEXT_PUBLIC_RATING_GOOD_FROM,
                            to: process.env.NEXT_PUBLIC_RATING_GOOD_TO,
                          },
                          {
                            background: process.env.NEXT_PUBLIC_RATING_FAIR_COLOR,
                            label: process.env.NEXT_PUBLIC_RATING_FAIR_LABEL,
                            from: process.env.NEXT_PUBLIC_RATING_FAIR_FROM,
                            to: process.env.NEXT_PUBLIC_RATING_FAIR_TO,
                          },
                          {
                            background: process.env.NEXT_PUBLIC_RATING_POOR_COLOR,
                            label: process.env.NEXT_PUBLIC_RATING_POOR_LABEL,
                            from: process.env.NEXT_PUBLIC_RATING_POOR_FROM,
                            to: process.env.NEXT_PUBLIC_RATING_POOR_TO,
                          },
                          {
                            background: process.env.NEXT_PUBLIC_RATING_BAD_COLOR,
                            label: process.env.NEXT_PUBLIC_RATING_BAD_LABEL,
                            from: process.env.NEXT_PUBLIC_RATING_BAD_FROM,
                            to: process.env.NEXT_PUBLIC_RATING_BAD_TO,
                          },
                        ].map((l, i, { length }) => (
                          <div className="flex h-full items-center relative">
                            {
                              // add border-t to first
                              i === 0 ? (
                                <div className="text-[10px] text-turquoise-700/75 dark:text-turquoise-300/75 border-b-[0.5px] border-b-turquoise-700/75 dark:border-b-turquoise-300/75 -left-2 w-6 h-4 absolute leading-none -top-[16px] text-right font-mono">
                                  <span className="w-full absolute top-2.5 -left-[30px]">
                                    100
                                  </span>
                                </div>
                              ) : (
                                i + 1 === length && (
                                  <div className="text-[10px] text-turquoise-700/75 dark:text-turquoise-300/75 border-b-[0.5px] border-b-turquoise-700/75 dark:border-b-turquoise-300/75 -left-2 w-6 h-4 absolute leading-none bottom-0 text-right font-mono">
                                    <span className="w-full absolute top-2.5 -left-[30px]">
                                      0
                                    </span>
                                  </div>
                                )
                              )
                            }
                            {(l.from === views.rating || l.from < views.rating) &&
                              (l.to === views.rating || l.to > views.rating) ? (
                              <div className="hidden dark:text-white text-black absolute -left-80 w-80 md:flex flex-col gap-0.5 text-right items-end">
                                <span className="mr-2 font-bold text-lg flex gap-1.5">
                                  Your last rating is:
                                  <span className="font-medium text-xl lowercase">
                                    <ScoreItem
                                      item_style="boxless"
                                      textual={true}
                                      scoreValue={
                                        views.rating ? views.rating : process.env.NEXT_PUBLIC_RATING_NOT_RATED_LABEL
                                      }
                                    />
                                  </span>
                                </span>
                              </div>
                            ) : (
                              // <span className="pr-2 text-xs text-right absolute w-20 -left-20 text-turquoise-400 dark:text-turquoise-600">
                              <span className="pr-2 text-xs text-right absolute w-20 -left-20 text-black dark:text-white">
                                {l.label}
                              </span>
                            )}
                            <div
                              className="h-full w-4"
                              style={{
                                background: l.background,
                              }}
                            >
                              <span className="sr-only">{l.label}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </MDBox>
                </Grid>
              }
              {
                // risk badge s
              }
              {views.unathorized ? null : views && views.isSubscriptionPreview && risk_actual
                ? Object.values(risk_actual).map(
                  (r_type) => {
                    const method = r_type.CALCULATED;
                    return (
                      <Grid className="hidden md:flex" item style={{
                        paddingLeft: 0,
                        paddingTop: 0
                      }}>
                        <MDBox style={{
                          position: "relative"
                        }} marginTop={{ xs: "16px", md: "-27.5px" }} height={{ xs: "112px", md: "160px" }}>
                          <Flex className="ml-auto relative max-w-[124px] justify-end" style={
                            {
                              transform: "scale(0.8163)",
                              transformOrigin: "100% 0",
                              color: getRatingColorRiskActual(method),
                            }
                          }>
                            <svg
                              className="max-w-[124px] w-full h-auto inline-flex" width="124" height="196" viewBox="0 0 124 196" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0 0V195.96H123.34V0H0ZM117.52 190.14H5.82V5.82H39.57V26.17H46.16V5.82H52.35V26.17H58.94V5.82H65.15L65.12 33.69H71.71L71.68 5.82H77.89V26.17H84.48V5.82H117.52V190.14Z" fill="currentColor" />
                            </svg>
                            <span style={
                              {
                                left: 0,
                                bottom: 132,
                                color: "inherit",
                                right: 0,
                                fontSize: 14,
                                fontWeight: 500,
                                textAlign: "center"
                              }
                            } className='absolute'>
                              {method.companyrisk_type ? method.companyrisk_type : method.companyrisk_scenario}
                            </span>
                            <span style={
                              {
                                left: 0,
                                bottom: 84,
                                color: "inherit",
                                right: 0,
                                fontSize: 32,
                                fontWeight: 500,
                                textAlign: "center"
                              }
                            } className='absolute'>
                              {
                                // using lm
                                // valueFormatter(views.risk[selectedTab].riskData.badge.lm.averageValue, 1)
                              }
                              {
                                // valueFormatter(method.companyrisk_risk_avg, 2)
                              }
                              {
                                valueFormatter(method.companyrisk_risk_avg, 1).length > badgeNumberMaxLengthForDecimal ?
                                  valueFormatter(method.companyrisk_risk_avg, 0)
                                  : valueFormatter(method.companyrisk_risk_avg, 1)
                              }
                            </span>
                            <span style={
                              {
                                bottom: 74,
                                color: "inherit",
                                left: 12,
                                right: 68,
                                fontSize: 12,
                                fontWeight: 500,
                                textAlign: "center",
                              }
                            } className='absolute'>
                              min
                            </span>
                            <span style={
                              {
                                bottom: 56,
                                color: "inherit",
                                left: 12,
                                right: 68,
                                fontSize: 14,
                                fontWeight: 500,
                                textAlign: "center",
                              }
                            } className='absolute'>
                              {
                                // using lm
                                // valueFormatter(views.risk[selectedTab].riskData.badge.lm.maxValue < views.risk[selectedTab].riskData.badge.lm.minValue ? views.risk[selectedTab].riskData.badge.lm.maxValue : views.risk[selectedTab].riskData.badge.lm.minValue, 1)
                                // valueFormatter(method.companyrisk_risk_max < method.companyrisk_risk_min ? method.companyrisk_risk_max : method.companyrisk_risk_min, 2)
                              }
                              {
                                valueFormatter(method.companyrisk_risk_max < method.companyrisk_risk_min ?
                                  method.companyrisk_risk_max
                                  : method.companyrisk_risk_min, 2).length > badgeNumberMaxLengthForDecimal ?
                                  valueFormatter(method.companyrisk_risk_max < method.companyrisk_risk_min ?
                                    method.companyrisk_risk_max
                                    : method.companyrisk_risk_min, 0)
                                  :
                                  valueFormatter(method.companyrisk_risk_max < method.companyrisk_risk_min ?
                                    method.companyrisk_risk_max
                                    : method.companyrisk_risk_min, 1)
                              }
                            </span>
                            <span style={
                              {
                                bottom: 74,
                                color: "inherit",
                                left: 52,
                                right: 52,
                                fontSize: 8,
                                fontWeight: 400,
                                textAlign: "center",
                              }
                            } className='absolute'>
                              {
                                // to
                              }
                            </span>
                            <span style={
                              {
                                bottom: 74,
                                color: "inherit",
                                left: 68,
                                right: 12,
                                fontSize: 12,
                                fontWeight: 500,
                                textAlign: "center",
                              }
                            } className='absolute'>
                              max
                            </span>
                            <span style={
                              {
                                bottom: 56,
                                color: "inherit",
                                left: 68,
                                right: 12,
                                fontSize: 14,
                                fontWeight: 500,
                                textAlign: "center",
                              }
                            } className='absolute'>
                              {
                                // using lm
                                // valueFormatter(views.risk[selectedTab].riskData.badge.lm.minValue > views.risk[selectedTab].riskData.badge.lm.maxValue ? views.risk[selectedTab].riskData.badge.lm.minValue : views.risk[selectedTab].riskData.badge.lm.maxValue, 1)}
                                // valueFormatter(method.companyrisk_risk_min > method.companyrisk_risk_max ? method.companyrisk_risk_min : method.companyrisk_risk_max, 2)
                              }
                              {
                                valueFormatter(method.companyrisk_risk_min > method.companyrisk_risk_max ? method.companyrisk_risk_min : method.companyrisk_risk_max, 2).length > badgeNumberMaxLengthForDecimal ?
                                  valueFormatter(method.companyrisk_risk_min > method.companyrisk_risk_max ? method.companyrisk_risk_min : method.companyrisk_risk_max, 0) :
                                  valueFormatter(method.companyrisk_risk_min > method.companyrisk_risk_max ? method.companyrisk_risk_min : method.companyrisk_risk_max, 1)
                              }
                            </span>
                            <span style={
                              {
                                left: 0,
                                bottom: 32,
                                color: "inherit",
                                right: 0,
                                fontSize: 15,
                                fontWeight: 500,
                                textAlign: "center"
                              }
                            } className='absolute'>
                              {
                                method.companyrisk_lef_avg < 0.1 ? "< 1 event" : `${(Math.ceil(1 / method.companyrisk_lef_avg) * method.companyrisk_lef_avg).toFixed(2)} events`}
                            </span>
                            <span style={
                              {
                                left: 0,
                                bottom: 14,
                                color: "inherit",
                                right: 0,
                                fontSize: 16,
                                fontWeight: 500,
                                textAlign: "center"
                              }
                            } className='absolute'>
                              {
                                // method.companyrisk_lef_avg < 0.1 ? "Less than 1 event" : `${(Math.ceil(1 / method.companyrisk_lef_avg) * method.companyrisk_lef_avg).toFixed(2)} events in ${Math.ceil(1 / method.companyrisk_lef_avg)} years`}
                                method.companyrisk_lef_avg < 0.1 ? "in 10 year" : `in ${Math.ceil(1 / method.companyrisk_lef_avg)} years`}
                            </span>
                            {
                              /*
                          }
                          {method.companyrisk_lef_avg
                          }{views.risk[selectedTab].riskData.badge.lef.maxValue
         
                          }{views.risk[selectedTab].riskData.badge.lm.minValue
                          }{views.risk[selectedTab].riskData.badge.lm.averageValue
                          }{views.risk[selectedTab].riskData.badge.lm.maxValue
                          }
                          {
                              */
                            }
                          </Flex>
                        </MDBox>
                      </Grid>
                    )
                  }
                )
                : null}
              {
                // risk badge e
              }
            </Grid>
          </>
        )}</MDBox>
      {views.isDemo && <div className="cysr-demo-bar">Demo version</div>}

      {cti && <div className={`${darkMode ? "dark " : ""}w-full`}>
        <div className="md:flex w-full gap-4 my-4">
          <CardIn className="md:w-1/3 rounded-md">
            <CardHeader
            //</CardIn> className="flex flex-row items-center justify-between space-y-0 pb-2"
            >
              <CardTitle>
                {process.env.NEXT_PUBLIC_STRING_MODULE_200_CODE} Score
              </CardTitle>
              <CardDescription>
                {process.env.NEXT_PUBLIC_STRING_MODULE_200_DESCRIPTION}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <MDBox p={1}>
                {cti && cti.rating && cti.rating.rating_last && <ScoreItem scoreValue={cti.rating.rating_last} textual={true} item_style="big" />}
              </MDBox>
              <MDBox px={3}>
                <Grid container spacing={3}>
                  <Grid item my="auto" ml="auto">
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      <strong>updated</strong> on <strong>{
                        (cti.summary && cti.summary.last_update_date) ? format
                          (
                            new Date(cti.summary.last_update_date),
                            process.env.NEXT_PUBLIC_DATE_FORMAT ? process.env.NEXT_PUBLIC_DATE_FORMAT : "dd/MM/yyyy"
                          ) : process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE
                      }</strong>
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
            </CardContent>
          </CardIn>
          <CardIn className="md:w-2/3 rounded-md">
            <CardHeader>
              <CardTitle>Progress</CardTitle>
              {false && <CardDescription></CardDescription>}
            </CardHeader>
            <CardContent>
              {ctiHistoryState ? (
                <div style={{
                  /*
                  overflowX: "auto",
                  // so it start at the end of overflow:
                  display: "flex",
                  flexDirection: "row-reverse"
                  */
                  width: "100%",
                }}>
                  <Timeline
                    // data={company.assets.graphs.timeline} 
                    data={ctiHistoryState.data.datasets[0].data}
                    maxHeight={"129px"}
                    minHeight={"unset"}
                  />
                </div>
              ) : (
                <MDTypography variant="button" color="text">
                  There is no timeline available for this CTI yet.
                </MDTypography>
              )}
            </CardContent>
          </CardIn>
        </div>
        {cti &&
          <>
            {
              (cti && cti.dpo) ? null : (
                <div className="flex flex-col gap-2 my-2">{
                  CompanyDPO(
                    {
                      dpo_not_approved_count: views && cti && cti.domains && cti.domains.filter((x) => x[1].dpo_approved === false).length,
                      domains: (cti && cti.domains) ? cti.domains : []
                    },
                    cti.dpo ? cti.dpo : dpo.DPOData,
                    dpo.setDPOData,
                    dpo.form,
                    dpo.onSubmit,
                    darkMode)
                }
                </div>)
            }
            {false && cti && cti.dpo && (
              <ImportantMessage
                button={"Revoke"}
                className={`${"text-left w-full border pl-11 pr-4 py-3 rounded"} border-green-500/50 bg-green-500/25`}
                title={`The Data Protection Officer (DPO) of the company, ${cti.dpo.validator_name}, approved the management of personal data by the platform on ${cti.dpo.confermation_date ? format
                  (
                    new Date(cti.dpo.confermation_date),
                    process.env.NEXT_PUBLIC_DATE_FORMAT ? process.env.NEXT_PUBLIC_DATE_FORMAT : "dd/MM/yyyy"
                  ) : process.env.NEXT_PUBLIC_STRING_TERM_0_SINGLE

                  }`}
                description={``}
              />
            )
            }
            {false && <ImportantMessage
              button={"Request"}
              className={`${"text-left w-full border pl-11 pr-4 py-3 rounded"} border-red-500/50 bg-red-500/25`}
              description={
                process.env
                  .NEXT_PUBLIC_STRING_MODULE_200_DPO_AUTH_MESSAGE_NO_DPO_SET_DESCRIPTION
              }
              title={
                process.env
                  .NEXT_PUBLIC_STRING_MODULE_200_DPO_AUTH_MESSAGE_NO_DPO_SET_TITLE
              }
            />
            }

          </>
        }
        <CardIn className="mb-2 w-full rounded-md">
          <CardHeader>
            <CardTitle>Domain list</CardTitle>
            <CardDescription>
              {timeshift
                ? `Not available in ${process.env.NEXT_PUBLIC_STRING_FEATURE_300_FEATURE} mode`
                : "This list displays the domains and their status that have been examined for Cyber Threats."}
            </CardDescription>
          </CardHeader>
          {timeshift ? null : (
            <CardContent>
              <Accordion className="mt-0 pt-0" type="single" collapsible>
                <AccordionItem value="item-1">
                  <AccordionTrigger className="m-0 p-0">
                    {timeshift ? (
                      <p className="text-base"></p>
                    ) : (
                      <p className="text-base">
                        {
                          // if not all approved show danger s
                          cti && cti.domains && ((cti.domains.filter((x) => x[1].dpo_approved === true).length) < cti.domains.length) && (
                            <svg
                              className="h-4 w-4 inline-flex mr-2"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.4449 0.608765C8.0183 -0.107015 6.9817 -0.107015 6.55509 0.608766L0.161178 11.3368C-0.275824 12.07 0.252503 13 1.10608 13H13.8939C14.7475 13 15.2758 12.07 14.8388 11.3368L8.4449 0.608765ZM7.4141 1.12073C7.45288 1.05566 7.54712 1.05566 7.5859 1.12073L13.9798 11.8488C14.0196 11.9154 13.9715 12 13.8939 12H1.10608C1.02849 12 0.980454 11.9154 1.02018 11.8488L7.4141 1.12073ZM6.8269 4.48611C6.81221 4.10423 7.11783 3.78663 7.5 3.78663C7.88217 3.78663 8.18778 4.10423 8.1731 4.48612L8.01921 8.48701C8.00848 8.766 7.7792 8.98664 7.5 8.98664C7.2208 8.98664 6.99151 8.766 6.98078 8.48701L6.8269 4.48611ZM8.24989 10.476C8.24989 10.8902 7.9141 11.226 7.49989 11.226C7.08567 11.226 6.74989 10.8902 6.74989 10.476C6.74989 10.0618 7.08567 9.72599 7.49989 9.72599C7.9141 9.72599 8.24989 10.0618 8.24989 10.476Z"
                                fill="currentColor"
                                fillRule="evenodd"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          )
                          // if not all approved show danger e
                        }
                        {`validated domains `}
                        <span className="font-medium">
                          {`${cti && cti.domains && cti.domains.filter((x) => x[1].dpo_approved === true).length}`}
                        </span>/
                        <span className="font-medium">
                          {`${cti && cti.domains && cti.domains.length}`}
                        </span>
                        <div className="inline-flex flex-wrap mx-2 items-center text-sm font-light">
                          (
                          {cti && cti.domains && cti.domains.map((domain, i) => {
                            const d = domain[1];
                            return d ? (
                              <div className="flex gap-2 items-center">
                                {i > 0 && ","}
                                {d.dpo_approved ? (
                                  <svg className="h-3 w-3" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.49991 0.877045C3.84222 0.877045 0.877075 3.84219 0.877075 7.49988C0.877075 11.1575 3.84222 14.1227 7.49991 14.1227C11.1576 14.1227 14.1227 11.1575 14.1227 7.49988C14.1227 3.84219 11.1576 0.877045 7.49991 0.877045ZM1.82708 7.49988C1.82708 4.36686 4.36689 1.82704 7.49991 1.82704C10.6329 1.82704 13.1727 4.36686 13.1727 7.49988C13.1727 10.6329 10.6329 13.1727 7.49991 13.1727C4.36689 13.1727 1.82708 10.6329 1.82708 7.49988ZM10.1589 5.53774C10.3178 5.31191 10.2636 5.00001 10.0378 4.84109C9.81194 4.68217 9.50004 4.73642 9.34112 4.96225L6.51977 8.97154L5.35681 7.78706C5.16334 7.59002 4.84677 7.58711 4.64973 7.78058C4.45268 7.97404 4.44978 8.29061 4.64325 8.48765L6.22658 10.1003C6.33054 10.2062 6.47617 10.2604 6.62407 10.2483C6.77197 10.2363 6.90686 10.1591 6.99226 10.0377L10.1589 5.53774Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
                                ) : (
                                  <svg className="h-3 w-3" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.4449 0.608765C8.0183 -0.107015 6.9817 -0.107015 6.55509 0.608766L0.161178 11.3368C-0.275824 12.07 0.252503 13 1.10608 13H13.8939C14.7475 13 15.2758 12.07 14.8388 11.3368L8.4449 0.608765ZM7.4141 1.12073C7.45288 1.05566 7.54712 1.05566 7.5859 1.12073L13.9798 11.8488C14.0196 11.9154 13.9715 12 13.8939 12H1.10608C1.02849 12 0.980454 11.9154 1.02018 11.8488L7.4141 1.12073ZM6.8269 4.48611C6.81221 4.10423 7.11783 3.78663 7.5 3.78663C7.88217 3.78663 8.18778 4.10423 8.1731 4.48612L8.01921 8.48701C8.00848 8.766 7.7792 8.98664 7.5 8.98664C7.2208 8.98664 6.99151 8.766 6.98078 8.48701L6.8269 4.48611ZM8.24989 10.476C8.24989 10.8902 7.9141 11.226 7.49989 11.226C7.08567 11.226 6.74989 10.8902 6.74989 10.476C6.74989 10.0618 7.08567 9.72599 7.49989 9.72599C7.9141 9.72599 8.24989 10.0618 8.24989 10.476Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
                                )}
                                <span
                                  className={`text-base font-light`}
                                >
                                  {domain[0]}
                                </span>
                              </div>
                            ) : null;
                          })}
                          )
                        </div>
                      </p>
                    )}
                  </AccordionTrigger>
                  <AccordionContent className="my-4">
                    {cti && (CompanyDPO(
                      {
                        dpo_not_approved_count: views && cti && cti.domains && cti.domains.filter((x) => x[1].dpo_approved === false).length,
                        domains: (cti && cti.domains) ? cti.domains : []
                      },
                      cti.dpo ? cti.dpo : dpo.DPOData,
                      dpo.setDPOData,
                      dpo.form,
                      dpo.onSubmit,
                      darkMode))}
                    <Separator className="my-4" />
                    {cti && cti.domains && cti.domains.map((domain) => {
                      const d = domain[1];
                      return d ? (
                        <div className="flex gap-2 items-center">
                          {d.dpo_approved ? (
                            <svg className="h-3.5 w-3.5" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.49991 0.877045C3.84222 0.877045 0.877075 3.84219 0.877075 7.49988C0.877075 11.1575 3.84222 14.1227 7.49991 14.1227C11.1576 14.1227 14.1227 11.1575 14.1227 7.49988C14.1227 3.84219 11.1576 0.877045 7.49991 0.877045ZM1.82708 7.49988C1.82708 4.36686 4.36689 1.82704 7.49991 1.82704C10.6329 1.82704 13.1727 4.36686 13.1727 7.49988C13.1727 10.6329 10.6329 13.1727 7.49991 13.1727C4.36689 13.1727 1.82708 10.6329 1.82708 7.49988ZM10.1589 5.53774C10.3178 5.31191 10.2636 5.00001 10.0378 4.84109C9.81194 4.68217 9.50004 4.73642 9.34112 4.96225L6.51977 8.97154L5.35681 7.78706C5.16334 7.59002 4.84677 7.58711 4.64973 7.78058C4.45268 7.97404 4.44978 8.29061 4.64325 8.48765L6.22658 10.1003C6.33054 10.2062 6.47617 10.2604 6.62407 10.2483C6.77197 10.2363 6.90686 10.1591 6.99226 10.0377L10.1589 5.53774Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
                          ) : (
                            <svg className="h-3.5 w-3.5" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.4449 0.608765C8.0183 -0.107015 6.9817 -0.107015 6.55509 0.608766L0.161178 11.3368C-0.275824 12.07 0.252503 13 1.10608 13H13.8939C14.7475 13 15.2758 12.07 14.8388 11.3368L8.4449 0.608765ZM7.4141 1.12073C7.45288 1.05566 7.54712 1.05566 7.5859 1.12073L13.9798 11.8488C14.0196 11.9154 13.9715 12 13.8939 12H1.10608C1.02849 12 0.980454 11.9154 1.02018 11.8488L7.4141 1.12073ZM6.8269 4.48611C6.81221 4.10423 7.11783 3.78663 7.5 3.78663C7.88217 3.78663 8.18778 4.10423 8.1731 4.48612L8.01921 8.48701C8.00848 8.766 7.7792 8.98664 7.5 8.98664C7.2208 8.98664 6.99151 8.766 6.98078 8.48701L6.8269 4.48611ZM8.24989 10.476C8.24989 10.8902 7.9141 11.226 7.49989 11.226C7.08567 11.226 6.74989 10.8902 6.74989 10.476C6.74989 10.0618 7.08567 9.72599 7.49989 9.72599C7.9141 9.72599 8.24989 10.0618 8.24989 10.476Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
                          )}
                          <span
                            className={`text-base ${process.env.NEXT_PUBLIC_CLASSES_DOMAIN_NAME}`}
                          >
                            {domain[0]}
                          </span>
                          {d.rating_last && (
                            <ScoreItem
                              item_style="boxless"
                              textual={true}
                              scoreValue={
                                d.rating_last ? d.rating_last : process.env.NEXT_PUBLIC_RATING_NOT_RATED_LABEL
                              }
                            />
                          )}
                        </div>
                      ) : null;
                    })}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </CardContent>
          )}
        </CardIn>
      </div>}
    </div > : null
  )
};

function getRatingColorRiskActual(x) {
  return `#${x.companyrisk_lef_avg > .6
    ? "E7212E"
    : x.companyrisk_lef_avg > .5
      ? "F05E25"
      : x.companyrisk_lef_avg > .4
        ? "F6921F"
        : x.companyrisk_lef_avg > .3
          ? "57A6D9"
          : x.companyrisk_lef_avg > .2
            ? "2466B0" : "CCCCCC"}`;
}
/* eslint-disable */
// prettier-ignore