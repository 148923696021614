/* eslint-disable */
// prettier-ignore
import { TextInput } from '@tremor/react';
import React from 'react';
import { useState } from 'react';
import { toast } from "sonner"

import cysrConfig from "layouts/cysr/config";
import { DownloadSharp } from '@mui/icons-material';
// Tremor Raw input component, learn more: https://raw.tremor.so/docs/inputs/input

export const focusInput = [
    // base
    'focus:ring-2',
    // ring color
    'focus:ring-blue-200 focus:dark:ring-blue-700/30',
    // border color
    'focus:border-blue-400 focus:dark:border-blue-700',
];

export const hasErrorInput = [
    // base
    'ring-2',
    // border color
    'border-red-500 dark:border-red-700',
    // ring color
    'ring-red-200 dark:ring-red-700/30',
];

const inputStyles = ({
    base: [
        // base
        'relative cursor-pointer block w-full appearance-none rounded-tremor-default border px-2.5 py-1.5 shadow-tremor-input dark:shadow-dark-tremor-input outline-none !text-tremor-default',
        // border color
        'border-tremor-border dark:border-dark-tremor-border',
        // text color
        'text-tremor-content-strong dark:text-dark-tremor-content-strong',
        // placeholder color
        'placeholder-tremor-content-subtle dark:placeholder-dark-tremor-content-subtle',
        // background color
        'bg-tremor-background dark:bg-dark-tremor-background',
        // disabled
        'disabled:bg-tremor-background-subtle disabled:text-tremor-content-subtle',
        'disabled:dark:bg-dark-tremor-background-subtle disabled:dark:text-dark-tremor-content-subtle',
        // file
        [
            'file:text-tremor-default file:-my-1.5 file:-ml-2.5 file:h-[36px] file:cursor-pointer file:rounded-l-tremor-small file:border-0 file:px-3 file:py-1.5 file:outline-none file:rounded-r-none',
            'file:border-solid file:border-tremor-border file:bg-tremor-background-muted file:text-tremor-content file:dark:bg-dark-tremor-background-muted file:dark:border-dark-tremor-border hover:file:bg-tremor-background-subtle/80 file:dark:bg-dark-tremor-background-muted hover:file:dark:bg-dark-tremor-background-subtle/30',
            'file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem]',
            'disabled:pointer-events-none file:disabled:pointer-events-none file:disabled:bg-tremor-background-subtle file:disabled:text-tremor-content file:disabled:dark:bg-dark-tremor-background-subtle file:disabled:dark:border-gray-700 focus:outline-none',
        ],
        // focus
        focusInput,
        // invalid (optional)
        // "aria-[invalid=true]:dark:ring-red-400/20 aria-[invalid=true]:ring-2 aria-[invalid=true]:ring-red-200 aria-[invalid=true]:border-red-500 invalid:ring-2 invalid:ring-red-200 invalid:border-red-500"
        // remove search cancel button (optional)
        '[&::--webkit-search-cancel-button]:hidden [&::-webkit-search-cancel-button]:hidden [&::-webkit-search-decoration]:hidden',
    ],
    hasError: {
        true: hasErrorInput,
    },
    // number input
    enableStepper: {
        true: '[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
    },
});

const Input = React.forwardRef(
    ({ className, hasError, enableStepper, type, ...props }, forwardedRef) => {
        return (
            <div className="relative">
                <TextInput className="max-w-xs mt-2"
                    ref={forwardedRef}
                    type={type}
                    //className={inputStyles.base}
                    style={{
                        fontSize: 14,
                        maxHeight: 38
                    }}
                    {...props}
                />
            </div>
        );
    },
);

Input.displayName = 'Input';

export function CtrlEvidenceArtifactsList({ controlID, data, artifactsData }) {
    console.warn({ controlID, data, artifactsData })
    const [formData, setFormData] = useState({
        artifact_name: artifactsData && artifactsData.artifact_name ? artifactsData.artifact_name : '',
        artifact_description: artifactsData && artifactsData.artifact_description ? artifactsData.artifact_description : '',
        artifact_expiration_date: artifactsData && artifactsData.artifact_expiration_date ? artifactsData.artifact_expiration_date : '',
        artifact_file: null,
        artifact_owner: artifactsData && artifactsData.artifact_owner ? artifactsData.artifact_owner : '',
        artifact_control: [controlID]
    });
    return (
        <div className="w-full max-w-sm sticky top-0 flex flex-col bg-white border shadow-sm rounded-xl dark:bg-turquoise-950 dark:border-turquoise-900">
            {artifactsData && artifactsData.length > 0 && (artifactsData?.some(a => data?.evidencerequest_artifact.indexOf(a.artifact_id) > -1)) ? (
                <div className="p-4 md:p-5 space-y-7">
                    {artifactsData?.map(a =>
                        data?.evidencerequest_artifact.indexOf(a.artifact_id) > -1 ? (
                            <div key={a.artifact_id}>
                                {/* Uploaded File Content */}
                                <div className="mb-2 flex gap-1 flex-wrap justify-between items-center">
                                    <div className="flex items-center gap-x-3">

                                        <button
                                            className="size-8 flex justify-center items-center border border-gray-200 text-gray-500 rounded-lg dark:border-neutral-700 dark:text-neutral-500 hover:text-gray-800 dark:hover:text-neutral-200"
                                            type='button'
                                            onClick={() => {
                                                // /{version}/artifact/{artifact_id} 
                                                fetch(`${cysrConfig().ctrl_api_base_url}artifact/${a.artifact_id}`, {
                                                    headers: {
                                                        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                                        // 'Accept-Language': language,
                                                        // "content-type": "application/json",
                                                    },
                                                    // method: "GET"
                                                })
                                                    .then(response => response.blob())
                                                    .then(blob => {
                                                        var url = window.URL.createObjectURL(blob);
                                                        var aLink = document.createElement('a');
                                                        aLink.href = url;
                                                        aLink.download = a.artifact_filename;
                                                        document.body.appendChild(aLink); // we need to append the element to the dom -> otherwise it will not work in firefox
                                                        aLink.click();
                                                        aLink.remove();  //afterwards we remove the element again         
                                                        toast.success("Artifact downloaded.")
                                                    })
                                                    /*
                                                    .then((response) => response.json())
                                                    .then((getDownloadsResponse) => {
                                                        toast.success(<>{JSON.stringify(getDownloadsResponse)}</>)
                                                    })
                                                    */
                                                    .catch((err) => {
                                                        console.error(1, err)
                                                    });
                                            }}>
                                            <DownloadSharp className="flex-shrink-0 size-5" />
                                        </button>

                                        <div>
                                            <p className="text-sm font-medium text-gray-800 dark:text-white">{a.artifact_name}</p>
                                            <p className="text-xs text-gray-500 dark:text-neutral-500">{a.artifact_description}</p>
                                        </div>
                                    </div>
                                    <div className="w-full inline-flex items-center gap-x-2">
                                        <div className='w-full'>
                                            <p className="text-xs text-gray-800 dark:text-white">{a.artifact_filename}</p>
                                            <p className="text-xs text-gray-500 dark:text-neutral-500">Expire on {a.artifact_expiration_date}</p>
                                        </div>
                                        <svg className="flex-shrink-0 size-4 text-teal-500" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                                        </svg>
                                    </div>
                                </div>
                                {/* End Uploaded File Content */}
                            </div>
                        ) : null
                    )
                    }
                    {false &&
                        <div>
                            {/* Uploading File Content */}
                            <div className="mb-2 flex gap-1 flex-wrap justify-between items-center">
                                <div className="flex items-center gap-x-3">
                                    <span className="size-8 flex justify-center items-center border border-gray-200 text-gray-500 rounded-lg dark:border-neutral-700 dark:text-neutral-500">
                                        <svg className="flex-shrink-0 size-5" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                                            <polyline points="17 8 12 3 7 8" />
                                            <line x1={12} x2={12} y1={3} y2={15} />
                                        </svg>
                                    </span>
                                    <div>
                                        <p className="text-sm font-medium text-gray-800 dark:text-white">artifact name</p>
                                        <p className="text-xs text-gray-500 dark:text-neutral-500">description</p>
                                    </div>
                                </div>
                                <div className="inline-flex items-center gap-x-2">
                                    <div>
                                        <p className="text-xs text-gray-800 dark:text-white">yourfilename.csv</p>
                                        <p className="text-xs text-gray-500 dark:text-neutral-500">Expire on -</p>
                                    </div>
                                    {
                                        /*
                                    }
                                <a className="text-gray-500 hover:text-gray-800 dark:text-neutral-500 dark:hover:text-neutral-200" href="#">
                                    <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                                        <rect width={4} height={16} x={6} y={4} />
                                        <rect width={4} height={16} x={14} y={4} />
                                    </svg>
                                </a>
                                {
                                    */
                                    }
                                    <button type='button' className="text-gray-500 hover:text-gray-800 dark:text-neutral-500 dark:hover:text-neutral-200">
                                        <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M3 6h18" />
                                            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                                            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                                            <line x1={10} x2={10} y1={11} y2={17} />
                                            <line x1={14} x2={14} y1={11} y2={17} />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            {/* End Uploading File Content */}
                            {/* Progress Bar */}
                            <div className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700" role="progressbar" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                                <div className="flex flex-col justify-center rounded-full overflow-hidden bg-red-500 text-xs text-white text-center whitespace-nowrap transition duration-500" style={{ width: '25%' }} />
                            </div>
                            {/* End Progress Bar */}
                            {/* Progress Bar */}
                            <div className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700" role="progressbar" aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}>
                                <div className="flex flex-col justify-center rounded-full overflow-hidden bg-teal-500 text-xs text-white text-center whitespace-nowrap transition duration-500" style={{ width: '100%' }} />
                            </div>
                            {/* End Progress Bar */}
                        </div>
                    }
                </div>
            ) : (
                <div className="p-4 md:p-5">
                    <p className="text-sm font-medium text-gray-800 dark:text-white">Fill the form to add artifacts.</p>
                </div>
            )
            }
            {/* Footer */}
            <div className="bg-gray-50 border-t border-gray-200 rounded-b-xl py-2 px-4 md:px-5 dark:bg-white/10 dark:border-neutral-700">
                <div className="flex flex-wrap justify-between items-center gap-x-3">
                    <div>
                        <span className="text-sm font-medium text-gray-800 dark:text-white">
                            {(artifactsData && artifactsData.length > 0) ? ((artifactsData?.filter(a => data?.evidencerequest_artifact.indexOf(a.artifact_id) > -1))?.length) : "0"} artifacts uploaded.
                        </span>
                    </div>
                    {/* End Col
                    <div className="-me-2.5">
                        <button type="button" className="py-2 px-3 inline-flex items-center gap-x-1.5 text-sm font-medium rounded-lg border border-transparent text-gray-800 hover:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-800">
                            <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                                <polygon points="5 3 19 12 5 21 5 3" />
                            </svg>
                            Start
                        </button>
                        <button type="button" className="py-2 px-3 inline-flex items-center gap-x-1.5 text-sm font-medium rounded-lg border border-transparent text-gray-500 hover:bg-gray-200 hover:text-gray-800 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:bg-neutral-800 dark:hover:text-white">
                            <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                                <path d="M3 6h18" />
                                <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                                <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                                <line x1={10} x2={10} y1={11} y2={17} />
                                <line x1={14} x2={14} y1={11} y2={17} />
                            </svg>
                            Delete
                        </button>
                    </div>
                    End Col */}
                </div>
            </div>
            {/* End Footer */}
        </div>
    );
}